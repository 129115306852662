import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetWithIoConfigurations($id: UUID!) {
		asset: assetById(id: $id) {
			id
			name
			devices {
				id
				deviceType
				name
				ioConfigurations {
					name
					id
					isEnabled
					ioName
					onAction
					offAction
				}
				... on ATrackDevice {
					id
				}
				... on CamperVanDevice {
					id
				}
				... on DigitalMatterDevice {
					id
				}
				... on EroadDevice {
					id
				}
				... on HyteraRadioDevice {
					id
				}
				... on MobilePhoneDevice {
					id
				}
				... on TaitRadioDevice {
					radioId
					id
				}
				... on TeltonikaDevice {
					id
					teltonikaModelType: modelType
				}
			}
		}
	}
`;
