import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, Form, Field, FormikProps, FormikErrors } from 'formik';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import SaveIcon from '@material-ui/icons/Save';

import { beaconUuid } from 'src/config';
import { Button, FixedWidthPage, FormikCheckbox, FormikSelect, FormikTextField } from 'src/components';
import { getPrettyName, runFormValidation } from 'src/util';

import { BluetoothBeaconType } from 'src/../__generated__/globalTypes';
import { QueryEditBeacon_beacon } from 'src/graphql/__generated__/queries/queryEditBeacon';

import {
	AuthenticationService,
	useInjection,
	Service,
} from 'src/services';

export interface ManageBeaconFormValues {
	name: string;
	serialNumber: string;
	beaconType: BluetoothBeaconType;
	transmitPower: number;
	dealerId: string | null;
	enabled: boolean;
}

const validateForm = (values: ManageBeaconFormValues, errors: FormikErrors<ManageBeaconFormValues>) => {
	if (!values.name)
		errors.name = 'Beacon name is required.';

	if (!values.serialNumber)
		errors.serialNumber = 'Serial number is required.';

	if (values.transmitPower < -128 || values.transmitPower > 0)
		errors.transmitPower = 'Transmit power must be between -128 and 0.';
}

interface Props {
	beacon?: QueryEditBeacon_beacon;
	dealers?: {
		id: string;
		name: string;
	}[];
	submit: (values: ManageBeaconFormValues) => Promise<void>;
}

const renderUuidMajorMinor = (formikProps: FormikProps<ManageBeaconFormValues>, existingBeacon: QueryEditBeacon_beacon) => {
	let major = existingBeacon.major;
	let displayChangeWarning = false;

	if (formikProps.values.beaconType !== existingBeacon.beaconType) {
		if (existingBeacon.beaconType === BluetoothBeaconType.STANDARD || formikProps.values.beaconType === BluetoothBeaconType.STANDARD) {
			displayChangeWarning = true;

			if (existingBeacon.beaconType === BluetoothBeaconType.STANDARD)
				major += 32768;
			else if (formikProps.values.beaconType === BluetoothBeaconType.STANDARD)
				major -= 32768;
		}
	}

	return <>
		{displayChangeWarning && <Alert severity="warning">
			Changing the Beacon Type to <em>{getPrettyName(formikProps.values.beaconType)}</em> requires
			you to re-program the beacon with the new major/minor values below.
		</Alert>}

		<TextField
			id="uuid"
			label="UUID"
			value={beaconUuid}
			disabled
			fullWidth
			variant="filled"
		/>

		<TextField
			id="major"
			label="Major"
			value={major}
			disabled
			fullWidth
			variant="filled"
			type="number"
		/>

		<TextField
			id="minor"
			label="Minor"
			value={existingBeacon.minor}
			disabled
			fullWidth
			variant="filled"
			type="number"
		/>
	</>;
};

export const ManageBeaconComponent = observer((props: Props) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);

	const [initialValues] = useState<ManageBeaconFormValues>({
		name: props.beacon?.name ?? '',
		serialNumber: props.beacon?.serialNumber ?? '',
		beaconType: props.beacon?.beaconType ?? BluetoothBeaconType.STANDARD,
		transmitPower: props.beacon?.transmitPower ?? -77,
		dealerId: props.beacon?.dealer?.id ?? null,
		enabled: !props.beacon?.disabled,
	});

	const addingNewBeacon = !props.beacon;

	return <FixedWidthPage
		className="form-page"
		headingText={addingNewBeacon ? 'Add Bluetooth Beacon' : 'Edit Bluetooth Beacon'}
		pageItemId={props.beacon?.id}
		headingActions={props.beacon && authenticationService.currentAuth.permissions.general.placeBeacons ? <Button
			href={`/app/bluetooth-beacons/${props.beacon.id}/place`}
			startIcon={<LocationOnIcon />}
			text="Place Beacon"
			variant="outlined"
			color="primary"
		/> : null}
	>
		<Formik
			initialValues={initialValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={props.submit}
		>
			{formikProps => <Form className="formik-form">
				<Field
					name="name"
					label="Name"
					type="text"
					component={FormikTextField}
					required
				/>

				<Field
					name="serialNumber"
					label="Serial Number"
					type="text"
					component={FormikTextField}
					required
				/>

				<FormikSelect
					name="beaconType"
					label="Beacon Type"
					required
					form={formikProps}
					options={Object.keys(BluetoothBeaconType)}
					getOptionValue={x => x}
					getOptionLabel={x => getPrettyName(x as BluetoothBeaconType)}
				/>

				{props.beacon && renderUuidMajorMinor(formikProps, props.beacon)}

				<Field
					name="transmitPower"
					label="Transmit Power"
					type="number"
					component={FormikTextField}
					required
				/>

				{props.dealers && <FormikSelect
					name="dealerId"
					label="Dealer"
					form={formikProps}
					options={props.dealers}
					getOptionValue={x => x.id}
					getOptionLabel={x => x.name}
					clearable
				/>}

				{props.beacon && <Field
					name="enabled"
					label="Enabled"
					type="checkbox"
					component={FormikCheckbox}
				/>}

				<Button
					type="submit" variant="contained" color="primary"
					loading={formikProps.isSubmitting}
					startIcon={addingNewBeacon ? null : <SaveIcon />}
					text={addingNewBeacon ? 'Add Beacon' : 'Save Changes'}
				/>
			</Form>}
		</Formik>
	</FixedWidthPage>;
});
