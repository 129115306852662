import mapboxgl, { EventData, MapMouseEvent } from 'mapbox-gl';
import { bind } from 'bind-decorator';

import { tileServerBaseUrl } from 'src/config';

const closeIcon = require('!svg-inline-loader?classPrefix!../resources/icons/close-24px.svg');
const directionsIcon = require('!svg-inline-loader?classPrefix!../resources/icons/directions-24px.svg');

import {
	Client as C,
} from 'src/services';

interface ILayerStylePopupRow {
	type?: 'directions-button';
	requiredProperties: string[];
	render?: string;
	header?: boolean;
}

interface ILayerStylePopup {
	rows: ILayerStylePopupRow[];
}

interface ILayerStyle {
	style: C.ITilesetMapLayerStyleDto;
	hover?: ILayerStylePopup;
	click?: ILayerStylePopup;
}

export class MapDataManager {
	private _map: mapboxgl.Map;

	private _allTilesets = new Map<string, C.ITilesetDto>();
	private _allTilesetMapLayers: C.ITilesetMapLayerDto[] = [];
	private _allTilesetIcons = new Map<string, C.ITilesetIconDto>();

	private _activeTilesets = new Map<string, C.ITilesetMapLayerDto[]>();
	private _activeLayers = new Map<string, C.ITilesetMapLayerDto>();

	private _activeLayerStyles = new Map<string, ILayerStyle>();
	private _activeLayerStylesWithHover: string[] = [];
	private _activeLayerStylesWithClick: string[] = [];

	private _hoverLabel?: mapboxgl.Popup;
	private _iconsLoading = new Map<string, boolean>();

	constructor(map: mapboxgl.Map, tilesets: C.ITilesetDto[]) {
		this._map = map;

		for (const tileset of tilesets) {
			this._allTilesets.set(tileset.tilesetId, tileset);

			if (tileset.layers)
				this._allTilesetMapLayers.push(...tileset.layers);

			if (tileset.icons) {
				for (const icon of tileset.icons)
					this._allTilesetIcons.set(icon.tilesetIconId, icon);
			}
		}

		this._map.on('styleimagemissing', this.onMapboxStyleImageMissing);
		this._map.on('mousemove', this.onMapboxMouseMove);
		this._map.on('click', this.onMapboxClick);
	}

	public getAllTilesets(): C.ITilesetDto[] {
		return Array.from(this._allTilesets.values());
	}

	getActiveTilesetMapLayers() {
		return Array.from(this._activeLayers.values());
	}

	canSearchActiveTilesetMapLayers(): boolean {
		for (const layer of this._activeLayers.values()) {
			if (layer.searchable)
				return true;
		}

		return false;
	}

	reset() {
		this._activeTilesets.clear();
		this._activeLayers.clear();

		this._activeLayerStyles.clear();
		this._activeLayerStylesWithHover = [];
		this._activeLayerStylesWithClick = [];

		this._iconsLoading.clear();

		this.removeHoverLabel();
	}

	addOrRemoveLayers(hiddenLayers: string[]) {
		for (const tileset of this._allTilesets.values()) {
			if (!tileset.layers)
				continue;

			for (const layer of tileset.layers) {
				const active = !!this._activeLayers.get(layer.tilesetMapLayerId);
				const shouldHide = hiddenLayers.indexOf(layer.tilesetMapLayerId) !== -1;

				// If the layer is not on the map, but should be, add the layer.
				if (!active && !shouldHide)
					this.addLayer(layer);
				// If the layer is on the map, but shouldn't be, remove the layer.
				else if (active && shouldHide)
					this.removeLayer(layer);
			}
		}
	}

	private addLayer(layer: C.ITilesetMapLayerDto) {
		const existingLayer = this._activeLayers.get(layer.tilesetMapLayerId);
		if (existingLayer)
			return;

		this.maybeAddTilesetMapboxSource(layer);

		if (layer.styles) {
			for (const style of layer.styles) {
				try {
					const mapboxStyle: mapboxgl.AnyLayer = {
						id: style.tilesetMapLayerStyleId,
						source: layer.tilesetId,
						'source-layer': layer.tilesetMapLayerId,
						minzoom: style.minZoom ?? 1,
						maxzoom: style.maxZoom ?? 24,
						type: style.type as any,
					};

					if (style.layout)
						mapboxStyle.layout = JSON.parse(style.layout);

					if (style.paint)
						mapboxStyle.paint = JSON.parse(style.paint);

					this._map.addLayer(mapboxStyle);

					const layerStyle: ILayerStyle = {
						style: style,
						hover: style.hover ? JSON.parse(style.hover) as ILayerStylePopup : undefined,
						click: style.click ? JSON.parse(style.click) as ILayerStylePopup : undefined,
					};

					this._activeLayerStyles.set(style.tilesetMapLayerStyleId, layerStyle);

					if (layerStyle.hover)
						this._activeLayerStylesWithHover.push(style.tilesetMapLayerStyleId);

					if (layerStyle.click)
						this._activeLayerStylesWithClick.push(style.tilesetMapLayerStyleId);
				} catch (ex) {
					console.error('Failed to parse Mapbox map layer style.', layer, ex);
				}
			}
		}

		this._activeLayers.set(layer.tilesetMapLayerId, layer);
	}

	private removeLayer(layer: C.ITilesetMapLayerDto) {
		if (layer.styles) {
			for (const style of layer.styles) {
				this._map.removeLayer(style.tilesetMapLayerStyleId);
				this._activeLayerStyles.delete(style.tilesetMapLayerStyleId);

				this._activeLayerStylesWithHover = this._activeLayerStylesWithHover.filter(x => x !== style.tilesetMapLayerStyleId);
			}
		}

		this._activeLayers.delete(layer.tilesetMapLayerId);

		this.maybeRemoveTilesetMapboxSource(layer);
	}

	private maybeAddTilesetMapboxSource(layer: C.ITilesetMapLayerDto) {
		const activeTilesetLayers = this._activeTilesets.get(layer.tilesetId) ?? [];

		if (activeTilesetLayers.length === 0) {
			const tileset = this._allTilesets.get(layer.tilesetId);
			if (!tileset)
				return;

			this._map.addSource(tileset.tilesetId, {
				type: 'vector',
				tiles: [ tileServerBaseUrl + tileset.url ],
				minzoom: tileset.minZoom,
				maxzoom: tileset.maxZoom,
			});
		}

		activeTilesetLayers.push(layer);
		this._activeTilesets.set(layer.tilesetId, activeTilesetLayers);
	}

	private maybeRemoveTilesetMapboxSource(layer: C.ITilesetMapLayerDto) {
		let activeTilesetLayers = this._activeTilesets.get(layer.tilesetId) ?? [];
		activeTilesetLayers = activeTilesetLayers.filter(x => x.tilesetMapLayerId !== layer.tilesetMapLayerId);

		if (activeTilesetLayers.length === 0) {
			this._map.removeSource(layer.tilesetId);
			this._activeTilesets.delete(layer.tilesetId);
			return;
		}

		this._activeTilesets.set(layer.tilesetId, activeTilesetLayers);
	}

	@bind
	private renderRow(feature: mapboxgl.MapboxGeoJSONFeature, row: ILayerStylePopupRow, coordinates: [number, number]): HTMLElement | null {
		if (row.type === 'directions-button') {
			const directionsButton = document.createElement('a');
			directionsButton.classList.add('directions');
			directionsButton.innerHTML = directionsIcon;
			directionsButton.appendChild(document.createTextNode('Directions'));

			directionsButton.href = `https://www.google.com/maps/dir/?api=1&destination=${coordinates[1]},${coordinates[0]}`;
			directionsButton.target = '_blank';
			directionsButton.rel = 'noopener noreferrer';

			return directionsButton;
		}

		let rowText = row.render!!;

		for (const property of row.requiredProperties) {
			const value = feature.properties?.[property];
			if (!value)
				return null;

			rowText = rowText.replace(`__${property}__`, value);
		}

		const rowDiv = document.createElement('div');
		rowDiv.appendChild(document.createTextNode(rowText));

		return rowDiv;
	}

	@bind
	private renderPopupContent(popup: mapboxgl.Popup, feature: mapboxgl.MapboxGeoJSONFeature, style: ILayerStylePopup, click: boolean, coordinates: [number, number]): HTMLElement | null {
		const headerRows: HTMLElement[] = [];
		const rows: HTMLElement[] = [];

		for (const row of style.rows) {
			const renderedRow = this.renderRow(feature, row, coordinates);
			if (renderedRow) {
				if (row.header)
					headerRows.push(renderedRow);
				else
					rows.push(renderedRow);
			}
		}

		// No header rows and no normal rows = no popup to show.
		if (headerRows.length === 0 && rows.length === 0)
			return null;

		const popupContent = document.createElement('div');
		popupContent.classList.add('map-data-popup');

		// Only add the header element if there are header rows, or if this is click popup.
		if (headerRows.length > 0 || click) {
			const header = document.createElement('div');
			header.classList.add('header');

			if (headerRows.length > 0) {
				const headerRowsElement = document.createElement('div');
				headerRowsElement.classList.add('rows');

				for (const row of headerRows)
					headerRowsElement.appendChild(row);

				header.appendChild(headerRowsElement);
			}

			const closeButton = document.createElement('button');
			closeButton.innerHTML = closeIcon;
			closeButton.addEventListener('click', popup.remove);
			header.appendChild(closeButton);

			popupContent.appendChild(header);
		}

		for (const row of rows)
			popupContent.appendChild(row);

		return popupContent;
	}

	@bind
	removeHoverLabel() {
		if (!this._hoverLabel)
			return;

		this._map.getCanvas().style.cursor = '';
		this._hoverLabel.remove();
		this._hoverLabel = undefined;
	}

	/**
	 * "Fired when an icon or pattern needed by the style is missing."
	 * We use this to trigger loading for a custom icon.
	 */
	@bind
	async onMapboxStyleImageMissing(e: EventData): Promise<void> {
		const imageId = e['id'];

		// Only try to handle images that have an ID matching an icon.
		const icon = this._allTilesetIcons.get(imageId);
		if (!icon)
			return;

		// Don't try to load the icon if it is already loading (or an attempt to load has already been made, and it failed).
		if (this._iconsLoading.has(icon.tilesetIconId))
			return;

		this.loadIcon(icon);
	}

	/**
	 * Load the given icon and add it to the map.
	 */
	@bind
	loadIcon(icon: C.ITilesetIconDto) {
		this._iconsLoading.set(icon.tilesetIconId, true);

		this._map.loadImage(icon.imageUrl, (error, result) => {
			if (error) {
				console.error(`Failed to load map icon image. (ID: ${icon.tilesetIconId}) (image URL: ${icon.imageUrl})\n`, error);
				return;
			}

			this._map.addImage(icon.tilesetIconId, result!);
			this._iconsLoading.delete(icon.tilesetIconId);
		});
	}

	@bind
	onMapboxMouseMove(e: MapMouseEvent & EventData): void {
		const features = this._map.queryRenderedFeatures(e.point, {
			layers: this._activeLayerStylesWithHover,
		});

		this.removeHoverLabel();

		if (features.length === 0 || !features[0].properties)
			return;

		this._map.getCanvas().style.cursor = 'pointer';

		const feature = features[0];
		const layerStyle = this._activeLayerStyles.get(feature.layer.id);
		if (!layerStyle || !layerStyle.hover)
			return;

		const popup = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false,
			anchor: 'left',
			focusAfterOpen: false,
			className: 'map-data-popup__no-pointer',
		});

		let coordinates: [number, number] = [ e.lngLat.lng, e.lngLat.lat ];
		if (feature.geometry.type === 'Point')
			coordinates = feature.geometry.coordinates as [number, number];

		const popupContent = this.renderPopupContent(popup, feature, layerStyle.hover, false, coordinates);
		if (!popupContent)
			return;

		this._hoverLabel = popup
			.setLngLat(coordinates)
			.setDOMContent(popupContent)
			.addTo(this._map);
	}

	@bind
	onMapboxClick(e: MapMouseEvent & EventData): void {
		const features = this._map.queryRenderedFeatures(e.point, {
			layers: this._activeLayerStylesWithClick,
		});

		if (features.length === 0 || !features[0].properties)
			return;

		const feature = features[0];
		const layerStyle = this._activeLayerStyles.get(feature.layer.id);
		if (!layerStyle || !layerStyle.click)
			return;

		const popup = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: true,
			anchor: 'bottom',
			focusAfterOpen: false,
		});

		let coordinates: [number, number] = [ e.lngLat.lng, e.lngLat.lat ];
		if (feature.geometry.type === 'Point')
			coordinates = feature.geometry.coordinates as [number, number];

		const popupContent = this.renderPopupContent(popup, feature, layerStyle.click, true, coordinates);
		if (!popupContent)
			return;

		this.removeHoverLabel();

		popup
			.setLngLat(coordinates)
			.setDOMContent(popupContent)
			.addTo(this._map);
	}
}
