import { injectable } from 'inversify';
import { Howl } from 'howler';

const onDutySound = require('src/on-duty.mp3');
const offDutySound = require('src/off-duty.mp3');
const alertSound = require('src/alert.mp3');
const priorityAlertSound = require('src/priority_alert.mp3');
const alertWarningSound = require('src/alert_warning.mp3');

export enum PlayState {
	Play,
	Stop,
}

@injectable()
export class SoundService {
	private onDutySoundPlayer = new Howl({
		src: onDutySound,
	});

	private offDutySoundPlayer = new Howl({
		src: offDutySound,
	});

	private alertWarningSoundPlayer = new Howl({
		src: alertWarningSound,
		loop: true,
	});

	// Exclusive mix
	private emergencySoundPlayer = new Howl({
		src: alertSound,
		loop: true,
	});

	private priorityAlertSoundPlayer = new Howl({
		src: priorityAlertSound,
		loop: true,
	});

	setPlayState(sound: Howl, playState: PlayState, exclusive: boolean = false) {
		if (playState === PlayState.Play) {
			if (exclusive && sound.playing()) {
				// Already playing
			} else {
				sound.play();
			}
		} else {
			sound.stop();
		}
	}

	onDuty() {
		this.setPlayState(this.onDutySoundPlayer, PlayState.Play);
	}

	offDuty() {
		this.setPlayState(this.offDutySoundPlayer, PlayState.Play);
	}

	alertWarning(playState: PlayState) {
		if (this.emergencySoundPlayer.playing())
			return;

		this.setPlayState(this.alertWarningSoundPlayer, playState, true);
	}

	priorityAlert(playState: PlayState) {
		if (playState === PlayState.Play)
			this.setPlayState(this.alertWarningSoundPlayer, PlayState.Stop);

		this.setPlayState(this.priorityAlertSoundPlayer, playState, true);
	}

	emergency(playState: PlayState) {
		if (playState === PlayState.Play)
			this.setPlayState(this.alertWarningSoundPlayer, PlayState.Stop);

		this.setPlayState(this.emergencySoundPlayer, playState, true);
	}
}
