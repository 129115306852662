import React from 'react';

import { Button } from 'src/components';
import { MapViewMode } from './mapViewMode';

export interface MapModeSelectorProps {
	mapViewModeOptionsDisabled: Set<MapViewMode>;
	mapViewModeOptions: Set<MapViewMode>;
	mapViewMode: MapViewMode;

	onMapViewModeChange: (mode: MapViewMode) => void;
}

export const MapModeSelector = (props: MapModeSelectorProps) => {
	const { mapViewModeOptions } = props;

	if (props.mapViewModeOptions.size < 2)
		return null;

	return <div className="map-mode-selector">
		<div className="toggle-button-group">
			{mapViewModeOptions.has(MapViewMode.FloorPlan) && <Button
				text="Floor Plan"
				className={props.mapViewMode == MapViewMode.World ? 'default' : 'success'}
				onClick={() => props.onMapViewModeChange(MapViewMode.FloorPlan)}
				disabled={props.mapViewModeOptionsDisabled.has(MapViewMode.FloorPlan)}
				variant="outlined"
				size="small"
			/>}

			{mapViewModeOptions.has(MapViewMode.World) && <Button
				text="World"
				className={props.mapViewMode == MapViewMode.World ? 'success' : 'default'}
				onClick={() => props.onMapViewModeChange(MapViewMode.World)}
				variant="outlined"
				size="small"
			/>}
		</div>
	</div>;
};
