import React from 'react';

import { ThingLoader } from 'src/components';

import { ManageAssetGroupComponent } from './manageAssetGroupComponent';

import {
	Client as C,
	AuthenticationService,
	ClientService,
	Service,
	useInjection,
} from 'src/services';

interface IPageData {
	clients?: C.IClientDto[];
}

export const AddAssetGroup = () => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _clientService = useInjection<ClientService>(Service.Client);

	const load = async (): Promise<IPageData | null> => {
		let clients: C.IClientDto[] = [];
		if (_authService.currentAuth.user.identity.type !== C.IdentityType.Client) {
			const loadedClients = await _clientService.getAllClients();
			if (!loadedClients)
				return null;

			clients = loadedClients;
		}

		return {
			clients: clients,
		};
	};

	return <ThingLoader
		load={load}
		render={(pageData: IPageData) => <ManageAssetGroupComponent
			clients={pageData.clients}
		/>}
	/>;
};
