import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryCallRecordingsOptions($includeNetwork: Boolean!) {
		assets {
			id
			name
			devices {
				deviceType
				... on MobilePhoneDevice {
					chatterPttUserId
					network @include(if: $includeNetwork) {
						id
					}
				}
				... on TaitRadioDevice {
					selcallId
					radioId
					network @include(if: $includeNetwork) {
						id
					}
				}
			}
		}
		callGroups {
			id
			name
			network {
				id
			}
		}
		networks @include(if: $includeNetwork) {
			id
			name
		}
	}
`;
