import React from 'react';
import { Redirect } from 'react-router-dom';

import { FixedWidthPage } from 'src/components';

import {
	Client as C,
	AuthenticationService,
	Service,
	useInjection,
} from 'src/services';

export const Welcome = () => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);

	if (_authService.currentAuth.permissions.general.viewAssetLocations)
		return <Redirect to="/app/map" />;

	if (_authService.currentAuth.user.identity.dealer?.type === C.DealerType.ConfigurationOnly)
		return <Redirect to="/app/assets/list" />;

	return <FixedWidthPage className="welcome">Welcome</FixedWidthPage>;
};
