import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetsForRemindersManage($includeClient: Boolean!)  {
		assets {
			id
			name
			client @include(if: $includeClient) {
				name
				id
			}
			assetTelematicsCurrentValueAndReadings {
				type
				value
				lastReading
				lastReadingTimestamp
			}
		}
	}
`;
