import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAddDevice($includeAssetTypes: Boolean!, $includeDealers: Boolean!, $includeClients: Boolean!) {
		assetTypes @include(if: $includeAssetTypes) {
			id
			name
			client @include(if: $includeClients) {
				id
			}
		}

		bluetoothBeaconSets {
			id
			name
			dealer @include(if: $includeDealers) {
				id
			}
		}

		dealers @include(if: $includeDealers) {
			id
			name
		}

		clients @include(if: $includeClients) {
			id
			name
			dealer @include(if: $includeDealers) {
				id
			}
		}
	}
`;
