import React from 'react';
import { observer } from 'mobx-react';
import { FormikProps } from 'formik';

import { FormikSelect } from 'src/components';
import { Client as C, useIdentityType } from 'src/services';
import { Tooltip } from '@material-ui/core';

interface IDealerClientFormValues {
	dealerId: string | null;
	clientId: string | null;
}

interface IProps<T> {
	form: FormikProps<T>;
	dealerDisabledMessage?: string | null;
	clientDisabledMessage?: string | null;
	dealers?: {
		id: string;
		name: string;
	}[];
	clients?: {
		id: string;
		name: string;
		dealer: null | {
			id: string;
		};
	}[];
}

export const DealerClientSelector = observer(<T extends IDealerClientFormValues>(props: IProps<T>) => {
	const identityType = useIdentityType()!;

	const hasDealerSelection = identityType === C.IdentityType.SuperUser;
	const hasClientSelection = identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer;

	let clientOptions = props.clients;
	if (hasDealerSelection && clientOptions) {
		if (props.form.values.dealerId)
			clientOptions = clientOptions.filter(x => x.dealer?.id === props.form.values.dealerId);
		else
			clientOptions = [];
	}

	const dealerSelector = hasDealerSelection && props.dealers && <FormikSelect
		name="dealerId"
		label="Dealer"
		form={props.form}
		options={props.dealers}
		getOptionValue={x => x.id}
		getOptionLabel={x => x.name}
		disabled={!!props.dealerDisabledMessage}
		clearable
	/>;

	const clientSelector = hasClientSelection && props.clients && <FormikSelect
		name="clientId"
		label="Client"
		form={props.form}
		disabled={(hasDealerSelection && !props.form.values.dealerId) || !!props.clientDisabledMessage}
		options={clientOptions || []}
		getOptionValue={x => x.id}
		getOptionLabel={x => x.name}
		clearable
	/>;

	return <>
		{dealerSelector && <div>
			{props.dealerDisabledMessage
				? <Tooltip arrow title={props.dealerDisabledMessage}><div>{dealerSelector}</div></Tooltip>
				: dealerSelector}
		</div>}

		{clientSelector && <div>
			{props.clientDisabledMessage
				? <Tooltip arrow title={props.clientDisabledMessage}><div>{clientSelector}</div></Tooltip>
				: clientSelector}
		</div>}
	</>;
});
