import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, Form, Field, FormikErrors } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikCheckbox, FormikSelect, FormikTextField } from 'src/components';
import { DealerClientSelector } from 'src/components/form';
import { Client as C, useCurrentUser } from 'src/services';
import { featureToggleAssetTypes } from 'src/config';

import { QueryEditAsset_asset } from 'src/graphql/__generated__/queries/queryEditAsset';
import { runFormValidation } from 'src/util';

export interface ManageAssetFormValues {
	name: string;
	assetTypeId: string | null;
	dealerId: string | null;
	clientId: string | null;
	speedLimitEnabled: boolean;
	speedLimit: number | null;
	emergencyNotes: string | null;
}

const validateForm = (values: ManageAssetFormValues, errors: FormikErrors<ManageAssetFormValues>) => {
	if (!values.name)
		errors.name = 'Asset name is required.';
}

interface Props {
	asset?: QueryEditAsset_asset;
	assetTypes?: {
		id: string;
		name: string;
		client: null | {
			id: string;
		};
	}[];
	dealers?: {
		id: string;
		name: string;
	}[];
	clients?: {
		id: string;
		name: string;
		dealer: null | {
			id: string;
		};
	}[];
	submit: (values: ManageAssetFormValues) => Promise<void>;
}

export const ManageAssetComponent = observer((props: Props) => {
	const currentUser = useCurrentUser()!;

	const [initialValues] = useState<ManageAssetFormValues>({
		name: props.asset?.name ?? '',
		assetTypeId: props.asset?.assetType?.id ?? null,
		dealerId: props.asset?.dealer?.id ?? null,
		clientId: props.asset?.client?.id ?? null,
		speedLimitEnabled: !!props.asset?.speedLimit,
		speedLimit: props.asset?.speedLimit
			? (currentUser.usesMetric ? props.asset.speedLimit : Math.round(props.asset.speedLimit * 0.621371))
			: (currentUser.usesMetric ? 110 : 70),
		emergencyNotes: props.asset?.emergencyNotes ?? null,
	});

	const addingNewAsset = !props.asset;

	const hasClientSelection = currentUser.identity.type === C.IdentityType.SuperUser || currentUser.identity.type === C.IdentityType.Dealer;

	return <FixedWidthPage
		className="form-page"
		headingText={addingNewAsset ? 'Add Asset' : 'Edit Asset'}
		pageItemId={props.asset?.id}
	>
		<Formik
			initialValues={initialValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={props.submit}
		>
			{formikProps => {
				let assetTypeOptions = props.assetTypes;
				if (hasClientSelection && assetTypeOptions) {
					if (formikProps.values.clientId)
						assetTypeOptions = assetTypeOptions.filter(x => x.client?.id === formikProps.values.clientId);
					else
						assetTypeOptions = [];
				}

				return <Form className="formik-form">
					<Field
						name="name"
						label="Name"
						type="text"
						component={FormikTextField}
						required
					/>

					<DealerClientSelector
						form={formikProps}
						dealers={props.dealers}
						clients={props.clients}
					/>

					{featureToggleAssetTypes === 'true' && assetTypeOptions && <FormikSelect
						name="assetTypeId"
						label="Asset Type"
						form={formikProps}
						disabled={hasClientSelection && !formikProps.values.clientId}
						options={assetTypeOptions || []}
						getOptionValue={x => x.id}
						getOptionLabel={x => x.name}
						clearable
					/>}

					<Field
						name="speedLimitEnabled"
						label="Enable Speed Limit"
						type="checkbox"
						component={FormikCheckbox}
					/>

					<Field
						name="speedLimit"
						label="Speed Limit"
						type="number"
						component={FormikTextField}
						disabled={!formikProps.values.speedLimitEnabled}
						helperText="Speed alerts will only be triggered if the asset sends location updates with travel speed information."
						InputProps={{
							endAdornment: <InputAdornment position="end">{currentUser.usesMetric ? 'km/h' : 'mph' }</InputAdornment>,
						}}
					/>

					<Field
						name="emergencyNotes"
						label="Emergency Notes"
						type="text"
						component={FormikTextField}
						helperText="Emergency notes will be displayed if the asset triggers an emergency alert."
						multiline={true}
					/>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						loading={formikProps.isSubmitting}
						startIcon={addingNewAsset ? null : <SaveIcon />}
						text={addingNewAsset ? 'Add Asset' : 'Save Changes'}
					/>
				</Form>;
			}}
		</Formik>
	</FixedWidthPage>;
});
