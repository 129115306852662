import React from 'react';
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import Card from '@material-ui/core/Card';
import { css } from '@emotion/css';

import { Button, FormikTextField } from 'src/components';

export interface IResolveEmergencyFormValues {
	notes: string;
}


interface IResolveEmergencyFormProps {
	onSubmit: (values: IResolveEmergencyFormValues, FormikHelpers: FormikHelpers<IResolveEmergencyFormValues>) => void;
}

const cardStyle = css`
	padding: 20px;

	h2 {
		margin-bottom: 0px;
	}
`;

export const ResolveEmergencyForm = (props: IResolveEmergencyFormProps) => <Card
	className={cardStyle}
>
	<h2>Resolve Alert</h2>

	<Formik
		initialValues={{
			notes: '',
		}}
		onSubmit={props.onSubmit}
		render={(formikProps: FormikProps<IResolveEmergencyFormValues>) => <Form className="formik-form">
			<Field
				name="notes"
				label="Notes"
				type="text"
				component={FormikTextField}
				variant="outlined"
				fullWidth
				multiline
				margin="normal"
			/>

			<div className="form-actions">
				<Button
					type="submit"
					variant="contained"
					color="primary"
					loading={formikProps.isSubmitting}
					text="Resolve"
				/>
			</div>
		</Form>}
	/>
</Card>;
