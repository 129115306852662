import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MaterialTableProps, Filter } from '@material-table/core';
import { DialogContentText } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HighlightOff from '@material-ui/icons/HighlightOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, FixedWidthPage, ThingLoader, MessagePage, CustomActionDialogBox, MaterialTable, PopoverMenu, PopoverMenuItem } from 'src/components';
import { shortDateFormat } from 'src/util/dateTimeFormats';
import { baseUrl } from 'src/config';

import {
	AuthenticationService,
	Client as C,
	ReportService,
	Service,
	ToasterService,
	useInjection,
	WebSocketService,
} from 'src/services';

import './index.scss';

type ReportDownload = {
	type: string;
	url: (reportId: string) => string;
};

type ReportTypeInfo = {
	label: string;
	viewUrl?: string;
	download?: ReportDownload[];
};

const reportTypesInfo: { [key: string]: ReportTypeInfo } = {
	[C.ReportType.Activity]: {
		label: 'Activity',
		viewUrl: '/app/reports/view/activity/',
		download: [
			{
				type: 'CSV (Summary)',
				url: (reportId: string) => `activity-report/${reportId}/download/summary/csv`,
			},
			{
				type: 'CSV (Detailed)',
				url: (reportId: string) => `activity-report/${reportId}/download/details/csv`,
			}
		],
	},
	[C.ReportType.AssetEventHistory]: {
		label: 'Asset Event History',
		download: [
			{
				type: 'CSV',
				url: (reportId: string) => `asset-event-history-report/${reportId}/download/csv`,
			}
		],
	},
	[C.ReportType.AssetLogHistory]: {
		label: 'Asset Log History',
		download: [
			{
				type: 'CSV',
				url: (reportId: string) => `asset-log-history-report/${reportId}/download/csv`,
			}
		],
	},
	[C.ReportType.Billing]: {
		label: 'Device Billing',
		viewUrl: '/app/reports/view/billing/',
		download: [
			{
				type: 'CSV (Summary)',
				url: (reportId: string) => `billing-report/${reportId}/download/summary/csv`,
			},
			{
				type: 'CSV (Detailed)',
				url: (reportId: string) => `billing-report/${reportId}/download/details/csv`,
			}
		],
	},
	[C.ReportType.Daily]: {
		label: 'Daily',
		download: [
			{
				type: 'CSV',
				url: (reportId: string) => `daily-report/${reportId}/download/csv`,
			}
		],
	},
	[C.ReportType.Driver]: {
		label: 'Driver',
		viewUrl: '/app/reports/view/driver/',
		download: [
			{
				type: 'CSV',
				url: (reportId: string) => `driver-report/${reportId}/download/csv`,
			}
		],
	},
	[C.ReportType.Geofence]: {
		label: 'Geofence',
		viewUrl: '/app/reports/view/geofence/',
	},
	[C.ReportType.Location]: {
		label: 'Location',
		viewUrl: '/app/reports/view/location/',
		download: [
			{
				type: 'CSV',
				url: (reportId: string) => `location-report/${reportId}/download/csv`,
			}
		],
	},
	[C.ReportType.SafetyTimer]: {
		label: 'Safety Timer',
		viewUrl: '/app/reports/view/safety-timer/',
		download: [
			{
				type: 'CSV',
				url: (reportId: string) => `safety-timer-report/${reportId}/download/csv`,
			}
		],
	},
	[C.ReportType.UserAuthAudit]: {
		label: 'User Auth Audit',
		viewUrl: '/app/reports/view/user-auth-audit/'
	}
};

export const ReportsIndex = () => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _reportService = useInjection<ReportService>(Service.Report);
	const _webSocketService = useInjection<WebSocketService>(Service.WebSocket);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [reports, setReports] = useState<Map<string, C.IReportDto>>(new Map<string, C.IReportDto>());
	const [reportsToDelete, setReportsToDelete] = useState<C.IReportDto[]>([]);

	useEffect(() => {
		_webSocketService.subscribeToReportUpdates(updatedReport => {
			setReports(old => {
				const newReports = new Map<string, C.IReportDto>(old);
				newReports.set(updatedReport.reportId, updatedReport);
				return newReports;
			});
		});

		return () => _webSocketService.unsubscribeFromReportUpdates();
	}, []);

	const reportTypes = useMemo(() => {
		const dictionary: { [key: string]: string } = {};
		const types = new Set(Array.from(reports.values()).map(x => x.type));

		for (const type of types)
			dictionary[type] = reportTypesInfo[type].label;

		return dictionary;
	}, [reports]);

	const load = async () => {
		const allReports = await _reportService.listReports();
		if (!allReports)
			return null;

		const map = new Map<string, C.IReportDto>();
		for (let i = 0; i < allReports.length; i++)
			map.set(allReports[i].reportId, allReports[i]);

		setReports(map);
		return true;
	};

	const downloadReportById = (reportId: string, reportDownload: ReportDownload) => {
		const url = `${baseUrl}/api/v1/reporting/${reportDownload.url(reportId)}`;
		window.open(url, '_blank');
	};

	const renderTableActions = (tableProps: MaterialTableProps<C.IReportDto>, reload: Function) => {
		if (!tableProps.data || tableProps.data.length === 0)
			return null;

		return <Button
			variant="contained"
			color={red}
			startIcon={<HighlightOff />}
			text={tableProps.data.length === 1 ? 'Delete Report' : 'Delete Reports'}
			title={tableProps.data.length === 1 ? 'Delete Report' : 'Delete Reports'}
			onClick={() => setReportsToDelete(tableProps.data as C.IReportDto[])}
		/>;
	};

	const confirmDelete = async (reload: Function) => {
		setReportsToDelete([]);

		try {
			await _reportService.deleteReports(reportsToDelete.map(x => x.reportId));
			_toasterService.showSuccess('The selected report(s) have been deleted.');

			reload();
		} catch (e) {
			_toasterService.handleWithToast(e, 'Failed to delete reports(s).');
		}
	};

	const renderActionButtons = (report: C.IReportDto) => {
		const reportType = reportTypesInfo[report.type];
		if (!reportType)
			return null;

		const downloadOptions = reportType.download && reportType.download.map(x => <PopoverMenuItem
			key={x.type}
			text={x.type}
			onClick={() => downloadReportById(report.reportId, x)}
		/>);

		const reportProcessingStatus = report.processingStatus;
		return <div className="action-buttons">
			{reportProcessingStatus === C.ReportProcessingStatus.Complete && <>
				{!!reportType.viewUrl && <Button
					startIcon={<VisibilityIcon />}
					text="view"
					href={`${reportType.viewUrl}${report.reportId}`}
				/>}

				{downloadOptions && downloadOptions.length > 0 && <PopoverMenu
					renderOptions={() => downloadOptions}
				>
					<Button
						startIcon={<CloudDownloadIcon />}
						text="Download"
					/>
				</PopoverMenu>}
			</>}

			{(reportProcessingStatus === C.ReportProcessingStatus.Queued || reportProcessingStatus === C.ReportProcessingStatus.Processing) && <Button loading disabled />}
		</div>;
	};

	return <ThingLoader
		load={load}
		render={(_, reload: Function) => <FixedWidthPage
			headingText="Reports"
			noContentBackground
			headingActions={<>
				<Button text="Scheduled Reports" variant="outlined" color="primary" href="/app/reports/scheduled" />
				<Button text="Create new report" startIcon={<AddIcon />} variant="outlined" color="primary" href="/app/reports/create" />
			</>}
			className="reports-list"
			contentMessageReplace={reports.size === 0 && <MessagePage
			title="No reports."
			action={<Button href="/app/reports/create" text="Create a new report?" variant="outlined" />}
			/>}
		>
			<MaterialTable
				tableName="reports-list"
				columns={[
					{
						title: 'Name',
						field: 'name',
					},
					{
						title: 'Type',
						field: 'type',
						lookup: reportTypes,
					},
					{
						title: 'Created',
						field: 'requestedAt',
						render: rowData => rowData.requestedAt.tz(_authService.currentAuth.user.timeZone).format(shortDateFormat),
					},
					{
						title: 'Processing Status',
						field: 'processingStatus',
					},
					{
						title: 'Options',
						field: 'options',
						filtering: false,
						sorting: false,
						headerStyle: ({
							textAlign: 'right',
						}),
						cellStyle: ({
							textAlign: 'right',
						}),
						render: rowData => renderActionButtons(reports.get(rowData.reportId)!),
					},
				]}
				data={Array.from(reports.values())}
				options={{
					selection: true
				}}
				components={{
					Actions: props => renderTableActions(props, reload),
				}}
				localization={{
					toolbar: {
						nRowsSelected: '{0} report(s) selected',
					},
				}}
			/>

			{reportsToDelete.length > 0 && <CustomActionDialogBox
				title={reportsToDelete.length === 1 ? `Delete report?` : `Delete ${reportsToDelete.length} reports?`}
				actionButton={<Button
					variant="contained"
					color={red}
					text="Delete"
					onClick={() => confirmDelete(reload)}
				/>}
				dialogCloseCallback={() => setReportsToDelete([])}
			>
				<DialogContentText>
					Are you sure you want to delete the selected {reportsToDelete.length === 1 ? `report` : `reports`}?
				</DialogContentText>
			</CustomActionDialogBox>}
		</FixedWidthPage>}
	/>;
};
