import { FormikErrors } from 'formik';

export function runFormValidation<T>(values: T, validate: (values: T, errors: FormikErrors<T>) => void) {
	const errors: FormikErrors<T> = {};
	validate(values, errors);
	return errors;
}

export function addFormArrayError<T>(errors: string | string[] | FormikErrors<T>[] | undefined): FormikErrors<T> {
	const arrayErrors = errors as any as FormikErrors<T>[];
	if (!arrayErrors)
		throw 'Errors array has not been initialised.';

	const newest: FormikErrors<T> = {};
	arrayErrors.push(newest);
	return newest;
}

export function validateIsNumber(value: string | number) {
	const stringValue = '' + value;
	return /^-?[0-9]([0-9.]+)?$/.test(stringValue);
}
