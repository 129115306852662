import React from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import { Client as C, useIdentityType } from 'src/services';
import { FormikCheckbox, FormikSelect, FormikTextField } from 'src/components';
import { NetworkSelector } from 'src/components/form';
import { IDeviceNetwork } from './manageDeviceComponent';
import { DeviceType } from 'src/../__generated__/globalTypes';
import Alert from '@material-ui/lab/Alert';

interface ITaitRadioDeviceFormValues {
	dealerId: string | null;
	networkId: string | null;
	radioId: string;
	selCallId: string;
	gpsEnabled: boolean;
	bleEnabled: boolean;
	callRecordingEnabled: boolean;
	manualPollingEnabled: boolean;
}

interface IProps<T> {
	form: FormikProps<T>;
	hasDealerSelection: boolean;
	bluetoothBeaconSets?: {
		id: string;
		name: string;
	}[];
	dealers?: {
		id: string;
		name: string;
	}[];
	networks?: IDeviceNetwork[];
}

export const ManageTaitRadioDeviceComponent = observer(<T extends ITaitRadioDeviceFormValues>(props: IProps<T>) => {
	const network = !!props.form.values.networkId && props.networks?.find(x => x.id === props.form.values.networkId);
	const onTier2Network = network && network.networkType === C.NetworkType.TaitTier2;

	return <>
		<NetworkSelector
			form={props.form}
			deviceType={DeviceType.TAIT_RADIO}
			dealers={props.dealers}
			networks={props.networks}
		/>

		<Field
			name="radioId"
			label="Radio ID"
			type="text"
			component={FormikTextField}
		/>

		{onTier2Network && <Field
			name="selCallId"
			label="SelCall ID"
			type="text"
			component={FormikTextField}
		/>}

		<Field
			name="gpsEnabled"
			label="Use locations received via GPS"
			type="checkbox"
			component={FormikCheckbox}
		/>

		<Field
			name="bleEnabled"
			label="Use locations received via BLE"
			type="checkbox"
			component={FormikCheckbox}
		/>

		<FormikSelect
			name="bluetoothBeaconSetIds"
			label="Bluetooth Beacon Sets"
			form={props.form}
			disabled={!props.form.values.bleEnabled || (props.hasDealerSelection && !props.form.values.dealerId)}
			options={props.bluetoothBeaconSets || []}
			getOptionValue={x => x.id}
			getOptionLabel={x => x.name}
			clearable
			multi
			disableCloseOnSelect
		/>

		<Field
			name="callRecordingEnabled"
			label="Enable call recording"
			type="checkbox"
			component={FormikCheckbox}
		/>

		{props.form.values.callRecordingEnabled && <Alert severity="warning">Call recording may incur additional costs.</Alert>}

		{onTier2Network && <Field
			name="manualPollingEnabled"
			label="Manual polling enabled"
			type="checkbox"
			component={FormikCheckbox}
		/>}
	</>;
});
