import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { match, Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';

import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';

import { Button, CustomAutocomplete, FixedWidthPage, ThingLoader } from 'src/components';

import {
	Client as C,
	HistoryService,
	Service,
	ToasterService,
	UsersService,
	UserGroupService,
	useInjection,
} from 'src/services';

import 'src/app/users/manageUserGroups.scss';
import 'src/app/itemGroupsTables.scss';

export interface Props {
	match: match<{ id: string }>;
}

export const ManageUserGroups = observer((props: Props) => {
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _usersService = useInjection<UsersService>(Service.Users);
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);

	const [saving, setSaving] = useState<boolean>(false);
	const [user, setUser] = useState<C.IUserDto>();
	const [groupOptions, setGroupOptions] = useState<C.IUserGroupDto[]>([]);
	const [existingGroups, setExistingGroups] = useState<C.IUserGroupDto[]>();
	const [newGroups, setNewGroups] = useState<C.IUserGroupDto[]>();

	const load = async (userId: string) => {
		const user = await _usersService.getUser(userId);
		if (!user)
			return null;

		const userGroupsResponse = await _usersService.getUserGroups(user.userId);
		if (!userGroupsResponse)
			return null;

		const allUserGroups = await _userGroupService.getAllUserGroups();
		if (!allUserGroups)
			return null;

		const userGroupOptions = allUserGroups
			.filter(x => x.client.clientId === user.identity.clientId && x.type === C.UserGroupType.UserCreated);

		setUser(user);
		setGroupOptions(userGroupOptions);
		setExistingGroups(userGroupsResponse.userGroups);
		setNewGroups(userGroupsResponse.userGroups);

		return true;
	};

	const removeGroup = (group: C.IUserGroupDto) => {
		setNewGroups(newGroups!.filter(x => x !== group));
	};

	const onChangeSelectedGroupToAdd = (selected: C.IUserGroupDto[] | null) => {
		if (selected == null || selected.length === 0)
			return;

		setNewGroups([ ...newGroups!, selected[0] ]);
	};

	const onSubmit = async () => {
		setSaving(true);

		const userId = user!.userId;

		const addedGroups = newGroups!
			.filter(x => existingGroups!.findIndex(y => y.userGroupId === x.userGroupId) === -1)
			.map(x => x.userGroupId);

		const removedGroups = existingGroups!
			.filter(x => newGroups!.findIndex(y => y.userGroupId === x.userGroupId) === -1)
			.map(x => x.userGroupId);

		const request: C.IUpdateUserUserGroupsByUserIdRequest = {
			addUserGroupIds: addedGroups,
			removeUserGroupIds: removedGroups,
		};

		try {
			await _usersService.modifyUserGroups(userId, request);
			_toasterService.showSuccess('Groups changed successfully.');
			_historyService.history.push(`/app/users/${userId}/edit`);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update assigned groups.');
			setSaving(false);
		}
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={() => <FixedWidthPage
			className="form-page manage-user-groups"
			headingText="Assign User Groups"
			subheadingText={user!.name}
			noContentBackground
		>
			{newGroups!.map(x => {
				return <div key={x.userGroupId} className="content-box user-group-box">
					<Link to={`/app/user-groups/${x.userGroupId}/edit`}>{x.name}</Link>

					{x.type === C.UserGroupType.UserCreated && <IconButton
						className="icon-button-small"
						title="Remove Group"
						onClick={() => removeGroup(x)}
					>
						<ClearIcon />
					</IconButton>}
				</div>;
			})}

			{newGroups!.length === 0 && <div className="content-box user-group-box">
				<div>No groups assigned.</div>
			</div>}

			<div className="content-box user-group-box group-selector-box">
				<CustomAutocomplete
					placeholder="Select a group to assign..."
					options={groupOptions.filter(x =>
						newGroups!.findIndex(y => y.userGroupId === x.userGroupId) === -1)}
					getOptionLabel={x => x.name}
					getOptionValue={x => x}
					onChange={onChangeSelectedGroupToAdd}
					value={[]}
					multi
					outlined
				/>
			</div>

			<Button
				type="submit" variant="contained" color="primary"
				startIcon={<SaveIcon />}
				text="Save Changes"
				loading={saving}
				onClick={onSubmit}
			/>
		</FixedWidthPage>}
	/>;
});
