import { uniq } from 'lodash';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import { executeQueryDealerList, QueryDealerList_dealers } from 'src/graphql/__generated__/queries/queryDealerList';

import {
	Container,
	Client as C,
	AuthenticationService,
	ClientService,
	Service,
} from 'src/services';

export class BillingCodesHelper {
	private _apolloClient = Container.get<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	private _authService = Container.get<AuthenticationService>(Service.Authentication);
	private _clientService = Container.get<ClientService>(Service.Client);

	public async getBillingCodes() : Promise<string[]> {
		let clients: C.IClientDto[] | null = null;
		let dealers: QueryDealerList_dealers[] | null = null;

		let billingCodes: string[] = [];

		if (this._authService.currentAuth.user.identity.type === C.IdentityType.SuperUser) {
			const dealersListQuery = await executeQueryDealerList(this._apolloClient);

			if (dealersListQuery.error || !dealersListQuery.data)
				throw dealersListQuery.error || 'Failed to load dealers.';

			dealers = dealersListQuery.data.dealers;

			if (dealers)
				billingCodes = dealers.map(x => x.billingCode).filter(x => x != null) as string[];
		} else if (this._authService.currentAuth.user.identity.type === C.IdentityType.Dealer) {
			clients = await this._clientService.getAllClients();

			if (clients)
				billingCodes = clients.map(x => x.billingCode).filter(x => x != null) as string[];
		}

		return uniq(billingCodes.filter(x => x != ''))
			.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
	}
}
