import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import { CustomActionDialogBox } from 'src/components/customActionDialogBox';

import {
	Client as C,
} from 'src/services';

interface Props {
	tilesets: C.ITilesetDto[];
	layerSettings: C.IUserTilesetMapLayerSettingDto[];
	onChange: (layerSettings: C.IUserTilesetMapLayerSettingDto[]) => void;
	onClose: () => void;
}

export const SelectLayersDialog = (props: Props) => {

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, tileset: C.ITilesetDto, layerName: string) => {
		if (!tileset.layers)
			return;

		const changes = tileset.layers
			.filter(x => x.name === layerName)
			.map(x => ({
				tilesetMapLayerId: x.tilesetMapLayerId,
				hide: !event.target.checked,
			}));

		props.onChange(changes);
	};

	const renderTilesetSection = (tileset: C.ITilesetDto) => {
		if (!tileset.layers)
			return;

		const sortedLayers = tileset.layers
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

		const layersToRender: { name: string; hidden: boolean }[] = [];

		// If multiple layers have the same name, we treat them as if they are a "single layer".
		// If any layer of a "single-layer" group is marked as hidden, then all of them should be hidden.
		for (const layer of sortedLayers) {
			const layerSettings = props.layerSettings.find(x => x.tilesetMapLayerId === layer.tilesetMapLayerId);

			const previousLayer = layersToRender.length > 0 && layersToRender[layersToRender.length - 1] || undefined;
			if (previousLayer && previousLayer.name == layer.name) {
				if (!previousLayer.hidden && layerSettings?.hide)
					previousLayer.hidden = true;

				continue;
			}

			layersToRender.push({
				name: layer.name,
				hidden: layerSettings?.hide || false,
			});
		}

		return <div key={tileset.tilesetId}>
			<span style={{ fontSize: '18px', color: 'grey' }}>{tileset.name}</span>

			{layersToRender.map(x => <FormControlLabel
				key={x.name}
				label={x.name}
				control={<Checkbox
					checked={!x.hidden}
					onChange={e => handleChange(e, tileset, x.name)}
					name={x.name}
					color="primary"
				/>}
				style={{ display: 'block' }}
			/>)}
		</div>;
	};

	return <CustomActionDialogBox
		title="Toggle Map Layers"
		cancelText="Done"
		dialogCloseCallback={props.onClose}
	>
		{props.tilesets
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
			.map(renderTilesetSection)}

		{props.tilesets.length === 0 && <div>No additional map layers available.</div>}
	</CustomActionDialogBox>;
};
