import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button, ErrorMessagePage, FixedWidthPage, LoadingMessagePage } from 'src/components';
import { useQueryAssetServiceReminderTypeById, QueryAssetServiceReminderTypeById_assetServiceReminderTypeById_reminders, } from 'src/graphql/__generated__/queries/queryAssetServiceReminderTypeById';
import { AssetServiceRemindersTable, AssetServiceReminderTableRow, IUser, IUserGroup } from 'src/components/assetServiceRemindersTable';
import { AssetTelematicsType } from 'src/../__generated__/globalTypes';

import {
	Client as C,
	useIdentityType,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const AssetServiceRemindersList = observer((props: Props) => {
	const identityType = useIdentityType();

	const [assetServiceReminders, setAssetServiceReminders] = React.useState<AssetServiceReminderTableRow[] | null>(null);

	const assetServiceReminderTypeQuery = useQueryAssetServiceReminderTypeById({
		variables: {
			id: props.match.params.id,
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		}
	});

	const assetServiceReminderType = assetServiceReminderTypeQuery.data?.assetServiceReminderTypeById;

	if (assetServiceReminderTypeQuery.loading)
		return <LoadingMessagePage />;

	if (assetServiceReminderTypeQuery.error || !assetServiceReminderType || !assetServiceReminderType.reminders)
		return <ErrorMessagePage />;

	async function refetchAssetServiceReminderType() {
		await assetServiceReminderTypeQuery.refetch();
		setAssetServiceReminders(null);
	}

	if (!assetServiceReminders) {
		const reminders =  assetServiceReminderType.reminders.filter(x => x !== null).map(y => y! as QueryAssetServiceReminderTypeById_assetServiceReminderTypeById_reminders);

		const assetServiceRemindersTableRows: AssetServiceReminderTableRow[] = reminders.map(x => {
			return {
				id: x.id,
				assetServiceReminderType: {
					name: assetServiceReminderType.name,
					dateEnabled: assetServiceReminderType.dateEnabled,
					odometerEnabled: assetServiceReminderType.odometerEnabled,
					engineTimeEnabled: assetServiceReminderType.engineTimeEnabled,
					clientId: assetServiceReminderType.client?.id
				},
				assetId:  x.asset.id,
				assetName: x.asset.name,
				state: x.state,
				nextDateTriggerDate: x.nextDateTriggerDate,
				nextEngineTimeTrigger: x.nextEngineTimeTrigger,
				nextOdometerTrigger: x.nextOdometerTrigger,
				dateDefaultFrequency: x.dateDefaultFrequency,
				engineTimeDefaultFrequency: x.engineTimeDefaultFrequency,
				odometerDefaultFrequency: x.odometerDefaultFrequency,
				odometerReadingValue: x.asset.assetTelematicsCurrentValueAndReadings?.find(x => x.type === AssetTelematicsType.ODOMETER)?.value ?? null,
				engineTimeReadingValue: x.asset.assetTelematicsCurrentValueAndReadings?.find(x => x.type === AssetTelematicsType.ENGINE_TIME)?.value ?? null,
				extraInformation: x.extraInformation,
				users: x.users ? x.users
					.filter(x => x != null)
					.map(x => ({id: x!.id, name: x!.name}))
					: null,
				userGroups: x.userGroups ? x.userGroups
					.filter(x => x != null)
					.map(x => ({id: x!.id, name: x!.name}))
				: null,
			};
		});
		setAssetServiceReminders(assetServiceRemindersTableRows);
	}

	return <FixedWidthPage
			headingText="Asset Service Reminders"
			subheadingText={`Service reminder type: ${assetServiceReminderTypeQuery.data!.assetServiceReminderTypeById!.name}`}
			headingActions={<Button href={`/app/asset-service-reminder-types/${props.match.params.id}/reminders/add`} text="Add Reminder" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
			noContentBackground
		>
			{assetServiceReminders && <AssetServiceRemindersTable
				assetServiceReminders={assetServiceReminders}
				showAssetName={true}
				showAssetServiceReminderType={false}
				showToolbar={true}
				grouping={true}
				refetchData={refetchAssetServiceReminderType}
			/>}
	</FixedWidthPage>;
});
