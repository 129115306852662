import React from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import bind from 'bind-decorator';
import { EventData, MapMouseEvent, LngLat } from 'mapbox-gl';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { first } from 'iter-tools';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, ThingLoader } from 'src/components';
import { MapboxManager } from 'src/app/map/mapboxManager';
import { FloorPlanMapMarkerManager } from 'src/components/georectifier/floorPlanMapMarkersManager';
import { convertFloorPlanXyToLatLng, convertLatLngToXy, convertMapBoxLatLngToXyDto, pixelSize } from 'src/util/latLngConverter';
import { executeQueryPlaceBeacon, QueryPlaceBeacon_beacon } from 'src/graphql/__generated__/queries/queryPlaceBeacon';
import { executeMutationUpdateBeacon } from 'src/graphql/__generated__/mutations/mutationUpdateBeacon';

import {
	Container,
	AuthenticationService,
	FloorPlansService,
	HistoryService,
	Service,
	ToasterService,
} from 'src/services';

import './placeBeacon.css';

interface Props {
	match: match<{ id: string }>;
}

interface IFloorPlanLocation {
	floorPlanId: string;
	x: number;
	y: number;
}

interface State {
	floorPlanLocation: null | IFloorPlanLocation;
	isSaving: boolean;
}

@observer
export class PlaceBeacon extends React.Component<Props, State> {
	private _apolloClient = Container.get<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	private _authenticationService = Container.get<AuthenticationService>(Service.Authentication);
	private _floorPlans = Container.get<FloorPlansService>(Service.FloorPlans);
	private _history = Container.get<HistoryService>(Service.History);
	private _toasterService = Container.get<ToasterService>(Service.Toaster);

	private _mapboxManager?: MapboxManager;
	private _mapMarkerManager: FloorPlanMapMarkerManager;

	constructor(props: any) {
		super(props);

		this.state = {
			floorPlanLocation: null,
			isSaving: false,
		};
	}

	@bind
	private async load(beaconId: string) {
		const queryResult = await executeQueryPlaceBeacon(this._apolloClient, { id: beaconId });
		if (queryResult.error)
			throw queryResult.error;

		const beacon = queryResult.data.beacon;
		if (!beacon)
			return null;

		await when(() => !this._floorPlans.isLoading);

		let floorPlanLocation: IFloorPlanLocation | null = null;

		const floorPlanId = beacon.floorPlan?.id;
		if (floorPlanId && beacon.longitude && beacon.latitude) {
			const floorPlan = this._floorPlans.floorPlans.get(floorPlanId);

			if (floorPlan) {
				const xy = convertLatLngToXy(floorPlan, beacon.latitude, beacon.longitude);
				floorPlanLocation = {
					floorPlanId,
					x: xy[0],
					y: xy[1],
				};
			}
		} else if (this._floorPlans.floorPlans.size > 0) {
			const floorPlan = first(this._floorPlans.floorPlans.values());
			if (!floorPlan)
				throw 'Failed to get floor plan.';

			floorPlanLocation = {
				floorPlanId: floorPlan.floorPlanId,
				x: floorPlan.imageWidth / 2 * pixelSize,
				y: floorPlan.imageHeight / 2 * -pixelSize,
			};
		}

		await new Promise(resolve => {
			this.setState({ floorPlanLocation }, resolve as any);
		});

		return beacon;
	}

	@bind
	private async floorPlanChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
		const floorPlanId = e.target.value;
		if (this.state.floorPlanLocation?.floorPlanId === floorPlanId)
			return;

		const floorPlan = this._floorPlans.floorPlans.get(floorPlanId);
		if (!floorPlan)
			return;

		this.setState({
			floorPlanLocation: {
				floorPlanId,
				x: floorPlan.imageWidth / 2 * pixelSize,
				y: floorPlan.imageHeight / 2 * -pixelSize,
			},
		}, async () => {
			await this.renderBeaconFloorPlan();
			this.updateBeaconMarker();
		});
	}

	private async savePlacement() {
		if (!this.state.floorPlanLocation) {
			this._toasterService.showDanger('You must place the beacon on a floor plan.');
			return;
		}

		const selectedFloorPlanId = this.state.floorPlanLocation?.floorPlanId;
		const selectedFloorPlan = selectedFloorPlanId && this._floorPlans.floorPlans.get(selectedFloorPlanId);
		if (!selectedFloorPlan)
			return;

		try {
			this.setState({ isSaving: true });

			const latLng = convertFloorPlanXyToLatLng(selectedFloorPlan, { x: this.state.floorPlanLocation.x, y: this.state.floorPlanLocation.y });

			await executeMutationUpdateBeacon(this._apolloClient, {
				input: {
					bluetoothBeaconId: this.props.match.params.id,
					floorPlanId: this.state.floorPlanLocation.floorPlanId,
					latitude: latLng.latitude,
					longitude: latLng.longitude,
				},
			});

			this._toasterService.showSuccess('Beacon position saved.');
			this._history.history.push('/app/bluetooth-beacons/list');
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to save position.');
			this.setState({ isSaving: false });
		}
	}

	private async renderBeaconFloorPlan() {
		const selectedFloorPlanId = this.state.floorPlanLocation?.floorPlanId;
		const selectedFloorPlan = selectedFloorPlanId && this._floorPlans.floorPlans.get(selectedFloorPlanId) || undefined;

		if (this._mapboxManager && selectedFloorPlan?.imageUrl) {
			await this._mapboxManager.viewFloorPlan({
				imageUrl: selectedFloorPlan.imageUrl,
				imageWidth: selectedFloorPlan.imageWidth,
				imageHeight: selectedFloorPlan.imageHeight,
			} as any, {
				padding: 20,
			});
		}
	}

	private updateBeaconMarker() {
		this._mapMarkerManager.clearMarkers();

		if (!this.state.floorPlanLocation)
			return;

		this._mapMarkerManager.addMarker(
			0,
			[this.state.floorPlanLocation.x * pixelSize, this.state.floorPlanLocation.y * -pixelSize],
			true,
			(div) => { div.className = 'map-icon icon-beacon icon draggable'; }
		);

	}

	@bind
	private async mapRefChanged(element: HTMLDivElement | null) {
		if (!element)
			return;

		if (this._mapboxManager)
			return;

		this._mapboxManager = new MapboxManager(element, { onClick: this.onMapClick, metric: this._authenticationService.currentAuth.user.usesMetric });
		this._mapMarkerManager = new FloorPlanMapMarkerManager(this._mapboxManager.map);
		this._mapMarkerManager.onMarkerDragEnd(this.onMarkerDragEnd);

		await this.renderBeaconFloorPlan();
		this.updateBeaconMarker();
	}

	@bind
	onMapClick(e: MapMouseEvent & EventData) {
		if (!this.state.floorPlanLocation)
			return;

		const floorPlanLocation: IFloorPlanLocation = {
			...this.state.floorPlanLocation,
			x: e.lngLat.lng / pixelSize,
			y: e.lngLat.lat / -pixelSize,
		};

		this.setState({
			floorPlanLocation,
		}, () => this.updateBeaconMarker());
	}

	@bind
	onMarkerDragEnd(id: number, lngLat: LngLat) {
		const imagePoint = convertMapBoxLatLngToXyDto(lngLat);

		if (this.state.floorPlanLocation?.floorPlanId) {
			this.setState({
				floorPlanLocation: {
					floorPlanId: this.state.floorPlanLocation?.floorPlanId!,
					x: imagePoint.x,
					y: imagePoint.y,
				},
			});
		}
	}
	render() {
		const selectedFloorPlanId = this.state.floorPlanLocation?.floorPlanId;
		const selectedFloorPlan = selectedFloorPlanId && this._floorPlans.floorPlans.get(selectedFloorPlanId);

		return <ThingLoader
			id={this.props.match.params.id}
			load={this.load}
			render={(beacon: QueryPlaceBeacon_beacon) => <FixedWidthPage
				className="place-beacon"
				headingText="Place Beacon"
				subheadingText={beacon.name}
			>
				{this._floorPlans.floorPlans.size === 0 && <Alert severity="error">You do not have access to any floor plans to place a beacon onto.</Alert>}

				<TextField
					select
					label="Floor Plan"
					value={selectedFloorPlanId || undefined}
					disabled={this._floorPlans.floorPlans.size === 0}
					onChange={this.floorPlanChanged}
					fullWidth
					SelectProps={{
						native: true,
					}}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="filled"
				>
					{Array.from(this._floorPlans.floorPlans.values()).map(option => (
						<option key={option.floorPlanId} value={option.floorPlanId}>{option.name}</option>
					))}
				</TextField>

				{selectedFloorPlan ? <div className="map" ref={this.mapRefChanged} /> : null}

				<Button
					type="submit"
					variant="contained"
					color="primary"
					disabled={!this.state.floorPlanLocation}
					onClick={() => this.savePlacement()}
					loading={this.state.isSaving}
					startIcon={<SaveIcon />}
					text="Save Position"
				/>
			</FixedWidthPage>}
		/>;
	}
}
