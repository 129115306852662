import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { Field, FormikProps, FieldArray, ArrayHelpers } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import styled from '@emotion/styled';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { Button, FormikTextField, FormikSelect, FormikCheckbox } from 'src/components';
import { getDeviceIoNameOptions, getDeviceIoActionOptions, IDeviceIoConfiguration } from './deviceIoConfigurationsModelsAndHelperMethods';
import { IDevicesIoFormValues } from './ioConfigurationsList';

const IoConfigurationsTableAndButtons = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column',
});

const IoConfigurationsAddButton = styled(Button)({
	alignSelf: 'flex-end',
	marginTop: '10px',
});

const NotConfiguredContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column',
	alignItems: 'center',
});

const IoConfigurationsTable = styled.table({
	tableLayout: 'fixed',
	width: '100%',
	'th': {
		textAlign: 'left',
		paddingBottom: '10px',
	},
	'tbody tr': {
		verticalAlign: 'top',
		height: '65px', // Has to be specified or errors will move rows underneath.
	},
	'td': {
		paddingRight: '10px',
	},
	'td:first-of-type, td:last-of-type': {
		marginTop: '-5px', // Makes sure the isEnabled checkbox and delete IconButton are vertically centered with the inputs.
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
	'label': {
		marginRight: '0px',
	},
	'th:first-of-type, th:last-of-type': {
		width: '80px',
		overflow: 'unset'
	},
	'.MuiFilledInput-input': {
		padding: '10px 12px 10px !important',
	},
	'.MuiAutocomplete-inputRoot, .MuiFilledInput-multiline': {
		padding: '0px 0px 0px 0px !important', // Makes sure there is no extra padding on inputs with no titles.
	},
});

export interface Props {
	deviceIndex: number;
	formikProps: FormikProps<IDevicesIoFormValues>;
}

export const DeviceIoConfigurationsTable = observer((props: Props) => {
	const device = props.formikProps.values.devices[props.deviceIndex];
	const modelType = device?.modelType;

	if (!modelType) {
		return <div className="content-box">
			<NotConfiguredContainer>
				<p>Model type is not configured for this device.</p>
				<Button text="Edit device" variant="contained" color="primary" href={`/app/devices/${device.id}/edit`}/>
			</NotConfiguredContainer>
		</div>;
	}

	const ioNameOptions = getDeviceIoNameOptions(modelType!);

	const addNewConfigurationValue = (arrayHelpers: ArrayHelpers) => {
		const newConfigurationRow : IDeviceIoConfiguration = {
			name: '',
			id: '',
			ioName: ioNameOptions[0].ioName,
			isEnabled: true,
			onAction: null,
			offAction: null,
		};

		arrayHelpers.push(newConfigurationRow);
	};

	const onActionInputChange = (index: number) => {
		props.formikProps.setFieldTouched(`devices[${props.deviceIndex}].ioConfigurations[${index}].onAction`, true);
		props.formikProps.setFieldTouched(`devices[${props.deviceIndex}].ioConfigurations[${index}].offAction`, true);
	};

	const renderDeviceIoTable = (arrayHelpers: ArrayHelpers) => {
		if (device.ioConfigurations.length == 0) {
			return <NotConfiguredContainer>
				<p>IO currently not configured for this device.</p>
			</NotConfiguredContainer>;
		}

		return <IoConfigurationsTable>
			<thead>
				<tr key="header">
					<th>Enabled</th>
					<th>Name</th>
					<th>IO</th>
					<th>Action On</th>
					<th>Action Off</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{renderDeviceIoTableRows(arrayHelpers)}
			</tbody>
		</IoConfigurationsTable>;
	};

	const renderDeviceIoTableRows = (arrayHelpers: ArrayHelpers) => {
		return device.ioConfigurations.map((_, index) => <tr key={index}>
			<td>
				<Field
					name={`devices[${props.deviceIndex}].ioConfigurations[${index}].isEnabled`}
					type="checkbox"
					component={FormikCheckbox}
				/>
			</td>

			<td>
				<Field
					name={`devices[${props.deviceIndex}].ioConfigurations[${index}].name`}
					type="text"
					placeholder="Enter name..."
					component={FormikTextField}
				/>
			</td>

			<td>
				<FormikSelect
					name={`devices[${props.deviceIndex}].ioConfigurations[${index}].ioName`}
					placeholder="Select an IO..."
					options={ioNameOptions}
					form={props.formikProps}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.ioName}
					required
				/>
			</td>

			<td>
				<FormikSelect
					name={`devices[${props.deviceIndex}].ioConfigurations[${index}].onAction`}
					placeholder="Select an action..."
					options={getDeviceIoActionOptions()}
					onBlur={() => onActionInputChange(index)}
					form={props.formikProps}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.action}
				/>
			</td>

			<td>
				<FormikSelect
					name={`devices[${props.deviceIndex}].ioConfigurations[${index}].offAction`}
					placeholder="Select an action..."
					options={getDeviceIoActionOptions()}
					onBlur={() => onActionInputChange(index)}
					form={props.formikProps}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.action}
				/>
			</td>

			<td>
				<IconButton
					title={`Delete Device IO Configuration`}
					onClick={() => arrayHelpers.remove(index)}
				>
					<CloseIcon/>
				</IconButton>
			</td>
		</tr>);
	};

	return <Box>
		<h2>{device.name}</h2>

		<FieldArray
			name={`devices[${props.deviceIndex}].ioConfigurations`}
			key={props.formikProps.values.devices[props.deviceIndex].id}
			render={(arrayHelpers: ArrayHelpers) => <IoConfigurationsTableAndButtons>
				<div className="content-box">
					{renderDeviceIoTable(arrayHelpers)}
				</div>
				<IoConfigurationsAddButton startIcon={<AddIcon/>} text="Add" onClick={() => addNewConfigurationValue(arrayHelpers)} variant="contained" color="primary" />
			</IoConfigurationsTableAndButtons>}
		/>
	</Box>;
});
