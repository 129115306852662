import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Input, FormControl, InputLabel, FilledInput } from '@material-ui/core';
import { InputProps } from '@material-ui/core/Input';
import ScheduleIcon from '@material-ui/icons/Schedule';
import moment from 'moment-timezone';

import classNames from 'classnames';

import { DateTimeRange } from './dateTimeRange';
import { DateTimeRangePickerDialog } from './dateTimeRangePickerDialog';

import './dateTimeRangePicker.scss';

type DateTimeRangePickerVariant = 'filled' | 'unfilled' | 'text';

export interface DateTimeRangePickerProps {
	className?: string;
	fullWidth?: boolean;
	label?: string;
	value: DateTimeRange;
	onChange: (value: DateTimeRange) => void;
	disabled?: boolean;
	variant?: DateTimeRangePickerVariant;
	maxDuration?: moment.Duration;
}

export const DateTimeRangePicker = (props: DateTimeRangePickerProps) => {
	const [showDialog, setShowDialog] = useState<boolean>(false);

	const openDialog = () => {
		setShowDialog(true);
	};

	const closeDialog = () => {
		setShowDialog(false);
	};

	const onDialogSubmit = (value: DateTimeRange) => {
		closeDialog();
		props.onChange(value);
	};

	const {
		label,
		value,
		disabled,
	} = props;

	const variant: DateTimeRangePickerVariant = props.variant == undefined ? 'filled' : props.variant;

	let inputText = `${value.start.format('DD/MM/YYYY h:mm A')} - `;
	if (value.start.isSame(value.end, 'day'))
		inputText += value.end.format('h:mm A');
	else
		inputText += value.end.format('DD/MM/YYYY h:mm A');

	const inputProps: InputProps = {
		value: inputText,
		onClick: !disabled ? openDialog : undefined,
		disabled: disabled,
	};

	const adornment = <IconButton
		className="icon-button-small adornment"
		disabled={disabled}
		onClick={openDialog}
	>
		<ScheduleIcon />
	</IconButton>;

	return <>
		<FormControl
			className={classNames('date-time-range-picker', props.className, { disabled: props.disabled })}
			fullWidth={props.fullWidth}
		>
			{label && <InputLabel className={classNames({ 'filled-input__label': variant === 'filled' })}>{label}</InputLabel>}

			{(variant == undefined || variant === 'filled') && <FilledInput
				{...inputProps}
			/>}

			{variant === 'unfilled' && <Input
				{...inputProps}
				startAdornment={adornment}
			/>}

			{variant === 'text' && <div
				onClick={disabled ? undefined : inputProps.onClick}
				className="text-variant"
			>
				{adornment}
				<div className="text-container">{inputProps.value as string}</div>
			</div>}
		</FormControl>

		{showDialog && <DateTimeRangePickerDialog
			value={value}
			onSubmit={onDialogSubmit}
			onCancel={closeDialog}
			maxDuration={props.maxDuration}
		/>}
	</>;
};
