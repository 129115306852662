import React from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import { FormikCheckbox, FormikSelect, FormikTextField } from 'src/components';
import { NetworkSelector } from 'src/components/form';
import { IDeviceNetwork } from './manageDeviceComponent';
import { DeviceType } from 'src/../__generated__/globalTypes';
import Alert from '@material-ui/lab/Alert';

interface IMobilePhoneDeviceFormValues {
	dealerId: string | null;
	networkId: string | null;
	callRecordingEnabled: boolean;
	chatterPttUserId: string;
}

interface IProps<T> {
	form: FormikProps<T>;
	hasDealerSelection: boolean;
	bluetoothBeaconSets?: {
		id: string;
		name: string;
	}[];
	dealers?: {
		id: string;
		name: string;
	}[];
	networks?: IDeviceNetwork[];
}

export const ManageMobilePhoneDeviceComponent = observer(<T extends IMobilePhoneDeviceFormValues>(props: IProps<T>) => {
	return <>
		<NetworkSelector
			form={props.form}
			deviceType={DeviceType.MOBILE_PHONE}
			dealers={props.dealers}
			networks={props.networks}
		/>

		<FormikSelect
			name="bluetoothBeaconSetIds"
			label="Bluetooth Beacon Sets"
			form={props.form}
			disabled={props.hasDealerSelection && !props.form.values.dealerId}
			options={props.bluetoothBeaconSets || []}
			getOptionValue={x => x.id}
			getOptionLabel={x => x.name}
			clearable
			multi
			disableCloseOnSelect
		/>

		<Field
			name="callRecordingEnabled"
			label="Enable call recording"
			type="checkbox"
			component={FormikCheckbox}
		/>

		{props.form.values.callRecordingEnabled && <>
			<Alert severity="warning">Call recording may incur additional costs.</Alert>

			<Field
				name="chatterPttUserId"
				label="ChatterPTT User ID"
				type="text"
				component={FormikTextField}
			/>
		</>}
	</>;
});
