import React from 'react';
import { FormikProps, getIn } from 'formik';
import styled from '@emotion/styled';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';

import { IAssetServiceReminderUserOrUserGroup, AssetServiceReminderUserOrUserGroupAlertState } from '../app/assetServiceReminders/assetServiceRemindersModelsAndHelperMethods';

export interface FormikUserCheckboxSelectorProps<FormikValuesType> {
	name: string;
	className?: string;
	label?: string;
	form: FormikProps<FormikValuesType>;
	disabled?: boolean;
	isLoading?: boolean;
	required?: boolean;
	options: IAssetServiceReminderUserOrUserGroup[];
	onChange?: (option: IAssetServiceReminderUserOrUserGroup) => any;
	onBlur?: () => void;
}

const AssetServiceRemindersUsersOrUserGroupsScrollableBox = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	border: '1px solid #e0e0e0',
	borderRadius: '5px',
	overflow: 'hidden',
	overflowY: 'scroll',
	height: '300px',
	paddingLeft: '15px',
	marginTop: '10px',
	backgroundColor: 'rgba(0, 0, 0, 0.09)'
});

const StyledError = styled('p') ({
	color: '#f44336',
	marginLeft: '14px',
});

const UsersOrUserGroupsToAlertLabel = styled('label') ({
	marginTop: '10px',
	fontSize: '12px',
});

export function FormikAssetServiceReminderUserOrUserGroupsCheckboxSelector<FormikValuesType>(props: FormikUserCheckboxSelectorProps<FormikValuesType>) {
	const onBlur = () => {

		if (props.onBlur)
			props.onBlur();

		props.form.setFieldTouched(props.name, true);
	};

	const {
		name,
		form: { touched, errors, values },
	} = props;

	const fieldTouched = getIn(touched, name) as boolean | undefined;
	const fieldError = getIn(errors, name) as string | undefined;
	const userOrUserGroupsValues = getIn(values, name) as IAssetServiceReminderUserOrUserGroup[];

	return <div className="formik-user-checkbox-selector">
		<AssetServiceRemindersUsersOrUserGroupsScrollableBox>
			<UsersOrUserGroupsToAlertLabel className={!props.disabled ? 'MuiFormLabel-root' : '.MuiFormLabel-root.Mui-disabled'}>{props.label ?? 'Users To Alert'}</UsersOrUserGroupsToAlertLabel>
			{props.options
				.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
				.map((x, _) => {
					let index = userOrUserGroupsValues.findIndex(y => y.id === x.id);
					const value: IAssetServiceReminderUserOrUserGroup = {
						id: x.id,
						name: x.name,
						state: index >= 0 ? userOrUserGroupsValues[index].state : x.state,
					};

					return <>
						<FormControlLabel
							key={x.id}
							control={<Checkbox
								indeterminate={value && value.state === AssetServiceReminderUserOrUserGroupAlertState.MixedAlertingForSelectedReminders}
								checked={value && value.state === AssetServiceReminderUserOrUserGroupAlertState.AlertForSelectedReminders}
								onClick={() => {
									// If this is not a in the current values index will be -1, update index to be the next index in the values array.
									if (index === -1)
										index = userOrUserGroupsValues.length;

									if (value.state === AssetServiceReminderUserOrUserGroupAlertState.MixedAlertingForSelectedReminders || value.state === AssetServiceReminderUserOrUserGroupAlertState.DoNotAlertForSelectedReminders)
										value.state = AssetServiceReminderUserOrUserGroupAlertState.AlertForSelectedReminders;
									else
										value.state = AssetServiceReminderUserOrUserGroupAlertState.DoNotAlertForSelectedReminders;

									props.form.setFieldValue(`${props.name}[${index}]`, value);
									props.form.setFieldTouched(`${props.name}[${index}]`, true);

									if (props.onChange)
										props.onChange(value);
								}}
								onBlur={onBlur}
								color="primary"
							/>}
							label={x.name}
						/>
					</>;
				})}
		</AssetServiceRemindersUsersOrUserGroupsScrollableBox>
		{fieldTouched && !!fieldError && <StyledError>{fieldError}</StyledError>}
	</div>;
}
