import { injectable } from 'inversify';
import moment from 'moment-timezone';
import { makeObservable, observable } from 'mobx';

interface CefSharp {
	bindObjectAsync(name: string): Promise<void>;
}

interface CefSharp_ViVideoController {
	playHistory: (cameraId: number, time: Date) => Promise<void>;
}

declare global {
	interface Window {
		cefSharp?: CefSharp;
		cefSharp_viVideoController?: CefSharp_ViVideoController;
	}
}

@injectable()
export class CefSharpService {
	@observable canPlayHistory = false;

	constructor() {
		makeObservable(this);
		this.setup();
	}

	async setup() {
		if (!window.cefSharp)
			return;

		await this.tryBindObject(window.cefSharp, 'cefSharp_viVideoController');
		if (window.cefSharp_viVideoController)
			this.canPlayHistory = true;
	}

	async tryBindObject(cefSharp: CefSharp, name: string) {
		try {
			await cefSharp.bindObjectAsync(name);
		} catch (e) {
			console.error(`Failed to bind CEF object: ${name}`);
		}
	}

	async playHistory(cameraId: number, time: moment.Moment) {
		if (!window.cefSharp_viVideoController)
			return;

		window.cefSharp_viVideoController.playHistory(cameraId, time.toDate());
	}

}
