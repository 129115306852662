import React from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import classNames from 'classnames';
import moment from 'moment-timezone';

import HistoryIcon from '@material-ui/icons/History';

import { FixedWidthPage, MessagePage, Button, PopoverMenu, PopoverMenuItem, ErrorMessagePage, LoadingMessagePage  } from 'src/components';

import { longDateTimeFormat } from 'src/util/dateTimeFormats';
import { useQueryEmergenciesForEmergencyList } from 'src/graphql/__generated__/queries/queryEmergenciesForEmergencyList';
import { EmergencyStatus } from 'src/../__generated__/globalTypes';
import { getEventTime } from 'src/util/eventHelpers';

import {
	AuthenticationService,
	Service,
	useInjection,
} from 'src/services';
import { getPrettyName } from 'src/util';

export const EmergencyList = () => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const emergenciesListQuery = useQueryEmergenciesForEmergencyList({
		variables: {
			emergencyStatus: EmergencyStatus.NEW,
		}
	});

	if (emergenciesListQuery.loading)
		return <LoadingMessagePage />;

	if (emergenciesListQuery.error || !emergenciesListQuery.data?.emergencies)
		return <ErrorMessagePage />;

	const emergencies = emergenciesListQuery.data.emergencies.sort((a, b) => moment(b.generatedAt).valueOf() - moment(a.generatedAt).valueOf());

	const renderActionMenu = (emergencyId: string, assetId: string) => {
		const options = [
			<PopoverMenuItem
				key="details"
				text="Details/Resolve"
				href={`/app/emergencies/${emergencyId}/details`}
			/>
		];

		if (_authenticationService.currentAuth.permissions.general.viewAssetLocations) {
			options.push(<PopoverMenuItem
				key="view-current-location"
				text="View Current Location"
				href={`/app/map?view=asset&assetId=${assetId}`}
			/>);
		}

		return options;
	};

	return <FixedWidthPage
		headingText="Alerts & Emergencies"
		headingActions={<Button href="/app/emergencies/history/list" startIcon={<HistoryIcon />} text="View History" variant="outlined" color="primary" />}
		noContentBackground
		contentClassName={classNames({ 'flex-fill-no-overflow': emergencies.length === 0 })}
	>
		{emergencies.length > 0 && <table className="card-table">
			<thead>
				<tr>
					<th>Asset Name</th>
					<th>Type</th>
					<th>Time</th>
					<th>IO configuration</th>
					<th></th>
				</tr>
			</thead>

			<tbody>
				{emergencies.map(x =>
					<tr key={x.id} className="content-box">
						<td>
							<div>{x.asset.name}</div>
						</td>
						<td>
							<div>{getPrettyName(x.type)}</div>
						</td>
						<td>
							<div>{moment(getEventTime(x)).tz(_authenticationService.currentAuth.user.timeZone).format(longDateTimeFormat)}</div>
						</td>
						<td>
							{x.deviceIoConfiguration ? <div>{x.deviceIoConfiguration.name}</div> : <></>}
						</td>
						<td className="actions">
							<PopoverMenu
								renderOptions={() => renderActionMenu(x.id, x.asset.id)}
							/>
						</td>
					</tr>
				)}
			</tbody>
		</table>}

		{emergencies.length === 0 && <MessagePage
			icon={<DoneAllIcon fontSize="large" />}
			title="No unresolved emergencies."
		/>}
	</FixedWidthPage>;
};
