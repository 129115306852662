import React from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import { FormikSelect, FormikTextField } from 'src/components';

import { ATrackDeviceModelType } from 'src/../__generated__/globalTypes';

const atrackModelTypeOptions = [
	{ value: ATrackDeviceModelType.AK1, label: 'AK1' },
	{ value: ATrackDeviceModelType.AK7, label: 'AK7' },
	{ value: ATrackDeviceModelType.AK7V, label: 'AK7V' },
	{ value: ATrackDeviceModelType.AL7, label: 'AL7' },
];

interface IATrackDeviceFormValues {
	atrackModelType: ATrackDeviceModelType | null;
	imei: string;
	phoneNumber: string;
}

interface IProps<T> {
	form: FormikProps<T>;
}

export const ManageATrackDeviceComponent = observer(<T extends IATrackDeviceFormValues>(props: IProps<T>) => {
	return <>
		<FormikSelect
			name="atrackModelType"
			label="Model Type"
			form={props.form}
			options={atrackModelTypeOptions}
			getOptionValue={x => x.value}
			getOptionLabel={x => x.label}
			required
		/>

		<Field
			name="imei"
			label="IMEI"
			type="text"
			component={FormikTextField}
			required
		/>

		<Field
			name="phoneNumber"
			label="Phone Number"
			type="text"
			component={FormikTextField}
		/>
	</>;
});
