import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';
import { ManageScheduledReportComponent } from './manageScheduledReportComponent';

import {
	ReportService,
	Client as C,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditScheduledReport = observer((props: Props) => {
	const _reportService = useInjection<ReportService>(Service.Report);

	const load = async (scheduledReportId: string): Promise<C.IScheduledReportParametersDto | null> => {
		const scheduledReport = await _reportService.getScheduledReportParametersId(scheduledReportId);

		if (!scheduledReport)
			return null;

		return scheduledReport;
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(scheduledReportParameters: C.IScheduledReportParametersDto) => <ManageScheduledReportComponent
			scheduledReportParameters={scheduledReportParameters}
		/>}
	/>;
});
