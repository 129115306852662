import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';
import { ToasterService } from './toasterService';

@injectable()
export class ClientService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.Toaster) private _toasterService: ToasterService,
	) {

	}

	async addClient(request: C.IAddClientRequest): Promise<C.IClientDto> {
		return await this.client.addClient(new C.AddClientRequest(request));
	}

	async updateClient(clientId: string, request: C.IUpdateClientRequest): Promise<C.IClientDto> {
		return await this.client.updateClientById(clientId, new C.UpdateClientRequest(request));
	}

	async getClient(clientId: string): Promise<C.IClientDto | null> {
		try {
			return await this.client.getClientById(clientId);
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load client.');
		}

		return null;
	}

	async getAllClients(): Promise<C.IClientDto[] | null> {
		try {
			return await this.client.listClients();
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load clients.');
		}

		return null;
	}

	async getClientOverview(clientId: string): Promise<C.IClientOverview | null> {
		try {
			return await this.client.getClientOverviewById(clientId);
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load client.');
		}

		return null;
	}
}
