import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class DeviceConfigurationService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
	) {

	}

	async getAllDeviceConfigurations() : Promise<C.DeviceConfigurationDto[]> {
		return this.client.listDeviceConfigurations();
	}

	async getDeviceConfigurationById(deviceConfigurationId: string) : Promise<C.DeviceConfigurationDto> {
		return this.client.getDeviceConfigurationById(deviceConfigurationId);
	}

	async addDeviceConfiguration(request: C.IAddDeviceConfigurationRequest): Promise<C.DeviceConfigurationDto> {
		return this.client.addDeviceConfiguration(new C.AddDeviceConfigurationRequest(request));
	}

	async addPendingDeviceConfigurationUpdate(request: C.IAddPendingDeviceConfigurationUpdateRequest) {
		return this.client.addPendingDeviceConfigurationUpdate(new C.AddPendingDeviceConfigurationUpdateRequest(request));
	}

	async downloadDeviceConfiguration(configurationId: string) {
		return this.client.downloadDeviceConfigurationFileById(configurationId);
	}
}
