import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, Form, Field, FormikErrors } from 'formik';
import { css } from '@emotion/css';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { throttle } from 'lodash';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField, FormikSelect, FormikCheckbox } from 'src/components';
import { runFormValidation } from 'src/util';

import { renderMapMarkerIconOption, mapMarkerIconOptions } from './assetTypesHelpers';
import { IconPreview } from './iconPreview';

const previewBackgroundImage = require('src/app/resources/EmptyMapImage.png');

import {
	Client as C,
	AssetTypesService,
	AuthenticationService,
	HistoryService,
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

const iconInputIconSelectedStyle = css`
	position: relative;
`;

const iconInputIconNotSelectedStyle = css`
	width: 100%;
`;

const iconContainerStyle = css`
	display: flex !important;
	flex-direction: column;
`;

const labelStyles = css`
	display: flex;
	flex-direction: row;
	margin-top: 10px;

	span {
		margin-left: auto;
		margin-right: 10px;
		justify-content: flex-end;

		font-size: 1rem;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		font-weight: 400;
		line-height: 1.5;
		letter-spacing: 0.00938em;
	}

	input {
		justify-content: flex-end;
	}
`;

const backgroundImageContainer = css`
	background-image: url(${previewBackgroundImage});
	background-position: 50% 50%;
	height: 250px;
	display: flex !important;
	align-items: center;
	justify-content: center;
`;

interface ManageAssetTypeFormValues {
	name: string;
	useCustomMapMarker: boolean;
	icon: string;
	iconColor: string;
	iconSize: number;
	mapMarkerColor: string;
	mapMarkerSize: number;
	clientId: string;
}

const validateForm = (values: ManageAssetTypeFormValues, errors: FormikErrors<ManageAssetTypeFormValues>) => {
	if (!values.name)
		errors.name = 'Asset type name is required.';

	if (!values.clientId)
		errors.clientId = 'Client is required.';
}

interface Props {
	assetType?: C.IAssetTypeDto | null;
	clients?: C.IClientDto[];
}

export const ManageAssetTypesComponent = observer((props: Props) => {
	const _assetTypeService = useInjection<AssetTypesService>(Service.AssetType);
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const onSubmit = async (values: ManageAssetTypeFormValues, { setSubmitting }: FormikHelpers<ManageAssetTypeFormValues>) => {
		const existingAssetType = props.assetType;

		try {
			if (existingAssetType) {
				const updateRequest: C.IUpdateAssetTypeRequest = {
					name: existingAssetType.name === values.name ? null : values.name,
					useCustomMapMarker: existingAssetType.useCustomMapMarker === values.useCustomMapMarker ? null : values.useCustomMapMarker,
					icon: existingAssetType.icon === values.icon ? undefined : values.icon as C.MapMarkerIcon,
					iconColor: existingAssetType.iconColor === values.iconColor ? null : values.iconColor,
					iconSize: existingAssetType.iconSize === values.iconSize ? null : values.iconSize,
					mapMarkerColor: existingAssetType.mapMarkerColor === values.mapMarkerColor ? null : values.mapMarkerColor,
					mapMarkerSize: existingAssetType.mapMarkerSize === values.mapMarkerSize ? null : values.mapMarkerSize,
				};

				await _assetTypeService.updateAssetType(existingAssetType.assetTypeId, updateRequest);
			} else {
				const addRequest: C.IAddAssetTypeRequest = {
					name: values.name,
					useCustomMapMarker: values.useCustomMapMarker,
					icon: values.icon as C.MapMarkerIcon,
					iconColor: values.useCustomMapMarker ? values.iconColor : null,
					iconSize: values.useCustomMapMarker ? values.iconSize : null,
					mapMarkerColor: values.useCustomMapMarker ? values.mapMarkerColor : null,
					mapMarkerSize: values.useCustomMapMarker ? values.mapMarkerSize : null,
					clientId: values.clientId,
				};

				await _assetTypeService.addAssetType(addRequest);
			}

			_toasterService.showSuccess(`Asset type ${existingAssetType ? 'updated' : 'added'}.`);
			_historyService.history.push('/app/asset-types/list');
		} catch (err) {
			_toasterService.handleWithToast(err);
			setSubmitting(false);
		}
	};

	const onChangeIconColor = (formikProps: FormikProps<ManageAssetTypeFormValues>, color: string) => {
		formikProps.setFieldValue('iconColor', color);
	};

	const onChangeIconSize = (formikProps: FormikProps<ManageAssetTypeFormValues>, value: number | number[]) => {
		formikProps.setFieldValue('iconSize', Array.isArray(value) ? value[0] : value);
	};

	const onChangeBackgroundColor = (formikProps: FormikProps<ManageAssetTypeFormValues>, color: string) => {
		formikProps.setFieldValue('mapMarkerColor', color);
	};

	const onChangeBackgroundSize = (formikProps: FormikProps<ManageAssetTypeFormValues>, value: number | number[]) => {
		formikProps.setFieldValue('mapMarkerSize', Array.isArray(value) ? value[0] : value);
	};

	const iconColorChangeThrottle = useCallback(throttle(onChangeIconColor, 50), []);
	const backgroundColorChangeThrottle = useCallback(throttle(onChangeBackgroundColor, 50), []);

	const renderIconAndColorInputs = (formikProps: FormikProps<ManageAssetTypeFormValues>) => {
		const iconType = formikProps.values.icon as C.MapMarkerIcon;

		const iconOptions = mapMarkerIconOptions.filter(x => x.asset);

		return <div className={iconContainerStyle}>
			<div className={iconType !== C.MapMarkerIcon.None ? iconInputIconSelectedStyle : iconInputIconNotSelectedStyle}>
				<FormikSelect
					name="icon"
					label="Icon"
					placeholder="Select an icon..."
					options={iconOptions}
					form={formikProps}
					getOptionLabel={option => option.label}
					renderOption={renderMapMarkerIconOption}
					getOptionValue={option => option.MapMarkerIcon}
				/>

				<div>
					{(iconType && iconType !== C.MapMarkerIcon.None) && <div>
						<div className={labelStyles}>
							<Typography gutterBottom>
								Icon size:
							</Typography>

							<span>Icon colour:</span>

							<input
								type="color"
								value={formikProps.values.iconColor}
								name="iconColor"
								onChange={e => iconColorChangeThrottle(formikProps, e.target.value)}
							/>
						</div>

						<Slider
							name="iconSize"
							onChange={(_, value) => onChangeIconSize(formikProps, value)}
							value={formikProps.values.iconSize}
							valueLabelDisplay="auto"
							step={2}
							marks
							min={9}
							max={69}
						/>
					</div>}

					<div className={labelStyles}>
						<Typography gutterBottom>
							Background size:
						</Typography>

						<span>Background colour: </span>

						<input
							type="color"
							defaultValue={formikProps.values.mapMarkerColor}
							name="mapMarkerColor"
							onChange={e => backgroundColorChangeThrottle(formikProps, e.target.value)}
						/>
					</div>

					<Slider
						name="mapMarkerSize"
						onChange={(_, value) => onChangeBackgroundSize(formikProps, value)}
						value={formikProps.values.mapMarkerSize}
						valueLabelDisplay="auto"
						step={2}
						marks
						min={9}
						max={69}
					/>
				</div>
			</div>
		</div>;
	};

	const clientOptions: C.IClientDto[] = useMemo(() => [
		{ clientId: 'none', name: 'None' },
		...props.clients!,
	], props.clients);

	const clientId = _authService.currentAuth.user.identity.type === C.IdentityType.Client ? _authService.currentAuth.user.identity.clientId! : 'none';

	const initialFormValues: ManageAssetTypeFormValues = {
		name: props.assetType?.name || '',
		useCustomMapMarker: props.assetType?.useCustomMapMarker ?? false,
		clientId: props.assetType?.clientId || clientId,
		icon: props.assetType?.icon || C.MapMarkerIcon.None,
		iconColor: props.assetType?.iconColor || '#ffffff',
		iconSize: props.assetType?.iconSize || 11,
		mapMarkerColor: props.assetType?.mapMarkerColor || '#5185f3',
		mapMarkerSize: props.assetType?.mapMarkerSize || 19,
	};

	const addingNew = !props.assetType;

	return <FixedWidthPage
		className="form-page"
		headingText={addingNew ? 'Add Asset Type' : 'Edit Asset Type'}
		pageItemId={props.assetType?.assetTypeId}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageAssetTypeFormValues>) => <Form className="formik-form">
				<Field
					name="name"
					label="Asset Type Name"
					component={FormikTextField}
				/>

				{_authService.currentAuth.user.identity.type !== C.IdentityType.Client && <FormikSelect
					name="clientId"
					label="Client"
					placeholder="Select a client..."
					options={clientOptions!!}
					form={formikProps}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.clientId}
					disabled={!!props.assetType}
				/>}

				<Field
					name="useCustomMapMarker"
					label="Use custom map marker"
					component={FormikCheckbox}
				/>

				{formikProps.values.useCustomMapMarker === true && renderIconAndColorInputs(formikProps)}

				<h2>Preview:</h2>
				<div className={backgroundImageContainer}>
					<IconPreview
						useCustomIcon={formikProps.values.useCustomMapMarker}
						iconShape={formikProps.values.icon as C.MapMarkerIcon}
						iconColor={formikProps.values.iconColor}
						iconSize={formikProps.values.iconSize}
						backgroundColor={formikProps.values.mapMarkerColor}
						backgroundSize={formikProps.values.mapMarkerSize}
					/>
				</div>

				<Button
					type="submit" variant="contained" color="primary"
					loading={formikProps.isSubmitting}
					startIcon={addingNew ? null : <SaveIcon />}
					text={addingNew ? 'Add Asset Type' : 'Save Changes'}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
