import React from 'react';
import { observer } from 'mobx-react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core';
import { red, green, yellow } from '@material-ui/core/colors';
import Badge from '@material-ui/core/Badge';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { groupBy } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { IconPreview } from 'src/app/assetTypes/iconPreview';
import { LiveMapAssetState } from 'src/app/map/liveMapAssetState';

import {
	Client as C,
} from 'src/services';

interface FloorPlanOverviewProps {
	floorPlan: C.IFloorPlanDto;
	alternativeFloorPlan?: C.IFloorPlanDto;
	selectFloorPlan: (floorPlanId: string) => void;
	assetTypes: C.IAssetTypeDto[];
	assetStates?: Set<LiveMapAssetState>;
	hovering: boolean;
	emergency: boolean;
}

const useStyles = makeStyles({
	root: {
		display: 'flex',
		border: '2px solid transparent',
		height: '85px',
		cursor: 'pointer',
		alignContent: 'center',
	},
	emergency: {
		border: `2px solid ${red[500]}`,
		backgroundColor: red[50],
	},
	hovering: {
		border: `2px solid ${yellow[500]}`,
		backgroundColor: yellow[50],
	},
	title: {
		fontWeight: 'bold',
		fontSize: '120%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	countCircle: {
		borderRadius: '50%',
		width: '50px',
		height: '50px',
		justifySelf: 'center',
		fontSize: '120%',
		fontWeight: 600,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '15px',
	},
	emergencyCountCircle: {
		backgroundColor: red[500],
		color: 'white',
	},
	assetCountCircle: {
		border: `2px solid ${green[300]}`,
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		color: green[800],
	},
});

const StyledBadge = withStyles(() =>
	createStyles({
		badge: {
			color: '#222',
			backgroundColor: 'white',
			border: `1px solid #888`,
			zIndex: 2,
		},
	}),
)(Badge);

const AssetInformationAndViewButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-left: auto;
`;

const AssetTypesOnFloorPlan = styled.span`
	display: flex;
	align-self: center;
	justify-self: center;
`;

const AssetTypesIcon = styled.span`
	margin-right: 15px;
`;

interface IAssetTypeGroup {
	assetType: C.IAssetTypeDto;
	count: number;
}

export const FloorPlanOverview = observer((props: FloorPlanOverviewProps) => {
	const classes = useStyles();

	const assets = Array.from(props.assetStates?.values() || []);

	const numberOfAssets = assets.length;
	const numberOfEmergencyAssets = !props.emergency ? 0 : assets.filter(x => x.hasEmergency).length;

	const assetTypeGroups: IAssetTypeGroup[] = [];

	const assetTypeIdGroups = groupBy(assets.map(x => x.asset.assetType?.id || ''));
	for (const [assetTypeId, group] of Object.entries(assetTypeIdGroups)) {
		const assetType = props.assetTypes.find(x => x.assetTypeId === assetTypeId);
		if (!assetType || !assetType.useCustomMapMarker)
			continue;

		assetTypeGroups.push({
			assetType,
			count: group.length,
		});
	}

	return <div
		className={classNames('content-box', classes.root, {
			[classes.emergency]: props.emergency,
			[classes.hovering]: props.hovering,
		})}
		data-floor-plan-id={props.floorPlan.floorPlanId}
		data-alternative-floor-plan-id={props.alternativeFloorPlan?.floorPlanId}
		onClick={() => props.selectFloorPlan(props.floorPlan.floorPlanId)}
	>
		<span className={classes.title}>{props.floorPlan.name}</span>

		<AssetInformationAndViewButton>
			{<AssetTypesOnFloorPlan>
				{assetTypeGroups.map(x => <AssetTypesIcon
					title="Total number of this asset type on this floor."
				>
					<StyledBadge overlap="circle" badgeContent={x.count}>
						<IconPreview
							useCustomIcon={x.assetType.useCustomMapMarker}
							iconShape={x.assetType.icon as C.MapMarkerIcon}
							iconColor={x.assetType.iconColor}
							iconSize={23}
							backgroundColor={x.assetType.mapMarkerColor ?? '#1e88e5'}
							backgroundSize={35}
						/>
					</StyledBadge>
				</AssetTypesIcon>)}
			</AssetTypesOnFloorPlan>}

			{numberOfEmergencyAssets > 0 && <span
				className={classNames(classes.countCircle, classes.emergencyCountCircle)}
				title="Number of assets that have triggered an emergency alert."
			>
				{numberOfEmergencyAssets}
			</span>}

			{numberOfAssets > 0 && <span
				className={classNames(classes.countCircle, classes.assetCountCircle)}
				title="Number of assets currently on this floor."
			>
				{numberOfAssets}
			</span>}

			<IconButton
				title="View floor plan"
				onClick={() => props.selectFloorPlan(props.floorPlan.floorPlanId)}
			>
				<KeyboardArrowRightIcon />
			</IconButton>
		</AssetInformationAndViewButton>
	</div>;
});
