import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetByIdForAssetServiceRemindersHistory($id: UUID!) {
		assetById(id: $id){
			id
			name
			serviceReminderHistory {
				assetServiceReminderType {
					name
				}
				actionType
				identity {
					id
					user {
						name
					}
				}
				performedAt
				stateAfterAction
				nextDateTriggerTimestamp
				nextOdometerTrigger
				nextEngineTimeTrigger
				notes
			}
		}
	}
`;
