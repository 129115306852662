import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';
import { ToasterService } from './toasterService';
import { HistoryService } from './history';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { QueryReport_reportById, executeQueryReport } from 'src/graphql/__generated__/queries/queryReport';

@injectable()
export class ReportService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.ApolloClient) private apolloClient: ApolloClient<InMemoryCache>,
		@inject(Service.History) private historyService: HistoryService,
		@inject(Service.Toaster) private toasterService: ToasterService,
	) {

	}

	async getReportById(reportId: string): Promise<QueryReport_reportById> {
		const reportQuery = await executeQueryReport(this.apolloClient, {reportId: reportId} );

		if (reportQuery.error || !reportQuery.data.reportById)
			throw reportQuery.error || 'Failed to load report.';

		return reportQuery.data.reportById;
	}

	async listReports(): Promise<C.IReportDto[] | null> {
		return await this.client.listReports();
	}

	async deleteReports(reportIds: string[]) {
		const deleteRequest: C.IDeleteReportsRequest = {
			reportIds: reportIds,
		};

		return await this.client.deleteReportsById(new C.DeleteReportsRequest(deleteRequest));
	}

	async listScheduledReports(): Promise<C.IScheduledReportDto[] | null> {
		return await this.client.listScheduledReports();
	}

	async addScheduledReport(request: C.IAddScheduledReportRequest): Promise<C.IScheduledReportDto | null> {
		return await this.client.createScheduledReport(new C.AddScheduledReportRequest(request));
	}

	async updateScheduledReport(scheduledReportId: string, request: C.IUpdateScheduledReportRequest) : Promise<C.IScheduledReportDto | null> {
		return await this.client.updateScheduledReportById(scheduledReportId, new C.UpdateScheduledReportRequest(request));
	}

	async deleteScheduledReport(scheduledReportId: string) {
		return await this.client.deleteScheduledReportById(scheduledReportId);
	}

	async getScheduledReportParametersId(scheduledReportId: string) {
		return await this.client.getScheduledReportParametersById(scheduledReportId);
	}

	async createActivityReport(request: C.INewActivityReportRequest): Promise<void> {
		await this.client.createNewActivityReport(new C.NewActivityReportRequest(request));
	}

	async getActivityReportData(reportId: string): Promise<C.IActivityReportData> {
		return await this.client.getActivityReportDataById(reportId);
	}

	async createAssetLogHistoryReport(request: C.INewAssetLogHistoryReportRequest): Promise<void> {
		await this.client.createNewAssetLogHistoryReport(new C.NewAssetLogHistoryReportRequest(request));
	}

	async createBillingReport(request: C.INewBillingReportRequest): Promise<void> {
		await this.client.createNewBillingReport(new C.NewBillingReportRequest(request));
	}

	async createDailyReport(request: C.INewDailyReportRequest): Promise<void> {
		await this.client.createNewDailyReport(new C.NewDailyReportRequest(request));
	}

	async createDriverReport(request: C.INewDriverReportRequest): Promise<void> {
		await this.client.createNewDriverReport(new C.NewDriverReportRequest(request));
	}

	async getDriverReportData(reportId: string) {
		return await this.client.getDriverReportDataById(reportId);
	}

	async createAssetEventsExport(request: C.INewAssetEventHistoryReportRequest): Promise<void> {
		await this.client.createAssetEventHistoryReport(new C.NewAssetEventHistoryReportRequest(request));
	}

	async createGeofenceReport(request: C.INewGeofenceReportRequest): Promise<void> {
		await this.client.createNewGeofenceReport(new C.NewGeofenceReportRequest(request));
	}

	async getGeofenceReportData(reportId: string): Promise<C.IGeofenceReportData> {
		return await this.client.getGeofenceReportDataById(reportId);
	}

	async getBillingReportData(reportId: string): Promise<C.IBillingReportData> {
		return await this.client.getBillingReportDataById(reportId);
	}

	async createLocationReport(request: C.INewLocationReportRequest): Promise<void> {
		await this.client.createNewLocationReport(new C.NewLocationReportRequest(request));
	}

	async createSafetyTimerReport(request: C.INewSafetyTimerReportRequest): Promise<void> {
		await this.client.createNewSafetyTimerReport(new C.NewSafetyTimerReportRequest(request));
	}

	async getSafetyTimerReportData(reportId: string): Promise<C.ISafetyTimerReportData> {
		return await this.client.getSafetyTimerReportDataById(reportId);
	}

	async createUserAuthAuditReport(request: C.INewUserAuthAuditReportRequest): Promise<void> {
		await this.client.createNewUserAuthAuditReport(new C.NewUserAuthAuditReportRequest(request));
	}

	async getUserAuthAuditReportData(reportId: string): Promise<C.IUserAuthAuditReportData> {
		return await this.client.getUserAuthAuditReportDataById(reportId);
	}

	getReportTypeFormatted(type: C.ReportType): string {
		switch (type) {
			case C.ReportType.Activity:
				return 'Activity Report';
			case C.ReportType.AssetEventHistory:
				return 'Asset Event History Report';
			case C.ReportType.AssetLogHistory:
				return 'Asset Log History Report';
			case C.ReportType.Billing:
				return 'Billing Report';
			case C.ReportType.Daily:
				return 'Daily Report';
			case C.ReportType.Driver:
				return 'Driver Report';
			case C.ReportType.Geofence:
				return 'Geofence Report';
			case C.ReportType.Location:
				return 'Location Report';
			case C.ReportType.SafetyTimer:
				return 'Safety Timer Report';
			case C.ReportType.UserAuthAudit:
				return 'User Auth Audit';
			default:
				return 'Unknown';
		}
	}
}
