import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';

import DeleteIcon from '@material-ui/icons/Delete';

import {
	Client as C,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import 'src/app/map/mapInfoBox.scss';

import { CustomMapMarkerManager } from 'src/app/map/customMapMarkerManager';
import { Button, CustomAutocomplete } from 'src/components';
import { useMutationAddCustomMapMarker } from 'src/graphql/__generated__/mutations/mutationAddCustomMapMarker';
import { useMutationUpdateCustomMapMarker } from 'src/graphql/__generated__/mutations/mutationUpdateCustomMapMarker';
import { useMutationDeleteCustomMapMarkers } from 'src/graphql/__generated__/mutations/mutationDeleteCustomMapMarkers';
import { renderMapMarkerIconOption, IMapMarkerIconOption, mapMarkerIconOptions } from 'src/app/assetTypes/assetTypesHelpers';
import { MapMarkerShape } from 'src/../__generated__/globalTypes';
import { getPrettyName, undefinedIfUnchanged } from 'src/util';

export interface IProps {
	customMapMarkerManager: CustomMapMarkerManager;
}

export const CustomMapMarkerEditBox = observer((props: IProps) => {
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);

	const [addCustomMapMarker] = useMutationAddCustomMapMarker();
	const [updateCustomMapMarker] = useMutationUpdateCustomMapMarker();
	const [deleteCustomMapMarker] = useMutationDeleteCustomMapMarkers();

	const editorState = props.customMapMarkerManager.editorState;
	if (!editorState)
		return null;

	const submitAddOrUpdate = async () => {
		const editorState = props.customMapMarkerManager.editorState;
		if (!editorState)
			return;

		setSaving(true);

		try {
			if (editorState.existingCustomMapMarker) {
				const response = await updateCustomMapMarker({
					variables: {
						input: {
							customMapMarkerId: editorState.existingCustomMapMarker.id,
							name: undefinedIfUnchanged(editorState.existingCustomMapMarker.name, editorState.name),
							details: undefinedIfUnchanged(editorState.existingCustomMapMarker.details, editorState.details),
							latitude: undefinedIfUnchanged(editorState.existingCustomMapMarker.latitude, editorState.latitude),
							longitude: undefinedIfUnchanged(editorState.existingCustomMapMarker.longitude, editorState.longitude),
							size: undefinedIfUnchanged(editorState.existingCustomMapMarker.size, editorState.size),
							backgroundShape: undefinedIfUnchanged(editorState.existingCustomMapMarker.backgroundShape, editorState.backgroundShape),
							backgroundShapeColor: undefinedIfUnchanged(editorState.existingCustomMapMarker.backgroundShapeColor, editorState.backgroundShape ? editorState.backgroundShapeColor : null),
							icon: undefinedIfUnchanged(editorState.existingCustomMapMarker.icon, editorState.icon),
							iconColor: undefinedIfUnchanged(editorState.existingCustomMapMarker.iconColor, editorState.icon ? editorState.iconColor : null),
						},
					},
				});

				const marker = response.data?.updateCustomMapMarker?.customMapMarker;
				if (response.errors || !marker)
					throw response.errors;

				props.customMapMarkerManager.addMarker(marker);
			} else {
				const response = await addCustomMapMarker({
					variables: {
						input: {
							name: editorState!.name,
							details: editorState.details,
							latitude: editorState.latitude,
							longitude: editorState.longitude,
							size: editorState.size,
							backgroundShape: editorState.backgroundShape,
							backgroundShapeColor: editorState.backgroundShape ? editorState.backgroundShapeColor : null,
							icon: editorState.icon,
							iconColor: editorState.icon ? editorState.iconColor : null,
						},
					},
				});

				const marker = response.data?.addCustomMapMarker?.customMapMarker;
				if (response.errors || !marker)
					throw response.errors;

				props.customMapMarkerManager.addMarker(marker);
			}

			toasterService.showSuccess('Saved changes.');

			props.customMapMarkerManager.stopEditingMapMarker(false);
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to save changes.');
		}

		setSaving(false);
	};

	const submitDelete = async () => {
		const marker = editorState.existingCustomMapMarker;
		if (!marker)
			return;

		if (!confirm(`Delete this custom map marker? (${marker.name})`))
			return;

		setDeleting(true);

		try {
			const response = await deleteCustomMapMarker({
				variables: {
					input: {
						ids: [ marker!.id ],
					},
				},
			});

			if (response.errors)
				throw response.errors;

			props.customMapMarkerManager.removeMarker(marker.id);

			toasterService.showSuccess('Custom map marker deleted.');
			props.customMapMarkerManager.stopEditingMapMarker(false);
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to delete custom map marker.');
		}

		setDeleting(false);
	};

	const filteredMapMarkerIconOptions = mapMarkerIconOptions.filter(x => {
		if (!editorState.backgroundShape && x.MapMarkerIcon === C.MapMarkerIcon.None)
			return false;

		return x.customMarker;
	});

	return <Card className="map-info-box" style={{ overflow: 'visible' }}>
		<div className="header">
			<span className="title">{editorState.existingCustomMapMarker ? 'Edit Map Marker' : 'Add Map Marker'}</span>
		</div>

		<div className="section">
			<TextField
				label="Marker Name"
				fullWidth
				value={editorState.name}
				onChange={e => editorState.name = e.target.value}
				variant="filled"
			/>

			<div>
				<div style={{ marginBottom: '3px' }}>Marker Size</div>
				<Slider
					onChange={(_, value) => editorState.size = Array.isArray(value) ? value[0] : value}
					value={editorState.size}
					valueLabelDisplay="auto"
					step={2}
					min={15}
					max={69}
				/>
			</div>

			<div className="input-color-group">
				<CustomAutocomplete
					label="Marker Shape"
					options={[ 'NONE' as MapMarkerShape, MapMarkerShape.CIRCLE, MapMarkerShape.SQUARE ]}
					getOptionLabel={option => option as any === 'NONE' ? 'Icon Only' : getPrettyName(option)}
					getOptionValue={option => option}
					value={editorState.backgroundShape ?? 'NONE'}
					onChange={(x: MapMarkerShape) => props.customMapMarkerManager.changeMapMarkerShape(x as any === 'NONE' ? null : x)}
					clearable={false}
				/>

				{editorState.backgroundShape && editorState.backgroundShapeColor && <input
					type="color"
					value={editorState.backgroundShapeColor}
					onChange={e => editorState.backgroundShapeColor = e.target.value}
				/>}
			</div>

			<div className="input-color-group">
				<CustomAutocomplete
					label="Icon"
					placeholder="Select an icon..."
					options={filteredMapMarkerIconOptions}
					getOptionLabel={option => option.label}
					renderOption={renderMapMarkerIconOption}
					getOptionValue={option => option.mapMarkerIcon}
					value={editorState.icon}
					onChange={(x: IMapMarkerIconOption | null) => editorState.icon = x ? x.mapMarkerIcon : null}
					clearable={false}
				/>

				{editorState.icon && editorState.iconColor && <input
					type="color"
					value={editorState.iconColor}
					onChange={e => editorState.iconColor = e.target.value}
				/>}
			</div>

			<TextField
				label="Details"
				fullWidth
				value={editorState.details}
				onChange={e => editorState.details = e.target.value}
				variant="filled"
				multiline
				rows={3}
			/>
		</div>

		<div className="section actions">
			<div>
				{editorState.existingCustomMapMarker && <Button
					className="delete"
					startIcon={<DeleteIcon />}
					disabled={saving || deleting}
					loading={deleting}
					onClick={submitDelete}
					title="Delete marker"
				/>}
			</div>

			<div>
				<Button
					text="Cancel"
					disabled={saving || deleting}
					onClick={() => props.customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Cancelling', true)}
				/>

				<Button
					text="OK"
					color="primary"
					variant="outlined"
					disabled={saving || deleting || !editorState.name || (!editorState.backgroundShape && !editorState.icon)}
					loading={saving}
					onClick={submitAddOrUpdate}
				/>
			</div>
		</div>
	</Card>;
});
