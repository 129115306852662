import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class LinkedDeviceService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
	) {
	}

	getAllLinkedDevices(): Promise<C.IMappedLinkedDeviceDto> {
		return this.client.listLinkedDevices();
	}

	createDeviceLinkCode(request: C.ICreateDeviceLinkCodeRequest): Promise<C.ICreateDeviceLinkCodeResponse> {
		return this.client.createDeviceLinkCode(new C.CreateDeviceLinkCodeRequest(request));
	}

	unlinkDevice(assetOrDeviceId: string) {
		return this.client.unlinkDevice(assetOrDeviceId);
	}
}
