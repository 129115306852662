import React from 'react';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
	IAssetSelectorFormValues,
} from 'src/services';

import { FormikSelect } from 'src/components';
import { IOption } from 'src/util';

interface Props {
	options: IOption<string>[] | null;
	formikProps: FormikProps<IAssetSelectorFormValues>;
}

export const AssetSelector = observer((props: Props) => {
	if (!props.options)
		return null;

	return <FormikSelect
		name="assets"
		placeholder="Select Assets"
		label="Assets"
		form={props.formikProps}
		multi
		options={props.options.slice()}
		getOptionLabel={option => option.label}
		getOptionValue={option => option.value}
		disableCloseOnSelect
	/>;
});
