//This file just re-exports existing services. Add your services in here and register them in inversify

import * as Client from './client';
import * as TileServerClient from './tileServerClient';

export {
	Client,
	TileServerClient,
};

export * from './service';

export * from './gatewayService';
export * from './addressBookService';
export * from './assetEventsService';
export * from './assetGroupService';
export * from './assetService';
export * from './deviceConfigurationService';
export * from './assetTypeService';
export * from './beaconsService';
export * from './brandingService';
export * from './authenticationService';
export * from './callService';
export * from './callGroupsService';
export * from './clientService';
export * from './emergencyService';
export * from './enableFleetConfigurationService';
export * from './floorPlansService';
export * from './gatewayLogsService';
export * from './geofenceService';
export * from './linkedDeviceService';
export * from './permissionManagementService';
export * from './permissionsService';
export * from './reportService';
export * from './userGroupService';
export * from './safetyTimerService';
export * from './siteService';
export * from './soundService';
export * from './usersService';
export * from './tableDataService';
export * from './webSocketService';
export * from './mapDataService';

export * from './cefSharpService';
export * from './floorLevelConverter';
export * from './history';
export * from './toasterService';
export * from './telematicsService';
export * from './distanceService';

export * from './formServices/addressBookEntrySelectorService';
export * from './formServices/assetSelectorService';
export * from './formServices/assetGroupSelectorService';
export * from './formServices/userSelectorService';
export * from './formServices/userGroupSelectorService';

export * from './booleanResponse';

export * from './inversify';

if (module && module.hot) {
	module.hot.decline();
}
