import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { useQueryAssetServiceReminderTypeById } from 'src/graphql/__generated__/queries/queryAssetServiceReminderTypeById';

import { ManageAssetServiceReminderType }  from './manageAssetServiceReminderType';
import { ErrorMessagePage, LoadingMessagePage } from 'src/components';

import {
	Client as C,
	useIdentityType,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditAssetServiceReminderType = observer((props: Props) => {
	const identityType = useIdentityType();

	const assetServiceReminderTypeQuery = useQueryAssetServiceReminderTypeById({
		variables: {
			id: props.match.params.id,
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		}
	});

	if (assetServiceReminderTypeQuery.loading)
		return <LoadingMessagePage />;

	if (assetServiceReminderTypeQuery.error || !assetServiceReminderTypeQuery.data?.assetServiceReminderTypeById)
		return <ErrorMessagePage />;

	return <ManageAssetServiceReminderType
		assetServiceReminderType={assetServiceReminderTypeQuery.data.assetServiceReminderTypeById}
	/>;
});
