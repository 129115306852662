import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import * as C from 'src/services/client';
import { Service } from 'src/services/service';
import { ToasterService } from 'src/services/toasterService';
import { AddressBookService } from 'src/services/addressBookService';
import { useInjection } from 'src/services';
import { IOption } from 'src/util';

export interface IAddressBookEntrySelectorFormValues {
	addressBookEntries: string[];
}

export const useAddressBookEntrySelectorService = () => {
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _addressBookService = useInjection<AddressBookService>(Service.AddressBooks);

	const [addressBookEntryOptions, setAddressBookEntryOptions] = useState<IOption<string>[] | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useAsyncEffect(async () => {
		try {
			const addressBooks = await _addressBookService.getAllAddressBooks();

			let options: IOption<string>[] = [];

			// This is necessary as .reduce() on an empty array will throw an exception.
			if (addressBooks.length !== 0) {
				const addressBookEntries: C.IAddressBookEntryDto[] = addressBooks
					.map(x => x.addressBookEntries)
					.reduce((prev, curr) => prev.concat(curr));

				options = addressBookEntries
					.sort((x, y) => x.userName.localeCompare(y.userName))
					.map(x => ({ label: x.userName, value: x.addressBookEntryId }));
			}

			setAddressBookEntryOptions(options);

		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to load address book entries.');
		}

		setLoading(false);
	}, []);

	return { addressBookEntryOptions, loading };
};
