import { IReportFormat } from './IReportFormat';

export class ActivityReportFormat implements IReportFormat {
	HasAssetSelection = true;
	HasGeofenceSelection = false;
	HasMinimumTravelSpeedAndStopTime = true;
	HasBillingCodes = false;
	HasUserSelection = false;
	HasAddressBookEntrySelection = false;
	ReportDefinitionHelperText = 'The activity (travelling/stopped) of one or more assets during a specified time period.';
}
