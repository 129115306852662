import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';

import AddIcon from '@material-ui/icons/Add';

import {
	ThingLoader,
	CustomActionDialogBox,
	Button,
	MessagePage,
	PopoverMenu,
	PopoverMenuItem
} from 'src/components';

import { FixedWidthPage, MaterialTable } from 'src/components';
import { IOption } from 'src/util';

import {
	AuthenticationService,
	Client as C,
	HistoryService,
	FloorPlansService,
	ToasterService,
	Service,
	ClientService,
	useInjection,
} from 'src/services';

import './floorPlansList.scss';

interface IPageData {
	clients?: IOption<string>[];
}

export const FloorPlanCollectionsList = observer(() => {
	const _auth = useInjection<AuthenticationService>(Service.Authentication);
	const _clientService = useInjection<ClientService>(Service.Client);
	const _floorPlans = useInjection<FloorPlansService>(Service.FloorPlans);
	const _history = useInjection<HistoryService>(Service.History);
	const _toaster = useInjection<ToasterService>(Service.Toaster);

	const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
	const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
	const [newCollectionName, setNewCollectionName] = useState<string>('');
	const [newCollectionError, setNewCollectionError] = useState<string | null>(null);

	const load = async (): Promise<IPageData | null> => {
		let clients: C.IClientDto[] = [];
		if (_auth.currentAuth.user.identity.type !== C.IdentityType.Client) {
			const loadedClients = await _clientService.getAllClients();
			if (!loadedClients)
				return null;

			clients = loadedClients;
			setSelectedClientId(clients.length > 0 ? clients[0].clientId : null);
		} else {
			setSelectedClientId(_auth.currentAuth.user.identity.clientId || null);
		}

		return {
			clients: clients.map(x => ({
				value: x.clientId,
				label: x.name,
			})),
		};
	};

	const handleClientChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		setSelectedClientId(event.target.value);
	};

	const handleCollectionNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		setNewCollectionName(event.target.value);
		setNewCollectionError(isCollectionNameValid(event.target.value) ? null : 'Collection name is required');
	};

	const isCollectionNameValid = (name: string | null): boolean => {
		return !!name;
	};

	const onDeleteClick = (floorPlanGroup: C.IFloorPlanGroupDto) => {
		const input = prompt('Are you sure you want to delete this collection? All groups and plans under it will also be deleted! Enter the collection name to confirm.');

		if (input === null)
			return;

		if (input === floorPlanGroup.name) {
			_floorPlans.deleteFloorPlanCollection(floorPlanGroup.collectionId);
		} else {
			_toaster.showWarning('The collection name you entered did not match the collection you attempted to delete. Please confirm your selection.');
		}
	};

	const onClickAddCollection = () => {
		setNewCollectionName('');
		setNewCollectionError(null);
		setAddDialogOpen(true);
	};

	const onConfirmAddCollection = async () => {
		const newCollectionRequest: C.IAddFloorPlanCollectionRequest = {
			name: newCollectionName,
			clientId: selectedClientId!!,
		};

		const result = await _floorPlans.addFloorPlanCollection(newCollectionRequest);

		if (result)
			_history.history.push(`/app/floorplans/${result.collectionId}/list`);
	};

	const renderActions = (floorPlanGroup: C.IFloorPlanGroupDto) => {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/floorplans/${floorPlanGroup.collectionId}/list`}
			/>,
			<PopoverMenuItem
				key="delete"
				text="Delete"
				onClick={() => onDeleteClick(floorPlanGroup)}
			/>,
		];
	};

	const renderAddCollectionPopup = (pageData: IPageData) => {
		return <CustomActionDialogBox
			title="Add floor plan collection"
			actionButton={<Button
				color="primary"
				text="Add"
				disabled={!newCollectionName || !!newCollectionError}
				variant="contained"
				onClick={onConfirmAddCollection}
			/>}
			dialogCloseCallback={() => setAddDialogOpen(false)}
		>
			{_auth.currentAuth.user.identity.type !== C.IdentityType.Client && <TextField
				select
				key="selectClient"
				label="Client"
				value={selectedClientId}
				onChange={handleClientChange}
				fullWidth
				SelectProps={{
					native: true,
				}}
				InputLabelProps={{
					shrink: true,
				}}
				margin="normal"
				variant="filled"
			>
				{pageData.clients!.map(option => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</TextField>
			}

			<TextField
				label="Collection name"
				id="collectionName"
				value={newCollectionName}
				onChange={handleCollectionNameChange}
				error={!!newCollectionError}
				helperText={newCollectionError}
				fullWidth
				margin="normal"
				variant="filled"
			/>
		</CustomActionDialogBox>;
	};

	return <ThingLoader
		load={load}
		render={(pageData: IPageData) => <FixedWidthPage
			className="floorplans-list"
			headingText="Floor Plan Collections"
			headingActions={_auth.currentAuth.permissions.general.manageFloorPlans
				? <Button
					id="add-button"
					key="addClient"
					disabled={selectedClientId === null}
					text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"
					onClick={onClickAddCollection}
				/>
				: undefined
			}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': _floorPlans.collections.size === 0 })}
		>
			{_floorPlans.isLoading && <MessagePage loading />}

			{!_floorPlans.isLoading && _floorPlans.collections.size > 0 && <MaterialTable
				tableName="floor-plan-collections-list"
				columns={[
					{
						title: 'Name',
						field: 'name',
						grouping: false,
					},
					{
						title: 'Dealer',
						field: 'dealer',
						grouping: true,
						hidden: _auth.currentAuth.user.identity.type == C.IdentityType.Client
							|| _auth.currentAuth.user.identity.type == C.IdentityType.Dealer,
					},
					{
						title: 'Client',
						field: 'client',
						grouping: true,
						hidden: _auth.currentAuth.user.identity.type == C.IdentityType.Client,
					},
					{
						title: 'Options',
						field: 'options',
						grouping: false,
						filtering: false,
						sorting: false,
						headerStyle: ({
							textAlign: 'right',
						}),
						cellStyle: () => ({
							textAlign: 'right',
						}),
						render: rowData => <PopoverMenu
							renderOptions={() => renderActions(rowData)}
						/>,
					},
				]}
				data={Array.from(_floorPlans.collections.values())}
				options={{
					grouping: _auth.currentAuth.user.identity.type !== C.IdentityType.Client,
				}}
			/>}

			{!_floorPlans.isLoading && _floorPlans.collections.size === 0 && <MessagePage
				title="No floor plan collections."
				action={_auth.currentAuth.permissions.general.manageFloorPlans
					? (selectedClientId === null
						? <p>Adding a floor plan collection requires at least one client.</p>
						: <Button
							onClick={onClickAddCollection}
							text="Add a floor plan collection?"
							variant="outlined"
						/>)
					: undefined}
			/>}

			{addDialogOpen && renderAddCollectionPopup(pageData)}
		</FixedWidthPage>
	}/>;
});
