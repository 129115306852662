import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikCheckbox, FormikSelect, FormikTextField } from 'src/components';
import { DealerClientSelector } from 'src/components/form';
import { Client as C, useCurrentUser } from 'src/services';
import { getBillingTypeOptions } from './deviceBillingOptions';
import { getDeviceTypeOptions } from './deviceTypeOptions';
import { runFormValidation } from 'src/util';
import { featureToggleAssetTypes } from 'src/config';

import { getInitialFormValues } from './initialFormValues';
import { ManageATrackDeviceComponent } from './manageATrackDeviceComponent';
import { ManageCamperVanDeviceComponent } from './manageCamperVanDeviceComponent';
import { ManageDigitalMatterDeviceComponent } from './manageDigitalMatterDeviceComponent';
import { ManageEroadDeviceComponent } from './manageEroadDeviceComponent';
import { ManageHyteraRadioDeviceComponent } from './manageHyteraRadioDeviceComponent';
import { ManageMobilePhoneDeviceComponent } from './manageMobilePhoneDeviceComponent';
import { ManageTaitRadioDeviceComponent } from './manageTaitRadioDeviceComponent';
import { ManageTeltonikaDeviceComponent } from './manageTeltonikaDeviceComponent';

import { ATrackDeviceModelType, DeviceBillingType, DeviceType, TeltonikaDeviceModelType } from 'src/../__generated__/globalTypes';
import { QueryEditDevice_device } from 'src/graphql/__generated__/queries/queryEditDevice';

export interface ManageDeviceFormValues {
	// General asset/device information.
	dealerId: string | null;
	clientId: string | null;

	// Device only information.
	deviceSameNameAsAsset: boolean;
	deviceName: string;
	serialNumber: string;
	billingType: DeviceBillingType;
	trialPeriodEndTimestamp: moment.Moment | null;
	trialPeriodMonths: number | null;
	deviceType: DeviceType | null;

	// Asset only information.
	assetName: string;
	assetTypeId: string | null;
	speedLimitEnabled: boolean;
	speedLimit: number | null;
	emergencyNotes: string | null;

	// Only applicable to certain device types.
	atrackModelType: ATrackDeviceModelType | null;
	teltonikaModelType: TeltonikaDeviceModelType | null;
	networkId: string | null;
	radioId: string;
	selCallId: string;
	gpsEnabled: boolean;
	bleEnabled: boolean;
	bluetoothBeaconSetIds: string[] | null;
	manualPollingEnabled: boolean;
	callRecordingEnabled: boolean;
	imei: string;
	phoneNumber: string;
	vehiclePlate: string;
	chatterPttUserId: string;
}

export interface IDeviceNetwork {
	id: string;
	name: string;
	networkType: C.NetworkType;
	dealers: null | {
		id: string;
	}[];
}

export const apiNetworksToDeviceNetworks = (apiNetworks: C.ISiteDto[] | null) => apiNetworks?.map(x => ({
	id: x.siteId,
	name: x.name,
	networkType: x.networkType,
	dealers: x.dealerIds.map(y => ({ id: y })),
})) || null;

interface Props {
	addAssetView?: boolean;
	device?: QueryEditDevice_device;
	dealers?: {
		id: string;
		name: string;
	}[];
	clients?: {
		id: string;
		name: string;
		dealer: null | {
			id: string;
		};
	}[];
	assetTypes?: {
		id: string;
		name: string;
		client: null | {
			id: string;
		};
	}[];
	bluetoothBeaconSets?: {
		id: string;
		name: string;
		dealer: null | {
			id: string;
		};
	}[];
	networks?: IDeviceNetwork[];
	submit: (values: ManageDeviceFormValues) => Promise<void>;
}

export const ManageDeviceComponent = observer((props: Props) => {
	const currentUser = useCurrentUser()!;

	const billingTypeOptions = getBillingTypeOptions(currentUser.identity, props.device?.billingType || null);
	const deviceTypeOptions = getDeviceTypeOptions(currentUser.identity, props.device?.deviceType || null);

	let maxTrialPeriodMonths = 0;
	if (currentUser.identity.type === C.IdentityType.SuperUser) {
		maxTrialPeriodMonths = 48;
	} else if (currentUser.identity.type === C.IdentityType.Dealer) {
		maxTrialPeriodMonths = currentUser.identity.dealer!.maxTrialMonths;
	}

	const [initialValues] = useState<ManageDeviceFormValues>(getInitialFormValues(props.device, billingTypeOptions, maxTrialPeriodMonths));

	const addingNewDevice = !props.device;
	
	const validateForm = (values: ManageDeviceFormValues, errors: FormikErrors<ManageDeviceFormValues>) => {
		if (!props.addAssetView || !values.deviceSameNameAsAsset) {
			if (!values.deviceName)
				errors.deviceName = 'Device name is required.';
		}

		if (addingNewDevice && values.billingType === DeviceBillingType.TRIAL && !values.trialPeriodMonths)
			errors.trialPeriodMonths = 'Trial period is required';

		if (!values.deviceType)
			errors.deviceType = 'Device type is required.';

		if (!values.imei) {
			if (values.deviceType === DeviceType.ATRACK || values.deviceType === DeviceType.CAMPER_VAN || values.deviceType === DeviceType.DIGITAL_MATTER || values.deviceType === DeviceType.TELTONIKA)
				errors.imei = 'IMEI is required.';
		}

		if (props.addAssetView && !values.assetName)
			errors.assetName = 'Asset name is required.';

		if (values.speedLimitEnabled && !values.speedLimit)
			errors.speedLimit = 'Speed limit is required if speed limit is enabled.';

		if (values.deviceType === DeviceType.ATRACK && !values.atrackModelType)
			errors.atrackModelType = 'ATrack model type is required.';
		else if (values.deviceType === DeviceType.TELTONIKA && !values.teltonikaModelType)
			errors.teltonikaModelType = 'Teltonika model type is required.';
	}

	const heading = props.addAssetView ? 'Add Asset with Device' : (addingNewDevice ? 'Add Device' : 'Edit Device');
	const submitButtonText = props.addAssetView ? 'Add Asset with Device' : (addingNewDevice ? 'Add Device' : 'Save Changes');

	const hasDealerSelection = currentUser.identity.type === C.IdentityType.SuperUser;
	const hasClientSelection = currentUser.identity.type === C.IdentityType.SuperUser || currentUser.identity.type === C.IdentityType.Dealer;

	return <FixedWidthPage
		className="form-page"
		headingText={heading}
		headingActions={props.addAssetView && <Button href="/app/assets/add" text="Add Asset Only" variant="outlined" color="primary"/> || undefined}
		pageItemId={props.device?.id}
		noContentBackground
	>
		<Formik
			initialValues={initialValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={props.submit}
		>
			{formikProps => {
				let assetTypeOptions = props.assetTypes;
				if (hasClientSelection && assetTypeOptions) {
					if (formikProps.values.clientId)
						assetTypeOptions = assetTypeOptions.filter(x => x.client?.id === formikProps.values.clientId);
					else
						assetTypeOptions = [];
				}

				let bluetoothBeaconSetOptions = props.bluetoothBeaconSets;
				if (hasDealerSelection && bluetoothBeaconSetOptions) {
					if (formikProps.values.dealerId)
						bluetoothBeaconSetOptions = bluetoothBeaconSetOptions.filter(x => x.dealer?.id === formikProps.values.dealerId);
					else
						bluetoothBeaconSetOptions = [];
				}

				return <Form className="formik-form">
					<div className="content-box form-card">
						<Field
							name={props.addAssetView ? 'assetName' : 'deviceName'}
							label="Name"
							type="text"
							component={FormikTextField}
							required
						/>

						<DealerClientSelector
							form={formikProps}
							dealers={props.dealers}
							dealerDisabledMessage={props.device?.asset ? 'Cannot change dealer when the device is assigned to an asset.' : null}
							clients={props.clients}
							clientDisabledMessage={props.device?.asset ? 'Cannot change client when the device is assigned to an asset.' : null}
						/>

						<FormikSelect
							name="billingType"
							label="Billing Type"
							required
							form={formikProps}
							options={billingTypeOptions}
							getOptionValue={x => x.value}
							getOptionLabel={x => x.label}
						/>

						{!props.device && formikProps.values.billingType === DeviceBillingType.TRIAL && <TextField
							name="trialPeriodMonths"
							label="Trial Period"
							value={formikProps.values.trialPeriodMonths}
							onChange={formikProps.handleChange}
							onBlur={formikProps.handleBlur}
							error={formikProps.touched.trialPeriodMonths && !!formikProps.errors.trialPeriodMonths}
							helperText={formikProps.touched.trialPeriodMonths && formikProps.errors.trialPeriodMonths}
							type="number"
							margin="normal"
							fullWidth
							InputProps={{
								endAdornment: <InputAdornment position="end">month(s)</InputAdornment>,
							}}
							variant="filled"
							disabled={maxTrialPeriodMonths < 2}
						/>}

						{props.device && formikProps.values.billingType === DeviceBillingType.TRIAL && <KeyboardDatePicker
							name="trialPeriodEndTimestamp"
							label="Trial Period End Date"
							format="DD/MM/YYYY"
							inputVariant="filled"
							fullWidth
							value={formikProps.values.trialPeriodEndTimestamp}
							onChange={(date) => formikProps.setFieldValue('trialPeriodEndTimestamp', date)}
							error={formikProps.touched.trialPeriodEndTimestamp && !!formikProps.errors.trialPeriodEndTimestamp}
							helperText={formikProps.touched.trialPeriodEndTimestamp && formikProps.errors.trialPeriodEndTimestamp}
							disabled={currentUser.identity.type !== C.IdentityType.SuperUser}
						/>}

						{props.addAssetView && <>
							{featureToggleAssetTypes === 'true' && assetTypeOptions && <FormikSelect
								name="assetTypeId"
								label="Asset Type"
								form={formikProps}
								disabled={!formikProps.values.clientId}
								options={assetTypeOptions || []}
								getOptionValue={x => x.id}
								getOptionLabel={x => x.name}
								clearable
							/>}

							<Field
								name="speedLimitEnabled"
								label="Enable Speed Limit"
								type="checkbox"
								component={FormikCheckbox}
							/>

							<Field
								name="speedLimit"
								label="Speed Limit"
								type="number"
								component={FormikTextField}
								disabled={!formikProps.values.speedLimitEnabled}
								helperText="Speed alerts will only be triggered if the asset sends location updates with travel speed information."
								InputProps={{
									endAdornment: <InputAdornment position="end">{currentUser.usesMetric ? 'km/h' : 'mph' }</InputAdornment>,
								}}
							/>

							<Field
								name="emergencyNotes"
								label="Emergency Notes"
								type="string"
								component={FormikTextField}
								helperText="Emergency notes will be displayed if the asset triggers an emergency alert."
								multiline={true}
							/>
						</>}
					</div>

					<div className="content-box form-card">
						{props.addAssetView && <>
							<Field
								name="deviceSameNameAsAsset"
								label="Use asset name as device name"
								type="checkbox"
								component={FormikCheckbox}
							/>

							{!formikProps.values.deviceSameNameAsAsset && <Field
								name="deviceName"
								label="Device Name"
								type="text"
								component={FormikTextField}
								required
							/>}
						</>}

						<FormikSelect
							name="deviceType"
							label="Device Type"
							required
							form={formikProps}
							options={deviceTypeOptions}
							getOptionValue={x => x.value}
							getOptionLabel={x => x.label}
							disabled={!!props.device}
						/>

						{formikProps.values.deviceType && <Field
							name="serialNumber"
							label="Serial Number"
							type="text"
							component={FormikTextField}
						/>}

						{formikProps.values.deviceType === DeviceType.ATRACK && <ManageATrackDeviceComponent form={formikProps} />}
						{formikProps.values.deviceType === DeviceType.CAMPER_VAN && <ManageCamperVanDeviceComponent form={formikProps} />}
						{formikProps.values.deviceType === DeviceType.DIGITAL_MATTER && <ManageDigitalMatterDeviceComponent form={formikProps} />}
						{formikProps.values.deviceType === DeviceType.EROAD && <ManageEroadDeviceComponent form={formikProps} />}
						{formikProps.values.deviceType === DeviceType.HYTERA_RADIO && <ManageHyteraRadioDeviceComponent form={formikProps} dealers={props.dealers} networks={props.networks} bluetoothBeaconSets={bluetoothBeaconSetOptions} hasDealerSelection={hasDealerSelection} />}
						{formikProps.values.deviceType === DeviceType.TAIT_RADIO && <ManageTaitRadioDeviceComponent form={formikProps} dealers={props.dealers} networks={props.networks} bluetoothBeaconSets={bluetoothBeaconSetOptions} hasDealerSelection={hasDealerSelection} />}
						{formikProps.values.deviceType === DeviceType.MOBILE_PHONE && <ManageMobilePhoneDeviceComponent form={formikProps} dealers={props.dealers} networks={props.networks} bluetoothBeaconSets={bluetoothBeaconSetOptions} hasDealerSelection={hasDealerSelection} />}
						{formikProps.values.deviceType === DeviceType.TELTONIKA && <ManageTeltonikaDeviceComponent form={formikProps} />}
					</div>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						loading={formikProps.isSubmitting}
						startIcon={addingNewDevice ? null : <SaveIcon />}
						text={submitButtonText}
					/>
				</Form>;
			}}
		</Formik>
	</FixedWidthPage>;
});
