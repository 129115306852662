import mapboxgl from 'mapbox-gl';

import * as C from 'src/services/client';

export const pixelSize = 0.00001;

export function convertMapBoxLatLngToXyDto(lngLat: mapboxgl.LngLatLike): C.IXyDto {
	const ll = mapboxgl.LngLat.convert(lngLat);
	return { x: ll.lng / pixelSize, y: ll.lat / -pixelSize };
}

export function convertFloorPlanXyToLatLng(floorPlan: C.IFloorPlanDto, xy: C.IXyDto): C.ILatLngDto {
	const xPercent = xy.x / floorPlan.imageWidth;
	const yPercent = xy.y / floorPlan.imageHeight;

	const top_lng = floorPlan.topLeft.longitude + ((floorPlan.topRight.longitude - floorPlan.topLeft.longitude) * xPercent);
	const top_lat = floorPlan.topLeft.latitude + ((floorPlan.topRight.latitude - floorPlan.topLeft.latitude) * xPercent);

	const bottom_lng = floorPlan.bottomLeft.longitude + ((floorPlan.bottomRight.longitude - floorPlan.bottomLeft.longitude) * xPercent);
	const bottom_lat = floorPlan.bottomLeft.latitude + ((floorPlan.bottomRight.latitude - floorPlan.bottomLeft.latitude) * xPercent);

	const lng = top_lng + ((bottom_lng - top_lng) * yPercent);
	const lat = top_lat + ((bottom_lat - top_lat) * yPercent);

	return { latitude: lat, longitude: lng };
}

export function convertLatLngToXy(floorPlan: C.IFloorPlanDto, latitude: number, longitude: number, useFloorPlanCoordinates: boolean = false): [x: number, y: number] {
	// We calculate the X, Y coordinates by treating the floor plan as if it was on a flat plane (ignoring the curvature of the world
	// as it is not significant for the area the size of a building).

	// If the image is placed square on the world, i.e. no rotation, then gradient calculations will return 0 and infinity and are therefore unusable.
	// However this is the trivial case for finding image X and Y coordinates so it is easy to transform latitude and longitude to X and Y image coordinates.

	// If latitude for top left and right corners are the same then there is no image rotation.
	if (floorPlan.topLeft.latitude === floorPlan.topRight.latitude)
	{
		// Find the 'length' of the vertical and horizontal edges in terms of latitude and longitude (respectively).
		const lat_delta = floorPlan.topRight.latitude - floorPlan.bottomRight.latitude
		const lng_delta = floorPlan.topRight.longitude - floorPlan.topLeft.longitude;

		// Find the percentage of how far the beacon is along the horizontal and vertical edges of the image.
		const lat_percent = (latitude - floorPlan.bottomLeft.latitude) / lat_delta;
		const lng_percent = (longitude - floorPlan.bottomLeft.longitude) / lng_delta;

		// Calculate X and Y by multiplying the percentage by the image height (Y needs to be subtracted from total image height to get position from top).
		const xy = [floorPlan.imageWidth * lng_percent, (floorPlan.imageHeight - (floorPlan.imageHeight * lat_percent))];

		// Return pixel adjusted values if required.
		return useFloorPlanCoordinates
			? [xy[0] * pixelSize, xy[1] * -pixelSize]
			: [xy[0], xy[1]];
	}

	// If the image is on an angle, gradient calculations need to be performed to determine where the supplied coordinates are on the rotated image.

	// To calculate X:
	// We get the line from the left edge of the floor plan and shift it across so that it passes through the requested point instead.
	// We can then calculate the point where the shifted line intercepts with the line from the bottom edge of the floor plan.
	// If the distance from the bottom left of the floor plan to the bottom right of the floor plan is 100%, then the distance from
	// the bottom left of the floor plan to the intercept point is the percentage of the image width that X sits at.
	// Multiplying this percentage by the image width gives us X.
	//
	// This process is repeated for Y, by shifting the bottom edge of the floor plan and finding where it intercepts with the left edge of the floor plan.

	// y = mx + c

	// Calculate m (the gradient) of the horizontal and vertical edges of the floor plan.
	const m_horizontal = (floorPlan.bottomRight.latitude - floorPlan.bottomLeft.latitude) / (floorPlan.bottomRight.longitude - floorPlan.bottomLeft.longitude);
	const m_vertical = (floorPlan.topLeft.latitude - floorPlan.bottomLeft.latitude) / (floorPlan.topLeft.longitude - floorPlan.bottomLeft.longitude);

	// Calculate c (the y-intercept) when the gradient lines are passing through the given point.
	const c_horizontal_point = latitude - (m_horizontal * longitude);
	const c_vertical_point = latitude - (m_vertical * longitude);

	// Calculate c (the y-intercept) when the gradient lines are passing through the bottom left floor plan coordinate.
	const c_horizontal_bottomLeft = floorPlan.bottomLeft.latitude - (m_horizontal * floorPlan.bottomLeft.longitude);
	const c_vertical_bottomLeft = floorPlan.bottomLeft.latitude - (m_vertical * floorPlan.bottomLeft.longitude);

	// Find the point on the horizontal edge of the floor plan where the shifted vertical edge intercepts.
	const horizontal_x = (c_vertical_point - c_horizontal_bottomLeft) / (m_horizontal - m_vertical);
	const horizontal_y = (m_horizontal * horizontal_x) + c_horizontal_bottomLeft;

	// Find the point on the vertical edge of the floor plan where the shifted horizontal edge intercepts.
	const vertical_x = (c_horizontal_point - c_vertical_bottomLeft) / (m_vertical - m_horizontal);
	const vertical_y = (m_vertical * vertical_x) + c_vertical_bottomLeft;

	// Calculate the distances between:
	//     - the bottom left corner of the floor plan to the horizontal intercept point.
	//     - the bottom left corner of the floor plan to the bottom right corner.
	// The x location of the result is the percentage across of the intercept point multiplied by the floor plan image width.
	const horizontalDistance_point = Math.sqrt(Math.pow(horizontal_x - floorPlan.bottomLeft.longitude, 2) + Math.pow(horizontal_y - floorPlan.bottomLeft.latitude, 2));
	const horizontalDistance_bottomRight = Math.sqrt(Math.pow(floorPlan.bottomRight.longitude - floorPlan.bottomLeft.longitude, 2) + Math.pow(floorPlan.bottomRight.latitude - floorPlan.bottomLeft.latitude, 2));
	const x = (horizontalDistance_point / horizontalDistance_bottomRight) * floorPlan.imageWidth;

	// Calculate the distances between:
	//     - the bottom left corner of the floor plan to the vertical intercept point.
	//     - the bottom left corner of the floor plan to the top left corner.
	// The y location of the result is the percentage up of the intercept point multiplied by the floor plan image height.
	const verticalDistance_point = Math.sqrt(Math.pow(vertical_x - floorPlan.bottomLeft.longitude, 2) + Math.pow(vertical_y - floorPlan.bottomLeft.latitude, 2));
	const verticalDistance_topLeft = Math.sqrt(Math.pow(floorPlan.topLeft.longitude - floorPlan.bottomLeft.longitude, 2) + Math.pow(floorPlan.topLeft.latitude - floorPlan.bottomLeft.latitude, 2));
	const y = floorPlan.imageHeight - ((verticalDistance_point / verticalDistance_topLeft) * floorPlan.imageHeight);

	return useFloorPlanCoordinates
			? [x * pixelSize, y * -pixelSize]
			: [x, y];
}
