import React from 'react';
import { match } from 'react-router-dom';
import { QueryReport_reportById } from 'src/graphql/__generated__/queries/queryReport';

import moment from 'moment-timezone';

import {
	AuthenticationService,
	Client as C,
	DistanceService,
	DistanceUnit,
	ReportService,
	Service,
	useInjection,
} from 'src/services';

import { FixedWidthPage, ThingLoader, ReportParametersDisplay } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';
import { calculateAndFormatLongDuration } from 'src/util/durationFormats';

import './reportViewer.scss';

export interface Props {
	match: match<{ id: string }>;
}

interface ReportViewData {
	report: QueryReport_reportById;
	reportData: C.IGeofenceReportData;
}

export const ViewGeofenceReport = (props: Props) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _distanceService = useInjection<DistanceService>(Service.DistanceService);
	const _reportService = useInjection<ReportService>(Service.Report);

	const load = async (reportId: string): Promise<ReportViewData> => {
		const report = _reportService.getReportById(reportId);
		const reportData = _reportService.getGeofenceReportData(reportId);

		return {
			report: await report,
			reportData: await reportData,
		};
	};

	const renderRow = (action: C.IReportItem, reportViewData: ReportViewData, rowNumber: number) => {
		const reportData = reportViewData.reportData;

		const asset = reportData.assets.find(x => x.assetId === action.assetId)!;
		const geofence = reportData.geofences.find(x => x.geofenceId === action.geofenceId)!;

		// If the assets exit time is before the report end, format the time the asset exited the geofence.
		let exitMessage = action.exited && action.exited.format(longDateTimeFormat);
		if (action.exitedAfterReportEnd)
			exitMessage = 'Asset is inside geofence at report end.';

		// If the asset has entered but not exited the geofence (null exit time), calculate their duration in the geofence until the end of the report.
		let timeInGeofence = action.exited && calculateAndFormatLongDuration(action.entered, action.exited);
		if (action.entered && action.exitedAfterReportEnd)
			timeInGeofence = calculateAndFormatLongDuration(action.entered, moment(reportViewData.report.parameters!.endTimestamp));

		return <tr
			key={rowNumber}
			className="content-box"
		>
			<td>{asset.name}</td>
			<td>{geofence.name}</td>
			<td>{action.enteredBeforeReportStart ? 'Geofence entered before report period.' : action.entered!.format(longDateTimeFormat)}</td>
			<td>{exitMessage}</td>
			<td>{timeInGeofence}</td>
			<td>{action.distanceTravelledInGeofence && _distanceService.formatDistance(action.distanceTravelledInGeofence, _authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles)}</td>
		</tr>;
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(reportViewData: ReportViewData) => <FixedWidthPage
			headingText="Geofence Report"
			subheadingText={reportViewData.report.name}
			noContentBackground
			contentClassName="report-viewer"
		>
			{reportViewData.report.parameters && <ReportParametersDisplay
				reportParameters={reportViewData.report.parameters}
			/>}

			<div>
				<div className="report-section">
					<table className="card-table">
						<thead>
							<tr>
								<th>Asset</th>
								<th>Geofence</th>
								<th>Entered</th>
								<th>Exited</th>
								<th>Time in geofence</th>
								<th>Distance in geofence</th>
							</tr>
						</thead>

						<tbody>
							{reportViewData.reportData.reportItems.map((x, i) => renderRow(x, reportViewData, i))}
						</tbody>
					</table>
				</div>
			</div>
		</FixedWidthPage>}
	/>;
};
