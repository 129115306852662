import React from 'react';

import { Button, FixedWidthPage, ThingLoader } from 'src/components';

import {
	AuthenticationService,
	Client as C,
	Service,
	ToasterService,
	useInjection,
	UsersService,
} from 'src/services';

import { EditUserDetailsComponent } from './editUserDetailsComponent';

import './account.scss';

export const Account = () => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _userService = useInjection<UsersService>(Service.Users);

	const loadUser = async (userId: string) => {
		return await _userService.getUser(userId);
	};

	const onEditSuccess = () => {
		_toasterService.showSuccess('Account details saved successfully.');
		_authService.checkForCurrentAuthUserUpdate();
	};

	return <ThingLoader
		id={_authService.currentAuth.user.userId}
		load={loadUser}
		render={(user: C.IUserDto) => {
			return <FixedWidthPage
				className="form-page account"
				headingText="Account"
				subheadingText={user.name}
				noContentBackground
			>
				<div className="content-box actions">
					<Button
						className="fill"
						variant="outlined" color="primary"
						href="/app/account/change-password"
						text="Change Password"
					/>

					{user.identity.type == C.IdentityType.Client && <Button
						href={`/app/users/${user.userId}/settings/alerts`}
						text="Manage Alert & Emergency Settings"
						className="fill"
						variant="outlined" color="primary"
					/>}
				</div>

				<div className="content-box">
					<EditUserDetailsComponent
						user={user}
						onEditSuccess={onEditSuccess}
					/>
				</div>
			</FixedWidthPage>;
		}}
	/>;
};
