import React from 'react';
import { observer } from 'mobx-react';
import { css } from '@emotion/css';
import { Formik, FormikHelpers, FormikProps, Form, Field, FormikErrors } from 'formik';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';

import { ATrackDeviceModelType, DeviceType, TeltonikaDeviceModelType } from 'src/../__generated__/globalTypes';
import { getBillingTypeOptions } from 'src/app/devices/deviceBillingOptions';
import { Button, FixedWidthPage, FormikTextField, FormikSelect } from 'src/components';
import { ReadFileToBase64String } from 'src/util/fileToBytesStringHelper';
import { getPrettyName, IOption, runFormValidation } from 'src/util';

import {
	Client as C,
	AssetService,
	AuthenticationService,
	HistoryService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

interface ImportAssetsValues {
	deviceType: C.DeviceType | null;
	file: string | null;
}

const validateForm = (values: ImportAssetsValues, errors: FormikErrors<ImportAssetsValues>) => {
	if (!values.deviceType)
		errors.deviceType = 'Device type is required.';

	if (!values.file)
		errors.file = 'File is required.';
}

const importAssetsFromFileFormStyles = css`
	.download-template-button {
		align-self: flex-end;
		max-width: 250px;
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.content-box {
		margin-bottom: 15px;
		display: flex;
		flex-direction: column;
	}

	.id-copy-icon {
		width: 15px;
		height: 15px;
	}
`;

const acceptedValuesTable = css`
	border: 1px solid #a7a5a5;
	border-collapse: collapse;
	margin-bottom: 10px;

	tr, th, td {
		padding: 10px;
		border: inherit;
	}

	th {
		background-color: #e8e8e8;
		text-align: left;
	}

	td {
		vertical-align: top;
	}
`;

const downloadTemplateFileButton = css`
	margin-top: 15px !important;
	width: 250px;
	align-self: flex-end;
`;

const uniqueIdSeeBelowMessage = 'Unique identifier (see below for more information).';
const booleanDescriptionMessage = `One of: TRUE, FALSE`;
const deviceModelOptions = [ ATrackDeviceModelType.AK1, ATrackDeviceModelType.AK7, ATrackDeviceModelType.AK7V, ATrackDeviceModelType.AL7, TeltonikaDeviceModelType.FMC130, TeltonikaDeviceModelType.FMC230, TeltonikaDeviceModelType.FMC640 ];

export const ImportAssetsFromFile = observer(() => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const assetService = useInjection<AssetService>(Service.Asset);
	const historyService = useInjection<HistoryService>(Service.History);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [currentFile, setFile] = React.useState<File | null>(null);

	const configurationOnlyDealer = authenticationService.currentAuth.user.identity.dealer?.type === C.DealerType.ConfigurationOnly;
	const currentUserType = authenticationService.currentAuth.user.identity.type;

	const billingTypeOptions = getBillingTypeOptions(authenticationService.currentAuth.user.identity, null);
	const billingTypes = billingTypeOptions.map(x => x.value);

	const initialValues: ImportAssetsValues = {
		file: null,
		deviceType: getDeviceTypesCanAdd()[0].value,
	};

	async function onSubmit(values: ImportAssetsValues, { setSubmitting }: FormikHelpers<ImportAssetsValues>) {
		setSubmitting(true);

		try {
			let failed = false;
			const failedMessage = 'Failed to read file, please try again.';
			const base64FileString = await ReadFileToBase64String(currentFile as File, failedMessage, () => {
				toasterService.showDanger(failedMessage);
				failed = true;
			});

			if (failed)
				return;

			const importFromFileRequest: C.IImportFromFileRequest = {
				fileBytes: base64FileString,
				deviceType: values.deviceType!,
			};

			await assetService.uploadAssetImportFile(importFromFileRequest);

			toasterService.showSuccess('Successfully imported assets from the selected file.');
			historyService.history.push('/app/assets/list');
		} catch (error) {
			toasterService.handleWithToast(error, 'Failed to upload or process the selected file.');
		}

		setSubmitting(false);
	}

	function handleFileSelected(evt: React.ChangeEvent<HTMLInputElement>, formikProps: FormikProps<ImportAssetsValues>) {
		const files = evt.target.files;

		if (files) {
			formikProps.setFieldValue('file', evt.target.value);
			formikProps.setFieldTouched('file');

			const file = files[0];
			if (file)
				setFile(file);
		}
	}

	async function downloadTemplateFile(formikProps: FormikProps<ImportAssetsValues>) {
		if (!formikProps.values.deviceType) {
			formikProps.setFieldTouched('deviceType', true, false);
			formikProps.setFieldError('deviceType', 'Device type is required to download a template file.');
			return;
		}

		try {
			const file = await assetService.downloadAssetImportTemplateFile(formikProps.values.deviceType!);

			const a = document.createElement('a');
			a.href = window.URL.createObjectURL(file.data);
			a.download = file.fileName || `${formikProps.values.deviceType}AssetsImportTemplate.csv`;

			a.click();
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to download assets import template file.');
		}
	}

	function getAcceptedValues() : {valueName: string, valueDescription: string}[] {
		const acceptedValuesArray: {valueName: string, valueDescription: string}[] = [
			{valueName: 'AssetTypeId', valueDescription: uniqueIdSeeBelowMessage},
			{valueName: 'BillingType', valueDescription: `One of: ${billingTypes.join(', ')}`},
			{valueName: 'ClientId', valueDescription: uniqueIdSeeBelowMessage},
			{valueName: 'Name', valueDescription: 'Name of the asset.'},
			{valueName: 'RadioId', valueDescription: `Radio ID on the network.`},
			{valueName: 'SelCallId', valueDescription: 'SelCall ID of the radio.'},
			{valueName: 'SerialNumber', valueDescription: 'Serial number of the asset.'},
			{valueName: 'NetworkId', valueDescription: uniqueIdSeeBelowMessage},
			{valueName: 'SpeedLimit', valueDescription: 'Speed limit of the asset.'},
		];

		if (currentUserType === C.IdentityType.SuperUser)
			acceptedValuesArray.push({valueName: 'DealerId', valueDescription: uniqueIdSeeBelowMessage});

		if (!configurationOnlyDealer) {
			acceptedValuesArray.push({valueName: 'BleEnabled', valueDescription: booleanDescriptionMessage});
			acceptedValuesArray.push({valueName: 'BleBeaconSetIds', valueDescription: uniqueIdSeeBelowMessage + ` To add multiple beacon sets, add additional columns with this header (BleBeaconSetIds).`});
			acceptedValuesArray.push({valueName: 'CallRecordingEnabled', valueDescription: booleanDescriptionMessage});
			acceptedValuesArray.push({valueName: 'ChatterPttUserId', valueDescription: `ChatterPTT User ID associated with the device.`});
			acceptedValuesArray.push({valueName: 'DeviceModelType', valueDescription: `One of: ${deviceModelOptions.join(', ')}`});
			acceptedValuesArray.push({valueName: 'GpsEnabled', valueDescription: booleanDescriptionMessage});
			acceptedValuesArray.push({valueName: 'Imei', valueDescription: 'IMEI of the device.'});
			acceptedValuesArray.push({valueName: 'ManualPollingEnabled', valueDescription: booleanDescriptionMessage});
			acceptedValuesArray.push({valueName: 'PendingConfigurationId', valueDescription: uniqueIdSeeBelowMessage});
			acceptedValuesArray.push({valueName: 'PhoneNumber', valueDescription: 'International mobile phone number (beginning with +) of the device.'});
			acceptedValuesArray.push({valueName: 'VehiclePlate', valueDescription: '1–6 alphanumeric characters.'});

			if (currentUserType === C.IdentityType.SuperUser)
				acceptedValuesArray.push({valueName: 'TrialPeriodMonths', valueDescription: '0 or more months.'});
			else if (currentUserType === C.IdentityType.Dealer && authenticationService.currentAuth.user.identity.dealer?.maxTrialMonths !== 0)
				acceptedValuesArray.push({valueName: 'TrialPeriodMonths', valueDescription: `Between 0 and ${authenticationService.currentAuth.user.identity.dealer?.maxTrialMonths} months.`});
		}

		// Sort by value names and then return.
		return acceptedValuesArray.sort((a, b) => a.valueName.localeCompare(b.valueName, undefined, { numeric: true }));
	}

	function getDeviceTypesCanAdd(): IOption<C.DeviceType>[] {
		const deviceTypes: IOption<C.DeviceType>[] = [];

		const generalPermissions = authenticationService.currentAuth.permissions.general;
		if (generalPermissions.addATrackDevices)
			deviceTypes.push({ label: getPrettyName(DeviceType.ATRACK), value: C.DeviceType.ATrack });

		if (generalPermissions.addCamperVanDevices)
			deviceTypes.push({ label: getPrettyName(DeviceType.CAMPER_VAN), value: C.DeviceType.CamperVan });

		if (generalPermissions.addDigitalMatterDevices)
			deviceTypes.push({ label: getPrettyName(DeviceType.DIGITAL_MATTER), value: C.DeviceType.DigitalMatter });

		if (generalPermissions.addEroadDevices)
			deviceTypes.push({ label: getPrettyName(DeviceType.EROAD), value: C.DeviceType.Eroad });

		if (generalPermissions.addHyteraRadioDevices)
			deviceTypes.push({ label: getPrettyName(DeviceType.HYTERA_RADIO), value: C.DeviceType.HyteraRadio });

		if (generalPermissions.addMobilePhoneDevices)
			deviceTypes.push({ label: getPrettyName(DeviceType.MOBILE_PHONE), value: C.DeviceType.MobilePhone });

		if (generalPermissions.addTaitRadioDevices)
			deviceTypes.push({ label: getPrettyName(DeviceType.TAIT_RADIO), value: C.DeviceType.TaitRadio });

		if (generalPermissions.addTeltonikaDevices)
			deviceTypes.push({ label: getPrettyName(DeviceType.TELTONIKA), value: C.DeviceType.Teltonika });

		return deviceTypes;
	}

	return <FixedWidthPage
		className={`form-page ${importAssetsFromFileFormStyles}`}
		headingText="Import assets from a file"
		noContentBackground
	>
		<Formik
			initialValues={initialValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={true}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ImportAssetsValues>) => <Form className="formik-form">
				<div className="content-box">
					<FormikSelect
						name="deviceType"
						label="Device type"
						placeholder="Select a device type..."
						options={getDeviceTypesCanAdd()}
						form={formikProps}
						getOptionLabel={option => option.label}
						getOptionValue={option => option.value}
						helperText=""
					/>

					<Button
						className={downloadTemplateFileButton}
						text="Download Template File"
						color="primary"
						variant="contained"
						onClick={() => downloadTemplateFile(formikProps)}
						disabled={!formikProps.values.deviceType}
					/>
				</div>

				<div className="content-box">
					<table className={acceptedValuesTable}>
						<tr>
							<th>Field</th>
							<th>Description</th>
						</tr>

						{getAcceptedValues().map(x => <tr>
							<td>{x.valueName}</td>
							<td>{x.valueDescription}</td>
						</tr>)}
					</table>
				</div>

				<div className="content-box">
					<p>
						Please note:

						<ul>
							<li>Do not add extra header values to the template file (unless noted).</li>
							<li>Do not change the header values supplied in template file.</li>
							<li>The device type selected must match the device type of the file uploaded.</li>
						</ul>
					</p>

					<p>
						<strong>What is a unique identifier/where do I find it?</strong><br />
						Every item in the system has a unique ID associated with it. An example is: <em>35aad943-c657-45cc-62b4-08d82d0621e4</em>.
						To find the unique ID for an item, first navigate to that item. The copy icon/button (<FileCopyIcon className="id-copy-icon"/>)
						next to the name of the item will copy the ID to your clipboard.
					</p>
				</div>

				<div className="content-box">
					<h2>Upload a file</h2>

					<Field
						name="file"
						label="Assets Import File"
						type="file"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileSelected(e, formikProps)}
						onBlur={() => formikProps.setFieldTouched('file', true)}
						inputProps={{accept: '.csv'}}
						variant="filled"
						InputLabelProps={{
							shrink: true,
						}}
						component={FormikTextField}
					/>

					<Button
						type="submit"
						text="Submit File"
						color="primary"
						variant="contained"
						startIcon={<SaveIcon />}
						loading={formikProps.isSubmitting}
					/>
				</div>
			</Form>}
		/>
	</FixedWidthPage>;
});
