import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateDealer($input: UpdateDealerInput!) {
		updateDealer(input: $input) {
			dealer {
				id
				name
			}
		}
	}
`;
