import React from 'react';
import { Router as ReactRouter, Route, Switch, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import './services/inversify';
import './reactions';
import { SnackbarUtilsConfigurator } from './services/toasterService';

import {
	Container,
	HistoryService,
	ToasterService,
	Service,
} from './services';

import { Auth } from './auth';
import { App } from './app';
import { Emergency } from './emergency';

WebFont.load({
	google: {
		families: ['Roboto', 'sans-serif']
	}
});

const theme = createTheme({
	palette: {
		primary: {
			main: '#1e88e5'
		}
	},
});

const history = Container.get<HistoryService>(Service.History);
//Creating the toaster service touches react, so we ensure it exists here to prevent react errors later
Container.get<ToasterService>(Service.Toaster);

// Never display "a few seconds ago", use the actual number of seconds instead.
// Use the "correct" time thresholds (e.g. only say "an hour ago" when 60 minutes
// has passed, Moment defaults to 45 minutes).
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('ss', 0);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 28);
moment.relativeTimeThreshold('M', 12);

const apolloClient = Container.get<ApolloClient<InMemoryCache>>(Service.ApolloClient);

export const Router = () => {
	return <MuiThemeProvider theme={theme}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
				<SnackbarUtilsConfigurator />

				<ApolloProvider client={apolloClient}>
					<ReactRouter history={history.history}>
						<Switch>
							<Route path="/app" component={App} />
							<Route path="/auth" component={Auth} />
							<Route path="/emergency" component={Emergency} />
							<Route exact path="/privacy-policy" component={Auth} />
							<Route component={() => <Redirect to="/auth/sign-in" />} />
						</Switch>
					</ReactRouter>
				</ApolloProvider>
			</SnackbarProvider>
		</MuiPickersUtilsProvider>
	</MuiThemeProvider>;
};

export default Router;
