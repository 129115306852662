import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { red } from '@material-ui/core/colors';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Button } from './button';
import { CustomActionDialogBox } from './customActionDialogBox';
import { Service, ToasterService, useInjection } from 'src/services';

import { useMutationUnassignDeviceFromAsset } from 'src/graphql/__generated__/mutations/mutationUnassignDeviceFromAsset';

export interface UnassignAssetDevice {
	asset: {
		id: string;
		name: string;
	};
	device: {
		id: string;
		name: string;
	};
}

interface Props {
	assetDevice: UnassignAssetDevice;
	close: () => void;
	onSuccess?: () => Promise<any>;
}

export const UnassignDeviceFromAssetDialog = observer((props: Props) => {
	const toasterService = useInjection<ToasterService>(Service.Toaster);
	const [acknowledgedWarning, setAcknowledgedWarning] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [unassignDeviceFromAsset] = useMutationUnassignDeviceFromAsset();

	const submit = async () => {
		setLoading(true);

		try {
			await unassignDeviceFromAsset({
				variables: {
					input: {
						assetId: props.assetDevice.asset.id,
						deviceId: props.assetDevice.device.id,
					},
				},
			});

			props.onSuccess && await props.onSuccess();
			props.close();
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to unassign device.');
			setLoading(false);
		}
	};

	return <CustomActionDialogBox
		title="Unassign Device From Asset"
		actionButton={<Button
			variant="contained"
			color={red}
			text="Unassign"
			onClick={submit}
			disabled={!acknowledgedWarning}
			loading={loading}
		/>}
		dialogCloseCallback={props.close}
	>
		<DialogContentText>
			If you continue, the device <strong>{props.assetDevice.device.name}</strong> will be unassigned from the asset <strong>{props.assetDevice.asset.name}</strong>.
		</DialogContentText>

		<DialogContentText>
			If unassigned:
		</DialogContentText>

		<DialogContentText component="ul">
			<li>Any physical device(s) (e.g. mobile phones) linked to the selected device(s) will be unlinked.</li>
			<li>The selected device(s) will not be able to record any new information <strong>(including locations and emergencies)</strong>.</li>
		</DialogContentText>

		<FormControlLabel
			control={<Checkbox
				onChange={(_, checked) => setAcknowledgedWarning(checked)}
				checked={acknowledgedWarning}
				color="primary"
				disabled={loading}
			/>}
			label="I acknowledge that I have read and understand the above information."
		/>
	</CustomActionDialogBox>;
});
