import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import GroupAddIcon from '@material-ui/icons/GroupAdd';

import { FixedWidthPage, MessagePage, ThingLoader, Button, PopoverMenu, PopoverMenuItem } from 'src/components';

import {
	Client as C,
	AuthenticationService,
	UserGroupService,
	Service,
	useInjection,
} from 'src/services';

export const UserGroupsList = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);

	const loadUserGroups = async () => {
		const userGroups = await _userGroupService.getAllUserGroups();
		if (!userGroups)
			return null;

		return userGroups.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }) || a.client.name.localeCompare(b.client.name, undefined, { numeric: true }));
	};

	const renderActionMenu = (userGroup: C.IUserGroupDto) => {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/user-groups/${userGroup.userGroupId}/edit`}
			/>,
		];
	};

	return <ThingLoader
		load={loadUserGroups}
		render={(userGroups: C.IUserGroupDto[]) => <FixedWidthPage
			headingText="User Groups"
			headingActions={<Button href="/app/user-groups/add" text="Add" startIcon={<GroupAddIcon />} variant="outlined" color="primary"/>}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': userGroups.length === 0 })}
		>
			{userGroups.length > 0 && <table className="card-table">
				<thead>
					<tr>
						<th>Group Name</th>
						{_authService.currentAuth.user.identity.type !== C.IdentityType.Client && <th>Client</th>}
					</tr>
				</thead>

				<tbody>
					{userGroups.map(x => <tr
						key={x.userGroupId}
						className="content-box"
					>
						<td>{x.name}</td>

						{_authService.currentAuth.user.identity.type !== C.IdentityType.Client && <td>{x.client.name}</td>}

						<td className="actions">
							<PopoverMenu
								renderOptions={() => renderActionMenu(x)}
							/>
						</td>
					</tr>)}
				</tbody>
			</table>}

			{userGroups.length === 0 && <MessagePage
				title="No user groups."
				action={<Button href="/app/user-groups/add" text="Add a user group?" variant="outlined" />}
			/>}
		</FixedWidthPage>}
	/>;
});
