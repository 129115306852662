import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { withStyles, Theme, } from '@material-ui/core/styles';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';

import './fixedWidthPage.scss';

import {
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

interface FixedWidthPageProps {
	headingText?: string | JSX.Element;
	pageItemId?: string | null;
	pageName?: string | JSX.Element;
	subheadingText?: string | JSX.Element | null;
	headingActions?: JSX.Element | (JSX.Element | null)[] | null;
	className?: string;
	contentClassName?: string;
	noContentBackground?: boolean | null;
	contentMessageReplace?: JSX.Element | null | false;
	children?: React.ReactNode;
}

const CopyIdButton = withStyles((theme: Theme) => ({
	root: {
		width: '15px',
		height: '15px',

		'& span': {
			'& svg': {
				width: '15px',
				height: '15px',
			}
		}
	},
}))(IconButton);

const PageHeading = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const SubHeading = styled.span`
	flex-basis: 100%;
`;

export const FixedWidthPage = (props: FixedWidthPageProps) => {
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const copyToIdToClipboard = () => {
		navigator.clipboard.writeText(props.pageItemId!);
		_toasterService.showSuccess(`Copied ID to clipboard.`);
	};

	const hasHeader = props.headingText != null || props.headingActions != null;
	const hasContentReplace = props.contentMessageReplace != null && props.contentMessageReplace !== false;
	const noContentBackground = props.noContentBackground || hasContentReplace;
	const content = hasContentReplace ? props.contentMessageReplace : props.children;

	let headingText: JSX.Element | undefined = undefined;
	if (props.headingText) {
		headingText = <>
			{props.headingText}
			{props.pageName && <span className="heading-with-actions__page-name"> {props.pageName}</span>}
		</>;
	}

	return <div className={classNames('flex-fill', 'flex-column-children', 'fixed-width', props.className)}>
		{hasHeader && <div className="heading-with-actions no-flex">
			<PageHeading>
				<h1>{headingText}</h1>
				{headingText && props.pageItemId && <CopyIdButton
					title={`Copy ID`}
					onClick={copyToIdToClipboard}
				>
					<FileCopyIcon />
				</CopyIdButton>}
				{props.subheadingText && <SubHeading>{props.subheadingText}</SubHeading>}
			</PageHeading>

			<div>
				{props.headingActions}
			</div>
		</div>}

		<div className={classNames(
			'fixed-width-page-parent',
			props.contentClassName,
			{'content-box': !noContentBackground},
			{'flex-fill-no-overflow': hasContentReplace},
		)}>
			{content}
		</div>
	</div>;
};
