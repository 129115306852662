import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { ButtonProps } from './button';

interface Props {
	children?: React.ReactElement<ButtonProps>;
	renderOptions: () => (JSX.Element | null)[];
	disabled?: boolean;
	stayOpenOnClick?: boolean;
}

const useStyles = makeStyles(theme => ({
	paper: {
		boxShadow: '0px 0px 10px 0px rgba(57, 75, 89, 0.25)',
	},
	popper: {
		zIndex: 999,
		'&[x-placement*="bottom"] $arrow': {
			top: 0,
			left: 0,
			marginTop: '-0.9em',
			width: '3em',
			height: '1em',
			'&::before': {
				borderWidth: '0 1em 1em 1em',
				borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
			},
		},
		'&[x-placement*="top"] $arrow': {
			bottom: 0,
			left: 0,
			marginBottom: '-0.9em',
			width: '3em',
			height: '1em',
			'&::before': {
				borderWidth: '1em 1em 0 1em',
				borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
			},
		},
	},
	arrow: {
		position: 'absolute',
		fontSize: 7,
		width: '3em',
		height: '3em',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid',
		},
	},
}));

export const PopoverMenu = (props: Props) => {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (!props.stayOpenOnClick)
			setOpen(false);
	};

	const button = props.children
		? React.cloneElement(props.children, {
			ref: anchorRef,
			onClick: handleToggle,
			'aria-controls': open ? 'menu-list-grow' : undefined,
			'aria-haspopup': 'true',
			disabled: props.disabled,
		})
		: <IconButton
			ref={anchorRef}
			onClick={handleToggle}
			aria-controls={open ? 'menu-list-grow' : undefined}
			aria-haspopup="true"
			size="small"
			disabled={props.disabled}
		>
			<ArrowDropDownIcon />
		</IconButton>;

	return <>
		{button}

		<Popper
			open={open}
			placement="bottom-end"
			anchorEl={anchorRef.current}
			transition
			className={classes.popper}
			modifiers={{
				arrow: {
					enabled: true,
					element: arrowRef,
				},
			}}
		>
			{({ TransitionProps }) => (
				<Grow {...TransitionProps} style={{ transformOrigin: '50% 0 0' }}>
					<Paper className={classes.paper}>
						<div className={classes.arrow} ref={setArrowRef} />

						<ClickAwayListener onClickAway={handleClose}>
							<MenuList autoFocusItem={open} id="menu-list-grow" onClick={handleClose}>
								{props.renderOptions()}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	</>;
};
