import { inject, injectable } from 'inversify';

import * as C from './client';
import * as TileServerClient from './tileServerClient';
import { Service } from './service';

@injectable()
export class MapDataService {
	constructor(
		@inject(Service.ApiClient) private _client: C.Client,
		@inject(Service.TileServerApiClient) private _tileServerApiClient: TileServerClient.TileServerClient
	) { }

	public async fetchTilesets(): Promise<C.ITilesetDto[]> {
		return this._client.listMapDataTilesets();
	}

	public async searchTileLayers(request: TileServerClient.ISearchRequestDto): Promise<TileServerClient.ISearchResponseDto[]> {
		return this._tileServerApiClient.search(new TileServerClient.SearchRequestDto(request));
	}

	public async generateTilesetKeys(): Promise<C.ITilesetKeysDto> {
		return this._client.generateTilesetKeys();
	}
}
