import {
	Client as C,
} from 'src/services';

export function getLocationParts(locationDetails: C.ILocationDetailsDto): string[] | null {
	const result = new Array<string>();

	if (locationDetails.road) {
		if (locationDetails.houseNumber)
			result.push(`${locationDetails.houseNumber} ${locationDetails.road}`);
		else
			result.push(locationDetails.road);
	}

	if (locationDetails.suburb)
		result.push(locationDetails.suburb);

	if (locationDetails.city)
		result.push(locationDetails.city);
	else if (locationDetails.county)
		result.push(locationDetails.county);

	if (locationDetails.postCode)
		result.push(locationDetails.postCode);

	// Don't return an empty string if there were no details.
	if (result.length === 0)
		return null;

	return result;
}

export function getSingleLineLocationSummary(locationDetails: C.ILocationDetailsDto): string | null {
	const parts = getLocationParts(locationDetails);
	if (!parts)
		return null;

	return parts.join(', ');
}
