import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import {
	Client as C,
	UserGroupService,
	Service,
	useInjection,
} from 'src/services';

import { SelectItemsDialog } from 'src/components/selectItemsDialog';

interface Props {
	userGroupId: string;
	users: C.IUserDto[];
	existing: C.IUserDto[];
	complete: () => void;
	closeDialog: () => void;
}

export const AddUsersDialog = observer((props: Props) => {
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);

	const [saving, setSaving] = useState<boolean>(false);
	const [savingError, setSavingError] = useState<boolean>(false);

	const items = useMemo(() => {
		const exclude = new Set<string>(props.existing.map(x => x.identity.identityId));

		return props.users
			.filter(x => !exclude.has(x.identity.identityId))
			.map(x => ({
				id: x.identity.identityId,
				name: x.name,
			}))
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
	}, [props.existing, props.users]);

	const addUsers = async (identityIds: string[]) => {
		setSaving(true);

		try {
			await _userGroupService.updateUsers(props.userGroupId, {
				addIdentityIds: identityIds,
			});

			props.complete();
			props.closeDialog();
		} catch (err) {
			setSavingError(true);
			setSaving(false);
		}
	};

	return <SelectItemsDialog
		title="Select users to add"
		complete={addUsers}
		close={props.closeDialog}
		saving={saving}
		savingError={savingError}
		items={items}
	/>;
});
