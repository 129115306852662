import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAddEditAssetServiceReminder($includeUsers: Boolean!, $includeUserGroups: Boolean!, $includeClients: Boolean!) {
		userGroups @include(if: $includeUserGroups){
			id
			name
			client {
				id
				name
			}
		}
		users @include(if: $includeUsers) {
			id
			name
			identity {
				id
				client @include(if: $includeClients) {
					id
					name
				}
			}
		}
		clients @include(if: $includeClients) {
			id
			name
		}
	}
`;
