import React, { useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions, DialogTitle, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import { MessagePage } from 'src/components';

import './selectItemsDialog.scss';

export interface DialogItem {
	id: string;
	name: string;
}

interface Props {
	title: string;
	loading?: boolean;
	saving?: boolean;
	savingError?: boolean;
	items?: DialogItem[];
	complete: (itemIds: string[]) => Promise<void>;
	close: () => void;
}

export const SelectItemsDialog = observer((props: Props) => {
	const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

	const confirm = async () => {
		await props.complete(Array.from(selectedItems));
	};

	const toggleSelected = (itemId: string) => {
		const updatedSelectedItems = new Set(selectedItems);
		if (updatedSelectedItems.has(itemId))
			updatedSelectedItems.delete(itemId);
		else
			updatedSelectedItems.add(itemId);

		setSelectedItems(updatedSelectedItems);
	};

	const renderItem = (item: DialogItem) => {
		const selected = selectedItems.has(item.id);

		return <div
			key={item.id}
			className={classNames('select-items-dialog__item', { 'selected': selected })}
			onClick={() => toggleSelected(item.id)}
		>
			{item.name}
			{selected && <CheckIcon fontSize="small" />}
		</div>;
	};

	const renderContent = () => {
		if (props.loading || props.saving) {
			return <MessagePage
				title={props.saving ? 'Saving...' : undefined}
				loading
			/>;
		}

		if (!props.items) {
			return <MessagePage
				title="Failed to load the required data."
			/>;
		}

		return props.items.map(renderItem);
	};

	return <Dialog
		className="select-items-dialog"
		fullScreen={window.innerWidth < 500}
		open={true}
		onClose={props.close}
	>
		<DialogTitle>
			{props.title}
		</DialogTitle>

		<DialogContent
			className="select-items-dialog__content"
		>
			{renderContent()}
		</DialogContent>

		{props.savingError && <div className="status-bar error">
			An error occurred while trying to save your changes.
		</div>}

		{!props.loading && !props.saving && <DialogActions>
			<Button
				color="primary"
				onClick={props.close}
			>
				Cancel
			</Button>

			<Button
				color="primary"
				onClick={confirm}
				disabled={selectedItems.size === 0}
			>
				Confirm{selectedItems.size > 0 && ` (${selectedItems.size})`}
			</Button>
		</DialogActions>}
	</Dialog>;
});
