import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import moment from 'moment-timezone';

import CloseIcon from '@material-ui/icons/Close';

import { CallSegments, CallPlayer } from 'src/components';
import { CallDetails, CallTimeElapsedContainer, AudioControls } from './callPlayerComponents';

import { useQueryCallById } from 'src/graphql/__generated__/queries/queryCallById';

const CallDialog = styled(Dialog)({
	'& .MuiPaper-root': {
		backgroundColor: '#FAFAFA !important',
		minHeight: '505px',
		transition: 'height 2s',
	},
});

const DialogTitleAndCloseButtonContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	backgroundColor: '#757ce8',
});

const CloseButton = styled(IconButton)({
	margin: '20px',
	color: 'white',
	width: '32px',
	height: '32px',
});

const CallDialogTitle = styled(Typography)({
	margin: '20px',
	color: 'white',
});

interface Props {
	callId: string;
	canGoToNextCall: boolean;
	canGoToPreviousCall: boolean;
	onClose?: () => void;
	onClickPreviousOrNext: (nextCall: boolean) => void;
	onDownload: (callId: string) => Promise<void>;
}

export const CallPlayingDialog = (props: Props) => {
	const query = useQueryCallById({variables: {
		id: props.callId,
	}});

	const numberOfPreviousSegments = React.useRef(0);

	function renderCallPlayerSkeleton() {
		return <Box>
			<CallDetails>
				<Box>
					<Skeleton animation="wave" variant="text" width={220} height={24}/>
					<Skeleton animation="wave" variant="text" width={210} height={24}/>
				</Box>
				<Skeleton animation="wave" variant="circle" width={50} height={50}/>
			</CallDetails>
			<Skeleton animation="wave" variant="rect" height={128}/>

			<CallTimeElapsedContainer>
				<Skeleton animation="wave" variant="text" width={60} height={24}/>
				<AudioControls>
					<Skeleton animation="wave" variant="circle" width={50} height={50}/>
					<Skeleton animation="wave" variant="circle" width={50} height={50}/>
					<Skeleton animation="wave" variant="circle" width={50} height={50}/>
				</AudioControls>
				<Skeleton animation="wave" variant="text" width={60} height={24}/>
			</CallTimeElapsedContainer>
		</Box>;
	}

	function renderContent() {
		if (query.loading) {
			// Try calculate the height of the previous segments material table, 163 = height of empty material table (always has one row even without data), 53 = height of material table row.
			const heightOfPreviousMaterialTable = numberOfPreviousSegments.current === 0 ? 163 : 163 + (53 * (numberOfPreviousSegments.current - 1));

			return <>
				{renderCallPlayerSkeleton()}
				<Skeleton animation="wave" variant="rect" height={heightOfPreviousMaterialTable}/>
			</>;
		}

		if (query.error || !query.data?.callById) {
			return <Typography>Failed to load selected call</Typography>;
		}

		numberOfPreviousSegments.current = query.data.callById.segments?.length ?? 0;

		return <>
			<CallPlayer
				call={query.data.callById}
				canGoToNextCall={props.canGoToNextCall}
				canGoToPreviousCall={props.canGoToPreviousCall}
				onClickPreviousOrNext={props.onClickPreviousOrNext}
				onDownload={props.onDownload}
			/>

			<CallSegments
				callSegments={query.data.callById.segments ?? []}
				callStartTimestamp={moment(query.data.callById.startTimestamp)}
			/>
		</>;
	}

	return <CallDialog
		open={true}
		onClose={props.onClose}
		className="dialogStyles"
		fullScreen={window.innerWidth < 500}
		fullWidth={true}
		maxWidth="lg"
	>
		<DialogTitleAndCloseButtonContainer>
			<CallDialogTitle>Call Playback</CallDialogTitle>
			<CloseButton onClick={props.onClose}>
				<CloseIcon />
			</CloseButton>
		</DialogTitleAndCloseButtonContainer>

		<DialogContent>
			{renderContent()}
		</DialogContent>
	</CallDialog>;
};
