import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { bind } from 'bind-decorator';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import moize from 'moize';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import { Button } from 'src/components';
import { CustomActionDialogBox, CustomActionDialogBoxProps } from 'src/components/customActionDialogBox';
import { getDeviceConfigurationTypeFormatted, deviceCanUseConfigurationType } from './deviceConfigurationHelpers';

import { executeQueryDealerList, QueryDealerList_dealers } from 'src/graphql/__generated__/queries/queryDealerList';

import {
	Container,
	Client as C,
	DeviceConfigurationService,
	AuthenticationService,
	Service,
	ToasterService,
} from 'src/services';

import { QueryDevicesList_devices } from 'src/graphql/__generated__/queries/queryDevicesList';

interface Props extends Omit<CustomActionDialogBoxProps, 'title'>{
	deviceConfigurationId?: string;
	devices?: QueryDevicesList_devices[];
	allDevices: QueryDevicesList_devices[];
	onSuccess?: () => Promise<any>;
}

interface State {
	deviceConfiguration: C.IDeviceConfigurationDto | null;
	devices: QueryDevicesList_devices[];
	loading: boolean;
	submitting: boolean;
	touched: {
		deviceConfiguration: boolean;
		devices: boolean;
	};
	combinationError?: string;
}

const loadingSpinnerStyles = css`
	margin-left: auto;
	margin-right: auto;
	display: block !important;
`;

const OptionSubText = styled.div`
	color: #999;
`;

@observer
export class DeviceConfigurationUpdateDialog extends React.Component<Props, State> {
	private _apolloClient = Container.get<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	private _deviceConfigurationService = Container.get<DeviceConfigurationService>(Service.DeviceConfiguration);
	private _authService = Container.get<AuthenticationService>(Service.Authentication);
	private _toasterService = Container.get<ToasterService>(Service.Toaster);

	@observable private _allDeviceConfigurations: C.IDeviceConfigurationDto[] = [];
	@observable private _allDealers = new Map<string, QueryDealerList_dealers>();

	constructor(props: Props) {
		super(props);

		this.state = {
			deviceConfiguration: null,
			devices: [],
			loading: true,
			submitting: false,
			touched: {
				deviceConfiguration: false,
				devices: false,
			},
		};
	}

	async componentDidMount() {
		this._allDeviceConfigurations = await this._deviceConfigurationService.getAllDeviceConfigurations();

		if (this._authService.currentAuth.permissions.general.manageDealers) {
			const dealersListQuery = await executeQueryDealerList(this._apolloClient);

			if (dealersListQuery.error || !dealersListQuery.data)
				throw dealersListQuery.error || 'Failed to load dealers.';

			const allDealers = dealersListQuery.data.dealers ?? [];
			for (const dealer of allDealers)
				this._allDealers.set(dealer.id, dealer);
		}

		const deviceConfiguration = this._allDeviceConfigurations.find(x => x.deviceConfigurationId === this.props.deviceConfigurationId) || null;
		const devices = this.props.devices || [];

		this.setState({
			loading: false,
			deviceConfiguration,
			devices: devices,
			touched: {
				deviceConfiguration: !!deviceConfiguration,
				devices: devices.length > 0,
			},
		});
	}

	@bind
	handleDeviceConfigurationChange(_: React.ChangeEvent, value: C.IDeviceConfigurationDto | null) {
		this.setState({
			deviceConfiguration: value,
			touched: {
				...this.state.touched,
				deviceConfiguration: true,
			},
		}, () => this.validate());
	}

	@bind
	onChangeDevice(_: React.ChangeEvent, value: QueryDevicesList_devices[] | null) {
		this.setState({
			devices: value || [],
			touched: {
				...this.state.touched,
				devices: true,
			},
		}, () => this.validate());
	}

	@bind
	async onSubmit() {
		this.setState({
			touched: {
				deviceConfiguration: true,
				devices: true,
			},
		});

		const validationSuccess = this.validate();
		if (!validationSuccess)
			return;

		this.setState({
			submitting: true,
		});

		const request: C.IAddPendingDeviceConfigurationUpdateRequest = {
			deviceConfigurationId: this.state.deviceConfiguration!.deviceConfigurationId,
			deviceIds: this.state.devices.map(x => x.id),
		};

		try {
			await this._deviceConfigurationService.addPendingDeviceConfigurationUpdate(request);

			this._toasterService.showSuccess(`Successfully queued the configuration update for ${this.state.devices.length} device(s).`);
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to queue the configuration update for the selected device(s).');
		}

		this.setState({ submitting: false });

		if (this.props.dialogCloseCallback)
			this.props.dialogCloseCallback();
	}

	@bind
	validate(): boolean {
		let combinationError: string | undefined;

		const { deviceConfiguration, devices } = this.state;

		if (deviceConfiguration && devices.length > 0) {
			for (const device of devices) {
				if (device.dealer?.id) {
					const dealerCanUseConfiguration = deviceConfiguration.allDealersCanAccess || deviceConfiguration.dealerId === device.dealer.id;
					if (!dealerCanUseConfiguration) {
						combinationError = `The dealer for device "${device.name}" (${this._allDealers.get(device.dealer.id)?.name || 'Unknown'}) does not have permission to use the selected configuration.`;
						break;
					}
				} else {
					if (!deviceConfiguration.allDealersCanAccess) {
						combinationError = `The selected configuration can only be used for devices under dealer "${this._allDealers.get(deviceConfiguration.dealerId!!)?.name || 'Unknown'}", ` +
							`but device "${device.name}" does not have a dealer configured.`;
						break;
					}
				}

				if (!deviceCanUseConfigurationType(device, deviceConfiguration.type)) {
					combinationError = `The selected configuration is not compatible with the selected device(s).`;
					break;
				}
			}
		}

		this.setState({
			combinationError,
		});

		return !!deviceConfiguration && devices.length > 0 && !combinationError;
	}

	filteredConfigurationOptions = moize.deep(
		(allConfigurations: C.IDeviceConfigurationDto[], selectedDevices: QueryDevicesList_devices[]) => {
			if (selectedDevices.length === 0)
				return allConfigurations;

			// Filter the configuraiton options depending on the model types of the selected devices.
			let configurations = allConfigurations.filter(config => {
				return selectedDevices.every(device => deviceCanUseConfigurationType(device, config.type));
			});

			// Filter the configuration options depending on the dealer IDs of the selected devices.
			const deviceWithDealer = selectedDevices.find(x => !!x.dealer?.id);
			if (deviceWithDealer)
				configurations = configurations.filter(x => x.allDealersCanAccess || x.dealerId === deviceWithDealer.dealer!.id);

			return configurations;
		}
	);

	filteredDeviceOptions = moize(
		(allDevices: QueryDevicesList_devices[], selectedConfiguration: C.IDeviceConfigurationDto | null) => {
			if (!selectedConfiguration)
				return allDevices;

			// Filter the device options depending on the configuration type of the selected configuration.
			let devices = allDevices.filter(device => deviceCanUseConfigurationType(device, selectedConfiguration.type));

			// Filter the device options depending on which dealers can use the selected configuration.
			if (!selectedConfiguration.allDealersCanAccess)
				devices = devices.filter(device => device.dealer?.id === selectedConfiguration.dealerId);

			return devices;
		}
	);

	@bind
	renderContent(): JSX.Element {
		return <>
			<Typography gutterBottom>
				Update one or more devices using a selected configuration. The configuration will be queued to be applied when next possible. The actual update
				time depends on a number of factors (e.g. if the device is currently online, when the device last checked for an update).
			</Typography>

			<Autocomplete
				options={this.filteredConfigurationOptions(this._allDeviceConfigurations, this.state.devices)}
				getOptionLabel={(option: C.IDeviceConfigurationDto) => option.name}
				getOptionSelected={(option: C.IDeviceConfigurationDto, value: C.IDeviceConfigurationDto) => option.deviceConfigurationId === value.deviceConfigurationId}
				value={this.state.deviceConfiguration}
				onChange={this.handleDeviceConfigurationChange}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						label="Configuration"
						helperText="Select a configuration to apply."
						error={this.state.touched.deviceConfiguration && !this.state.deviceConfiguration}
						margin="normal"
					/>
				)}
				renderOption={option => <div>
					{option.name}
					<OptionSubText>Configuration type: {getDeviceConfigurationTypeFormatted(option.type)}</OptionSubText>
					{this._authService.currentAuth.user.identity.type === C.IdentityType.SuperUser && <OptionSubText>
						Dealer: {option.allDealersCanAccess
							? 'All dealers'
							: this._allDealers.get(option.dealerId || '')?.name || 'Unknown'}
					</OptionSubText>}
				</div>}
			/>

			<Autocomplete
				multiple
				options={this.filteredDeviceOptions(this.props.allDevices, this.state.deviceConfiguration)}
				getOptionLabel={(option: QueryDevicesList_devices) => option.name}
				getOptionSelected={(option: QueryDevicesList_devices, value: QueryDevicesList_devices) => option.id === value.id}
				value={this.state.devices}
				onChange={this.onChangeDevice}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						label="Devices"
						helperText="Select one or more device that the configuration will be applied to."
						error={this.state.touched.devices && this.state.devices.length === 0}
						margin="normal"
					/>
				)}
				renderOption={option => <div>
					{option.name}
					{/* <OptionSubText>Device type/model: {option.modelType && getFormattedDeviceModelType(option.modelType) || 'Unknown'}</OptionSubText> */}
					{this._authService.currentAuth.user.identity.type === C.IdentityType.SuperUser && <OptionSubText>
						Dealer: {option.dealer?.id
							? this._allDealers.get(option.dealer.id || '')?.name || 'Unknown'
							: 'Dealer not configured'}
					</OptionSubText>}
				</div>}
			/>

			{this.state.combinationError && <Alert severity="error">{this.state.combinationError}</Alert>}
		</>;
	}

	render() {
		return <CustomActionDialogBox
			title="Device Configuration Update"
			loading={this.state.loading}
			actionButton={<Button
				variant="contained"
				text="Queue update"
				onClick={this.onSubmit}
				loading={this.state.loading || this.state.submitting}
				color="primary"
			/>}
			dialogCloseCallback={this.props.dialogCloseCallback}
			fullWidth={true}
			maxWidth="sm"
		>
			{this.state.loading
				? <CircularProgress className={loadingSpinnerStyles} size={20} />
				: this.renderContent()}
		</CustomActionDialogBox>;
	}
}
