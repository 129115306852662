import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { QueryReport_reportById_parameters } from 'src/graphql/__generated__/queries/queryReport';
import moment from 'moment-timezone';

import {
	AuthenticationService,
	DistanceService,
	Service,
	SpeedUnit,
	useInjection,
} from 'src/services';

import { shortDateFormat, longDateTimeFormat } from 'src/util/dateTimeFormats';
import { selectOptions as timeZoneOptions } from 'src/util/timeZones';
import { CollectionsDisplayer } from 'src/components';

interface Props {
	reportParameters: QueryReport_reportById_parameters;
}

interface ParameterValueAndName {
	name: string;
	parsedValue: string;
}

const useStyles = makeStyles({
	valuesTable: {
		borderSpacing: '0 10px',
	},
	valuesTablePropertyName: {
		paddingRight: '10px !important',
		fontWeight: 700,
		whiteSpace: 'nowrap',
		verticalAlign: 'top',
	},
	valuesTableRows: {
		backgroundColor: 'white !important',
		marginBottom: '10px',
	}
});

export const ReportParametersDisplay = observer((props: Props) => {
	const authService = useInjection<AuthenticationService>(Service.Authentication);
	const distanceService = useInjection<DistanceService>(Service.DistanceService);

	const reportParameters = props.reportParameters;
	const classes = useStyles();

	// All different types of date/time parameters.
	const propertiesAndNames: ParameterValueAndName[] = [];
	if (reportParameters.startTimestamp)
		propertiesAndNames.push({name: 'Start Date/Time:', parsedValue: moment(props.reportParameters.startTimestamp).tz(authService.currentAuth.user.timeZone).format(longDateTimeFormat) });

	if (reportParameters.endTimestamp)
		propertiesAndNames.push({name: 'End Date/Time:', parsedValue: moment(props.reportParameters.endTimestamp).tz(authService.currentAuth.user.timeZone).format(longDateTimeFormat) });

	if (reportParameters.startDate)
		propertiesAndNames.push({name: 'Start Date:', parsedValue: moment(props.reportParameters.startDate).tz(authService.currentAuth.user.timeZone).format(shortDateFormat) });

	if (reportParameters.endDate)
		propertiesAndNames.push({name: 'End Date:', parsedValue: moment(props.reportParameters.endDate).tz(authService.currentAuth.user.timeZone).format(shortDateFormat) });

	if (reportParameters.timestamp)
		propertiesAndNames.push({name: 'Date/Time:', parsedValue: moment(props.reportParameters.timestamp).tz(authService.currentAuth.user.timeZone).format(longDateTimeFormat) });

	if (reportParameters.timeZone) {
		const timezoneOption = timeZoneOptions.find(x => x.value == reportParameters.timeZone);
		propertiesAndNames.push({name: 'Time Zone:', parsedValue: timezoneOption ? timezoneOption.label : reportParameters.timeZone});
	}

	// Asset activity parameters.
	if (reportParameters.minimumTravelSpeed)
		propertiesAndNames.push({name: 'Minimum Travel Speed:', parsedValue: distanceService.formatSpeed(reportParameters.minimumTravelSpeed, authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour) });

	if (reportParameters.minimumStopTime)
		propertiesAndNames.push({name: 'Minimum Stop Time:', parsedValue: `${reportParameters.minimumStopTime}s`});

	// Billing specific parameters.
	if (reportParameters.billingCodes)
		propertiesAndNames.push({name: 'Billing Codes:', parsedValue: reportParameters.billingCodes.join(', ')});

	return <div className="content-box" style={{ marginBottom: '20px' }}>
		<h2>Report Parameters</h2>

		<table className={classes.valuesTable}>
			{propertiesAndNames.map(x => <tr className={classes.valuesTableRows}>
				<td className={classes.valuesTablePropertyName}>
					{x.name}
				</td>
				<td>
					{x.parsedValue}
				</td>
			</tr>)}

			{reportParameters.assets && reportParameters.assets.length > 0 &&<tr className={classes.valuesTableRows}>
				<td className={classes.valuesTablePropertyName}>Assets:</td>
				<td>
					<CollectionsDisplayer
						values={reportParameters.assets}
						getOptionLabel={x => x.name}
						maxNumberOfValues={15}
					/>
				</td>
			</tr>}

			{reportParameters.assetGroups && reportParameters.assetGroups.length > 0 &&<tr className={classes.valuesTableRows}>
				<td className={classes.valuesTablePropertyName}>Asset Groups:</td>
				<td>
					<CollectionsDisplayer
						values={reportParameters.assetGroups}
						getOptionLabel={x => x.name}
						maxNumberOfValues={15}
					/>
				</td>
			</tr>}

			{reportParameters.users && reportParameters.users.length > 0 &&<tr className={classes.valuesTableRows}>
				<td className={classes.valuesTablePropertyName}>Users:</td>
				<td>
					<CollectionsDisplayer
						values={reportParameters.users}
						getOptionLabel={x => x.name}
						maxNumberOfValues={15}
					/>
				</td>
			</tr>}

			{reportParameters.userGroups && reportParameters.userGroups.length > 0 &&<tr className={classes.valuesTableRows}>
				<td className={classes.valuesTablePropertyName}>User groups:</td>
				<td>
					<CollectionsDisplayer
						values={reportParameters.userGroups}
						getOptionLabel={x => x.name}
						maxNumberOfValues={15}
					/>
				</td>
			</tr>}

			{reportParameters.addressBookEntries && reportParameters.addressBookEntries.length > 0 &&<tr className={classes.valuesTableRows}>
				<td className={classes.valuesTablePropertyName}>Address book entries:</td>
				<td>
					<CollectionsDisplayer
						values={reportParameters.addressBookEntries}
						getOptionLabel={x => x.userName}
						maxNumberOfValues={15}
					/>
				</td>
			</tr>}
		</table>
	</div>;
});
