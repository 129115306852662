import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class SafetyTimerService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
	) {

	}

	async startSafetyTimer(request: C.IStartSafetyTimerRequest): Promise<C.ISafetyTimerDto> {
		return await this.client.safetyTimerStart(new C.StartSafetyTimerRequest(request));
	}

	async safetyTimerCheckIn(safetyTimerId: string, request: C.ISafetyTimerCheckInRequest): Promise<C.ISafetyTimerDto> {
		return await this.client.safetyTimerCheckIn(safetyTimerId, new C.SafetyTimerCheckInRequest(request));
	}

	async endSafetyTimer(safetyTimerId: string, request: C.IEndSafetyTimerRequest): Promise<C.ISafetyTimerDto> {
		return await this.client.safetyTimerEnd(safetyTimerId, new C.EndSafetyTimerRequest(request));
	}
}
