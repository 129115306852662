import { IReportFormat } from './IReportFormat';

export class BillingReportFormat implements IReportFormat {
	HasAssetSelection = false;
	HasGeofenceSelection = false;
	HasMinimumTravelSpeedAndStopTime = false;
	HasSpeedLimit = false;
	HasBillingCodes = true;
	HasUserSelection = false;
	HasAddressBookEntrySelection = false;
	ReportDefinitionHelperText = 'The billing information for one or more assets over a specified time period.';
}
