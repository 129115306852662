import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';
import { ManageEnableFleetConfigurationComponent } from './manageEnableFleetConfigurationComponent';

import {
	Client as C,
	EnableFleetConfigurationService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditEnableFleetConfiguration = observer((props: Props) => {
	const _enableFleetConfigurationService = useInjection<EnableFleetConfigurationService>(Service.EnableFleetConfiguration);

	const load = async (configurationId: string): Promise<C.IEnableFleetConfigurationDto> => {
		return await _enableFleetConfigurationService.getEnableFleetConfiguration(configurationId);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(configuration: C.IEnableFleetConfigurationDto) => <ManageEnableFleetConfigurationComponent
			configuration={configuration}
		/>}
	/>;
});
