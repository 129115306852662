import React from 'react';
import { FieldProps, getIn } from 'formik';
import TextField from '@material-ui/core/TextField';

import './formik.scss';

interface FormikTextFieldProps {
	helperText: string;
	label: string;
}

export const FormikTextField = ({
	field,
	form: { touched, errors },
	helperText,
	label,
	...props
}: FieldProps & FormikTextFieldProps) => {
	const fieldError = getIn(errors, field.name);
	const fieldTouched = getIn(touched, field.name);

	return <TextField
		fullWidth
		label={label}
		error={fieldTouched && !!fieldError}
		helperText={(fieldTouched && fieldError) || helperText}
		variant="filled"
		{...field}
		{...props}
	/>;
};
