import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import { InputAdornment } from '@material-ui/core';
import moment from 'moment-timezone';

import { AssetSelector, Button, FixedWidthPage, FormikDateTimeRangePicker, DateTimeRange, FormikTextField, MessagePage, AssetGroupSelector } from 'src/components';
import { runFormValidation, validateIsNumber } from 'src/util';
import { maxReportDuration } from 'src/app/reports/reportConfiguration';

import {
	Client as C,
	AuthenticationService,
	DistanceService,
	HistoryService,
	ReportService,
	Service,
	ToasterService,
	useInjection,
	useAssetSelectorService,
	useAssetGroupSelectorService,
} from 'src/services';

interface CreateActivityReportFormValues {
	name: string;
	assets: string[];
	assetGroups: string[];
	dateTimeRange: DateTimeRange;
	minimumTravelSpeed: number;
	minimumStopTime: number;
}

export const CreateActivityReport = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _reportService = useInjection<ReportService>(Service.Report);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _distanceService = useInjection<DistanceService>(Service.DistanceService);

	const _assetSelectorService = useAssetSelectorService();
	const _assetGroupSelectorService = useAssetGroupSelectorService(C.ListAssetGroupsType.Report);

	const validateForm = (values: CreateActivityReportFormValues, errors: FormikErrors<CreateActivityReportFormValues>) => {
		if (!values.name)
			errors.name = 'Report name is required.';

		if (values.assets.length === 0 && values.assetGroups.length === 0) {
			errors.assets = 'At least one asset or asset group is required.';
			errors.assetGroups = 'At least one asset or asset group is required.';
		}

		if (values.minimumTravelSpeed == null || !validateIsNumber(values.minimumTravelSpeed))
			errors.minimumTravelSpeed = 'Minimum travel speed must be a valid number.';
		else if (values.minimumTravelSpeed < 0)
			errors.minimumTravelSpeed = 'Minimum travel speed must be at least 0.';

		if (values.minimumStopTime == null || !validateIsNumber(values.minimumStopTime))
			errors.minimumStopTime = 'Minimum stop time must be a valid number.';
		else if (values.minimumStopTime < 0)
			errors.minimumStopTime = 'Minimum stop time must be at least 0.';
	};

	const onSubmit = async (values: CreateActivityReportFormValues, { setSubmitting }: FormikHelpers<CreateActivityReportFormValues>) => {
		try {
			const minimumTravelSpeed = _authService.currentAuth.user.usesMetric ? values.minimumTravelSpeed : _distanceService.convertMilesToKilometres(values.minimumTravelSpeed);

			const request: C.INewActivityReportRequest = {
				name: values.name,
				assetIds: values.assets,
				assetGroupIds: values.assetGroups,
				startTimestamp: values.dateTimeRange.start,
				endTimestamp: values.dateTimeRange.end,
				minimumStopTime: values.minimumStopTime,
				minimumTravelSpeed: Math.round(minimumTravelSpeed), // Rounding because we only accept integers.
			};

			await _reportService.createActivityReport(request);
			_historyService.history.push('/app/reports');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to create report.');
			setSubmitting(false);
		}
	};

	const now = moment.tz(_authService.currentAuth.user.timeZone);

	return <FixedWidthPage
		className="form-page"
		headingText="Create Activity Report"
		contentMessageReplace={_assetSelectorService.loading && <MessagePage loading />}
	>
		<Formik
			initialValues={{
				name: '',
				assets: [],
				assetGroups: [],
				dateTimeRange: { start: now.clone().subtract(1, 'day'), end: now },
				minimumTravelSpeed: 5,
				minimumStopTime: 120,
			}}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<CreateActivityReportFormValues>) => <Form className="formik-form material">
				<Field
					name="name"
					label="Report Name"
					component={FormikTextField}
					required
				/>

				<AssetSelector
					options={_assetSelectorService.assetOptions}
					formikProps={formikProps}
				/>

				<AssetGroupSelector
					options={_assetGroupSelectorService.assetGroupOptions}
					formikProps={formikProps}
				/>

				<FormikDateTimeRangePicker
					name="dateTimeRange"
					label="Report Period"
					form={formikProps}
					fullWidth
					maxDuration={maxReportDuration}
				/>

				<Field
					name="minimumTravelSpeed"
					label="Travel Speed"
					type="number"
					component={FormikTextField}
					InputProps={{
						endAdornment: <InputAdornment position="end">{_authService.currentAuth.user.usesMetric ? 'km/h' : 'mph' }</InputAdornment>,
					}}
					helperText="How fast assets must travel to be considered travelling."
				/>

				<Field
					name="minimumStopTime"
					label="Stop Time"
					type="number"
					component={FormikTextField}
					InputProps={{
						endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
					}}
					helperText="How long assets must stop to be considered stopped."
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					text="Create"
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
