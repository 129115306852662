import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';
import { ManageGeofenceTypeComponent } from './manageGeofenceTypeComponent';

import {
	GeofenceService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditGeofenceType = observer((props: Props) => {
	const _geofenceService = useInjection<GeofenceService>(Service.Geofence);

	const load = async (geofenceTypeId: string) => {
		return _geofenceService.getGeofenceType(geofenceTypeId);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={geofenceType => <ManageGeofenceTypeComponent
			geofenceType={geofenceType}
		/>}
	/>;
});
