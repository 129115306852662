import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class CallGroupsService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
	) {

	}
	async updateCallGroupsBySiteId(updateRequest: C.IUpdateCallGroupsRequest) {
		return await this.client.updateCallGroupsBySiteId(new C.UpdateCallGroupsRequest(updateRequest));
	}
}
