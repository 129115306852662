import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateDigitalMatterDevice($input: UpdateDigitalMatterDeviceInput!) {
		updateDigitalMatterDevice(input: $input) {
			digitalMatterDevice {
				id
			}
		}
	}
`;
