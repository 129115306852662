import React from 'react';
import { FormikProps, getIn } from 'formik';

import { CustomAutocomplete } from './customAutocomplete';

import './formik.scss';

export interface FormikSelectProps<FormikValuesType, OptionsType> {
	name: string;
	className?: string;
	label?: string;
	form: FormikProps<FormikValuesType>;
	options: OptionsType[];
	getOptionLabel?: (option: OptionsType) => string;
	renderOption?: (option: OptionsType) => React.ReactNode;
	getOptionValue: (option: OptionsType) => any;
	helperText?: string;
	placeholder?: string;
	clearable?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	required?: boolean;
	onChange?: (option: OptionsType | OptionsType[] | null) => any;
	multi?: boolean;
	disableCloseOnSelect?: boolean;
	onBlur?: () => void;
}

export function FormikSelect<FormikValuesType, OptionsType>(props: FormikSelectProps<FormikValuesType, OptionsType>) {
	const onBlur = () => {

		if (props.onBlur)
			props.onBlur();

		props.form.setFieldTouched(props.name, true);
	};

	const onChange = (selected: OptionsType | OptionsType[] | null) => {
		let newValue = null;
		if (!selected) {
			// New value is null
		} else if (Array.isArray(selected)) {
			newValue = selected.map(x => props.getOptionValue!!(x));
		} else {
			newValue = props.getOptionValue!!(selected);
		}

		props.form.setFieldValue(props.name, newValue);
		props.form.setFieldTouched(props.name, true);

		if (props.onChange)
			props.onChange(selected);
	};

	const {
		name,
		form: { touched, errors, values },
		...otherProps
	} = props;

	const fieldError = getIn(errors, name);
	const fieldTouched = getIn(touched, name);
	const value = getIn(values, name);

	return <div className="formik-select">
		<CustomAutocomplete
			{...otherProps}
			value={value}
			touched={fieldTouched}
			error={fieldError}
			onChange={onChange}
			onBlur={onBlur}
		/>
	</div>;
}
