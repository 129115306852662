import * as C from './../../../services/client';
import { ActivityReportFormat } from './activityReportFormat';
import { AssetEventHistoryReportFormat } from './assetEventHistoryReportFormat';
import { AssetLogHistoryReportFormat } from './assetLogHistoryFormat';
import { DailyReportFormat } from './dailyReportFormat';
import { DriverReportFormat } from './driverReportFormat';
import { BillingReportFormat } from './billingReportFormat';
import { GeofenceReportFormat } from './geofenceReportFormat';
import { LocationReportFormat } from './locationReportFormat';
import { SafetyTimerReportFormat } from './safetyTimerReport';
import { UserAuthAuditReportFormat } from './userAuthAuditReportFormat';

export interface IReportFormat {
	HasAddressBookEntrySelection: boolean;
	HasAssetSelection: boolean;
	HasGeofenceSelection: boolean;
	HasMinimumTravelSpeedAndStopTime: boolean;
	HasUserSelection: boolean;
	HasBillingCodes: boolean;
	ReportDefinitionHelperText: string;
}

export const getReportTypeFormatFromReportType = (reportType: C.ReportType): IReportFormat => {
	switch (reportType) {
		case C.ReportType.Activity:
			return new ActivityReportFormat;
		case C.ReportType.AssetEventHistory:
			return new AssetEventHistoryReportFormat;
		case C.ReportType.AssetLogHistory:
			return new AssetLogHistoryReportFormat;
		case C.ReportType.Billing:
			return new BillingReportFormat;
		case C.ReportType.Daily:
			return new DailyReportFormat;
		case C.ReportType.Driver:
			return new DriverReportFormat;
		case C.ReportType.Geofence:
			return new GeofenceReportFormat;
		case C.ReportType.Location:
			return new LocationReportFormat;
		case C.ReportType.SafetyTimer:
			return new SafetyTimerReportFormat;
		case C.ReportType.UserAuthAudit:
			return new UserAuthAuditReportFormat;
	}
};
