import {
	Client as C,
} from 'src/services';

export function GetGraphQlDeviceType(deviceType: C.DeviceType): 'ATrackDevice' | 'CamperVanDevice' | 'DigitalMatterDevice' | 'EroadDevice' | 'HyteraRadioDevice' | 'MobilePhoneDevice' | 'TaitRadioDevice' | 'TeltonikaDevice' {
	switch (deviceType) {
		case C.DeviceType.ATrack:
			return 'ATrackDevice';

		case C.DeviceType.CamperVan:
			return 'CamperVanDevice';

		case C.DeviceType.DigitalMatter:
			return 'DigitalMatterDevice';

		case C.DeviceType.Eroad:
			return 'EroadDevice';

		case C.DeviceType.HyteraRadio:
			return 'HyteraRadioDevice';

		case C.DeviceType.MobilePhone:
			return 'MobilePhoneDevice';

		case C.DeviceType.TaitRadio:
			return 'TaitRadioDevice';

		case C.DeviceType.Teltonika:
			return 'TeltonikaDevice';
	}
}
