import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryBeaconsList {
		bluetoothBeacons {
			id
			name
			serialNumber
			major
			minor
			transmitPower
			beaconType
			disabled
			floorPlan {
				name
			}
		}
	}
`;
