import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';

import { ThingLoader, Button } from 'src/components';

import { ManageUserGroup } from './manageUserGroup';
import { AddUsersDialog } from './addUsersDialog';

import {
	Client as C,
	UsersService,
	UserGroupService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

interface IPageData {
	userGroup: C.IUserGroupDto;
	userGroupUsers: C.IUserDto[];
	allUsers: C.IUserDto[];
}

export const UserGroupUsers = observer((props: Props) => {
	const _userService = useInjection<UsersService>(Service.Users);
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set<string>());
	const [addUsersDialogOpen, setAddUsersDialogOpen] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);

	const load = async (userGroupId: string): Promise<IPageData> => {
		const userGroup = await _userGroupService.getUserGroup(userGroupId);
		const userGroupUsers = await _userGroupService.getUsers(userGroupId);
		const allUsers = await _userService.getAllUsers();

		return {
			userGroup: userGroup!,
			userGroupUsers: userGroupUsers.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
			allUsers: allUsers!.filter(x => x.identity.clientId === userGroup!.client.clientId),
		};
	};

	const addUsersComplete = (reload: Function) => {
		setAddUsersDialogOpen(false);
		reload();
	};

	const toggleUser = (identityId: string) => {
		const newSelectedUsers = new Set(selectedUsers);
		if (newSelectedUsers.has(identityId))
			newSelectedUsers.delete(identityId);
		else
			newSelectedUsers.add(identityId);

		setSelectedUsers(newSelectedUsers);
	};

	const removeUsers = async (reload: Function) => {
		if (!confirm('Are you sure you want to remove the selected user(s) from this user group?'))
			return;

		setSaving(true);

		try {
			await _userGroupService.updateUsers(props.match.params.id, {
				removeIdentityIds: Array.from(selectedUsers),
			});

			reload();

			setSelectedUsers(new Set<string>());
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to remove users from group.');
		}

		setSaving(false);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(pageData: IPageData, reload: Function) => <ManageUserGroup
			userGroup={pageData.userGroup}
		>
			<div className="user-group-assets">
				{pageData.userGroup.type === C.UserGroupType.UserCreated && <div className="actions">
					<Button
						text="Remove"
						variant="outlined"
						disabled={selectedUsers.size === 0 || saving}
						loading={saving}
						onClick={() => removeUsers(reload)}
					/>
					<Button
						text="Add"
						color="primary"
						variant="contained"
						onClick={() => setAddUsersDialogOpen(true)}
					/>
				</div>}

				{pageData.userGroup.type !== C.UserGroupType.UserCreated && <>
					Assignment of users in this group is managed automatically.
				</>}

				<table className="card-table">
					<tbody>
						{pageData.userGroupUsers!.map(x => <tr key={x.identity.identityId} className="content-box">
							<td>
								<Checkbox
									checked={selectedUsers.has(x.identity.identityId)}
									onClick={() => toggleUser(x.identity.identityId)}
									disabled={pageData.userGroup.type !== C.UserGroupType.UserCreated}
								/>
							</td>

							<td>{x.name}</td>
						</tr>)}
					</tbody>
				</table>

				{addUsersDialogOpen && <AddUsersDialog
					userGroupId={pageData.userGroup.userGroupId}
					users={pageData.allUsers}
					existing={pageData.userGroupUsers}
					complete={() => addUsersComplete(reload)}
					closeDialog={() => setAddUsersDialogOpen(false)}
				/>}
			</div>
		</ManageUserGroup>}
	/>;
});
