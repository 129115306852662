import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import DefaultMaterialTable, { Column, Localization, Components, Options, Query, QueryResult } from '@material-table/core';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { Client as C, Service, TableDataService, useInjection } from 'src/services';

const MaterialTableIcons = {
	Add: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <AddBox {...props} ref={ref} />),
	Check: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Check {...props} ref={ref} />),
	Clear: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
	Delete: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
	Edit: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Edit {...props} ref={ref} />),
	Export: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <SaveAlt {...props} ref={ref} />),
	Filter: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Search {...props} ref={ref} />),
	FirstPage: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <FirstPage {...props} ref={ref} />),
	LastPage: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <LastPage {...props} ref={ref} />),
	MoreVert: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <MoreVertIcon {...props} ref={ref} />),
	NextPage: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
	Search: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Search {...props} ref={ref} />),
	SortArrow: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Remove {...props} ref={ref} />),
	ViewColumn: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ViewColumn {...props} ref={ref} />)
};

export const tableTheme = createTheme({
	palette: {
		primary: {
			main: '#1e88e5'
		},
		secondary: {
			main: '#1e88e5'
		}
	}
});

interface Props<T extends object> {
	tableName: string;
	columns: Column<T>[];
	data: T[] | ((query: Query<T>) => Promise<QueryResult<T>>);
	components?: Components;
	localization?: Localization;
	options?: Options<T>;
	style?: React.CSSProperties;
	tableRef?: React.RefObject<any> | React.MutableRefObject<undefined>;
}

export const MaterialTable: <T extends object>(props: Props<T>) => React.ReactElement = observer(props => {
	const tableDataService = useInjection<TableDataService>(Service.TableDataService);

	const onOrderChange = (orderBy: number, orderDirection: 'asc' | 'desc') => {
		let fieldName: string | undefined = undefined;
		if (orderBy >= 0)
			fieldName = props.columns[orderBy].field as string;

		tableDataService.setColumnSortingOrder(props.tableName, fieldName, orderDirection);
	};

	const columns = useMemo(() => props.columns.map(x => {
		const column = { ...x };

		if (column.filtering !== false)
			column.defaultFilter = tableDataService.getColumnFilter(props.tableName, column.field as string);

		if (column.sorting !== false)
			column.defaultSort = tableDataService.getColumnSortingOrder(props.tableName, column.field as string);

		const hidden = tableDataService.getHiddenColumn(props.tableName, column.field as string);
		if (hidden !== undefined)
			column.hidden = hidden;

		return column;
	}), [props.columns]);

	return <ThemeProvider theme={tableTheme}>
		<DefaultMaterialTable
			icons={MaterialTableIcons}
			onFilterChange={(filters) => tableDataService.setColumnFilters(props.tableName, filters)}
			onChangeColumnHidden={(column) => tableDataService.setHiddenColumn(props.tableName, column)}
			onPageChange={(page) => tableDataService.setPaginationPageNumber(props.tableName, page)}
			onRowsPerPageChange={(pageSize) => tableDataService.setPaginationPageSize(props.tableName, pageSize)}
			onOrderChange={onOrderChange}
			columns={columns}
			data={props.data}
			options={{
				filtering: true,
				grouping: true,
				pageSize: tableDataService.getPaginationPageSize(props.tableName) || 100,
				initialPage: tableDataService.getPaginationPageNumber(props.tableName) || 0,
				pageSizeOptions: [10, 25, 50, 100],
				padding: 'default',
				emptyRowsWhenPaging: false,
				search: false,
				showSelectAllCheckbox: false,
				columnsButton: true,
				showTitle: false,
				...props.options,
			}}
			components={props.components}
			localization={{
				toolbar: {
					nRowsSelected: '{0} item(s) selected',
				},
				...props.localization,
			}}
			style={props.style}
			tableRef={props.tableRef}
		/>
	</ThemeProvider>;
});
