import React, { useState } from 'react';
import { observer } from 'mobx-react';

import RefreshIcon from '@material-ui/icons/Refresh';

import { FixedWidthPage, MessagePage, ThingLoader, Button, DeviceUnlinkDialogBox, DeviceLinkDialog } from 'src/components';

import {
	Client as C,
	LinkedDeviceService,
	Service,
	useInjection,
} from 'src/services';

import './linkDevice.scss';

export const LinkedDeviceList = observer(() => {
	const _linkedDeviceService = useInjection<LinkedDeviceService>(Service.LinkedDevice);

	const [deviceToLink, setDeviceToLink] = useState<C.ILinkedDeviceDto | null>(null);
	const [deviceToUnlink, setDeviceToUnlink] = useState<C.ILinkedDeviceDto | null>(null);

	const load = async (): Promise<C.IMappedLinkedDeviceDto> => {
		const devices = await _linkedDeviceService.getAllLinkedDevices();

		devices.availableDevices = devices.availableDevices.sort((a, b) => a.deviceName.localeCompare(b.deviceName, undefined, { numeric: true }));
		devices.linkedDevices = devices.linkedDevices.sort((a, b) => a.deviceName.localeCompare(b.deviceName, undefined, { numeric: true }));

		return devices;
	};

	const renderLinkedDevicesList = (device: C.ILinkedDeviceDto, reload: Function): JSX.Element => {
		return <tr key={device.deviceId} className="content-box">
			<td>{device.deviceName}</td>
			<td align="right">{generateButton(device, reload)}</td>
		</tr>;
	};

	const generateButton = (device: C.ILinkedDeviceDto, reload: Function): JSX.Element => {
		return <Button
			variant="outlined"
			color={device.linkedAt ? 'secondary' : 'primary'}
			text={device.linkedAt ? 'Unlink' : 'Link'}
			onClick={() => (device.linkedAt ?
				setDeviceToUnlink(device) :
				setDeviceToLink(device))}
		/>;
	};

	const closeCodeDialog = (reload: Function) => {
		setDeviceToLink(null);
		reload();
	};

	return <ThingLoader
		load={load}
		render={(devices: C.IMappedLinkedDeviceDto, reload: () => void) => <FixedWidthPage
			headingText="Linked Devices"
			headingActions={<Button onClick={reload} text="Refresh" startIcon={<RefreshIcon />} variant="outlined" color="primary" />}
			noContentBackground
		>
			<table className="card-table">
				<thead>
					<tr>
						<th>Available Devices</th>
					</tr>
				</thead>

				{devices.availableDevices.length > 0 && <tbody>
					{devices.availableDevices.map(x => renderLinkedDevicesList(x, reload))}
				</tbody>}

				{devices.availableDevices.length == 0 && <tbody>
					<tr className="content-box">
						<td>Currently no available devices.</td>
					</tr>
				</tbody>}
			</table>

			<table className="card-table">
				<thead>
					<tr>
						<th>Linked Devices</th>
					</tr>
				</thead>

				{devices.linkedDevices.length > 0 && <tbody>
					{devices.linkedDevices.map(x => renderLinkedDevicesList(x, reload))}
				</tbody>}

				{devices.linkedDevices.length == 0 && <tbody>
					<tr className="content-box">
						<td>Currently no linked devices.</td>
					</tr>
				</tbody>}
			</table>

			{deviceToLink && <DeviceLinkDialog
				deviceId={deviceToLink.deviceId}
				close={() => closeCodeDialog(reload)}
			/>}

			{deviceToUnlink && <DeviceUnlinkDialogBox
				devices={[{ deviceId: deviceToUnlink.deviceId, name: deviceToUnlink.deviceName }]}
				reload={reload}
				dialogCloseCallback={() => setDeviceToUnlink(null)}
			/>}
		</FixedWidthPage>}
	/>;
});
