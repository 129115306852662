import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUnassignDeviceFromAsset($input: UnassignDeviceFromAssetInput!) {
		unassignDeviceFromAsset(input: $input) {
			device {
				id
			}
		}
	}
`;
