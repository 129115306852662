import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { useQueryAddAsset } from 'src/graphql/__generated__/queries/queryAddAsset';
import { useMutationAddAsset } from 'src/graphql/__generated__/mutations/mutationAddAsset';
import { ManageAssetComponent, ManageAssetFormValues } from './manageAssetComponent';

import {
	Client as C,
	HistoryService,
	Service,
	ToasterService,
	useCurrentUser,
	useIdentityType,
	useInjection,
} from 'src/services';

import { ErrorMessagePage, LoadingMessagePage } from 'src/components';

export interface Props {
	match: match<{
		type: string,
	}>;
}

export const AddAsset = observer(() => {
	const currentUser = useCurrentUser()!;
	const identityType = useIdentityType();
	const historyService = useInjection<HistoryService>(Service.History);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const query = useQueryAddAsset({
		variables: {
			includeDealers: identityType === C.IdentityType.SuperUser,
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		},
	});

	const [addAsset] = useMutationAddAsset();

	if (query.loading)
		return <LoadingMessagePage />;

	if (query.error)
		return <ErrorMessagePage />;

	const submit = async (values: ManageAssetFormValues) => {
		let speedLimit: number | null = null;
		if (values.speedLimitEnabled && values.speedLimit) {
			speedLimit = values.speedLimit;

			if (!currentUser.usesMetric)
				speedLimit = Math.round(speedLimit * 1.60934);
		}

		try {
			const result = await addAsset({
				variables: {
					input: {
						name: values.name,
						dealerId: values.dealerId,
						clientId: values.clientId,
						assetTypeId: values.assetTypeId,
						speedLimit: speedLimit,
						emergencyNotes: values.emergencyNotes,
					},
				},
			});

			const assetId = result.data!.addAsset!.asset!.id;

			toasterService.showSuccess('Asset added.');
			historyService.history.push(`/app/assets/${assetId}/edit`);
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to add asset.');
		}
	};

	return <ManageAssetComponent
		assetTypes={query.data?.assetTypes || undefined}
		dealers={query.data?.dealers || undefined}
		clients={query.data?.clients || undefined}
		submit={submit}
	/>;
});
