export async function ReadFileToBase64String(file: File, failMessage: string, onError: () => void): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			const resultString = reader.result as string;
			const base64 = resultString.substring(resultString.indexOf(',') + 1);

			resolve(base64);
		};

		reader.onerror = () => {
			onError();
			reject(failMessage);
		};
		reader.readAsDataURL(file);
	});
}
