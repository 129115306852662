import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryDealersForManageSitesPage {
		dealers {
			id
			name
		}
	}
`;
