import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export const CallDetails = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	marginBottom: '10px',
	alignItems: 'center',
});

export const CallTimeElapsedContainer = styled(Box)({
	display: 'flex',
	marginTop: '10px',
	justifyContent: 'space-between',
});

export const AudioControls = styled(Box)({
	display: 'grid',
	justifyContent: 'center',
	marginBottom: '10px',
	gap: '5px',

	'& *': {
		gridRow: 1,
	}
});
