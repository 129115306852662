import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import AddIcon from '@material-ui/icons/Add';

import { FixedWidthPage, MessagePage, ThingLoader, Button, PopoverMenu, PopoverMenuItem } from 'src/components';

import {
	Client as C,
	ClientService,
	Service,
	useInjection,
} from 'src/services';

export const ClientsList = observer(() => {
	const _clientService = useInjection<ClientService>(Service.Client);

	const loadClients = async () => {
		const clients = await _clientService.getAllClients();
		if (!clients)
			return null;

		return clients.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
	};

	const renderActionMenu = (client: C.IClientDto) => {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				title="Edit client"
				href={`/app/clients/${client.clientId}/overview`}
			/>,
		];
	};

	return <ThingLoader
		load={loadClients}
		render={(clients: C.IClientDto[]) => <FixedWidthPage
			headingText="Clients"
			headingActions={<Button href="/app/clients/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': clients.length === 0 })}
		>
			{clients.length > 0 && <table className="card-table">
				<thead>
					<tr>
						<th>Client Name</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{clients.map(x =>
					<tr className="content-box"
						key={x.clientId}
					>
						<td>{x.name}</td>
						<td className="actions">
							<PopoverMenu
								renderOptions={() => renderActionMenu(x)}
							/>
						</td>
					</tr>)}
				</tbody>
			</table>}

			{clients.length === 0 && <MessagePage
				title="No clients."
				action={<Button href="/app/clients/add" text="Add a client?" variant="outlined" />}
			/>}
		</FixedWidthPage>}
	/>;
});
