export const Service = {
	Gateway: Symbol(),
	AddressBooks: Symbol(),
	ApiClient: Symbol(),
	ApolloClient: Symbol(),
	AssetEvents: Symbol(),
	Asset: Symbol(),
	DeviceConfiguration: Symbol(),
	AssetType: Symbol(),
	AssetGroup: Symbol(),
	ATrackAsset: Symbol(),
	Authentication: Symbol(),
	Beacons: Symbol(),
	Branding: Symbol(),
	Call: Symbol(),
	CallGroup: Symbol(),
	CefSharp: Symbol(),
	Client: Symbol(),
	DistanceService: Symbol(),
	Emergency: Symbol(),
	EnableFleetConfiguration: Symbol(),
	ErrorResponseHandler: Symbol(),
	FloorLevelConverter: Symbol(),
	FloorPlans: Symbol(),
	GatewayLogs: Symbol(),
	Geofence: Symbol(),
	History: Symbol(),
	LinkedDevice: Symbol(),
	Permissions: Symbol(),
	PermissionManagement: Symbol(),
	Report: Symbol(),
	UserGroup: Symbol(),
	SafetyTimer: Symbol(),
	Site: Symbol(),
	Sound: Symbol(),
	TileServerApiClient: Symbol(),
	Toaster: Symbol(),
	Users: Symbol(),
	TableDataService: Symbol(),
	Telematics: Symbol(),
	WebSocket: Symbol(),
	MapData: Symbol(),

	// Forms
	AddressBookEntrySelector: Symbol(),
	AssetSelector: Symbol(),
	AssetGroupSelector: Symbol(),
	UserSelector: Symbol(),
	UserGroupSelector: Symbol(),
};
