import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryManageAssetGroup($includeClient: Boolean!) {
		assets {
			assetId: id
			name
			client @include(if: $includeClient) {
				id
			}
		}
	}
`;
