import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAssignDeviceToAsset($input: AssignDeviceToAssetInput!) {
		assignDeviceToAsset(input: $input) {
			asset {
				id
			}
		}
	}
`;
