import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import queryString from 'query-string';

import {
	AuthenticationService,
	HistoryService,
	Service,
	useInjection,
} from 'src/services';

import { MapViewMode } from './mapViewMode';
import { HistoryMap } from './historyMap';
import { DateTimeRange } from 'src/components';

export interface CurrentMapViewMode {
	currentMapViewMode: MapViewMode; // The current view mode of the map.
	preferredMapViewMode: MapViewMode; // The mode the user last selected.
}

interface ViewState {
	assetId?: string | null;
	dateTimeRange: DateTimeRange;
	sliderDateTime: moment.Moment;
}

export const HistoryMapContainer = observer(() => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);

	const onChangeFilters = (assetId: string | null, dateTimeRange: DateTimeRange) => {
		let sliderDateTime = viewState.sliderDateTime;
		if (dateTimeRange.start.isAfter(sliderDateTime) || dateTimeRange.end.isBefore(sliderDateTime))
			sliderDateTime = dateTimeRange.end;

		setViewState({
			assetId: assetId || undefined,
			dateTimeRange,
			sliderDateTime: sliderDateTime,
		});
	};

	const onChangeSliderDateTime = (sliderDateTime: moment.Moment) => {
		setViewState({
			...viewState,
			sliderDateTime,
		});
	};

	const updateUrl = () => {
		const search = queryString.stringify({
			assetId: viewState.assetId,
			start: viewState.dateTimeRange.start.toISOString(),
			end: viewState.dateTimeRange.end.toISOString(),
			selected: viewState.sliderDateTime.toISOString(),
		});

		_historyService.history.replace({
			pathname: '/app/map/history',
			search: search,
		});
	};

	const tryParseDateTime = (dateTime: string): moment.Moment | null => {
		const parsed = moment.tz(dateTime, _authenticationService.currentAuth.user.timeZone);
		if (parsed.isValid())
			return parsed;

		return null;
	};

	const getUrlStateValues = (): Partial<ViewState> => {
		const update: Partial<ViewState> = {};
		const queryParams = queryString.parse(_historyService.history.location.search);

		if (queryParams.start && queryParams.end) {
			const start = tryParseDateTime(queryParams.start as string);
			const end = tryParseDateTime(queryParams.end as string);

			if (start && end)
				update.dateTimeRange = { start, end };
		}

		if (queryParams.selected) {
			const selected = tryParseDateTime(queryParams.selected as string);
			if (selected) update.sliderDateTime = selected;
		}

		if (queryParams.assetId) {
			update.assetId = queryParams.assetId as string;
		}

		return update;
	};

	const [viewState, setViewState] = useState<ViewState>(() => {
		const urlStateValues = getUrlStateValues();
		const now = moment.tz(_authenticationService.currentAuth.user.timeZone).startOf('minute');

		return {
			assetId: urlStateValues.assetId,
			dateTimeRange: urlStateValues.dateTimeRange || { start: now.clone().subtract(30, 'minutes'), end: now },
			sliderDateTime: urlStateValues.sliderDateTime || now,
		};
	});

	useEffect(() => {
		const timeout = window.setTimeout(updateUrl, 1000);
		return () => clearTimeout(timeout);
	}, [ viewState ]);

	if (!_authenticationService.currentAuth.permissions.general.viewAssetLocations) {
		_historyService.history.replace('/app');
		return null;
	}

	return <HistoryMap
		assetId={viewState.assetId}
		dateTimeRange={viewState.dateTimeRange}
		sliderDateTime={viewState.sliderDateTime}
		onChangeFilters={onChangeFilters}
		onChangeSliderDateTime={onChangeSliderDateTime}
	/>;
});
