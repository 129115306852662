import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';
import { ManageSiteComponent } from './manageSiteComponent';

import {
	AuthenticationService,
	EnableFleetConfigurationService,
	SiteService,
	Client as C,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

interface IPageData {
	site: C.ISiteDto | null;
	enableFleetConfigurationOptions: C.IEnableFleetConfigurationDto[] | null;
}

export const AddEditSite = observer((props: Props) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _enableFleetConfigurationSerivce = useInjection<EnableFleetConfigurationService>(Service.EnableFleetConfiguration);
	const _siteService = useInjection<SiteService>(Service.Site);

	const load = async (siteId: string | undefined): Promise<IPageData | null> => {
		let site: C.ISiteDto | null = null;
		if (siteId) {
			site = await _siteService.getSite(siteId);
			if (!site)
				return null;
		}

		let enableFleetConfigurationOptions: C.IEnableFleetConfigurationDto[] | null = null;
		if (_authenticationService.currentAuth.user.identity.type == C.IdentityType.SuperUser) {
			enableFleetConfigurationOptions = await _enableFleetConfigurationSerivce.listEnableFleetConfigurations();
		}

		return {
			site,
			enableFleetConfigurationOptions,
		};
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(pageData: IPageData) => <ManageSiteComponent
			site={pageData.site}
			enableFleetConfigurations={pageData.enableFleetConfigurationOptions}
		/>}
	/>;
});
