import { gql } from '@apollo/client';

export const QUERY = gql`
query QueryCallGroupsByNetworkId($networkId: UUID!) {
	callGroupsByNetworkId(networkId: $networkId) {
		id
		name
		networkCallGroupId
		recordCalls
		client {
			id
			name
		}
	}

	networkById(id: $networkId) {
		name
	}
}`;
