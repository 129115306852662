import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddAsset($input: AddAssetInput!) {
		addAsset(input: $input) {
			asset {
				id
			}
		}
	}
`;
