import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

interface Props {
	href?: string | null;
	onClick?: () => void;
	text?: string;
	title?: string;
	disabled?: boolean;
	icon?: React.ReactNode;
	children?: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	icon: {
		minWidth: '35px',
		fontSize: '20px',
	}
}));

export const PopoverMenuItem = (props: Props) => {
	const classes = useStyles();

	const href = React.forwardRef<Link, never>((linkProps, ref) => (
		<Link ref={ref} to={props.href} {...linkProps} />
	));

	return <MenuItem
		className={classes.root}
		component={props.href ? href : 'button'}
		onClick={props.onClick}
		title={props.title}
		disabled={props.disabled}
	>
		{props.icon && <ListItemIcon className={classes.icon}>{props.icon}</ListItemIcon>}
		{props.children || props.text}
	</MenuItem>;
};
