import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers } from 'formik';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikCheckbox } from 'src/components';

import {
	Client as C,
	EmergencyService,
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

import './manageUserAlertSettingsComponent.scss';

interface ManageUserAlertSettingsFormValues {
	emergencySms: boolean;
	emergencyEmail: boolean;
	priorityAlertEmail: boolean;
	geofenceSms: boolean;
	geofenceEmail: boolean;
	speedingEmail: boolean;
}

interface Props {
	user: C.IUserDto;
	settings: C.IUserAlertSettingsDto;
}

export const ManageUserAlertSettingsComponent = observer((props: Props) => {
	const _emergencyService = useInjection<EmergencyService>(Service.Emergency);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const initialFormValues: ManageUserAlertSettingsFormValues = {
		emergencySms: props.settings.emergencySms,
		emergencyEmail: props.settings.emergencyEmail,
		priorityAlertEmail: props.settings.priorityAlertEmail,
		geofenceSms: props.settings.geofenceSms,
		geofenceEmail: props.settings.geofenceEmail,
		speedingEmail: props.settings.speedingEmail,
	};

	const onSubmit = async (values: ManageUserAlertSettingsFormValues, { setSubmitting }: FormikHelpers<ManageUserAlertSettingsFormValues>) => {
		const request: C.IEditUserAlertSettingsRequest = {
			emergencySms: values.emergencySms,
			emergencyEmail: values.emergencyEmail,
			priorityAlertEmail: values.priorityAlertEmail,
			geofenceSms: values.geofenceSms,
			geofenceEmail: values.geofenceEmail,
			speedingEmail: values.speedingEmail,
		};

		try {
			await _emergencyService.editUserAlertSettings(props.user.userId, request);
			_toasterService.showSuccess('Alert and emergency settings updated successfully.');
		} catch (err) {
			_toasterService.handleWithToast(err);
		}

		setSubmitting(false);
	};

	return <FixedWidthPage
		className="form-page manage-user-alert-settings"
		headingText="Enabled Alert & Emergency Settings"
		subheadingText={props.user.name}
		noContentBackground
	>
		<Formik
			initialValues={initialFormValues}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageUserAlertSettingsFormValues>) => <Form className="formik-form">
				<div className="content-box">
					<h2>Emergency Alerts</h2>

					<Field
						name="emergencySms"
						label="SMS"
						type="checkbox"
						component={FormikCheckbox}
					/>

					<Field
						name="emergencyEmail"
						label="Email"
						type="checkbox"
						component={FormikCheckbox}
					/>
				</div>

				<div className="content-box">
					<h2>Priority Alerts</h2>

					<Field
						name="priorityAlertEmail"
						label="Email"
						type="checkbox"
						component={FormikCheckbox}
					/>
				</div>

				<div className="geofence-settings content-box">
					<h2>Geofence Alerts</h2>

					<Field
						name="geofenceSms"
						label="SMS"
						type="checkbox"
						component={FormikCheckbox}
					/>

					<Field
						name="geofenceEmail"
						label="Email"
						type="checkbox"
						component={FormikCheckbox}
					/>
				</div>

				<div className="content-box">
					<h2>Asset Speeding Alerts</h2>

					<Field
						name="speedingEmail"
						label="Email"
						type="checkbox"
						component={FormikCheckbox}
					/>
				</div>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					loading={formikProps.isSubmitting}
					startIcon={<SaveIcon />}
					text="Save Changes"
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
