import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateTeltonikaDevice($input: UpdateTeltonikaDeviceInput!) {
		updateTeltonikaDevice(input: $input) {
			teltonikaDevice {
				id
			}
		}
	}
`;
