import bind from 'bind-decorator';
import mapboxgl, { Marker } from 'mapbox-gl';
import { autorun, IReactionDisposer, makeObservable, observable } from 'mobx';

import { MapMarkerIcon, MapMarkerShape } from 'src/../__generated__/globalTypes';
import { MapMarkerManager } from 'src/app/map/mapMarkerManager';
import { renderMapMarkerIcon, mapMarkerIconOptions } from 'src/app/assetTypes/assetTypesHelpers';
import { QueryCustomMapMarkers_customMapMarkers } from 'src/graphql/__generated__/QueryCustomMapMarkers';
import { MapViewMode } from './mapViewMode';

interface ICustomMapMarkerState {
	marker: QueryCustomMapMarkers_customMapMarkers;
	mapboxMarker?: mapboxgl.Marker;
	labelElement?: HTMLDivElement;
}

interface ICustomMapMarker {
	name: string;
	details: string | null;
	latitude: number;
	longitude: number;
	size: number;
	backgroundShape: MapMarkerShape | null;
	backgroundShapeColor: string | null;
	icon: MapMarkerIcon | null;
	iconColor: string | null;
}

export class CustomMapMarkerEditorState implements ICustomMapMarker {
	existingCustomMapMarker?: QueryCustomMapMarkers_customMapMarkers;

	@observable editing = false;

	@observable name = '';
	@observable details: string | null = '';
	@observable latitude: number;
	@observable longitude: number;
	@observable size = 25;
	@observable backgroundShape: MapMarkerShape | null = MapMarkerShape.CIRCLE;
	@observable backgroundShapeColor: string | null = '#000000';
	@observable icon: MapMarkerIcon | null = null;
	@observable iconColor: string | null = '#FFFFFF';

	static newMarker(location: mapboxgl.LngLat) {
		const state = new CustomMapMarkerEditorState();

		state.editing = true;

		state.latitude = location.lat;
		state.longitude = location.lng;

		makeObservable(state);
		return state;
	}

	static existingMarker(existing: QueryCustomMapMarkers_customMapMarkers, editing: boolean) {
		const state = new CustomMapMarkerEditorState();

		state.existingCustomMapMarker = existing;
		state.editing = editing;

		state.name = existing.name;
		state.details = existing.details;
		state.latitude = existing.latitude;
		state.longitude = existing.longitude;
		state.size = existing.size;
		state.backgroundShape = existing.backgroundShape;
		state.backgroundShapeColor = existing.backgroundShapeColor;
		state.icon = existing.icon;
		state.iconColor = existing.iconColor;

		makeObservable(state);
		return state;
	}
}

export class CustomMapMarkerManager extends MapMarkerManager {
	private _customMarkerStates = new Map<string, ICustomMapMarkerState>();

	private _onClickCustomMapMarker: (marker: QueryCustomMapMarkers_customMapMarkers) => void;

	@observable editorState: CustomMapMarkerEditorState | undefined;
	private _editorMarker: mapboxgl.Marker | undefined;
	private _edtiorMarkerElement: HTMLDivElement | undefined;
	private _disposeEditorMarkerRenderer: IReactionDisposer | undefined;

	constructor(map: mapboxgl.Map, onClickCustomMapMarker: (marker: QueryCustomMapMarkers_customMapMarkers) => void) {
		super(map);

		this._onClickCustomMapMarker = onClickCustomMapMarker;

		makeObservable(this);
	}

	@bind
	addMarker(marker: QueryCustomMapMarkers_customMapMarkers) {
		const state: ICustomMapMarkerState = { marker };
		this._customMarkerStates.set(marker.id, state);
		this.createMapboxMarker(state);
	}

	@bind
	private createMapboxMarker(state: ICustomMapMarkerState) {
		const { marker } = state;

		if (this._currentMapViewMode === MapViewMode.FloorPlan)
			return;

		const markerLocation = this.getMarkerLocation(marker.longitude!, marker.latitude!, marker.floorPlan?.id || undefined);
		if (!markerLocation)
			return;

		const markerElement = this.baseCreateNewMarkerElement();
		this.renderMarker(markerElement, marker);

		markerElement.dataset['customMapMarkerId'] = state.marker.id;

		const mapboxMarker = new mapboxgl.Marker(markerElement)
			.setLngLat([ markerLocation.lng, markerLocation.lat ])
			.addTo(this._map);

		state.mapboxMarker = mapboxMarker;
	}

	@bind
	removeMarker(id: string) {
		const state = this._customMarkerStates.get(id);
		if (!state)
			return;

		this._customMarkerStates.delete(id);

		if (state.mapboxMarker) {
			state.mapboxMarker.remove();
			state.mapboxMarker = undefined;
		}
	}

	@bind
	addNewMarker(location: mapboxgl.LngLat) {
		const editorState = CustomMapMarkerEditorState.newMarker(location);
		this.beginEditing(editorState);
	}

	@bind
	viewMarkerInfo(id: string) {
		if (this.editorState?.existingCustomMapMarker?.id === id)
			return;

		const state = this._customMarkerStates.get(id);
		if (!state)
			return;

		this.editorState = CustomMapMarkerEditorState.existingMarker(state.marker, false);
	}

	@bind
	editExistingMarker(id: string) {
		const state = this._customMarkerStates.get(id);
		if (!state)
			return;

		if (state.mapboxMarker) {
			state.mapboxMarker.remove();
			state.mapboxMarker = undefined;
		}

		const editorState = CustomMapMarkerEditorState.existingMarker(state.marker, true);
		this.beginEditing(editorState);
	}

	@bind
	private beginEditing(editorState: CustomMapMarkerEditorState) {
		this.editorState = editorState;

		const markerElement = this.baseCreateNewMarkerElement();
		this.renderMarker(markerElement, this.editorState);

		this._edtiorMarkerElement = markerElement;

		this._editorMarker = new mapboxgl.Marker(markerElement)
			.setDraggable(true)
			.setLngLat([ editorState.longitude, editorState.latitude ])
			.addTo(this._map);

		this._editorMarker.on('dragend', this.onDragEnd);

		this._map.getCanvasContainer().classList.add('custom-map-marker-positioning');

		this._disposeEditorMarkerRenderer = autorun(() => {
			this.updateEditorMarker();
		});
	}

	@bind
	updateEditorMarker() {
		const element = this._edtiorMarkerElement;
		if (!this.editorState || !element)
			return;

		this.renderMarker(element, this.editorState);
	}

	@bind
	private renderMarker(element: HTMLDivElement, marker: ICustomMapMarker) {
		element.classList.add('connect-map-marker');

		while (element.childElementCount > 0)
			element.removeChild(element.lastChild!);

		element.style.width = marker.size + 'px';
		element.style.height = marker.size + 'px';

		const mapMarkerContent = document.createElement('div');
		mapMarkerContent.className = 'connect-map-marker__content';

		if (marker.backgroundShape === null) {
			mapMarkerContent.classList.add('custom-map-marker-no-shape');
		} else {
			// Has a shape, default is circle.
			mapMarkerContent.style.backgroundColor = marker.backgroundShapeColor!;

			if (marker.backgroundShape === MapMarkerShape.SQUARE)
				mapMarkerContent.classList.add('custom-map-marker-square');
		}

		const icon = mapMarkerIconOptions.find(x => x.mapMarkerIcon === marker.icon)?.MapMarkerIcon;
		renderMapMarkerIcon(mapMarkerContent, icon, marker.size - 10, marker.iconColor)!;

		element.appendChild(mapMarkerContent);
	}

	@bind
	private onDragEnd(event: any) {
		if (!this.editorState)
			return;

		const marker = event.target as Marker;
		const lngLat = marker.getLngLat();

		this.editorState.latitude = lngLat.lat;
		this.editorState.longitude = lngLat.lng;
	}

	@bind
	onMapboxClick(event: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
		if (!this.editorState || !this.editorState.editing)
			return false;

		this._editorMarker?.setLngLat(event.lngLat);
		this.editorState.latitude = event.lngLat.lat;
		this.editorState.longitude = event.lngLat.lng;

		return true;
	}

	@bind
	changeMapMarkerShape(shape: MapMarkerShape | null) {
		if (!this.editorState)
			return;

		this.editorState.backgroundShape = shape;

		if (!this.editorState.backgroundShape) {
			this.editorState.icon = MapMarkerIcon.WARNING;
			this.editorState.size = Math.max(this.editorState.size, this.editorState.size + 10);

			if (this.editorState.iconColor === '#FFFFFF')
				this.editorState.iconColor = '#000000';
		} else {
			if (this.editorState.backgroundShapeColor === '#000000' && this.editorState.iconColor === '#000000')
				this.editorState.iconColor = '#FFFFFF';
		}

	}

	@bind
	tryStopEditingMapMarkerIfEditing(action: string, restoreExisting: boolean): boolean {
		if (this.editorState) {
			if (!this.editorState.editing) {
				this.editorState = undefined;
				return true;
			}

			const message = action + ' will exit the map marker editor and any changes will be lost. Continue?';
			if (!confirm(message))
				return false;

			this.stopEditingMapMarker(restoreExisting);
		}

		return true;
	}

	@bind
	stopEditingMapMarker(restoreExisting: boolean) {
		this._map.getCanvasContainer().classList.remove('custom-map-marker-positioning');

		this._editorMarker!.remove();

		this._disposeEditorMarkerRenderer!();

		if (this.editorState && this.editorState.existingCustomMapMarker && restoreExisting) {
			const state = this._customMarkerStates.get(this.editorState.existingCustomMapMarker.id);
			if (state)
				this.createMapboxMarker(state);
		}

		this.editorState = undefined;
	}

	@bind
	protected clearMarkers(): void {
		for (const state of this._customMarkerStates.values()) {
			if (state.mapboxMarker) {
				state.mapboxMarker.remove();
				state.mapboxMarker = undefined;
			}
		}
	}

	@bind
	protected reinitialiseMarkers(): void {
		for (const state of this._customMarkerStates.values())
			this.createMapboxMarker(state);
	}

	@bind
	private getMouseEventMarkerState(ev: MouseEvent): ICustomMapMarkerState | null {
		if (!ev.currentTarget)
			return null;

		const element = ev.currentTarget as HTMLDivElement;
		if (!element.dataset)
			return null;

		const customMapMarkerId = element.dataset['customMapMarkerId'];
		if (!customMapMarkerId)
			return null;

		const state = this._customMarkerStates.get(customMapMarkerId);
		if (!state)
			return null;

		return state;
	}

	@bind
	protected onClickMarker(ev: MouseEvent): void {
		if (this.editorState?.editing)
			return;

		const state = this.getMouseEventMarkerState(ev);
		if (!state)
			return;

		this._onClickCustomMapMarker(state.marker);
		ev.stopImmediatePropagation();
	}

	@bind
	protected onMouseOverMarker(ev: MouseEvent): void {
		const state = this.getMouseEventMarkerState(ev);
		if (!state)
			return;

		this.addLabel(state);
	}

	@bind
	protected onMouseOutMarker(ev: MouseEvent): void {
		const state = this.getMouseEventMarkerState(ev);
		if (!state)
			return;

		this.removeLabel(state);
	}

	@bind
	private addLabel(state: ICustomMapMarkerState) {
		if (state.labelElement || !state.mapboxMarker)
			return;

		const label = document.createElement('div');
		label.className = 'connect-map-marker__label';

		const arrow = document.createElement('div');
		arrow.className = 'connect-map-marker__label__arrow';
		label.appendChild(arrow);

		const textDiv = document.createElement('div');
		textDiv.className = 'connect-map-marker__label__text';


		label.style.marginLeft = `${state.marker.size - 3}px`;

		textDiv.appendChild(document.createTextNode(state.marker.name));
		label.appendChild(textDiv);

		state.labelElement = label;
		state.mapboxMarker.getElement().appendChild(label);
	}

	@bind
	private removeLabel(state: ICustomMapMarkerState) {
		if (!state.mapboxMarker || !state.labelElement)
			return;

		const markerElement = state.mapboxMarker.getElement();

		markerElement.removeChild(state.labelElement);
		state.labelElement = undefined;
	}
}
