import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { isArray } from 'lodash';

interface IProps<OptionsType> {
	name?: string;
	className?: string;
	label?: string;
	options: OptionsType[];
	getOptionLabel?: (option: OptionsType) => string;
	renderOption?: (option: OptionsType) => React.ReactNode;
	getOptionValue: (option: OptionsType) => any;
	helperText?: string;
	placeholder?: string;
	clearable?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	required?: boolean;
	onChange: (option: OptionsType | OptionsType[] | null) => any;
	multi?: boolean;
	value: any;
	touched?: boolean;
	error?: string;
	onBlur?: () => void;
	outlined?: boolean;
	disableCloseOnSelect?: boolean;
	groupBy?: (option: OptionsType) => string;
}

export function CustomAutocomplete<OptionsType>(props: IProps<OptionsType>) {
	let selectedValues: OptionsType | OptionsType[] | null;
	if (props.multi) {
		selectedValues = [];

		if (props.value) {
			for (let i = 0; i < props.value.length; i++) {
				const returnedValue = props.options.find(x => props.getOptionValue(x) === props.value[i]);
				if (returnedValue)
					selectedValues.push(returnedValue);
			}
		}
	} else {
		selectedValues = props.options.find(x => props.getOptionValue(x) === props.value) || null;
	}

	return <Autocomplete
		value={selectedValues}
		size="medium"
		options={props.options}
		getOptionLabel={props.getOptionLabel}
		renderOption={props.renderOption}
		renderTags={(value, getTagProps) => value.map((option, index) => <Chip
			variant="outlined"
			label={props.getOptionLabel!!(isArray(option) ? option[index] : option)}
			size="medium"
			{...getTagProps({ index })}
		/>)}
		renderInput={(params) => <TextField
			{...params}
			variant={props.outlined ? 'outlined' : 'filled'}
			label={props.label}
			placeholder={props.placeholder}
			helperText={props.touched && !!props.error ? props.error : props.helperText}
			error={(props.touched && !!props.error)}
			required={props.required}
		/>}
		placeholder={props.placeholder}
		disableClearable={!props.clearable}
		disabled={props.disabled}
		loading={props.isLoading}
		onChange={(_, selected) => props.onChange(selected)}
		onBlur={props.onBlur}
		className={props.className}
		multiple={props.multi}
		disableCloseOnSelect={props.disableCloseOnSelect}
		groupBy={props.groupBy}
	/>;
}
