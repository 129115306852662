import React, { useState } from 'react';
import { observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { FixedWidthPage } from 'src/components';

import {
	Client as C,
} from 'src/services';

import './gatewayOverviewComponent.scss';

export interface GatewayOverviewProps {
	gateway: C.IGatewayDto;
	apiKey: string;
}

export const GatewayOverviewComponent = observer((props: GatewayOverviewProps) => {
	const [showApiKey, setShowApiKey] = useState<boolean>(false);

	return <FixedWidthPage
		className="gateway-overview"
		headingText={props.gateway.name}
		subheadingText={props.gateway.serial}
		noContentBackground
	>
		<div className="icon-card content-box">
			<VpnKeyIcon />

			<div className="card-content">
				<div className="card-title">API key</div>

				<Input
					className="api-key monospace"
					type={showApiKey ? 'text' : 'password'}
					value={props.apiKey}
					readOnly
					disableUnderline
					endAdornment={<InputAdornment position="end">
						<IconButton
							aria-label="Toggle password visibility"
							onClick={() => setShowApiKey(!showApiKey)}
							title="Toggle visibility"
						>
							{showApiKey ? <VisibilityIcon /> : <VisibilityOffIcon />}
						</IconButton>
					</InputAdornment>}
				/>
			</div>
		</div>
	</FixedWidthPage>;
});
