import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import parsePhoneNumber from 'libphonenumber-js/mobile';
import useAsyncEffect from 'use-async-effect';

import {
	Client as C,
	HistoryService,
	PermissionsService,
	Service,
	ToasterService,
	useCurrentUser,
	useInjection,
	UsersService,
} from 'src/services';

import { Button, FixedWidthPage, FormikSelect, MessagePage, FormikCheckbox, FormikTextField } from 'src/components';
import { runFormValidation } from 'src/util';
import { selectOptions } from 'src/util/timeZones';
import { measurementsSystemsOptions } from 'src/util/systemsOfMeasurement';

interface AddUserFormValues {
	name: string;
	emailAddress: string;
	mobilePhoneNumber: string;
	identityType: C.AddUserType | null;
	dealerId: string | null;
	clientId: string | null;
	isAdmin: boolean;
	timeZone: string;
	usesMetric: string;
	sendActivationEmail: boolean;
}

function userTypeLabel(creatingUserType: C.AddUserType): string {
	if (creatingUserType === C.AddUserType.SuperUser)
		return 'Super User';

	if (creatingUserType === C.AddUserType.Dealer)
		return 'Dealer Administrator';

	return `${creatingUserType}`;
}

export const AddUser = observer(() => {
	const currentUser = useCurrentUser()!;
	const _historyService = useInjection<HistoryService>(Service.History);
	const _permissionsService = useInjection<PermissionsService>(Service.Permissions);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _usersService = useInjection<UsersService>(Service.Users);

	const [loading, setLoading] = useState<boolean>(true);
	const [userTypes, setUserTypes] = useState<C.AddUserType[]>([]);
	const [dealers, setDealers] = useState<C.IDealerDto[]>([]);
	const [clients, setClients] = useState<C.IClientDto[]>([]);

	useAsyncEffect(async () => {
		const addPermissions = await _permissionsService.fetchUserAddPermissions(currentUser.identityId);

		setUserTypes(addPermissions.userTypeCanAdd);

		if (addPermissions.dealers)
			setDealers(addPermissions.dealers);

		if (addPermissions.clients)
			setClients(addPermissions.clients);

		setLoading(false);
	}, []);

	const onSubmit = async (values: AddUserFormValues, { setSubmitting }: FormikHelpers<AddUserFormValues>) => {
		const creatingUserType = values.identityType!;
		const currentUserIdentity = currentUser.identity;

		const request: C.IAddUserRequest = {
			name: values.name,
			emailAddress: values.emailAddress,
			mobilePhoneNumber: values.mobilePhoneNumber,
			type: creatingUserType,
			dealerId: null,
			clientId: null,
			isAdmin: false,
			timeZone: values.timeZone,
			usesMetric: values.usesMetric === 'metric' ? true : false,
			sendActivationEmail: values.sendActivationEmail,
		};

		if (currentUserIdentity.type === C.IdentityType.Dealer && creatingUserType === C.AddUserType.Dealer) {
			request.dealerId = currentUserIdentity.dealerId;
		} else if (shouldSelectDealer(values)) {
			request.dealerId = values.dealerId;
		}

		if (currentUserIdentity.type === C.IdentityType.Client && creatingUserType === C.AddUserType.Client) {
			request.clientId = currentUserIdentity.clientId;
		} else if (shouldSelectClient(values)) {
			request.clientId = values.clientId;
		}

		if (creatingUserType === C.AddUserType.SuperUser || creatingUserType === C.AddUserType.Dealer)
			request.isAdmin = true;
		else
			request.isAdmin = values.isAdmin;

		try {
			await _usersService.addUser(request);
			_historyService.history.push('/app/users/list');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to add user.');
		} finally {
			setSubmitting(false);
		}
	};

	const shouldSelectDealer = (values: AddUserFormValues): boolean => {
		if (values.identityType == null)
			return false;

		if (currentUser.identity.type === C.IdentityType.SuperUser &&
			values.identityType === C.AddUserType.Dealer)
			return true;

		return false;
	};

	const renderDealerSelector = (formikProps: FormikProps<AddUserFormValues>) => {
		if (!shouldSelectDealer(formikProps.values))
			return null;

		return <FormikSelect
			name="dealerId"
			label="Dealer"
			placeholder="Select a dealer..."
			options={dealers}
			form={formikProps}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.dealerId}
			required
		/>;
	};

	const shouldSelectClient = (values: AddUserFormValues): boolean => {
		if (values.identityType == null)
			return false;

		if (currentUser.identity.type === C.IdentityType.Dealer &&
			values.identityType === C.AddUserType.Client)
			return true;

		return false;
	};

	const renderClientSelector = (formikProps: FormikProps<AddUserFormValues>) => {
		if (!shouldSelectClient(formikProps.values))
			return null;

		return <>
			<FormikSelect
				name="clientId"
				label="Client"
				placeholder="Select a client..."
				options={clients}
				form={formikProps}
				getOptionLabel={option => option.name}
				getOptionValue={option => option.clientId}
				required
			/>

			<Field
				name="isAdmin"
				label="Administrator"
				type="checkbox"
				component={FormikCheckbox}
			/>
		</>;
	};

	const validateForm = (values: AddUserFormValues, errors: FormikErrors<AddUserFormValues>) => {
		if (!values.name)
			errors.name = 'Name is required.';

		if (!values.emailAddress)
			errors.emailAddress = 'Email address is required.';

		if (values.mobilePhoneNumber) {
			const phoneNumber = parsePhoneNumber(values.mobilePhoneNumber);
			if (phoneNumber == null || !phoneNumber.isValid())
				errors.mobilePhoneNumber = 'Please enter a valid international mobile phone number (beginning with +). For example, the New Zealand mobile number 027 123 4567 should be entered as +64 27 123 4567.';
		}

		if (shouldSelectDealer(values)) {
			if (!values.dealerId)
				errors.dealerId = 'You must select a dealer.';
		}

		if (shouldSelectClient(values)) {
			if (!values.clientId)
				errors.clientId = 'You must select a client.';
		}
	};

	if (loading)
		return <MessagePage loading />;

	const initialFormValues: AddUserFormValues = {
		name: '',
		emailAddress: '',
		mobilePhoneNumber: '',
		identityType: userTypes.length === 1 ? userTypes[0] : null,
		dealerId: null,
		clientId: null,
		isAdmin: false,
		timeZone: currentUser.timeZone,
		usesMetric: 'metric',
		sendActivationEmail: true,
	};

	return <FixedWidthPage
		className="form-page"
		headingText="Add User"
	>
		<Formik
			initialValues={initialFormValues!}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<AddUserFormValues>) => <Form className="formik-form">
				<Field
					name="name"
					label="Name"
					type="text"
					component={FormikTextField}
					required
				/>

				<Field
					name="emailAddress"
					label="Email Address"
					type="text"
					component={FormikTextField}
					required
				/>

				<Field
					name="mobilePhoneNumber"
					label="Mobile Phone Number"
					component={FormikTextField}
				/>

				{userTypes.length > 1 && <FormikSelect
					name="identityType"
					label="User Type"
					placeholder="Select a user type..."
					options={userTypes}
					form={formikProps}
					getOptionLabel={userTypeLabel}
					getOptionValue={option => option}
					required
				/>}

				{renderDealerSelector(formikProps)}
				{renderClientSelector(formikProps)}

				<FormikSelect
					name="timeZone"
					label="Time Zone"
					options={selectOptions}
					form={formikProps}
					clearable={false}
					getOptionLabel={option => option.label}
					getOptionValue={option => option.value}
					required
				/>

				<FormikSelect
					name="usesMetric"
					label="System of Measurement"
					options={measurementsSystemsOptions}
					form={formikProps}
					clearable={false}
					getOptionLabel={option => option.label}
					getOptionValue={option => option.value}
					required
				/>

				{currentUser.identity.type !== C.IdentityType.Client && <Field
					name="sendActivationEmail"
					label="Send user activation email"
					type="checkbox"
					component={FormikCheckbox}
					helperText="The created user must activate their account before they are able to login. If you de-select this option, you can send the activation email via the user management page at a later time."
				/>}

				<Button
					type="submit" variant="contained" color="primary"
					text="Add User"
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
