import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';

import { ManageUserAlertSettingsComponent } from './manageUserAlertSettingsComponent';

import {
	Client as C,
	EmergencyService,
	UsersService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

interface PageData {
	user: C.IUserDto;
	settings: C.IUserAlertSettingsDto;
}

export const EditUserAlertSettings = observer((props: Props) => {
	const _emergencyService = useInjection<EmergencyService>(Service.Emergency);
	const _usersService = useInjection<UsersService>(Service.Users);

	const load = async (userId: string): Promise<PageData | null> => {
		const user = await _usersService.getUser(userId);
		if (!user)
			return null;

		const settings = await _emergencyService.fetchUserAlertSettings(userId);

		return {
			user: user!,
			settings,
		};
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(data: PageData) => <ManageUserAlertSettingsComponent
			{...data}
		/>}
	/>;
});
