import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, FormikHelpers, Field } from 'formik';
import { match } from 'react-router-dom';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import { Button, FixedWidthPage, FormikSelect, ThingLoader, FormikCheckbox } from 'src/components';

import { executeQueryDealerList, QueryDealerList_dealers } from 'src/graphql/__generated__/queries/queryDealerList';

import {
	Client as C,
	EnableFleetConfigurationService,
	Service,
	HistoryService,
	SiteService,
	ToasterService,
	useInjection,
} from 'src/services';

import './enableFleet.scss';

export interface Props {
	match: match<{ id: string, siteId: string }>;
}

interface ILoadData {
	site: C.ISiteDto;
	initialFormValues: IFormValues;
	dealerOptions: QueryDealerList_dealers[];
}

interface SiteField {
	fieldId: string;
	label: string;
	hasPermission: boolean;
	existingPermissionId?: string;
}

interface IFormValues {
	permissionsByDealer: { [key: string]: SiteField[] };
	dealerId: string;
}

export const ManageEnableFleetPermissions = observer((props: Props) => {
	const _apolloClient = useInjection<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _enableFleetConfigurationService = useInjection<EnableFleetConfigurationService>(Service.EnableFleetConfiguration);
	const _siteService = useInjection<SiteService>(Service.Site);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const load = async (siteId: string): Promise<ILoadData | null> => {
		const site = await _siteService.getSite(siteId);

		const dealersListQuery = await executeQueryDealerList(_apolloClient);

		if (dealersListQuery.error || !dealersListQuery.data)
			throw dealersListQuery.error || 'Failed to load dealers.';

		let dealers = dealersListQuery.data.dealers;

		if (!site?.enableFleetConfigurationId || !dealers)
			return null;

		dealers = dealers.filter(x => site.dealerIds.some(y => x.id === y));

		const dealerOptions: QueryDealerList_dealers[] = [];
		for (const dealerId of site.dealerIds) {
			const dealer = dealers.find(x => x.id === dealerId);
			if (!dealer)
				continue;

			dealerOptions.push(dealer);
		}

		const configurationFields = await _enableFleetConfigurationService.getEnableFleetConfigurationFields(site.enableFleetConfigurationId);
		const dealersPermissions = await _enableFleetConfigurationService.getEnableFleetPermissionBySiteId(siteId);

		const permissionsByDealer: { [key: string]: SiteField[]; } = {};
		for (const dealer of dealers) {
			permissionsByDealer[dealer.id] = [];

			for (const field of configurationFields) {
				const existingPermission = dealersPermissions.find(x => x.dealerId === dealer.id && x.enableFleetConfigurationFieldId === field.enableFleetConfigurationFieldId);

				permissionsByDealer[dealer.id].push({
					fieldId: field.enableFleetConfigurationFieldId,
					label: field.label,
					hasPermission: !!existingPermission,
					existingPermissionId: existingPermission?.enableFleetFieldPermissionId,
				});
			}
		}

		return {
			site,
			initialFormValues: {
				permissionsByDealer: permissionsByDealer,
				dealerId: '',
			},
			dealerOptions,
		};
	};

	const onSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>, loadData: ILoadData) => {
		const request: C.IUpdateEnableFleetPermissionsRequest = {
			add: [],
			remove: [],
		};

		for (const dealerId of Object.keys(values.permissionsByDealer)) {
			const fields = values.permissionsByDealer[dealerId];

			for (const field of fields) {
				const previous = loadData.initialFormValues.permissionsByDealer[dealerId].find(x => x.fieldId === field.fieldId)!;

				if (field.hasPermission === previous.hasPermission) {
					continue;
				} else if (field.hasPermission && !previous.hasPermission) {
					request.add!.push({
						enableFleetConfigurationFieldId: field.fieldId,
						dealerId,
					});
				} else {
					request.remove!.push(previous.existingPermissionId!);
				}
			}
		}

		try {
			await _enableFleetConfigurationService.updateEnableFleetPermissions(loadData.site.siteId, request);
			_historyService.history.push('/app/sites/list');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update EnableFleet permissions.');
		} finally {
			setSubmitting(false);
		}
	};

	const renderCurrentFields = (values: IFormValues) => {
		return <div className="content-box">
			<p>Select the fields this dealer has permission to change:</p>

			{values.permissionsByDealer[values.dealerId].map((field, index) => <Field
				key={field.fieldId}
				name={`permissionsByDealer.${values.dealerId}.${index}.hasPermission`}
				label={field.label}
				type="checkbox"
				component={FormikCheckbox}
			/>)}
		</div>;
	};

	return <ThingLoader
		load={load}
		id={props.match.params.siteId}
		render={(loadData: ILoadData) => <FixedWidthPage
			className="form-page"
			headingText="Manage EnableFleet Permissions"
			subheadingText={loadData.site.name}
			noContentBackground
		>
			<Formik
				initialValues={loadData.initialFormValues!}
				validateOnChange={false}
				onSubmit={(values: IFormValues, actions: FormikHelpers<IFormValues>) => onSubmit(values, actions, loadData)}
				render={(formikProps: FormikProps<IFormValues>) => <Form className="formik-form">
					<div className="content-box">
						<FormikSelect
							name="dealerId"
							label="Dealer"
							placeholder="Select a dealer..."
							clearable={false}
							options={loadData.dealerOptions}
							form={formikProps}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.id}
						/>
					</div>

					{formikProps.values.dealerId && renderCurrentFields(formikProps.values)}

					<Button
						type="submit"
						variant="contained"
						color="primary"
						text="Save Changes"
						loading={formikProps.isSubmitting}
					/>
				</Form>}
			/>
		</FixedWidthPage>}
	/>;
});
