import { RGBColor } from 'react-color';

function rgbComponentToHex(c: number) {
	const hex = c.toString(16);
	return hex.length == 1 ? '0' + hex : hex;
}

export function rgbToHexColorOnly(color: RGBColor) {
	return '#' + rgbComponentToHex(color.r) + rgbComponentToHex(color.g) + rgbComponentToHex(color.b);
}

export function hexToRgb(color: string, alpha: number): RGBColor {
	return {
		r: parseInt(color.substring(1, 3), 16),
		g: parseInt(color.substring(3, 5), 16),
		b: parseInt(color.substring(5, 7), 16),
		a: alpha,
	};
}
