import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ManageAssetServiceReminder }  from './manageAssetServiceReminder';

export interface Props {
	match: match<{ id: string }>;
}

export const AddAssetServiceReminderForReminderType = observer((props: Props) => {
	return <ManageAssetServiceReminder
		assetServiceReminderTypeId={props.match.params.id}
	/>;
});
