import { inject, injectable } from 'inversify';

import * as C from './client';

import { Service } from './service';

@injectable()
export class AssetGroupService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client
	) {

	}

	async addAssetGroup(request: C.IAddAssetGroupRequest): Promise<C.IAssetGroupDto> {
		return this.client.addAssetGroup(new C.AddAssetGroupRequest(request));
	}

	async getAssetGroup(assetGroupId: string): Promise<C.IAssetGroupDto> {
		return this.client.getAssetGroupById(assetGroupId);
	}

	async updateAssetGroup(assetGroupId: string, request: C.IUpdateAssetGroupRequest): Promise<C.IAssetGroupDto> {
		return this.client.updateAssetGroupById(assetGroupId, new C.UpdateAssetGroupRequest(request));
	}

	async deleteAssetGroup(assetGroupId: string): Promise<void> {
		return this.client.deleteAssetGroupById(assetGroupId);
	}

	async getAssetGroups(assetGroupType?: C.ListAssetGroupsType): Promise<C.IAssetGroupDto[]> {
		return this.client.listAssetGroups(assetGroupType);
	}

	async getAssetGroupAssets(assetGroupId: string): Promise<C.IAssetDto[]> {
		return this.client.getAssetGroupAssets(assetGroupId);
	}

	async addAssetGroupAssets(assetGroupId: string, request: C.IAssetGroupAddRemoveAssetsRequest): Promise<C.IAssetDto[]> {
		return this.client.assetGroupAddAssets(assetGroupId, new C.AssetGroupAddRemoveAssetsRequest(request));
	}

	async removeAssetGroupAssets(assetGroupId: string, request: C.IAssetGroupAddRemoveAssetsRequest): Promise<void> {
		return this.client.assetGroupRemoveAssets(assetGroupId, new C.AssetGroupAddRemoveAssetsRequest(request));
	}

	async getAssetGroupGeofences(assetGroupId: string): Promise<C.IGeofenceDto[]> {
		return this.client.getAssetGroupGeofences(assetGroupId);
	}

	async addAssetGroupGeofences(assetGroupId: string, request: C.IAssetGroupAddRemoveGeofencesRequest): Promise<C.IGeofenceDto[]> {
		return this.client.assetGroupAddGeofences(assetGroupId, new C.AssetGroupAddRemoveGeofencesRequest(request));
	}

	async removeAssetGroupGeofences(assetGroupId: string, request: C.IAssetGroupAddRemoveGeofencesRequest): Promise<void> {
		return this.client.assetGroupRemoveGeofences(assetGroupId, new C.AssetGroupAddRemoveGeofencesRequest(request));
	}
}
