import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import AddIcon from '@material-ui/icons/Add';

import { FixedWidthPage, MessagePage, Button, PopoverMenu, PopoverMenuItem, ErrorMessagePage, LoadingMessagePage } from 'src/components';

import { useQueryDealerList, QueryDealerList_dealers } from 'src/graphql/__generated__/queries/queryDealerList';

export const DealersList = observer(() => {
	const dealersListQuery = useQueryDealerList({});

	function renderActionMenu(dealer: QueryDealerList_dealers) {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				title="Edit dealer"
				href={`/app/dealers/${dealer.id}/edit`}
			/>,
		];
	}

	if (dealersListQuery.loading)
		return <LoadingMessagePage />;

	if (dealersListQuery.error || !dealersListQuery.data?.dealers)
		return <ErrorMessagePage />;

	const dealers = dealersListQuery.data.dealers.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

	return <FixedWidthPage
		headingText="Dealers"
		headingActions={<Button href="/app/dealers/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
		noContentBackground
		contentClassName={classNames({ 'flex-fill-no-overflow': dealers.length === 0 })}
	>
		{dealers.length > 0 && <table className="card-table">
			<thead>
				<tr>
					<th>Dealer Name</th>
				</tr>
			</thead>
			<tbody>
				{dealers.map(x => <tr
					key={x.id}
					className="content-box"
				>
					<td>{x.name}</td>
					<td className="actions">
						<PopoverMenu
							renderOptions={() => renderActionMenu(x)}
						/>
					</td>
				</tr>)}
			</tbody>
		</table>}

		{dealers.length === 0 && <MessagePage
			title="No dealers."
			action={<Button href="/app/dealers/add" text="Add a dealer?" variant="outlined" />}
		/>}
	</FixedWidthPage>;
});
