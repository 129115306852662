import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetServiceReminderTypesForAssetServiceReminders($includeClients: Boolean!) {
		assetServiceReminderTypes {
			id
			name
			dateEnabled
			odometerEnabled
			engineTimeEnabled
			client @include(if: $includeClients) {
				id
				name
			}
		}
	}
`;
