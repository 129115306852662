import React from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import { FormikTextField } from 'src/components';

interface ICamperVanDeviceFormValues {
	imei: string;
}

interface IProps<T> {
	form: FormikProps<T>;
}

export const ManageCamperVanDeviceComponent = observer(<T extends ICamperVanDeviceFormValues>(props: IProps<T>) => {
	return <>
		<Field
			name="imei"
			label="IMEI"
			type="text"
			component={FormikTextField}
			required
		/>
	</>;
});
