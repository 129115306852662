import React from 'react';
import { Formik, Form, Field, FormikProps, FormikHelpers, FormikErrors } from 'formik';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	AuthenticationService,
	Client as C,
	UsersService,
	HistoryService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

interface ChangePasswordFormValues {
	currentPassword: string;
	newPassword: string;
	newPasswordConfirm: string;
}

const validateForm = (values: ChangePasswordFormValues, errors: FormikErrors<ChangePasswordFormValues>) => {
	if (!values.currentPassword)
		errors.currentPassword = 'Current password is required.';

	if (!values.newPassword)
		errors.newPassword = 'New password is required.';
	else if (values.newPassword.length < 8)
		errors.newPassword = 'Password must be at least 8 characters.';

	if (!values.newPasswordConfirm)
		errors.newPasswordConfirm = 'Password confirmation is required.';
	else if (values.newPasswordConfirm !== values.newPassword)
		errors.newPasswordConfirm = 'Passwords must match.';
}

export const ChangePassword = () => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _usersService = useInjection<UsersService>(Service.Users);

	const initialFormValues: ChangePasswordFormValues = {
		currentPassword: '',
		newPassword: '',
		newPasswordConfirm: '',
	};

	const onSubmit = async (values: ChangePasswordFormValues, { setSubmitting }: FormikHelpers<ChangePasswordFormValues>) => {
		const request: C.IChangePasswordRequest = {
			currentPassword: values.currentPassword,
			newPassword: values.newPassword,
		};

		try {
			await _usersService.changePassword(_authenticationService.currentAuth.user.userId, request);
			_toasterService.showSuccess('Password successfully changed.');
			_historyService.history.push('/app');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to change password.');
			setSubmitting(false);
		}
	};

	return <FixedWidthPage
		className="form-page"
		headingText="Change Password"
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ChangePasswordFormValues>) => <Form className="formik-form">
				<Field
					name="currentPassword"
					label="Current Password"
					type="password"
					component={FormikTextField}
					fullWidth
					margin="normal"
					required
				/>

				<Field
					name="newPassword"
					label="New Password"
					type="password"
					component={FormikTextField}
					fullWidth
					margin="normal"
					required
				/>

				<Field
					name="newPasswordConfirm"
					label="Confirm New Password"
					type="password"
					component={FormikTextField}
					fullWidth
					margin="normal"
					required
				/>

				<Button
					type="submit" variant="contained" color="primary"
					startIcon={<SaveIcon />}
					text="Change Password"
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
};
