import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import Slider, { createSliderWithTooltip, Marks as RcSliderMarks } from 'rc-slider';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
	AuthenticationService,
	Client as C,
	Service,
	useInjection,
} from 'src/services';

import { Button } from 'src/components';
import { longDateFormat, longTimeFormat } from 'src/util/dateTimeFormats';
import { getEventTime } from 'src/util/eventHelpers';

import 'rc-slider/assets/index.css';
import './historySlider.scss';

const SliderWithTooltip = createSliderWithTooltip(Slider);

export interface HistorySliderProps {
	min: moment.Moment;
	max: moment.Moment;
	value: moment.Moment;
	events?: C.IAssetEventDto[] | null;
	noHistory: boolean;
	loading: boolean;
	error?: string;
	onValueUpdated: (value: moment.Moment) => void;
	disabled?: boolean;
	loadHistory: () => Promise<void>;
}

export const HistorySlider = (props: HistorySliderProps) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);

	const formatValue = (value: number) => {
		return moment(value).tz(_authService.currentAuth.user.timeZone).format(`${longDateFormat} ${longTimeFormat}`);
	};

	const sliderMarks = useMemo(() => {
		const result: RcSliderMarks = {};
		if (!props.events)
			return result;

		for (const event of props.events) {
			let mark = null;
			const eventTime = getEventTime(event);

			// If we are a camper and have errors then say that it is an emergency
			if (event.properties && event.properties.camperVan && event.properties.camperVan.outputStates) {
				if (event.properties.camperVan.outputStates.some(s => s.hasFault)) {
					event.type = C.AssetEventType.Emergency;
				}
			}
			switch (event.type) {
				case C.AssetEventType.Emergency:
					if (event.properties?.emergency?.emergencyType === C.EmergencyType.PriorityAlert)
						mark = { style: {}, label: <div className="warning" title="Priority Alert"></div> };
					else
						mark = { style: {}, label: <div className="emergency" title="Emergency"></div> };
					break;
				case C.AssetEventType.KeyOn:
					mark = { style: {}, label: <div className="key-on" title="Key On"></div> };
					break;
				case C.AssetEventType.KeyOff:
					mark = { style: {}, label: <div className="key-off" title="Key Off"></div> };
					break;
				case C.AssetEventType.StartMoving:
					mark = { style: {}, label: <div className="start-moving" title="Start Moving"></div> };
					break;
				case C.AssetEventType.StopMoving:
					mark = { style: {}, label: <div className="stop-moving" title="Stop Moving"></div> };
					break;
			}

			result[eventTime.valueOf()] = mark || '';
		}

		return result;
	}, [ props.events ]);

	return <div className={`history-slider-container ${props.noHistory || props.loading ? 'show-message' : ''}`}>
		{!props.loading && <>
			{props.noHistory && !props.error && <div className="status-bar warning">No history available for the selected asset and date/time range.</div>}

			{props.error && <div className="status-bar error">
				{props.error}

				<Button
					onClick={props.loadHistory}
					text="Retry"
					variant="contained"
					size="small"
				/>
			</div>}
		</>}

		{!props.noHistory && <div className="history-slider">
			{props.loading && <div className="loading-message">
				<CircularProgress size={20} />
				<div className="text">Loading history...</div>
			</div>}

			{!props.loading && <SliderWithTooltip
				min={props.min.valueOf()}
				max={props.max.valueOf()}
				value={props.value.valueOf()}
				marks={sliderMarks}
				disabled={props.noHistory || props.disabled}
				onChange={value => props.onValueUpdated(moment(value))}
				tipFormatter={formatValue}
				// Hide the fill along the track before the slider
				trackStyle={[{ display: 'none' }]}
				handleStyle={[{
					backgroundColor: '#fff',
					borderRadius: '50%',
					border: '1px solid #aaa',
					boxShadow: '0 1px 1px 1px rgba(200, 200, 200, 0.8)',
					marginTop: '-10px',
					height: '24px',
					width: '24px',
				}]}
			/>}
		</div>}
	</div>;
};
