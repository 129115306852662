import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TimerIcon from '@material-ui/icons/Timer';

import { FixedWidthPage, ThingLoader } from 'src/components';
import { defaultBranding, featureToggleAssetTypes } from 'src/config';

const accountMenuIconImage = require('./resources/AccountMenuIcon.png');
const accessAssetsImageDealerImage = require('./resources/AssetsMenuDealer.png');
const accessAssetsImageClientImage = require('./resources/AssetsMenuClient.png');
const accessAddressBooksImageDealerImage = require('./resources/AddressBooksMenuDealer.png');
const accessAddressBooksClientImage = require('./resources/AddressBooksMenuClient.png');
const accessDevicesImage = require('./resources/DevicesMenuDealer.png');
const addOrEditAssetGroupsImage = require('./resources/ManageAssetGroups.png');
const allAssetStatusesImage = require('./resources/AllAssetStatuses.png');
const assetTypesManagePageImage = require('./resources/AssetTypesManagePage.png');
const billingReportSuperUserImage = require('./resources/BillingReportSuperUser.png');
const billingReportDealerImage = require('./resources/BillingReportDealer.png');
const callRecordingsMenuClientImage = require('./resources/CallRecordingsMenuClient.png');
const callRecordingsMenuDealerImage = require('./resources/CallRecordingsMenuDealer.png');
const editAssetImage = require('./resources/EditAsset.png');
const editDeviceImage = require('./resources/EditDevice.png');
const alertAboveNavBarImage = require('./resources/EmergencyAboveNavBar.png');
const alertsMenuClientImage = require('./resources/EmergenciesMenuClient.png');
const alertsListImage = require('./resources/EmergenciesList.png');
const alertsViewHistoryButtonImage = require('./resources/EmergenciesViewHistoryButton.png');
const gatewaysMenuImage = require('./resources/GatewaysMenuDealer.png');
const gatewaysStatusMenuImage = require('./resources/GatewaysStatusMenu.png');
const geofenceAddImage = require('./resources/GeofenceAdd.png');
const geofenceAddButtonImage = require('./resources/GeofenceAddButton.png');
const geofenceMenuClientImage = require('./resources/GeofenceMenuClient.png');
const geofenceListClientImage = require('./resources/GeofenceList.png');
const geofenceTypesListClientImage = require('./resources/GeofenceTypesList.png');
const geofenceTypesButtonClientImage = require('./resources/GeofenceTypesButton.png');
const geofenceEditImage = require('./resources/GeofenceEdit.png');
const historyMapMenuImage = require('./resources/HistoryMapMenuIcon.png');
const historyMapViewTypesImage = require('./resources/HistoryMapViewTypes.png');
const historyMapConfigurationImage = require('./resources/HistoryMapConfiguration.png');
const historyMapDragBarImage = require('./resources/HistoryMapDragBar.png');
const liveMapMenuImage = require('./resources/LiveMapMenu.png');
const liveMapViewTypesImage = require('./resources/LiveMapViewTypes.png');
const mapInfoBoxImageSafetyTimerImage = require('./resources/InformationBoxSafetyTimer.png');
const mapInfoBoxImage = require('./resources/InformationBox.png');
const mapInfoBoxImageSafetyTimerRunningImage = require('./resources/InformationBoxSafetyTimerRunning.png');
const mapSettingsButtonImage = require('./resources/MapSettingsButton.png');
const mapSettingsDialogImage = require('./resources/MapSettingsDialog.png');
const reportsMenuImage = require('./resources/ReportsMenu.png');
const resolveAlertImage = require('./resources/ResolveEmergency.png');
const saveAndDownloadReportImage = require('./resources/SaveAndDownloadReport.png');
const scheduledReportsButtonImage = require('./resources/ScheduledReportsButton.png');
const scheduledReportsCreateButtonImage = require('./resources/ScheduledReportsCreateButton.png');
const scheduledReportsEditAndDeleteButtonsImage = require('./resources/ScheduledReportsEditAndDeleteButtons.png');
const sideBarAssetWithSafetyTimerImage = require('./resources/SideBarAssetWithSafetyTimer.png');
const usersMenuImageDealerImage = require('./resources/UsersMenuDealer.png');
const usersMenuImageClientImage = require('./resources/UsersMenuClient.png');
const userManageMenuNoSignInImage = require('./resources/UserManageMenuNoSignIn.png');
const userManageMenuCanSignInImage = require('./resources/UserManageMenuCanSignIn.png');
const userGroupsSideBarUsersImage = require('./resources/UserGroupsSideBarUsers.png');
const userGroupsSideBarAssetsImage = require('./resources/UserGroupsSideBarAssets.png');
const userGroupsSideBarPermissionsImage = require('./resources/UserGroupsSideBarPermissions.png');

import {
	Client as C,
	Service,
	AuthenticationService,
	BrandingService,
	VitalBranding,
	LogicWirelessBranding,
	TorutekBranding,
	useInjection,
} from 'src/services';

import './help.scss';

interface Question {
	question: string;
	answer: JSX.Element;
}

interface AllQuestions {
	Account: Question[];
	Assets: Question[];
	AddressBooks: Question[];
	CallRecordings: Question[];
	Devices: Question[];
	Emergencies: Question[];
	Geofences: Question[];
	Gateways: Question[];
	HistoryMap: Question[];
	LiveMap: Question[];
	Maps: Question[];
	Reports: Question[];
	SafetyTimer: Question[];
	ScheduledReports: Question[];
	UserManagement: Question[];
	Other: Question[];
}

export const Help = () => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _brandingService = useInjection<BrandingService>(Service.Branding);

	const load = async () => {
		const allQuestions: AllQuestions = {
			Account: [],
			Assets: [],
			AddressBooks: [],
			CallRecordings: [],
			Devices: [],
			Emergencies: [],
			Geofences: [],
			Gateways: [],
			HistoryMap: [],
			LiveMap:[],
			Maps: [],
			Reports: [],
			SafetyTimer: [],
			ScheduledReports: [],
			UserManagement: [],
			Other: [],
		};

		const identityType = _authenticationService.currentAuth.user.identity.type;
		const generalPermissions = _authenticationService.currentAuth.permissions.general;
		const showSafetyTimerQuestions = identityType === C.IdentityType.Client &&  _authenticationService.currentAuth.permissions.general.canUseSafetyTimer;

		// Your Account questions
		allQuestions.Account.push({
			question: 'How do I manage my account details and password?',
			answer: <>
				<p>
					To access your account details, click on your account name in the menu bar located at the top of your screen.
					A menu will appear with the "My Account" option, click on this to access your account details.
					From here you can change your password and other account details.
				</p>
				<img src={accountMenuIconImage} alt="Account Menu Image"/>
			</>,
		});

		allQuestions.Account.push({
			question: 'How do I sign out?',
			answer: <>
				<p>
					To sign out of your account, click the dropdown near your account name in the menu bar located at the top of your screen.
					After clicking the dropdown, a menu will appear with the "Sign Out" option, click on this to sign out.
				</p>
				<img src={accountMenuIconImage} alt="Sign Out Image"/>
			</>,
		});

		const usersManageMenuImage = identityType === C.IdentityType.Client ? <img src={usersMenuImageClientImage} alt="Client Menu Image"/> : <img src={usersMenuImageDealerImage} alt="Dealer Menu Image"/>;

		if (generalPermissions.signInAs)
			allQuestions.Account.push({
				question: 'How do I sign in as a different user?',
				answer: <>
					<p>
						To sign in as another user, click on the "Manage" menu item and select the "Users" option from the dropdown.
						When on the Users page you can click the dropdown for a specific user and select the "Sign in as this user" option.
						This feature will only allow you to sign in as users which your account has permission to manage.
					</p>
					{usersManageMenuImage}
				</>,
			});

		const accessAssetsImage = identityType === C.IdentityType.Client ? <img src={accessAssetsImageClientImage} alt="Client Assets Menu Image"/> : <img src={accessAssetsImageDealerImage} alt="Dealer Assets Menu Image"/>;

		// Asset questions.
		if (generalPermissions.manageAssets) {
			allQuestions.Assets.push({
				question: 'What is an asset?',
				answer: <>
					<p>
						An asset is a collection of one or more assets grouped to act as one entity, see the question "What is a Device?" in the devices section of the help page.
						All data received from the devices will be handled as one asset. In general, most assets will only ever have one device associated with them.
					</p>

					<p>
						An example use case of associating two or more devices with an asset could be a commercial logging truck.
						This truck may require a GPS tracking device for location but also needs another device for call recording.
						In this example, the truck asset could have an aTrack device for location, and a Tait radio device associated with it.
					</p>

					<p>All data received from devices associated with an asset will be processed as one entity for use on the live, history map, alerts and reporting.</p>

					<span><strong>Note:</strong> Be careful when associating two devices with an asset, if they both send locations and are not closely tied together physically then the asset may look as if it is jumping between locations very quickly.</span>
				</>,
			});

			allQuestions.Assets.push({
				question: 'How do I manage/view my assets?',
				answer: <>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Assets" option.</li>
					</ol>

					{accessAssetsImage}
				</>,
			});

			if (identityType === C.IdentityType.Dealer || identityType === C.IdentityType.SuperUser) {
				allQuestions.Assets.push({
					question: 'How do I add a new asset?',
					answer: <>
						<span>Add an asset without a device:</span>

						<ol>
							<li>Expand the "Manage" menu from the top navigation bar.</li>
							<li>Click the "Assets" option.</li>
							<li>Click the "Add" button on the top right of the page.</li>
							<li>Click the "Add Asset Only" button on the top right of the page.</li>
							<li>Fill in the assets details.</li>
							<li>Click "Add Asset".</li>
						</ol>

						<span>Add an asset with a device:</span>

						<ol>
							<li>Expand the "Manage" menu from the top navigation bar.</li>
							<li>Click the "Assets" option.</li>
							<li>Click the "Add" button on the top right of the page.</li>
							<li>Fill in the assets details.</li>
							<li>Select the device type and fill in it's details.</li>
							<li>Click "Add Asset With Device".</li>
						</ol>
					</>,
				});

				{generalPermissions.importFromFile && allQuestions.Assets.push({
					question: 'How do I import assets from a file?',
					answer: <>
						<ol>
							<li>Expand the "Manage" menu from the top navigation bar.</li>
							<li>Click the "Assets" option.</li>
							<li>Click the "Add" button on the top right of the page.</li>
							<li>Click the "Import from file" option.</li>
							<li>Select your device type to import, and click "Download Template File"</li>
							<li>Fill in the asset details in the file, following the instructions on this page.</li>
							<li>Upload the file.</li>
							<li>Click the "Submit File" button.</li>
						</ol>
					</>});
				}

				allQuestions.Assets.push({
					question: 'How do I assign a device to an asset?',
					answer: <>
						<p>For an asset to work correctly the asset will need one or more devices associated with it. There are three ways of associating a device with an asset as seen below.</p>

						<strong>While creating an asset:</strong>
						<p>
							When you create an asset, you can specify the details of the device you want to create and assign to the asset.
							See the "How do I add a new asset?" question above for details on how to add an asset.
						</p>

						<strong>From the assets list page:</strong><br/>

						<span><strong>Note:</strong> This is only available on assets which do not already have a device assigned to them.</span>
						<ol>
							<li>Navigate to the assets list page. See the "How do I manage/view my assets" question above.</li>
							<li>Select the small arrow pointing downwards on the row of the asset you want to assign a device to.</li>
							<li>Providing the asset has no device associated with it, click the menu item "Assign device" and select the device to assign.</li>
						</ol>

						<strong>From the devices list page:</strong><br/>

						<span><strong>Note:</strong> This is only available for devices which are not already assigned to an asset.</span>
						<ol>
							<li>Navigate to the devices list page. See the "How do I manage/view my devices" in the devices section of the help page.</li>
							<li>Select the small arrow pointing downwards on the row of the device you want to assign to an asset.</li>
							<li>Providing the device is not assigned to an asset, click the menu item "Assign to asset" and select the asset to assign to.</li>
						</ol>
					</>,
				});
			}

			allQuestions.Assets.push({
				question: 'How do I rename/edit an asset?',
				answer: <>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Assets" option.</li>
						<li>Find the asset you want to edit.</li>
						<li>Expand the options menu for the asset.</li>
						<li>Click the "Edit" menu option.</li>
					</ol>

					<img src={editAssetImage} alt="Edit Asset Image"/>
				</>,
			});

			allQuestions.Assets.push({
				question: 'How do I configure asset speed alerts?',
				answer: <>
					<p>Speed alerts will only be triggered if the asset sends location updates with travel speed information.</p>

					<p>Speed alerts are sent when an asset exceeds a speed limit that is set on the specific asset.</p>

					<ol>
						<li>Navigate to the edit page of the asset you wish to receive speed alerts for.</li>
						<li>Enable speed alerts on the asset.</li>
						<li>Specify the maximum speed the asset can travel, before an alert is sent.</li>
						<li>Navigate to your user settings.</li>
						<li>Click the 'Manage Alert & Emergency Settings' button and enable asset speed alerts.</li>
					</ol>
				</>,
			});

			if (featureToggleAssetTypes === 'true') {
				allQuestions.Assets.push({
					question: 'What are asset types?',
					answer: <>
						<p>
							An asset type is a name and map marker icon which can be associated with one or more assets. For example, a bus company could create a "Green Line" asset type
							which could have a green map marker. The bus company would then select this asset type for all relevant buses.
						</p>
					</>,
				});

				allQuestions.Assets.push({
					question: 'How do I add and edit asset types?',
					answer: <>
						<ol>
							<li>Expand the "Manage" menu from the top navigation bar.</li>
							<li>Click the the "Assets" option.</li>
							<li>Click the "Asset Types" button in the top right.</li>
						</ol>

						<p>
							To add an asset type, click the "Add A New Asset Type".
						</p>

						<p>
							To edit an asset type, expand the options menu for the desired asset type, then click the "Edit" menu option.
						</p>

						<img src={assetTypesManagePageImage} alt="Manage Asset Types"/>
					</>,
				});

				allQuestions.Assets.push({
					question: 'How do I configure an asset with an asset type?',
					answer: <>
						<ol>
							<li>Create your asset type.</li>
							<li>Expand the "Manage" menu from the top navigation bar.</li>
							<li>Click the the "Assets" option.</li>
							<li>Expand the options menu for the desired asset.</li>
							<li>Click the "Edit" menu option.</li>
							<li>Edit the "Asset Type" field and select the desired asset type.</li>
							<li>Click "Save Changes".</li>
						</ol>
					</>,
				});
			}
		}

		// Device questions.
		if (generalPermissions.manageDevices) {
			allQuestions.Devices.push({
				question: 'What is a device?',
				answer: <>
					<p>
						A device is a physical piece of hardware that exists in the real world.
						The different types of devices on the platform all have varying capabilities and use-cases..
					</p>

					<p>
						One or more devices can be associated with an asset. For more detail on what an asset is, please see the "What is an asset?" question in the assets section of the help page.
					</p>
				</>,
			});

			allQuestions.Devices.push({
				question: 'How do I manage/view my devices?',
				answer: <>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Devices" option.</li>
					</ol>

					<img src={accessDevicesImage} alt="Devices Menu Image"/>
				</>,
			});

			if (identityType === C.IdentityType.Dealer || identityType === C.IdentityType.SuperUser) {
				allQuestions.Devices.push({
					question: 'How do I add a new devices?',
					answer: <>
						<ol>
							<li>Expand the "Manage" menu from the top navigation bar.</li>
							<li>Click the "Devices" option.</li>
							<li>Click the "Add" button on the top right of the page.</li>
							<li>Fill in the device's details.</li>
							<li>Click "Add Device".</li>
						</ol>
					</>,
				});

				allQuestions.Devices.push({
					question: 'How do I assign a device to an asset?',
					answer: <>
						<p> See the "How do I assign an device to an asset?" question in the asset section of the help page.</p>
					</>,
				});
			}

			allQuestions.Devices.push({
				question: 'How do I rename/edit a device?',
				answer: <>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Devices" option.</li>
						<li>Find the device you want to edit.</li>
						<li>Expand the options menu for the device.</li>
						<li>Click the "Edit" menu option.</li>
					</ol>

					<img src={editDeviceImage} alt="Edit device Image"/>
				</>,
			});

			{generalPermissions.canViewDeviceConfigurations && allQuestions.Devices.push({
					question: 'How do I view/download device configurations?',
					answer: <>
						<ol>
							<li>Expand the "Manage" menu from the top navigation bar.</li>
							<li>Click the "Devices" option.</li>
							<li>Click the "Device configuration" button in the top right of the devices page.</li>
							<li>To download a configuration, click the small arrow on the row of the configuration you wish to download and click "Download".</li>
						</ol>
					</>
				});
			}

			{generalPermissions.canManageDeviceConfigurations && identityType === C.IdentityType.SuperUser && allQuestions.Devices.push({
				question: 'How do I add device configurations?',
				answer: <>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Devices" option.</li>
						<li>Click the "Device configuration" button in the top right of the devices page.</li>
						<li>Click the "Add" button in the top right.</li>
						<li>Fill in the device configuration details.</li>
						<li>Select an appropriate configuration file.</li>
						<li>Click the "Save Configuration" button.</li>
					</ol>
				</>});
			}

			{generalPermissions.applyDeviceConfigurations && allQuestions.Devices.push({
				question: 'How do I apply a device configuration?',
				answer: <>
					<span><strong>Note:</strong> This is only available for certain device types, the configuration and the device <strong>must</strong> have the same device model type.</span>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Devices" option.</li>
						<li>Find the device you want to apply a configuration to.</li>
						<li>Click the small arrow on the row of the device you wish to apply a configuration to.</li>
						<li>Select the configuration.</li>
						<li>Select which device to apply the configuration to.</li>
						<li>Click the "Queue Update" button.</li>
					</ol>
				</>});
			}
		}

		const accessAddressBooksImage = identityType === C.IdentityType.Client ? <img src={accessAddressBooksClientImage} alt="Client Address Books Menu Image"/> : <img src={accessAddressBooksImageDealerImage} alt="Dealer Address Books Menu Image"/>;

		// Address books.
		if (generalPermissions.manageAddressBooks) {
			allQuestions.AddressBooks.push({
				question: 'What is a address book?',
				answer: <>
					<p>
						An address book is a collection of user identifiers associated with a user name.
						When an address book is created, it is associated with a site.
						If an event is received from a device on the site containing a user identifier, the matching user name will be displayed in both the live map, activity report and driver report.
					</p>
				</>,
			});

			allQuestions.AddressBooks.push({
				question: 'How do manage/view address books?',
				answer: <>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Address Books" option.</li>
					</ol>

					{accessAddressBooksImage}
				</>
			});

			{generalPermissions.addAddressBooks && allQuestions.AddressBooks.push({
				question: 'How do I add a new address book?',
				answer: <>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Address Books" option.</li>
						<li>Click the "Add" button on the top right of the page.</li>
						<li>Fill in the address book details.</li>
						<li>Click "Add Address Book.</li>
					</ol>

					<span><strong>Note:</strong> The user identifier is the value that a device has been configured to send, if we receive that user identifier we will display the associated user name with the asset on the live map.</span>
				</>});
			}

			allQuestions.AddressBooks.push({
				question: 'How do I edit an address book?',
				answer: <>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Address Books" option.</li>
						<li>Find the address book you want to edit.</li>
						<li>Click the small arrow on the row of the address book you wish to edit.</li>
						<li>Make your changes and select "Save All Changes" on the top right of the page.</li>
					</ol>

					<span><strong>Note:</strong> The user identifier is the value that a device has been configured to send, if we receive that user identifier we will display the associated user name with the asset on the live map.</span>
				</>
			});
		}

		const canSeeUserGroups = identityType == C.IdentityType.SuperUser || identityType == C.IdentityType.Dealer || generalPermissions.manageUsers;

		if (generalPermissions.manageAssetGroups) {
			allQuestions.Assets.push({
				question: 'What are asset groups?',
				answer: <>
					<p>
						Asset groups are collections of assets. {canSeeUserGroups && 'By using asset groups, you can limit the assets which are accessible to certain user groups.'}
					</p>
				</>,
			});

			allQuestions.Assets.push({
				question: 'How do I add and edit asset groups?',
				answer: <>
					<p>
						To add an asset group, from the Manage Asset Groups page, click the "Add" button on the top right of the screen under the menu bar.
					</p>
					<p>
						To edit or manage the assets in an asset group, from the Manage Asset Groups page, click the small arrow beside the asset group you want to change and select the appropriate menu option.
					</p>
					<img src={addOrEditAssetGroupsImage} alt="Add or Edit Asset Groups"/>
				</>,
			});

			allQuestions.Assets.push({
				question: 'How do I assign users to asset groups?',
				answer: <>
					<p>
						Every client has an “All Users” user group, and an “All Assets” asset group, which are managed automatically by the system. By default, the “All Users” group
						grants access to the “All Assets” group. If you want to assign different assets to user groups, the “All Assets” group should first be removed from the
						“All Users” group (edit the “All Users” group, go to the “Assets” section, then remove the “All Assets” group). After removing this group, you can then:
					</p>
					<ol>
						<li>Create an asset group containing the required assets.</li>
						<li>Create a user group containing the required users.</li>
						<li>Edit the user group, and add the asset group in the “Assets” section.</li>
					</ol>
				</>,
			});
		}

		// Gateway questions
		if (generalPermissions.manageGateways) {
			allQuestions.Gateways.push({
				question: 'Where can I view and edit my gateways?',
				answer: <>
					<p>
						To view all of your gateways, click on the "Manage" menu item and select the "Gateways" option from the dropdown.
					</p>

					<img src={gatewaysMenuImage} alt="Dealer Gateways"/>
				</>,
			});

			allQuestions.Gateways.push({
				question: 'How can I access gateway diagnostic logs?',
				answer: <>
					<p>
						To access gateway logs, navigate to your list of gateways.
						Expand the options menu for the desired gateway, then click the "Status" menu item:
					</p>

					<img src={gatewaysStatusMenuImage} alt="Gateway Status Logs Menu Item"/>

					<p>
						You will be taken to the gateway status page, and logs will be displayed if the gateway is online.
					</p>
				</>,
			});

			{(_brandingService.getBranding() === LogicWirelessBranding || _brandingService.getBranding() === TorutekBranding) &&
				(identityType === C.IdentityType.Dealer || identityType === C.IdentityType.SuperUser) &&
				allQuestions.Gateways.push({
					question: 'What are the firewall requirements for gateways?',
					answer: <>
						<p>Firewalls should be configured to allow the gateways to establish outbound connections to the following ports:</p>

						<table className="help__firewall-requirement-table">
							<thead>
								<tr>
									<th>Port</th>
									<th>Protocol</th>
									<th>Purpose</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td>443</td>
									<td>TCP</td>
									<td>
										<span>Allow the gateway to communicate with the Logic Connect API.</span><br/>
										<span>Allow the gateway to establish a VPN connection for remote management.</span>
									</td>
								</tr>
								<tr>
									<td>123</td>
									<td>UDP</td>
									<td>NTP time synchronization.</td>
								</tr>
								<tr>
									<td>53</td>
									<td>UDP</td>
									<td>DNS name resolution.</td>
								</tr>
							</tbody>
						</table>

						<p>Connections are made to the following:</p>

						<span>Domains (443):</span>
						<ul>
							<li>*.logic-connect.com</li>
							<li>*.balena-cloud.com</li>
							<li>*.docker.com</li>
							<li>*.docker.io</li>
						</ul>

						<span>NTP time servers (123):</span>
						<ul>
							<li>0.resinio.pool.ntp.org</li>
							<li>1.resinio.pool.ntp.org</li>
							<li>2.resinio.pool.ntp.org</li>
							<li>3.resinio.pool.ntp.org</li>
						</ul>

						<span>DNS servers (53):</span>
						<ul>
							<li>8.8.8.8</li>
							<li>8.8.4.4</li>
							<li>1.1.1.1</li>
							<li>1.0.0.1</li>
						</ul>

						<p> We are able to configure custom NTP/DNS servers if required.
							Note that if the network connection of the gateway is configured via DHCP, the DNS servers provided by DHCP will be used in addition to those listed above. This behaviour can be adjusted if necessary.
						</p>
					</>,
				});
			}
		}

		// Alert & emergency questions.
		if (identityType == C.IdentityType.Client) {
			allQuestions.Emergencies.push({
				question: 'How do I view unresolved alerts & emergencies?',
				answer: <>
					<p>
						From the alert & emergency bar, click the "View" button to view all unresolved alerts & emergencies.
					</p>

					<img src={alertAboveNavBarImage} alt="Alert Above Navigation Bar Image"/>

					<p>
						Alternatively, navigate to the alerts & emergencies page by selecting the "Alerts & Emergencies" menu item of the "Manage" menu.
					</p>

					<img src={alertsMenuClientImage} alt="Alerts Menu Client Image"/>
				</>,
			});

			allQuestions.Emergencies.push({
				question: 'How do I resolve an alerts & emergencies?',
				answer: <>
					<span>
						To resolve an alert or emergency, select the "Details/Resolve" option from the dropdown menu of the alert or emergency you want to resolve.
					</span>

					<img src={alertsListImage} alt="Alerts List Image"/>

					<p>
						You may then optionally add a note. Finally, click "Resolve" to resolve the alert or emergency.
					</p>

					<img src={resolveAlertImage} alt="Resolve Alert Image"/>
				</>,
			});

			allQuestions.Emergencies.push({
				question: 'How do I view alert & emergency history?',
				answer: <>
					<span>
						From the alerts & emergencies page, click the "View History" button on the top right of the page.
					</span>

					<img src={alertsViewHistoryButtonImage} alt="Alert View History Button Image"/>
				</>,
			});

			allQuestions.Emergencies.push({
				question: 'How do I turn on alerts & emergencies for my account?',
				answer: <>
					<p>
						To manage your alert or emergency settings, click on your account name in the menu bar and select the "My Account" option from the dropdown.
						Select the "Manage Alert & Emergency Settings" option from your account page.
						From this page, you are able to choose the type of alerts and emergencies you would like to receive.
					</p>

					<img src={accountMenuIconImage} alt="Accounts Menu Icon Image"/>

					<p>
						The alert & emergency bar (shown below) cannot be disabled.
					</p>

					<img src={alertAboveNavBarImage} alt="Alert Above Navigation Bar Image"/>
				</>,
			});
		}

		// Safety Timer Questions
		if (showSafetyTimerQuestions) {
			allQuestions.SafetyTimer.push({
				question: 'What is a safety timer?',
				answer: <>
					<p>
						The safety timer features allow a user to choose a time period that an asset should be monitored for.
						Within the safety timer period, the user has the option to extend or end the safety timer.
						If the safety timer expires without being extended or ended, an emergency will be generated for that asset.
					</p>

					<p>
						An example use case for safety timers is lone workers. For example: a forestry worker in an isolated location.
						The forestry worker would make contact with their their safety operator (e.g. via radio), and ask for a safety timer to be created for them.
						If the forestry worker does not make contact again within the duration of the safety timer, then the safety timer expires and an emergency is generated for them.
						Otherwise, the safety operator ends the safety timer.
					</p>
				</>,
			});

			allQuestions.SafetyTimer.push({
				question: 'How do I create/manage safety timers?',
				answer: <>
					<p>
						Safety timers are managed via the "Live Map" page.
					</p>

					<p>
						Assets that have an active safety timer will be at the top of your assets list. These assets are identified by a timer icon (<TimerIcon fontSize="small" />) next to their status indicator:
					</p>

					<img src={sideBarAssetWithSafetyTimerImage} alt="Side Bar Asset With Safety Timer"/>

					<p>
						To create a new safety timer, or manage an existing safety timer, click the relevant asset in the asset list.
						Depending on the asset's current safety timer status, the information box will display either a button to start a new
						safety timer ("Safety Timer") or buttons to manage the status of an existing safety timer.
					</p>

					<div className="multiple-pictures-under-text">
						<img src={mapInfoBoxImageSafetyTimerImage} alt="Information Box"/>
						<img src={mapInfoBoxImageSafetyTimerRunningImage} alt="Information Box With Safety Timer Running"/>
					</div>
				</>,
			});
		}

		// Geofence Questions
		if (identityType == C.IdentityType.Client) {
			allQuestions.Geofences.push({
				question: 'How do I create a geofence?',
				answer: <>
					<p>
						New geofences are created on the live map page.
						To enter geofence creation mode, click on the menu in the top right of the map and select the "Add Geofence" option.
					</p>

					<img src={geofenceAddButtonImage} alt="Add Geofence Menu Image"/>

					<ol>
						<li>Click the map to add points to the perimeter of your geofence.</li>
						<li>When you have completed drawing your geofence perimeter, click on any existing point to finish drawing.</li>
						<li>In the dialog box in the top left of the map, name the geofence and select the geofence type.</li>
						<li>Click "OK" to complete adding the geofence.</li>
					</ol>

					<img src={geofenceAddImage} alt="Completed Geofence Image"/>
				</>,
			});

			allQuestions.Geofences.push({
				question: 'What is a "geofence type"?',
				answer: <>
					<p>A geofence type is a category of geofences that represent a similar use.</p>

					<p>
						For example, a security company may create one geofence type for shopping malls, and another geofence
						type for music festivals.
					</p>

					<p>These geofence types can then be set to look and behave differently, depending on requirements.</p>
				</>,
			});

			allQuestions.Geofences.push({
				question: 'How do I edit a geofence?',
				answer: <>
					<p>Geofences can be edited on the live map page.</p>

					<ol>
						<li>Click on the outline of a goefence to begin editing that geofence.</li>
						<li>In the dialog box in the top left of the map, you can change the name of the geofence, or delete the geofence.</li>
						<li>Click and drag an existing point of the geofence to resize the geofence.</li>
						<li>Click "OK" to confirm your changes.</li>
					</ol>

					<p>Note that changing the type of a geofence, and adding/removing geofence perimeter points is currently not supported.</p>

					<img src={geofenceEditImage} alt="Geofence Types List Image"/>
				</>,
			});

			allQuestions.Geofences.push({
				question: 'How can I view geofences?',
				answer: <>
					<p>
						To view a list of all of your geofences, select the "Geofences" option from the "Manage" menu.
					</p>

					<img src={geofenceMenuClientImage} alt="Geofence Menu Image"/>

					<p>
						You can view the geofence on the live map by selecting the "View" option from the geofence's dropdown menu.
					</p>

					<img src={geofenceListClientImage} alt="Geofence List Image"/>
				</>,
			});

			allQuestions.Geofences.push({
				question: 'How do I view, add or edit geofence types?',
				answer: <>
					<p>
						Click the "Geofence Types" button on the "Geofences" page to go to your list of geofence types.
					</p>

					<img src={geofenceTypesButtonClientImage} alt="Geofence Types Button Image"/>

					<p>
						You can add a geofence type by clicking the "Add" button on the top right of the page.
						Select the "Edit" option from the geofence type's dropdown menu to edit a geofence type.
					</p>

					<img src={geofenceTypesListClientImage} alt="Geofence Types List Image"/>
				</>,
			});

			allQuestions.Geofences.push({
				question: 'How do I turn on/off alerts and emergencies for a geofence? (e.g. enter/exit alerts and emergencies)',
				answer: <>
					<p>Alerts and emergencies for a geofence are managed by editing the geofence type it belongs to.</p>
					<p>To receive geofence alerts and emergencies this type must also be enabled in your account alert and emergency settings.</p>
				</>,
			});
		}

		if (generalPermissions.viewAssetLocations) {
			// Maps questions
			allQuestions.Maps.push({
				question: 'How do I change my map settings?',
				answer: <>
					<ol>
						<li>Navigate to the live map page.</li>
						<li>Expand the map menu in the top right of the live map.</li>
						<li>Click the settings button (shown below).</li>
					</ol>

					<img src={mapSettingsButtonImage} alt="Live Map Menu Icon"/>
				</>,
			});

			allQuestions.Maps.push({
				question: 'What settings are available?',
				answer: <>
					<dl>
						<dt>Use Custom Home Location</dt>
						<dd>
							The default map view that is shown when the live map is opened.
							If enabled, a custom home location can be selected, otherwise the map will default to the showing all of your current assets.
							When enabled, click the "Set Home to Current Map View" button to set the update the relevant values to the current map view.
						</dd>

						<dt>Default Map Style</dt>
						<dd>
							The default map style that is shown when the live/history map is opened ("Map" or "Satellite").
						</dd>

						<dt>Asset Label Behaviour</dt>
						<dd>
							Controls whether or not labels will be "Shown on hover" or "Shown always" on the live map.
						</dd>

						<dt>Asset Active/Inactive Behaviour</dt>
						<dd>
							This will allow you to either "Play a sound", "Show a notification" or both when an asset becomes active/inactive on the live map.
						</dd>

						<dt>Asset Last Update Threshold</dt>
						<dd>
							Controls if map markers should be shown on the live map for assets which have been extended for an extended amount of time.
							If an asset has been inactive for longer than the selected time period, the map marker for that asset will not show on the live map (unless the asset is specifically selected).
						</dd>

						<dt>Enable Asset Clustering</dt>
						<dd>
							Enables whether or not asset markers on the map will be grouped together when overlapping each other.
						</dd>
					</dl>

					<img src={mapSettingsDialogImage} alt="Map Settings dialog"/>
				</>,
			});

			allQuestions.Maps.push({
				question: 'When you click on an asset, what does the information in the top left-hand box mean?',
				answer: <>
					<p>
						The information in the box is described below:
					</p>

					<dt>Asset name:</dt>
					<dd>This is the name of the selected asset. The asset selected in the example below has the name "Asset 1".</dd>

					<dt>User name:</dt>
					<dd>This is the name of the user currently using this asset. The name of the user in the example image below is "Driver 1".</dd>

					<dt>Date and time:</dt>
					<dd>This is the date and time of the last available event generated for the asset. This is shown in the example below as "Wednesday, 26 Aug 2020, 10:20:44 am".</dd>

					<dt>Time since last event:</dt>
					<dd>This shows the how long it has been since the last available event for the asset was generated. This is shown in the example below as "45 minutes ago".</dd>

					<dt>Location:</dt>
					<dd>This is the most recent location the system has for the asset, in the form of a human-readable nearest address (where possible) and latitude/longitude coordinates in decimal degrees. This is shown in the example below as "25 Index Place, Auckland" at the latitude of -37.019358, and longitude of 174.909648.</dd>

					<dt>Accuracy:</dt>
					<dd>This is the estimated accuracy of the most recent location the system has for the asset. This is shown in the example below as "(± 2.00 m)" and means that we are 67% confident that the true location of the asset is within 2.00 metres of the location given.</dd>

					<dt>Speed:</dt>
					<dd>This is the most recent speed estimate the system has for the asset . This is shown in the example below as "12 km/h". Note that speed is calculated from GPS data, and is subject to inaccuracies - particularly if the unit has a partially occluded view of the sky.</dd>

					<dt>Heading:</dt>
					<dd>This is the most recent direction estimate the system has for the asset, in decimal degrees. This is shown in the example below as 156°, which would be a heading of roughly south east. Note that heading is calculated from GPS data, and is subject to inaccuracies - particularly if the unit has a partially occluded view of the sky.</dd>

					<dt>Poll asset Location:</dt>
					<dd>Send a message to an asset to update its current location. This option is only available for selected asset device types.</dd>

					<dt>View History:</dt>
					<dd>View the history for the selected asset. See the "History Map" section of the help page for more details.</dd>

					{showSafetyTimerQuestions && <>
						<dt>Safety Timer:</dt>
						<dd>Create a safety timer for an asset. See the "Safety Timer" section of the help page for more details.</dd>
					</>}

					{!showSafetyTimerQuestions && <img src={mapInfoBoxImage} alt="History Map Info Box Image"/>}
					{showSafetyTimerQuestions && <img src={mapInfoBoxImageSafetyTimerImage} alt="History Map Info Box Image"/>}
				</>,
			});

			// Live map Questions
			allQuestions.LiveMap.push({
				question: 'How do I access the live map?',
				answer: <>
					<p>
						To access the live map, click on the menu item "MAP" or the globe beside it, this is located in the menu bar at the top of your screen.
					</p>
					<img src={liveMapMenuImage} alt="Live Map Menu Icon"/>
				</>,
			});

			allQuestions.LiveMap.push({
				question: 'How do I change between map and satellite view?',
				answer: <>
					<p>
						To change between the normal map view and the satellite map view, click the icon at the bottom left of the map page.
					</p>
					<img src={liveMapViewTypesImage} alt="Live Map View Types"/>
				</>,
			});

			allQuestions.LiveMap.push({
				question: "What's the difference between colour of the circles next to each asset on the left-hand column?",
				answer: <>
					<p>
						These coloured circles represent the current status of the asset:
					</p>

					<dt>Green:</dt>
					<dd>
						This status shows that the asset has been active/responsive in the past 20 minutes.
					</dd>

					<dt>Grey:</dt>
					<dd>
						This status shows that the asset has not been active/responsive in the past 20 minutes.
					</dd>

					<dt>Orange:</dt>
					<dd>
						This status shows that the Asset has an active priority alert.
						To remove the priority alert, click the 'Manage' tab in the top menu, then click 'Alerts & Emergencies'.
						Once you are on the 'Alerts & Emergencies' web page you can click the alert and resolve it for the Asset.
					</dd>

					<dt>Red:</dt>
					<dd>
						This status shows that the Asset currently is in an emergency.
						To remove the emergency, click the 'Manage' tab in the top menu, then click 'Alerts & Emergencies'.
						Once you are on the 'Alerts & Emergencies' web page you can click the emergency and resolve it for the Asset.
					</dd>

					<img src={allAssetStatusesImage} alt="All Asset Statuses"/>
				</>,
			});

			allQuestions.LiveMap.push({
				question: `What does the "Last Communicated" box mean when I hover over the coloured circle next to each asset on the left-hand column?`,
				answer: <>
					When you hover over the coloured circle next to an asset’s label with your mouse, the "Last Communicated" box shows the last time that the selected asset successfully communicated with the server.
				</>,
			});

			// History map questions
			allQuestions.HistoryMap.push({
				question: 'How do I access the history map?',
				answer: <>
					<p>
						To access the history map, click on the "History" button in the top navigation bar.
					</p>
					<img src={historyMapMenuImage} alt="History Map Menu Icon"/>
				</>,
			});

			allQuestions.HistoryMap.push({
				question: 'How do I change between map and satellite view?',
				answer: <>
					<p>
						To change between the normal map view and the satellite map view, click the icon at the bottom left of the map page (shown in #1).
						Once the icon has been clicked, another menu (shown in #2) will appear and you can select the map view type.
					</p>
					<img src={historyMapViewTypesImage} alt="History Map View Types"/>
				</>,
			});

			allQuestions.HistoryMap.push({
				question: 'How can I access historical view of where an asset has been over a given timeframe?',
				answer: <>
					<p>
						On the history map page you will see the history controls at the bottom centre of your screen.
						Using these controls, select an asset and a timeframe (start and end date and time) to display the history for that asset within the specified time period.
					</p>

					<p>
						The play button on the top right will play through each asset event so you can view the assets movements on the map.
						The two buttons on either side of the play button can be used change the speed at which the events are played. The right button increases the speed, and the left button decreases the speed.
					</p>
					<img src={historyMapConfigurationImage} alt="History Map Configuration"/>
				</>,
			});

			allQuestions.HistoryMap.push({
				question: 'What does the drag bar at the bottom of the history page do?',
				answer: <>
					<p>
						The drag bar located at the bottom of the history map page (as shown in the picture below) is used to traverse through the history for the selected asset.
						The bar is a timeline over the selected history period, with the big circle on the line showing your current place in the history, the circles with key icons represent either key on or off events and the smaller circles representing events generated by the asset within the period.
						You can click and hold on the big circle and drag it along the bar to traverse the history for the selected asset.
					</p>
					<img src={historyMapDragBarImage} alt="History Map Drag Bar"/>
				</>,
			});
		}

		const callRecordingsImage = identityType === C.IdentityType.Client ? <img src={callRecordingsMenuClientImage} alt="Client Call Recording Menu"/> : <img src={callRecordingsMenuDealerImage} alt="Dealer Call Recording Menu"/>;

		// Call recording.
		if (generalPermissions.accessCalls) {
			allQuestions.CallRecordings.push({
				question: `How do I access my call recordings?`,
				answer: <>
					<ol>
						<li>Expand the "Manage" menu from the top navigation bar.</li>
						<li>Click the "Call recordings" menu option.</li>
					</ol>

					{callRecordingsImage}

					<p>
						From here you can listen to or download call recordings.<br />
						To listen to the recording, click the play button on the row of the recording.<br />
						To download a recording, click the download button on the row of the recording.
					</p>
				</>,
			});

			if (generalPermissions.manageCallGroups) {
				allQuestions.CallRecordings.push({
					question: `How do I configure an individual asset to record from ChatterPTT?`,
					answer: <>
						<ol>
							<li>Create a mobile device asset.</li>
							<li>Select the appropriate ChatterPTT site.</li>
							<li>Enter the desired ChatterPTT username.</li>
						</ol>
					</>,
				});

				allQuestions.CallRecordings.push({
					question: `What is a call group?`,
					answer: <>
						<p>
							A call group is a way to map a group from an external network into {defaultBranding}.
						</p>

						<p>
							For example, if you have a call group in an external network, this call group will have an ID associated with it.
							If this value matches the "Network ID" of the call group in {defaultBranding}, then the calls will be recorded for the call group (if enabled).
						</p>
					</>,
				});

				allQuestions.CallRecordings.push({
					question: `How do I create or edit a call group?`,
					answer: <>
						<ol>
							<li>Expand the "Manage" menu from the top navigation bar.</li>
							<li>Click the "Sites" menu option.</li>
							<li>Find the site that the call group will be associated with.</li>
							<li>Click the "Manage call groups" option.</li>
							<li>If creating a new call group, click the "Add" button.</li>
							<li>Enter the details for the call group.</li>
							<li>Click "Save all changes" to save all new/edited call groups.</li>
						</ol>
					</>,
				});
			}
		}

		// Reports questions
		allQuestions.Reports.push({
			question: `Why does it say "No reports"?`,
			answer: <>
				The webpage shows "No reports" when you have not requested any reports recently.
			</>,
		});

		allQuestions.Reports.push({
			question: 'How do I create a report?',
			answer: <>
				<p>
					Click onto the "Reports" button on the menu bar at the top of the screen. On the reports page, click the "Create New Report" button, then select the type of report you want to create.
					Fill in the details needed and click "Create". The requested report will now show its processing status on the reports page and will be available to view once finished processing.
				</p>
				<img src={reportsMenuImage} alt="Reports Menu Icon"/>
			</>,
		});

		const reportTypes: JSX.Element[] = [];

		if (generalPermissions.viewAssetLocations) {
			reportTypes.push(<>
				<dt>Activity:</dt>
				<dd>The Activity Report displays the summarised movements (travelling/stopped) of one or more assets during a specified time period.</dd>

				<dt>Asset Event Export:</dt>
				<dd>Asset Event Export allows you to export all asset events for one or more assets during a specified time period.</dd>

				<dt>Daily:</dt>
				<dd>The Daily Report displays daily summaries of activity for one or more assets during a specified time period.</dd>

				<dt>Geofence:</dt>
				<dd>The Geofence Report displays the geofences visited by one or more assets during a specified time period.</dd>

				<dt>Location:</dt>
				<dd>The Location report displays the location of one or more assets at a specified time (now or in the past).</dd>

				<dt>Safety Timer:</dt>
				<dd>The Safety Timer report displays the safety timer history of one or more assets during a specified time period.</dd>
			</>);
		}

		if (generalPermissions.manageAddressBooks && generalPermissions.manageAssets) {
			reportTypes.push(<>
				<dt>Driver:</dt>
				<dd>The history for drivers, which details what assets the drivers have used during a specified time period.</dd>
			</>);
		}

		if (generalPermissions.accessBillingReports) {
			reportTypes.push(<>
				<dt>Device Billing:</dt>
				<dd>The billing information for one or more devices over a specified time period.</dd>

				<dt>Asset Log History:</dt>
				<dd>The asset log history for one or more assets over a specified time period.</dd>
			</>);

			reportTypes.push(<>
				<dt>User Auth Audit:</dt>
				<dd>The authorization actions for one or more users during a specified time period (e.g. sign in/sign out/sign in as).</dd>
			</>);
		}

		allQuestions.Reports.push({
			question: 'What is the difference between each report type?',
			answer: <>
				{reportTypes}
			</>,
		});

		if (generalPermissions.accessBillingReports) {
			const billingCompanyType = identityType === C.IdentityType.SuperUser ? 'dealer' : 'client';

			allQuestions.Reports.push({
				question: 'What information does the billing report contain?',
				answer: <>
					<p>The billing report is split into sections containing information for each of your {billingCompanyType}s, or if billing codes are specified, the report is split by the {billingCompanyType}s who have those billing codes associated with them.</p>

					<p>When creating a report without specifying billing codes there will be one section of the report at the top labelled 'Unassigned', this is information of assets that are not assigned to any of your {billingCompanyType}s.</p>

					{identityType === C.IdentityType.SuperUser && <img src={billingReportSuperUserImage} alt="Billing Super User Image"/>}
					{identityType === C.IdentityType.Dealer && <img src={billingReportDealerImage} alt="Billing Dealer Image"/>}

					<dt>Billing Code:</dt>
					<dd>The billing code that is associated with the {billingCompanyType}.</dd>

					<dt>Billing Types:</dt>
					<dd>How many assets are either billable or non billable.</dd>

					<dt>Additional Asset Features:</dt>
					<dd>This section will show how many assets have additional features enabled.</dd>

					<dt>Billable Months:</dt>
					<dd>This is the total billable months for all the assets associated with the specific {billingCompanyType}.</dd>

					<dt>Activation State:</dt>
					<dd>The activation state of all assets associated with the {billingCompanyType}.</dd>

					<dt>Other Items:</dt>
					{identityType === C.IdentityType.Dealer && <dd>Any other items associated with the {billingCompanyType}, such as how many configuration updates were made for the assets.</dd>}
					{identityType === C.IdentityType.SuperUser && <dd>Any other items associated with the {billingCompanyType}, such as how many configuration updates were made for the assets and how many gateways they have.</dd>}

					<br />

					<p>
						If more information is required from the report, you can download the 'Details' CSV report version of the billing report.
						This contains billing information for each asset rather then per {billingCompanyType}.
					</p>
				</>,
			});
		}

		allQuestions.Reports.push({
			question: 'What is the difference between each processing status for reports?',
			answer: <>
				<p>The report processing types are listed in detail below:</p>

				<dt>Queued:</dt>
				<dd>This status occurs when a report is first requested. When a report is requested from the server it will be put onto a queue of reports to be processed.</dd>

				<dt>Processing:</dt>
				<dd>This status occurs when a report has been removed from the queue to be processed, the report is then created using the values supplied when requesting the report.</dd>

				<dt>Complete:</dt>
				<dd>This status occurs when the report has finished processing and is available for viewing or download.</dd>

				<dt>Failed:</dt>
				<dd>This status occurs when there was an issue processing the report on the server. If you consistently receive this status for a specific report, please contact support.</dd>
			</>,
		});

		allQuestions.Reports.push({
			question: 'How do I view a report that I have created?',
			answer: <>
				<p>
					Once your report is in the "Complete" state, you may view or download the report (option will depend on report type).
					To view or download a report, click the appropriate button next to the report on the main reports page.
				</p>
				<img src={saveAndDownloadReportImage} alt="Save and downlaod report image"/>
			</>,
		});

		allQuestions.Reports.push({
			question: 'Why is there no information in the report I created?',
			answer: <>
				Sometimes a report will be created with no information. This means that there was no data or events applicable to the selected report type within the specified time period.
				This usually happens when the values supplied for the report were not correct, or the asset was not active during the selected time period. You may have selected the wrong assets,
				wrong time/date, or other unique report values may not be correct.
			</>,
		});

		allQuestions.Reports.push({
			question: 'How do I delete a report?',
			answer: <>
				It is currently not possible to delete a report from the Reports page.
			</>,
		});

		allQuestions.Reports.push({
			question: 'How do I get out of the report and back to the reports list page?',
			answer: <>
				<p>
					To get back to the reports list page, click the "Reports" button on the menu bar at the top of the screen.
				</p>
				<img src={reportsMenuImage} alt="Reports Menu Icon Image"/>
			</>,
		});

		if (generalPermissions.manageUserGroups) {
			allQuestions.Reports.push({
				question: 'How do I configure which users can view and create reports?',
				answer: <>
					<p>
						Permission to view and create reports is handled via user groups.
						See the questions "How do I add a user group?" or "How do I view/edit a user group?" in the user management section for information about user group management.
					</p>

					<p>
						The "Use reporting" permission in a user group controls if users in a user group can create/view reports.
						See the "How do I view/edit the permissions for a user group?" question in the user management section for information on enabling/disabling this permission.
					</p>

					<p>
						Note that by default, the "All Users" group has the "Use reporting" permission. This should be disabled if only a subset of users should have this permission.
					</p>
				</>,
			});
		}

		// Scheduled reports.
		allQuestions.ScheduledReports.push({
			question: 'What is a scheduled report?',
			answer:<>
				<p>A scheduled report allows you to configure a report to be generated a recurring basis.</p>
			</>
		});

		allQuestions.ScheduledReports.push({
			question: 'How do I view a list of all my scheduled reports?',
			answer:<>
				<p>
					Click the "Scheduled Reports" button on the top right of the "Reports" page.
				</p>

				<img src={scheduledReportsButtonImage} alt="Scheduled Report Button Image"/>
			</>
		});

		allQuestions.ScheduledReports.push({
			question: 'How do I create a scheduled report?',
			answer:<>
				<p>Click the "Create New Scheduled Report" button on the top right of the "Scheduled Reports" page.</p>

				<img src={scheduledReportsCreateButtonImage} alt="Scheduled Report Create Button Image"/>
			</>
		});

		allQuestions.ScheduledReports.push({
			question: 'How do I edit or delete a scheduled report?',
			answer:<>
				<span>
					On the "Scheduled Reports" page, select the "Edit" or "Delete" menu option from the scheduled report dropdown menu.
				</span>

				<img src={scheduledReportsEditAndDeleteButtonsImage} alt="Scheduled Report Edit And Delete Buttons Image"/>
			</>
		});

		// User management questions
		if (identityType == C.IdentityType.SuperUser || identityType == C.IdentityType.Dealer || generalPermissions.manageUsers) {
			allQuestions.UserManagement.push({
				question: 'How do I view/manage a user?',
				answer: <>
					<p>
						To edit a user you will need to navigate to the user list page.
						To navigate to the user list page click the "Manage" menu item to open the manage menu, from here select the "Users" option as seen below to access the list of all of your users.
					</p>

					{usersManageMenuImage}

					<p>
						You can then expand the options menu for the user you want to manage, and select the appropriate option:
					</p>

					{generalPermissions.signInAs && <img src={userManageMenuCanSignInImage} alt="Users Manage Menu"/>}
					{!generalPermissions.signInAs && <img src={userManageMenuNoSignInImage} alt="Users Manage Menu"/>}
				</>,
			});

			allQuestions.UserManagement.push({
				question: 'How do I view/edit which groups a user belongs to?',
				answer: <>
					To view which groups a user belongs to, edit the required user. On the edit page, there is a button labeled "Assign groups".
					Clicking this button will take you to a page which will display all the current groups the user is assigned to, and allow you to add or remove groups.
				</>,
			});

			allQuestions.UserManagement.push({
				question: 'How do I add a user group?',
				answer: <>
					From the user list page, click the "Add" button to add a new user group.
				</>,
			});

			allQuestions.UserManagement.push({
				question: 'How do I view/edit a user group?',
				answer: <>
					From the user list page, click the "User groups" button to be view your user groups.
					You can then expand the options menu for the required user group and select the appropriate option.
				</>,
			});

			allQuestions.UserManagement.push({
				question: 'How do I view/edit which users can view which assets?',
				answer: <>
					<p>
						Permission to view assets is managed via user groups. When editing a user group, the "Assets" page allows you to specify which
						asset groups a user group has access to.
					</p>

					<img src={userGroupsSideBarAssetsImage} alt="User Groups Sidebar Assets Option Selected"/>
				</>,
			});

			allQuestions.UserManagement.push({
				question: 'How do I view/edit which users are in a group?',
				answer: <>
					<p>
						To view which users are in a group, click the "User groups" button on the user management page. From here, you can select which group you wish to view/edit.
						On the user group view/edit page, there is an option on the left of the page labelled "Users", click this to visit the users page for this user group.
					</p>

					<img src={userGroupsSideBarUsersImage} alt="User Groups Sidebar Users Option Selected"/>

					<p>
						The "Users" page displays all users currently in that group and allows you to add/remove users.
					</p>
				</>,
			});

			allQuestions.UserManagement.push({
				question: 'How do I view/edit the permissions for a user group?',
				answer: <>
					<p>
						Edit the user group you want to change permissions for, then click the "Permissions" menu option on the left side of the page:
					</p>

					<img src={userGroupsSideBarPermissionsImage} alt="User Groups Sidebar Permissions Option Selected"/>

					<p>
						The "Permissions" page allows you to specify which permissions this user group has. These permissions will be applied to all users in this group.
					</p>

					<p>
						<strong>Note:</strong> By default, some permissions are given to all users via the "All Users" group. You may want to disable these permissions if
						you are creating multiple different user groups.
					</p>
				</>,
			});
		}

		// Other questions
		if (generalPermissions.viewAssetLocations) {
			allQuestions.Other.push({
				question: 'How often does the location data get updated?',
				answer: <>
					The location data gets updated as soon as the server receives a location from an asset.
					This will depend on the type of asset, and the settings for that asset.
				</>,
			});
		}

		allQuestions.Other.push({
			question: `What can I do in the "Manage" section?`,
			answer: <>
				{generalPermissions.manageAddressBooks && <>
					<dt>Address Books:</dt>
					<dd>View all the address books you have access to, and {generalPermissions.addAddressBooks ? 'add or' : ''} edit address books.</dd>
				</>}

				{generalPermissions.manageAssets && <>
					<dt>Assets:</dt>
					<dd>View all the assets you have access to, and add or edit assets.</dd>
				</>}

				{generalPermissions.manageBeacons && <>
					<dt>Beacons:</dt>
					<dd>View beacons, and add or edit beacons.</dd>
				</>}

				{generalPermissions.accessCalls && <>
					<dt>Call recordings:</dt>
					<dd>View and search call recordings for your assets.</dd>
				</>}

				{ /* Dealers are the only identity type with the manage menu for clients. */}
				{generalPermissions.manageClients && identityType != C.IdentityType.Dealer && <>
					<dt>Clients:</dt>
					<dd>View clients, and add or edit clients.</dd>
				</>}

				{generalPermissions.manageDealers && <>
					<dt>Dealers:</dt>
					<dd>View dealers, and add or edit dealers.</dd>
				</>}

				{generalPermissions.manageDevices && <>
					<dt>Devices:</dt>
					<dd>View devices, and add or edit dealers.</dd>
				</>}

				{generalPermissions.accessEmergencies && <>
					<dt>Alerts & Emergencies:</dt>
					<dd>View alerts & emergencies and allow you to resolve them.</dd>
				</>}

				{generalPermissions.manageFloorPlans && <>
					<dt>Floor Plans:</dt>
					<dd>View floor plans, and add or edit floor plans.</dd>
				</>}

				{generalPermissions.manageGateways && <>
					<dt>Gateways:</dt>
					<dd>View gateways, and add or edit gateways.</dd>
				</>}

				{generalPermissions.manageGeofences && <>
					<dt>Geofences:</dt>
					<dd>View geofences, and add or edit geofences.</dd>
				</>}

				{generalPermissions.linkAssets && <>
					<dt>Linked Devices:</dt>
					<dd>View linked devices, link a new device, or unlink an existing device.</dd>
				</>}

				{generalPermissions.manageSites && <>
					<dt>Sites:</dt>
					<dd>View sites, and add or edit sites.</dd>
				</>}

				{generalPermissions.manageUsers && <>
					<dt>Users:</dt>
					<dd>View users, add or edit users, and sign in as a specific user.</dd>
				</>}

				{generalPermissions.manageAssetTelematics && <>
					<dt>Vehicle Information:</dt>
					<dd>
						View all the current vehicle information for assets you have access to, and update the information with current readings.
						The information includes the current odometer value and engine hours for all assets.
						The current odometer is helpful for an approximate reading of how far an asset has travelled, but it is not valid for calculating road user charges (RUC).
					</dd>
				</>}
			</>,
		});

		let urgentHelp = `please contact support_general@torutek.com`;
		if (_brandingService.getBranding() === VitalBranding)
			urgentHelp = `please contact faults@vital.co.nz or call 0800 101 900 and select option 2 for faults and network support.`;

		allQuestions.Other.push({
			question: 'Who can I go to for help?',
			answer: <>
				Please contact your account manager for support. For urgent assistance or to report a critical fault, {urgentHelp}
			</>,
		});

		return allQuestions;
	};

	const renderSection = (sectionQuestions: Question[], nameOfSection: string) => {
		return <div className="help__expansion-panel">
			<h2>{nameOfSection}</h2>
			{sectionQuestions.map(renderQuestion)}
		</div>;
	};

	const renderQuestion = (question: Question, questionNumber: number) => {
		return <Accordion key={questionNumber}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				>
				<span className="help__question">{question.question}</span>
			</AccordionSummary>

			<AccordionDetails className="help__answer">
				{question.answer}
			</AccordionDetails>
		</Accordion>;
	};

	const renderHelpSections = (questions: AllQuestions): JSX.Element => {
		return <div>
			{questions.Account.length > 0 && renderSection(questions.Account, 'Your Account')}
			{questions.Assets.length > 0 && renderSection(questions.Assets, 'Assets')}
			{questions.Devices.length > 0 && renderSection(questions.Devices, 'Devices')}
			{questions.AddressBooks.length > 0 && renderSection(questions.AddressBooks, 'Address Books')}
			{questions.Gateways.length > 0 && renderSection(questions.Gateways, 'Gateways')}
			{questions.Emergencies.length > 0 && renderSection(questions.Emergencies, 'Alerts & Emergencies')}
			{questions.SafetyTimer.length > 0 && renderSection(questions.SafetyTimer, 'Safety Timer')}
			{questions.Geofences.length > 0 && renderSection(questions.Geofences, 'Geofences')}
			{questions.Maps.length > 0 && renderSection(questions.Maps, 'Maps')}
			{questions.LiveMap.length > 0 && renderSection(questions.LiveMap, 'Live Map')}
			{questions.HistoryMap.length > 0 && renderSection(questions.HistoryMap, 'History Map')}
			{questions.CallRecordings.length > 0 && renderSection(questions.CallRecordings, 'Call Recordings')}
			{questions.Reports.length > 0 && renderSection(questions.Reports, 'Reports')}
			{questions.ScheduledReports.length > 0 && renderSection(questions.ScheduledReports, 'Scheduled Reports')}
			{questions.UserManagement.length > 0 && renderSection(questions.UserManagement, 'User Management')}
			{questions.Other.length > 0 && renderSection(questions.Other, 'Other')}
		</div>;
	};

	return <ThingLoader
		load={load}
		render={(questions: AllQuestions) => <FixedWidthPage
			headingText="Help"
			noContentBackground
		>
			{renderHelpSections(questions)}
		</FixedWidthPage>}
	/>;
};
