import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, Form, Field, FormikErrors } from 'formik';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	AuthenticationService,
	Client as C,
	ClientService,
	HistoryService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

interface ManageClientFormValues {
	name: string;
	billingCode: string;
}

const validateForm = (values: ManageClientFormValues, errors: FormikErrors<ManageClientFormValues>) => {
	if (!values.name)
		errors.name = 'Client name name is required.';
}

interface Props {
	client?: C.IClientDto | null;
}

export const ManageClientComponent = observer((props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _clientService = useInjection<ClientService>(Service.Client);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	useEffect(() => {
		if (_authService.currentAuth.user.identity.type !== C.IdentityType.Dealer)
			_historyService.history.replace('/app');
	}, []);

	const addClient = async (values: ManageClientFormValues): Promise<boolean> => {
		const currentUserIdentity = _authService.currentAuth.user.identity;

		const request: C.IAddClientRequest = {
			name: values.name,
			dealerId: currentUserIdentity.dealerId!,
			billingCode: values.billingCode,
		};

		try {
			const result = await _clientService.addClient(request);
			_historyService.history.push(`/app/clients/${result.clientId}/overview`);
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err);
			return false;
		}
	};

	const updateClient = async (values: ManageClientFormValues): Promise<boolean> => {
		const request: C.IUpdateClientRequest = {
			name: values.name,
			billingCode: values.billingCode,
		};

		try {
			const result = await _clientService.updateClient(props.client!.clientId, request);
			_historyService.history.push(`/app/clients/${result.clientId}/overview`);
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err);
			return false;
		}
	};

	const onSubmit = async (values: ManageClientFormValues, { setSubmitting }: FormikHelpers<ManageClientFormValues>) => {
		let success;
		if (props.client)
			success = await updateClient(values);
		else
			success = await addClient(values);

		if (!success)
			setSubmitting(false);
	};

	const addingNewClient = !props.client;

	const initialFormValues: ManageClientFormValues = {
		name: props.client?.name ?? '',
		billingCode: props.client?.billingCode ?? ''
	};

	return <FixedWidthPage
		className="form-page"
		headingText={addingNewClient ? 'Add Client' : 'Edit Client'}
		pageItemId={props.client?.clientId}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageClientFormValues>) => <Form className="formik-form">
				<Field
					name="name"
					label="Client Name"
					type="text"
					component={FormikTextField}
					required
				/>

				<Field
					name="billingCode"
					label="Billing Code"
					type="text"
					component={FormikTextField}
				/>

				<Button
					type="submit" variant="contained" color="primary"
					loading={formikProps.isSubmitting}
					startIcon={addingNewClient ? null : <SaveIcon />}
					text={addingNewClient ? 'Add Client' : 'Save Changes'}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
