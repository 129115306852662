import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import {
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import { ErrorMessagePage, LoadingMessagePage } from 'src/components';

interface WithIdPropsBase<T> {
	id: string;
	load: (id: string) => Promise<T> | null;
}

interface WithoutIdPropsBase<T> {
	id?: undefined;
	load: () => Promise<T>;
}

interface RenderProps<T> {
	render: (thing: T, reload: Function) => JSX.Element;
	errorLoadingMessage?: string;
}

type WithIdProps<T> = WithIdPropsBase<T> & RenderProps<T>;
type WithoutIdProps<T> = WithoutIdPropsBase<T> & RenderProps<T>;

export type ThingLoaderProps<T> = WithIdProps<T> | WithoutIdProps<T>;

function isIdLoader<T>(item: ThingLoaderProps<T>): item is WithIdProps<T> {
	return (item as WithIdPropsBase<T>).id != undefined;
}

export function ThingLoader<T>(props: ThingLoaderProps<T>) {
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [loading, setLoading] = useState<boolean>(true);
	const [thing, setThing] = useState<T | null>(null);

	const loadThing = async (thingId?: string | null) => {
		setLoading(true);
		setThing(null);

		let thing: T | null = null;
		try {
			if (isIdLoader(props) && thingId)
				thing = await props.load(thingId);
			else
				thing = await (props as WithoutIdProps<T>).load();
		} catch (err) {
			_toasterService.handleWithToast(err);
		}

		setThing(thing);
		setLoading(false);
	};

	const reload = async () => {
		await loadThing(props.id);
	};

	useAsyncEffect(async () => {
		await loadThing(props.id);
	}, [props.id]);

	if (loading)
		return <LoadingMessagePage />;

	if (!thing)
		return <ErrorMessagePage message={props.errorLoadingMessage} />;

	return props.render(thing, reload);
}
