import React from 'react';
import classNames from 'classnames';
import * as moment from 'moment-timezone';

import { FixedWidthPage, MessagePage, ErrorMessagePage, LoadingMessagePage } from 'src/components';

import { longDateTimeFormat } from 'src/util/dateTimeFormats';
import { useQueryEmergenciesForEmergencyHistoryList, QueryEmergenciesForEmergencyHistoryList_emergencies } from 'src/graphql/__generated__/queries/queryEmergenciesForEmergencyHistoryList';
import { EmergencyStatus } from 'src/../__generated__/globalTypes';
import { getEventTime } from 'src/util/eventHelpers';

import {
	AuthenticationService,
	Service,
	useInjection,
} from 'src/services';

export const EmergencyHistoryList = () => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const emergenciesListQuery = useQueryEmergenciesForEmergencyHistoryList({
		variables: {
			emergencyStatus: EmergencyStatus.RESOLVED,
		}
	});

	if (emergenciesListQuery.loading)
		return <LoadingMessagePage />;

	if (emergenciesListQuery.error || !emergenciesListQuery.data?.emergencies)
		return <ErrorMessagePage />;

	const emergencies = emergenciesListQuery.data.emergencies.sort((a, b) => moment(b.generatedAt).valueOf() - moment(a.generatedAt).valueOf());

	const renderRow = (emergency: QueryEmergenciesForEmergencyHistoryList_emergencies) => {
		const resolveAction = emergency.actions && emergency.actions.length > 0 && emergency.actions[0];

		return <tr key={emergency.id} className="content-box">
			<td>{emergency.asset.name}</td>
			<td>{moment(getEventTime(emergency)).tz(_authenticationService.currentAuth.user.timeZone).format(longDateTimeFormat)}</td>
			<td>{emergency.deviceIoConfiguration && emergency.deviceIoConfiguration.name}</td>
			<td>{resolveAction && resolveAction.user.name}</td>
			<td>{resolveAction && moment(resolveAction.performedAt).tz(_authenticationService.currentAuth.user.timeZone).format(longDateTimeFormat)}</td>
			<td>{resolveAction && resolveAction.notes}</td>
		</tr>;
	};

	return <FixedWidthPage
		headingText="Emergency History"
		noContentBackground
		contentClassName={classNames({ 'flex-fill-no-overflow': emergencies.length === 0 })}
	>
		{emergencies.length > 0 && <table className="card-table">
			<thead>
				<tr>
					<th>Asset Name</th>
					<th>Time of Emergency</th>
					<th>IO configuration</th>
					<th>Resolved By</th>
					<th>Resolved At</th>
					<th>Notes</th>
				</tr>
			</thead>

			<tbody>
				{emergencies.map(renderRow)}
			</tbody>
		</table>}

		{emergencies.length === 0 && <MessagePage
			title="No resolved emergencies."
		/>}
	</FixedWidthPage>;
};
