import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryEmergenciesForEmergencyHistoryList($emergencyStatus: EmergencyStatus!) {
		emergencies(emergencyStatus: $emergencyStatus) {
			id
			status
			asset {
				name
			}
			recordedAt
			generatedAt
			actions {
				user {
					name
				}
				performedAt
				notes
			}
			deviceIoConfiguration {
				name
				device {
					name
				}
			}
		}
	}
`;
