import React from 'react';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react';

import { FormikSelect } from 'src/components';
import { IOption } from 'src/util';

import {
	IUserSelectorFormValues,
} from 'src/services';

interface Props {
	options: IOption<string>[] | null;
	formikProps: FormikProps<IUserSelectorFormValues>;
}

export const UserSelector = observer((props: Props) => {
	if (!props.options)
		return null;

	return <FormikSelect
		name="identityIds"
		placeholder="Select Users"
		label="Users"
		form={props.formikProps}
		multi
		options={props.options.slice()}
		getOptionLabel={option => option.label}
		getOptionValue={option => option.value}
		disableCloseOnSelect
	/>;
});
