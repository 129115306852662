import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import { Field, FormikErrors } from 'formik';
import { InputAdornment } from '@material-ui/core';
import moment from 'moment-timezone';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Button, FormikTextField } from 'src/components';
import { CustomActionDialogBox } from 'src/components/customActionDialogBox';
import { undefinedIfUnchanged } from 'src/util';
import { AssetServiceReminderTableRow, AssetServiceReminderTableRowReminderType } from 'src/components/assetServiceRemindersTable';

import { useMutationResolveAssetServiceReminder } from 'src/graphql/__generated__/mutations/mutationResolveAssetServiceReminder';
import { AssetTelematicsType } from 'src/../__generated__/globalTypes';

import {
	AuthenticationService,
	Client as C,
	DistanceService,
	DistanceUnit,
	Service,
	ToasterService,
	TelematicsService,
	useInjection,
} from 'src/services';

const useStyles = makeStyles({
	loadingSpinnerStyles: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block !important',
	},
	dialogInputsStyles: {
		'& > div': {
			marginBottom: '10px',
		}
	}
});

interface Props {
	assetServiceReminder: AssetServiceReminderTableRow;
	assetServiceReminderType: AssetServiceReminderTableRowReminderType;
	close: () => void;
	onSuccess?: () => Promise<any>;
}

export interface ResolveAssetServiceReminderFormValues {
	nextDateTrigger: string;
	nextOdometerTrigger: number;
	nextEngineTimeTrigger: number;
	notes?: string;
}

export const ResolveAssetServiceReminderDialog = observer((props: Props) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const distanceService = useInjection<DistanceService>(Service.DistanceService);
	const telematicsService = useInjection<TelematicsService>(Service.Telematics);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const classNames = useStyles();

	const [loading, setLoading] = useState<boolean>(false);
	const [ resolveAssetServiceReminderMutation ] = useMutationResolveAssetServiceReminder();

	const identityDistanceUnit = authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles;

	const submit = async (values: ResolveAssetServiceReminderFormValues) => {
		setLoading(true);

		try {
			const nextDateTrigger = props.assetServiceReminderType.dateEnabled ? values.nextDateTrigger : undefined;
			const nextOdometerTrigger = props.assetServiceReminderType.odometerEnabled ? distanceService.getMetresFromDistance(values.nextOdometerTrigger, identityDistanceUnit) : undefined;
			const nextEngineTimeTrigger = props.assetServiceReminderType.engineTimeEnabled ? Math.round(values.nextEngineTimeTrigger * 60) : undefined;

			await resolveAssetServiceReminderMutation({
				variables: {
					input: {
						assetReminderId: props.assetServiceReminder.id,
						nextDateTrigger: undefinedIfUnchanged(props.assetServiceReminder.nextDateTriggerDate, nextDateTrigger),
						nextOdometerTrigger: undefinedIfUnchanged(props.assetServiceReminder.nextOdometerTrigger, nextOdometerTrigger),
						nextEngineTimeTrigger: undefinedIfUnchanged(props.assetServiceReminder.nextEngineTimeTrigger, nextEngineTimeTrigger),
						notes: values.notes,
					}
				}
			});

			props.onSuccess && await props.onSuccess();

			toasterService.showSuccess(`Asset service reminder resolved.`);

			props.close();
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to resolve asset service reminder.');
			setLoading(false);
		}
	};

	const getInitialTelematicsTriggerValue = (telematicsType: AssetTelematicsType) => {
		let telematicsValue = 0;
		if (telematicsType === AssetTelematicsType.ENGINE_TIME && props.assetServiceReminder.nextEngineTimeTrigger && props.assetServiceReminder.engineTimeDefaultFrequency) {
			const engineTimeValue = props.assetServiceReminder.nextEngineTimeTrigger + props.assetServiceReminder.engineTimeDefaultFrequency;
			telematicsValue = Math.round(engineTimeValue / 60);
		} else if (telematicsType === AssetTelematicsType.ODOMETER && props.assetServiceReminder.nextOdometerTrigger && props.assetServiceReminder.odometerDefaultFrequency) {
			const odoValue = props.assetServiceReminder.nextOdometerTrigger + props.assetServiceReminder.odometerDefaultFrequency;
			telematicsValue = distanceService.getDistance(odoValue, identityDistanceUnit);
		}

		return telematicsValue;
	};

	const validateForm = (values: ResolveAssetServiceReminderFormValues) => {
		const errors: FormikErrors<ResolveAssetServiceReminderFormValues> = {};

		if (props.assetServiceReminderType.dateEnabled) {
			const nextDateTriggerAsDate = moment(values.nextDateTrigger);
			if (!nextDateTriggerAsDate.isValid())
				errors.nextDateTrigger = 'Next date trigger is not valid.';
		}

		if (props.assetServiceReminderType.odometerEnabled) {
			if (!values.nextOdometerTrigger || values.nextOdometerTrigger <= 0)
				errors.nextOdometerTrigger = 'Next odometer trigger must be greater than 0.';
		}

		if (props.assetServiceReminderType.engineTimeEnabled) {
			if (!values.nextEngineTimeTrigger || values.nextEngineTimeTrigger <= 0)
				errors.nextEngineTimeTrigger = 'Next engine time trigger must be greater than 0.';
		}

		if (values.notes && values.notes.length > 500)
			errors.notes = 'Notes must be less than 500 characters.';

		return errors;
	};

	const initialValues: ResolveAssetServiceReminderFormValues = {
		nextDateTrigger: props.assetServiceReminder.nextDateTriggerDate ? moment(props.assetServiceReminder.nextDateTriggerDate).add(props.assetServiceReminder.dateDefaultFrequency, 'day').format('YYYY-MM-DD') :  moment.tz(authenticationService.currentAuth.user.timeZone).add(1, 'day').format('YYYY-MM-DD'),
		nextOdometerTrigger: getInitialTelematicsTriggerValue(AssetTelematicsType.ODOMETER),
		nextEngineTimeTrigger: getInitialTelematicsTriggerValue(AssetTelematicsType.ENGINE_TIME),
		notes: undefined,
	};

	const renderDialogContent = () => {
		return <div className={classNames.dialogInputsStyles}>
			<Typography gutterBottom>
				Asset: {props.assetServiceReminder.assetName}
			</Typography>
			<Typography gutterBottom>
				Service reminder type: {props.assetServiceReminderType.name}
			</Typography>

			{props.assetServiceReminderType.dateEnabled && <Field
				name="nextDateTrigger"
				label="Next date trigger"
				type="date"
				component={FormikTextField}
				required
			/>}

			{props.assetServiceReminderType.odometerEnabled && <div>
				<span>{telematicsService.getFormattedOdometerTelematicsReadingValue(props.assetServiceReminder.odometerReadingValue ?? undefined, authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles)}</span>

				<Field
					name="nextOdometerTrigger"
					label="Next odometer trigger"
					type="number"
					InputProps={{
						endAdornment: <InputAdornment position="end">{authenticationService.currentAuth.user.usesMetric ? 'km' : 'mi' }</InputAdornment>,
					}}
					component={FormikTextField}
					required
				/>
			</div>}

			{props.assetServiceReminderType.engineTimeEnabled && <div>
				<span>{telematicsService.getFormattedEngineTimeTelematicsReadingValue(props.assetServiceReminder.engineTimeReadingValue ?? undefined)}</span>

				<Field
					name="nextEngineTimeTrigger"
					label="Next engine time trigger"
					type="number"
					InputProps={{
						endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
					}}
					component={FormikTextField}
					required
				/>
			</div>}

			<Field
				name="notes"
				label="Notes"
				type="text"
				component={FormikTextField}
				multiline
				minRows={3}
			/>
		</div>;
	};

	return <Formik
		initialValues={initialValues}
		validate={validateForm}
		validateOnChange={false}
		onSubmit={submit}
	>
		{formikProps => <Form className="formik-form">
			<CustomActionDialogBox
				title="Resolve Asset Service Reminder"
				actionButton={<Button
					variant="contained"
					color="primary"
					text="Resolve"
					onClick={formikProps.submitForm}
					loading={loading}
				/>}
				dialogCloseCallback={props.close}
				fullWidth
				maxWidth="sm"
			>
				{loading
					? <CircularProgress className={classNames.loadingSpinnerStyles} size={20} />
					: renderDialogContent()}
			</CustomActionDialogBox>
		</Form>}
	</Formik>;
});
