import React, { useState } from 'react';
import { MaterialTableProps, Filter } from '@material-table/core';
import { DialogContentText } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { css } from '@emotion/css';
import { IconPreview } from './iconPreview';

import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { Button, FixedWidthPage, ThingLoader, MessagePage, CustomActionDialogBox, MaterialTable, PopoverMenu, PopoverMenuItem } from 'src/components';

import {
	AssetTypesService,
	AuthenticationService,
	Client as C,
	ClientService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

interface IAssetTypeRow {
	id: string;
	name: string;
	useCustomMapMarker: boolean;
	clientName: string;
	icon?: C.MapMarkerIcon | null;
	iconColor?: string | null;
	iconSize?: number | null;
	mapMarkerColor?: string | null;
	mapMarkerSize?: number | null;
}

interface IPageData {
	assetTypesData: IAssetTypeRow[];
	canSetClient: boolean;
}

const deleteButtonStyle = css`
	width: 210px;
	height: 36px;
	align-self: center;
`;

export const AssetTypesList = () => {
	const _assetTypesService = useInjection<AssetTypesService>(Service.AssetType);
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _clientService = useInjection<ClientService>(Service.Client);
	const _toaster = useInjection<ToasterService>(Service.Toaster);

	const [selectedAssetTypes, setSelectedAssetTypes] = useState<IAssetTypeRow[] | null>(null);

	const load = async (): Promise<IPageData | null> => {
		const allAssetTypes = await _assetTypesService.listAssetTypes();
		if (!allAssetTypes)
			return null;

		const canManageClients = !!_authService.currentAuth.permissions.general.manageClients;
		let clients: C.IClientDto[] | null = null;

		if (canManageClients)
			clients = await _clientService.getAllClients();

		const clientIdToName: { [key: string]: string } = {};
		if (clients) {
			for (const client of clients) {
				clientIdToName[client.clientId] = client.name;
			}
		}

		const assetData = allAssetTypes.map((assetType: C.IAssetTypeDto): IAssetTypeRow => {
			let clientName = '';
			if (canManageClients && clientIdToName && assetType && assetType.clientId)
				clientName = clientIdToName[assetType.clientId];

			return {
				id: assetType.assetTypeId,
				useCustomMapMarker: assetType.useCustomMapMarker,
				name: assetType.name,
				clientName: clientName,
				icon: assetType.icon,
				iconColor: assetType.iconColor,
				iconSize: assetType.iconSize,
				mapMarkerSize: assetType.mapMarkerSize,
				mapMarkerColor: assetType.mapMarkerColor,
			};
		});

		return {
			canSetClient: canManageClients,
			assetTypesData: assetData,
		};
	};

	const renderTableActions = (props: MaterialTableProps<IAssetTypeRow>, reload: Function) => {
		if (!props.data || props.data.length === 0)
			return null;

		const rowData = props.data as IAssetTypeRow[];

		return <Button
			className={deleteButtonStyle}
			variant="contained"
			color={red}
			startIcon={<HighlightOffIcon />}
			text={props.data.length === 1 ? 'Delete Asset Type' : 'Delete Asset Types'}
			title={props.data.length === 1 ? 'Delete Asset Type' : 'Delete Asset Types'}
			onClick={() => deleteAssetTypes(rowData)}
		/>;
	};

	const deleteAssetTypes = (assetTypes: IAssetTypeRow[]) => {
		setSelectedAssetTypes(assetTypes);
	};

	const confirmDelete = async (assetTypes: IAssetTypeRow[], reload: Function) => {
		try {
			await _assetTypesService.deleteAssetTypes(assetTypes.map(x => x.id));
			_toaster.showSuccess('The selected asset type(s) have been deleted.');

			reload();
		} catch (e) {
			_toaster.handleWithToast(e, 'Failed to delete asset type(s).');
		}

		setSelectedAssetTypes(null);
	};

	const renderOptionsMenu = (assetRow: IAssetTypeRow) => {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				title="Edit asset type"
				href={`/app/asset-types/${assetRow.id}/edit`}
			/>,
			<PopoverMenuItem
				key="delete"
				text="Delete"
				title="Delete asset type"
				onClick={() => setSelectedAssetTypes([assetRow])}
			/>
		];
	};

	return <ThingLoader
		load={load}
		render={(pageData: IPageData, reload: Function) => <FixedWidthPage
			headingText="Asset Types"
			noContentBackground
			headingActions={[
				<Button text="Add a new asset type" startIcon={<AddIcon />} variant="outlined" color="primary" href="/app/asset-types/add" />
			]}
			contentMessageReplace={Object.keys(pageData.assetTypesData).length === 0 && <MessagePage
				title="No asset types."
				action={<Button href="/app/asset-types/add" text="Add a new asset type?" variant="outlined" />}
			/>}
		>
			<MaterialTable
				tableName="list-asset-types"
				columns={[
					{
						title: 'Name',
						field: 'name',
					},
					{
						title: 'Icon',
						field: 'iconName',
						render: rowData => {
							return <IconPreview
								useCustomIcon={rowData.useCustomMapMarker}
								iconShape={rowData.icon as C.MapMarkerIcon}
								iconColor={rowData.iconColor}
								iconSize={rowData.iconSize}
								backgroundColor={rowData.mapMarkerColor!}
								backgroundSize={rowData.mapMarkerSize!}
							/>;
						},
						filtering: false,
					},
					{
						title: 'Client',
						field: 'clientName',
						hidden: !pageData.canSetClient,
					},
					{
						title: 'Options',
						grouping: false,
						filtering: false,
						sorting: false,
						headerStyle: ({
							textAlign: 'right',
						}),
						cellStyle: () => ({
							textAlign: 'right',
						}),
						render: rowData => <PopoverMenu
							renderOptions={() => renderOptionsMenu(rowData)}
						/>
					}
				]}
				data={pageData.assetTypesData}
				options={{
					selection: true,
				}}
				components={{
					Actions: props => renderTableActions(props, reload),
				}}
				localization={{
					toolbar: {
						nRowsSelected: '{0} asset type(s) selected',
					},
				}}
			/>

			{selectedAssetTypes && <CustomActionDialogBox
				title={selectedAssetTypes.length === 1 ? `Delete asset type?` : `Delete ${selectedAssetTypes.length} asset types?`}
				actionButton={<Button
					variant="contained"
					color={red}
					text="Delete"
					onClick={() => confirmDelete(selectedAssetTypes, reload)}
				/>}
				dialogCloseCallback={() => setSelectedAssetTypes(null)}
			>
				<DialogContentText>
					Are you sure you want to delete {selectedAssetTypes.length === 1 ? selectedAssetTypes[0].name : `the selected asset types`}?
				</DialogContentText>
			</CustomActionDialogBox>}
		</FixedWidthPage>}
	/>;
};
