import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';

import { ThingLoader, Button } from 'src/components';

import { ManageUserGroup } from './manageUserGroup';
import { AddAssetGroupsDialog } from './addAssetGroupsDialog';

import {
	Client as C,
	AssetGroupService,
	UserGroupService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

interface IPageData {
	userGroup: C.IUserGroupDto;
	userGroupAssetGroups: C.IUserGroupAssignedAssetGroupDto[];
	allAssetGroups: C.IAssetGroupDto[];
}

export const UserGroupAssets = observer((props: Props) => {
	const _assetGroupService = useInjection<AssetGroupService>(Service.AssetGroup);
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [selectedAssetGroups, setSelectedAssetGroups] = useState<Set<string>>(new Set<string>());
	const [addAssetGroupsDialogOpen, setAddAssetGroupsDialogOpen] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);

	const load = async (userGroupId: string): Promise<IPageData> => {
		const userGroup = await _userGroupService.getUserGroup(userGroupId);
		const userGroupAssetGroups = await _userGroupService.getAssetGroups(userGroupId);
		const allAssetGroups = await _assetGroupService.getAssetGroups(C.ListAssetGroupsType.Manage);

		return {
			userGroup: userGroup!,
			userGroupAssetGroups: userGroupAssetGroups.sort((a, b) => a.assetGroup.name.localeCompare(b.assetGroup.name, undefined, { numeric: true })),
			allAssetGroups: allAssetGroups.filter(x => x.client.clientId === userGroup!.client.clientId),
		};
	};

	const addAssetGroupsComplete = (reload: Function) => {
		setAddAssetGroupsDialogOpen(false);
		reload();
	};

	const toggleAssetGroup = (assetGroupId: string) => {
		const newSelectedAssetGroups = new Set(selectedAssetGroups);
		if (newSelectedAssetGroups.has(assetGroupId))
			newSelectedAssetGroups.delete(assetGroupId);
		else
			newSelectedAssetGroups.add(assetGroupId);

		setSelectedAssetGroups(newSelectedAssetGroups);
	};

	const removeAssetGroups = async (reload: Function) => {
		if (!confirm('Are you sure you want to remove the selected asset group(s) from this user group?'))
			return;

		setSaving(true);

		try {
			await _userGroupService.updateAssetGroups(props.match.params.id, {
				removeAssetGroupIds: Array.from(selectedAssetGroups),
			});

			reload();
			setSelectedAssetGroups(new Set<string>());
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to remove assets from group.');
		}

		setSaving(false);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(pageData: IPageData, reload: Function) => <ManageUserGroup
			userGroup={pageData.userGroup}
		>
			<div className="user-group-assets">
				Users in this group have access to all assets in the asset groups listed below.

				<div className="actions">
					<Button
						text="Remove"
						variant="outlined"
						disabled={selectedAssetGroups.size === 0 || saving}
						loading={saving}
						onClick={() => removeAssetGroups(reload)}
					/>
					<Button
						text="Add"
						color="primary"
						variant="contained"
						onClick={() => setAddAssetGroupsDialogOpen(true)}
					/>
				</div>

				<table className="card-table">
					<tbody>
						{pageData.userGroupAssetGroups!.map(x => <tr key={x.assetGroup.assetGroupId} className="content-box">
							<td>
								<Checkbox
									checked={selectedAssetGroups.has(x.assetGroup.assetGroupId)}
									onClick={() => toggleAssetGroup(x.assetGroup.assetGroupId)}
								/>
							</td>
							<td>{x.assetGroup.name}</td>
						</tr>)}
					</tbody>
				</table>

				{addAssetGroupsDialogOpen && <AddAssetGroupsDialog
					userGroupId={pageData.userGroup.userGroupId}
					assetGroups={pageData.allAssetGroups}
					existing={pageData.userGroupAssetGroups!.map(x => x.assetGroup)}
					complete={() => addAssetGroupsComplete(reload)}
					closeDialog={() => setAddAssetGroupsDialogOpen(false)}
				/>}
			</div>
		</ManageUserGroup>}
	/>;
});
