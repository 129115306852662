import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, FormikHelpers, FieldArray, ArrayHelpers } from 'formik';
import { withStyles, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage } from 'src/components';
import { AddEditFieldDialogBox } from './addEditFieldDialogBox';

import {
	Client as C,
	EnableFleetConfigurationService,
	HistoryService,
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

import './enableFleet.scss';

export interface IEnableFleetConfigurationField {
	enableFleetConfigurationFieldId?: string;
	fieldType: string;
	reference: string;
	label: string;
	readOnly: boolean;
	canBeBlank: boolean;
	stringMinChars?: number;
	stringMaxChars?: number;
	numberMinimum?: number;
	numberMaximum?: number;
	numberStep?: number;
	dropdownOptions?: string[];
}

interface IFormValues {
	configurationFields: IEnableFleetConfigurationField[];
}

export interface Props {
	configuration: C.IEnableFleetConfigurationDto;
	configurationFields: C.IEnableFleetConfigurationFieldDto[];
	customizationProfileFields: C.IEnableFleetCustomizationProfileFieldDto[];
}

const RedButton = withStyles((theme: Theme) => ({
	root: {
		color: theme.palette.getContrastText(red[500]),
		backgroundColor: red[500],
		'&:hover': {
			backgroundColor: red[700],
		},
	},
}))(Button);

export const EditEnableFleetConfigurationFieldsComponent = observer((props: Props) => {
	const _enableFleetConfigurationService = useInjection<EnableFleetConfigurationService>(Service.EnableFleetConfiguration);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [showAddFieldDialog, setShowAddFieldDialog] = useState<boolean>(false);
	const [editFieldIndex, setEditFieldIndex] = useState<number | null>(null);

	const initialFormValues: IFormValues = {
		configurationFields: props.configurationFields.map(x => ({
			enableFleetConfigurationFieldId: x.enableFleetConfigurationFieldId,
			reference: x.fieldReference,
			label: x.label,
			fieldType: x.fieldType,
			readOnly: x.readOnly,
			canBeBlank: x.canBeBlank,
			stringMinChars: x.stringMinChars || undefined,
			stringMaxChars: x.stringMaxChars || undefined,
			numberMinimum: x.numberMinimum || undefined,
			numberMaximum: x.numberMaximum || undefined,
			numberStep: x.numberStep || undefined,
			dropdownOptions: x.dropdownOptions || undefined,
		})),
	};

	const onSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
		const success = await update(values);
		if (!success)
			setSubmitting(false);
	};

	const update = async (values: IFormValues): Promise<boolean> => {
		const add: C.IAddEnableFleetField[] = values.configurationFields
			.filter(x => !x.enableFleetConfigurationFieldId)
			.map(x => ({
				fieldReference: x.reference,
				label: x.label,
				fieldType: x.fieldType as C.EnableFleetFieldType,
				readOnly: x.readOnly,
				canBeBlank: x.canBeBlank,
				stringMinChars: x.stringMinChars,
				stringMaxChars: x.stringMaxChars,
				numberMinimum: x.numberMinimum,
				numberMaximum: x.numberMaximum,
				numberStep: x.numberStep,
				dropdownOptions: x.dropdownOptions,
			}));

		const edit: C.IEditEnableFleetField[] = values.configurationFields
			.filter(x => !!x.enableFleetConfigurationFieldId)
			.map(x => ({
				enableFleetConfigurationFieldId: x.enableFleetConfigurationFieldId!,
				fieldReference: x.reference,
				label: x.label,
				fieldType: x.fieldType as C.EnableFleetFieldType,
				readOnly: x.readOnly,
				canBeBlank: x.canBeBlank,
				stringMinChars: x.stringMinChars,
				stringMaxChars: x.stringMaxChars,
				numberMinimum: x.numberMinimum,
				numberMaximum: x.numberMaximum,
				numberStep: x.numberStep,
				dropdownOptions: x.dropdownOptions,
			}));

		const remove: string[] = props.configurationFields
			.filter(x => !values.configurationFields.find(field => field.enableFleetConfigurationFieldId === x.enableFleetConfigurationFieldId))
			.map(x => x.enableFleetConfigurationFieldId);

		const request: C.IUpdateEnableFleetConfigurationFieldsRequest = {
			add,
			edit,
			remove,
		};

		try {
			await _enableFleetConfigurationService.updateEnableFleetConfigurationFields(props.configuration!.enableFleetConfigurationId, request);
			_toasterService.showSuccess('EnableFleet fields updated.');
			_historyService.history.push(`/app/enablefleet/list`);
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update EnableFleet fields.');
			return false;
		}
	};

	const addFieldFromWindow = (arrayHelpers: ArrayHelpers, field: IEnableFleetConfigurationField) => {
		arrayHelpers.push(field);
		setShowAddFieldDialog(false);
	};

	const editFieldFromWindow = (arrayHelpers: ArrayHelpers, field: IEnableFleetConfigurationField, index: number) => {
		arrayHelpers.replace(index, field);
		setEditFieldIndex(null);
	};

	const renderEditFieldButton = (formikProps: FormikProps<IFormValues>, index: number) => {
		return <Button
			variant="contained" color="primary"
			onClick={() => setEditFieldIndex(index)}
			startIcon={<EditIcon />}
		/>;
	};

	const removeField = (arrayHelpers: ArrayHelpers, index: number) => {
		if (!confirm('Are you sure you want to delete this field?'))
			return;

		arrayHelpers.remove(index);
	};

	const renderCurrentFields = (formikProps: FormikProps<IFormValues>) => {
		return <FieldArray
			name="configurationFields"
			render={(arrayHelpers: ArrayHelpers) => <table>
				<thead className="enable-fleet-head">
					<tr>
						<th>Field Reference</th>
						<th>Label</th>
						<th style={{ width: '1px' }}></th>
						<th style={{ width: '1px' }}></th>
					</tr>
				</thead>

				<tbody>
					{formikProps.values.configurationFields!.map((field, index) => (
						<tr className="column" key={index}>
							<td className="enable-fleet-body" scope="row">{field.reference}{!props.customizationProfileFields.find(x => x.reference === field.reference) && ' (Missing!)'}</td>
							<td className="enable-fleet-body">{field.label}</td>
							<td>{renderEditFieldButton(formikProps, index)}</td>
							<td><RedButton variant="contained" color="primary" startIcon={<ClearIcon />} onClick={() => removeField(arrayHelpers, index)} /></td>
						</tr>
					))}

					<tr>
						<td>
							<Button
								variant="contained"
								color="primary"
								onClick={() => setShowAddFieldDialog(true)}
								text="Add Field"
							/>
						</td>
					</tr>
				</tbody>

				{showAddFieldDialog && <AddEditFieldDialogBox
					title="Add Field"
					onSubmit={field => addFieldFromWindow(arrayHelpers, field)}
					configurationFields={formikProps.values.configurationFields}
					customizationProfileFields={props.customizationProfileFields}
					onClose={() => setShowAddFieldDialog(false)}
					buttonType="Add Field"
				/>}

				{editFieldIndex !== null && <AddEditFieldDialogBox
					title="Edit Field"
					onSubmit={field => editFieldFromWindow(arrayHelpers, field, editFieldIndex)}
					configurationFields={formikProps.values.configurationFields}
					customizationProfileFields={props.customizationProfileFields}
					onClose={() => setEditFieldIndex(null)}
					buttonType="Edit Field"
					field={formikProps.values.configurationFields![editFieldIndex]}
					editFieldIndex={editFieldIndex}
				/>}
			</table>}
		/>;
	};

	return <FixedWidthPage
		className="form-page"
		headingText="Edit EnableFleet Fields"
		subheadingText={props.configuration?.name}
	>
		<Formik
			initialValues={initialFormValues}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<IFormValues>) => <Form
				className="formik-form"
			>
				{renderCurrentFields(formikProps)}

				<Button
					type="submit"
					variant="contained"
					color="primary"
					loading={formikProps.isSubmitting}
					startIcon={<SaveIcon />}
					text="Save Changes"
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
