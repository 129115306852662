import React from 'react';
import Card from '@material-ui/core/Card';
import { css } from '@emotion/css';
import * as moment from 'moment-timezone';

import { longDateTimeFormat } from 'src/util/dateTimeFormats';

interface ISafetyTimerEmergencyInfoProps {
	safetyTimer: ISafetyTimer;
	timeZone: string;
}

export interface ISafetyTimer {
	startTimestamp: moment.Moment;
	details: string | null | undefined;
}

const cardStyle = css`
	padding: 20px;
`;

export const SafetyTimerEmergencyInfo = (props: ISafetyTimerEmergencyInfoProps) => <Card
	className={cardStyle}
>
	<h2>Safety Timer</h2>

	<table>
		<tbody>
			<tr>
				<td>Started</td>
				<td>{props.safetyTimer.startTimestamp.tz(props.timeZone).format(longDateTimeFormat)}</td>
			</tr>

			{props.safetyTimer.details && <tr>
				<td>Details</td>
				<td>{props.safetyTimer.details}</td>
			</tr>}
		</tbody>
	</table>
</Card>;
