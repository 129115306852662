import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { Service } from 'src/services/service';
import { ToasterService } from 'src/services/toasterService';
import { UsersService } from 'src/services/usersService';
import { useInjection } from 'src/services';
import { IOption } from 'src/util';

export interface IUserSelectorFormValues {
	identityIds: string[];
}

export const useUserSelectorService = () => {
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _usersService = useInjection<UsersService>(Service.Users);

	const [identityOptions, setIdentityOptions] = useState<IOption<string>[] | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useAsyncEffect(async () => {
		try {
			const users = await _usersService.getAllUsers();
			if (users) {
				setIdentityOptions(users.map(x => ({ label: x.name, value: x.identityId })));
			}
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to load users.');
		}

		setLoading(false);
	}, []);

	return { identityOptions, loading };
};
