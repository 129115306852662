import React from 'react';
import { match } from 'react-router-dom';
import moment from 'moment-timezone';
import styled from '@emotion/styled';
import { QueryReport_reportById } from 'src/graphql/__generated__/queries/queryReport';

import {
	AuthenticationService,
	Client as C,
	ReportService,
	Service,
	useInjection,
} from 'src/services';

import { FixedWidthPage, ThingLoader, ReportParametersDisplay } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';

import './reportViewer.scss';

const UserSection = styled.div`
	margin-bottom: 25px;

	h2 {
		font-size: 18px;
	}
`;

export interface Props {
	match: match<{ id: string }>;
}

interface ReportViewData {
	report: QueryReport_reportById;
	reportData: C.IUserAuthAuditReportData;
}

export const ViewUserAuthAuditReport = (props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _reportService = useInjection<ReportService>(Service.Report);

	const load = async (reportId: string): Promise<ReportViewData> => {
		const report = _reportService.getReportById(reportId);
		const reportData = _reportService.getUserAuthAuditReportData(reportId);

		return {
			report: await report,
			reportData: await reportData,
		};
	};

	const renderUser = (user: C.IUserAuthAuditUser) => {
		return <UserSection>
			<h2>{user.userName}</h2>
			<p>
				Last active: {user.timestampLastActive
					? moment.tz(user.timestampLastActive, _authService.currentAuth.user.timeZone).format(longDateTimeFormat)
					: 'Unknown'}
			</p>

			{renderActions(user)}
		</UserSection>;
	};

	const renderActions = (user: C.IUserAuthAuditUser) => {
		if (!user.actions || user.actions.length === 0)
			return null;

		return <table className="card-table">
			<thead>
				<tr>
					<th>Timestamp</th>
					<th>Description</th>
				</tr>
			</thead>

			<tbody>
				{user.actions.map(x => renderAction(user, x))}
			</tbody>
		</table>;
	};

	const renderAction = (user: C.IUserAuthAuditUser, action: C.IUserAuthAuditUserAction) => {
		if (!action.timestamp || !action.type)
			return;

		const description = getActionDescription(action);
		if (!description)
			return;

		return <tr
			key={`${user.identityId!}-${action.timestamp}`}
			className="content-box"
		>
			<td>{moment.tz(action.timestamp, _authService.currentAuth.user.timeZone).format(longDateTimeFormat)}</td>
			<td>{description}</td>
		</tr>;
	};

	const getActionDescription = (action: C.IUserAuthAuditUserAction): string | null => {
		switch (action.type!) {
			case C.AuthLogType.UserSignIn:
				return 'Signed in.';

			case C.AuthLogType.UserSignOut:
				return 'Signed out.';

			case C.AuthLogType.UserSignInAs:
				if (action.signInAsUserName)
					return `Signed in as ${action.signInAsUserName}.`;
				else
					return 'Signed in as another user.';

			case C.AuthLogType.UserSignInAsReturn:
				if (action.signInAsUserName)
					return `Returned to their own account (previously signed in as ${action.signInAsUserName}).`;
				else
					return 'Returned to their own account (previously signed in as another user).';
		}

		return null;
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(reportViewData: ReportViewData) => <FixedWidthPage
			headingText="User Auth Audit Report"
			subheadingText={reportViewData.report.name}
			noContentBackground
			contentClassName="report-viewer"
		>
			{reportViewData.report.parameters && <ReportParametersDisplay
				reportParameters={reportViewData.report.parameters}
			/>}

			<div>
				{reportViewData.reportData.userAuthAuditUsers?.map(renderUser)}
			</div>
		</FixedWidthPage>}
	/>;
};
