import { isEqual } from 'lodash';
import moment from 'moment-timezone';

export function undefinedIfUnchanged<T>(oldValue: any, newValue: T | undefined, handleChange: boolean = true): T | undefined {
	if (!handleChange)
		return undefined;

	if (oldValue == null && newValue == null)
		return undefined;

	if (oldValue === newValue)
		return undefined;

	return newValue;
}

export function undefinedIfUnchangedArray<T>(oldValue: Array<T> | undefined | null, newValue: Array<T> | undefined | null): Array<T> | undefined {
	if (!oldValue && !newValue || !newValue)
		return undefined;

	if (isEqual(oldValue, newValue))
		return undefined;

	return newValue;
}

export function undefinedIfUnchangedInstant<T>(oldValue: any, newValue: T | undefined): T | undefined {
	const exactlyTheSame = undefinedIfUnchanged(oldValue, newValue) === undefined;
	if (exactlyTheSame)
		return undefined;

	if (oldValue && newValue) {
		if (moment(oldValue).isSame(newValue))
			return undefined;
	}

	return newValue;
}
