import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { red } from '@material-ui/core/colors';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Button } from './button';
import { CustomActionDialogBox } from './customActionDialogBox';
import { Service, ToasterService, useInjection } from 'src/services';

import { useMutationDeleteDevices } from 'src/graphql/__generated__/mutations/mutationDeleteDevices';

interface Props {
	deviceIds: string[];
	close: () => void;
	onSuccess?: () => Promise<any>;
}

export const DeleteDevicesDialog = observer((props: Props) => {
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [acknowledgedWarning, setAcknowledgedWarning] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [deleteDevices] = useMutationDeleteDevices();

	const submit = async () => {
		setLoading(true);

		try {
			await deleteDevices({
				variables: {
					input: {
						ids: props.deviceIds,
					},
				},
			});

			props.onSuccess && await props.onSuccess();
			props.close();
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to delete device(s).');
			setLoading(false);
		}
	};

	return <CustomActionDialogBox
		title={props.deviceIds.length === 1 ? `Delete Device?` : `Delete ${props.deviceIds.length} Devices?`}
		actionButton={<Button
			variant="contained"
			color={red}
			text="Delete"
			onClick={submit}
			disabled={!acknowledgedWarning}
			loading={loading}
		/>}
		dialogCloseCallback={props.close}
	>
			<DialogContentText>
				If deleted:
			</DialogContentText>

			<DialogContentText component="ul">
				<li>Any physical device(s) (e.g. mobile phones) linked to the selected device(s) will be unlinked.</li>
				<li>The selected device(s) will not be able to record any new information <strong>(including locations and emergencies)</strong>.</li>
			</DialogContentText>

			<DialogContentText><strong>This cannot be undone.</strong></DialogContentText>

			<FormControlLabel
				control={<Checkbox
					onChange={(_, checked) => setAcknowledgedWarning(checked)}
					checked={acknowledgedWarning}
					color="primary"
					disabled={loading}
				/>}
				label="I acknowledge that I have read and understand the above information."
			/>
	</CustomActionDialogBox>;
});
