import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import GroupIcon from '@material-ui/icons/Group';

import { FixedWidthPage, ThingLoader, Button } from 'src/components';

import { EditUserDetailsComponent } from './editUserDetailsComponent';

import {
	AuthenticationService,
	Client as C,
	UsersService,
	HistoryService,
	Service,
	useInjection,
} from 'src/services';

export interface EditUserProps {
	match: match<{ id: string }>;
}

export const EditUser = observer((props: EditUserProps) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _userService = useInjection<UsersService>(Service.Users);
	const _historyService = useInjection<HistoryService>(Service.History);

	const loadUser = async (userId: string) => {
		return await _userService.getUser(userId);
	};

	const onEditSuccess = () => {
		_historyService.history.push('/app/users/list');
	};

	return <ThingLoader
		id={props.match.params.id}
		load={loadUser}
		render={(user: C.IUserDto) => {
			const actions = user.identity.type === C.IdentityType.Client && _authService.currentAuth.permissions.general.manageUsers ? <>
				<Button href={`/app/users/${user.userId}/groups`} startIcon={<GroupIcon />} text="Assign groups" variant="outlined" color="primary" />
			</> : null;

			return <FixedWidthPage
				className="form-page"
				headingText="Edit User"
				subheadingText={user.name}
				headingActions={actions}
			>
				<EditUserDetailsComponent
					user={user}
					onEditSuccess={onEditSuccess}
				/>
			</FixedWidthPage>;
		}}
	/>;
});
