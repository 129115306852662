import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryManageBeaconSet($bluetoothBeaconSetId: UUID!, $includeDealer: Boolean!) {
		bluetoothBeaconSet: bluetoothBeaconSetById(id: $bluetoothBeaconSetId) {
			id
			name
			dealer @include(if: $includeDealer) {
				id
			}
			bluetoothBeacons {
				id
				name
			}
		}

		bluetoothBeacons {
			id
			name
			dealer @include(if: $includeDealer) {
				id
			}
		}
	}
`;
