import React from 'react';
import Card from '@material-ui/core/Card';
import { css } from '@emotion/css';
import * as moment from 'moment-timezone';

import { longDateTimeFormat } from 'src/util/dateTimeFormats';
import { IEmergency } from './emergencyViewer';
import { getEventTime } from 'src/util/eventHelpers';

import { EmergencyStatus } from 'src/../__generated__/globalTypes';
import { getPrettyName } from 'src/util';

interface IEmergencyInfoProps {
	emergency: IEmergency;
	timeZone: string;
}

const cardStyle = css`
	padding: 20px;
`;

const renderStatus = (emergency: IEmergency, timeZone: string) => {
	if (emergency.status === EmergencyStatus.NEW)
		return 'New';

	if (emergency.status === EmergencyStatus.RESOLVED) {
		const actions = emergency.actions;
		if (actions == null || actions.length === 0)
			return 'Resolved';

		const action = actions[actions.length - 1];
		return `Resolved by ${action.performedByUserName} at ${moment.tz(action.performedAt, timeZone).format(longDateTimeFormat)}`;
	}

	return 'Unknown';
};

export const EmergencyInfo = (props: IEmergencyInfoProps) => <Card
	className={cardStyle}
>
	<h2>Details</h2>

	<table>
		<tbody>
			<tr>
				<td>Asset</td>
				<td>{props.emergency.asset.name}</td>
			</tr>

			<tr>
				<td>Alert Type</td>
				<td>{getPrettyName(props.emergency.type)}</td>
			</tr>

			<tr>
				<td>Triggered at</td>
				<td>{moment(getEventTime(props.emergency)).tz(props.timeZone).format(longDateTimeFormat)}</td>
			</tr>

			{props.emergency.ioConfiguration && <tr>
				<td>IO configuration</td>
				<td>{props.emergency.ioConfiguration.name}</td>
			</tr>}

			{props.emergency.ioConfiguration && <tr>
				<td>Device</td>
				<td>{props.emergency.ioConfiguration.deviceName}</td>
			</tr>}

			<tr>
				<td>Status</td>
				<td>{renderStatus(props.emergency, props.timeZone)}</td>
			</tr>
			{props.emergency.asset.emergencyNotes && <tr>
				<td valign="top">Notes</td>
				<td style={{ whiteSpace: 'pre-line', wordBreak: 'break-all', wordWrap: 'break-word' }}>
					{props.emergency.asset.emergencyNotes}
				</td>
			</tr>}
		</tbody>
	</table>
</Card>;
