import React from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import { FormikSelect, FormikTextField } from 'src/components';

import { TeltonikaDeviceModelType } from 'src/../__generated__/globalTypes';

const teltonikaModelTypeOptions = [
	{ value: TeltonikaDeviceModelType.FMC130, label: 'FMC130' },
	{ value: TeltonikaDeviceModelType.FMC230, label: 'FMC230' },
	{ value: TeltonikaDeviceModelType.FMC640, label: 'FMC640' },
];

interface ITeltonikaDeviceFormValues {
	teltonikaModelType: TeltonikaDeviceModelType | null;
	imei: string;
	phoneNumber: string;
}

interface IProps<T> {
	form: FormikProps<T>;
}

export const ManageTeltonikaDeviceComponent = observer(<T extends ITeltonikaDeviceFormValues>(props: IProps<T>) => {
	return <>
		<FormikSelect
			name="teltonikaModelType"
			label="Model Type"
			form={props.form}
			options={teltonikaModelTypeOptions}
			getOptionValue={x => x.value}
			getOptionLabel={x => x.label}
			required
		/>

		<Field
			name="imei"
			label="IMEI"
			type="text"
			component={FormikTextField}
			required
		/>

		<Field
			name="phoneNumber"
			label="Phone Number"
			type="text"
			component={FormikTextField}
		/>
	</>;
});
