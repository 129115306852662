import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Checkbox from '@material-ui/core/Checkbox';
import { match } from 'react-router-dom';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import useAsyncEffect from 'use-async-effect';

import { FixedWidthPage, MessagePage, Button } from 'src/components';

import { AddAssetsDialog, IAsset } from './addAssetsDialog';
import { AddGeofencesDialog } from './addGeofencesDialog';

import { executeQueryManageAssetGroup } from 'src/graphql/__generated__/queries/queryManageAssetGroup';

import {
	Client as C,
	AssetGroupService,
	AuthenticationService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

interface Props {
	match: match<{ id: string }>;
}

import './manageAssetGroup.scss';

export const ManageAssetGroup = observer((props: Props) => {
	const _assetGroupService = useInjection<AssetGroupService>(Service.AssetGroup);
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _apolloClient = useInjection<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [loading, setLoading] = useState<boolean>(true);
	const [group, setGroup] = useState<C.IAssetGroupDto | null>(null);
	const [saving, setSaving] = useState<boolean>(false);

	const [assets, setAssets] = useState<IAsset[] | null>(null);
	const [groupAssets, setGroupAssets] = useState<IAsset[] | null>(null);
	const [selectedAssets, setSelectedAssets] = useState<Set<string>>(new Set<string>());

	const [geofences, setGeofences] = useState<C.IGeofenceDto[] | null>(null);
	const [selectedGeofences, setSelectedGeofences] = useState<Set<string>>(new Set<string>());

	const [addAssetsDialogOpen, setAddAssetsDialogOpen] = useState<boolean>(false);
	const [addGeofencesDialogOpen, setAddGeofencesDialogOpen] = useState<boolean>(false);

	useAsyncEffect(async () => {
		const query = await executeQueryManageAssetGroup(_apolloClient, { includeClient: _authService.currentAuth.user.identity.type !== C.IdentityType.Client });
		if (query.error || !query.data || !query.data.assets)
			throw 'Failed to load.';

		const group = await _assetGroupService.getAssetGroup(props.match.params.id);
		const groupAssets = await _assetGroupService.getAssetGroupAssets(props.match.params.id);
		const geofences = await _assetGroupService.getAssetGroupGeofences(props.match.params.id);

		setGroup(group);
		setAssets(query.data.assets.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })));
		setGroupAssets(groupAssets.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })));
		setGeofences(geofences.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })));
		setLoading(false);
	}, []);

	const toggleAsset = (assetId: string) => {
		const newSelectedAssets = new Set(selectedAssets);
		if (newSelectedAssets.has(assetId))
			newSelectedAssets.delete(assetId);
		else
			newSelectedAssets.add(assetId);

		setSelectedAssets(newSelectedAssets);
	};

	const toggleGeofence = (geofenceId: string) => {
		const newSelectedGeofences = new Set(selectedGeofences);
		if (newSelectedGeofences.has(geofenceId))
			newSelectedGeofences.delete(geofenceId);
		else
			newSelectedGeofences.add(geofenceId);

		setSelectedGeofences(newSelectedGeofences);
	};

	const removeAssets = async () => {
		if (!confirm(`Are you sure you want to remove the selected asset(s) from this group?`))
			return;

		setSaving(true);

		try {
			await _assetGroupService.removeAssetGroupAssets(props.match.params.id, {
				assetIds: Array.from(selectedAssets),
			});

			setGroupAssets(groupAssets!.filter(x => !selectedAssets.has(x.assetId)));
			setSelectedAssets(new Set<string>());
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to remove assets from group.');
		}

		setSaving(false);
	};

	const removeGeofences = async () => {
		if (!confirm(`Are you sure you want to remove the selected geofence(s) from this group?`))
			return;

		setSaving(true);

		try {
			await _assetGroupService.removeAssetGroupGeofences(props.match.params.id, {
				geofenceIds: Array.from(selectedGeofences),
			});

			setGeofences(geofences!.filter(x => !selectedGeofences.has(x.geofenceId)));
			setSelectedGeofences(new Set<string>());
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to remove geofences from group.');
		}

		setSaving(false);
	};

	const addAssetsComplete = (addedAssets: C.IAssetDto[]) => {
		let newGroupAssets = [ ...groupAssets! ];
		for (const asset of addedAssets) {
			if (!newGroupAssets.find(x => x.assetId === asset.assetId))
				newGroupAssets.push(asset);
		}

		newGroupAssets = newGroupAssets.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

		setGroupAssets(newGroupAssets);
		setAddAssetsDialogOpen(false);
	};

	const addGeofencesComplete = (addedGeofences: C.IGeofenceDto[]) => {
		let newGeofences = [ ...geofences! ];
		for (const geofence of addedGeofences) {
			if (!newGeofences.find(x => x.geofenceId === geofence.geofenceId))
				newGeofences.push(geofence);
		}

		newGeofences = newGeofences.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

		setGeofences(newGeofences);
		setAddGeofencesDialogOpen(false);
	};

	const renderAssetsBlock = () => {
		return <div className="manage-asset-group__item-block">
			<div className="manage-asset-group__item-block__heading">
				<h2>Assets</h2>

				{group!.type === C.AssetGroupType.UserCreated && <>
					<Button
						text="Remove"
						variant="outlined"
						disabled={selectedAssets.size === 0 || saving}
						loading={saving}
						onClick={removeAssets}
					/>

					<Button
						text="Add"
						variant="outlined"
						disabled={saving}
						onClick={() => setAddAssetsDialogOpen(true)}
					/>
				</>}

				{group!.type !== C.AssetGroupType.UserCreated && <>
					Assignment of assets in this group is managed automatically.
				</>}
			</div>

			{groupAssets!.map(asset => <div
				key={asset.assetId}
				className="manage-asset-group__item-block__item content-box"
			>
				<Checkbox
					checked={selectedAssets.has(asset.assetId)}
					onClick={x => toggleAsset(asset.assetId)}
					disabled={group!.type !== C.AssetGroupType.UserCreated}
				/>

				{asset.name}
			</div>)}

			{groupAssets!.length === 0 && <div
				className="manage-asset-group__item-block__no-items content-box"
			>
				No assets in this group.
			</div>}
		</div>;
	};

	const renderGeofencesBlock = () => {
		return <div className="manage-asset-group__item-block">
			<div className="manage-asset-group__item-block__heading">
				<h2>Geofences</h2>

				<Button
					text="Remove"
					variant="outlined"
					disabled={selectedGeofences.size === 0 || saving}
					loading={saving}
					onClick={removeGeofences}
				/>

				<Button
					text="Add"
					variant="outlined"
					disabled={saving}
					onClick={() => setAddGeofencesDialogOpen(true)}
				/>
			</div>

			{geofences!.map(geofence => <div
				key={geofence.geofenceId}
				className="manage-asset-group__item-block__item content-box"
			>
				<Checkbox
					checked={selectedGeofences.has(geofence.geofenceId)}
					onClick={x => toggleGeofence(geofence.geofenceId)}
				/>

				{geofence.name}
			</div>)}

			{geofences!.length === 0 && <div
				className="manage-asset-group__item-block__no-items content-box"
			>
				No geofences in this group.
			</div>}
		</div>;
	};

	if (loading)
		return <MessagePage loading />;

	return <FixedWidthPage
		headingText={group!.name}
		noContentBackground
		contentClassName="manage-asset-group"
	>
		{addAssetsDialogOpen && <AddAssetsDialog
			assetGroup={group!}
			assets={assets!}
			existing={groupAssets!}
			complete={addAssetsComplete}
			closeDialog={() => setAddAssetsDialogOpen(false)}
		/>}

		{addGeofencesDialogOpen && <AddGeofencesDialog
			assetGroup={group!}
			existing={geofences!}
			complete={addGeofencesComplete}
			closeDialog={() => setAddGeofencesDialogOpen(false)}
		/>}

		{renderAssetsBlock()}
		{renderGeofencesBlock()}
	</FixedWidthPage>;
});
