import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateCamperVanDevice($input: UpdateCamperVanDeviceInput!) {
		updateCamperVanDevice(input: $input) {
			camperVanDevice {
				id
			}
		}
	}
`;
