import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateAsset($input: UpdateAssetInput!) {
		updateAsset(input: $input) {
			asset {
				id
			}
		}
	}
`;
