import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class GeofenceService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
	) {

	}

	async addGeofence(request: C.IAddGeofenceRequest): Promise<C.IGeofenceDto> {
		return this.client.addGeofence(new C.AddGeofenceRequest(request));
	}

	async updateGeofence(geofenceId: string, request: C.IUpdateGeofenceRequest): Promise<C.IGeofenceDto> {
		return this.client.updateGeofenceById(geofenceId, new C.UpdateGeofenceRequest(request));
	}

	async getGeofences(): Promise<C.IGeofenceDto[]> {
		return this.client.listGeofences();
	}

	async deleteGeofence(geofenceId: string): Promise<void> {
		return this.client.deleteGeofenceById(geofenceId);
	}

	async addGeofenceType(request: C.IAddGeofenceTypeRequest): Promise<C.IGeofenceTypeDto> {
		return this.client.addGeofenceType(new C.AddGeofenceTypeRequest(request));
	}

	async updateGeofenceType(geofenceTypeId: string, request: C.IUpdateGeofenceTypeRequest): Promise<C.IGeofenceTypeDto> {
		return this.client.updateGeofenceTypeById(geofenceTypeId, new C.UpdateGeofenceTypeRequest(request));
	}

	async getGeofenceType(geofenceTypeId: string): Promise<C.IGeofenceTypeDto> {
		return this.client.getGeofenceTypeById(geofenceTypeId);
	}

	async getGeofenceTypes(): Promise<C.IGeofenceTypeDto[]> {
		return this.client.listGeofenceTypes();
	}

	async updateGeofenceTypeAlerts(geofenceTypeId: string, request: C.IGeofenceTypeAlertChangeRequest) {
		return this.client.updateGeofenceTypeAlerts(geofenceTypeId, new C.GeofenceTypeAlertChangeRequest(request));
	}
}
