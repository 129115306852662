import { gql } from '@apollo/client';

export const CUSTOM_MAP_MARKER_FIELDS = gql`
	fragment CustomMapMarkerFields on CustomMapMarker {
		id
		name
		details
		latitude
		longitude
		floorPlan {
			id
		}
		backgroundShape
		backgroundShapeColor
		icon
		iconColor
		size
		createdTimestamp
	}
`;
