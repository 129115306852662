import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateAssetServiceReminderType($input: UpdateAssetServiceReminderTypeInput!) {
		updateAssetServiceReminderType(input: $input) {
			assetServiceReminderType {
				id
				name
			}
		}
	}
`;
