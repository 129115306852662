import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

import { renderMapMarkerIcon } from './assetTypesHelpers';
import { Client as C } from 'src/services';

interface IIconStyleProps {
	useCustomIcon: boolean;
	iconShape: C.MapMarkerIcon;
	iconColor?: string | null;
	backgroundColor: string;
	iconSize?: number | null;
	backgroundSize: number;
}

const useStyles = makeStyles({
	background: (props: { backgroundSize: number }) => ({
		width: `${props.backgroundSize}px`,
		height: `${props.backgroundSize}px`,
		position: 'relative',
	}),
});

function iconContainerRefChanged(element: HTMLDivElement | null, props: IIconStyleProps) {
	if (element) {
		// check if map marker exists, if it does remove it and replace it.
		const oldMarkers = element.getElementsByClassName('connect-map-marker__content');
		if (oldMarkers.length > 0) {
			for (let i = 0; i < oldMarkers.length; i++) {
				element.removeChild(oldMarkers[i]);
			}
		}

		const mapMarkerContent = document.createElement('div');

		// Show the custom icon or the default
		if (props.useCustomIcon) {
			mapMarkerContent.style.backgroundColor = props.backgroundColor;
			renderMapMarkerIcon(mapMarkerContent, props.iconShape!, props.iconSize, props.iconColor);
		}

		mapMarkerContent.className = 'connect-map-marker__content';
		element!.appendChild(mapMarkerContent);
	}
}

export const IconPreview = (props: IIconStyleProps) => {
	const backgroundSize = props.useCustomIcon ? props.backgroundSize : 19;
	const classes = useStyles({ backgroundSize });

	return <div
		className={classNames('connect-map-marker', classes.background)}
		ref={(divElement: HTMLDivElement) => iconContainerRefChanged(divElement, props)}
	/>;
};
