import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryEditBeacon($id: UUID!, $isSuperUser: Boolean!) {
		dealers @include(if: $isSuperUser) {
			id
			name
		}

		beacon: bluetoothBeaconById(id: $id) {
			id
			name
			serialNumber
			major
			minor
			transmitPower
			beaconType
			disabled
			floorPlan {
				name
			}
			dealer @include(if: $isSuperUser) {
				id
			}
		}
	}
`;
