import React from 'react';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
	IAssetGroupSelectorFormValues,
} from 'src/services';

import { FormikSelect } from 'src/components';
import { IOption } from 'src/util';

interface Props {
	options: IOption<string>[] | null;
	formikProps: FormikProps<IAssetGroupSelectorFormValues>;
	isLoading?: boolean;
	className?: string;
}

export const AssetGroupSelector = observer((props: Props) => {
	if (!props.options)
		return null;

	return <FormikSelect
		name="assetGroups"
		placeholder="Select Asset Groups"
		label="Asset Groups"
		form={props.formikProps}
		isLoading={props.isLoading}
		multi
		className={props.className}
		options={props.options.slice()}
		getOptionLabel={option => option.label}
		getOptionValue={option => option.value}
		disableCloseOnSelect
	/>;
});
