import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import {
	Client as C,
	FloorPlansService,
	HistoryService,
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

import { Button, FixedWidthPage, Georectifier, ImageCoordinates, LevelSelector } from 'src/components';

import './manageFloorPlanComponent.scss';

export interface ManageFloorPlanProps {
	floorPlanGroupId: string;
	floorPlan?: C.IFloorPlanDto | null;
}

interface IImage {
	name?: string;
	width: number;
	height: number;
	bytes?: string;
	url: string;
}

export const ManageFloorPlanComponent = observer((props: ManageFloorPlanProps) => {
	const _history = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _floorPlans = useInjection<FloorPlansService>(Service.FloorPlans);

	const [name, setName] = useState<string>(props.floorPlan?.name ?? '');
	const [levelIndex, setLevelIndex] = useState<number>(props.floorPlan?.levelIndex ?? 0);
	const [numberingStyle, setNumberingStyle] = useState<C.FloorNumberingStyle>(props.floorPlan?.numberingStyle ?? C.FloorNumberingStyle.British);
	const [uploadingImage, setUploadingImage] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);

	const [coordinates, setCoordinates] = useState<ImageCoordinates | null>(props.floorPlan ? {
		topLeft: props.floorPlan.topLeft,
		topRight: props.floorPlan.topRight,
		bottomLeft: props.floorPlan.bottomLeft,
		bottomRight: props.floorPlan.bottomRight,
	} : null);

	const [image, setImage] = useState<IImage | null>(props.floorPlan ? {
		width: props.floorPlan.imageWidth,
		height: props.floorPlan.imageHeight,
		url: props.floorPlan.imageUrl,
	} : null);

	const validateFloorPlan = () => {
		if (!name.trim()) {
			_toasterService.showDanger('Please enter a name.');
			return false;
		}

		return true;
	};

	const createFloorPlan = async () => {
		if (!coordinates || !validateFloorPlan())
			return;

		const minLongitude = Math.min(coordinates.topLeft.longitude, coordinates.topRight.longitude, coordinates.bottomLeft.longitude, coordinates.bottomRight.longitude);
		const maxLongitude = Math.max(coordinates.topLeft.longitude, coordinates.topRight.longitude, coordinates.bottomLeft.longitude, coordinates.bottomRight.longitude);

		if (minLongitude < -180 || maxLongitude > 180) {
			_toasterService.showDanger('Floor plan cannot cross the 180th meridian (180° east or west).');
			return;
		}

		setSaving(true);

		const result = await _floorPlans.addFloorPlan({
			parentGroupId: props.floorPlanGroupId!,
			name: name,
			levelIndex: levelIndex,
			numberingStyle: numberingStyle,
			topLeft: coordinates.topLeft,
			topRight: coordinates.topRight,
			bottomLeft: coordinates.bottomLeft,
			bottomRight: coordinates.bottomRight,
			imageBytes: image!.bytes!,
		});

		if (result) {
			_toasterService.showSuccess('Floor plan added.');
			_history.history.push(`/app/floorplans/${result.collectionId}/list`);
		} else {
			setSaving(false);
		}
	};

	const updateFloorPlan = async () => {
		if (uploadingImage) {
			if (!coordinates)
				return;

			const minLongitude = Math.min(coordinates.topLeft.longitude, coordinates.topRight.longitude, coordinates.bottomLeft.longitude, coordinates.bottomRight.longitude);
			const maxLongitude = Math.max(coordinates.topLeft.longitude, coordinates.topRight.longitude, coordinates.bottomLeft.longitude, coordinates.bottomRight.longitude);

			if (minLongitude < -180 || maxLongitude > 180) {
				_toasterService.showDanger('Floor plan cannot cross the 180th meridian (180° east or west).');
				return;
			}
		}

		if (!validateFloorPlan())
			return;

			setSaving(true);

		try {
			const request: C.IUpdateFloorPlanRequest = {
				name: name,
				levelIndex: levelIndex,
				numberingStyle: numberingStyle,
			};

			if (uploadingImage) {
				request.topLeft = coordinates!.topLeft;
				request.topRight = coordinates!.topRight;
				request.bottomLeft = coordinates!.bottomLeft;
				request.bottomRight = coordinates!.bottomRight;
				request.imageBytes = image!.bytes!;
			}

			await _floorPlans.updateFloorPlan(props.floorPlan!.floorPlanId, request);

			_toasterService.showSuccess('Floor plan updated.');
			_history.history.push(`/app/floorPlans/${props.floorPlan!.collectionId}/list`);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update floor plan.');
			setSaving(false);
		}
	};

	const fileSelected = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const files = evt.target.files;

		if (files) {
			const file = files[0];
			if (file)
				validateImage(file);
		}
	};

	const validateImage = (file: File) => {
		const image = new Image();

		image.onload = () => handleImage(file, image.width, image.height);
		image.onerror = () => _toasterService.showDanger('Cannot read file, please check it is a valid image.');

		const url = window.URL || (window as any).webkitURL;

		// If this doesn't throw an error, the file is a valid image.
		image.src = url.createObjectURL(file);

		setUploadingImage(true);
	};

	const handleImage = (file: File, imageWidth: number, imageHeight: number) => {
		const reader = new FileReader();

		reader.onload = () => {
			const resultString = reader.result as string;
			const base64 = resultString.substring(resultString.indexOf(',') + 1);

			setImage({
				name: file.name,
				width: imageWidth,
				height: imageHeight,
				bytes: base64,
				url: `data:${file.type};base64,${base64}`,
			});
		};

		reader.onerror = () => _toasterService.showDanger('Cannot read image file, please try again.');

		reader.readAsDataURL(file);
	};

	return <FixedWidthPage
		className="manage-floorplan"
		noContentBackground
		headingText={props.floorPlan ? 'Edit Floor Plan' : 'Add Floor Plan'}
	>
		<div className="content-box floorplan-properties">
			<TextField
				label="Name"
				type="text"
				value={name}
				onChange={e => setName(e.target.value)}
				variant="filled"
			/>

			<LevelSelector
				levelIndex={levelIndex}
				numberingStyle={numberingStyle}
				onLevelChange={setLevelIndex}
				onNumberingStyleChange={setNumberingStyle}
			/>

			<TextField
				label="Image"
				type="file"
				onChange={fileSelected}
				inputProps={{ accept: '.png,.jpg,.jpeg' }}
				variant="filled"
				InputLabelProps={{
					shrink: true,
				}}
			/>
		</div>

		{image && <Georectifier
			image={image}
			disabled={props.floorPlan != null && !uploadingImage}
			floorPlanLocationChanged={setCoordinates}
			floorPlan={props.floorPlan}
			uploadingImage={uploadingImage}
		/>}

		<div>
			{props.floorPlan && <Button
				startIcon={<SaveIcon />}
				text="Save Changes"
				className="fill"
				variant="contained"
				color="primary"
				onClick={updateFloorPlan}
				loading={saving}
			/>}

			{!props.floorPlan && <Button
				startIcon={<AddIcon />}
				text="Create Floor Plan"
				className="fill"
				variant="contained"
				color="primary"
				onClick={createFloorPlan}
				loading={saving}
			/>}
		</div>
	</FixedWidthPage>;
});
