import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';

import { Button, FixedWidthPage, FormikTextField } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	Client as C,
	HistoryService,
	ToasterService,
	Service,
	EnableFleetConfigurationService,
	useInjection,
} from 'src/services';

interface IFormValues {
	name: string;
	managementApiUrl: string;
	managementApiUsername: string;
	managementApiPassword: string;
}

export interface ExternalSiteProps {
	configuration?: C.IEnableFleetConfigurationDto | null;
}

export const ManageEnableFleetConfigurationComponent = observer((props: ExternalSiteProps) => {
	const _historyService = useInjection<HistoryService>(Service.History);
	const _enableFleetConfigurationService = useInjection<EnableFleetConfigurationService>(Service.EnableFleetConfiguration);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const addConfiguration = async (values: IFormValues): Promise<boolean> => {
		const request: C.IAddEnableFleetConfigurationRequest = {
			name: values.name,
			managementApiUrl: values.managementApiUrl,
			managementApiUsername: values.managementApiUsername,
			managementApiPassword: values.managementApiPassword,
		};

		try {
			await _enableFleetConfigurationService.addEnableFleetConfiguration(request);
			_toasterService.showSuccess('EnableFleet configuration added.');
			_historyService.history.push('/app/enablefleet/list');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to add EnableFleet configuration.');
			return false;
		}
	};

	const updateConfiguration = async (values: IFormValues): Promise<boolean> => {
		const request: C.IUpdateEnableFleetConfigurationRequest = {
			name: values.name,
			managementApiUrl: values.managementApiUrl,
			managementApiUsername: values.managementApiUsername,
			managementApiPassword: values.managementApiPassword || undefined,
		};

		try {
			await _enableFleetConfigurationService.updateEnableFleetConfiguration(props.configuration!.enableFleetConfigurationId, request);
			_toasterService.showSuccess('EnableFleet configuration updated.');
			_historyService.history.push('/app/enablefleet/list');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update EnableFleet configuration.');
			return false;
		}
	};

	const onSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
		let success;
		if (props.configuration)
			success = await updateConfiguration(values);
		else
			success = await addConfiguration(values);

		if (!success)
			setSubmitting(false);
	};

	const validateForm = (values: IFormValues, errors: FormikErrors<IFormValues>) => {
		if (!values.name)
			errors.name = 'Configuration name is required.';

		if (!values.managementApiUrl)
			errors.managementApiUrl = 'EnableFleet API URL is required.';

		if (!values.managementApiUsername)
			errors.managementApiUsername = 'EnableFleet API username is required.';

		// Allow a blank password if the user is updating an existing configuration.
		// (Blank = don't change the password)
		if (!props.configuration) {
			if (!values.managementApiPassword)
				errors.managementApiPassword = 'EnableFleet API password is required.';
		}
	};

	const addingNew = !props.configuration;

	const initialFormValues: IFormValues = {
		name: props.configuration?.name ?? '',
		managementApiUrl: props.configuration?.managementApiUrl ?? '',
		managementApiUsername: props.configuration?.managementApiUsername ?? '',
		managementApiPassword: '',
	};

	return <FixedWidthPage
		className="form-page"
		headingText={addingNew ? 'Add EnableFleet Configuration' : 'Edit EnableFleet Configuration'}
		subheadingText={props.configuration?.name}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<IFormValues>) => <Form
				className="formik-form"
			>
				<Field
					name="name"
					label="Configuration Name"
					type="text"
					component={FormikTextField}
					required
				/>

				<Field
					name="managementApiUrl"
					label="EnableFleet API URL"
					type="text"
					component={FormikTextField}
					required
				/>

				<Field
					name="managementApiUsername"
					label="EnableFleet API Username"
					type="text"
					component={FormikTextField}
					required
				/>
				<Field
					name="managementApiPassword"
					label="EnableFleet API Password"
					type="password"
					component={FormikTextField}
					required
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					loading={formikProps.isSubmitting}
					text={addingNew ? 'Add Client' : 'Save Changes'}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
