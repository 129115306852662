import React from 'react';

import { ManageUserGroupComponent } from './manageUserGroupComponent';
import { FixedWidthPage, ThingLoader } from 'src/components';

import {
	Client as C,
	AuthenticationService,
	ClientService,
	Service,
	useInjection,
} from 'src/services';

interface IPageData {
	clients?: C.IClientDto[];
}

export const AddUserGroup = () => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _clientService = useInjection<ClientService>(Service.Client);

	const load = async (): Promise<IPageData | null> => {
		let clients: C.IClientDto[] = [];
		if (_authService.currentAuth.user.identity.type !== C.IdentityType.Client) {
			const loadedClients = await _clientService.getAllClients();
			if (!loadedClients)
				return null;

			clients = loadedClients;
		}

		return {
			clients
		};
	};

	return <ThingLoader
		load={load}
		render={(pageData: IPageData) => <FixedWidthPage
			className="form-page"
			noContentBackground
			headingText="Add User Group"
		>
			<ManageUserGroupComponent
				clients={pageData.clients}
			/>
		</FixedWidthPage>}
	/>;
};
