import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useAsyncEffect from 'use-async-effect';

import {
	Client as C,
	AssetGroupService,
	AuthenticationService,
	Service,
	useInjection,
} from 'src/services';

import { DialogItem, SelectItemsDialog } from 'src/components/selectItemsDialog';

export interface IAsset {
	assetId: string;
	name: string;
	client?: null | {
		id: string;
	};
}

interface Props {
	assetGroup: C.IAssetGroupDto;
	assets: IAsset[];
	existing: IAsset[];
	complete: (added: IAsset[]) => void;
	closeDialog: () => void;
}

export const AddAssetsDialog = observer((props: Props) => {
	const _assetGroupService = useInjection<AssetGroupService>(Service.AssetGroup);
	const _authService = useInjection<AuthenticationService>(Service.Authentication);

	const [loading, setLoading] = useState<boolean>(true);
	const [items, setItems] = useState<DialogItem[] | undefined>(undefined);
	const [saving, setSaving] = useState<boolean>(false);
	const [savingError, setSavingError] = useState<boolean>(false);

	useAsyncEffect(async () => {
		const exclude = new Set<string>(props.existing.map(x => x.assetId));

		let assets = props.assets
			.filter(x => x.name.trim().length !== 0 && !exclude.has(x.assetId));

		if (_authService.currentAuth.user.identity.type !== C.IdentityType.Client)
			assets = assets.filter(x => x.client?.id && x.client.id === props.assetGroup.client.clientId);

		const items = assets.map(x => ({
				id: x.assetId,
				name: x.name,
			}))
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

		setItems(items);
		setLoading(false);
	}, []);

	const addAssets = async (assetIds: string[]) => {
		setSaving(true);

		try {
			const addedAssets = await _assetGroupService.addAssetGroupAssets(props.assetGroup.assetGroupId, {
				assetIds: assetIds,
			});

			props.complete(addedAssets);
			props.closeDialog();
		} catch (err) {
			setSavingError(true);
			setSaving(false);
		}
	};

	return <SelectItemsDialog
		title="Add Assets to Group"
		complete={addAssets}
		close={props.closeDialog}
		loading={loading}
		saving={saving}
		savingError={savingError}
		items={items}
	/>;
});
