import { inject, injectable } from 'inversify';
import moment from 'moment-timezone';
import * as C from './client';
import { FloorPlansService } from './floorPlansService';

import { Service } from './service';

@injectable()
export class AssetEventsService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.FloorPlans) private _floorPlanService: FloorPlansService) {
	}

	async fetchHistory(assetId: string, from: moment.Moment, to: moment.Moment, floorPlans: Map<string, C.IFloorPlanDto>): Promise<C.IAssetEventHistory> {
		const history = await this.client.getAssetEventHistoryForAssetIdV2(assetId, from, to);

		if (floorPlans.size > 0) {
			for (const event of history.events) {
				if (event.assetLocation)
					event.assetLocation.floorPlanLocation = this._floorPlanService.getFloorPlanLocationForAssetLocation(floorPlans, event.assetLocation);
			}
		}

		return history;
	}
}
