import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryPlaceBeacon($id: UUID!) {
		beacon: bluetoothBeaconById(id: $id) {
			id
			name
			disabled
			floorPlan {
				id
				name
			}
			longitude
			latitude
		}
	}
`;
