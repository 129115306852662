import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import * as C from 'src/services/client';

import { Service } from 'src/services/service';
import { UserGroupService } from 'src/services/userGroupService';
import { ToasterService } from 'src/services/toasterService';
import { IOption } from 'src/util';
import { useInjection } from 'src/services';

export interface IUserGroupSelectorFormValues {
	userGroupIds: string[];
}

export const useUserGroupSelectorService = () => {
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);

	const [userGroupOptions, setUserGroupOptions] = useState<IOption<string>[] | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useAsyncEffect(async () => {
		try {
			const userGroups = await _userGroupService.getAllUserGroups();

			if (userGroups) {
				const options = userGroups
					.map(x => ({
						label: x.type === C.UserGroupType.AllUsersForClient ? `${x.name} (${x.client.name})` : x.name,
						value: x.userGroupId
					}))
					.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));

				setUserGroupOptions(options);
			}
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to load user groups.');
		}

		setLoading(false);
	}, []);

	return { userGroupOptions, loading };
};
