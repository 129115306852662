import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FieldArray, ArrayHelpers, FormikErrors } from 'formik';
import { HuePicker, AlphaPicker, ColorResult } from 'react-color';
import { IconButton, InputAdornment } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikCheckbox, FormikTextField, FormikSelect, FormikDurationPicker } from 'src/components';
import { runFormValidation } from 'src/util';
import { rgbToHexColorOnly, hexToRgb } from 'src/util/colorHelpers';

import {
	Client as C,
	AuthenticationService,
	GeofenceService,
	HistoryService,
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

const geofenceAlertLevelOptions: { value: string, label: string }[] = [
	{
		label: 'Notification',
		value: C.AlertLevel.Notification,
	},
	{
		label: 'Warning',
		value: C.AlertLevel.Warning,
	},
	{
		label: 'Emergency',
		value: C.AlertLevel.Emergency,
	},
];

export interface GeofenceTypeAlertFormValues {
	geofenceTypeAlertId: string | undefined;
	alertLevel: C.AlertLevel;
	alertDuration: string;
	alertDistance: number;
	userNotification: string;
}

interface ManageGeofenceTypeFormValues {
	name: string;
	color: string;
	fillOpacity: number;
	alerts: GeofenceTypeAlertFormValues[];
	triggersCustomAlerts: boolean;
	triggersEnterAlerts: boolean;
	triggersExitAlerts: boolean;
}

const validateForm = (values: ManageGeofenceTypeFormValues, errors: FormikErrors<ManageGeofenceTypeFormValues>) => {
	if (!values.name)
		errors.name = 'Name is required.';

	if (!values.color)
		errors.color = 'Color is required.';

	if (!values.fillOpacity)
		errors.fillOpacity = 'Fill opacity is required.';
};

export interface Props {
	geofenceType?: C.IGeofenceTypeDto | null;
}

import './manageGeofenceTypeComponent.scss';

export const ManageGeofenceTypeComponent = observer((props: Props) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _geofenceService = useInjection<GeofenceService>(Service.Geofence);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [loading, setLoading] = useState<boolean>(false);
	const [alertsToDelete, setAlertsToDelete] = useState<string[]>([]);

	const mapAlert = (alert: C.IGeofenceTypeAlertDto): GeofenceTypeAlertFormValues => {
		return {
			geofenceTypeAlertId: alert.geofenceTypeAlertId,
			alertDistance: alert.alertDistance == null ? 0 : alert.alertDistance,
			alertDuration: alert.alertDuration == null ? '00:00:00' : alert.alertDuration,
			alertLevel: alert.alertLevel,
			userNotification: alert.userNotification == null ? '' : alert.userNotification,
		};
	};

	const addGeofenceType = async (values: ManageGeofenceTypeFormValues): Promise<boolean> => {
		const request: C.IAddGeofenceTypeRequest = {
			name: values.name,
			clientId: _authenticationService.currentAuth.user.identity.clientId!,
			color: values.color,
			fillOpacity: values.fillOpacity,
			triggersCustomAlerts: values.triggersCustomAlerts,
			triggersEnterAlerts: values.triggersEnterAlerts,
			triggersExitAlerts: values.triggersExitAlerts,
		};

		try {
			const geofence = await _geofenceService.addGeofenceType(request);
			await addUpdateGeofenceAlerts(geofence.geofenceTypeId, values.alerts);
			_toasterService.showSuccess('Geofence type added.');
			_historyService.history.push('/app/geofences/types/list');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to add geofence type.');
			return false;
		}
	};

	const updateGeofenceType = async (values: ManageGeofenceTypeFormValues): Promise<boolean> => {
		const request: C.IUpdateGeofenceTypeRequest = {
			name: values.name,
			color: values.color,
			fillOpacity: values.fillOpacity,
			triggersEnterAlerts: props.geofenceType!.triggersEnterAlerts === values.triggersEnterAlerts ? null : values.triggersEnterAlerts,
			triggersExitAlerts: props.geofenceType!.triggersExitAlerts === values.triggersExitAlerts ? null : values.triggersExitAlerts,
			triggersCustomAlerts: props.geofenceType!.triggersCustomAlerts === values.triggersCustomAlerts ? null : values.triggersCustomAlerts,
		};

		try {
			await _geofenceService.updateGeofenceType(props.geofenceType!.geofenceTypeId, request);
			await addUpdateGeofenceAlerts(props.geofenceType!.geofenceTypeId, values.alerts);
			_toasterService.showSuccess('Geofence type updated.');
			_historyService.history.push('/app/geofences/types/list');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update geofence type.');
			return false;
		}
	};

	const addUpdateGeofenceAlerts = async (geofenceTypeId: string, alerts: GeofenceTypeAlertFormValues[]) => {
		const alertsToAdd: C.IAddGeofenceTypeAlertRequest[] = [];
		const alertsToUpdate: C.IUpdateGeofenceTypeAlertRequest[] = [];
		const alertIdsToDelete: string[] = [];

		for (const alert of alerts) {
			if (!alert.geofenceTypeAlertId) {
				const addRequest: C.IAddGeofenceTypeAlertRequest = {
					alertDistance: alert.alertDistance,
					alertDuration: alert.alertDuration,
					alertLevel: alert.alertLevel,
					userNotification: alert.userNotification,
				};

				alertsToAdd.push(addRequest);
			} else {
				const updateRequest: C.IUpdateGeofenceTypeAlertRequest = {
					geofenceTypeAlertId: alert.geofenceTypeAlertId,
					alertDistance: alert.alertDistance,
					alertDuration: alert.alertDuration,
					alertLevel: alert.alertLevel,
					userNotification: alert.userNotification,
				};

				alertsToUpdate.push(updateRequest);
			}
		}

		for (const alertId of alertsToDelete) {
			if (alertId)
				alertIdsToDelete.push(alertId);
		}

		const changeGeofenceTypeAlertsRequest: C.IGeofenceTypeAlertChangeRequest = {
			add: alertsToAdd.length > 0 ? alertsToAdd : undefined,
			update: alertsToUpdate.length > 0 ? alertsToUpdate : undefined,
			remove: alertIdsToDelete.length > 0 ? alertIdsToDelete : undefined,
		};

		_geofenceService.updateGeofenceTypeAlerts(geofenceTypeId, changeGeofenceTypeAlertsRequest);
	};

	const onSubmit = async (values: ManageGeofenceTypeFormValues, { setSubmitting }: FormikHelpers<ManageGeofenceTypeFormValues>) => {
		let success;
		if (props.geofenceType)
			success = await updateGeofenceType(values);
		else
			success = await addGeofenceType(values);

		if (!success)
			setSubmitting(false);
	};

	const onChangeColor = (formikProps: FormikProps<ManageGeofenceTypeFormValues>, color: ColorResult) => {
		formikProps.setFieldValue('color', rgbToHexColorOnly(color.rgb));
	};

	const onChangeFillOpacity = (formikProps: FormikProps<ManageGeofenceTypeFormValues>, color: ColorResult) => {
		formikProps.setFieldValue('fillOpacity', color.rgb.a);
	};

	const addGeofenceTypeAlert = (arrayHelpers: ArrayHelpers) => {
		const newAlert: GeofenceTypeAlertFormValues = {
			geofenceTypeAlertId: undefined,
			alertDistance: 0,
			alertDuration: '00:00:00',
			alertLevel: C.AlertLevel.Notification,
			userNotification: '',
		};

		arrayHelpers.push(newAlert);
	};

	const removeGeofenceTypeAlert = (arrayHelpers: ArrayHelpers, index: number, alertId: string | undefined) => {
		arrayHelpers.remove(index);

		if (!alertId)
			return;

		setAlertsToDelete([ ...alertsToDelete, alertId ]);
	};

	const renderGeofenceSettings = (formikProps: FormikProps<ManageGeofenceTypeFormValues>) => {
		const color = formikProps.values['color'];
		const fillOpacity = formikProps.values['fillOpacity'];
		const rgbColor = hexToRgb(color, fillOpacity);

		return <div className="content-box geofence-settings">
			<h2>Geofence settings</h2>

			<Field
				className="input-field"
				name="name"
				label="Name"
				component={FormikTextField}
				required
			/>

			<div className="geofence-color-picker">
				<div className="sliders">
					<div className="label">Color</div>
					<HuePicker
						width="100%"
						color={rgbColor}
						onChange={x => onChangeColor(formikProps, x)}
					/>

					<div className="label">Fill Opacity</div>
					<AlphaPicker
						width="100%"
						color={rgbColor}
						onChange={x => onChangeFillOpacity(formikProps, x)}
					/>
				</div>

				<div className="preview">
					<div className="label">Preview</div>
					<div
						style={{
							height: '75px',
							width: '75px',
							backgroundColor: `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${rgbColor.a})`,
							border: `3px solid ${color}`,
						}}
					/>
				</div>
			</div>
		</div>;
	};

	const renderGeofenceAlertSettings = (formikProps: FormikProps<ManageGeofenceTypeFormValues>) => {
		return <>
			<div className="content-box">
				<h2 className="alerts-title">Alert & Emergency settings</h2>

				<div className="triggers-alerts-checkbox">
					<Field
						name="triggersEnterAlerts"
						label="Triggers enter alerts"
						type="checkbox"
						component={FormikCheckbox}
					/>

					<Field
						name="triggersExitAlerts"
						label="Triggers exit alerts"
						type="checkbox"
						component={FormikCheckbox}
					/>

					{formikProps.values.triggersCustomAlerts && <Field
						name="triggersCustomAlerts"
						label="Triggers custom alerts"
						type="checkbox"
						component={FormikCheckbox}
					/>}
				</div>
			</div>

			{formikProps.values.triggersCustomAlerts && <FieldArray
				name="alerts"
				render={arrayHelpers => (
					<div className="custom-alert-types">
						<div className="add-alert-type-button-container">
							<h2 className="custom-alert-types-title">Custom Alerts</h2>

							<Button
								className="add-alert-type-button"
								variant="contained" color="primary"
								startIcon={<AddIcon />}
								text="Add Custom Alert"
								onClick={() => addGeofenceTypeAlert(arrayHelpers)}
							/>
						</div>

						<Alert severity="warning">Custom alerts are not supported for all types of assets.</Alert>

						{formikProps.values.alerts && formikProps.values.alerts.length === 0 && <div className="content-box">
							No custom alerts configured.
						</div>}

						{formikProps.values.alerts.map((alert, index) => (
							<div key={alert.geofenceTypeAlertId} className="content-box alert-type-container">
								<div className="alert-type-container-header">
									<IconButton
										onClick={() => removeGeofenceTypeAlert(arrayHelpers, index, alert.geofenceTypeAlertId)}
										title="Delete custom alert"
									>
										<ClearIcon />
									</IconButton>
								</div>

								<div className="geofence-alert-type">
									<FormikSelect
										className="input-field"
										name={`alerts[${index}].alertLevel`}
										label="Alert Level"
										options={geofenceAlertLevelOptions}
										clearable={false}
										isLoading={loading}
										form={formikProps}
										getOptionLabel={option => option.label}
										getOptionValue={option => option.value}
									/>

									<FormikDurationPicker
										className="input-field"
										label="Alert Duration"
										name={`alerts.${index}.alertDuration`}
										helperText=""
										form={formikProps}
									/>

									<Field
										className="input-field"
										label="Alert distance"
										component={FormikTextField}
										InputProps={{
											endAdornment: <InputAdornment position="end">metres</InputAdornment>,
										}}
										name={`alerts.${index}.alertDistance`}
										type="number"
									/>

									<Field
										className="input-field, user-notification"
										label="User notification"
										multiline
										rows="4"
										component={FormikTextField}
										name={`alerts.${index}.userNotification`}
									/>
								</div>
							</div>
						))}
					</div>
				)}
			/>}
		</>;
	};

	const addingNew = !props.geofenceType;

	const initialFormValues: ManageGeofenceTypeFormValues = {
		name: props.geofenceType?.name ?? '',
		color: props.geofenceType?.color ?? '#0000FF',
		fillOpacity: props.geofenceType?.fillOpacity ?? 0.2,
		triggersEnterAlerts: props.geofenceType?.triggersEnterAlerts ?? false,
		triggersExitAlerts: props.geofenceType?.triggersExitAlerts ?? false,
		triggersCustomAlerts: props.geofenceType?.triggersCustomAlerts ?? false,
		alerts: (props.geofenceType?.behavior?.alerts ?? []).map(mapAlert),
	};

	return <FixedWidthPage
		className="form-page"
		noContentBackground
		headingText={addingNew ? 'Add Geofence Type' : 'Edit Geofence Type'}
		subheadingText={addingNew ? null : props.geofenceType!.name}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageGeofenceTypeFormValues>) => <Form className="formik-form geofence-alert-type-settings">
				{renderGeofenceSettings(formikProps)}
				{renderGeofenceAlertSettings(formikProps)}

				<Button
					className="submit-button"
					type="submit"
					variant="contained"
					color="primary"
					loading={formikProps.isSubmitting}
					startIcon={addingNew ? null : <SaveIcon />}
					text={addingNew ? 'Add Geofence Type' : 'Save Changes'}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
