import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';
import { ToasterService } from './toasterService';

@injectable()
export class GatewayService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.Toaster) private _toasterService: ToasterService,
	) {

	}

	async addGateway(gateway: C.IAddGatewayRequest): Promise<C.IGatewayDto> {
		return await this.client.addGateway(new C.AddGatewayRequest(gateway));
	}

	async updateGateway(gatewayId: string, request: C.IUpdateGatewayRequest): Promise<C.IGatewayDto> {
		return await this.client.updateGatewayById(gatewayId, new C.UpdateGatewayRequest(request));
	}

	async getGateway(gatewayId: string): Promise<C.IGatewayDto | null> {
		try {
			return await this.client.getGatewayById(gatewayId);
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load gateway.');
		}

		return null;
	}

	async getGatewayApiKey(gatewayId: string): Promise<C.IGatewayApiKeyDto | null> {
		try {
			return await this.client.getGatewayApiKeyById(gatewayId);
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load gateway API key.');
		}

		return null;
	}

	async getAllGateways(): Promise<C.IGatewayDto[] | null> {
		try {
			return await this.client.listGateways();
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load gateways.');
		}

		return null;
	}
}
