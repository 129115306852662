import React, { useState } from 'react';
import moment from 'moment-timezone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DatePicker, TimePicker } from '@material-ui/pickers';

import { Button } from './button';
import { DateTimeRange } from './dateTimeRange';

import './dateTimeRangePicker.scss';

import {
	AuthenticationService,
	Service,
	useInjection,
} from 'src/services';

interface Props {
	value: DateTimeRange;
	onSubmit: (value: DateTimeRange) => void;
	onCancel: () => void;
	maxDuration?: moment.Duration;
}

const shortcuts = [
	{
		label: 'Past ½ Hour',
		start: (now: moment.Moment) => now.subtract(30, 'minutes'),
	},
	{
		label: 'Past Hour',
		start: (now: moment.Moment) => now.subtract(1, 'hour'),
	},
	{
		label: 'Past 6 Hours',
		start: (now: moment.Moment) => now.subtract(6, 'hours'),
	},
	{
		label: 'Past 12 Hours',
		start: (now: moment.Moment) => now.subtract(12, 'hours'),
	},
	{
		label: 'Past 24 Hours',
		start: (now: moment.Moment) => now.subtract(1, 'day'),
	},
	{
		// NOTE: The end date needs to be 11:59:59pm as otherwise it is considered in the future.
		// API is exclusive, so we will lose events at or after 11:59:59pm.
		label: 'Today',
		start: (now: moment.Moment) => now.startOf('day'),
		end: (now: moment.Moment) => now.endOf('day'),
	},
	{
		label: 'Yesterday',
		start: (now: moment.Moment) => now.subtract(1, 'day').startOf('day'),
		end: (now: moment.Moment) => now.startOf('day'),
	},
];

interface IDateTimeSelectorProps {
	key: string;
	title: string;
	date: moment.Moment;
	handler: (timestamp: moment.Moment) => void;
}

const DateTimeSelector = (props: IDateTimeSelectorProps) => {
	return <div className="summary">
		<div className="title">{props.title}</div>

		<div className="datetime">
			<DatePicker
				label="Date"
				value={props.date}
				format="ddd, MMM Do YYYY"
				onChange={props.handler as any}
				disableFuture
			/>

			<TimePicker
				label="Time"
				value={props.date}
				onChange={props.handler as any}
			/>
		</div>
	</div>;
};

export const DateTimeRangePickerDialog = (props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);

	const [startDateTime, setStartDateTime] = useState<moment.Moment>(props.value.start);
	const [endDateTime, setEndDateTime] = useState<moment.Moment>(props.value.end);

	const handleDateTimeChange = () => {
		if (startDateTime.isValid() && endDateTime.isValid())
			props.onSubmit({ start: startDateTime, end: endDateTime });
	};

	const goToShortcutDateTime = (start: (now: moment.Moment) => moment.Moment, end?: (now: moment.Moment) => moment.Moment) => {
		const now = moment.tz(_authService.currentAuth.user.timeZone).startOf('minute');

		setStartDateTime(start(now.clone()));
		setEndDateTime(end ? end(now.clone()) : now);
	};

	const endOfToday = moment.tz(_authService.currentAuth.user.timeZone).endOf('day');
	let invalidDateTimeError: string | null = null;

	if (startDateTime.isAfter(endDateTime)) {
		invalidDateTimeError = 'End time must be after start time.';
	} else if (endDateTime.isAfter(endOfToday)) {
		invalidDateTimeError = 'End time cannot be in the future.';
	}

	if (props.maxDuration) {
		if (endDateTime.diff(startDateTime) > props.maxDuration.asMilliseconds())
			invalidDateTimeError = `Duration may not exceed ${props.maxDuration.humanize()}.`;
	}

	return <Dialog
		className="date-time-range-picker__dialog"
		open
		onClose={props.onCancel}
	>
		<DialogContent className="content">
			<div className="datetime-shortcuts">
				{shortcuts.map((x) => <Button
					key={x.label}
					text={x.label}
					onClick={() => goToShortcutDateTime(x.start, x.end)}
					size="small"
					variant="text"
				/>)}
			</div>

			<div className="datetime-selectors">
				<DateTimeSelector
					key="start-panel"
					title="FROM"
					date={startDateTime}
					handler={setStartDateTime}
				/>

				<DateTimeSelector
					key="end-panel"
					title="TO"
					date={endDateTime}
					handler={setEndDateTime}
				/>
			</div>
		</DialogContent>

		{invalidDateTimeError && <div className="status-bar error">{invalidDateTimeError}</div>}

		<DialogActions>
			<Button text="Cancel" onClick={props.onCancel} variant="text" />
			<Button
				autoFocus
				text="Done"
				disabled={invalidDateTimeError == null ? false : true}
				onClick={handleDateTimeChange}
				variant="text" color="primary"
			/>
		</DialogActions>
	</Dialog>;
};
