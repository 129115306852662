import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, FormikErrors, Form, Field } from 'formik';
import { InputAdornment, makeStyles } from '@material-ui/core';
import MuiImage from 'material-ui-image';
import { undefinedIfUnchanged } from 'src/util';
import Alert from '@material-ui/lab/Alert';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField, FormikCheckbox } from 'src/components';

import { useMutationAddDealer } from 'src/graphql/__generated__/mutations/mutationAddDealer';
import { useMutationUpdateDealer } from 'src/graphql/__generated__/mutations/mutationUpdateDealer';
import { QueryDealer_dealerById } from 'src/graphql/__generated__/queries/queryDealer';
import { DealerType } from 'src/../__generated__/globalTypes';

import {
	HistoryService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

interface ManageDealerFormValues {
	name: string;
	type: string;
	billingCode: string;
	maxTrialPeriod: number;
	deviceTrialEndNotificationEnabled: boolean;
	deviceTrialEndNotificationPeriodDays: number;
	navbarBrandingFile: string;
	customBrandingEnabled: boolean;
}

const useStyles = makeStyles({
	imageContainer: {
		'& div': {
			paddingTop: '35px !important',
			marginTop: '10px',
		},
		'& > span': {
			fontWeight: '500',
			fontSize: '21px',
			paddingBottom: '10px',
			marginRight: 'auto',
		},
	},
	image: {
		width: 'auto !important',
		height: 'auto !important',
		maxHeight: '35px',
		maxWidth: '300px',
	},
});

interface Props {
	dealer?: QueryDealer_dealerById | null;
}

export const ManageDealerComponent = observer((props: Props) => {
	const historyService = useInjection<HistoryService>(Service.History);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
	const [selectedImage, setSelectedImage] = useState<HTMLImageElement | undefined>(undefined);

	const [ addDealerMutation ] = useMutationAddDealer();
	const [ updateDealerMutation ] = useMutationUpdateDealer();

	const classNames = useStyles();

	const dealer = props.dealer;

	const initialFormValues: ManageDealerFormValues = {
		name: dealer ? dealer.name : '',
		type: dealer?.type || DealerType.STANDARD,
		maxTrialPeriod: dealer?.maxTrialMonths ?? 0,
		billingCode: dealer?.billingCode ?? '',
		deviceTrialEndNotificationEnabled: dealer ? dealer.deviceTrialEndNotificationEnabled ?? false : true,
		deviceTrialEndNotificationPeriodDays: dealer?.deviceTrialEndNotificationPeriodDays || 7,
		navbarBrandingFile: '',
		customBrandingEnabled: !!dealer?.navbarBrandingImageUrl,
	};

	const addingNewDealer = !dealer;

	const validateForm = (values: ManageDealerFormValues) => {
		const errors: FormikErrors<ManageDealerFormValues> = {};

		if (!values.name)
			errors.name = 'Name is required.';

		if (values.maxTrialPeriod < 0)
			errors.maxTrialPeriod = 'Max trial period must be greater or equal to 0.';

		if (values.deviceTrialEndNotificationPeriodDays < 1)
			errors.deviceTrialEndNotificationPeriodDays = 'Asset trial end notification period must be at least 1.';
		else if (values.deviceTrialEndNotificationPeriodDays > 30)
			errors.deviceTrialEndNotificationPeriodDays = 'Asset trial end notification period cannot be greater than 30.';

		if (values.customBrandingEnabled) {
			if (!values.navbarBrandingFile && !dealer?.navbarBrandingImageUrl) {
				errors.navbarBrandingFile = 'Custom website branding image is required.';
			} else if (selectedFile && selectedImage) {
				const error = validateImage(selectedFile, selectedImage.width, selectedImage.height);
				// If there is an error, set it on the navbarBrandingFile input.
				if (error)
					errors.navbarBrandingFile = error;
			}
		}

		return errors;
	};

	async function addOrEditDealer(values: ManageDealerFormValues) {
		try {
			if (dealer) {
				let navbarImage: File | undefined | null = undefined;
				if (values.customBrandingEnabled)
					navbarImage = selectedFile ? selectedFile : undefined;
				else if (!values.navbarBrandingFile && props.dealer?.navbarBrandingImageUrl)
					navbarImage = null;

				await updateDealerMutation({
					variables: {
						input: {
							dealerId: dealer!.id,
							name: undefinedIfUnchanged(dealer.name, values.name),
							type: undefinedIfUnchanged(dealer.type, values.type as DealerType),
							billingCode: undefinedIfUnchanged(dealer.billingCode, values.billingCode),
							maxTrialMonths: undefinedIfUnchanged(dealer.maxTrialMonths, values.maxTrialPeriod),
							deviceTrialEndNotificationEnabled: undefinedIfUnchanged(dealer.deviceTrialEndNotificationEnabled, values.deviceTrialEndNotificationEnabled),
							deviceTrialEndNotificationPeriodDays: undefinedIfUnchanged(dealer.deviceTrialEndNotificationEnabled, values.deviceTrialEndNotificationEnabled ? values.deviceTrialEndNotificationPeriodDays : null),
							navbarBrandingImageFile: navbarImage,
						}
					}
				});
			} else {
				await addDealerMutation({
					variables: {
						input: {
							name: values.name,
							type: values.type as DealerType,
							billingCode: values.billingCode,
							maxTrialMonths: values.maxTrialPeriod,
							deviceTrialEndNotificationEnabled: values.deviceTrialEndNotificationEnabled,
							deviceTrialEndNotificationPeriodDays: values.deviceTrialEndNotificationEnabled ? values.deviceTrialEndNotificationPeriodDays : null,
							navbarBrandingImageFile: values.customBrandingEnabled ? selectedFile : null,
						}
					}
				});
			}

			historyService.history.push('/app/dealers/list');
			return true;
		} catch (err) {
			toasterService.handleWithToast(err);
			return false;
		}
	}

	async function onSubmit(values: ManageDealerFormValues, { setSubmitting }: FormikHelpers<ManageDealerFormValues>) {
		const success = await addOrEditDealer(values);

		if (success)
			toasterService.showSuccess(`Dealer has been ${dealer ? 'updated' : 'added'} successfully.`);

		if (!success)
			setSubmitting(false);
	}

	// Handle the file selected, if any. 
	async function handleFileSelected(evt: React.ChangeEvent<HTMLInputElement>, formikProps: FormikProps<ManageDealerFormValues>) {
		const files = evt.target.files;

		if (!!files && files.length > 0) {
			await formikProps.setFieldValue('navbarBrandingFile', evt.target.value);

			await loadImage(files[0], formikProps);
		} else {
			// Clear image state as the user cleared the image from the select.
			await formikProps.setFieldValue('navbarBrandingFile', '');

			setSelectedFile(undefined);
			setSelectedImage(undefined);
			formikProps.setFieldError('navbarBrandingFile', undefined);
		}

		await formikProps.setFieldTouched('navbarBrandingFile');
	}

	// Load the file as an image.
	async function loadImage(file: File, formikProps: FormikProps<ManageDealerFormValues>) {
		const image = new Image();

		image.onload = async () => {
			await handleImage(file, image, formikProps);
		};
		image.onerror = () => setInvalidFileTypeError(formikProps);

		const url = window.URL || (window as any).webkitURL;

		// If this doesn't throw an error (see above 'image.onerror'), the file is a valid image.
		image.src = url.createObjectURL(file);
	}

	// Handle the image and file objects to make sure the file uploaded is a valid navbar image.
	async function handleImage(file: File, image: HTMLImageElement, formikProps: FormikProps<ManageDealerFormValues>) {
		const reader = new FileReader();
		reader.onload = async function () {
			// Validate image dimensions and file size on upload.
			const error = validateImage(file, image.width, image.height);

			// If no error set the field error to undefined as this will clear the error.
			formikProps.setFieldError('navbarBrandingFile', error);

			setSelectedImage(image);
			setSelectedFile(file);
		};
		reader.onerror = () => setInvalidFileTypeError(formikProps);

		reader.readAsDataURL(file);
	}

	function setInvalidFileTypeError(formikProps: FormikProps<ManageDealerFormValues>) {
		formikProps.setFieldError('navbarBrandingFile', 'Cannot read file, please check it is a valid .PNG image.');

		setSelectedImage(undefined);
		setSelectedFile(undefined);
	}

	// Returns an error message if the file or image is not valid.
	function validateImage(file: File, imageWidth: number, imageHeight: number): string | undefined {
		if (file.type != 'image/png') {
			return 'Image selected must be a .PNG image.';
		} else if (file.size > 51200) {
			return 'Image size is too large.';
		} else if (imageWidth > 300 || imageHeight > 35) {
			return 'Selected image dimensions are too large.';
		}

		return undefined;
	}

	return <FixedWidthPage
		className="form-page"
		headingText={addingNewDealer ? 'Add Dealer' : 'Edit Dealer'}
		pageItemId={dealer?.id}
	>
		<Formik
			initialValues={initialFormValues}
			validate={validateForm}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageDealerFormValues>) => <Form className="formik-form">
				<Field
					name="name"
					label="Dealer Name"
					type="text"
					component={FormikTextField}
					required
				/>

				<Field
					name="type"
					label="Type"
					select
					component={FormikTextField}
					children={<>
						<option key={DealerType.STANDARD} value={DealerType.STANDARD}>
							Standard
						</option>
						<option key={DealerType.CONFIGURATION_ONLY} value={DealerType.CONFIGURATION_ONLY}>
							Configuration Only
						</option>
					</>}
					SelectProps={{
						native: true,
					}}
				/>

				<Field
					name="billingCode"
					label="Billing Code"
					type="text"
					component={FormikTextField}
				/>

				{formikProps.values.type === DealerType.STANDARD && <Field
					name="maxTrialPeriod"
					label="Max trial period"
					type="number"
					component={FormikTextField}
					InputProps={{
						endAdornment: <InputAdornment position="end">months</InputAdornment>,
					}}
					helperText="When activating an asset on a trial basis, the maximum length of time this dealer can specify as the trial period."
				/>}

				<Field
					name="deviceTrialEndNotificationEnabled"
					label="Device Trial End Notifications"
					type="checkbox"
					component={FormikCheckbox}
				/>

				<Field
					name="deviceTrialEndNotificationPeriodDays"
					label="Device Trial End Notification Period"
					type="number"
					disabled={!formikProps.values.deviceTrialEndNotificationEnabled}
					component={FormikTextField}
					InputProps={{
						endAdornment: <InputAdornment position="end">days</InputAdornment>,
					}}
					helperText="A notification will be sent the specified number of days before an device trial is due to end."
				/>

				<Field
					name="customBrandingEnabled"
					label="Custom Website Branding Enabled"
					type="checkbox"
					component={FormikCheckbox}
				/>

				{formikProps.values.customBrandingEnabled && <>
					<Field
						name="navbarBrandingFile"
						label="Upload Website Branding Image"
						type="file"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileSelected(e, formikProps)}
						onBlur={() => formikProps.setFieldTouched('navbarBrandingFile', true)}
						inputProps={{ accept: '.png', }}
						variant="filled"
						InputLabelProps={{
							shrink: true,
						}}
						component={FormikTextField}
					/>
					<Alert severity="info">Image must be in PNG format, less than 50KB, and no larger than 300 pixels wide and 35 pixels high.</Alert>
				</>}

				{(selectedImage || dealer?.navbarBrandingImageUrl) && formikProps.values.customBrandingEnabled && !formikProps.errors.navbarBrandingFile && <>
					<div className={classNames.imageContainer}>
						<span>Logo</span>
						<MuiImage className={classNames.image}	src={selectedImage?.src ? selectedImage.src : dealer!.navbarBrandingImageUrl!}/>
					</div>
				</>}

				<Button
					type="submit"
					variant="contained"
					color="primary"
					loading={formikProps.isSubmitting}
					startIcon={addingNewDealer ? null : <SaveIcon />}
					text={addingNewDealer ? 'Add Dealer' : 'Save Changes'}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
