import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddHyteraRadioDevice($input: AddHyteraRadioDeviceInput!) {
		addHyteraRadioDevice(input: $input) {
			hyteraRadioDevice {
				id
				asset {
					id
				}
			}
		}
	}
`;
