import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import {
	HistoryService,
	Service,
	useInjection,
} from 'src/services';

import { Button } from './button';

export interface MessagePageProps {
	title?: string | null;
	action?: JSX.Element | null;
	icon?: JSX.Element | null;
	loading? : boolean;
	backButton?: boolean;
	className?: string;
}

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '25px',

		'& > :not(:first-child)': {
			marginTop: '15px',
		},

		'& > :not(:last-child)': {
			marginBottom: '15px',
		},
	},
	title: {
		fontWeight: 500,
		fontSize: '130%',
	},
});

export const MessagePage = (props: MessagePageProps) => {
	const historyService = useInjection<HistoryService>(Service.History);
	const classes = useStyles();

	let icon: JSX.Element | null = null;
	if (props.loading)
		icon = <CircularProgress />;
	else if (props.icon)
		icon = props.icon;

	let action: JSX.Element | null = null;
	if (props.backButton)
		action = <Button variant="outlined" onClick={() => historyService.history.goBack()} text="Go back?" />;
	else if (props.action)
		action = props.action;

	return <div className={classNames('flex-fill-no-overflow', classes.root, props.className)}>
		{icon}
		{props.title && <span className={classes.title}>{props.title}</span>}
		{action}
	</div>;
};

export const LoadingMessagePage = () => <MessagePage loading />;

export const ErrorMessagePage = (props: { message?: string }) => <MessagePage
	title={props.message || 'There was an error while trying to load this page.'}
	backButton
/>;
