import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';

import { ManageUserGroup } from './manageUserGroup';
import { ManageUserGroupComponent } from './manageUserGroupComponent';

import {
	Client as C,
	UserGroupService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditUserGroup = observer((props: Props) => {
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);

	const load = async (userGroupId: string) => {
		return await _userGroupService.getUserGroup(userGroupId);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(userGroup: C.IUserGroupDto, reload: Function) => <ManageUserGroup
			userGroup={userGroup}
		>
			<ManageUserGroupComponent
				userGroup={userGroup}
				onSubmitSuccess={reload}
			/>
		</ManageUserGroup>}
	/>;
});
