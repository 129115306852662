import React, { useState } from 'react';
import { match } from 'react-router-dom';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';

import { QueryReport_reportById } from 'src/graphql/__generated__/queries/queryReport';

import {
	AuthenticationService,
	Client as C,
	DistanceService,
	DistanceUnit,
	ReportService,
	Service,
	SpeedUnit,
	useInjection,
} from 'src/services';

import { CustomAutocomplete, FixedWidthPage, ThingLoader, ReportParametersDisplay } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';
import { getLocationParts } from 'src/util/locationFormats';
import { formatLongDuration } from 'src/util/durationFormats';

import './activity.scss';

export interface Props {
	match: match<{ id: string }>;
}

interface ActivityReportViewData {
	report: QueryReport_reportById;
	reportData: C.IActivityReportData;
	activityTypes: string[];
}

interface ActivityDetails {
	type: string;
	details: string;
}

const formatLocation = (assetLocation: C.IAssetLocationDto) => {
	const locationParts = assetLocation.locationDetails && getLocationParts(assetLocation.locationDetails);
	if (locationParts)
		return locationParts.join(', ');

	return `${assetLocation.location.latitude}, ${assetLocation.location.longitude}`;
};

export const ViewActivityReport = observer((props: Props) => {
	const authService = useInjection<AuthenticationService>(Service.Authentication);
	const reportService = useInjection<ReportService>(Service.Report);
	const distanceService = useInjection<DistanceService>(Service.DistanceService);

	const [activityTypeFilter, setActivityTypeFilter] = useState<string[]>([]);

	const onChanceActivityTypeFilter = (selected: string[]) => {
		setActivityTypeFilter(selected);
	};

	const load = async (reportId: string): Promise<ActivityReportViewData> => {
		const report = reportService.getReportById(reportId);

		const reportDataResponseObject = await reportService.getActivityReportData(reportId);

		const activityTypes = new Set<string>();
		for (const asset of reportDataResponseObject.assets) {
			for (const activity of asset.activities) {
				const details = getActivityTypeAndDetails(activity);
				if (!details)
					continue;

				activityTypes.add(details.type);
			}
		}

		const allActivityTypes = Array.from(activityTypes.values()).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

		setActivityTypeFilter(allActivityTypes);

		return {
			report: await report,
			reportData: reportDataResponseObject,
			activityTypes: allActivityTypes,
		};
	};

	const renderReport = (reportViewData: ActivityReportViewData) => {
		const showTotalTimesOverSpeedLimit = (reportViewData.reportData.assets.find(x => x.travelSummary != null && x.travelSummary.totalTimesSpeedLimitExceeded != null) !== undefined);

		return <div>
			<div className="report-section">
				{reportViewData.activityTypes.length > 0 && <CustomAutocomplete
					className="activity-type-filter"
					options={reportViewData.activityTypes}
					getOptionValue={x => x}
					getOptionLabel={x => x}
					onChange={onChanceActivityTypeFilter}
					value={activityTypeFilter}
					label="Filter Activity Types"
					outlined
					multi
					disableCloseOnSelect
				/>}

				<h2>Summary</h2>

				<table className="card-table">
					<thead>
						<tr>
							<th>Asset Name</th>
							<th>Distance Travelled</th>
							<th>Average Speed</th>
							<th>Max Speed</th>
							<th>Idle</th>
							{showTotalTimesOverSpeedLimit && <th>Total Times Speeding</th>}
						</tr>
					</thead>

					<tbody>
						{reportViewData.reportData.assets.map((x: any) => summaryRow(x, showTotalTimesOverSpeedLimit))}
					</tbody>
				</table>
			</div>

			{reportViewData.reportData.assets.map(assetReportTable)}
		</div>;
	};

	const summaryRow = (asset: C.IAssetData, showTotalTimesOverSpeedLimit: boolean) => {
		return <tr
			key={asset.assetId.toString()}
			className="content-box"
		>
			<td>{asset.assetName}</td>

			{asset.travelSummary && <>
				<td>{distanceService.formatDistance(asset.travelSummary.distanceTravelled, authService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles)}</td>
				<td>{distanceService.formatSpeed(asset.travelSummary.averageSpeed, authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour)}</td>
				<td>{distanceService.formatSpeed(asset.travelSummary.maxSpeed, authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour)}</td>
				<td>{asset.travelSummary.totalIdleTime ? formatLongDuration(moment.duration(asset.travelSummary.totalIdleTime)) : '0 hrs'}</td>
				{showTotalTimesOverSpeedLimit && <td>{asset.travelSummary.totalTimesSpeedLimitExceeded}</td>}
			</>}

			{!asset.travelSummary && <>
				<td>0 {authService.currentAuth.user.usesMetric ? 'km' : 'mi'}</td>
				<td>N/A</td>
				<td>N/A</td>
				<td>0 hrs</td>
				{showTotalTimesOverSpeedLimit && <td>0</td>}
			</>}
		</tr>;
	};

	const assetReportTable = (asset: C.IAssetData) => {
		if (asset.activities.length === 0)
			return null;

		let locationBeforeReportPeriod = 'Unknown';
		if (asset.locationBeforeReportPeriod?.assetLocation?.location?.latitude)
			locationBeforeReportPeriod = formatLocation(asset!.locationBeforeReportPeriod!.assetLocation);

		return <div
			key={asset.assetId}
			className="report-section"
		>
			<h2>{asset.assetName}</h2>

			<p><strong>Location before report period: </strong>{locationBeforeReportPeriod}</p>
			<p><strong>Asset speed limit:</strong> {asset.assetSpeedLimit ? distanceService.formatSpeed(asset.assetSpeedLimit, authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour) : 'Not set'}</p>

			<table className="card-table">
				<thead>
					<tr>
						<th>Time of Activity/Event</th>
						<th>Activity/Event</th>
						<th>Detail</th>
						<th>Location</th>
						<th>Driver</th>
					</tr>
				</thead>

				<tbody>
					{asset.activities.map(activityRow)}
				</tbody>
			</table>
		</div>;
	};

	const activityRow = (activity: C.IActivity, index: any) => {
		const typeAndDetails = getActivityTypeAndDetails(activity);
		if (!typeAndDetails || !activityTypeFilter.includes(typeAndDetails.type))
			return undefined;

		return <tr
			key={index.toString()}
			className="content-box"
		>
			<td>{moment.tz(activity.activityTimestamp, authService.currentAuth.user.timeZone).format(longDateTimeFormat)}</td>
			<td>{typeAndDetails.type}</td>
			<td>{typeAndDetails.details || ''}</td>
			<td>{activity.location?.assetLocation?.location?.latitude && formatLocation(activity!.location.assetLocation)}</td>
			<td>{activity.userName || ''}</td>
		</tr>;
	};

	const getActivityTypeAndDetails = (activity: C.IActivity): ActivityDetails | null => {
		const typeAndDetails: ActivityDetails = {
			type: '',
			details: '',
		};

		if (activity instanceof C.AssetActiveActivity) {
			typeAndDetails.type = 'Asset Active';
		} else if (activity instanceof C.AssetInactiveActivity) {
			typeAndDetails.type = 'Asset Inactive';
			typeAndDetails.details = `The asset was active for ${formatLongDuration(moment.duration(activity.activeDuration))}`;
		} else if (activity instanceof C.ContinuedAssetActiveActivity) {
			typeAndDetails.type = 'Asset Active at Report Start';
			typeAndDetails.details = 'The asset was active at Report Start.';
		} else if (activity instanceof C.ContinuedGeofenceEnterActivity) {
			typeAndDetails.type = 'Asset in Geofence at Report Start';
			typeAndDetails.details = `The asset was inside the geofence ${activity.geofenceName} at Report Start.`;
		} else if (activity instanceof C.ContinuedKeyOnActivity) {
			typeAndDetails.type = 'Key on at Report Start';
			typeAndDetails.details = 'The key for this asset was on at the start of the report.';
		} else if (activity instanceof C.ContinuedSafetyTimerActivity) {
			typeAndDetails.type = 'Safety Timer Active at Report Start';
			typeAndDetails.details = 'Safety timer was active at the report start.';
		} else if (activity instanceof C.ContinuedTravelStartActivity) {
			typeAndDetails.type = 'Travelling at Report Start';
			typeAndDetails.details = 'This asset was travelling at the start of the report.';
		} else if (activity instanceof C.ContinuedUserNameActivity) {
			typeAndDetails.type = 'Driver At Report Start';
			typeAndDetails.details = `Driver: ${activity.userName}`;
		} else if (activity instanceof C.EmergencyActivity) {
			typeAndDetails.type = 'Emergency';
		} else if (activity instanceof C.GeofenceEnterActivity) {
			typeAndDetails.type = 'Geofence Enter';
			typeAndDetails.details = `Asset has entered the geofence ${activity.geofenceName}`;
		} else if (activity instanceof C.GeofenceExitActivity) {
			typeAndDetails.type = 'Geofence Exit';
			typeAndDetails.details = `Asset has exited the geofence ${activity.geofenceName}, ${getFormattedDurationAndDistanceTravelled(activity.geofenceEnterDuration, activity.distanceTravelledInGeofence)}`;
		} else if (activity instanceof C.GeofenceSpeedingActivity) {
			typeAndDetails.type = 'Asset Speeding In Geofence';
			typeAndDetails.details = `Asset exceeded the speed limit configured on the geofence ${activity.geofenceName}, max speed: ${distanceService.formatSpeed(activity.maxSpeedWhileSpeeding, authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour)}, geofence speed limit: ${distanceService.formatSpeed(activity.geofenceSpeedLimit, authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour)}.`;
		} else if (activity instanceof C.IdleActivity) {
			typeAndDetails.type = 'Asset Idled';
			typeAndDetails.details = `Idle for ${formatLongDuration(moment.duration(activity.duration))}, (started idling at ${moment.tz(moment(activity.activityTimestamp).subtract(activity.duration), authService.currentAuth.user.timeZone).format(longDateTimeFormat)})`;
		} else if (activity instanceof C.KeyOnActivity) {
			typeAndDetails.type = 'Key On';
		} else if (activity instanceof C.KeyOffActivity) {
			typeAndDetails.type = 'Key Off';
			typeAndDetails.details = `Key On for ${formatLongDuration(moment.duration(activity.keyOnDuration))}`;
		} else if (activity instanceof C.SafetyTimerStartActivity) {
			typeAndDetails.type = 'Safety Timer Started';
		} else if (activity instanceof C.SafetyTimeEndActivity) {
			typeAndDetails.type = 'Safety Timer Ended';
			typeAndDetails.details = `Safety timer was active for ${formatLongDuration(moment.duration(activity.safetyTimerDuration))}`;
		} else if (activity instanceof C.SafetyTimerEmergencyActivity) {
			typeAndDetails.type = 'Asset failed to check-in for safety timer.';
		} else if (activity instanceof C.SpeedingActivity) {
			typeAndDetails.type = 'Asset Speeding';
			typeAndDetails.details = `Asset exceeded its configured speed limit, max speed: ${distanceService.formatSpeed(activity.maxSpeedWhileSpeeding, authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour)}.`;
		} else if (activity instanceof C.TravelStartActivity) {
			typeAndDetails.type = 'Travel Start';
			typeAndDetails.details = `Stopped for ${formatLongDuration(moment.duration(activity.stopDuration))}`;
		} else if (activity instanceof C.TravelStopActivity) {
			typeAndDetails.type = 'Travel Stop';
			typeAndDetails.details = formatTravelSummaryAndDuration(activity.travelDuration, activity.travelSummary, 'Travelled');
		} else if (activity instanceof C.UnfinishedTravelStartActivity) {
			typeAndDetails.type = 'Travelling at Report End';
			typeAndDetails.details = formatTravelSummaryAndDuration(activity.travelDuration, activity.travelSummary, 'Travelled');
		} else if (activity instanceof C.UnfinishedTravelStopActivity) {
			typeAndDetails.type = 'Stopped at Report End';
			typeAndDetails.details = `Stopped for ${formatLongDuration(moment.duration(activity.stopDuration)) }`;
		} else if (activity instanceof C.UnfinishedKeyOnActivity) {
			typeAndDetails.type = 'Key On at Report End';
			typeAndDetails.details =  `Key On for ${formatLongDuration(moment.duration(activity.keyOnDuration))}.`;
		} else if (activity instanceof C.UnfinishedSafetyTimerActivity) {
			typeAndDetails.type = 'Safety Timer Unfinished at Report End';
			typeAndDetails.details = `Safety timer was active for ${formatLongDuration(moment.duration(activity.safetyTimerDuration))} at Report End.`;
		} else if (activity instanceof C.UnfinishedAssetActiveActivity) {
			typeAndDetails.type = 'Asset Active at Report End';
			typeAndDetails.details = `The asset was active for ${formatLongDuration(moment.duration(activity.activeDuration))} at Report End.`;
		} else if (activity instanceof C.UnfinishedGeofenceEnterActivity) {
			typeAndDetails.type = 'Asset In Geofence at Report End';
			typeAndDetails.details = `Asset is still inside geofence ${activity.geofenceName}, ${getFormattedDurationAndDistanceTravelled(activity.geofenceEnterDuration, activity.distanceTravelledInGeofence)}`;
		} else if (activity instanceof C.UserNameChangeActivity) {
			typeAndDetails.type = 'Driver Changed';
			typeAndDetails.details = `Driver: ${activity.userName ? activity.userName : 'None'}`;
		} else {
			return null;
		}

		return typeAndDetails;
	};

	const formatTravelSummaryAndDuration = (travelActivityDuration: string, activityTravelSummary: C.ActivityTravelSummary, activitySummaryType: string): string => {
		return `${activitySummaryType} for ${formatLongDuration(moment.duration(travelActivityDuration))}, ${distanceService.formatDistance(activityTravelSummary.distanceTravelled, authService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles)},
		avg: ${distanceService.formatSpeed(activityTravelSummary.averageSpeed, authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour)},
		max: ${distanceService.formatSpeed(activityTravelSummary.maxSpeed, authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour)}`;
	};

	const getFormattedDurationAndDistanceTravelled = (insideGeofenceDuration: string, insideGeofenceDistance: number) => {
		const timeFormatted = `${formatLongDuration(moment.duration(insideGeofenceDuration))}`;
		const distanceFormatted = `${distanceService.formatDistance(insideGeofenceDistance, authService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles)}`;
		return `time in geofence: ${timeFormatted}, distance in geofence: ${distanceFormatted} (during report period)`;
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(reportViewData: ActivityReportViewData) => <FixedWidthPage
			headingText="Activity Report"
			subheadingText={reportViewData.report.name}
			noContentBackground
			contentClassName="view-activity-report"
		>
			{reportViewData.report.parameters && <ReportParametersDisplay
				reportParameters={reportViewData.report.parameters}
			/>}

			{renderReport(reportViewData)}
		</FixedWidthPage>}
	/>;
});
