import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Button, FixedWidthPage, MessagePage, PopoverMenu, PopoverMenuItem, ThingLoader } from 'src/components';

import {
	Client as C,
	GeofenceService,
	Service,
	useInjection,
} from 'src/services';

export const GeofenceList = observer(() => {
	const _geofenceService = useInjection<GeofenceService>(Service.Geofence);

	const load = async () => {
		const geofences = await _geofenceService.getGeofences();
		return geofences.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
	};

	const renderGeofence = (geofence: C.IGeofenceDto) => {
		return <tr key={geofence.geofenceId} className="content-box">
			<td>{geofence.name}</td>
			<td>{geofence.geofenceType ? geofence.geofenceType.name : <span style={{ fontStyle: 'italic' }}>Default</span>}</td>

			<td className="actions">
				<PopoverMenu
					renderOptions={() => renderActionMenu(geofence)}
				/>
			</td>
		</tr>;
	};

	const renderActionMenu = (geofence: C.IGeofenceDto) => {
		return [
			<PopoverMenuItem
				key="view"
				text="View"
				href={`/app/map?view=geofence&geofenceId=${geofence.geofenceId}`}
			/>,
		];
	};

	return <ThingLoader
		load={load}
		render={geofences => <FixedWidthPage
			headingText="Geofences"
			headingActions={<Button href="/app/geofences/types/list" text="Geofence Types" variant="outlined" color="primary"/>}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': geofences.length === 0 })}
		>
			{geofences.length > 0 && <table className="card-table">
				<thead>
					<tr>
						<th>Name</th>
						<th>Type</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{geofences.map(renderGeofence)}
				</tbody>
			</table>}

			{geofences.length === 0 && <MessagePage
				title="No geofences."
			/>}
		</FixedWidthPage>}
	/>;
});
