import { ApolloClient, InMemoryCache } from '@apollo/client';
import { autorun } from 'mobx';

import {
	Container,
	AuthenticationService,
	TableDataService,
	EmergencyService,
	FloorPlansService,
	HistoryService,
	Service,
	WebSocketService,
} from 'src/services';

const apolloClient = Container.get<ApolloClient<InMemoryCache>>(Service.ApolloClient);
const authService = Container.get<AuthenticationService>(Service.Authentication);
const emergencyService = Container.get<EmergencyService>(Service.Emergency);
const floorPlanService = Container.get<FloorPlansService>(Service.FloorPlans);
const historyService = Container.get<HistoryService>(Service.History);
const tableDataService = Container.get<TableDataService>(Service.TableDataService);
const webSocketService = Container.get<WebSocketService>(Service.WebSocket);

let returnToUrl: string | null = null;
let hadUser: boolean = false;

// Stop app access when not authenticated, allow after signing in
autorun(() => {
	const history = historyService.history;
	if (!history.location)
		return;

	if (history.location.pathname.startsWith('/emergency') || history.location.pathname.startsWith('/privacy-policy'))
		return;

	// If the user is signed in, send them to the app
	if (authService.isAuthenticated) {
		if (!history.location.pathname.startsWith('/app')) {
			const url = returnToUrl && returnToUrl.startsWith('/app') ? returnToUrl : null;
			history.replace(url || '/app');
			returnToUrl = null;
		}

		return;
	}

	// If we are verifyingt the user's auth status, send them to sign in page if they are not on it already
	if (authService.isAttemptingReauthentication) {
		if (history.location.pathname !== '/auth/sign-in') {
			returnToUrl = history.location.pathname + history.location.search;
			history.replace('/auth/sign-in');
		}

		return;
	}

	// If the user is not signed in and not on an auth page, send them to sign in
	if (!history.location.pathname.startsWith('/auth')) {
		returnToUrl = history.location.pathname + history.location.search;
		history.replace('/auth/sign-in');
		return;
	}

	// User is not signed in and we are not verifying their auth status, either send them
	// back to where they were trying to go, or to sign in if they need to auth to get there
	let url = returnToUrl;
	if (!url || !url.startsWith('/auth'))
		url = '/auth/sign-in';
	else
		returnToUrl = null;

	history.replace(url);
}, { name: 'stop app access when not authenticated, allow after signing in' });

let currentUserId: string | null = null;

// Notify stores that user has changed/signed out
autorun(async () => {
	if (authService.currentAuth) {
		if (currentUserId === authService.currentAuth.user.userId)
			return;
	} else {
		if (!currentUserId)
			return;
	}

	if (hadUser)
		await webSocketService.resetState();

	emergencyService.resetState();
	floorPlanService.resetState();
	tableDataService.resetState();

	if (hadUser) {
		apolloClient.stop();
		await apolloClient.resetStore();
	}

	currentUserId = authService.currentAuth ? authService.currentAuth.user.userId : null;

	if (currentUserId) {
		await webSocketService.start();
		hadUser = true;
	}
}, { name: 'notify stores that user has changed/signed out' });
