import { inject, injectable } from 'inversify';
import { makeObservable, observable, runInAction } from 'mobx';

import * as A from './authenticationService';
import * as C from './client';
import { ToasterService } from './toasterService';

import { TreeItem } from 'react-sortable-tree';
import { Service } from './service';
import { convertLatLngToXy } from 'src/util/latLngConverter';

export type TreeNode = TreeItem & {
	id?: string,
	parentId?: string,
	isNew?: boolean,
	nameChanged?: boolean,
	type: C.FloorPlanCollectionItemType,
	data?: C.IFloorPlanGroupDto | C.IFloorPlanDto | null,
};

interface GeorectifierParameters {
	mapCentre: mapboxgl.LngLat;
	mapZoom: number;
	mapBearing: number;
	imageWidth: number;
	imageHeight: number;
	imageOpacity: number;
	imageWidthPercent: number;
	imageHeightPercent: number;
}

@injectable()
export class FloorPlansService {
	@observable isLoading: boolean = true;

	@observable collections = new Map<string, C.IFloorPlanGroupDto>();
	@observable floorPlanGroups = new Map<string, C.IFloorPlanGroupDto>();
	@observable floorPlans = new Map<string, C.IFloorPlanDto>();

	previousGeorectifierParams: GeorectifierParameters | undefined;

	constructor(
		@inject(Service.Authentication) private auth: A.AuthenticationService,
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.Toaster) private _toasterService: ToasterService,
	) {
		makeObservable(this);
		this.resetState();
	}

	private async load(): Promise<void> {
		try {
			const allFloorPlanData = await this.client.allFloorPlanGroupsAndFloorPlans();

			runInAction(() => {
				for (const floorPlanGroup of allFloorPlanData.floorPlanGroups) {
					this.floorPlanGroups.set(floorPlanGroup.floorPlanGroupId, floorPlanGroup);

					if (!floorPlanGroup.parentGroupId)
						this.collections.set(floorPlanGroup.collectionId, floorPlanGroup);
				}

				for (const floorPlan of allFloorPlanData.floorPlans)
					this.floorPlans.set(floorPlan.floorPlanId, floorPlan);
			});
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load floor plans.');
		}

		this.isLoading = false;
	}

	async getFloorPlan(floorPlanId: string): Promise<C.IFloorPlanDto> {
		const floorPlan = await this.client.getFloorPlanById(floorPlanId);
		this.floorPlans.set(floorPlan.floorPlanId, floorPlan);

		return floorPlan;
	}

	async getFloorPlanCollection(floorPlanCollectionId: string): Promise<C.IQueryCollectionResponse> {
		const collectionResponse = await this.client.getFloorPlanCollectionById(floorPlanCollectionId);

		for (const floorPlanGroup of collectionResponse.floorPlanGroups) {
			this.floorPlanGroups.set(floorPlanGroup.floorPlanGroupId, floorPlanGroup);

			if (!floorPlanGroup.parentGroupId)
				this.collections.set(floorPlanGroup.collectionId, floorPlanGroup);
		}

		for (const floorPlan of collectionResponse.floorPlans)
			this.floorPlans.set(floorPlan.floorPlanId, floorPlan);

		return collectionResponse;
	}

	async addFloorPlanCollection(request: C.IAddFloorPlanCollectionRequest): Promise<C.IFloorPlanGroupDto> {
		try {
			const newFloorPlanGroup = await this.client.addFloorPlanCollection(new C.AddFloorPlanCollectionRequest(request));
			this.collections.set(newFloorPlanGroup.collectionId, newFloorPlanGroup);
			this.floorPlanGroups.set(newFloorPlanGroup.floorPlanGroupId, newFloorPlanGroup);

			return newFloorPlanGroup;
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to add floor plan collection.');
			return <any> null;
		}
	}

	async addFloorPlan(floorPlan: C.IAddFloorPlanRequest): Promise<C.IFloorPlanDto | null> {
		try {
			const newFloorPlan = await this.client.addFloorPlan(new C.AddFloorPlanRequest(floorPlan));
			this.floorPlans.set(newFloorPlan.floorPlanId, newFloorPlan);

			return newFloorPlan;
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to add new floor plan.');
			return null;
		}
	}

	async updateFloorPlan(floorPlanId: string, update: C.IUpdateFloorPlanRequest): Promise<void> {
		const floorPlan = await this.client.updateFloorPlanById(floorPlanId, new C.UpdateFloorPlanRequest(update));
		this.floorPlans.set(floorPlan.floorPlanId, floorPlan);
	}

	async deleteFloorPlan(floorPlanId: string): Promise<void> {
		await this.client.deleteFloorPlanById(floorPlanId);
		this.floorPlans.delete(floorPlanId);
	}

	async deleteFloorPlanCollection(collectionId: string): Promise<void> {
		await this.client.deleteFloorPlanCollectionById(collectionId);

		this.collections.delete(collectionId);

		const group = Array.from(this.floorPlanGroups.values()).find(x => x.collectionId === collectionId && !x.parentGroupId);
		if (group)
			this.floorPlanGroups.delete(group.floorPlanGroupId);

		const plansToDelete = Array.from(this.floorPlans.values()).filter(x => x.collectionId == collectionId);
		for (const floorPlan of plansToDelete)
			this.floorPlans.delete(floorPlan.floorPlanId);
	}

	async updateFloorPlanCollection(collectionId: string, collection: TreeItem): Promise<void> {
		const rootNodeDto = this.mapTreeItemToDto(collection);
		const response = await this.client.updateFloorPlanCollectionById(collectionId, new C.FloorPlanCollectionTreeNodeDto(rootNodeDto));

		this.collections.set(response.collectionId, response);
		this.floorPlanGroups.set(response.floorPlanGroupId, response);
	}

	private mapTreeItemToDto(item: TreeItem): C.IFloorPlanCollectionTreeNodeDto {
		const itemNode = item as TreeNode;

		return {
			id: itemNode.isNew ? null : itemNode.id,
			type: itemNode.type,
			name: itemNode.isNew || itemNode.nameChanged ? itemNode.title : null,
			children: itemNode.children ? itemNode.children.map(x => this.mapTreeItemToDto(x)) : null,
			displayOrder: itemNode.data?.displayOrder || undefined,
		};
	}

	private compareFloorPlanGroups(a: C.IFloorPlanGroupDto, b: C.IFloorPlanGroupDto): number {
		return a.displayOrder - b.displayOrder || a.name.localeCompare(b.name);
	}

	private compareFloorPlans(a: C.IFloorPlanDto, b: C.IFloorPlanDto): number {
		return a.displayOrder - b.displayOrder || a.name.localeCompare(b.name);
	}

	getFloorPlanLocationForAssetLocation(floorPlans: Map<string, C.IFloorPlanDto>, location: C.IAssetLocationDto) : C.FloorPlanLocationDto | undefined {
		if (!location.location.latitude || !location.location.longitude || (location.floorLevelIndex == null && !location.floorPlanId))
			return undefined;

		const worldLocation = {
			latitude: location.location.latitude,
			longitude: location.location.longitude,
			floorLevelIndex: location.floorLevelIndex,
			floorPlanId: location.floorPlanId,
		};

		let selectedFloorPlan: C.IFloorPlanDto | null | undefined = null;

		if (worldLocation.floorPlanId)
			selectedFloorPlan = floorPlans.get(worldLocation.floorPlanId);

		if (!selectedFloorPlan) {
			selectedFloorPlan = this.getFloorPlanForLocation(floorPlans, worldLocation.latitude, worldLocation.longitude, worldLocation.floorLevelIndex);
			if (!selectedFloorPlan)
				return undefined;
		}

		let xy = convertLatLngToXy(selectedFloorPlan, worldLocation.latitude, worldLocation.longitude);

		return new C.FloorPlanLocationDto({
			floorPlanId: selectedFloorPlan.floorPlanId,
			location: new C.XyDto({
				x: xy[0],
				y: xy[1],
			}),
		});
	}

	private getFloorPlanForLocation(floorPlans: Map<string, C.IFloorPlanDto> | null, latitude: number | null, longitude: number | null, floorLevelIndex: number | null | undefined): C.IFloorPlanDto | null {
		if (!floorPlans)
			return null;

		if (!latitude || !longitude || floorLevelIndex == null)
			return null;

		for (const floorPlan of floorPlans.values()) {
			if (floorLevelIndex !== floorPlan.levelIndex)
				continue;

			if (this.isWithinPolygon({ latitude: latitude, longitude: longitude }, [floorPlan.topLeft, floorPlan.topRight, floorPlan.bottomRight, floorPlan.bottomLeft]))
				return floorPlan;
		}

		return null;
	}

	/**
	 * Check whether a point is within a polygon by using the winding number algorithm
	 * Adapted from https://stackoverflow.com/a/46144206 with components from https://stackoverflow.com/a/3637354
	 */
	private isWithinPolygon(point: C.ILatLngDto, polygon: C.ILatLngDto[]) {
		let windingNumber = 0;

		// Adapted from https://stackoverflow.com/a/58377541
		let longitudeWrap = Math.abs(Math.min(...polygon.map(x => x.longitude)) - Math.max(...polygon.map(x => x.longitude))) > 180;

		for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
			// Check the polygon edge from polygon[i] to polygon[j].

			if (polygon[i].latitude <= point.latitude) {
				// Check for an upwards crossing.
				if (polygon[j].latitude > point.latitude) {
					// Check if the point lies to the left of the edge.
					if (this.isLeft(polygon[i], polygon[j], point, longitudeWrap) > 0) {
						// Valid up intersect.
						++windingNumber;
					}
				}
			}
			else {
				// Check for a downwards crossing.
				if (polygon[j].latitude <= point.latitude) {
					// Check if the point lies to the right of the edge.
					if (this.isLeft(polygon[i], polygon[j], point, longitudeWrap) < 0) {
						// Valid down intersect.
						--windingNumber;
					}
				}
			}
		}

		return windingNumber != 0;
	}

	/**
	 * Check whether a point lies to the left or right of a line drawn by two other points.
	 * Adapted from https://stackoverflow.com/a/46144206 with components from https://stackoverflow.com/a/3637354
	 */
	private isLeft(P0: C.ILatLngDto, P1: C.ILatLngDto, P2: C.ILatLngDto, longitudeWrap: boolean) {
		var p0Lng = longitudeWrap && P0.longitude < 0 ? P0.longitude + 360 : P0.longitude;
		var p1Lng = longitudeWrap && P1.longitude < 0 ? P1.longitude + 360 : P1.longitude;
		var p2Lng = longitudeWrap && P2.longitude < 0 ? P2.longitude + 360 : P2.longitude;

		const calc = (p1Lng - p0Lng) * (P2.latitude - P0.latitude) - (p2Lng - p0Lng) * (P1.latitude - P0.latitude);

		if (calc > 0)
			return 1;

		if (calc < 0)
			return -1;

		return 0;
	}

	buildTreeFromCollection(collection: C.IQueryCollectionResponse): TreeItem[] {
		const rootKey = '-1';
		const flatGroups = [...collection.floorPlanGroups]
			.sort(this.compareFloorPlanGroups)
			.map(g => {
				return {
					id: g.floorPlanGroupId,
					parentId: g.parentGroupId || rootKey,
					title: g.name,
					type: C.FloorPlanCollectionItemType.Group,
					expanded: true,
					data: {...g},
				};
			}
		);
		const flatPlans = [...collection.floorPlans]
			.sort(this.compareFloorPlans)
			.map(p => {
				return {
					id: p.floorPlanId,
					parentId: p.parentGroupId,
					title: p.name,
					type: C.FloorPlanCollectionItemType.Plan,
					expanded: true,
					data: {...p},
				};
			}
		);
		const flatData = [...flatGroups, ...flatPlans];

		const childrenToParents: {[key: string]: TreeNode[]} = {};

		flatData.forEach(child => {
			const parentKey = child.parentId;

			if (parentKey in childrenToParents) {
				childrenToParents[parentKey].push(child);
			} else {
				childrenToParents[parentKey] = [child];
			}
		});

		if (!(rootKey in childrenToParents))
			throw Error('Collection does not contain a top-level group');

		const addChildren = (parent: TreeNode): TreeNode => {
			const parentKey = parent.id;
			if (parentKey && parentKey in childrenToParents) {
				return {
				...parent,
				children:
					childrenToParents[parentKey].map(addChildren),
				};
			}

			return {...parent};
		};

		return childrenToParents[rootKey].map(addChildren);
	}

	resetState(): void {
		runInAction(() => {
			this.isLoading = true;

			this.collections = new Map<string, C.IFloorPlanGroupDto>();
			this.floorPlanGroups = new Map<string, C.IFloorPlanGroupDto>();
			this.floorPlans = new Map<string, C.IFloorPlanDto>();

			this.previousGeorectifierParams = undefined;

			if (this.auth.currentAuth)
				this.load();
		});
	}

	nodeAtPathDeleted(deletedPaths: string[][], path: string[]): boolean {
		for (let i = 0; i < deletedPaths.length; i++) {
			// This path can't be a child of, or match deletedPaths[i] if it's shorter
			if (path.length < deletedPaths[i].length)
				continue;

			if (deletedPaths[i].every((key, j) => key === path[j]))
				return true;
		}

		return false;
	}
}
