import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';
import { ManageGatewayComponent } from './manageGatewayComponent';

import {
	GatewayService,
	Client as C,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditGateway = observer((props: Props) => {
	const _gatewayService = useInjection<GatewayService>(Service.Gateway);

	const load = async (gatewayId: string): Promise<C.IGatewayDto | null> => {
		const gateway = await _gatewayService.getGateway(gatewayId);
		if (!gateway)
			return null;

		return gateway;
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(gateway: C.IGatewayDto) => <ManageGatewayComponent
			gateway={gateway}
		/>}
	/>;
});
