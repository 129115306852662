import React from 'react';
import { observer } from 'mobx-react';
import { Formik, Form, Field, FormikErrors, FormikProps } from 'formik';
import { InputAdornment } from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';

import { QueryAssetServiceReminderTypeById_assetServiceReminderTypeById } from 'src/graphql/__generated__/queries/queryAssetServiceReminderTypeById';
import { useQueryAddEditAssetServiceReminder } from 'src/graphql/__generated__/queries/queryAddEditAssetServiceReminder';
import { useMutationAddAssetServiceReminderType } from 'src/graphql/__generated__/mutations/mutationAddAssetServiceReminderType';
import { useMutationUpdateAssetServiceReminderType } from 'src/graphql/__generated__/mutations/mutationUpdateAssetServiceReminderType';

import { Button, FixedWidthPage, FormikTextField, FormikSelect, FormikCheckbox, ErrorMessagePage, LoadingMessagePage } from 'src/components';
import { formGlobalErrors, addGlobalError, undefinedIfUnchanged } from 'src/util';

import {
	AuthenticationService,
	Client as C,
	DistanceService,
	DistanceUnit,
	HistoryService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

export interface Props {
	assetServiceReminderType?: QueryAssetServiceReminderTypeById_assetServiceReminderTypeById;
}

export interface AssetServiceReminderTypeFormValues {
	name: string;
	clientId: string | null;
	dateEnabled: boolean;
	dateWhenToRemindInAdvance: number;
	odometerEnabled: boolean;
	odometerWhenToRemindInAdvance: number;
	engineTimeEnabled: boolean;
	engineTimeWhenToRemindInAdvance: number;
	message: string;
}

const validateForm = (values: AssetServiceReminderTypeFormValues) => {
	const errors: FormikErrors<AssetServiceReminderTypeFormValues> = {};
	const triggerMustBeEnabledMessage = 'Either date, odometer, or engine time must be enabled.';

	if (!values.name)
		errors.name = 'Name is required.';

	if (!values.clientId)
		errors.clientId = 'Client is required.';

	if (!values.dateEnabled && !values.odometerEnabled && !values.engineTimeEnabled) {
		addGlobalError(errors, triggerMustBeEnabledMessage);
	}

	if (values.dateEnabled) {
		if (values.dateWhenToRemindInAdvance <= 0 || values.dateWhenToRemindInAdvance > 365)
			errors.dateWhenToRemindInAdvance = 'When to remind in advanced is required to be greater then 1 but less then or equal to 365.';
	}

	if (values.odometerEnabled) {
		if (values.odometerWhenToRemindInAdvance <= 0)
			errors.odometerWhenToRemindInAdvance = 'When to remind in advanced is required to be greater then 1.';
	}

	if (values.engineTimeEnabled) {
		if (values.engineTimeWhenToRemindInAdvance <= 0)
			errors.engineTimeWhenToRemindInAdvance = 'When to remind in advanced is required to be greater then 1.';
	}

	return errors;
};

export const ManageAssetServiceReminderType = observer((props: Props) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const distanceService = useInjection<DistanceService>(Service.DistanceService);
	const historyService = useInjection<HistoryService>(Service.History);
	const toasterService = useInjection<ToasterService>(Service.Toaster);
	const identity = authenticationService.currentAuth.user.identity;
	const identityDistanceUnit = authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles;

	const assetServiceReminderType = props.assetServiceReminderType;

	const includeUsers = identity.type === C.IdentityType.SuperUser || identity.type === C.IdentityType.Dealer || (identity.type === C.IdentityType.Client && !!identity.isAdmin);

	const [ addAssetServiceType ] = useMutationAddAssetServiceReminderType();
	const [ updateAssetServiceType ] = useMutationUpdateAssetServiceReminderType();

	const usersAndClients = useQueryAddEditAssetServiceReminder({
		variables: {
			includeClients: identity.type === C.IdentityType.SuperUser || identity.type === C.IdentityType.Dealer,
			includeUsers: includeUsers,
			includeUserGroups: includeUsers,
		}
	});

	if (usersAndClients.loading)
		return <LoadingMessagePage />;

	if (usersAndClients.error || !usersAndClients.data)
		return <ErrorMessagePage />;

	const clients = usersAndClients.data.clients;
	let users = usersAndClients.data.users;
	let userGroups = usersAndClients.data.userGroups;

	let clientId = assetServiceReminderType?.client?.id;
	if (identity.type === C.IdentityType.Client) {
		clientId = identity.clientId;
	} else {
		users = usersAndClients.data.users ? usersAndClients.data.users.filter(x => x.identity?.client?.id === clientId) : null;
		userGroups = usersAndClients.data.userGroups ? usersAndClients.data.userGroups.filter(x => x.client?.id === clientId) : null;
	}

	const initialValues: AssetServiceReminderTypeFormValues = {
		name: assetServiceReminderType ? assetServiceReminderType.name : '',
		clientId: clientId,
		dateEnabled: assetServiceReminderType?.dateEnabled ? assetServiceReminderType.dateEnabled : false,
		dateWhenToRemindInAdvance: assetServiceReminderType?.dateWhenToRemindInAdvance ? assetServiceReminderType.dateWhenToRemindInAdvance : 1,
		odometerEnabled: assetServiceReminderType?.odometerEnabled ? assetServiceReminderType.odometerEnabled : false,
		odometerWhenToRemindInAdvance: assetServiceReminderType?.odometerWhenToRemindInAdvance ?  distanceService.getDistance(assetServiceReminderType.odometerWhenToRemindInAdvance, identityDistanceUnit) : 1000,
		engineTimeEnabled: assetServiceReminderType?.engineTimeEnabled ? assetServiceReminderType.engineTimeEnabled : false,
		engineTimeWhenToRemindInAdvance: assetServiceReminderType?.engineTimeWhenToRemindInAdvance ? Math.round(assetServiceReminderType.engineTimeWhenToRemindInAdvance / 60) : 20,
		message: assetServiceReminderType?.message ? assetServiceReminderType.message : '',
	};

	const submit = async (values: AssetServiceReminderTypeFormValues) => {
		try {
			// Convert display units to send to the API.
			const odometerWhenToRemindInAdvance = distanceService.getMetresFromDistance(values.odometerWhenToRemindInAdvance, identityDistanceUnit);
			const engineTimeWhenToRemindInAdvance = values.engineTimeWhenToRemindInAdvance * 60;

			if (!assetServiceReminderType) {
				const result = await addAssetServiceType({
					variables: {
						input: {
							clientId: values.clientId,
							name: values.name,

							dateEnabled: values.dateEnabled,
							dateWhenToRemindInAdvance: values.dateEnabled ? values.dateWhenToRemindInAdvance : undefined,

							odometerEnabled: values.odometerEnabled,
							odometerWhenToRemindInAdvance: values.odometerEnabled ? odometerWhenToRemindInAdvance : undefined,

							engineTimeEnabled: values.engineTimeEnabled,
							engineTimeWhenToRemindInAdvance: values.engineTimeEnabled ? engineTimeWhenToRemindInAdvance : undefined,

							message: values.message,
						},
					},
				});

				toasterService.showSuccess('Asset service reminder type added.');

				const assetServiceReminderTypeId = result.data!.addAssetServiceReminderType!.assetServiceReminderType?.id;
				historyService.history.push(`/app/asset-service-reminders/${assetServiceReminderTypeId}/list`);
			} else {
				// Do not send down undefined for whenToRemindInAdvance these values are necessary for when triggers are enabled/ disabled on the reminders type.
				await updateAssetServiceType({
					variables: {
						input: {
							assetServiceReminderTypeId: assetServiceReminderType.id,
							name: undefinedIfUnchanged(assetServiceReminderType.name, values.name),

							dateEnabled: undefinedIfUnchanged(assetServiceReminderType.dateEnabled, values.dateEnabled),
							dateWhenToRemindInAdvance: undefinedIfUnchanged(assetServiceReminderType.dateWhenToRemindInAdvance, values.dateWhenToRemindInAdvance, values.dateEnabled),

							odometerEnabled: undefinedIfUnchanged(assetServiceReminderType.odometerEnabled, values.odometerEnabled),
							odometerWhenToRemindInAdvance: undefinedIfUnchanged(assetServiceReminderType.odometerWhenToRemindInAdvance, odometerWhenToRemindInAdvance, values.odometerEnabled),

							engineTimeEnabled: undefinedIfUnchanged(values.engineTimeEnabled, values.engineTimeEnabled),
							engineTimeWhenToRemindInAdvance: undefinedIfUnchanged(assetServiceReminderType.engineTimeWhenToRemindInAdvance, engineTimeWhenToRemindInAdvance, values.engineTimeEnabled),

							message: undefinedIfUnchanged(assetServiceReminderType.message, values.message),
						},
					}
				});

				toasterService.showSuccess('Saved changes.');
			}
		} catch (err) {
			toasterService.handleWithToast(err, `Failed to ${assetServiceReminderType? 'edit' : 'add'} asset service reminder type.`);
		}
	};

	return <FixedWidthPage
		headingText={`${assetServiceReminderType ? 'Edit' : 'Add'} Asset Service Reminder Type`}
		className="form-page"
		pageItemId={assetServiceReminderType?.id}
		noContentBackground
	>
		<div className="content-box">
			<Formik
				initialValues={initialValues}
				validate={validateForm}
				validateOnChange={false}
				onSubmit={submit}
			>
				{formikProps => <Form className="formik-form">
					<Field
						name="name"
						label="Name"
						type="text"
						component={FormikTextField}
						required
					/>

					{clients && <FormikSelect
						name="clientId"
						label="Client"
						placeholder="Select a client..."
						options={clients}
						form={formikProps}
						getOptionLabel={option => option.name}
						getOptionValue={option => option.id}
						required
						disabled={!!assetServiceReminderType}
					/>}

					<Field
						name="dateEnabled"
						label="Date reminder"
						type="checkbox"
						component={FormikCheckbox}
						error={false}
					/>

					<Field
						name="dateWhenToRemindInAdvance"
						label="How far in advance to remind"
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="end">days</InputAdornment>,
						}}
						component={FormikTextField}
						disabled={!formikProps.values.dateEnabled}
					/>

					<Field
						name="odometerEnabled"
						label="Odometer reminder"
						type="checkbox"
						component={FormikCheckbox}
						error={false}
					/>

					<Field
						name="odometerWhenToRemindInAdvance"
						label="How far in advance to remind"
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="end">{authenticationService.currentAuth.user.usesMetric ? 'km' : 'mi' }</InputAdornment>,
						}}
						component={FormikTextField}
						disabled={!formikProps.values.odometerEnabled}
					/>

					<Field
						name="engineTimeEnabled"
						label="Engine time reminder"
						type="checkbox"
						component={FormikCheckbox}
						error={false}
					/>

					<Field
						name="engineTimeWhenToRemindInAdvance"
						label="How far in advance to remind"
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
						}}
						component={FormikTextField}
						disabled={!formikProps.values.engineTimeEnabled}
					/>

					{formGlobalErrors(formikProps.errors)}

					<Field
						name="message"
						label="Message"
						type="text"
						component={FormikTextField}
						multiline
						rows={3}
					/>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						loading={formikProps.isSubmitting}
						startIcon={assetServiceReminderType ? <SaveIcon /> : null }
						text={assetServiceReminderType ? 'Save Changes' : 'Add Type'}
					/>
				</Form>}
			</Formik>
		</div>
	</FixedWidthPage>;
});
