import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class AssetTypesService {

	constructor(
		@inject(Service.ApiClient) private client: C.Client,
	) {

	}

	async addAssetType(addAssetTypeRequest: C.IAddAssetTypeRequest): Promise<C.IAssetTypeDto> {
		return this.client.addAssetType(new C.AddAssetTypeRequest(addAssetTypeRequest));
	}

	async updateAssetType(assetTypeId: string, updateAssetTypeRequest: C.IUpdateAssetTypeRequest): Promise<C.IAssetTypeDto> {
		return this.client.updateAssetTypeById(assetTypeId, new C.UpdateAssetTypeRequest(updateAssetTypeRequest));
	}

	async getAssetTypeById(assetTypeId: string): Promise<C.IAssetTypeDto> {
		return this.client.getAssetTypeById(assetTypeId);
	}

	async listAssetTypes(): Promise<C.IAssetTypeDto[]> {
		return this.client.listAssetTypes();
	}

	async deleteAssetTypes(assetTypeIds: string[]) {
		const assetDeleteRequest: C.IAssetTypeIdsRequest = {
			assetTypeIds: assetTypeIds,
		};
		return this.client.deleteAssetTypesById(new C.AssetTypeIdsRequest(assetDeleteRequest));
	}
}
