import React from 'react';
import { FormikErrors } from 'formik';
import Alert from '@material-ui/lab/Alert';

interface IFormErrorCollection {
	_global?: string[];
}

export function formGlobalErrors<T>(errors: FormikErrors<T>): JSX.Element | null {
	const errorCollection = errors as any as IFormErrorCollection;
	if (!errorCollection || !errorCollection._global)
		return null;

	return <div className="global-errors">
		{errorCollection._global.map((x, index) => <Alert severity="warning" key={index}>{x}</Alert>)}
	</div>;
}

export function addGlobalError<T>(errors: FormikErrors<T>, globalError: string) {
	const globalErrors = (errors as IFormErrorCollection);

	if (!globalErrors._global)
		globalErrors._global = [];

	globalErrors._global.push(globalError);
}
