import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import AddIcon from '@material-ui/icons/Add';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NewReleaseIcon from '@material-ui/icons/NewReleases';
import TickIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';

import { Button, FixedWidthPage, MessagePage, PopoverMenu, PopoverMenuItem, ThingLoader, CustomActionDialogBox } from 'src/components';
import { shortDateFormat, longTimeFormat } from 'src/util/dateTimeFormats';

import { useMutationRebootGateway } from 'src/graphql/__generated__/mutations/mutationRebootGateway';
import { useMutationUpdateGatewayVersion } from 'src/graphql/__generated__/mutations/mutationUpdateGatewayVersion';

import {
	AuthenticationService,
	GatewayService,
	Client as C,
	Service,
	WebSocketService,
	useInjection,
	ToasterService,
} from 'src/services';

const StyledUpdateStatusContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
});

const StyledGreenTick = styled(TickIcon) ({
	color: '#4CAF50',
});

export const GatewayList = observer(() => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _gatewayService = useInjection<GatewayService>(Service.Gateway);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _webSocketService = useInjection<WebSocketService>(Service.WebSocket);

	const canAddGateways = _authenticationService.currentAuth.permissions.general.addGateways;
	const showDealerColumn = _authenticationService.currentAuth.user.identity.type === C.IdentityType.SuperUser;

	const [gateways, setGateways] = useState<C.IGatewayDto[]>([]);
	const [gatewayToUpdate, setGatewayToUpdate] = useState<C.IGatewayDto | null>(null);
	const [gatewayToReboot, setGatewayToReboot] = useState<C.IGatewayDto | null>(null);
	const [acknowledgedWarning, setAcknowledgedWarning] = useState<boolean>(false);

	const [ rebootGatewayMutation ] = useMutationRebootGateway();
	const [ updateGatewayVersionMutation ] = useMutationUpdateGatewayVersion();

	useEffect(() => {
		_webSocketService.subscribeToGatewayUpdates(gateway => {
			setGateways(oldGateways => {
				const newGateways = oldGateways.filter(x => x.gatewayId !== gateway.gatewayId);
				newGateways.push(gateway);

				return newGateways;
			});
		});

		return () => _webSocketService.unsubscribeFromGatewayUpdates();
	}, []);

	const load = async () => {
		const gateways = await _gatewayService.getAllGateways();
		if (gateways == null)
			return null;

		setGateways(gateways);

		return true;
	};

	const renderGatewayInformation = (gateway: C.IGatewayDto) => {
		let statusType = 'offline';
		let statusText = 'Never communicated.';

		if (gateway.state.lastHeartbeatReceivedAt != null) {
			if (gateway.state.online) {
				statusType = 'online';
				statusText = 'Online';
			} else {
				statusType = 'danger';
				statusText = `Offline since ${gateway.state.lastHeartbeatReceivedAt.tz(_authenticationService.currentAuth.user.timeZone).format(`${shortDateFormat} ${longTimeFormat}`)}`;
			}
		}

		const updateNeeded = gateway.version == null || gateway.version.gatewayVersionId != gateway.latestVersion?.gatewayVersionId;

		let updateStatusIcon = <StyledGreenTick/>;
		let updateStatusTooltip = 'Update Successful';
		if (updateNeeded) {
			updateStatusIcon = <NewReleaseIcon color="primary"/>;
			updateStatusTooltip = 'Update Available';
		}

		if (gateway.state.updateFailed) {
			updateStatusIcon = <ErrorIcon color="error"/>;
			updateStatusTooltip = 'Update Failed';
		}

		if (gateway.state.updating) {
			updateStatusIcon = <CircularProgress size={20}/>;
			updateStatusTooltip = 'Update In Progress';
		}

		return <tr key={gateway.gatewayId} className="content-box">
			<td>{gateway.name}</td>

			<td>{gateway.serial}</td>

			{showDealerColumn && <td>
				{gateway.dealer && gateway.dealer.name}
			</td>}

			<td>
				{gateway.site && gateway.site.name}
			</td>

			<td>
				<StyledUpdateStatusContainer className="update">
					<Tooltip title={updateStatusTooltip}>
						{updateStatusIcon}
					</Tooltip>
				</StyledUpdateStatusContainer>
			</td>

			<td>
				<div className="status">
					<Tooltip title={statusText}>
						<div
							className={classNames('status-indicator', statusType)}
						/>
					</Tooltip>
				</div>
			</td>

			<td className="actions">
				<PopoverMenu
					renderOptions={() => renderActionMenu(gateway)}
				/>
			</td>
		</tr>;
	};

	const renderActionMenu = (gateway: C.IGatewayDto) => {
		const options: JSX.Element[] = [];

		const updateNeeded = gateway.version == null || gateway.version.gatewayVersionId != gateway.latestVersion?.gatewayVersionId;
		const cantWhenOfflineTitle = (action : string) => `Cannot ${action} When Offline`;
		const showSuperUserActions = _authenticationService.currentAuth.user.identity.type === C.IdentityType.SuperUser;
		if (showSuperUserActions) {
			options.push(<PopoverMenuItem
				key="overview"
				text="Overview"
				href={`/app/gateways/${gateway.gatewayId}/overview`}
			/>);
		}

		options.push(<PopoverMenuItem
			key="edit"
			text="Edit"
			href={`/app/gateways/${gateway.gatewayId}/edit`}
		/>);

		options.push(<PopoverMenuItem
			key="status"
			text="Status"
			href={`/app/gateways/${gateway.gatewayId}/status`}
		/>);

		updateNeeded && options.push(<Tooltip title={!gateway.state.online || gateway.state.updating ? cantWhenOfflineTitle('Update') : 'Update Gateway'}>
			<div>
				<PopoverMenuItem
					key="update"
					text="Update"
					disabled={!gateway.state.online || gateway.state.updating}
					onClick={() => setGatewayToUpdate(gateway)}
				/>
			</div>
		</Tooltip>);

		options.push(<Tooltip title={!gateway.state.online || gateway.state.updating ? cantWhenOfflineTitle('Reboot') : 'Reboot Gateway'}>
			<div>
				<PopoverMenuItem
					key="reboot"
					text="Reboot"
					disabled={!gateway.state.online || gateway.state.updating}
					onClick={() => setGatewayToReboot(gateway)}
				/>
			</div>
		</Tooltip>);

		return options;
	};

	const rebootGateway = async (gateway: C.IGatewayDto) => {
		try {
			await rebootGatewayMutation({
				variables: {
					input: {
						gatewayId: gateway.gatewayId,
					}
				}
			});

			_toasterService.showSuccess(`Successfully requested gateway reboot.`);
		} catch (err) {
			_toasterService.handleWithToast(err, `Failed to request gateway reboot.`);
		}

		closeDialogs();
	};

	const updateGatewayVersion = async (gateway: C.IGatewayDto) => {
		const failedToUpdateMessage = `Failed to request gateway update.`;

		try {
			if (!gateway.latestVersion?.gatewayVersionId) {
				_toasterService.handleWithToast(failedToUpdateMessage);
				return;
			}

			await updateGatewayVersionMutation({
				variables: {
					input: {
						gatewayId: gateway.gatewayId,
						gatewayVersionId: gateway.latestVersion.gatewayVersionId,
					}
				}
			});

			_toasterService.showSuccess(`Successfully requested gateway update.`);
		} catch (err) {
			_toasterService.handleWithToast(err, failedToUpdateMessage);
		}

		closeDialogs();
	};

	const closeDialogs = () => {
		setGatewayToUpdate(null);
		setGatewayToReboot(null);
		setAcknowledgedWarning(false);
	};

	const sortedGateways = useMemo(() => gateways.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })), [gateways]);

	return <ThingLoader
		load={load}
		render={() => <FixedWidthPage
			headingText="Gateways"
			headingActions={[
				<Button
					key="settings"
					href={`/app/users/${_authenticationService.currentAuth.user.userId}/settings/gateway-alerts`}
					startIcon={<NotificationsIcon />}
					text="Alert & Emergency Settings"
					variant="outlined"
					color="primary"
				/>,
				canAddGateways ? <Button key="add" href="/app/gateways/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"/> : null
			]}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': sortedGateways.length === 0 })}
		>
			{sortedGateways.length > 0 && <table className="card-table gateway-list">
				<thead>
					<tr>
						<th>Name</th>
						<th>Serial</th>
						{showDealerColumn && <th>Dealer</th>}
						<th>Site</th>
						<th className="align-center">Update Status</th>
						<th className="align-center">Connection Status</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{sortedGateways.map(renderGatewayInformation)}
				</tbody>
			</table>}

			{sortedGateways.length === 0 && <MessagePage
				title="No gateways."
				action={canAddGateways ? <Button href="/app/gateways/add" text="Add a gateway?" variant="outlined" /> : null}
			/>}

			{(gatewayToUpdate || gatewayToReboot) && <CustomActionDialogBox
					title={gatewayToUpdate ? `Update gateway ${gatewayToUpdate.name}` : `Reboot gateway ${gatewayToReboot!.name}`}
					actionButton={<Button
						variant="contained"
						color="primary"
						text={gatewayToUpdate ? 'Update' : 'Reboot'}
						onClick={() => gatewayToUpdate ? updateGatewayVersion(gatewayToUpdate) : rebootGateway(gatewayToReboot!)}
						disabled={!acknowledgedWarning}
					/>}
					dialogCloseCallback={closeDialogs}
				>
					<DialogContentText>
						If {gatewayToUpdate ? 'updated' : 'rebooted'}, the gateway will not process information (including alerts and emergencies) until the operation has completed.
					</DialogContentText>

					<DialogContentText>
						{gatewayToUpdate
							? 'Updating a gateway can take up to 30 minutes, depending on factors such as the internet connection of the gateway and the size of the update.'
							: 'Rebooting a gateway can take up to 5 minutes.'}
					</DialogContentText>

					<FormControlLabel
						control={<Checkbox
							onChange={(_, checked) => setAcknowledgedWarning(checked)}
							checked={acknowledgedWarning}
							color="primary"
						/>}
						label="I acknowledge that I have read and understand the above information."
					/>
			</CustomActionDialogBox>}
		</FixedWidthPage>}
	/>;
});
