import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssignDeviceToAsset($includeAssets: Boolean!, $includeDevices: Boolean!) {
		assets @include(if: $includeAssets) {
			id
			name
			devices {
				id
				name
			}
		}
		devices @include(if: $includeDevices) {
			id
			name
			asset {
				id
				name
			}
		}
	}
`;
