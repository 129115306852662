import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateHyteraRadioDevice($input: UpdateHyteraRadioDeviceInput!) {
		updateHyteraRadioDevice(input: $input) {
			hyteraRadioDevice {
				id
			}
		}
	}
`;
