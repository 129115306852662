import React from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { useQueryEditDevice } from 'src/graphql/__generated__/queries/queryEditDevice';

import {
	Client as C,
	Service,
	useIdentityType,
	useInjection,
	ToasterService,
	SiteService,
} from 'src/services';

import { ErrorMessagePage, LoadingMessagePage } from 'src/components';
import { apiNetworksToDeviceNetworks, IDeviceNetwork, ManageDeviceComponent, ManageDeviceFormValues } from './manageDeviceComponent';
import { submitUpdateDevice } from './submitUpdateDevice';

export interface Props {
	match: match<{
		id: string,
	}>;
}

export const EditDevice = observer((props: Props) => {
	const identityType = useIdentityType();
	const apolloClient = useInjection<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const siteService = useInjection<SiteService>(Service.Site);
	const [networks, setNetworks] = React.useState<IDeviceNetwork[] | null>(null);
	const [loading, setLoading] = React.useState(true);

	useAsyncEffect(async () => {
		if (networks === null) {
			const apiNetworks = await siteService.getAllSites();
			setNetworks(apiNetworksToDeviceNetworks(apiNetworks));
			setLoading(false);
		}
	}, []);

	const query = useQueryEditDevice({
		variables: {
			id: props.match.params.id,
			includeDealers: identityType === C.IdentityType.SuperUser,
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		},
	});

	if (loading || query.loading)
		return <LoadingMessagePage />;

	if (query.error || !query.data?.device)
		return <ErrorMessagePage />;

	const device = query.data.device;

	const submit = async (values: ManageDeviceFormValues) => {
		try {
			await submitUpdateDevice(apolloClient, values, device);
			toasterService.showSuccess('Saved changes.');

			await query.refetch();
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to save changes.');
		}
	};

	return <ManageDeviceComponent
		device={device}
		dealers={query.data.dealers || undefined}
		clients={query.data.clients || undefined}
		bluetoothBeaconSets={query.data.bluetoothBeaconSets || undefined}
		networks={networks || undefined}
		submit={submit}
	/>;
});
