import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateAssetServiceRemindersUsersAndUserGroups($input: UpdateAssetServiceReminderUsersAndUserGroupsInput!) {
		updateAssetServiceRemindersUsersAndUserGroups(input: $input) {
			assetServiceReminders {
				id
			}
		}
	}
`;
