import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryEmergencyForEmergencyDetailsById($id: UUID!) {
		emergencyById(id: $id) {
			id
			status
			type
			generatedAt
			recordedAt
			asset {
				name
				emergencyNotes
			}
			safetyTimer {
				startTimestamp
				details
			}
			actions {
				performedAt
				user {
					name
				}
			}
			deviceIoConfiguration {
				name
				device {
					name
				}
			}
		}
	}
`;
