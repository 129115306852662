import React from 'react';
import { match } from 'react-router-dom';

import { ManageFloorPlanComponent } from './manageFloorPlanComponent';

export interface AddFloorPlanProps {
	match: match<{ floorPlanGroupId: string }>;
}

export const AddFloorPlan = (props: AddFloorPlanProps) => <ManageFloorPlanComponent
	floorPlanGroupId={props.match.params.floorPlanGroupId}
/>;
