import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';
import { ManageAssetTypesComponent } from './manageAssetTypesComponent';

import {
	Client as C,
	ClientService,
	AssetTypesService,
	AuthenticationService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

interface IPageData {
	assetType: C.IAssetTypeDto;
	clients: C.IClientDto[];
}

export const EditAssetType = observer((props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _clientService = useInjection<ClientService>(Service.Client);
	const _assetTypesService = useInjection<AssetTypesService>(Service.AssetType);

	const load = async (assetTypeId: string): Promise<IPageData> => {
		let clients: C.IClientDto[] = [];
		if (_authService.currentAuth.user.identity.type !== C.IdentityType.Client)
			clients = await _clientService.getAllClients() || [];

		const assetType = await _assetTypesService.getAssetTypeById(assetTypeId);

		return {
			assetType,
			clients,
		};
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(pageData: IPageData) => <ManageAssetTypesComponent
			assetType={pageData.assetType}
			clients={pageData.clients}
		/>}
	/>;
});
