import React, { useMemo, useState } from 'react';
import Card from '@material-ui/core/Card';
import { observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import DeleteIcon from '@material-ui/icons/Delete';

import {
	AuthenticationService,
	Client as C,
	GeofenceService,
	Service,
	ToasterService,
	DistanceService,
	useInjection,
} from 'src/services';

import { Button, CustomAutocomplete } from 'src/components';
import { GeofenceManager, GeofenceEditorState } from './geofenceManager';
import { convertFloorPlanXyToLatLng, convertMapBoxLatLngToXyDto } from 'src/util/latLngConverter';
import { MapViewMode } from './mapViewMode';
import { IOption } from 'src/util';

import './mapInfoBox.scss';

interface GeofenceEditBoxProps {
	geofenceManager: GeofenceManager;
	editorState: GeofenceEditorState;
	mapViewMode: MapViewMode;
	floorPlan?: C.IFloorPlanDto;
}

export const GeofenceEditBox = observer((props: GeofenceEditBoxProps) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _distanceService = useInjection<DistanceService>(Service.DistanceService);
	const _geofenceService = useInjection<GeofenceService>(Service.Geofence);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const existingGeofence = props.editorState.existingGeofence;

	const [geofenceName, setGeofenceName] = useState<string>(existingGeofence?.name ?? '');
	const [geofenceType, setGeofenceType] = useState<string | null>(existingGeofence?.geofenceType?.geofenceTypeId ?? null);
	const [assignToAllClientAssets, setAssignToAllClientAssets] = useState<boolean>(true);
	const [speedLimitEnabled, setSpeedLimitEnabled] = useState<boolean>(existingGeofence?.speedLimit != null ?? false);
	const [speedLimit, setSpeedLimit] = useState<number | null>(existingGeofence?.speedLimit ?? null);
	const [saving, setSaving] = useState<boolean>(false);

	const isGeofenceValid = (): boolean => {
		if (geofenceName.trim() === '')
			return false;

		if (!props.editorState.valid)
			return false;

		return true;
	};

	const deleteGeofence = () => {
		if (!props.editorState || !props.editorState.existingGeofence)
			return;

		const geofence = props.editorState.existingGeofence;

		if (!confirm(`Delete this geofence? (${geofence.name})`))
			return;

		try {
			_geofenceService.deleteGeofence(geofence.geofenceId);
			props.geofenceManager.stopEditingGeofence(false);
			_toasterService.showSuccess('Succesfully deleted geofence.');
		} catch (e) {
			_toasterService.handleWithToast(e, 'Failed to delete geofence.');
		}
	};

	const onClickCancelButton = () => {
		if (props.editorState.points.length > 0) {
			if (!confirm(`Cancel ${props.editorState.existingGeofence ? 'editing' : 'adding'} this geofence?`))
				return;
		}

		props.geofenceManager.stopEditingGeofence(true);
	};

	const onClickConfirmButton = async () => {
		setSaving(true);

		let geofenceWorldCoordinates: number[][] | null | undefined;
		if (props.floorPlan && props.mapViewMode === MapViewMode.FloorPlan) {
			geofenceWorldCoordinates = props.editorState.points
				.map(coordinates => {
					const xyCoordinates = convertMapBoxLatLngToXyDto(coordinates);
					return convertFloorPlanXyToLatLng(props.floorPlan!, xyCoordinates);
				})
				.map(x => [ x.longitude, x.latitude ]);
		} else {
			geofenceWorldCoordinates = props.editorState.points.map(x => [ x.lng, x.lat ]);
		}

		let requestSpeedLimit: number | undefined = undefined;
		if (speedLimitEnabled && speedLimit)
			requestSpeedLimit = _authenticationService.currentAuth.user.usesMetric ? speedLimit : _distanceService.convertMilesToKilometres(speedLimit);

		try {
			if (props.editorState.existingGeofence) {
				const updateGeofenceRequest: C.IUpdateGeofenceRequest = {
					name: geofenceName,
					geofenceTypeId: geofenceType,
					coordinates: geofenceWorldCoordinates,
					speedLimit: requestSpeedLimit,
				};

				const updatedGeofence = await _geofenceService.updateGeofence(props.editorState.existingGeofence.geofenceId, updateGeofenceRequest);
				props.geofenceManager.addGeofence(updatedGeofence);
			} else {
				const addGeofenceRequest: C.IAddGeofenceRequest = {
					name: geofenceName,
					clientId: _authenticationService.currentAuth.user.identity.clientId!,
					coordinates: geofenceWorldCoordinates,
					geofenceTypeId: geofenceType,
					floorPlanId: props.floorPlan?.floorPlanId,
					speedLimit: requestSpeedLimit,
					assignToAllClientAssets: assignToAllClientAssets,
				};

				const newGeofence = await _geofenceService.addGeofence(addGeofenceRequest);
				props.geofenceManager.addGeofence(newGeofence);
			}

			props.geofenceManager.updateGeofences();
		} catch (e) {
			_toasterService.handleWithToast(e, 'Failed to save geofence.');
			setSaving(false);
			return;
		}

		_toasterService.showSuccess('Succesfully saved geofence.');

		props.geofenceManager.stopEditingGeofence(false);
	};

	const onChangeGeofenceType = (e: IOption<string>) => {
		const geofenceTypeId = e.value === '' ? null : e.value;
		setGeofenceType(geofenceTypeId);
		props.geofenceManager.setEditingGeofenceTypeId(geofenceTypeId);
	};

	const geofenceTypeOptions = useMemo(() => {
		const options: IOption<string>[] = [ { label: 'Default', value: '' } ];

		for (const geofenceType of props.geofenceManager.geofenceTypes)
			options.push({ label: geofenceType.name, value: geofenceType.geofenceTypeId });

		return options;
	}, [props.geofenceManager.geofenceTypes]);

	const { editorState } = props;

	return <Card className="map-info-box" style={{ overflow: 'visible' }}>
		<div className="header">
			<span className="title">{editorState.existingGeofence ? 'Edit Geofence' : 'Add Geofence'}</span>
		</div>

		<div className="section">
			<TextField
				label="Geofence Name"
				fullWidth
				value={geofenceName}
				onChange={e => setGeofenceName(e.target.value)}
				variant="filled"
			/>

			<CustomAutocomplete
				label="Type"
				value={geofenceType || ''}
				onChange={onChangeGeofenceType}
				options={geofenceTypeOptions}
				getOptionLabel={x => x.label}
				getOptionValue={x => x.value}
				clearable={false}
			/>

			<FormControlLabel
				control={<Checkbox
					checked={speedLimitEnabled}
					onChange={() => setSpeedLimitEnabled(!speedLimitEnabled)}
					color="primary"
				/>}
				label="Enable Speed Limit"
			/>

			<TextField
				type="number"
				label="Speed Limit"
				fullWidth
				value={speedLimit}
				onChange={e => setSpeedLimit(parseInt(e.target.value))}
				variant="filled"
				InputProps={{
					endAdornment: <InputAdornment position="end">{_authenticationService.currentAuth.user.usesMetric ? 'km/h' : 'mph'}</InputAdornment>,
				}}
				helperText="Speed alerts will only be triggered if the asset sends location updates with travel speed information."
				disabled={!speedLimitEnabled}
			/>

			{!editorState.existingGeofence && <FormControlLabel
				className="assign-all-assets"
				control={<Checkbox
					checked={assignToAllClientAssets}
					onChange={() => setAssignToAllClientAssets(!assignToAllClientAssets)}
					color="primary"
				/>}
				label="Assign to all assets"
			/>}

		</div>

		<div className="section actions">
			<div>
				{editorState.existingGeofence && <Button
					className="delete"
					startIcon={<DeleteIcon />}
					disabled={saving}
					onClick={deleteGeofence}
					title="Delete geofence"
				/>}
			</div>

			<div>
				<Button
					text="Cancel"
					disabled={saving}
					onClick={onClickCancelButton}
				/>

				<Button
					text="OK"
					color="primary"
					variant="outlined"
					disabled={!isGeofenceValid()}
					loading={saving}
					onClick={onClickConfirmButton}
				/>
			</div>
		</div>
	</Card>;
});
