import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class PermissionManagementService {
	constructor(
		@inject(Service.ApiClient) private _client: C.Client,
	) {

	}

	async fetchUserGroupPermissions(userGroupId: string): Promise<C.IManagePermissionsCurrent> {
		return await this._client.getUserGroupPermissionsById(userGroupId);
	}

	async updateUserGroupPermissions(userGroupId: string, request: C.IManagePermissionsUpdateRequest): Promise<void> {
		return await this._client.updateUserGroupPermissionsById(userGroupId, new C.ManagePermissionsUpdateRequest(request));
	}
}
