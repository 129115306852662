import { inject, injectable } from 'inversify';
import { convertLatLngToXy } from 'src/util/latLngConverter';

import * as C from './client';
import { FloorPlansService } from './floorPlansService';
import { Service } from './service';
import { ToasterService } from './toasterService';

@injectable()
export class AssetService {

	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.Toaster) private _toasterService: ToasterService,
		@inject(Service.FloorPlans) private _floorPlanService: FloorPlansService,
	) {

	}

	async getAssetStates(floorPlans: Map<string, C.IFloorPlanDto>) {
		const assetStates = await this.client.getAssetStates();

		if (floorPlans.size > 0) {
			for (const state of assetStates) {
				if (state.assetLocation)
					state.assetLocation.floorPlanLocation = this._floorPlanService.getFloorPlanLocationForAssetLocation(floorPlans, state.assetLocation);
			}
		}

		return assetStates;
	}

	downloadAssetImportTemplateFile(deviceType: C.DeviceType) {
		return this.client.downloadAssetImportTemplateFile(deviceType);
	}

	uploadAssetImportFile(importAssetFromFileRequest: C.IImportFromFileRequest) {
		return this.client.importFromFile(new C.ImportFromFileRequest(importAssetFromFileRequest));
	}
}
