import React, { useState } from 'react';
import { observer } from 'mobx-react';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Button } from './button';
import { CustomActionDialogBox } from './customActionDialogBox';
import { Service, ToasterService, useInjection } from 'src/services';

import { useMutationActivateDevices } from 'src/graphql/__generated__/mutations/mutationActivateDevices';

interface Props {
	deviceIds: string[];
	close: () => void;
	onSuccess?: () => Promise<any>;
}

export const ActivateDevicesDialog = observer((props: Props) => {
	const toasterService = useInjection<ToasterService>(Service.Toaster);
	const [loading, setLoading] = useState<boolean>(false);

	const [activateDevices] = useMutationActivateDevices();

	const submit = async () => {
		setLoading(true);

		try {
			await activateDevices({
				variables: {
					input: {
						ids: props.deviceIds,
					},
				},
			});

			props.onSuccess && await props.onSuccess();
			props.close();
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to activate device(s).');
			setLoading(false);
		}
	};

	return <CustomActionDialogBox
		title={props.deviceIds.length === 1 ? `Activate Device?` : `Activate ${props.deviceIds.length} Devices?`}
		actionButton={<Button
			variant="contained"
			color="primary"
			text="Activate"
			onClick={submit}
			loading={loading}
		/>}
		dialogCloseCallback={props.close}
	>
		<DialogContentText>
			Activating a device allows it to record new information (e.g. locations and emergencies).
		</DialogContentText>
	</CustomActionDialogBox>;
});
