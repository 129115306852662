import React from 'react';
import { match } from 'react-router-dom';
import { css } from '@emotion/css';
import { QueryReport_reportById } from 'src/graphql/__generated__/queries/queryReport';

import {
	AuthenticationService,
	Client as C,
	ReportService,
	Service,
	useInjection,
} from 'src/services';

import { FixedWidthPage, ThingLoader, ReportParametersDisplay } from 'src/components';

export interface Props {
	match: match<{ id: string }>;
}

interface IBillingReportViewData {
	report: QueryReport_reportById;
	reportData: C.IBillingReportData;
}

const reportSummaryContainer = css`
	display: flex;
	flex-direction: column;

	margin-bottom: 20px;
`;

const summarySectionContainerStyle = css`
	display: flex;
	margin-top: 10px;

	> div, > p {
		width: 50%;

		.value-title {
			font-weight: bold;
		}

		.value {
			margin-right: 0px;
			margin-left: auto;
		}

		li {
			display: flex;
		}

		ul {
			width: 210px;
		}
	}
`;

export const ViewBillingReport = (props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _reportService = useInjection<ReportService>(Service.Report);

	const load = async (reportId: string): Promise<IBillingReportViewData> => {
		const report = _reportService.getReportById(reportId);
		const reportDataResponse = _reportService.getBillingReportData(reportId);

		return {
			report: await report,
			reportData: await reportDataResponse,
		};
	};

	const renderSummary = (summary: C.IBillingReportSummary, hasAssetPricing?: boolean) => {
		const isSuperUser = _authService.currentAuth.user.identity.type === C.IdentityType.SuperUser;

		const summaryTitle = isSuperUser ? summary.dealerName : summary.clientName;

		return <div>
			<h2>{summaryTitle == null ? `Unassigned` : summaryTitle}</h2>

			<div className={`content-box ${reportSummaryContainer}`}>
				<div className={summarySectionContainerStyle}>
					<div>
						<span className="value-title">Billing Code:</span> <span className="value">{summary.billingCode ?? 'None'}</span>
					</div>

					<div>
						<span className="value-title">Billable Months:</span> <span className="value">{summary.billableMonths ? summary.billableMonths.toFixed(2) : 0}</span>
					</div>
				</div>

				<div className={summarySectionContainerStyle}>
					<div>
						<span className="value-title">Billing Types</span>
						<ul>
							<li>Billable: <span className="value">{summary.billableDevices ?? 0}</span></li>
							<li>Non billable: <span className="value">{summary.nonBillableDevices ?? 0}</span></li>
						</ul>
					</div>

					<div>
						<span className="value-title">Activation State</span>
						<ul>
							<li>Activated: <span className="value">{summary.devicesActivated ?? 0}</span></li>
							<li>Not activated: <span className="value">{summary.devicesNotActivated ?? 0}</span></li>
							<li>Deactivated: <span className="value">{summary.devicesDeactivated ?? 0}</span></li>
						</ul>
					</div>
				</div>

				<div className={summarySectionContainerStyle}>
					<div>
						<span className="value-title">Additional Asset Features</span>
						<ul>
							<li>Indoor tracking: <span className="value">{summary.indoorTrackingDevices ?? 0}</span></li>
							{_authService.currentAuth.permissions.general.accessCalls && <>
								<li>Call recording: <span className="value">{summary.callRecordingDevices ?? 0}</span></li>
							</>}
						</ul>
					</div>

					<div>
						<span className="value-title">Other Items</span>
						<ul>
							{isSuperUser && <li>Gateways: <span className="value">{summary.gateways ?? 0}</span></li>}
							<li>Configuration updates: <span className="value">{summary.configurationUpdates ?? 0}</span></li>
							{_authService.currentAuth.permissions.general.accessCalls && <>
								<li>Call recording groups: <span className="value">{summary.callRecordingGroups ?? 0}</span></li>
							</>}
						</ul>
					</div>
				</div>

				{hasAssetPricing && <div className={summarySectionContainerStyle}>
					<div>
						<span className="value-title">Costs:</span>
						<ul>
							<li>Subtotal: <span className="value">${summary.subtotal ? (summary.subtotal! / 100).toFixed(2) : 0.0}</span></li>
							<li>Volume discount: <span className="value">${summary.volumeDiscount ? (summary.volumeDiscount! / 100).toFixed(2) : 0.0}</span></li>
							<li>Total: <span className="value">${summary.totalPrice ? (summary.totalPrice! / 100).toFixed(2) : 0.0}</span></li>
						</ul>
					</div>
				</div>}
			</div>
		</div>;
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(reportViewData: IBillingReportViewData) => <FixedWidthPage
			headingText="Billing Report"
			subheadingText={reportViewData.report.name}
			noContentBackground
			contentClassName="report-viewer view-billing-report"
		>
			{reportViewData.report.parameters && <ReportParametersDisplay
				reportParameters={reportViewData.report.parameters}
			/>}

			{reportViewData.reportData.billingReportSummaries &&
				reportViewData.reportData.billingReportSummaries.map(x => renderSummary(x, reportViewData.reportData.hasPricing))}
		</FixedWidthPage>}
	/>;
};
