import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { AssetServiceReminderStateBubble, AssetServiceReminderProps } from './assetServiceReminderStateBubble';

import { AssetServiceReminderState } from 'src/../__generated__/globalTypes';

interface Props extends Omit<AssetServiceReminderProps, 'state'> {
	totalNotExpiredReminders?: number;
	totalRemindersAboutToExpire?: number;
	totalExpiredReminders?: number;
	href: string;
}

export const AssetServiceRemindersStateBubblesDisplay = (props: Props) => {
	let gap = '15px';
	if (props.size === 'small')
		gap = '10px';
	else if (props.size === 'large')
		gap = '20px';

	const useStyles = makeStyles({
		serviceReminderStates: {
			display: 'flex',
			flexDirection: 'row',
			columnGap: gap,
			textDecoration: 'none',
		},
	});

	const classes = useStyles();

	return <Link to={props.href} className={classes.serviceReminderStates}>
		{props.totalNotExpiredReminders != undefined && <AssetServiceReminderStateBubble
			state={AssetServiceReminderState.NOT_EXPIRED}
			count={props.totalNotExpiredReminders}
			whiteBackgroundColour={props.whiteBackgroundColour}
			size={props.size}
		/>}

		{props.totalRemindersAboutToExpire != undefined && <AssetServiceReminderStateBubble
			state={AssetServiceReminderState.WITHIN_REMINDER_PERIOD}
			count={props.totalRemindersAboutToExpire}
			whiteBackgroundColour={props.whiteBackgroundColour}
			size={props.size}
		/>}

		{props.totalExpiredReminders != undefined && <AssetServiceReminderStateBubble
			state={AssetServiceReminderState.EXPIRED}
			count={props.totalExpiredReminders}
			whiteBackgroundColour={props.whiteBackgroundColour}
			size={props.size}
		/>}
	</Link>;
};
