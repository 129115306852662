import { IReportFormat } from './IReportFormat';

export class AssetLogHistoryReportFormat implements IReportFormat {
	HasAssetSelection = true;
	HasGeofenceSelection = false;
	HasMinimumTravelSpeedAndStopTime = false;
	HasSpeedLimit = false;
	HasBillingCodes = false;
	HasUserSelection = false;
	HasAddressBookEntrySelection = false;
	ReportDefinitionHelperText = 'The asset log history for one or more assets over a specified time period.';
}
