import React from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Divider, MenuList, MenuItem } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';

import { FixedWidthPage } from 'src/components';

import {
	Client as C,
	HistoryService,
	UserGroupService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import './manageUserGroup.scss';

export interface Props {
	userGroup: C.IUserGroupDto;
	children?: React.ReactNode;
}

export const ManageUserGroup = observer((props: Props) => {
	const _historyService = useInjection<HistoryService>(Service.History);
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const onClickDelete = async () => {
		const input = prompt('Are you sure you want to delete this user group?\n\nUsers belonging to this group may lose access to any assets granted to them via this group.\nThis cannot be undone.\n\nEnter the user group name to confirm:');
		if (!input)
			return;

		if (input !== props.userGroup.name) {
			_toasterService.showWarning('The name you entered did not match the user group you attempted to delete. No changes have been made.');
			return;
		}

		try {
			await _userGroupService.deleteUserGroup(props.userGroup.userGroupId);

			_historyService.history.push('/app/user-groups/list');
			_toasterService.showSuccess(`${props.userGroup.name} has been deleted successfully.`);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to delete asset group.');
		}
	};

	return <FixedWidthPage
		headingText={props.userGroup.name}
		pageName={<Switch>
			<Route exact path="/app/user-groups/:id/edit" component={() => <>Edit Details</>} />
			<Route exact path="/app/user-groups/:id/permissions" component={() => <>Permissions</>} />
			<Route exact path="/app/user-groups/:id/assets" component={() => <>Assets</>} />
			<Route exact path="/app/user-groups/:id/users" component={() => <>Users</>} />
		</Switch>}
		noContentBackground
		contentClassName="user-group-test"
	>
		<div className="content-box menu">
			<MenuList>
				<NavLink to={`/app/user-groups/${props.userGroup.userGroupId}/edit`} activeClassName="menu__item--active"><MenuItem>Edit Details</MenuItem></NavLink>
				<NavLink to={`/app/user-groups/${props.userGroup.userGroupId}/permissions`} activeClassName="menu__item--active"><MenuItem>Permissions</MenuItem></NavLink>
				<Divider />
				<NavLink to={`/app/user-groups/${props.userGroup.userGroupId}/assets`} activeClassName="menu__item--active"><MenuItem>Assets</MenuItem></NavLink>
				<NavLink to={`/app/user-groups/${props.userGroup.userGroupId}/users`} activeClassName="menu__item--active"><MenuItem>Users</MenuItem></NavLink>
				{props.userGroup.type === C.UserGroupType.UserCreated && [
					<Divider />,
					<MenuItem onClick={onClickDelete}>Delete</MenuItem>
				]}
			</MenuList>
		</div>

		<div className="content">
			{props.children}
		</div>
	</FixedWidthPage>;
});
