import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import WaveSurfer  from 'wavesurfer.js';
const CursorPlugin = require('wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js') as any;
import moment from 'moment-timezone';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { getPrettyName } from 'src/util';
import { CallDetails, CallTimeElapsedContainer, AudioControls } from './callPlayerComponents';

import { shortDateShortTimeFormat } from 'src/util/dateTimeFormats';

import { QueryCallById_callById } from 'src/graphql/__generated__/queries/queryCallById';

const PlayerButton = styled(IconButton)({
	border: '1px solid currentColor',
	backgroundColor: 'white'
});

const WaveFormAndLoadingIcon = styled(Box)({
	display: 'grid',
	gridTemplateRows: 'auto',
	gridTemplateColumns: 'auto',
});

const WaveFormPosition = styled('div')({
	position: 'relative', // This value is required or the waveform cursor will be as tall as parent.
	gridColumn: '1 / 1',
	gridRow: '1 / 1',
});

interface Props {
	call: QueryCallById_callById;
	canGoToNextCall: boolean;
	canGoToPreviousCall: boolean;
	onClickPreviousOrNext: (nextCall: boolean) => void;
	onDownload: (callId: string) => Promise<void>;
}

enum CallState {
	Playing,
	Paused,
	Stopped,
}

const getFormattedTime = (time: number) => {
	const minutes = ('00' + Math.floor((time % 3600) / 60)).slice(-2);
	const seconds = ('00' + Math.floor(time % 60)).slice(-2);
	return minutes + ':' + seconds;
};

export const CallPlayer = (props: Props) => {
	const waveform = React.useRef<HTMLDivElement>(null);

	const [playingCallState, setPlayingCallState] = React.useState<CallState>(CallState.Stopped);
	const [timeElapsed, setTimeElapsed] = React.useState<number>(0);
	const [durationOfCall, setDurationOfCall] = React.useState<number>(0);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [waveSurfer, setWaveSurfer] = React.useState<WaveSurfer | null>(null);

	React.useEffect(() => {
		if (!waveform.current)
			return;

		const waveSurferInit = WaveSurfer.create({
			container: waveform.current!,
			waveColor: '#757ce8',
			backgroundColor: 'lightgray',
			plugins: [
				CursorPlugin.create({
					showTime: true,
					opacity: 1,
					customShowTimeStyle: {
						'background-color': '#000',
						color: '#fff',
						padding: '2px',
						'font-size': '10px'
					},
					formatTimeCallback: (timeSeconds: number) => {
						const seconds = '0' + Math.round(timeSeconds % 60);
						const minutes =  '0' + Math.round(timeSeconds / 60);

						return `${minutes.slice(-2)}:${seconds.slice(-2)}`;
					}
				}),
			]
		});

		waveSurferInit.on('ready', () => {
			setDurationOfCall(waveSurferInit.getDuration());
			setLoading(false);
		});

		waveSurferInit.on('audioprocess', (time: number) => {
			setTimeElapsed(time);
		});

		waveSurferInit.on('finish', () => {
			waveSurferInit.stop();

			setTimeElapsed(0);
			setPlayingCallState(CallState.Stopped);
		});

		waveSurferInit.on('seek', (percentageOfCallSkipped: number) => {
			if (!waveSurferInit.isPlaying() && percentageOfCallSkipped != 0) {
				waveSurferInit.play();

				setPlayingCallState(CallState.Playing);
			}
		});

		setWaveSurfer(waveSurferInit);

		return () => {
			if (waveSurferInit.isPlaying())
				waveSurferInit.stop;

			waveSurferInit.destroy();
		};
	}, [waveform]);

	React.useEffect(() => {
		if (waveSurfer && props?.call?.audioUrl) {
			if (waveSurfer.isPlaying())
				waveSurfer.stop;

			setPlayingCallState(CallState.Stopped);

			waveSurfer.load(props.call.audioUrl);
			setLoading(true);
		}
	}, [props.call]);

	React.useEffect(() => {
		if (!waveSurfer)
			return;

		if (props?.call?.audioUrl)
			waveSurfer.load(props.call.audioUrl);

	}, [waveSurfer]);

	async function startOrPauseAudio() {
		if (!waveSurfer)
			return;

		setPlayingCallState(playingCallState === CallState.Playing ? CallState.Paused : CallState.Playing);

		waveSurfer.playPause();
	}

	function renderCallPlayerControls() {
		return <AudioControls>
			<PlayerButton
				size="medium"
				color="primary"
				disabled={!props.canGoToPreviousCall}
				onClick={() => props.onClickPreviousOrNext(false)}
			>
				<SkipPreviousIcon/>
			</PlayerButton>

			<PlayerButton
				size="medium"
				color="primary"
				onClick={startOrPauseAudio}
				disabled={loading}
			>
				{playingCallState !== CallState.Playing ? <PlayArrowIcon /> : <PauseIcon />}
			</PlayerButton>

			<PlayerButton
				size="medium"
				color="primary"
				disabled={!props.canGoToNextCall}
				onClick={() => props.onClickPreviousOrNext(true)}
			>
				<SkipNextIcon/>
			</PlayerButton>
		</AudioControls>;
	}

	return <Box>
		<CallDetails>
			<Box>
				<Typography><strong>Time:</strong> {moment(props.call.startTimestamp).format(shortDateShortTimeFormat)}</Typography>
				<Typography><strong>Type:</strong> {getPrettyName(props.call.type)}</Typography>
			</Box>

			<PlayerButton
				size="medium"
				color="primary"
				onClick={() => props.onDownload(props.call!.id)}
			>
				<CloudDownloadIcon />
			</PlayerButton>
		</CallDetails>

		<WaveFormAndLoadingIcon>
			{/* Cannot use Box here as it does not have ref till V5 of material UI*/}
			<WaveFormPosition ref={waveform}></WaveFormPosition>
		</WaveFormAndLoadingIcon>

		<CallTimeElapsedContainer>
			<Typography>{getFormattedTime(timeElapsed)}</Typography>

			{renderCallPlayerControls()}

			<Typography>{getFormattedTime(durationOfCall)}</Typography>
		</CallTimeElapsedContainer>
	</Box>;
};
