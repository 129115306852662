import React from 'react';
import { match } from 'react-router-dom';
import moment from 'moment-timezone';
import { QueryReport_reportById } from 'src/graphql/__generated__/queries/queryReport';

import {
	AuthenticationService,
	Client as C,
	ReportService,
	Service,
	useInjection,
} from 'src/services';

import { FixedWidthPage, ThingLoader, ReportParametersDisplay } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';

import './reportViewer.scss';
import './safetyTimer.scss';

export interface Props {
	match: match<{ id: string }>;
}

interface ReportViewData {
	report: QueryReport_reportById;
	reportData: C.ISafetyTimerReportData;
}

export const ViewSafetyTimerReport = (props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _reportService = useInjection<ReportService>(Service.Report);

	const load = async (reportId: string): Promise<ReportViewData> => {
		const report = _reportService.getReportById(reportId);
		const reportData = _reportService.getSafetyTimerReportData(reportId);

		return {
			report: await report,
			reportData: await reportData,
		};
	};

	const renderRow = (action: C.ISafetyTimerReportRow, rowNumber: number) => {
		const timestamp = moment.tz(action.timestamp, _authService.currentAuth.user.timeZone).format(longDateTimeFormat);
		const type = getSafetyTimerAction(action.type);

		return <tr
			key={rowNumber}
			className="content-box view-safety-timer"
		>
			<td className="timestamp-column">{timestamp}</td>
			<td className="name-column">{action.assetName}</td>
			<td className="type-column">{type}</td>
			<td className="details-column">{action.details ?? ''}</td>
		</tr>;
	};

	const getSafetyTimerAction = (type: C.SafetyTimerReportRowType | undefined): string => {
		switch (type) {
			case C.SafetyTimerReportRowType.CheckIn:
				return 'Check-in';
			case C.SafetyTimerReportRowType.EmergencyTriggered:
				return 'Emergency Triggered';
			case C.SafetyTimerReportRowType.StartSafetyTimer:
				return 'Start';
			case C.SafetyTimerReportRowType.EndSafetyTimer:
				return 'End';
			default:
				return 'Unknown';
		}
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(reportViewData: ReportViewData) => <FixedWidthPage
			headingText="Safety Timer Report"
			subheadingText={reportViewData.report.name}
			noContentBackground
			contentClassName="report-viewer"
		>
			{reportViewData.report.parameters && <ReportParametersDisplay
				reportParameters={reportViewData.report.parameters}
			/>}

			<div>
				<div className="report-section">
					<table className="card-table">
						<thead>
							<tr>
								<th>Timestamp</th>
								<th>Asset</th>
								<th>Action</th>
								<th>Details</th>
							</tr>
						</thead>

						<tbody>
							{reportViewData.reportData.data.map(renderRow)}
						</tbody>
					</table>
				</div>
			</div>
		</FixedWidthPage>}
	/>;
};
