import React, { useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Checkbox } from '@material-ui/core';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import GroupIcon from '@material-ui/icons/Group';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { Button, FixedWidthPage, MaterialTable, MessagePage, PopoverMenu, PopoverMenuItem, ThingLoader } from 'src/components';

import { executeQueryDealerList, QueryDealerList_dealers } from 'src/graphql/__generated__/queries/queryDealerList';

import {
	AuthenticationService,
	Client as C,
	ClientService,
	HistoryService,
	Service,
	ToasterService,
	useInjection,
	UsersService,
} from 'src/services';

interface IUserRow {
	name: string;
	email: string;
	identityType: string;
	dealer?: string;
	client?: string;
	userId: string;
	isActivated: boolean;
}

interface IPageData {
	userTypes: { [key: string]: string };
	userData: IUserRow[];
}

export const UsersList = observer(() => {
	const _apolloClient = useInjection<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	const _auth = useInjection<AuthenticationService>(Service.Authentication);
	const _clientService = useInjection<ClientService>(Service.Client);
	const _usersService = useInjection<UsersService>(Service.Users);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const _hasUserTypeColumn = _auth.currentAuth.user.identity.type === C.IdentityType.SuperUser ||
		_auth.currentAuth.user.identity.type === C.IdentityType.Dealer;

	const _hasDealerColumn = _auth.currentAuth.user.identity.type === C.IdentityType.SuperUser;
	const _hasClientColumn = _auth.currentAuth.user.identity.type === C.IdentityType.SuperUser || _auth.currentAuth.user.identity.type === C.IdentityType.Dealer;

	const load = async (): Promise<IPageData | null> => {
		const allUsers = await _usersService.getAllUsers();
		if (!allUsers)
			return null;

		let dealers: QueryDealerList_dealers[] = [];
		if (_auth.currentAuth.user.identity.type === C.IdentityType.SuperUser) {
			const dealersListQuery = await executeQueryDealerList(_apolloClient);

			if (dealersListQuery.error || !dealersListQuery.data)
				throw dealersListQuery || 'Failed to load dealers.';

			dealers = dealersListQuery.data.dealers ?? [];
		}

		let clients: C.IClientDto[] = [];
		if (_auth.currentAuth.user.identity.type === C.IdentityType.SuperUser || _auth.currentAuth.user.identity.type === C.IdentityType.Dealer)
			clients = await _clientService.getAllClients() || [];

		const userData = allUsers.map((user): IUserRow => {
			let dealer: QueryDealerList_dealers | undefined = undefined;
			let client: C.IClientDto | undefined = undefined;

			if (user.identity.type === C.IdentityType.Dealer) {
				dealer = dealers.find(x => x.id === user.identity.dealerId);
			} else if (user.identity.type === C.IdentityType.Client) {
				client = clients.find(x => x.clientId === user.identity.clientId);
				dealer = client && dealers.find(x => x.id === client!.dealerId);
			}

			return {
				name: user.name,
				email: user.emailAddress,
				identityType: user.identity.type,
				dealer: dealer?.name,
				client: client?.name,
				userId: user.userId,
				isActivated: user.isActivated,
			};
		});

		const userTypes: { [key: string]: string } = {};
		const types = new Set(allUsers.map(x => x.identity.type));
		for (const type of types)
			userTypes[type] = type;

		return {
			userTypes,
			userData,
		};
	};

	const onClickDelete = async (userId: string, reload: Function) => {
		if (!confirm('Are you sure you want to delete this user?'))
			return;

		await _usersService.deleteUser(userId);
		reload();
	};

	const onClickSignInAs = async (userId: string) => {
		try {
			await _auth.signInAs(userId);
			_historyService.history.push(`/app`);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to sign in as the selected user.');
		}
	};

	const onClickSendActivationEmail = async (userId: string) => {
		try {
			await _usersService.sendActivationEmail(userId);
			_toasterService.showSuccess('Sent user activation email.');
		} catch (e) {
			_toasterService.handleWithToast(e);
		}
	};

	const renderActionMenu = (user: IUserRow, reload: Function) => {
		const options: JSX.Element[] = [];

		if (_auth.currentAuth.permissions.general.signInAs) {
			options.push(<PopoverMenuItem
				key="signInAs"
				text="Sign in as this user"
				onClick={() => onClickSignInAs(user.userId)}
			/>);
		}

		options.push(<PopoverMenuItem
			key="edit"
			text="Edit"
			href={`/app/users/${user.userId}/edit`}
		/>);

		if (!user.isActivated) {
			options.push(<PopoverMenuItem
				key="sendActivation"
				text="Send Activation Email"
				onClick={() => onClickSendActivationEmail(user.userId)}
			/>);
		}

		if (user.identityType === C.IdentityType.Client) {
			options.push(<PopoverMenuItem
				key="alert-settings"
				text="Alert & Emergency Settings"
				href={`/app/users/${user.userId}/settings/alerts`}
			/>);
		}

		options.push(<PopoverMenuItem
			key="delete"
			text="Delete"
			onClick={() => onClickDelete(user.userId, reload)}
		/>);

		return options;
	};

	const canManageUserGroups = _auth.currentAuth.permissions.general.manageUserGroups;

	return <ThingLoader
		load={load}
		render={(pageData: IPageData, reload: Function) => <FixedWidthPage
			headingText="User Management"
			headingActions={<>
				{canManageUserGroups && <Button text="User Groups" startIcon={<GroupIcon />} variant="outlined" color="primary" href="/app/user-groups/list" />}
				<Button text="Add" startIcon={<PersonAddIcon />} variant="outlined" color="primary" href="/app/users/add" />
			</>}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': pageData.userData.length === 0 })}
		>
			<MaterialTable
				tableName="users-list"
				columns={[
					{
						title: 'Name',
						field: 'name',
						grouping: false,
					},
					{
						title: 'Email Address',
						field: 'email',
						grouping: false,
					},
					{
						title: 'User Type',
						field: 'identityType',
						hidden: !_hasUserTypeColumn,
						hiddenByColumnsButton: !_hasUserTypeColumn,
						lookup: pageData.userTypes,
					},
					{
						title: 'Dealer',
						hidden: !_hasDealerColumn,
						hiddenByColumnsButton: !_hasDealerColumn,
						field: 'dealer',
					},
					{
						title: 'Client',
						hidden: !_hasClientColumn,
						hiddenByColumnsButton: !_hasClientColumn,
						field: 'client',
					},
					{
						title: 'Options',
						field: 'options',
						filtering: false,
						grouping: false,
						sorting: false,
						headerStyle: ({
							textAlign: 'right',
						}),
						cellStyle: () => ({
							textAlign: 'right',
						}),
						render: rowData => <PopoverMenu
							renderOptions={() => renderActionMenu(pageData.userData.find(x => x.userId === rowData.userId)!, reload)}
						/>,
					},
				]}
				data={pageData.userData}
				options={{
					grouping: _auth.currentAuth.user.identity.type !== C.IdentityType.Client,
				}}
			/>

			{pageData.userData.length === 0 && <MessagePage
				title="No users."
				action={<Button href="/app/users/add" text="Add a user?" variant="outlined" />}
			/>}
		</FixedWidthPage>}
	/>;
});
