import React, { useState } from 'react';
import { match } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import {
	Client as C,
	HistoryService,
	Service,
	ToasterService,
	useInjection,
	UsersService,
} from 'src/services';

import { Button, FixedWidthPage, ThingLoader } from 'src/components';

interface Props {
	match: match<{ id: string }>;
}

export const UserGatewayAlertSettings = (props: Props) => {
	const _userService  = useInjection<UsersService>(Service.Users);

	const load = async (userId: string): Promise<C.IUserGatewayAlertSettingsDto | null> => {
		return await _userService.getUserGatewayAlertSettings(userId);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(settings: C.IUserGatewayAlertSettingsDto) => <EditSettings
			userId={props.match.params.id}
			existingSettings={settings}
		/>}
	/>;
};

interface EditProps {
	userId: string;
	existingSettings: C.IUserGatewayAlertSettingsDto;
}

const EditSettings = (props: EditProps) => {
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _userService  = useInjection<UsersService>(Service.Users);

	const [alertsEnabled, setalertsEnabled] = useState<boolean>(props.existingSettings.alertsEnabled);
	const [saving, setSaving] = useState<boolean>(false);

	const onSave = async () => {
		const updateRequest: C.IUpdateUserGatewayAlertSettingsRequest = {
			alertsEnabled: alertsEnabled,
		};

		try {
			setSaving(true);

			await _userService.saveUserGatewayAlertSettings(props.userId, updateRequest);

			_toasterService.showSuccess('Settings saved successfully');
			_historyService.history.push('/app/gateways/list');
		} catch (err) {
			setSaving(false);
			_toasterService.handleWithToast(err, 'Failed to save settings.');
		}
	};

	return <FixedWidthPage
		headingText="Gateway Alert Settings"
		headingActions={<Button
			className="save-button"
			variant="contained"
			color="primary"
			onClick={onSave}
			text="Save settings"
			loading={saving}
		/>}
	>
		<FormControl component={'fieldset' as 'div'} className="alerts-enabled">
			<RadioGroup
				className="alerts-enabled-buttons"
				value={alertsEnabled ? 'on' : 'off'}
				onChange={() => setalertsEnabled(!alertsEnabled)}
			>
				<FormControlLabel value="on" control={<Radio />} label="Alerts enabled" />
				<FormControlLabel value="off" control={<Radio />} label="Alerts disabled" />
			</RadioGroup>
		</FormControl>
	</FixedWidthPage>;
};
