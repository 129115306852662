import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationResolveAssetServiceReminder($input: ResolveAssetServiceReminderInput!) {
		resolveAssetServiceReminder(input: $input) {
			assetServiceReminder {
				id
			}
		}
	}
`;
