import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { useInjection, ToasterService, Service } from 'src/services';

const CopyButton = styled(IconButton)({
	marginRight: '10px',
	width: '15px',
	height: '15px',

	'& svg': {
		width: '15px',
		height: '15px',
	},
});

interface Props {
	id: string;
}

export const ListCopyIdButton = (props: Props) => {
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const copyIdToClipboard = () => {
		navigator.clipboard.writeText(props.id!);
		toasterService.showSuccess(`Copied ID to clipboard.`);
	};

	return <CopyButton
		title="Copy ID"
		onClick={copyIdToClipboard}
		>
		<FileCopyIcon />
	</CopyButton>;
};
