import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { css } from '@emotion/css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import { IMapAsset } from './mapAsset';
import { Button, CustomActionDialogBox } from 'src/components';

import {
	Client as C,
} from 'src/services';

export interface LiveMapFilter {
	status?: 'Active' | 'Inactive';
	assetGroups?: string[];
	assetTypes?: string[];
	lastUpdate?: moment.Moment;
}

const liveMapFilerDialogStyle = css`
	.MuiPaper-root {
		min-width: 300px;
	}

	.MuiTextField-root,
	.MuiFormControl-root {
		width: 100%;
	}

	.MuiDialogContent-root > div {
		margin-bottom: 10px;
	}
`;

interface Props {
	onClose: () => void;
	onApply: (filter?: LiveMapFilter) => void;
	liveMapFilter?: LiveMapFilter;
	assets: IMapAsset[];
	assetGroups: C.IAssetGroupDto[];
	assetTypes: C.IAssetTypeDto[];
}

export const LiveMapAssetFilterDialog = (props: Props) => {

	const onChangeAssetGroups = (event: React.ChangeEvent, value: C.IAssetGroupDto[]) => {
		props.onApply({
			...props.liveMapFilter,
			assetGroups: value.map(x => x.assetGroupId),
		});
	};

	const onChangeAssetTypes = (event: React.ChangeEvent, value: C.IAssetTypeDto[]) => {
		props.onApply({
			...props.liveMapFilter,
			assetTypes: value.map(x => x.assetTypeId),
		});
	};

	const onChangeStatus = (event: React.ChangeEvent, value: string) => {
		props.onApply({
			...props.liveMapFilter,
			status: value as any,
		});
	};

	const onChangeLastUpdate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		props.onApply({
			...props.liveMapFilter,
			lastUpdate: moment(event.target.value),
		});
	};

	const assetGroupOptions = useMemo(() => {
			return props.assetGroups.filter(x => x.type === C.AssetGroupType.UserCreated);
	}, [props.assetGroups]);

	const selectedAssetGroups = useMemo(() => {
		const filterGroups = props.liveMapFilter?.assetGroups;
		if (!filterGroups)
			return [];

		return assetGroupOptions.filter(x => filterGroups.includes(x.assetGroupId));
	}, [props.liveMapFilter?.assetGroups, assetGroupOptions]);

	const selectedAssetTypes = useMemo(() => {
		const filterTypes = props.liveMapFilter?.assetTypes;
		if (!filterTypes)
			return [];

		return props.assetTypes.filter(x => filterTypes.includes(x.assetTypeId));
	}, [props.liveMapFilter?.assetTypes, props.assetTypes]);

	return <CustomActionDialogBox
		className={liveMapFilerDialogStyle}
		dialogCloseCallback={props.onClose}
		title="Filter Assets"
		actionButton={<Button color="primary" variant="contained" onClick={props.onClose} text="Done" />}
		secondaryButton={{ text: 'Clear', action: () => props.onApply(undefined) }}
	>
		{assetGroupOptions.length > 0 && <Autocomplete
			multiple
			disableCloseOnSelect
			options={assetGroupOptions}
			value={selectedAssetGroups}
			getOptionSelected={(option: C.IAssetGroupDto, value: C.IAssetGroupDto) => option.assetGroupId === value.assetGroupId}
			onChange={onChangeAssetGroups}
			getOptionLabel={option => option.name}
			renderInput={params => <TextField
				{...params}
				label="Asset Group"
				margin="dense"
			/>}
		/>}

		{props.assetTypes.length > 0 && <Autocomplete
			multiple
			disableCloseOnSelect
			options={props.assetTypes}
			value={selectedAssetTypes}
			getOptionSelected={(option: C.IAssetTypeDto, value: C.IAssetTypeDto) => option.assetTypeId === value.assetTypeId}
			onChange={onChangeAssetTypes}
			getOptionLabel={option => option.name}
			renderInput={params => <TextField
				{...params}
				label="Asset Type"
				margin="dense"
			/>}
		/>}

		<Autocomplete
			options={[ 'Active', 'Inactive' ]}
			value={props.liveMapFilter?.status || null}
			onChange={onChangeStatus}
			renderInput={params => <TextField
				{...params}
				label="Status"
				margin="dense"
			/>}
		/>

		<TextField
			label="Last Update"
			type="date"
			value={props.liveMapFilter?.lastUpdate?.format('YYYY-MM-DD') || ''}
			onChange={onChangeLastUpdate}
			margin="dense"
			InputLabelProps={{
				shrink: true,
			}}
		/>
	</CustomActionDialogBox>;
};
