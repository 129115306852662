import React from 'react';
import { observer } from 'mobx-react';

import AddIcon from '@material-ui/icons/Add';

import { AssetServiceReminderStateDisplay, Button, FixedWidthPage, MaterialTable, ErrorMessagePage, LoadingMessagePage, PopoverMenu, PopoverMenuItem } from 'src/components';

import { useQueryAssetServiceReminderTypes } from 'src/graphql/__generated__/queries/queryAssetServiceReminderTypes';
import { useMutationDeleteAssetServiceReminderTypes } from 'src/graphql/__generated__/mutations/mutationDeleteAssetServiceReminderTypes';

import {
	AuthenticationService,
	Client as C,
	Service,
	ToasterService,
	useIdentityType,
	useInjection,
} from 'src/services';

export const AssetServiceRemindersTypesList = observer(() => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const identityType = useIdentityType();

	const [ deleteAssetServiceReminderTypes ] = useMutationDeleteAssetServiceReminderTypes();

	const assetServiceReminderTypesQuery = useQueryAssetServiceReminderTypes({
		variables: {
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		},
	});

	if (assetServiceReminderTypesQuery.loading)
		return <LoadingMessagePage />;

	if (assetServiceReminderTypesQuery.error || !assetServiceReminderTypesQuery.data?.assetServiceReminderTypes)
		return <ErrorMessagePage />;

	const deleteAssetServiceReminderType = async (reminderTypeId: string) => {
		try {
			await deleteAssetServiceReminderTypes({
				variables: {
					input: {
						ids: [ reminderTypeId ],
					}
				}
			});

			toasterService.showSuccess(`Asset service reminder deleted.`);
			await assetServiceReminderTypesQuery.refetch();
		} catch (err) {
			toasterService.handleWithToast(err, `Failed to delete asset service reminder.`);
		}
	};

	const assetServiceReminderTypes = assetServiceReminderTypesQuery.data.assetServiceReminderTypes;

	return <FixedWidthPage
		headingText="Asset Service Reminder Types"
		headingActions={<Button href="/app/asset-service-reminder-types/add" text="Add Reminder Type" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
		noContentBackground
	>
		<MaterialTable
			tableName="asset-service-reminder-types-list"
			columns={[
				{
					title: 'Name',
					field: 'name',
					grouping: false,
				},
				{
					title: 'Client',
					field: 'client.name',
					grouping: true,
					hidden: authenticationService.currentAuth.user.identity.type == C.IdentityType.Client,
				},
				{
					title: 'Reminder States',
					field: 'state',
					grouping: false,
					filtering: false,
					sorting: false,
					render: rowData => {
						return <AssetServiceReminderStateDisplay
							serviceReminderType={rowData}
							href={`/app/asset-service-reminders/${rowData.id}/list`}
						/>;
					},
				},
				{
					title: 'Message',
					field: 'message',
					grouping: false,
					sorting: false,
					filtering: false,
				},
				{
					title: 'Options',
					field: 'options',
					grouping: false,
					filtering: false,
					sorting: false,
					headerStyle: ({
						textAlign: 'right',
					}),
					cellStyle: () => ({
						textAlign: 'right',
					}),
					render: rowData => <PopoverMenu
						renderOptions={() => [
							<PopoverMenuItem
								key="view/add reminders"
								text="View/add reminders"
								href={`/app/asset-service-reminders/${rowData.id}/list`}
							/>,
							<PopoverMenuItem
								key="edit"
								text="Edit"
								href={`/app/asset-service-reminder-types/${rowData.id}/edit`}
							/>,
							<PopoverMenuItem
								key="delete"
								text="Delete"
								onClick={() => deleteAssetServiceReminderType(rowData.id)}
							/>,
						]}
					/>,
				},
			]}
			data={assetServiceReminderTypes}
			options={{
				grouping: authenticationService.currentAuth.user.identity.type !== C.IdentityType.Client,
			}}
		/>
	</FixedWidthPage>;
});
