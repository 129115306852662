import React from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { ViewEmergency } from './viewEmergency';

export const Emergency = observer(() => {
	return <Switch>
		<Route exact path="/emergency/:id" component={ViewEmergency} />
		<Route component={() => <Redirect to="/auth/sign-in" />} />
	</Switch>;
});
