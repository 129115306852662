import React, { useState } from 'react';
import { Formik, Form, FormikProps, Field, FormikHelpers } from 'formik';
import { featureToggleCallRecording } from 'src/config';

import SaveIcon from '@material-ui/icons/Save';

import {
	AuthenticationService,
	Client as C,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import { Button, FormikCheckbox } from 'src/components';

import './editPermissionsComponent.scss';

interface EditPermissionsFormValues {
	accessCallRecording?: boolean | null;
	signInAs?: boolean | null;
	manageFloorPlans?: boolean | null;
	manageUsersAndPermissions: boolean;
	useReporting: boolean;
	useSafetyTimer?: boolean | null;
}

interface UserProps {
	user: C.IUserDto;
	userGroup?: undefined;
}

interface UserGroupProps {
	user?: undefined;
	userGroup: C.IUserGroupDto;
}

type EditPermissionsProps = (UserProps | UserGroupProps) & {
	permissions: C.IManagePermissionsCurrent;
	onSubmit: (request: C.IManagePermissionsUpdateRequest) => Promise<void>;
};

export const EditPermissionsComponent = (props: EditPermissionsProps) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [initialFormValues, setInitialFormValues] = useState<EditPermissionsFormValues>({
		accessCallRecording: props.permissions.accessCallRecordings,
		signInAs: props.permissions.signInAs,
		manageFloorPlans: props.permissions.manageFloorPlans,
		manageUsersAndPermissions: props.permissions.manageUsersAndPermissions,
		useReporting: props.permissions.useReporting,
		useSafetyTimer: props.permissions.useSafetyTimer,
	});

	const onSubmit = async (values: EditPermissionsFormValues, { setSubmitting }: FormikHelpers<EditPermissionsFormValues>) => {
		const initial = { ...initialFormValues };
		const request: C.IManagePermissionsUpdateRequest = {};

		if (values.accessCallRecording != initial.accessCallRecording) {
			request.accessCallRecordings = values.accessCallRecording;
			initial.accessCallRecording = values.accessCallRecording;
		}

		if (values.signInAs != initial.signInAs) {
			request.signInAs = values.signInAs;
			initial.signInAs = values.signInAs;
		}

		if (values.manageFloorPlans != initial.manageFloorPlans) {
			request.manageFloorPlans = values.manageFloorPlans;
			initial.manageFloorPlans = values.manageFloorPlans;
		}

		if (values.manageUsersAndPermissions != initial.manageUsersAndPermissions) {
			request.manageUsersAndPermissions = values.manageUsersAndPermissions;
			initial.manageUsersAndPermissions = values.manageUsersAndPermissions;
		}

		if (values.useReporting != initial.useReporting) {
			request.useReporting = values.useReporting;
			initial.useReporting = values.useReporting;
		}

		if (values.useSafetyTimer != initial.useSafetyTimer) {
			request.useSafetyTimer = values.useSafetyTimer;
			initial.useSafetyTimer = values.useSafetyTimer;
		}

		try {
			await props.onSubmit(request);
			_toasterService.showSuccess('Permissions changed successfully.');

			// Update the initial values for the page now as they have changed.
			// We have to do this as we do not change to another page after updating, so the initial values would never change.
			setInitialFormValues(initial);
		} catch (err) {
			_toasterService.handleWithToast(err);
		}

		setSubmitting(false);
	};

	const canManageUserGroupSafetyTimers = _authenticationService.currentAuth.permissions.general.manageUserSafetyTimerPermissions;

	return <Formik
		initialValues={initialFormValues}
		validateOnChange={false}
		onSubmit={onSubmit}
		render={(formikProps: FormikProps<EditPermissionsFormValues>) => <Form className="formik-form">
			<div className="content-box permission-box">
				<h2>General</h2>

				<Field
					name="manageUsersAndPermissions"
					label="Manage users and permissions"
					type="checkbox"
					component={FormikCheckbox}
				/>

				{initialFormValues.signInAs != null && <Field
					name="signInAs"
					label="Allow signing in as other users"
					type="checkbox"
					component={FormikCheckbox}
				/>}

				<Field
					name="manageFloorPlans"
					label="Manage floor plans"
					type="checkbox"
					component={FormikCheckbox}
				/>

				<Field
					name="useReporting"
					label="Use reporting"
					type="checkbox"
					component={FormikCheckbox}
				/>

				{canManageUserGroupSafetyTimers && <Field
					name="useSafetyTimer"
					label="Use safety timer"
					type="checkbox"
					component={FormikCheckbox}
				/>}

				{featureToggleCallRecording === 'true' && <Field
					name="accessCallRecording"
					label="Access call recordings"
					type="checkbox"
					component={FormikCheckbox}
				/>}
			</div>

			<Button
				className="form-submit-button"
				type="submit"
				variant="contained"
				color="primary"
				startIcon={<SaveIcon />}
				text="Save Changes"
				loading={formikProps.isSubmitting}
			/>
		</Form>}
	/>;
};
