import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddAssetServiceReminder($input: AddAssetServiceReminderInput!) {
		addAssetServiceReminder(input: $input) {
			assetServiceReminder {
				id
			}
		}
	}
`;
