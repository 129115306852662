import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { Button, MessagePage } from 'src/components';
import { LinkedDeviceService, Service, ToasterService, useInjection } from 'src/services';

interface Props {
	deviceId: string;
	close: () => void;
}

export const DeviceLinkDialog = (props: Props) => {
	const _linkedDeviceService = useInjection<LinkedDeviceService>(Service.LinkedDevice);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [code, setCode] = useState<string | null>();

	useAsyncEffect(async () => {
		try {
			const newCode = await _linkedDeviceService.createDeviceLinkCode({ deviceId: props.deviceId });
			setCode(newCode.code);
		} catch (err) {
			props.close();
			_toasterService.handleWithToast(err, 'Failed to generate linking code.');
		}
	}, [props.deviceId]);

	return <Dialog
		open={true}
		onClose={() => props.close()}
	>
		<DialogTitle>Link Device</DialogTitle>

		{!code && <DialogContent>
			<DialogContentText>
				<MessagePage loading title="Generating link code..." />
			</DialogContentText>
		</DialogContent>}

		{code && <DialogContent>
			<DialogContentText>
				Link device with the code:<br />
				<span className="device-link-dialog__code">{code}</span>
			</DialogContentText>
		</DialogContent>}

		<DialogActions>
			<Button
				type="submit" variant="contained" color="primary"
				text="OK"
				onClick={() => props.close()}
			/>
		</DialogActions>
	</Dialog>;
};
