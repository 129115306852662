import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';

import { EditEnableFleetConfigurationFieldsComponent, Props as EditEnableFleetConfigurationFieldsComponentProps } from './editEnableFleetConfigurationFieldsComponent';

import {
	EnableFleetConfigurationService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditEnableFleetConfigurationFields = observer((props: Props) => {
	const _enableFleetConfigurationService = useInjection<EnableFleetConfigurationService>(Service.EnableFleetConfiguration);

	const load = async (configurationId: string): Promise<EditEnableFleetConfigurationFieldsComponentProps | null> => {
		const configuration = await _enableFleetConfigurationService.getEnableFleetConfiguration(configurationId);
		if (!configuration)
			return null;

		const customizationProfileFields = await _enableFleetConfigurationService.getEnableFleetCustomizationProfileFields(configurationId);
		const configurationFields = await _enableFleetConfigurationService.getEnableFleetConfigurationFields(configurationId);

		return {
			configuration,
			configurationFields,
			customizationProfileFields,
		};
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(props: EditEnableFleetConfigurationFieldsComponentProps) => <EditEnableFleetConfigurationFieldsComponent
			configuration={props.configuration}
			configurationFields={props.configurationFields}
			customizationProfileFields={props.customizationProfileFields}
		/>}
	/>;
});
