import React from 'react';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles({
	label: {
		fontWeight: 700,
		marginRight: '10px',
	},
	button: {
		marginTop: '10px',
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'row'
	}
});

interface IProps<OptionsType> {
	label?: string;
	values: OptionsType[];
	getOptionLabel: (option: OptionsType) => string;
	maxNumberOfValues: number;
}

export function CollectionsDisplayer<OptionsType>(props: IProps<OptionsType>) {
	const [showHiddenValues, setShowHiddenValues] = React.useState<boolean>(false);

	const classes = useStyles();

	const valuesToShow = props.values.slice(0, props.maxNumberOfValues);
	const valuesToHide = props.values.slice(props.maxNumberOfValues);

	function onButtonClick() {
		setShowHiddenValues(!showHiddenValues);
	}

	return <div>
		{props.label && <span className={classes.label}>{props.label}</span>}

		{/* Show the max amount of values, and show a "Show More" button if there are more to show. */}
		<div className={classes.contentContainer}>
			<div>
				{valuesToShow.map(x => props.getOptionLabel(x)).join(', ')}
				{showHiddenValues && `, ${valuesToHide.map(x => props.getOptionLabel(x)).join(', ')}`}
			</div>
			<div>
				{valuesToHide.length > 0 && <IconButton
					onClick={onButtonClick}
				>
					{showHiddenValues && <KeyboardArrowUpIcon/>}
					{!showHiddenValues && <KeyboardArrowDownIcon/>}
				</IconButton>}
			</div>
		</div>
	</div>;
}
