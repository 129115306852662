import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import RefreshIcon from '@material-ui/icons/Refresh';

import { FixedWidthPage, Button, LoadingMessagePage, ErrorMessagePage } from 'src/components';
import { ConnectionStatus } from './../../services/webSocketService';
import { GatewayLogViewer } from './gatewayLogViewer';

import './gatewayStatus.scss';

import {
	Client as C,
	AuthenticationService,
	GatewayService,
	WebSocketService,
	Service,
	useInjection,
	GatewayLogsService,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const GatewayStatus = observer((props: Props) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _gatewayService = useInjection<GatewayService>(Service.Gateway);
	const _gatewayLogsService = useInjection<GatewayLogsService>(Service.GatewayLogs);
	const _webSocketService = useInjection<WebSocketService>(Service.WebSocket);

	const [gateway, setGateway] = useState<C.IGatewayDto | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useAsyncEffect(async () => {
		setLoading(true);

		try {
			const response = await _gatewayService.getGateway(props.match.params.id);
			setGateway(response || null);
		} catch (e) {
			setGateway(null);
		}

		setLoading(false);
	}, [props.match.params.id]);

	useEffect(() => {
		if (!gateway)
			return;

		if (_webSocketService.status === ConnectionStatus.Connected)
			_gatewayLogsService.monitorGateway(gateway.gatewayId);
		else
			_gatewayLogsService.stopMonitoringGateway();

		return () => _gatewayLogsService.stopMonitoringGateway();
	}, [gateway?.gatewayId, _webSocketService.status]);

	useEffect(() => {
		document.addEventListener('mousemove', _gatewayLogsService.userActive);
		return () => document.removeEventListener('mousemove', _gatewayLogsService.userActive);
	}, [_gatewayLogsService]);

	if (loading)
		return <LoadingMessagePage />;

	if (!gateway)
		return <ErrorMessagePage />;

	const webSocketConnected = _webSocketService.status === ConnectionStatus.Connected;

	return <FixedWidthPage
		className="gateway-status-page"
		headingText="Gateway Status"
		subheadingText={gateway.name}
	>
		<div className="logs-heading-values">
			{webSocketConnected && !_gatewayLogsService.monitoringGatewayId && <Button
				className="reconnect-button"
				startIcon={<RefreshIcon />}
				text="Reconnect To Gateway"
				onClick={() => _gatewayLogsService.monitorGateway(gateway.gatewayId)}
				variant="outlined"
				color="primary"
			/>}
		</div>

		<GatewayLogViewer
			logs={_gatewayLogsService.logs}
			timezone={_authenticationService.currentAuth.user.timeZone}
			connected={webSocketConnected && !!_gatewayLogsService.monitoringGatewayId}
		/>
	</FixedWidthPage>;
});
