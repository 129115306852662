import React from 'react';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react';

import { FormikSelect } from 'src/components';

import {
	IUserGroupSelectorFormValues,
} from 'src/services';

import { IOption } from 'src/util';

interface Props {
	options: IOption<string>[] | null;
	formikProps: FormikProps<IUserGroupSelectorFormValues>;
	isLoading?: boolean;
	className?: string;
}

export const UserGroupSelector = observer((props: Props) => {
	if (!props.options)
		return null;

	return <FormikSelect
		name="userGroupIds"
		placeholder="Select User Groups"
		label="User Groups"
		form={props.formikProps}
		isLoading={props.isLoading}
		multi
		className={props.className}
		options={props.options.slice()}
		getOptionLabel={option => option.label}
		getOptionValue={option => option.value}
		disableCloseOnSelect
	/>;
});
