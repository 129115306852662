import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import AddIcon from '@material-ui/icons/Add';

import { FixedWidthPage, MessagePage, ThingLoader, Button, PopoverMenu, PopoverMenuItem } from 'src/components';

import {
	Client as C,
	EnableFleetConfigurationService,
	Service,
	useInjection,
} from 'src/services';

export const EnableFleetConfigurationsList = observer(() => {
	const _enableFleetConfigurationService = useInjection<EnableFleetConfigurationService>(Service.EnableFleetConfiguration);

	const load = async () => {
		const configurations = await _enableFleetConfigurationService.listEnableFleetConfigurations();
		if (!configurations)
			return null;

		return configurations.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
	};

	const renderActionMenu = (configuration: C.IEnableFleetConfigurationDto) => {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/enablefleet/${configuration.enableFleetConfigurationId}/edit`}
			/>,
			<PopoverMenuItem
				key="fields"
				text="Manage Fields"
				href={`/app/enablefleet/${configuration.enableFleetConfigurationId}/fields`}
			/>,
		];
	};

	return <ThingLoader
		load={load}
		render={(configurations: C.IEnableFleetConfigurationDto[]) => <FixedWidthPage
			headingText="EnableFleet Configurations"
			headingActions={<Button href="/app/enablefleet/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': configurations.length === 0 })}
		>
			{configurations.length > 0 && <table className="card-table">
				<thead>
					<tr>
						<th>Configuration Name</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{configurations.map(x =>
					<tr className="content-box"
						key={x.enableFleetConfigurationId}
					>
						<td>{x.name}</td>
						<td className="actions">
							<PopoverMenu
								renderOptions={() => renderActionMenu(x)}
							/>
						</td>
					</tr>)}
				</tbody>
			</table>}

			{configurations.length === 0 && <MessagePage
				title="No configurations."
				action={<Button href="/app/enablefleet/add" text="Add a configuration?" variant="outlined" />}
			/>}
		</FixedWidthPage>}
	/>;
});
