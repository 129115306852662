import React from 'react';
import { FormikProps } from 'formik';

import { DateTimeRangePickerProps, DateTimeRange, DateTimeRangePicker } from 'src/components';

import './formik.scss';

interface FormikDateTimeRangePickerProps<V> {
	name: string;
	label: string;
	form: FormikProps<V>;
}

export function FormikDateTimeRangePicker<V>(props: FormikDateTimeRangePickerProps<V> & Partial<DateTimeRangePickerProps>) {
	const onChange = (value: DateTimeRange) => {
		props.form.setFieldValue(props.name, value);
		props.form.setFieldTouched(props.name, true);
	};

	const {
		name,
		label,
		form: { touched, errors, values },
		...otherProps
	} = props;

	// TODO: Make these work without using any.
	const fieldError = (errors as any)[name];
	const fieldTouched = (touched as any)[name];
	const value = (values as any)[name] as DateTimeRange;

	return <div className="formik-field">
		<DateTimeRangePicker
			label={label}
			value={value}
			onChange={onChange}
			{...otherProps}
		/>

		{fieldTouched && fieldError && <div className="error">
			<div className="triangle"></div>
			<div className="contents">{fieldError}</div>
		</div>}
	</div>;
}
