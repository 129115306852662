import { QueryDevicesList_devices, QueryDevicesList_devices_ATrackDevice } from 'src/graphql/__generated__/queries/queryDevicesList';
import { ATrackDeviceModelType, DeviceType } from 'src/../__generated__/globalTypes';

import * as C from 'src/services/client';

export function getDeviceConfigurationTypeFormatted(type: C.DeviceConfigurationType): string {
	switch (type) {
		case C.DeviceConfigurationType.ATrack_AK1:
			return 'ATrack AK1';

		case C.DeviceConfigurationType.ATrack_AK7:
			return 'ATrack AK7';

		case C.DeviceConfigurationType.ATrack_AK7V:
			return 'ATrack AK7V';

		case C.DeviceConfigurationType.ATrack_AL7:
			return 'ATrack AL7';

		default:
			return 'Unknown';
	}
}

export function getDeviceConfigurationTypeFileType(type: C.DeviceConfigurationType): string | null {
	switch (type) {
		case C.DeviceConfigurationType.ATrack_AK1:
		case C.DeviceConfigurationType.ATrack_AK7:
		case C.DeviceConfigurationType.ATrack_AK7V:
		case C.DeviceConfigurationType.ATrack_AL7:
			return '.txt';

		default:
			return null;
	}
}

function isATrackDevice(device: QueryDevicesList_devices): device is QueryDevicesList_devices_ATrackDevice {
	return device.deviceType === DeviceType.ATRACK;
}

export function deviceCanUseConfigurationType(device: QueryDevicesList_devices, type: C.DeviceConfigurationType): boolean {
	if (!isATrackDevice(device))
		return false;

	return (device.atrackModelType === ATrackDeviceModelType.AK1 && type === C.DeviceConfigurationType.ATrack_AK1) ||
		(device.atrackModelType === ATrackDeviceModelType.AK7 && type === C.DeviceConfigurationType.ATrack_AK7) ||
		(device.atrackModelType === ATrackDeviceModelType.AK7V && type === C.DeviceConfigurationType.ATrack_AK7V) ||
		(device.atrackModelType === ATrackDeviceModelType.AL7 && type === C.DeviceConfigurationType.ATrack_AL7);
}
