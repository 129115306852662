import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Formik, Form, Field, FormikProps, FormikHelpers, FormikErrors } from 'formik';

import {
	AuthenticationService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import { Button, FormikTextField, MessagePage } from 'src/components';
import { runFormValidation } from 'src/util';

interface SignInFormValues {
	emailAddress: string;
	password: string;
}

const validateForm = (values: SignInFormValues, errors: FormikErrors<SignInFormValues>) => {
	if (!values.emailAddress)
		errors.emailAddress = 'Email address is required.';

	if (!values.password)
		errors.password = 'Password is required.';
};

export const SignIn = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _toaster = useInjection<ToasterService>(Service.Toaster);

	const onSubmit = async (values: SignInFormValues, { setSubmitting }: FormikHelpers<SignInFormValues>) => {
		try {
			await _authService.authenticate(values.emailAddress, values.password);
			// Authentication reaction handles sign in success.
		} catch (err) {
			_toaster.handleWithToast(err, 'Failed to sign in.');
			setSubmitting(false);
		}
	};

	if (_authService.isAttemptingReauthentication)
		return <MessagePage loading />;

	return <div>
		<h1>Sign in</h1>

		<Formik
			initialValues={{
				emailAddress: '',
				password: '',
			}}
			validate={values => runFormValidation(values, validateForm)}
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<SignInFormValues>) => <Form className="formik-form sign-in">
				<Field
					name="emailAddress"
					label="Email address"
					type="text"
					component={FormikTextField}
				/>

				<Field
					name="password"
					label="Password"
					type="password"
					component={FormikTextField}
				/>

				<div className="forgot-password">
					<Link to={!formikProps.values.emailAddress ? '/auth/reset-password' : `/auth/reset-password?emailAddress=${formikProps.values.emailAddress}`}>
						Forgot password?
					</Link>
				</div>

				<div className="auth-action">
					<Button
						type="submit" variant="contained" color="primary"
						text="Sign in"
						loading={formikProps.isSubmitting}
					/>
				</div>
			</Form>}
		/>
	</div>;
});
