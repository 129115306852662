import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddBeacon($input: AddBluetoothBeaconInput!) {
		addBluetoothBeacon(input: $input) {
			bluetoothBeacon {
				id
			}
		}
	}
`;
