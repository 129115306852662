import React, { useMemo, useState } from 'react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import moment from 'moment-timezone';
import { InputAdornment } from '@material-ui/core';
import useAsyncEffect from 'use-async-effect';

import { AddressBookEntrySelector, AssetSelector, AssetGroupSelector, Button, FixedWidthPage, FormikCheckbox, FormikSelect, FormikTextField, FormikTimePicker, MessagePage, UserSelector, UserGroupSelector } from 'src/components';
import { runFormValidation, validateIsNumber } from 'src/util';

import { IReportFormat, getReportTypeFormatFromReportType } from 'src/app/reports/formats/IReportFormat';
import { reportTypes, reportFrequency } from 'src/app/reports/reportOptions';
import { BillingCodesHelper } from 'src/app/reports/billingCodesHelper';

import './manageScheduledReportComponent.scss';

import {
	Client as C,
	AuthenticationService,
	DistanceService,
	HistoryService,
	ReportService,
	ToasterService,
	Service,
	useInjection,
	useUserSelectorService,
	useUserGroupSelectorService,
	useAddressBookEntrySelectorService,
	useAssetSelectorService,
	useAssetGroupSelectorService,
} from 'src/services';

interface ManageScheduledReportFormValues {
	name: string;
	reportType: C.ReportType;
	enabled: boolean;
	addressBookEntries: string[];
	assets: string[];
	assetGroups: string[];
	identityIds: string[];
	userGroupIds: string[];
	frequency: C.ReportFrequency;
	nextReportDate: string;
	timeStamp: string;
	minimumTravelSpeed: number | null;
	minimumStopTime: number | null;
	billingCodes: string[];
}

interface Props {
	scheduledReportParameters?: C.IScheduledReportParametersDto;
}

export const ManageScheduledReportComponent = (props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _distanceService = useInjection<DistanceService>(Service.DistanceService);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _reportService = useInjection<ReportService>(Service.Report);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const _addressBookEntrySelectorService = useAddressBookEntrySelectorService();
	const _assetSelectorService = useAssetSelectorService();
	const _assetGroupSelectorService = useAssetGroupSelectorService(C.ListAssetGroupsType.Report);
	const _userSelectorService = useUserSelectorService();
	const _userGroupSelectorService = useUserGroupSelectorService();

	const [loading, setLoading] = useState<boolean>(true);
	const [billingCodeOptions, setBillingCodeOptions] = useState<string[]>([]);

	const initialReportType = _authService.currentAuth.user.identity.type === C.IdentityType.Client ? C.ReportType.Activity : C.ReportType.Billing;
	const [reportFormat, setReportFormat] = useState<IReportFormat>(getReportTypeFormatFromReportType(props.scheduledReportParameters?.reportType ?? initialReportType));

	const [initialFormValues, setInitialFormValues] = useState<ManageScheduledReportFormValues>(() => {
		const now = moment.tz(_authService.currentAuth.user.timeZone);

		const scheduledReportParameters = props.scheduledReportParameters;
		if (scheduledReportParameters) {
			return {
				name: scheduledReportParameters.name,
				reportType: scheduledReportParameters.reportType,
				enabled: scheduledReportParameters.enabled,
				addressBookEntries: scheduledReportParameters.addressBookEntryIds != null ? scheduledReportParameters!.addressBookEntryIds : [],
				assets: scheduledReportParameters.assetIds != null ? scheduledReportParameters!.assetIds : [],
				assetGroups: scheduledReportParameters.assetGroupIds != null ? scheduledReportParameters!.assetGroupIds : [],
				identityIds: scheduledReportParameters.identityIds != null ? scheduledReportParameters!.identityIds : [],
				userGroupIds: scheduledReportParameters.userGroupIds != null ? scheduledReportParameters!.userGroupIds : [],
				frequency: scheduledReportParameters.frequency,
				nextReportDate: scheduledReportParameters.nextReportDate!,
				timeStamp: scheduledReportParameters.timeOfDay,
				minimumTravelSpeed: scheduledReportParameters.minimumTravelSpeed != null ? scheduledReportParameters!.minimumTravelSpeed : 5,
				minimumStopTime: scheduledReportParameters.minimumStopTime != null ? scheduledReportParameters!.minimumStopTime : 120,
				billingCodes: scheduledReportParameters.billingCodes ?? [],
			};
		} else {
			return {
				name: '',
				reportType: initialReportType,
				enabled: true,
				assets: [],
				addressBookEntries: [],
				assetGroups: [],
				identityIds: [],
				userGroupIds: [],
				frequency: C.ReportFrequency.Daily,
				nextReportDate: moment.tz(_authService.currentAuth.user.timeZone).add(1, 'day').format('YYYY-MM-DD'),
				timeStamp: now.clone().add(1, 'day').startOf('day').format('HH:mm'),
				minimumTravelSpeed: 5,
				minimumStopTime: 120,
				billingCodes: [],
			};
		}
	});

	useAsyncEffect(async () => {
		const billingTypesHelper = new BillingCodesHelper();
		const billingCodes = await billingTypesHelper.getBillingCodes();
		setBillingCodeOptions(billingCodes);

		setLoading(false);
	}, []);

	const handleReportTypeChange = (newReportType: { value: string, label: string } | { value: string, label: string }[] | null) => {
		if (newReportType == null || Array.isArray(newReportType))
			return;

		const newReportFormat = getReportTypeFormatFromReportType(newReportType.value as C.ReportType);
		setReportFormat(newReportFormat);
	};

	const validateForm = (values: ManageScheduledReportFormValues, errors: FormikErrors<ManageScheduledReportFormValues>) => {
		if (!values.name)
			errors.name = 'Name is required.';

		if (!values.nextReportDate)
			errors.nextReportDate = 'Next report date is required.';

		if (!values.timeStamp)
			errors.timeStamp = 'Report time is required.';

		if (values.assets.length === 0 && values.assetGroups.length === 0) {
			errors.assets = 'At least one asset or asset group is required.';
			errors.assetGroups = 'At least one asset or asset group is required.';
		}

		if (reportFormat.HasAddressBookEntrySelection) {
			if (values.addressBookEntries.length === 0)
				errors.addressBookEntries = 'At least one address book entry is required.';
		}

		if (reportFormat.HasAssetSelection) {
			if (values.assets.length === 0 && values.assetGroups.length === 0) {
				errors.assets = 'At least one asset or asset group is required.';
				errors.assetGroups = 'At least one asset or asset group is required.';
			}
		}

		if (reportFormat.HasMinimumTravelSpeedAndStopTime) {
			if (values.minimumTravelSpeed == null || !validateIsNumber(values.minimumTravelSpeed))
				errors.minimumTravelSpeed = 'Minimum travel speed must be a valid number.';
			else if (values.minimumTravelSpeed < 0)
				errors.minimumTravelSpeed = 'Minimum travel speed must be at least 0.';

			if (values.minimumStopTime == null || !validateIsNumber(values.minimumStopTime))
				errors.minimumStopTime = 'Minimum stop time must be a valid number.';
			else if (values.minimumStopTime < 0)
				errors.minimumStopTime = 'Minimum stop time must be at least 0.';
		}

		if (reportFormat.HasUserSelection) {
			if (values.identityIds.length === 0 && values.userGroupIds.length === 0) {
				errors.identityIds = 'At least one user or user group is required.';
				errors.userGroupIds = 'At least one user or user group is required.';
			}
		}
	}

	const addScheduledReport = async (values: ManageScheduledReportFormValues): Promise<boolean> => {
		const request: C.IAddScheduledReportRequest = {
			name: values.name,
			reportType: values.reportType,
			frequency: values.frequency,
			nextRunDate: values.nextReportDate,
			timeOfDay: values.timeStamp,
			addressBookEntries: reportFormat.HasAddressBookEntrySelection && values.addressBookEntries.length > 0 ? values.addressBookEntries : null,
			assetIds: reportFormat.HasAssetSelection && values.assets.length > 0 ? values.assets : null,
			assetGroupIds: reportFormat.HasAssetSelection && values.assetGroups.length > 0 ? values.assetGroups : null,
			identityIds: reportFormat.HasUserSelection && values.identityIds.length > 0 ? values.identityIds : null,
			userGroupIds: reportFormat.HasUserSelection && values.userGroupIds.length > 0 ? values.userGroupIds : null,
			minimumStopTime: reportFormat.HasMinimumTravelSpeedAndStopTime ? values.minimumStopTime : null,
			minimumTravelSpeed: reportFormat.HasMinimumTravelSpeedAndStopTime ? values.minimumTravelSpeed : null,
			billingCodes: reportFormat.HasBillingCodes && values.billingCodes.length > 0 ? values.billingCodes : null,
		};

		try {
			await _reportService.addScheduledReport(request);
			_toasterService.showSuccess('Scheduled report added.');
			_historyService.history.push('/app/reports/scheduled');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to add scheduled report.');
			return false;
		}
	};

	const getChanges = (hasType: boolean, current: string[], original?: string[] | null): { toAdd: string[], toRemove: string[] } => {
		let toAdd: string[] = [];
		let toRemove: string[] = [];

		if (hasType) {
			if (original) {
				toAdd = current.filter(x => !original.includes(x));
				toRemove = original.filter(x => !current.includes(x));
			} else {
				toAdd = current;
			}
		}

		return {
			toAdd,
			toRemove,
		};
	};

	const updateScheduledReport = async (values: ManageScheduledReportFormValues): Promise<boolean> => {
		const originalValues = props.scheduledReportParameters!;

		let newMininumStopTime;
		if (reportFormat.HasMinimumTravelSpeedAndStopTime && originalValues.minimumStopTime != values.minimumStopTime)
			newMininumStopTime = values.minimumStopTime;
		else
			newMininumStopTime = null;

		let newMininumTravelSpeed;
		if (reportFormat.HasMinimumTravelSpeedAndStopTime && originalValues.minimumTravelSpeed != values.minimumTravelSpeed)
			newMininumTravelSpeed = values.minimumTravelSpeed;
		else
			newMininumTravelSpeed = null;

		const addressBookChanges = getChanges(reportFormat.HasAddressBookEntrySelection, values.addressBookEntries, originalValues.addressBookEntryIds);
		const assetIdChanges = getChanges(reportFormat.HasAssetSelection, values.assets, originalValues.assetIds);
		const assetGroupIdChanges = getChanges(reportFormat.HasAssetSelection, values.assetGroups, originalValues.assetGroupIds);

		const identityIdChanges = getChanges(reportFormat.HasUserSelection, values.identityIds, originalValues.identityIds);
		const userGroupIdChanges = getChanges(reportFormat.HasUserSelection, values.userGroupIds, originalValues.userGroupIds);

		const billingCodeChanges = getChanges(reportFormat.HasBillingCodes, values.billingCodes, originalValues.billingCodes);

		const updateRequest: C.IUpdateScheduledReportRequest = {
			name: values.name != originalValues.name ? values.name : null,
			enabled: values.enabled != originalValues.enabled ? values.enabled : null,
			frequency: values.frequency != originalValues.frequency ? values.frequency : undefined,
			nextRunDate: values.nextReportDate != originalValues.nextReportDate ? values.nextReportDate : undefined,
			timeOfDay: values.timeStamp != originalValues.timeOfDay ? values.timeStamp : null,
			minimumStopTime: newMininumStopTime,
			minimumTravelSpeed: newMininumTravelSpeed,

			// Address book selection.
			addressBookEntriesToAdd : addressBookChanges.toAdd.length > 0 ? addressBookChanges.toAdd : null,
			addressBookEntriesRemove : addressBookChanges.toRemove.length > 0 ? addressBookChanges.toRemove : null,

			// Asset selection.
			assetIdsToAdd: assetIdChanges.toAdd.length > 0 ? assetIdChanges.toAdd : null,
			assetIdsToRemove: assetIdChanges.toRemove.length  > 0 ? assetIdChanges.toRemove : null,
			assetGroupIdsToAdd: assetGroupIdChanges.toAdd.length > 0 ? assetGroupIdChanges.toAdd : null,
			assetGroupIdsToRemove: assetGroupIdChanges.toRemove.length > 0 ? assetGroupIdChanges.toRemove : null,

			// User selection.
			identityIdsToAdd: identityIdChanges.toAdd.length > 0 ? identityIdChanges.toAdd : null,
			identityIdsToRemove: identityIdChanges.toRemove.length  > 0 ? identityIdChanges.toRemove : null,
			userGroupIdsToAdd: userGroupIdChanges.toAdd.length > 0 ? userGroupIdChanges.toAdd : null,
			userGroupIdsToRemove: userGroupIdChanges.toRemove.length > 0 ? userGroupIdChanges.toRemove : null,

			// Billing codes.
			billingCodesToAdd: billingCodeChanges.toAdd.length > 0 ? billingCodeChanges.toAdd : null,
			billingCodesToRemove: billingCodeChanges.toRemove.length > 0 ? billingCodeChanges.toRemove : null,
		};

		try {
			await _reportService.updateScheduledReport(props.scheduledReportParameters!.scheduledReportId, updateRequest);
			_toasterService.showSuccess('Scheduled report updated.');
			_historyService.history.push('/app/reports/scheduled');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update scheduled report.');
			return false;
		}
	};

	const onSubmit = async (values: ManageScheduledReportFormValues, { setSubmitting }: FormikHelpers<ManageScheduledReportFormValues>) => {
		let success;
		if (props.scheduledReportParameters)
			success = await updateScheduledReport(values);
		else
			success = await addScheduledReport(values);

		if (!success)
			setSubmitting(false);
	};

	const renderFrquencySelectors = (formikProps: FormikProps<ManageScheduledReportFormValues>) => {
		return <>
			<FormikSelect
				name="frequency"
				label="Report Frequency"
				placeholder="Select a report frequency..."
				options={reportFrequency}
				clearable={false}
				isLoading={loading}
				form={formikProps}
				required
				getOptionLabel={option => option.label}
				getOptionValue={option => option.value}
			/>

			<Field
				name="nextReportDate"
				label="Next Report Date"
				type="date"
				component={FormikTextField}
				required
			/>

			<FormikTimePicker
				name="timeStamp"
				label="Time"
				form={formikProps}
				variant="filled"
				fullWidth={true}
				className="time-picker"
				required
			/>
		</>;
	};

	const renderMinStopAndTravelTimeInputs = () => {
		return <>
			<Field
				name="minimumTravelSpeed"
				label="Travel Speed"
				type="number"
				component={FormikTextField}
				InputProps={{
					endAdornment: <InputAdornment position="end">{_authService.currentAuth.user.usesMetric ? 'km/h' : 'mph' }</InputAdornment>,
				}}
				helperText="How fast assets must travel to be considered travelling."
			/>

			<Field
				name="minimumStopTime"
				label="Stop Time"
				type="number"
				component={FormikTextField}
				InputProps={{
					endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
				}}
				helperText="How long assets must stop to be considered stopped."
			/>
		</>;
	};

	const allReportTypes = useMemo(() => reportTypes(_authService.currentAuth.permissions.general)
			.sort((a, b) => a.value!.localeCompare(b.value!, undefined, { numeric: true })),
		[ _authService.currentAuth.permissions.general ]);

	if (loading || _addressBookEntrySelectorService.loading ||
		_assetSelectorService.loading || _assetGroupSelectorService.loading ||
		_userSelectorService.loading || _userGroupSelectorService.loading)
		return <MessagePage loading />;

	const addingNewScheduledReport = !props.scheduledReportParameters;

	return <FixedWidthPage
		className="form-page"
		headingText={addingNewScheduledReport ? 'Create Scheduled Report' : 'Edit Scheduled Report'}
		subheadingText={addingNewScheduledReport ? null : props.scheduledReportParameters!.name}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageScheduledReportFormValues>) => <Form className="formik-form scheduled-report-form"
		>
				<Field
					name="name"
					label="Report Name"
					component={FormikTextField}
					required
				/>

				<FormikSelect
					name="reportType"
					label="Report Type"
					placeholder="Select a report type..."
					options={allReportTypes}
					disabled={props.scheduledReportParameters != null}
					clearable={false}
					isLoading={loading}
					form={formikProps}
					onChange={handleReportTypeChange}
					helperText={reportFormat.ReportDefinitionHelperText}
					getOptionLabel={option => option.label}
					getOptionValue={option => option.value}
				/>

				{renderFrquencySelectors(formikProps)}

				{reportFormat.HasAddressBookEntrySelection && <AddressBookEntrySelector
					options={_addressBookEntrySelectorService.addressBookEntryOptions}
					formikProps={formikProps}
				/>}

				{reportFormat.HasAssetSelection && <>
					<AssetSelector
						options={_assetSelectorService.assetOptions}
						formikProps={formikProps}
					/>

					<AssetGroupSelector
						options={_assetGroupSelectorService.assetGroupOptions}
						formikProps={formikProps}
						isLoading={loading}
					/>
				</>}

				{reportFormat.HasUserSelection && <>
					<UserSelector
						options={_userSelectorService.identityOptions}
						formikProps={formikProps}
					/>

					<UserGroupSelector
						options={_userGroupSelectorService.userGroupOptions}
						formikProps={formikProps}
						isLoading={loading}
					/>
				</>}

				{reportFormat.HasMinimumTravelSpeedAndStopTime && renderMinStopAndTravelTimeInputs()}

				{reportFormat.HasBillingCodes && <FormikSelect
					name="billingCodes"
					label="Billing Codes"
					placeholder="Select billing codes..."
					multi={true}
					options={billingCodeOptions}
					form={formikProps}
					getOptionLabel={option => option}
					getOptionValue={option => option}
				/>}

				{props.scheduledReportParameters && <Field
					name="enabled"
					label="Enabled"
					type="checkbox"
					component={FormikCheckbox}
				/>}

				<Button
					type="submit"
					variant="contained"
					color="primary"
					text={props.scheduledReportParameters == null ? 'Create' : 'Update'}
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
;}
