import React from 'react';

export const VitalPrivacyPolicy = <div>
	<h1>Privacy Policy</h1>

	<h2>INTRODUCTION</h2>

	<p>Vital Limited (we, us, our) complies with the New Zealand Privacy Act 2020 (the Act) when dealing with personal information. Personal information is information about an identifiable individual (being a natural person).</p>
	<p>This policy complements our general privacy policy available at <a href="https://vital.co.nz/privacy-policy/" target="_blank" rel="noopener noreferrer">vital.co.nz/privacy-policy</a> and provides additional detail around how Vital Locate collects and uses your personal information. You should read this policy with the general privacy policy.</p>

	<h2>INFORMATION WE COLLECT THROUGH VITAL LOCATE</h2>

	<p>Vital Locate collects location data through either or both of the GPS and Bluetooth systems on the device on which it is installed. In order to function as intended, Vital Locate must collect such location data in the background.</p>
	<p>If a safety timer in Vital Locate is enabled, information relating the time, date and location of the activation of the safety time is alco collected.</p>
	<p>Vital Locate may also collect:</p>
	<ul>
		<li>Technical device information, what it is and how it interacts with our network.</li>
		<li>Service usage information, including metadata.</li>
		<li>IP address information and other unique identifiers of the device on which Vital Locate is installed.</li>
	</ul>

	<p>Vital may also collect other information from you other than through the Vital Locate application as set out in our general privacy policy at <a href="https://vital.co.nz/privacy-policy/" target="_blank" rel="noopener noreferrer">vital.co.nz/privacy-policy</a>.</p>

	<h2>HOW WE USE PERSONAL INFORMATION COLLECTED THROUGH VITAL LOCATE</h2>

	<p>The information set out above that Vital Locate collects is sent to the Vital Locate API, which is managed by Vital or its service provider. The API processes the location and timer data and will send out relevant alerts if required. Location and timer data is also able to be viewed through the Vital Locate website portal by those persons at your organisation to whom access has been made available and to Vital and it’s service provider for service improvement and diagnostic purposes only.</p>

	<h2>HOW VITAL LOCATE PROTECTS PERSONAL INFORMATION</h2>

	<p>The Vital Locate platform is hosted on Microsoft Azure cloud servers in Australia and is built on the Platform as a Service (PaaS) components provided by the Microsoft Azure platform.</p>
	<p>For security and privacy information specific to the Microsoft Azure platform, and how it pertains to New Zealand Security and Privacy requirements, see <a href="https://azure.microsoft.com/en-us/resources/microsoft-azure-compliance-in-the-context-of-new-zealand/" target="_blank" rel="noopener noreferrer">https://azure.microsoft.com/en-us/resources/microsoft-azure-compliance-in-the-context-of-new-zealand/</a>.</p>
	<p>All Vital Locate application data is encrypted in transit using TLS 1.2 or greater, and all data transferred between Microsoft Azure is also encrypted in transit. All data is stored in Microsoft Azure at rest and is encrypted by the Microsoft Azure platform.</p>
	<p>Vital Locate takes all reasonable steps to ensure that the personal information we collect is protected against:</p>
	<ul>
		<li>loss</li>
		<li>unauthorised access, use, modification, or disclosure; and</li>
		<li>other misuse</li>
	</ul>
</div>;
