import React from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { computedFn } from 'mobx-utils';

import {
	Client as C,
	FloorPlansService,
	Service,
	useInjection,
} from 'src/services';

import { useEventListener } from 'src/util/useEventListener';
import { BuildingRenderer } from './buildingRenderer';
import { FloorPlanOverview } from './floorPlanOverview';
import { LiveMapAssetState } from 'src/app/map/liveMapAssetState';

interface FloorPlanViewerProps {
	assetTypes: C.IAssetTypeDto[];
	floorPlanGroup: C.IFloorPlanGroupDto;
	selectFloorPlan: (floorPlanId: string) => void;
	assetStatesByFloorPlanId: Map<string, Set<LiveMapAssetState>>;
}

const useStyles = makeStyles({
	root: {
		gridRow: '1',
		gridColumn: '2',
		zIndex: 2,
		marginLeft: '-40px',
		backgroundColor: '#FAFAFA',
		pointerEvents: 'initial',
		padding: '0px 0px 0px 70px',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridTemplateRows: 'auto 1fr',
	},
	title: {
		gridColumn: '1 / 3',
		gridRow: 1,
		paddingTop: '30px',
		paddingBottom: '15px',
	},
	building: {
		position: 'relative',
		gridColumn: 1,
		gridRow: 2,
		overflow: 'hidden',
		padding: '20px',
	},
	overviewsParent: {
		gridColumn: 2,
		gridRow: 2,
		overflowY: 'auto',
		padding: '20px',

		'&::-webkit-scrollbar': {
			width: '10px',
		},
		'&::-webkit-scrollbar-track': {
			background: '#F0F0F0',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#D8D8D8',
		}
	},
	overviews: {
		display: 'grid',
		gridGap: '15px',
	}
});

export const FloorPlanViewer = observer((props: FloorPlanViewerProps) => {
	const classes = useStyles();
	const floorPlansService = useInjection<FloorPlansService>(Service.FloorPlans);

	const [hoveringFloor, setHoveringFloor] = React.useState<string | undefined>(undefined);
	const overviewsContainerRef = React.useRef<HTMLDivElement>(null);

	useEventListener('mousemove', (e: MouseEvent) => {
		if (!e.target || !(e.target instanceof Element)) {
			if (hoveringFloor)
				setHoveringFloor(undefined);

			return;
		}

		const elementWithFloorPlanId = e.target.closest('*[data-floor-plan-id]');
		if (!(elementWithFloorPlanId instanceof SVGElement || elementWithFloorPlanId instanceof HTMLElement)) {
			if (hoveringFloor)
				setHoveringFloor(undefined);

			return;
		}

		const floorPlanId = elementWithFloorPlanId.dataset['floorPlanId'];
		if (floorPlanId === hoveringFloor)
			return;

		setHoveringFloor(floorPlanId);

		// If the user hovers over a floor in the building SVG, scroll that floor into view in the overviews section.
		if (elementWithFloorPlanId instanceof SVGElement && overviewsContainerRef.current) {
			const overviewElement = overviewsContainerRef.current.querySelectorAll(`*[data-floor-plan-id='${floorPlanId}']`);
			if (overviewElement.length > 0)
				overviewElement[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	});

	const obs = useLocalObservable(() => ({
		floorPlans: computedFn((floorPlanGroupId: string) => Array.from(floorPlansService.floorPlans.values())
			.filter(x => x.parentGroupId === floorPlanGroupId)
			.sort((a, b) => a.displayOrder - b.displayOrder || a.name.localeCompare(b.name))
		),
	}));

	const floorPlans = obs.floorPlans(props.floorPlanGroup.floorPlanGroupId);

	const floorPlansWithEmergencies = new Set<string>();
	for (const floorPlan of floorPlans) {
		const assetsSet = props.assetStatesByFloorPlanId.get(floorPlan.floorPlanId);
		if (!assetsSet)
			continue;

		assetsSet.forEach(state => {
			if (state.hasEmergency)
				floorPlansWithEmergencies.add(floorPlan.floorPlanId);
		});
	}

	return <div className={classes.root}>
		<h1 className={classes.title}>{props.floorPlanGroup.name}</h1>

		<BuildingRenderer
			className={classes.building}
			floorPlanGroup={props.floorPlanGroup}
			hoveringFloor={hoveringFloor}
			selectFloorPlan={props.selectFloorPlan}
			emergencyFloors={floorPlansWithEmergencies}
		/>

		<div className={classes.overviewsParent}>
			<div className={classes.overviews} ref={overviewsContainerRef}>
				{floorPlans.map(x => <FloorPlanOverview
					key={x.floorPlanId}
					floorPlan={x}
					alternativeFloorPlan={floorPlans.find(y => x.levelIndex === y.levelIndex && y.floorPlanId !== x.floorPlanId)}
					selectFloorPlan={props.selectFloorPlan}
					assetTypes={props.assetTypes}
					assetStates={props.assetStatesByFloorPlanId.get(x.floorPlanId)}
					hovering={hoveringFloor === x.floorPlanId}
					emergency={floorPlansWithEmergencies.has(x.floorPlanId)}
				/>)}
			</div>
		</div>
	</div>;
});
