import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ErrorMessagePage, LoadingMessagePage } from 'src/components';

import { ManageDealerComponent } from './manageDealerComponent';

import { useQueryDealer } from 'src/graphql/__generated__/queries/queryDealer';

export interface Props {
	match: match<{ id: string }>;
}

export const EditDealer = observer((props: Props) => {
	const dealerQuery = useQueryDealer({
		variables: {
			id: props.match.params.id,
		}
	});

	if (dealerQuery.loading)
	return <LoadingMessagePage />;

	if (dealerQuery.error || !dealerQuery.data?.dealerById)
		return <ErrorMessagePage />;

	return <ManageDealerComponent
		dealer={dealerQuery.data.dealerById}
	/>;
});
