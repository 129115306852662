import React from 'react';
import moment from 'moment-timezone';
import { useRef } from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import { getPrettyName } from 'src/util';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import HistoryIcon from '@material-ui/icons/History';

import { Button, ErrorMessagePage, FixedWidthPage, LoadingMessagePage, MaterialTable } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';
import { EnterVehicleInformationDialog } from './enterVehicleInformationDialog';
import { AssetServiceRemindersTable, AssetServiceReminderTableRow, IUser, IUserGroup } from 'src/components/assetServiceRemindersTable';

import { useQueryAssetByIdForVehicleInformation, QueryAssetByIdForVehicleInformation_assetById_assetTelematicsCurrentValueAndReadings } from 'src/graphql/__generated__/queries/queryAssetByIdForVehicleInformation';
import { useMutationAddAssetTelematicsReading } from 'src/graphql/__generated__/mutations/mutationAddAssetTelematicsReading';

import { AssetTelematicsType } from 'src/../__generated__/globalTypes';

import {
	Client as C,
	AuthenticationService,
	DistanceUnit,
	useInjection,
	Service,
	DistanceService,
	ToasterService,
} from 'src/services';

interface Props {
	match: match<{ id: string }>;
}

const ServiceRemindersTitleAndButton = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginTop: '20px',
});

const ServiceRemindersButtons = styled(Box)({
	marginBottom: 'auto',
});

const ViewHistoryButton = styled(Button)({
	marginRight: '20px',
});

export const VehicleInformation = observer((props: Props) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const distanceService = useInjection<DistanceService>(Service.DistanceService);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const identity = authenticationService.currentAuth.user.identity;

	const [telematicsReadingTypeToEdit, setTelematicsReadingTypeToEdit] = React.useState<AssetTelematicsType | null>(null);
	const [telematicsReadingValueToEdit, setTelematicsReadingValueToEdit] = React.useState<number | null>(null);
	const [telematicsReadingsAndValueRows, setTelematicsReadingsAndValueRows] = React.useState<QueryAssetByIdForVehicleInformation_assetById_assetTelematicsCurrentValueAndReadings[]>([]);
	const initlialiseTableValues = useRef<Boolean> (false);

	const [assetServiceReminders, setAssetServiceReminders] = React.useState<AssetServiceReminderTableRow[] | null>(null);

	const [addTelematicsReading] = useMutationAddAssetTelematicsReading();

	function onDialogClosed() {
		setTelematicsReadingTypeToEdit(null);
		setTelematicsReadingValueToEdit(null);
	}

	function openDialog(telematicsValue: QueryAssetByIdForVehicleInformation_assetById_assetTelematicsCurrentValueAndReadings) {
		setTelematicsReadingTypeToEdit(telematicsValue.type);
		setTelematicsReadingValueToEdit(telematicsValue.lastReading);
	}

	const assetQuery = useQueryAssetByIdForVehicleInformation({
		variables: {
			id: props.match.params.id,
			includeClients: identity.type === C.IdentityType.SuperUser || identity.type === C.IdentityType.Dealer,
		}
	});

	if (assetQuery.loading)
		return <LoadingMessagePage />;

	if (!assetQuery?.data || !assetQuery.data?.assetById)
		return <ErrorMessagePage />;

	const asset = assetQuery.data!.assetById;
	const telematicsReadingsAndValues = assetQuery.data.assetById.assetTelematicsCurrentValueAndReadings ?? [];
	if (!initlialiseTableValues.current) {
		const engineTimeValue = telematicsReadingsAndValues.find(x => x.type === AssetTelematicsType.ENGINE_TIME);
		// Add empty telematics values, otherwise no row will be shown for the user to update the value of the reading type.
		if (!engineTimeValue)
			addEmptyTelematicsRow(AssetTelematicsType.ENGINE_TIME, telematicsReadingsAndValues);

		const odoValue = telematicsReadingsAndValues.find(x => x.type === AssetTelematicsType.ODOMETER);
		if (!odoValue)
			addEmptyTelematicsRow(AssetTelematicsType.ODOMETER, telematicsReadingsAndValues);

		setTelematicsReadingsAndValueRows(telematicsReadingsAndValues);

		const assetServiceRemindersResult = assetQuery.data!.assetById.serviceReminders ?? [];

		const assetServiceRemindersTableRows: AssetServiceReminderTableRow[] = assetServiceRemindersResult.map(x => {
			return {
				id: x.id,
				assetServiceReminderType: {
					name: x.assetServiceReminderType.name,
					dateEnabled: x.assetServiceReminderType.dateEnabled,
					odometerEnabled: x.assetServiceReminderType.odometerEnabled,
					engineTimeEnabled: x.assetServiceReminderType.engineTimeEnabled,
					clientId: x.assetServiceReminderType.client?.id,
				},
				assetId:  asset.id,
				assetName: asset.name,
				state: x.state,
				nextDateTriggerDate: x.nextDateTriggerDate,
				nextEngineTimeTrigger: x.nextEngineTimeTrigger,
				nextOdometerTrigger: x.nextOdometerTrigger,
				dateDefaultFrequency: x.dateDefaultFrequency,
				engineTimeDefaultFrequency: x.engineTimeDefaultFrequency,
				odometerDefaultFrequency: x.odometerDefaultFrequency,
				odometerReadingValue: odoValue?.value ?? null,
				engineTimeReadingValue: engineTimeValue?.value ?? null,
				extraInformation: x.extraInformation,
				users: x.users ? x.users
					.filter(x => x != null)
					.map(x => ({id: x!.id, name: x!.name}))
					: null,
				userGroups: x.userGroups ? x.userGroups
					.filter(x => x != null)
					.map(x => ({id: x!.id, name: x!.name}))
				: null,
			};
		});
		setAssetServiceReminders(assetServiceRemindersTableRows);

		initlialiseTableValues.current = true;
	}

	async function addEmptyTelematicsRow(type: AssetTelematicsType, telematicsValues: QueryAssetByIdForVehicleInformation_assetById_assetTelematicsCurrentValueAndReadings[]){
		telematicsValues.push({
			__typename: 'AssetTelematicsCurrentValueAndReading',
			type: type,
			value: null,
			lastReading: null,
			lastReadingTimestamp: null,
		})
	}

	async function onDialogSubmit(value: number) {
		try {
			if (telematicsReadingTypeToEdit === AssetTelematicsType.ODOMETER) {
				const odoKilometres = authenticationService.currentAuth.user.usesMetric ? value : distanceService.convertMilesToKilometres(value);
				value = odoKilometres * 1000;
			} else {
				value = value * 60; // Get minutes from hours.
			}

			await addTelematicsReading({
				variables: {
					input: {
						assetId: asset.id,
						value: value,
						type: telematicsReadingTypeToEdit!,
					},
				},
			});

			toasterService.showSuccess('Successfully added a asset telematics reading.');
		} catch (e) {
			toasterService.handleWithToast(e, 'Failed to save new odometer reading.');
		}

		setTelematicsReadingTypeToEdit(null);
		setTelematicsReadingValueToEdit(null);

		// Make sure the new values returned are set.
		initlialiseTableValues.current = false;
		await assetQuery.refetch();
	}

	async function refetchAssetServiceReminders() {
		// Make sure the new values returned are set.
		initlialiseTableValues.current = false;
		await assetQuery.refetch();
	}

	return <FixedWidthPage
		headingText="Vehicle Information"
		subheadingText={asset.name}
		noContentBackground
	>
		<h2>Asset Telematics</h2>

		<MaterialTable
			tableName="asset-telematics-list"
			options={{
				filtering: false,
				showTitle: false,
				pageSize: 25,
				selection: false,
				toolbar: false,
				grouping: false,
				draggable: false,
				paging: undefined
			}}
			columns={[
				{
					title: 'Type',
					field: 'type',
					render: rowData => {
						return getPrettyName(rowData.type);
					}
				},
				{
					title: 'Current Estimated Value',
					field: 'value',
					render: rowData => {
						let value = 'Unknown';
						if (rowData.value && rowData.type === AssetTelematicsType.ENGINE_TIME)
							value = `${(rowData.value / 60).toFixed(1)} hrs`;
						else if (rowData.value && rowData.type === AssetTelematicsType.ODOMETER)
							value = distanceService.formatDistance(rowData.value, authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles);

						return <>{value}</>;
					},
				},
				{
					title: 'Last Reading Entered',
					field: 'lastReading', 
					render: rowData => {
						let lastReadingValue = 'No reading entered';
						if (rowData.lastReading && rowData.type === AssetTelematicsType.ENGINE_TIME)
							lastReadingValue = `${(rowData.lastReading / 60).toFixed(1)} hrs`;
						else if (rowData.lastReading && rowData.type === AssetTelematicsType.ODOMETER)
							lastReadingValue = distanceService.formatDistance(rowData.lastReading, authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles);

						return <>{lastReadingValue}</>;
					},
				},
				{
					title: 'Date Of Reading Entered',
					field: 'lastReadingTimestamp',
					render: rowData => {
						return <>{rowData?.lastReadingTimestamp == null ? 'No reading entered' : moment(rowData.lastReadingTimestamp).tz(authenticationService.currentAuth.user.timeZone).format(longDateTimeFormat)}</>;
					},
				},
				{
					field: 'options',
					grouping: false,
					filtering: false,
					sorting: false,
					headerStyle: ({
						textAlign: 'right',
					}),
					cellStyle: () => ({
						textAlign: 'right',
					}),
					render: rowData => <Button
						text="Enter reading"
						startIcon={<EditIcon/>}
						color="primary"
						variant="outlined"
						onClick={() => openDialog(rowData)}
					/>
				},
			]}
			data={telematicsReadingsAndValueRows}
		/>

		<ServiceRemindersTitleAndButton>
			<h2>Asset Service Reminders</h2>

			<ServiceRemindersButtons>
				<ViewHistoryButton
					text="View history"
					startIcon={<HistoryIcon/>}
					color="primary"
					variant="contained"
					href={`/app/assets/${props.match.params.id}/service-reminders/history`}
					title="View the history of service reminders for an asset"
					size="small"
				/>

				<Button
					text="Add service reminder"
					startIcon={<AddIcon/>}
					color="primary"
					variant="contained"
					href={`/app/assets/${props.match.params.id}/service-reminders/add`}
					title="Add asset service reminder for an asset"
					size="small"
				/>
			</ServiceRemindersButtons>
		</ServiceRemindersTitleAndButton>

		{assetServiceReminders && <AssetServiceRemindersTable
			assetServiceReminders={assetServiceReminders}
			showAssetName={false}
			showAssetServiceReminderType={true}
			showToolbar={true}
			grouping={false}
			refetchData={refetchAssetServiceReminders}
		/>}

		{telematicsReadingTypeToEdit && <EnterVehicleInformationDialog
			type={telematicsReadingTypeToEdit!}
			assetId={props.match.params.id}
			value={telematicsReadingValueToEdit || 0}
			onCancel={onDialogClosed}
			onSubmit={onDialogSubmit}
		/>}
	</FixedWidthPage>;
});
