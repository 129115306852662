import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddMobilePhoneDevice($input: AddMobilePhoneDeviceInput!) {
		addMobilePhoneDevice(input: $input) {
			mobilePhoneDevice {
				id
				asset {
					id
				}
			}
		}
	}
`;
