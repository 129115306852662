import React from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { runInAction, observable, makeObservable, when, ObservableSet, action } from 'mobx';
import { observer } from 'mobx-react';
import { bind } from 'bind-decorator';
import classNames from 'classnames';
import queryString from 'query-string';
import moment from 'moment-timezone';
import mapboxgl, { LngLat, LngLatBounds } from 'mapbox-gl';
import { debounce } from 'lodash';

import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import IconLayers from '@material-ui/icons/Layers';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
	Container,
	AddressBookService,
	AssetService,
	AssetEventsService,
	AssetGroupService,
	AssetTypesService,
	AuthenticationService,
	Client as C,
	EmergencyService,
	EmergencyState,
	FloorPlansService,
	GeofenceService,
	HistoryService,
	PlayState,
	Service,
	SoundService,
	ToasterService,
	TileServerClient,
	WebSocketService,
	UsersService,
	MapDataService,
} from 'src/services';

import { LiveMapAssetState } from './liveMapAssetState';
import { MapboxManager, MapboxMapStyle, mapboxMaxStyleFromUserSetting } from './mapboxManager';
import { LiveMapSidebarManager } from './liveMapSidebarManager';
import { LiveMapMarkerManager } from './liveMapMarkerManager';
import { SelectedClusteredAssetsInfoBox } from './selectedClusteredAssetsInfoBox';
import { GeofenceManager } from './geofenceManager';
import { MapDataManager } from './mapDataManager';
import { CurrentMapSidebar, MapDataSearchState } from './liveMapSidebar';
import { SelectedAssetInfoBox, InfoBoxDisplayMode } from './selectedAssetInfoBox';
import { GeofenceEditBox } from './geofenceEditBox';
import { MapViewMode } from './mapViewMode';
import { MessagePage } from 'src/components';
import { getEventTime } from 'src/util/eventHelpers';
import { MapSettingsDialog } from './mapSettingsDialog';
import { MapActionButtons } from './mapActionButtons';
import { SelectLayersDialog } from './selectLayersDialog';
import { mapContainerStyle, mapStyle } from './mapStyles';
import { FloorPlanViewer } from './floorPlanViewer/floorPlanViewer';
import { IMapAsset } from './mapAsset';
import { CustomMapMarkerManager } from 'src/app/map/customMapMarkerManager';
import { CustomMapMarkerEditBox } from 'src/app/map/customMapMarkers/customMapMarkerEditBox';
import { CustomMapMarkerInfoBox } from 'src/app/map/customMapMarkers/customMapMarkerInfoBox';

import { executeQueryAssetServiceRemindersForLiveMap, QueryAssetServiceRemindersForLiveMap_assetServiceReminders } from 'src/graphql/__generated__/queries/queryAssetServiceRemindersForLiveMap';
import { DeviceActivationState, DeviceBillingType, DeviceType, EmergencyStatus, EmergencyType } from 'src/../__generated__/globalTypes';
import { executeQueryMapAssets } from 'src/graphql/__generated__/queries/queryMapAssets';
import { executeQueryCustomMapMarkers, QueryCustomMapMarkers_customMapMarkers } from 'src/graphql/__generated__/queries/queryCustomMapMarkers';
import { executeMutationPollDeviceLocation } from 'src/graphql/__generated__/mutations/mutationPollDeviceLocation';
import { executeQueryEmergenciesForLiveMap, QueryEmergenciesForLiveMap_emergencies } from 'src/graphql/__generated__/queries/queryEmergenciesForLiveMap';

import './mapPage.scss';

interface State {
	mapSidebarOpen: boolean;
	mapMenuOpen: boolean;
	floorPlan?: C.IFloorPlanDto;
	floorPlanGroup?: C.IFloorPlanGroupDto;
	mapViewMode: MapViewMode;
	mapStyle: MapboxMapStyle;
	loading: boolean;
	settingsDialogOpen: boolean;
	layerSettingsOpen: boolean;
	canSearchMapData: boolean;
	mapDataSearchState: MapDataSearchState;
	mapDataSearchQuery: string | null;
	mapDataSearchResults: TileServerClient.ISearchResponseDto[];
}

@observer
export class LiveMap extends React.Component<{}, State> {
	private _addressBookService = Container.get<AddressBookService>(Service.AddressBooks);
	private _apolloClient = Container.get<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	private _assetService = Container.get<AssetService>(Service.Asset);
	private _assetEventsService = Container.get<AssetEventsService>(Service.AssetEvents);
	private _assetGroupService = Container.get<AssetGroupService>(Service.AssetGroup);
	private _assetTypesService = Container.get<AssetTypesService>(Service.AssetType);
	private _authenticationService = Container.get<AuthenticationService>(Service.Authentication);
	private _emergencyService = Container.get<EmergencyService>(Service.Emergency);
	private _floorPlanService = Container.get<FloorPlansService>(Service.FloorPlans);
	private _geofenceService = Container.get<GeofenceService>(Service.Geofence);
	private _historyService = Container.get<HistoryService>(Service.History);
	private _toaster = Container.get<ToasterService>(Service.Toaster);
	private _soundService = Container.get<SoundService>(Service.Sound);
	private _webSocketService = Container.get<WebSocketService>(Service.WebSocket);
	private _usersService = Container.get<UsersService>(Service.Users);
	private _mapDataService = Container.get<MapDataService>(Service.MapData);

	private _mapboxManager?: MapboxManager;

	@observable private _assets = new Map<string, IMapAsset>();
	@observable private _assetStates = new Map<string, LiveMapAssetState>();
	@observable private _assetStatesByFloorPlanId = new Map<string, ObservableSet<LiveMapAssetState>>();

	@observable private _clusteredAssetsSelected: IMapAsset[] | undefined = [];
	private _assetOfflineTimeouts = new Map<string, number>();
	private _assetTypes: C.IAssetTypeDto[] = [];
	private _assetGroups: C.IAssetGroupDto[] = [];
	private _addressBooks: C.IAddressBookDto[] = [];
	private _addressBookEntriesById = new Map<string, C.IAddressBookEntryDto>();

	private _currentMapSidebarManager: LiveMapSidebarManager = new LiveMapSidebarManager();
	private _currentMapMarkerManager: LiveMapMarkerManager;
	private _customMapMarkerManager: CustomMapMarkerManager;
	private _geofenceManager: GeofenceManager;
	private _mapDataManager: MapDataManager;
	private _tilesetKeys: C.ITilesetKeysDto;

	@observable private _userMapSettings: C.IUserMapSettingsDto;

	@observable private _selectedAsset?: LiveMapAssetState;
	private _selectedAssetTrailEvents?: C.IAssetEventDto[];

	// The asset with the oldest event, that has not aged off the map yet.
	private _oldestNonAgedAssetId?: string = undefined;
	private _oldestAssetAgingTimestamp?: moment.Moment = undefined;
	private _oldestAssetAgingTimeout?: any = undefined;

	private safetyTimerWarningIntervalMs = 10 * 1000; // 10 sec
	private safetyTimerWarningPeriodMs = 5 * 60 * 1000; // 5 mins
	private _safetyTimerWarningInterval: any;
	private _closedSafetyTimerToasts = new Set<string>();

	constructor(props: any) {
		super(props);
		makeObservable(this);

		this._safetyTimerWarningInterval = setInterval(this.onSafetyTimerWarningInterval, this.safetyTimerWarningIntervalMs);

		this.state = {
			mapSidebarOpen: window.innerWidth > 750,
			mapMenuOpen: false,
			mapViewMode: MapViewMode.World,
			mapStyle: MapboxMapStyle.Streets,
			loading: true,
			settingsDialogOpen: false,
			layerSettingsOpen: false,
			mapDataSearchResults: [],
			canSearchMapData: false,
			mapDataSearchQuery: null,
			mapDataSearchState: MapDataSearchState.Idle,
		};
	}

	@bind
	toggleMapSidebar() {
		this.setState({ mapSidebarOpen: !this.state.mapSidebarOpen });
	}

	@bind
	toggleMapMenu() {
		this.setState({ mapMenuOpen: !this.state.mapMenuOpen });
	}

	@bind
	moveViewToHome() {
		const animationMiliseconds = 1000;

		if (this._userMapSettings.customHomeLocationEnabled && this._userMapSettings.customHomeLocationLongitude != null && this._userMapSettings.customHomeLocationLatitude != null && this._userMapSettings.customHomeLocationZoom) {
			this._mapboxManager!.easeToLocation(this._userMapSettings.customHomeLocationLongitude, this._userMapSettings.customHomeLocationLatitude, this._userMapSettings.customHomeLocationZoom, animationMiliseconds);
		} else {
			const bounds = this.getAssetStateMapBounds(Array.from(this._assetStates.values()));

			if (bounds) {
				this._mapboxManager!.fitBounds(bounds, {
					maxZoom: 17,
					padding: {
						top: 50,
						left: window.innerWidth > 750 ? 310 : 50,
						bottom: 50,
						right: 50,
					},
					animate: true,
					duration: animationMiliseconds,
				});
			}
		}
	}

	@bind
	private async mapRefChanged(element: HTMLDivElement | null) {
		if (!element)
			return;

		if (!this._authenticationService.currentAuth.permissions.general.viewAssetLocations) {
			this._historyService.history.replace('/app');
			return;
		}

		const mapboxManager = new MapboxManager(element, {
			hasTopRightMenu: true,
			metric: this._authenticationService.currentAuth.user.usesMetric,
			onAddCustomMapMarker: this.addCustomMapMarker,
			onClick: this.onMapboxClick,
		});

		await this.initialise(mapboxManager);
	}

	@bind
	private async initialise(mapboxManager: MapboxManager) {
		this._mapboxManager = mapboxManager;

		const identityType = this._authenticationService.currentAuth.user.identity.type;

		const apiGetUserMapSettings = this._usersService.getUserMapSettings(this._authenticationService.currentAuth.user.userId);
		const apiTilesets = this._mapDataService.fetchTilesets();
		const apiTilesetKeys = this._mapDataService.generateTilesetKeys();
		const apiGeofenceTypes = this._geofenceService.getGeofenceTypes();
		const apiGeofences = this._geofenceService.getGeofences();
		const apiAssetTypes = this._assetTypesService.listAssetTypes();
		const apiAssetsQuery = executeQueryMapAssets(this._apolloClient, { includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer });
		const apiAssetStates = this._assetService.getAssetStates(this._floorPlanService.floorPlans);
		const apiAssetGroups = this._assetGroupService.getAssetGroups(C.ListAssetGroupsType.Report);
		const apiAddressBooks = this._addressBookService.getAllAddressBooks();
		const apiCustomMapMarkers = executeQueryCustomMapMarkers(this._apolloClient);

		await when(() => !this._floorPlanService.isLoading);
		this._userMapSettings = await apiGetUserMapSettings;

		const mapStyle = mapboxMaxStyleFromUserSetting(this._userMapSettings.defaultMapStyle);
		await this._mapboxManager.world(mapStyle);

		await new Promise(resolve => {
			this.setState({
				mapStyle: mapStyle,
			}, resolve as any);
		});

		this._assetGroups = await apiAssetGroups;
		this._addressBooks = await apiAddressBooks;
		this._assetTypes = await apiAssetTypes;

		for (const addressBook of this._addressBooks) {
			for (const entry of addressBook.addressBookEntries)
				this._addressBookEntriesById.set(entry.addressBookEntryId, entry);
		}

		this._currentMapMarkerManager = new LiveMapMarkerManager(this._mapboxManager!.map, this._assetTypes, this.setSelectedAsset, this._userMapSettings.assetLabelBehavior, this._userMapSettings.assetClusteringEnabled, this.setSelectedAssetsInCluster);
		const geofenceTypes = await apiGeofenceTypes;
		this._geofenceManager = new GeofenceManager(this._mapboxManager!.map, this.onClickGeofence, geofenceTypes, this._userMapSettings.geofenceLabelBehaviour);

		this._tilesetKeys = await apiTilesetKeys;
		this._mapboxManager.updateTilesetKeys(this._tilesetKeys);

		const tilesets = await apiTilesets;
		this._mapDataManager = new MapDataManager(this._mapboxManager!.map, tilesets);
		this.addMapDataLayers();

		this._geofenceManager.initialise(this.state.mapViewMode);
		const geofences = await apiGeofences;
		for (const geofence of geofences)
			this._geofenceManager.addGeofence(geofence);
		this._geofenceManager.updateGeofences();

		const { error: assetsQueryError, data: assetsData } = await apiAssetsQuery;
		if (assetsQueryError || !assetsData?.assets)
			throw assetsQueryError;

		let emergencies: QueryEmergenciesForLiveMap_emergencies[] = [];
		if (identityType === C.IdentityType.Client) {
			const apiEmergenciesQuery = executeQueryEmergenciesForLiveMap(this._apolloClient, {
				emergencyStatus: EmergencyStatus.NEW,
			});

			const { error: emergencyQueryError, data: emergencyData } = await apiEmergenciesQuery;

			if (emergencyQueryError || !emergencyData?.emergencies)
				throw emergencyQueryError;

			emergencies = emergencyData.emergencies.sort((a, b) => moment(b.generatedAt).valueOf() - moment(a.generatedAt).valueOf());
		}

		const assets: IMapAsset[] = assetsData.assets
			.filter(x =>
				x.devices &&
				x.devices.length > 0 &&
				x.devices.some(device => device.activationState === DeviceActivationState.ACTIVATED && device.billingType !== DeviceBillingType.CONFIGURATION_ONLY))
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
			.map(x => ({
				assetId: x.id,
				name: x.name,
				assetType: x.assetType,
				client: x.client,
				devices: x.devices,
				emergencyNotes: x.emergencyNotes,
			} as IMapAsset));

		const assetStatesMap = new Map<string, C.IAssetStateDto>();
		const assetStates = await apiAssetStates;
		for (const assetState of assetStates)
			assetStatesMap.set(assetState.assetId, assetState);

		const liveAssetStates: LiveMapAssetState[] = [];
		runInAction(() => {
			for (const [floorPlanId, ] of this._floorPlanService.floorPlans)
				this._assetStatesByFloorPlanId.set(floorPlanId, observable.set<LiveMapAssetState>());

			for (const asset of assets) {
				this._assets.set(asset.assetId, asset);

				const assetState = assetStatesMap.get(asset.assetId);
				if (!assetState)
					continue;

				// Try get the user's name from the address book entry.
				let userName: string | undefined = undefined;
				if (assetState?.properties?.addressBookEntryId)
					userName = this._addressBookEntriesById.get(assetState.properties.addressBookEntryId)?.userName || undefined;

				const assetEmergencies = emergencies.filter(x => x.asset.id == asset.assetId);

				const hasTypeEmergency = emergencies.some(x => x.type === EmergencyType.EMERGENCY);
				let emergencyType = C.EmergencyType.Emergency;
				if (!hasTypeEmergency && assetEmergencies.some(x => x.type === EmergencyType.PRIORITY_ALERT))
					emergencyType = C.EmergencyType.PriorityAlert;

				const mapAssetState: LiveMapAssetState = observable({
					asset: asset,
					isOnline: false,
					latestEventTimestamp: assetState.latestEventTimestamp || undefined,
					latestLocationTimestamp: assetState.latestLocationTimestamp || undefined,
					assetLocation: assetState?.assetLocation || undefined,
					isSelected: false,
					safetyTimer: assetState.safetyTimer || undefined,
					properties: assetState.properties,
					isAgedOff: this.isAgedOff(assetState.latestEventTimestamp || undefined),
					userName: userName,
					hiddenBySearchOrFilter: false,
					hasEmergency: emergencies.some(x => x.asset.id === asset.assetId),
					emergencyType: emergencyType,
				});

				if (mapAssetState.hasEmergency) {
					const mostRecentEmergency = emergencies.filter(x => x.asset.id === asset.assetId)[0];

					mapAssetState.emergencyDeviceIoConfigurationName = mostRecentEmergency.deviceIoConfiguration?.name;
				}

				if (mapAssetState.assetLocation?.floorPlanLocation) {
					const floorPlanId = mapAssetState.assetLocation.floorPlanLocation.floorPlanId;

					mapAssetState.currentFloorPlan = this._floorPlanService.floorPlans.get(floorPlanId);

					const floorPlanSet = this._assetStatesByFloorPlanId.get(floorPlanId);
					if (floorPlanSet)
						floorPlanSet.add(mapAssetState);
				}

				liveAssetStates.push(mapAssetState);
			}
		});

		for (const assetState of liveAssetStates) {
			this.updateStatus(assetState, true);
			this._assetStates.set(assetState.asset.assetId, assetState);
		}

		this._customMapMarkerManager = new CustomMapMarkerManager(this._mapboxManager!.map, this.onClickCustomMapMarker);

		const query = queryString.parse(this._historyService.history.location.search);

		const queryAssetId = query.view === 'asset' && query.assetId;
		const queryGeofence = query.view === 'geofence' && query.geofenceId && geofences.find(x => x.geofenceId === query.geofenceId);

		// If we're trying to go to a specific asset, go to that asset.
		if (queryAssetId) {
			this.setSelectedAsset(queryAssetId as string);
		// If we're trying to go to a specific geofence, go to that geofence.
		} else if (queryGeofence) {
			this.goToGeofence(queryGeofence);
		} else {
			// If there are assets with a current emergency, we will try to centre the map around them.
			const assetsInEmergencies = liveAssetStates.filter(x => x.hasEmergency);

			let movedMap = false;
			let bounds: LngLatBounds | undefined;
			if (assetsInEmergencies.length > 0)
				bounds = this.getAssetStateMapBounds(assetsInEmergencies);

			// If we can't get the bounds of the emergencies, or there are no emergencies:
			// - go to them home location if there is one set
			// - otherwise, try centre the map around the current locations of all assets.
			if (!bounds) {
				if (this._userMapSettings?.customHomeLocationEnabled && (this._userMapSettings.customHomeLocationLongitude && this._userMapSettings.customHomeLocationLatitude && this._userMapSettings.customHomeLocationZoom)) {
					this._mapboxManager.jumpToLocation(this._userMapSettings.customHomeLocationLongitude, this._userMapSettings.customHomeLocationLatitude, this._userMapSettings.customHomeLocationZoom);
					movedMap = true;
				} else {
					bounds = this.getAssetStateMapBounds(liveAssetStates);
				}
			}

			if (bounds) {
				this._mapboxManager!.fitBounds(bounds, {
					maxZoom: 17,
					padding: {
						top: 50,
						left: window.innerWidth > 750 ? 310 : 50,
						bottom: 50,
						right: 50,
					}
				});
			} else {
				if (!movedMap)
					this._mapboxManager!.jumpToLocation(0, 0, 0);
			}
		}

		let assetServiceReminders: QueryAssetServiceRemindersForLiveMap_assetServiceReminders[] = [];
		if (this._authenticationService.currentAuth.permissions.general.manageAssetServiceReminders) {
			const { error: assetServiceRemindersError, data: assetServiceRemindersData } = await executeQueryAssetServiceRemindersForLiveMap(this._apolloClient);
			if (assetServiceRemindersError || !assetServiceRemindersData?.assetServiceReminders)
				throw assetServiceRemindersError || 'Failed to load asset service reminders.';

			assetServiceReminders = assetServiceRemindersData.assetServiceReminders;
		}

		this._currentMapSidebarManager.initialize(liveAssetStates, this._assetGroups, assetServiceReminders);
		this._currentMapMarkerManager.initialize(liveAssetStates);

		const { error: customMapMarkersError, data: customMapMarkersData } = await apiCustomMapMarkers;
		if (customMapMarkersError || !customMapMarkersData?.customMapMarkers)
			throw customMapMarkersError;

		for (const marker of customMapMarkersData.customMapMarkers)
			this._customMapMarkerManager.addMarker(marker);

		await this._webSocketService.subscribeToAssetEvents(this.handleNewAssetEvents);
		this._emergencyService.subscribeToEmergencyStateChanges(this.handleEmergencyStateChange);
		this._webSocketService.subscribeToSafetyTimerUpdates(this.handleSafetyTimerUpdate);

		this.trySetAssetAgingTimeout();
		this.onSafetyTimerWarningInterval();

		this.setState({
			loading: false,
		});
	}

	@bind
	private onMapboxClick(event: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
		if (this._customMapMarkerManager.onMapboxClick(event))
			return;
	}

	private addMapDataLayers() {
		const hiddenLayers = this._userMapSettings.userTilesetMapLayerSettings?.filter(x => x.hide).map(x => x.tilesetMapLayerId) ?? [];

		this._mapDataManager.reset();
		this._mapDataManager.addOrRemoveLayers(hiddenLayers);

		this.setState({ canSearchMapData: this._mapDataManager.canSearchActiveTilesetMapLayers() });
	}

	@bind
	private updateMapDataLayerSettings(layerSettings: C.IUserTilesetMapLayerSettingDto[]) {
		for (const settings of layerSettings) {
			const layer = this._userMapSettings.userTilesetMapLayerSettings?.find(x => x.tilesetMapLayerId == settings.tilesetMapLayerId);

			if (layer)
				layer.hide = settings.hide;
			else
				this._userMapSettings.userTilesetMapLayerSettings?.push(settings);
		}

		const hiddenLayers = this._userMapSettings.userTilesetMapLayerSettings?.filter(x => x.hide).map(x => x.tilesetMapLayerId) ?? [];
		this._mapDataManager.addOrRemoveLayers(hiddenLayers);

		this.setState({ canSearchMapData: this._mapDataManager.canSearchActiveTilesetMapLayers() });

		this._usersService.updateUserMapLayerSettings(this._authenticationService.currentAuth.user.userId, { layers: layerSettings });
	}

	@bind
	private async onChangeMapDataSearchQuery(query: string | null) {
		const trimmedQuery = query?.trim() || '';

		if (!trimmedQuery) {
			this.setState({
				mapDataSearchQuery: query,
				mapDataSearchResults: [],
				mapDataSearchState: MapDataSearchState.Idle
			});

			return;
		}

		this.setState({
			mapDataSearchQuery: query,
			mapDataSearchState: MapDataSearchState.Searching
		});

		this.debounceMapDataSearch(trimmedQuery);
	}

	private debounceMapDataSearch = debounce((searchQuery: string) => this.mapDataSearch(searchQuery), 1000);

	@bind
	private async mapDataSearch(searchQuery: string) {
		const activeLayers = this._mapDataManager.getActiveTilesetMapLayers();

		const searchDto: TileServerClient.ISearchRequestDto = {
			tilesetMapLayerIds: activeLayers.map(x => x.tilesetMapLayerId),
			term: searchQuery,
			startIndex: 0,
			tilesetKeys: {},
		};

		for (const layer of activeLayers) {
			if (searchDto.tilesetKeys[layer.tilesetId])
				continue;

			const key = this._tilesetKeys.keys?.find(x => x.tilesetId === layer.tilesetId);
			if (!key)
				continue;

			searchDto.tilesetKeys[layer.tilesetId] = key.key;
		}

		try {
			const searchResults = await this._mapDataService.searchTileLayers(searchDto);

			// Only update the state if the user hasn't continued typing.
			if (searchQuery == this.state.mapDataSearchQuery) {
				this.setState({
					mapDataSearchState: MapDataSearchState.Idle,
					mapDataSearchResults: searchResults
				});
			}
		} catch {
			this.setState({ mapDataSearchState: MapDataSearchState.Error });
		}
	}

	@bind
	private onClickMapDataSearchFeature(searchFeature: TileServerClient.SearchResponseDto) {
		const bbox = searchFeature.bBox;

		const bounds = new LngLatBounds([
			[ bbox.bottomRightLong, bbox.topLeftLat ],
			[ bbox.topLeftLong, bbox.bottomRightLat ],
		]);

		this._mapboxManager!.fitBounds(bounds, {
			maxZoom: 17,
			padding: {
				top: 50,
				left: window.innerWidth > 750 ? 310 : 50,
				bottom: 50,
				right: 50,
			},
		});

		// Only create a ping if the map data is a single point.
		if (bbox.bottomRightLat !== bbox.topLeftLat || bbox.bottomRightLong !== bbox.topLeftLong)
			return;

		const markerElement = document.createElement('div');
		markerElement.className = 'connect-map-marker';

		const pulseElement = document.createElement('div');
		pulseElement.className = 'connect-map-marker__asset__pulse';
		markerElement.appendChild(pulseElement);

		new mapboxgl.Popup({
			anchor: 'center',
			className: 'popup-ping-marker',
			closeButton: false,
			closeOnClick: true,
			closeOnMove: true,
		})
		.setLngLat([ bbox.topLeftLong, bbox.topLeftLat ])
		.setDOMContent(markerElement)
		.addTo(this._mapboxManager!.map);
	}

	@bind
	getAssetStateMapBounds(assetStates: LiveMapAssetState[]): LngLatBounds | undefined {
		const nonAgedAssetStates = assetStates.filter(x => x.assetLocation && (!x.isAgedOff || x.hasEmergency));
		if (nonAgedAssetStates.length === 0)
			return undefined;

		const bounds = new LngLatBounds([
			[nonAgedAssetStates[0].assetLocation!.location.longitude, nonAgedAssetStates[0].assetLocation!.location.latitude],
			[nonAgedAssetStates[0].assetLocation!.location.longitude, nonAgedAssetStates[0].assetLocation!.location.latitude],
		]);

		for (let i = 1; i < nonAgedAssetStates.length; i++)
			bounds.extend(new LngLat(nonAgedAssetStates[i].assetLocation!.location.longitude, nonAgedAssetStates[i].assetLocation!.location.latitude));

		return bounds;
	}

	@bind
	changeMapBoundsToFitEmergencies() {
		const assetsInEmergencies = Array.from(this._assetStates.values()).filter(x => x.hasEmergency);
		const newBounds = this.getAssetStateMapBounds(assetsInEmergencies);

		if (newBounds) {
			this._mapboxManager!.fitBounds(newBounds, {
				maxZoom: 17,
				padding: {
					top: 50,
					left: window.innerWidth > 750 ? 310 : 50,
					bottom: 50,
					right: 50,
				}
			});
		}
	}

	@bind
	private trySetAssetAgingTimeout() {
		if (!this._userMapSettings.hideAssetLatestEventThreshold)
			return;

		const assetStatesNotAged = Array.from(this._assetStates.values())
			.filter(x => !x.isAgedOff && !!x.latestEventTimestamp);

		// Find the asset state with the oldest latestEventTimestamp.
		let oldestAssetState: LiveMapAssetState | undefined = undefined;
		for (const assetState of assetStatesNotAged) {
			if (oldestAssetState == undefined || assetState.latestEventTimestamp! < oldestAssetState.latestEventTimestamp!) {
				oldestAssetState = assetState;
			}
		}

		if (!oldestAssetState)
			return;

		// Get the time until this asset should be aged.
		const timeOfAssetAging = oldestAssetState.latestEventTimestamp!.clone().add(this._userMapSettings.hideAssetLatestEventThreshold, 'minutes');
		const timeTillAssetAges = Math.max(0, timeOfAssetAging.diff(moment())) + 1000;

		// setTimeout can only accept a max of 2147483647 (32 bit int), any more than this and the method will run again instantly.
		const timeUntilOffline = Math.min(timeTillAssetAges, 2147483647);

		this._oldestNonAgedAssetId = oldestAssetState.asset.assetId;
		this._oldestAssetAgingTimestamp = oldestAssetState.latestEventTimestamp;
		this._oldestAssetAgingTimeout = setTimeout(this.updateAgedAssetsOnMap, timeUntilOffline);
	}

	@bind
	private updateAgedAssetsOnMap(reset: boolean = false) {
		if (!reset && this._oldestNonAgedAssetId) {
			const assetState = this._assetStates.get(this._oldestNonAgedAssetId);

			if (assetState) {
				this.updateStatus(assetState);
				this._currentMapMarkerManager.handleAssetEventUpdate(assetState);
			}
		}

		if (this._oldestNonAgedAssetId) {
			clearTimeout(this._oldestAssetAgingTimeout);
			this._oldestNonAgedAssetId = undefined;
			this._oldestAssetAgingTimestamp = undefined;
			this._oldestAssetAgingTimeout = undefined;
		}

		this.trySetAssetAgingTimeout();
	}

	@bind
	private timeoutUpdateStatus(assetId: string) {
		const assetState = this._assetStates.get(assetId);
		if (!assetState)
			return;

		this.updateStatus(assetState);

		this._currentMapSidebarManager.handleAssetEventUpdate(assetState);
		this._currentMapMarkerManager.handleAssetEventUpdate(assetState);
	}

	// Hide a map marker if no events in X minutes.
	private isAgedOff(latestEventTimestamp: moment.Moment | undefined): boolean {
		if (!this._userMapSettings.hideAssetLatestEventThreshold)
			return false;

		return !latestEventTimestamp || moment().diff(latestEventTimestamp) > this._userMapSettings.hideAssetLatestEventThreshold * 60 * 1000;
	}

	@bind
	private updateStatus(assetState: LiveMapAssetState, suppressAssetActiveInactiveBehavior?: boolean) {
		if (!assetState.latestEventTimestamp)
			return;

		const existingTimeout = this._assetOfflineTimeouts.get(assetState.asset.assetId);
		if (existingTimeout)
			clearTimeout(existingTimeout);

		const latestEventTime = assetState.latestEventTimestamp;

		const offlineTimeMinutes = !assetState.asset.devices || assetState.asset.devices.length === 0 || assetState.asset.devices.some(x => x.deviceType !== DeviceType.MOBILE_PHONE) ? 20 : 5;
		const offlineTime = latestEventTime.clone().add(offlineTimeMinutes, 'minutes').add(1, 'second');
		const currentTime = moment.tz();

		assetState.isAgedOff = this.isAgedOff(latestEventTime);

		// Check whether this asset uses key on/off events and the current status of the key.
		const hasKey = !!assetState.properties.mostRecentKeyOn || !!assetState.properties.mostRecentKeyOff;
		let keyOn = false;
		if (hasKey) {
			if (assetState.properties.mostRecentKeyOn)
				if (!assetState.properties.mostRecentKeyOff || assetState.properties.mostRecentKeyOn.isAfter(assetState.properties.mostRecentKeyOff))
					keyOn = true;
		}

		const wasOnline = assetState.isOnline;

		const isOffline = hasKey ? !keyOn || currentTime.isAfter(offlineTime) : currentTime.isAfter(offlineTime);
		assetState.isOnline = !isOffline;

		// Play an online/offline sound if the user has configured their settings to do so.
		if (!suppressAssetActiveInactiveBehavior && assetState.isOnline !== wasOnline) {
			if (assetState.isOnline) {
				if (this._userMapSettings.assetActiveBehavior === C.AssetActiveInactiveBehavior.PlaySound || this._userMapSettings.assetActiveBehavior === C.AssetActiveInactiveBehavior.PlaySoundAndShowNotification)
					this._soundService.onDuty();

				if (this._userMapSettings.assetActiveBehavior === C.AssetActiveInactiveBehavior.ShowNotification || this._userMapSettings.assetActiveBehavior === C.AssetActiveInactiveBehavior.PlaySoundAndShowNotification)
					new Notification(`${assetState.asset.name} is now active.`);

			} else {
				if (this._userMapSettings.assetInactiveBehavior === C.AssetActiveInactiveBehavior.PlaySound || this._userMapSettings.assetInactiveBehavior === C.AssetActiveInactiveBehavior.PlaySoundAndShowNotification)
					this._soundService.offDuty();

				if (this._userMapSettings.assetInactiveBehavior === C.AssetActiveInactiveBehavior.ShowNotification || this._userMapSettings.assetInactiveBehavior === C.AssetActiveInactiveBehavior.PlaySoundAndShowNotification)
					new Notification(`${assetState.asset.name} is now inactive.`);
			}
		}

		if (isOffline)
			return;

		// Set up a timeout to transition the asset to offline if we don't receive another event before then.
		// window.setTimeout can only accept a max of 2147483647 (32 bit int), any more than this and the method will run again instantly.
		const timeUntilOffline = Math.min(offlineTime.diff(currentTime), 2147483647);

		const newTimeout = window.setTimeout(() => {
			this.timeoutUpdateStatus(assetState.asset.assetId);
		}, timeUntilOffline);

		this._assetOfflineTimeouts.set(assetState.asset.assetId, newTimeout);
	}

	componentDidMount() {
		// Safari needs a height set on the app.
		const app = window.document.querySelector('#app');
		if (app)
			app.classList.add('full-height');
	}

	componentWillUnmount() {
		this._webSocketService.unsubscribeFromAssetEvents();
		this._webSocketService.unsubscribeFromSafetyTimerUpdates();
		this._emergencyService.unsubscribeFromEmergencyStateChanges();

		for (const [_, timeout] of this._assetOfflineTimeouts)
			clearTimeout(timeout);

		if (this._oldestAssetAgingTimeout)
			clearTimeout(this._oldestAssetAgingTimeout);

		clearInterval(this._safetyTimerWarningInterval);

		for (const assetState of this._assetStates.values())
			this.closeToast(assetState.asset.assetId);

		this._currentMapSidebarManager.dispose();

		// Undo the height set for Safari.
		const app = window.document.querySelector('#app');
		if (app)
			app.classList.remove('full-height');
	}

	@bind
	private async setSelectedAssetsInCluster(assets: IMapAsset[] | undefined) {
		if (assets && !this._geofenceManager.tryStopEditingGeofenceIfEditing('Selecting this asset', true))
			return;

		if (assets && !this._customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Selecting this asset', true))
			return;

		this._clusteredAssetsSelected = assets;
	}

	@bind
	private clearClusteredMapMarker() {
		this._currentMapMarkerManager.clearSelectedClusteredMarker();
	}

	@bind
	private async setSelectedAsset(assetId: string | undefined, goToAsset: boolean = true) {
		this.maybeHideSidebar();

		if (this.state.floorPlanGroup)
			this.clearFloorPlanGroup();

		// If there is already a selected asset, the user has either clicked that asset
		// again or unselected the asset.
		if (this._selectedAsset) {
			if (this._selectedAsset.asset.assetId === assetId) {
				this.goToSelectedAsset();
				return;
			}

			this._selectedAsset.isSelected = false;

			this._currentMapSidebarManager.handleAssetEventUpdate(this._selectedAsset);
			this._currentMapMarkerManager.handleAssetEventUpdate(this._selectedAsset);
		}

		// Asset has been unselected, remove state for selected asset.
		if (!assetId) {
			this._selectedAsset = undefined;
			this._selectedAssetTrailEvents = undefined;
			this._mapboxManager!.setTrail(undefined);
			return;
		}

		if (!this._geofenceManager.tryStopEditingGeofenceIfEditing('Selecting this asset', true))
			return;

		if (!this._customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Selecting this asset', true))
			return;

		this._selectedAssetTrailEvents = undefined;
		this._mapboxManager!.setTrail(undefined);

		this._selectedAsset = this._assetStates.get(assetId);
		if (!this._selectedAsset)
			return;

		// Unset selected cluster, if there is one.
		this._currentMapMarkerManager.clearSelectedClusteredMarker();

		this._selectedAsset.isSelected = true;

		if (goToAsset)
			this.goToSelectedAsset();

		this._currentMapSidebarManager.handleAssetEventUpdate(this._selectedAsset);
		this._currentMapMarkerManager.handleAssetEventUpdate(this._selectedAsset);

		// Try scroll the sidebar so that the asset is visible.
		const sidebarAsset = document.querySelector(`.sidebar-item[data-asset-id='${assetId}']`);
		sidebarAsset?.scrollIntoView({ behavior: 'auto', block: 'nearest' });

		try {
			const history = await this._assetEventsService.fetchHistory(assetId, moment().subtract(30, 'minutes'), moment(), this._floorPlanService.floorPlans);

			// The selected asset might have changed while waiting for the history to be returned.
			if (this._selectedAsset?.asset.assetId === assetId) {
				this._selectedAssetTrailEvents = history.events.filter(x => !!x.assetLocation);
				this._mapboxManager!.setTrail(this._selectedAssetTrailEvents);
			}
		} catch (e) {
			console.error('Failed to load history.', e);
		}
	}

	@bind
	private goToGeofence(geofence: C.IGeofenceDto) {
		const bounds = new LngLatBounds([
			[geofence.coordinates[0][0], geofence.coordinates[0][1]],
			[geofence.coordinates[1][0], geofence.coordinates[1][1]],
		]);

		for (let i = 1; i < geofence.coordinates.length; i++)
			bounds.extend(new LngLat(geofence.coordinates[i][0], geofence.coordinates[i][1]));

		this._mapboxManager!.fitBounds(bounds, {
			maxZoom: 17,
			padding: {
				top: 50,
				left: window.innerWidth > 750 ? 310 : 50,
				bottom: 50,
				right: 50,
			}
		});
	}

	@bind
	private goToSelectedAsset() {
		const location = this._selectedAsset?.assetLocation;
		if (!location)
			return;

		this._mapboxManager!.goToAssetLocation(location);
	}

	@bind
	private selectFloorPlanById(floorPlanId: string) {
		const floorPlan = this._floorPlanService.floorPlans.get(floorPlanId);
		if (!floorPlan)
			return;

		this.selectFloorPlan(floorPlan);
	}

	@bind
	private async selectFloorPlan(floorPlan: C.IFloorPlanDto) {
		this.maybeHideSidebar();

		if (!this._geofenceManager.tryStopEditingGeofenceIfEditing('Changing the selected floor plan', true))
			return;

		if (!this._customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Changing the selected floor plan', true))
			return;

		this.setState({
			floorPlan: floorPlan,
			floorPlanGroup: undefined,
			mapViewMode: MapViewMode.FloorPlan,
		});

		this._mapDataManager.reset();
		await this._mapboxManager!.viewFloorPlan(floorPlan);
		this._currentMapMarkerManager!.setMapView(MapViewMode.FloorPlan, floorPlan);
		this._customMapMarkerManager!.setMapView(MapViewMode.FloorPlan, floorPlan);

		this._geofenceManager.initialise(this.state.mapViewMode, floorPlan);
	}

	@bind
	private async selectFloorPlanGroup(floorPlanGroup: C.IFloorPlanGroupDto) {
		this.maybeHideSidebar();

		if (!this._geofenceManager.tryStopEditingGeofenceIfEditing('Selecting this floor plan group', true))
			return;

		if (!this._customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Selecting this floor plan group', true))
			return;

		this.setSelectedAsset(undefined);
		this._currentMapMarkerManager.clearSelectedClusteredMarker();

		this.setState({
			floorPlanGroup: floorPlanGroup,
		});
	}

	@bind
	private async changeFloorPlanView() {
		if (!this.state.floorPlan)
			return;

		if (!this._geofenceManager.tryStopEditingGeofenceIfEditing('Changing view', true))
			return;

		if (!this._customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Changing view', true))
			return;

		if (this.state.mapViewMode === MapViewMode.FloorPlan) {
			await this._mapboxManager!.world(this.state.mapStyle);
			this._mapboxManager!.addFloorPlanToWorld(this.state.floorPlan);

			this._mapboxManager!.fitBounds([
				[this.state.floorPlan.topLeft.longitude, this.state.floorPlan.bottomRight.latitude],
				[this.state.floorPlan.bottomRight.longitude, this.state.floorPlan.topLeft.latitude],
			]);

			this._currentMapMarkerManager!.setMapView(MapViewMode.World, undefined);
			this.addMapDataLayers();

			this.setState({ mapViewMode: MapViewMode.World });
		} else if (this.state.mapViewMode === MapViewMode.World) {
			await this._mapboxManager!.viewFloorPlan(this.state.floorPlan);

			this._currentMapMarkerManager!.setMapView(MapViewMode.FloorPlan, this.state.floorPlan);
			this._customMapMarkerManager!.setMapView(MapViewMode.FloorPlan, this.state.floorPlan);
			this.setState({ mapViewMode: MapViewMode.FloorPlan });
		}

		this._geofenceManager.initialise(this.state.mapViewMode, this.state.floorPlan);
	}

	@bind
	private async clearFloorPlan() {
		if (!this._geofenceManager.tryStopEditingGeofenceIfEditing('Exiting viewing the floor plan', true))
			return;

		if (!this._customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Exiting viewing the floor plan', true))
			return;

		this.maybeHideSidebar();

		const previousFloorPlan = this.state.floorPlan;
		const previousMapMode = this.state.mapViewMode;

		this.setState({
			floorPlan: undefined,
			mapViewMode: MapViewMode.World,
		});

		await this._mapboxManager!.world(this.state.mapStyle);
		this.addMapDataLayers();
		this._geofenceManager.initialise(this.state.mapViewMode);
		this._currentMapMarkerManager!.setMapView(MapViewMode.World, undefined);
		this._customMapMarkerManager!.setMapView(MapViewMode.World, undefined);

		if (previousMapMode === MapViewMode.FloorPlan && previousFloorPlan) {
			this._mapboxManager!.fitBounds([
				[previousFloorPlan.topLeft.longitude, previousFloorPlan.bottomRight.latitude],
				[previousFloorPlan.topLeft.longitude, previousFloorPlan.topLeft.latitude],
			]);
		}
	}

	@bind
	private clearFloorPlanGroup() {
		this.maybeHideSidebar();

		this.setState({
			floorPlanGroup: undefined,
		});
	}

	// If the user makes an action which affects what is shown on the map,
	// they probably want to see the map. If the sidebar is open and they're
	// on a small screen, we hide the sidebar to show the map again.
	@bind
	private maybeHideSidebar() {
		if (window.innerWidth <= 750) {
			this.setState({
				mapSidebarOpen: false,
			});
		}
	}

	@bind
	private async toggleMapStyle(selectedStyle?: MapboxMapStyle) {
		const newMapStyle = selectedStyle ? selectedStyle : this.state.mapStyle === MapboxMapStyle.Streets ? MapboxMapStyle.Satellite : MapboxMapStyle.Streets;

		this.setState({ mapStyle: newMapStyle });

		await this._mapboxManager!.world(newMapStyle);

		if (this.state.floorPlan)
			this._mapboxManager!.addFloorPlanToWorld(this.state.floorPlan);

		this.addMapDataLayers();
		this._geofenceManager.initialise(this.state.mapViewMode);
	}

	@bind
	private createNewGeofence() {
		this.setSelectedAsset(undefined);
		this._currentMapMarkerManager.clearSelectedClusteredMarker();

		if (!this._customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Creating a geofence', true))
			return;

		this.toggleMapMenu();
		this._geofenceManager.startDrawing();
	}

	@bind
	private toggleLayers() {
		this.setState({ layerSettingsOpen: true });
	}

	@bind
	private onClickGeofence(geofence: C.IGeofenceDto) {
		this.setSelectedAsset(undefined);
		this._currentMapMarkerManager.clearSelectedClusteredMarker();

		if (!this._customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Editing a geofence', true))
			return;

		this._geofenceManager.removeGeofence(geofence.geofenceId);
		this._geofenceManager.updateGeofences();

		this._geofenceManager.startDrawing(geofence);
	}

	@bind
	private addCustomMapMarker(location: mapboxgl.LngLat) {
		this.setSelectedAsset(undefined);
		this._currentMapMarkerManager.clearSelectedClusteredMarker();

		if (!this._geofenceManager.tryStopEditingGeofenceIfEditing('Adding a custom map marker', true))
			return;

		this._customMapMarkerManager!.addNewMarker(location);
	}

	@bind
	private onClickCustomMapMarker(marker: QueryCustomMapMarkers_customMapMarkers) {
		this.setSelectedAsset(undefined);
		this._currentMapMarkerManager.clearSelectedClusteredMarker();

		if (!this._geofenceManager.tryStopEditingGeofenceIfEditing('Editing a custom map marker', true))
			return;

		this._customMapMarkerManager!.viewMarkerInfo(marker.id);
	}

	@bind
	private handlePollResponse(assetId: string, event: C.IAssetEventDto | null) {
		const state = this._assetStates.get(assetId);

		if (!state || !state.pollState)
			return;
		const pollState = state.pollState;
		runInAction(() => {
			clearTimeout(pollState.pollTimeout);
			pollState.pollTimeout = undefined;

			if (event) {
				pollState.pollResult = true;
				if (state.isSelected) {
					this._toaster.showSuccess('Received a new location from the polled asset.');

					// Move to asset
					const assetLocation = event.assetLocation;
					if (assetLocation)
						this._mapboxManager!.goToAssetLocation(assetLocation);
				}
			} else {
				pollState.pollResult = false;
				if (state.isSelected)
					this._toaster.showDanger('Polled asset did not not return a new location.');
			}

			pollState.pollResultTimeout = setTimeout(() => {
				// Reset the icon back to locate,
				state.pollState = undefined;
			}, 10000);
		});
	}

	@bind
	private async pollAssetLocation(assetId: string) {
		const asset = this._assets.get(assetId);
		const state = this._assetStates.get(assetId);
		if (!asset || !state)
			return;

		const device = asset.devices?.find(x => x.manualPollingEnabled);
		if (!device)
			return;

		let startPolling: Boolean = false;

		if (!!state.pollState) {
			if (!!state.pollState.pollResultTimeout && !!state.pollState.pollResult)
				clearTimeout(state.pollState.pollResultTimeout);
			if ((state.pollState.pollResultTimeout == null && state.pollState.pollResult == null) || (!!state.pollState.pollResult))
				startPolling = true;
		}

		if (startPolling || state.pollState == null) {
			await executeMutationPollDeviceLocation(this._apolloClient, {
				input: {
					deviceId: device.id,
				},
			});

			state.pollState = {
				pollTimeout: setTimeout(() => {
					// Poll request has timed out, cancel and fail request
					this.handlePollResponse(assetId, null);
				}, 30000),
			};
		}
	}

	@bind
	private getUsernameFromAddressBook(asset: IMapAsset, event: C.IAssetEventDto, userIdentifier: string) {
		if (this._authenticationService.currentAuth.user.identity.type !== C.IdentityType.Client && !asset.client)
			return undefined;

		let siteIds: string[] = [];
		if (event.deviceId) {
			const eventDevice = asset.devices?.find(x => x.id === event.deviceId);
			if (eventDevice && eventDevice.network?.id)
				siteIds= [ eventDevice.network.id ];
		} else {
			siteIds = asset.devices
				?.filter(x => !!x.network)
				?.map(x => x.network!.id) || [];
		}

		if (!siteIds || siteIds.length === 0)
			return undefined;

		let addressBooks = this._addressBooks.filter(x => siteIds.some(siteId =>  x.siteId === siteId));
		if (this._authenticationService.currentAuth.user.identity.type !== C.IdentityType.Client)
			addressBooks = addressBooks.filter(x => x.clientId === asset.client!.id);

		if (addressBooks.length === 1) {
			const entry = addressBooks[0].addressBookEntries.find(x => x.userIdentifier === userIdentifier);
			return entry?.userName ?? undefined;
		}

		return undefined;
	}

	@action.bound
	private handleNewAssetEvents(events: C.IAssetEventDto[]) {
		let resetAgingTimer = false;

		for (const event of events) {
			const state = this._assetStates.get(event.assetId);
			if (!state)
				return;

			state.latestEventTimestamp = getEventTime(event);

			if (event.assetLocation) {
				event.assetLocation.floorPlanLocation = this._floorPlanService.getFloorPlanLocationForAssetLocation(this._floorPlanService.floorPlans, event.assetLocation);

				state.assetLocation = event.assetLocation;
				state.latestLocationTimestamp = getEventTime(event);

				if (state.pollState)
					this.handlePollResponse(event.assetId, event);

				const previousFloorPlanId = state.currentFloorPlan?.floorPlanId;
				if (event.assetLocation.floorPlanLocation) {
					const newFloorPlanId = event.assetLocation.floorPlanLocation.floorPlanId;
					if (!state.currentFloorPlan || state.currentFloorPlan.floorPlanId !== newFloorPlanId)
						state.currentFloorPlan = this._floorPlanService.floorPlans.get(newFloorPlanId);
				}

				if (state.currentFloorPlan?.floorPlanId !== previousFloorPlanId) {
					if (previousFloorPlanId) {
						const floorPlanStates = this._assetStatesByFloorPlanId.get(previousFloorPlanId);
						if (floorPlanStates)
							floorPlanStates.delete(state);
					}

					if (state.currentFloorPlan?.floorPlanId) {
						const floorPlanStates = this._assetStatesByFloorPlanId.get(state.currentFloorPlan.floorPlanId);
						if (floorPlanStates)
							floorPlanStates.add(state);
					}
				}

				// If this is an event for the currently selected asset.
				if (this._selectedAsset && this._selectedAsset.asset.assetId === event.assetId) {
					// Add this location to the selected asset's trail.
					if (this._selectedAssetTrailEvents) {
						this._selectedAssetTrailEvents.push(event);
						const cutoff = moment().subtract(30, 'minutes');
						this._selectedAssetTrailEvents = this._selectedAssetTrailEvents.filter(x => x.generatedAt!.isAfter(cutoff));
						this._mapboxManager!.setTrail(this._selectedAssetTrailEvents);
					}
				}
			}

			if (event.properties?.deviceUser) {
				if (event.properties.deviceUser.userIdentifier) {
					const addressBookUserName = this.getUsernameFromAddressBook(state.asset, event, event.properties!.deviceUser!.userIdentifier);
					if (state.userName !== addressBookUserName)
						state.userName = addressBookUserName;
				}else {
					if (state.userName)
						state.userName = undefined;
				}
			}

			if (event.type === C.AssetEventType.KeyOn)
				state.properties.mostRecentKeyOn = getEventTime(event);
			else if (event.type === C.AssetEventType.KeyOff)
				state.properties.mostRecentKeyOff = getEventTime(event);

			if (event.properties?.fuelLevelPoC)
				state.properties.fuelLevelPoC = event.properties.fuelLevelPoC.value;

			this.updateStatus(state);

			this._currentMapSidebarManager.handleAssetEventUpdate(state);
			this._currentMapMarkerManager.handleAssetEventUpdate(state);

			if (state.latestEventTimestamp && !state.isAgedOff) {
				if (!this._oldestAssetAgingTimestamp || state.latestEventTimestamp < this._oldestAssetAgingTimestamp)
					resetAgingTimer = true;
			}
		}

		if (resetAgingTimer)
			this.updateAgedAssetsOnMap(true);
	}

	@bind
	private handleEmergencyStateChange(emergencyState: EmergencyState) {
		const asset = this._assets.get(emergencyState.assetId);
		if (!asset)
			return;

		const state = this._assetStates.get(emergencyState.assetId);
		if (!state)
			return;

		state.hasEmergency = emergencyState.hasEmergency;
		state.emergencyType = emergencyState.emergencyType;

		this._currentMapSidebarManager.handleAssetEventUpdate(state);
		this._currentMapMarkerManager.handleAssetEventUpdate(state);

		this.changeMapBoundsToFitEmergencies();
	}

	@bind
	handleSafetyTimerUpdate(safetyTimer: C.ISafetyTimerDto) {
		const state = this._assetStates.get(safetyTimer.assetId);
		if (!state)
			return;

		if (safetyTimer.status === C.SafetyTimerStatus.Ended || safetyTimer.status === C.SafetyTimerStatus.EmergencyTriggered) {
			state.safetyTimer = undefined;
			this.closeToast(safetyTimer.assetId);
		} else {
			state.safetyTimer = safetyTimer;

			const msRemaining = safetyTimer.endTimestamp.diff(moment(), 'milliseconds');
			if (msRemaining > this.safetyTimerWarningPeriodMs) {
				this.closeToast(safetyTimer.assetId);
				this._closedSafetyTimerToasts.delete(safetyTimer.safetyTimerId);
			}
		}

		this._currentMapSidebarManager.handleAssetEventUpdate(state);
		this._currentMapMarkerManager.handleAssetEventUpdate(state);
	}

	@bind
	closeToast(assetId: string, userClosed: boolean = false) {
		if (userClosed) {
			const state = this._assetStates.get(assetId);
			if (state && state.safetyTimer?.safetyTimerId)
				this._closedSafetyTimerToasts.add(state.safetyTimer.safetyTimerId);
		}

		this._toaster.closeToast(assetId);
		this._soundService.alertWarning(PlayState.Stop);
	}

	@action.bound
	onSafetyTimerWarningInterval() {
		if (this._authenticationService.currentAuth.user.identity.type !== C.IdentityType.Client)
			return;

		for (const assetState of this._assetStates.values()) {
			if (!assetState.safetyTimer || this._closedSafetyTimerToasts.has(assetState.safetyTimer.safetyTimerId))
				continue;

			const msRemaining = assetState.safetyTimer.endTimestamp.diff(moment(), 'milliseconds');
			if (msRemaining > this.safetyTimerWarningPeriodMs)
				continue;

			this._soundService.alertWarning(PlayState.Play);

			this._toaster.showCustom(`Safety timer emergency for ${assetState.asset.name} will trigger soon.`, {
				key: assetState.asset.assetId,
				preventDuplicate: true,
				autoHideDuration: msRemaining - 1000,
				variant: 'warning',
				action: _ => <IconButton
					className="icon-button-small button-close emergency-warning-close"
					onClick={() => this.closeToast(assetState.asset.assetId, true)}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			});
		}
	}

	@bind
	openMapSettingsDialog() {
		this.setState({
			settingsDialogOpen: true,
		});
	}

	@bind
	onMapSettingsDialogClosed(newMapSettings?: C.IUserMapSettingsDto) {
		this.setState({
			settingsDialogOpen: false,
		});

		if (!newMapSettings)
			return;

		if (this._userMapSettings.assetLabelBehavior != newMapSettings.assetLabelBehavior)
			this._currentMapMarkerManager.handleAssetLabelBehaviorChange(newMapSettings.assetLabelBehavior);

		if (this._userMapSettings.geofenceLabelBehaviour != newMapSettings.geofenceLabelBehaviour)
			this._geofenceManager.handleGeofenceLabelBehaviorChange(newMapSettings.geofenceLabelBehaviour);

		const mapStyle = mapboxMaxStyleFromUserSetting(newMapSettings.defaultMapStyle);
		if (this.state.mapViewMode === MapViewMode.World && this.state.mapStyle !== mapStyle)
			this.toggleMapStyle(mapStyle);

		//Update markers
		const ageThresholdChange = this._userMapSettings.hideAssetLatestEventThreshold !== newMapSettings.hideAssetLatestEventThreshold;
		const assetClusteringEnabledChanged = this._userMapSettings.assetClusteringEnabled !== newMapSettings.assetClusteringEnabled;

		this._userMapSettings = newMapSettings;

		if (ageThresholdChange || assetClusteringEnabledChanged) {
			for (const state of this._assetStates.values())
				this.updateStatus(state);

			this._currentMapMarkerManager.clearAndReinitialiseMarkers(this._userMapSettings.assetClusteringEnabled);

			this.updateAgedAssetsOnMap(true);
		}
	}

	private _debouncedFilterOrSearchUpdate = debounce(this.assetSideBarSearchOrFilterUpdate, 750);

	@bind
	assetSideBarSearchOrFilterUpdate() {
		this._currentMapMarkerManager.clearAndReinitialiseMarkers(this._userMapSettings.assetClusteringEnabled);
	}

	render() {
		// Only enable the "go to floor plan" button if we're not already viewing that floor plan.
		const disableFloorPlanTravelButton = this.state.mapViewMode === MapViewMode.FloorPlan &&
			this.state.floorPlan &&
			this._selectedAsset?.currentFloorPlan &&
			this._selectedAsset.currentFloorPlan.floorPlanId === this.state.floorPlan.floorPlanId;

		return <div className={mapContainerStyle}>
			<div className={classNames('current-map__overlay', { 'sidebar-open': this.state.mapSidebarOpen })}>
				<div className="map-sidebar" style={{ zIndex: 3 }}>
					<CurrentMapSidebar
						liveMapSidebarManager={this._currentMapSidebarManager}
						assetStates={this._assetStates}
						assetTypes={this._assetTypes}
						setSelectedAssetId={this.setSelectedAsset}
						selectFloorPlan={this.selectFloorPlan}
						selectFloorPlanGroup={this.selectFloorPlanGroup}
						floorPlan={this.state.floorPlan}
						floorPlanGroup={this.state.floorPlanGroup}
						clearFloorPlan={this.clearFloorPlan}
						clearFloorPlanGroup={this.clearFloorPlanGroup}
						changeFloorPlanView={this.changeFloorPlanView}
						canChangeFloorPlan={this._geofenceManager && !this._geofenceManager.editorState}
						assetGroups={this._assetGroups}
						mapDataSearchQuery={this.state.mapDataSearchQuery}
						onChangeMapDataSearchQuery={this.onChangeMapDataSearchQuery}
						onClickMapDataSearchFeature={this.onClickMapDataSearchFeature}
						mapDataSearchResults={this.state.mapDataSearchResults}
						canSearchMapData={this.state.canSearchMapData}
						mapDataSearchState={this.state.mapDataSearchState}
						filterOrSearchUpdateCallBack={this._debouncedFilterOrSearchUpdate}
					/>

					<div className="map-sidebar__collapse-button">
						<button onClick={this.toggleMapSidebar} type="button">
							{this.state.mapSidebarOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
						</button>
					</div>
				</div>

				<div style={{ gridRow: '1', gridColumn: '2', zIndex: 1 }}>
					{this._selectedAsset && <SelectedAssetInfoBox
						asset={this._selectedAsset.asset}
						displayMode={InfoBoxDisplayMode.Full}
						assetLocation={this._selectedAsset.assetLocation}
						timestamp={this._selectedAsset.latestEventTimestamp}
						emergency={this._selectedAsset.hasEmergency}
						emergencyType={this._selectedAsset.emergencyType}
						emergencyDeviceIoConfigurationName={this._selectedAsset.emergencyDeviceIoConfigurationName}
						floorPlanVisible={false}
						onCloseButtonClick={() => this.setSelectedAsset(undefined)}
						goToFloorPlan={!disableFloorPlanTravelButton && this.selectFloorPlanById || undefined}
						pollAssetLocation={this.pollAssetLocation}
						pollState={this._selectedAsset.pollState}
						safetyTimer={this._selectedAsset.safetyTimer}
						safetyTimerUpdate={this.handleSafetyTimerUpdate}
						isAgedOff={this._selectedAsset.isAgedOff}
						userName={this._selectedAsset.userName}
						fuelLevelPoC={this._selectedAsset.properties?.fuelLevelPoC ?? undefined}
					/>}

					{this._clusteredAssetsSelected && this._clusteredAssetsSelected?.length > 0 && <SelectedClusteredAssetsInfoBox
						selectedAssets={this._clusteredAssetsSelected}
						assetStates={this._assetStates}
						onCloseButtonClick={this.clearClusteredMapMarker}
						assetSelected={assetId => this.setSelectedAsset(assetId, true)}
					/>}

					{this._geofenceManager && this._geofenceManager.editorState && <GeofenceEditBox
						geofenceManager={this._geofenceManager}
						editorState={this._geofenceManager.editorState}
						floorPlan={this.state.floorPlan}
						mapViewMode={this.state.mapViewMode}
					/>}

					{this._customMapMarkerManager && this._customMapMarkerManager.editorState && this._customMapMarkerManager.editorState.editing && <CustomMapMarkerEditBox
						customMapMarkerManager={this._customMapMarkerManager}
					/>}

					{this._customMapMarkerManager && this._customMapMarkerManager.editorState && !this._customMapMarkerManager.editorState.editing && <CustomMapMarkerInfoBox
						customMapMarkerManager={this._customMapMarkerManager}
					/>}

					<MapActionButtons
						mapViewMode={this.state.mapViewMode}
						mapStyle={this.state.mapStyle}
						moveMapToHome={this.moveViewToHome}
						toggleMapStyle={this.toggleMapStyle}
						hasSidebar
					/>

					<div className={classNames('current-map__menu', { 'current-map__menu--open': this.state.mapMenuOpen })}>
						<button
							className="current-map__menu__toggle"
							type="button"
							onClick={this.toggleMapMenu}
						>
							<MenuIcon />
						</button>

						<div className={classNames('current-map__menu__options', { 'current-map__menu__options--open': this.state.mapMenuOpen })}>
							<button
								className="current-map__menu__option__map-settings"
								type="button"
								title="Map Settings"
								onClick={this.openMapSettingsDialog}
							>
								<SettingsIcon />
							</button>

							{this.state.settingsDialogOpen && <MapSettingsDialog
								mapSettings={this._userMapSettings}
								dialogCloseCallback={this.onMapSettingsDialogClosed}
								currentMapCenterAndZoom={this._mapboxManager!.getMapCenterAndZoom()}
							/>}
						</div>

						<div className={classNames('current-map__menu__options', { 'current-map__menu__options--open': this.state.mapMenuOpen })}>
							<button
								className="current-map__menu__option__add-geofence"
								type="button"
								title="Add Geofence"
								onClick={this.createNewGeofence}
								disabled={!this._authenticationService.currentAuth.permissions.general.manageGeofences}
							>
								<FormatShapesIcon />
							</button>
						</div>

						<div className={classNames('current-map__menu__options', { 'current-map__menu__options--open': this.state.mapMenuOpen })}>
							<button
								className="current-map__menu__option__toggle-layers"
								type="button"
								title="Toggle Layers"
								onClick={this.toggleLayers}
								disabled={false}
							>
								<IconLayers/>
							</button>

							{this.state.layerSettingsOpen && <SelectLayersDialog
								tilesets={this._mapDataManager.getAllTilesets()}
								layerSettings={this._userMapSettings.userTilesetMapLayerSettings ?? []}
								onChange={this.updateMapDataLayerSettings}
								onClose={() => this.setState({layerSettingsOpen: false})}
							/>}
						</div>
					</div>
				</div>

				{this.state.floorPlanGroup && <FloorPlanViewer
					assetTypes={this._assetTypes}
					floorPlanGroup={this.state.floorPlanGroup}
					assetStatesByFloorPlanId={this._assetStatesByFloorPlanId}
					selectFloorPlan={this.selectFloorPlanById}
				/>}
			</div>

			<div className={mapStyle} ref={this.mapRefChanged} />

			<div className={classNames('live-map--loader', { 'live-map--loader__loaded': !this.state.loading })}>
				<MessagePage
					title="Loading live map..."
					loading
				/>
			</div>
		</div>;
	}
}
