import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetByIdForVehicleInformation($id: UUID!, $includeClients: Boolean!) {
		assetById(id: $id){
			id
			name
			assetTelematicsCurrentValueAndReadings {
				type
				value
				lastReading
				lastReadingTimestamp
			}
			serviceReminders {
				id
				state
				assetServiceReminderType {
					name
					dateEnabled
					odometerEnabled
					engineTimeEnabled
					client @include(if: $includeClients) {
						id
					}
				}
				nextDateTriggerDate
				nextEngineTimeTrigger
				nextOdometerTrigger
				dateDefaultFrequency
				engineTimeDefaultFrequency
				odometerDefaultFrequency
				extraInformation
				users {
					id
					name
				}
				userGroups {
					id
					name
				}
			}
		}
	}
`;
