import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import useAsyncEffect from 'use-async-effect';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField, FormikSelect, FormikCheckbox } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	Client as C,
	GatewayService,
	AuthenticationService,
	HistoryService,
	PermissionsService,
	SiteService,
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

interface ManageGatewayFormValues {
	name: string;
	siteId: string | null;
	dealerId: string | null;
	sendStatusAlerts: boolean;
}

const validateForm = (values: ManageGatewayFormValues, errors: FormikErrors<ManageGatewayFormValues>) => {
	if (!values.name)
		errors.name = 'Name is required.';
};

export interface Props {
	gateway?: C.IGatewayDto | null;
}

export const ManageGatewayComponent = observer((props: Props) => {
	const _gatewayService = useInjection<GatewayService>(Service.Gateway);
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _permissionsService = useInjection<PermissionsService>(Service.Permissions);
	const _siteService = useInjection<SiteService>(Service.Site);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [loading, setLoading] = useState<boolean>(true);
	const [dealers, setDealers] = useState<C.IDealerDto[] | null>(null);
	const [sites, setSites] = useState<C.ISiteDto[] | null>(null);

	const initialFormValues: ManageGatewayFormValues = {
		name: props.gateway?.name ?? '',
		siteId: props.gateway?.site?.siteId ?? null,
		dealerId: props.gateway?.dealer?.dealerId ?? null,
		sendStatusAlerts: props.gateway ? !!props.gateway.sendStatusAlerts : true,
	};

	useAsyncEffect(async () => {
		const addPermissions = await _permissionsService.fetchUserAddPermissions(_authenticationService.currentAuth.user.identityId);

		if (addPermissions.dealers)
			setDealers(addPermissions.dealers);

		if (_authenticationService.currentAuth.user.identity.type === C.IdentityType.Dealer) {
			const sites = await _siteService.getAllSites();
			if (sites)
				setSites(sites);
		}

		setLoading(false);
	}, []);

	const addGateway = async (values: ManageGatewayFormValues): Promise<boolean> => {
		const request: C.IAddGatewayRequest = {
			name: values.name,
			siteId: values.siteId,
			dealerId: values.dealerId,
			sendStatusAlerts: values.sendStatusAlerts,
		};

		try {
			await _gatewayService.addGateway(request);
			_toasterService.showSuccess('Gateway added.');
			_historyService.history.push('/app/gateways/list');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to add Gateway.');
			return false;
		}
	};

	const updateGateway = async (values: ManageGatewayFormValues): Promise<boolean> => {
		const request: C.IUpdateGatewayRequest = {
			name: values.name,
			siteId: values.siteId,
			dealerId: values.dealerId,
			unsetSiteId: !!initialFormValues.siteId && !values.siteId,
			sendStatusAlerts: values.sendStatusAlerts,
		};

		try {
			await _gatewayService.updateGateway(props.gateway!.gatewayId, request);
			_toasterService.showSuccess('Gateway updated.');
			_historyService.history.push('/app/gateways/list');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update Gateway.');
			return false;
		}
	};

	const onSubmit = async (values: ManageGatewayFormValues, { setSubmitting }: FormikHelpers<ManageGatewayFormValues>) => {
		let success;
		if (props.gateway)
			success = await updateGateway(values);
		else
			success = await addGateway(values);

		if (!success)
			setSubmitting(false);
	}

	const renderDealerSelector = (formikProps: FormikProps<ManageGatewayFormValues>) => {
		if (!dealers || dealers.length == 0 ||
			_authenticationService.currentAuth.user.identity.type != C.IdentityType.SuperUser)
			return null;

		return <FormikSelect
			name="dealerId"
			label="Dealer"
			placeholder="Select a dealer..."
			options={dealers}
			clearable={false}
			isLoading={loading}
			form={formikProps}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.dealerId}
		/>;
	};

	const renderSiteSelector = (formikProps: FormikProps<ManageGatewayFormValues>) => {
		if (!sites || sites.length == 0 ||
			_authenticationService.currentAuth.user.identity.type != C.IdentityType.Dealer)
			return null;

		return <FormikSelect
			name="siteId"
			label="Site"
			placeholder="Select a site..."
			options={sites}
			isLoading={loading}
			form={formikProps}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.siteId}
		/>;
	};

	const addingNewGateway = !props.gateway;

	return <FixedWidthPage
		className="form-page"
		headingText={addingNewGateway ? 'Add Gateway' : 'Edit Gateway'}
		subheadingText={addingNewGateway ? null : props.gateway!.serial}
		pageItemId={props.gateway?.gatewayId}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageGatewayFormValues>) => <Form className="formik-form">
				<Field
					name="name"
					label="Name"
					type="text"
					component={FormikTextField}
					required
				/>

				{renderDealerSelector(formikProps)}
				{renderSiteSelector(formikProps)}

				<Field
					name="sendStatusAlerts"
					label="Send Status Alerts"
					type="checkbox"
					component={FormikCheckbox}
				/>

				<Button
					type="submit" variant="contained" color="primary"
					loading={formikProps.isSubmitting}
					startIcon={addingNewGateway ? null : <SaveIcon />}
					text={addingNewGateway ? 'Add Gateway' : 'Save Changes'}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
