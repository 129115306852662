import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import useAsyncEffect from 'use-async-effect';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { red, yellow } from '@material-ui/core/colors';

import {
	Client as C,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import { MessagePage } from 'src/components';

interface BuildingRendererProps {
	className?: string;
	floorPlanGroup: C.IFloorPlanGroupDto;
	hoveringFloor?: string;
	selectFloorPlan: (floorPlanId: string) => void;
	emergencyFloors: Set<string>;
}

const useStyles = makeStyles({
	root: {
		'& svg': {
			position: 'absolute',
			height: 'calc(100% - 40px)',
			width: 'calc(100% - 40px)',
		},
		'& path[data-building-outline]': {
			fill: 'none',
			stroke: 'black'
		},
		'& *[data-floor-plan-id]': {
			fill: 'white',
			cursor: 'pointer',
		},
		'& .emergency[data-floor-plan-id]': {
			fill: red[500],
		},
		'& .hovering[data-floor-plan-id]': {
			fill: yellow[300],
		},
	},
});

export const BuildingRenderer = observer((props: BuildingRendererProps) => {
	if (!props.floorPlanGroup.imageUrl)
		return <MessagePage className={props.className} title="Failed to load building image." />;

	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const classes = useStyles();

	const [svg, setSvg] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState(true);

	useAsyncEffect(async () => {
		setLoading(true);

		try {
			const svgResponse = await fetch(props.floorPlanGroup.imageUrl!);
			if (!svgResponse.ok)
				throw 'Failed to load building image.';

			setSvg(await svgResponse.text());
		} catch (e) {
			toasterService.handleWithToast(e);
		}

		setLoading(false);
	}, [props.floorPlanGroup.imageUrl]);

	const updateHighlighting = React.useCallback((node: HTMLDivElement | null) => {
		if (!node)
			return;

		node.querySelectorAll('*[data-floor-plan-id]').forEach(x => {
			if (!(x instanceof HTMLElement || x instanceof SVGElement))
				return;

			const floorPlanId = x.dataset['floorPlanId'];
			if (!floorPlanId)
				return;

			const alternativeFloorPlanId = x.dataset['alternativeFloorPlanId'];

			if (props.emergencyFloors.has(floorPlanId) || alternativeFloorPlanId && props.emergencyFloors.has(alternativeFloorPlanId)) {
				if (!x.classList.contains('emergency'))
					x.classList.add('emergency');
			} else {
				x.classList.remove('emergency');
			}

			if (props.hoveringFloor === floorPlanId || alternativeFloorPlanId && props.hoveringFloor === alternativeFloorPlanId) {
				if (!x.classList.contains('hovering'))
					x.classList.add('hovering');
			} else {
				x.classList.remove('hovering');
			}
		});
	}, [props.hoveringFloor, props.emergencyFloors]);

	const onClick = (e: React.MouseEvent) => {
		if (!e.target || !(e.target instanceof Element))
			return;

		const elementWithFloorPlanId = e.target.closest('*[data-floor-plan-id]');
		if (!(elementWithFloorPlanId instanceof SVGElement || elementWithFloorPlanId instanceof HTMLElement))
			return;

		const floorPlanId = elementWithFloorPlanId.dataset['floorPlanId'];
		if (floorPlanId)
			props.selectFloorPlan(floorPlanId);
	};

	if (loading)
		return <MessagePage className={props.className} loading />;

	if (!svg)
		return <MessagePage className={props.className} title="Failed to load building image." />;

	return <div
		ref={updateHighlighting}
		className={classNames(props.className, classes.root)}
		dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg) }}
		onClick={onClick}
	/>;
});
