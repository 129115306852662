import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';
import { ToasterService } from './toasterService';

@injectable()
export class UserGroupService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.Toaster) private _toasterService: ToasterService,
	) {

	}

	async addUserGroup(request: C.IAddUserGroupRequest): Promise<C.IUserGroupDto> {
		return await this.client.addUserGroup(new C.AddUserGroupRequest(request));
	}

	async updateUserGroup(userGroupId: string, request: C.IUpdateUserGroupRequest): Promise<C.IUserGroupDto> {
		return await this.client.updateUserGroupById(userGroupId, new C.UpdateUserGroupRequest(request));
	}

	async getUserGroup(userGroupId: string): Promise<C.IUserGroupDto | null> {
		try {
			return await this.client.getUserGroupById(userGroupId);
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load user group.');
		}

		return null;
	}

	async getAllUserGroups(): Promise<C.IUserGroupDto[] | null> {
		try {
			return await this.client.listUserGroups();
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load user groups.');
		}

		return null;
	}

	async deleteUserGroup(userGroupId: string) {
		await this.client.deleteUserGroupById(userGroupId);
	}

	async getAssetGroups(userGroupId: string) {
		return await this.client.getUserGroupAssetGroupsByUserGroupId(userGroupId);
	}

	async updateAssetGroups(userGroupId: string, request: C.IUpdateUserGroupAssetGroupsByUserGroupIdRequest) {
		await this.client.updateUserGroupAssetGroupsByUserGroupId(userGroupId, new C.UpdateUserGroupAssetGroupsByUserGroupIdRequest(request));
	}

	async getUsers(userGroupId: string) {
		return await this.client.getUserGroupUsersByUserGroupId(userGroupId);
	}

	async updateUsers(userGroupId: string, request: C.IUpdateUserGroupUsersByUserGroupIdRequest) {
		await this.client.updateUserGroupUsersByUserGroupId(userGroupId, new C.UpdateUserGroupUsersByUserGroupIdRequest(request));
	}
}
