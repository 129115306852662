import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { css } from '@emotion/css';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { FixedWidthPage, ThingLoader, Button, MaterialTable, PopoverMenu, PopoverMenuItem } from 'src/components';
import { getDeviceConfigurationTypeFormatted, getDeviceConfigurationTypeFileType as getDeviceConfigurationTypeFileExtension } from './deviceConfigurationHelpers';
import { shortDateShortTimeFormat } from 'src/util/dateTimeFormats';

import { executeQueryDealerList, QueryDealerList_dealers } from 'src/graphql/__generated__/queries/queryDealerList';

import {
	Client as C,
	Service,
	DeviceConfigurationService,
	AuthenticationService,
	ToasterService,
	useInjection,
} from 'src/services';

interface IDeviceConfigurationRow {
	deviceConfigurationId: string;
	name: string;
	configurationTypeFormatted: string;
	type: C.DeviceConfigurationType;
	dealerName: string;
	createdAt: string;
}

interface IPageData {
	deviceTypes: { [key: string]: string };
	deviceConfigurationData: IDeviceConfigurationRow[];
}

const copyConfigurationIdButtonStyles = css`
	width: 15px;
	height: 15px;

	svg {
		width: 15px;
		height: 15px;
	}
`;

export const DeviceConfigurationsList = observer(() => {
	const _apolloClient = useInjection<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	const _deviceConfigurationService = useInjection<DeviceConfigurationService>(Service.DeviceConfiguration);
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const canManageDealers = _authenticationService.currentAuth.permissions.general.manageDealers || false;

	const load = async (): Promise<IPageData | null> => {
		try {
			let deviceConfigurations = await _deviceConfigurationService.getAllDeviceConfigurations();
			deviceConfigurations = deviceConfigurations
				.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

			let dealers: QueryDealerList_dealers[] | null = null;
			if (canManageDealers) {
				const dealersListQuery = await executeQueryDealerList(_apolloClient);

				if (dealersListQuery.error || !dealersListQuery.data)
					throw dealersListQuery.error || 'Failed to load dealers.';

				dealers = dealersListQuery.data.dealers;
			}

			const dealerIdToName: { [key: string]: string } = {};
			if (dealers) {
				for (const dealer of dealers) {
					dealerIdToName[dealer.id] = dealer.name;
				}
			}

			const assetConfigData = deviceConfigurations.map((assetConfig: C.IDeviceConfigurationDto): IDeviceConfigurationRow => {
				let dealerName = 'Unknown';
				if (assetConfig.dealerId) {
					dealerName = dealerIdToName[assetConfig!.dealerId];
				} else if (assetConfig.allDealersCanAccess) {
					dealerName = 'All dealers';
				}

				return {
					deviceConfigurationId: assetConfig.deviceConfigurationId,
					name: assetConfig.name,
					configurationTypeFormatted: getDeviceConfigurationTypeFormatted(assetConfig.type),
					type: assetConfig.type,
					dealerName: dealerName,
					createdAt: assetConfig.createdAt.format(shortDateShortTimeFormat),
				};
			});

			const uniqueTypes = new Set(deviceConfigurations.map(x => getDeviceConfigurationTypeFormatted(x.type)));
			const deviceTypes: { [key: string]: string } = {};
			for (const type of uniqueTypes)
				deviceTypes[type] = type;

			return {
				deviceTypes: deviceTypes,
				deviceConfigurationData: assetConfigData,
			};
		} catch (err) {
			_toasterService.handleWithToast(err);
		}

		return null;
	};

	const renderHeadingActions = () => {
		return <Button
			startIcon={<AddIcon />}
			text="Add"
			color="primary"
			variant="outlined"
			href="/app/device-configurations/add"
		/>;
	};

	const renderActionButtons = (assetConfigRow: IDeviceConfigurationRow, reload: Function): JSX.Element[] => {
		return [
			<PopoverMenuItem
				key="download"
				text="Download"
				onClick={() => downloadConfiguration(assetConfigRow, reload)}
				title="Download"
			/>,
		];
	};

	const copyConfigurationIdToClipboard = (configurationId: string) => {
		navigator.clipboard.writeText(configurationId!);
		_toasterService.showSuccess(`Copied ID to clipboard.`);
	};

	const downloadConfiguration = async (assetConfigRow: IDeviceConfigurationRow, reload: Function) => {
		try {
			const fileResponse = await _deviceConfigurationService.downloadDeviceConfiguration(assetConfigRow.deviceConfigurationId);

			const a = document.createElement('a');
			a.href = window.URL.createObjectURL(fileResponse.data);
			a.download = fileResponse.fileName || `${assetConfigRow.name}${getDeviceConfigurationTypeFileExtension(assetConfigRow.type)}`;

			a.click();
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to download configuration.');
		}
	};

	return <ThingLoader
		load={load}
		render={(pageData: IPageData, reload: Function) => <FixedWidthPage
			headingText="Asset Configurations"
			headingActions={_authenticationService.currentAuth.user.identity.type === C.IdentityType.SuperUser ? renderHeadingActions() : null}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': true })}
		>
			<MaterialTable
				tableName="device-configurations-list"
				columns={[
					{
						title: 'Name',
						field: 'name',
						grouping: false,
						render: rowData => <>
							<IconButton
								className={copyConfigurationIdButtonStyles}
								title={`Copy Asset Configuration ID`}
								onClick={() => copyConfigurationIdToClipboard(rowData.deviceConfigurationId)}
							>
								<FileCopyIcon />
							</IconButton>
							{rowData.name}
						</>,
					},
					{
						title: 'Configuration Type',
						field: 'configurationTypeFormatted',
						lookup: pageData.deviceTypes,
					},
					{
						title: 'Dealer',
						field: 'dealerName',
						hidden: !canManageDealers,
					},
					{
						title: 'Created At',
						field: 'createdAt',
						grouping: false,
					},
					{
						title: 'Options',
						field: 'download',
						width: '25%',
						filtering: false,
						sorting: false,
						headerStyle: ({
							textAlign: 'right',
						}),
						cellStyle: () => ({
							textAlign: 'right',
						}),
						render: rowData => <PopoverMenu
							renderOptions={() => renderActionButtons(rowData, reload)}
						/>
					},
				]}
				data={pageData.deviceConfigurationData}
				options={{
					grouping: false,
				}}
			/>
		</FixedWidthPage>}
	/>;
});
