import React from 'react';
import { FormikProps, getIn } from 'formik';
import TextField from '@material-ui/core/TextField';

import './formik.scss';
import './formikDurationPicker.scss';

export interface FormikDurationProps<V> {
	name: string;
	label: string;
	helperText: string;
	form: FormikProps<V>;
	className: string;
}

interface Duration {
	hours: number;
	minutes: number;
	seconds: number;
}

enum InputType {
	Hours,
	Minutes,
	Seconds,
}

const splitDuration = (durationString?: string | null): Duration => {
	if (durationString == null) {
		return {
			hours: 0,
			minutes: 0,
			seconds: 0,
		};
	}

	const splitDuration = durationString.split(':', 3);

	return {
		hours: +splitDuration[0],
		minutes: +splitDuration[1],
		seconds: +splitDuration[2],
	};
};

export function FormikDurationPicker<V>(props: FormikDurationProps<V>) {
	const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string, inputType: InputType) => {
		const split = splitDuration(value);

		if (event.target.value == null)
			return;

		let maxValue = 0;
		let newDurationValue = '';
		switch (inputType) {
			case InputType.Hours:
				maxValue = 24;
				newDurationValue = `${event.target.value}:${split.minutes}:${split.seconds}`;
				break;
			case InputType.Minutes:
				maxValue = 60;
				newDurationValue = `${split.hours}:${event.target.value}:${split.seconds}`;
				break;
			case InputType.Seconds:
				maxValue = 60;
				newDurationValue = `${split.hours}:${split.minutes}:${event.target.value}`;
				break;
		}

		if (Number(event.target.value) >= maxValue || Number(event.target.value) < 0)
			return;

		props.form.setFieldValue(props.name, newDurationValue);
		props.form.setFieldTouched(props.name, true);
	};

	const {
		name,
		form: { touched, errors, values },
		label,
		helperText,
		className,
	} = props;

	const fieldError = getIn(errors, name);
	const fieldTouched = getIn(touched, name);
	const value = getIn(values, name) as string;

	const duration = splitDuration(value as string);

	return <div className={`formik-duration-picker ${className}`} >
		{label}

		<br/>

		<label className="formik-field">
			<TextField
				className="hours-input"
				label="Hours"
				error={fieldTouched && !!fieldError}
				helperText={(fieldTouched && fieldError) || helperText}
				name="hours"
				variant="filled"
				value={duration.hours}
				onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>, value, InputType.Hours)}
				type="number"
			/>

			<TextField
				className="minutes-input"
				label="Minutes"
				error={fieldTouched && !!fieldError}
				helperText={(fieldTouched && fieldError) || helperText}
				name="minutes"
				variant="filled"
				value={duration.minutes}
				onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>, value, InputType.Minutes)}
				type="number"
			/>

			<TextField
				className="seconds-input"
				label="Seconds"
				error={fieldTouched && !!fieldError}
				helperText={(fieldTouched && fieldError) || helperText}
				name="seconds"
				variant="filled"
				value={duration.seconds}
				onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>, value, InputType.Seconds)}
				type="number"
			/>

			{fieldTouched && fieldError && <div className="error">
				<div className="triangle"></div>
				<div className="contents">{fieldError}</div>
			</div>}
		</label>
	</div>;
}
