import { IReportFormat } from './IReportFormat';

export class LocationReportFormat implements IReportFormat {
	HasAssetSelection = true;
	HasGeofenceSelection = false;
	HasMinimumTravelSpeedAndStopTime = false;
	HasSpeedLimit = false;
	HasBillingCodes = false;
	HasUserSelection = false;
	HasAddressBookEntrySelection = false;
	ReportDefinitionHelperText = 'The locations of one or more assets at a specified time (now or in the past).';
}
