import React from 'react';
import { observer } from 'mobx-react';
import Card from '@material-ui/core/Card';
import moment from 'moment-timezone';

import CloseIcon from '@material-ui/icons/Close';

import {
	AuthenticationService,
	Service,
	useInjection,
} from 'src/services';

import 'src/app/map/mapInfoBox.scss';

import { CustomMapMarkerManager } from 'src/app/map/customMapMarkerManager';
import { Button } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';

export interface IProps {
	customMapMarkerManager: CustomMapMarkerManager;
}

export const CustomMapMarkerInfoBox = observer((props: IProps) => {
	const authService = useInjection<AuthenticationService>(Service.Authentication);

	const editorState = props.customMapMarkerManager.editorState;

	if (!editorState || editorState.editing)
		return null;

	const onClickEdit = () => {
		props.customMapMarkerManager.editExistingMarker(editorState.existingCustomMapMarker!.id);
	};

	const onClickClose = () => {
		props.customMapMarkerManager.tryStopEditingMapMarkerIfEditing('Cancelling', true);
	};

	const createdTimestamp = moment(editorState.existingCustomMapMarker?.createdTimestamp).tz(authService.currentAuth.user.timeZone);

	return <Card className="map-info-box" style={{ overflow: 'visible' }}>
		<div className="header">
			<span className="title">{editorState.name}</span>

			<div className="icon">
				<Button
					text={<CloseIcon />}
					onClick={onClickClose}
					variant="text"
				/>
			</div>
		</div>

		<div className="section">
			{editorState.details && <div className="titled-info">
				<div>Details</div>
				<div>{editorState.details}</div>
			</div>}

			<div className="titled-info">
				<div>Created</div>
				<div>{createdTimestamp.format(longDateTimeFormat)}</div>
			</div>
		</div>

		<div className="section actions">
			<div>
				{/* No left side actions. */}
			</div>

			<div>
				<Button
					text="Edit"
					variant="outlined"
					onClick={onClickEdit}
					size="small"
				/>
			</div>
		</div>
	</Card>;
});
