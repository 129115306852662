import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateATrackDevice($input: UpdateATrackDeviceInput!) {
		updateATrackDevice(input: $input) {
			atrackDevice {
				id
			}
		}
	}
`;
