import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Button } from 'src/components';

import { AssetTelematicsType } from 'src/../__generated__/globalTypes';

import {
	Client as C,
	DistanceService,
	DistanceUnit,
	Service,
	AuthenticationService,
	useInjection,
} from 'src/services';

interface Props {
	type: AssetTelematicsType;
	onCancel: () => void;
	value: number;
	assetId: string;
	onSubmit: (value: number) => Promise<void>;
}

export const EnterVehicleInformationDialog = observer((props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _distanceService = useInjection<DistanceService>(Service.DistanceService);

	const [saving, setSaving] = useState<boolean>(false);

	const [value, setValue] = useState<string>(() => {
		if (props.type === AssetTelematicsType.ODOMETER)
			return _distanceService.getDistance(props.value, _authService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles).toFixed(2);

		return (props.value / 60).toFixed(1);
	});

	const handleSubmit = async () => {
		if (!value)
			return;

		setSaving(true);

		await props.onSubmit(parseFloat(value));

		setSaving(false);
	};

	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		// Don't allow anything except numbers.
		if (props.type === AssetTelematicsType.ODOMETER && !event.target.value.match(/^[0-9]*(\.[0-9]*)?$/))
			return;
		else if (props.type === AssetTelematicsType.ENGINE_TIME && !event.target.value.match(/^[0-9]*(\.[0-9]{0,1})?$/))
			return;

		setValue(event.target.value);

		return;
	};

	const telematicsDsiplayName = props.type === AssetTelematicsType.ODOMETER ? 'Odometer' : 'Engine Hours';
	let inputAdornmentText = _authService.currentAuth.user.usesMetric ? 'km' : 'mi';
	if (props.type === AssetTelematicsType.ENGINE_TIME)
		inputAdornmentText = 'hrs';

	return <Dialog
		open
		onClose={props.onCancel}
	>
		<DialogTitle>{telematicsDsiplayName}</DialogTitle>

		<DialogContent>
			<TextField
				id="filled-name"
				label={`${telematicsDsiplayName} reading`}
				value={value}
				onChange={handleValueChange}
				margin="normal"
				variant="filled"
				autoComplete="off"
				InputProps={{
					endAdornment: <InputAdornment position="end">{inputAdornmentText}</InputAdornment>,
				}}
			/>
		</DialogContent>

		<DialogActions>
			<Button
				text="Cancel"
				onClick={props.onCancel}
				variant="outlined"
				disabled={saving}
			/>

			<Button
				autoFocus
				text="Done"
				onClick={handleSubmit}
				variant="contained"
				color="primary"
				loading={saving}
				disabled={isNaN(parseFloat(value))}
			/>
		</DialogActions>
	</Dialog>;
});
