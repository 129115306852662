import { gql } from '@apollo/client';

import { CUSTOM_MAP_MARKER_FIELDS } from 'src/graphql/fragments/customMapMarkerFields';

export const QUERY = gql`
	query QueryCustomMapMarkers {
		customMapMarkers {
			...CustomMapMarkerFields
		}
	}

	${CUSTOM_MAP_MARKER_FIELDS}
`;
