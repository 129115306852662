import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { red } from '@material-ui/core/colors';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Button } from './button';
import { CustomActionDialogBox } from './customActionDialogBox';

import { useMutationDeleteAssets } from 'src/graphql/__generated__/mutations/mutationDeleteAssets';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
	assetIds: string[];
	close: () => void;
	onSuccess?: () => Promise<any>;
}

export const DeleteAssetsDialog = observer((props: Props) => {
	const [acknowledgedWarning, setAcknowledgedWarning] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [deleteAssets] = useMutationDeleteAssets();

	const submit = async () => {
		setLoading(true);

		try {
			await deleteAssets({
				variables: {
					input: {
						ids: props.assetIds,
					},
				},
			});

			props.onSuccess && await props.onSuccess();
			props.close();
		} catch (err) {
			setLoading(false);
		}
	};

	return <CustomActionDialogBox
		title={props.assetIds.length === 1 ? `Delete Asset?` : `Delete ${props.assetIds.length} Assets?`}
		actionButton={<Button
			variant="contained"
			color={red}
			text="Delete"
			onClick={submit}
			disabled={!acknowledgedWarning}
			loading={loading}
		/>}
		dialogCloseCallback={props.close}
	>
		<DialogContentText>
			If deleted:
		</DialogContentText>

		<DialogContentText component="ul">
			<li>All active safety timer(s) for the selected asset(s) will be stopped.</li>
			<li>Any devices assigned to the selected asset(s) will be unassigned, and will not be able to record any new information <strong>(including locations and emergencies)</strong>.</li>
			<li>You will not be able to view the history of the selected asset(s).</li>
		</DialogContentText>

		<DialogContentText><strong>This cannot be undone.</strong></DialogContentText>

		<FormControlLabel
			control={<Checkbox
				onChange={(_, checked) => setAcknowledgedWarning(checked)}
				checked={acknowledgedWarning}
				color="primary"
				disabled={loading}
			/>}
			label="I acknowledge that I have read and understand the above information."
		/>
	</CustomActionDialogBox>;
});
