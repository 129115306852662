import bind from 'bind-decorator';
import mapboxgl, { LngLatLike, Marker } from 'mapbox-gl';

import { MapMarkerManager } from 'src/app/map/mapMarkerManager';

export class FloorPlanMapMarkerManager extends MapMarkerManager {
	private _markers: mapboxgl.Marker[] = [];
	private onDragMarker: (index: number, lngLat: mapboxgl.LngLat) => void;

	constructor(map: mapboxgl.Map) {
		super(map);
	}

	getMarkerCount(): number {
		return this._markers.length;
	}

	onMarkerDragEnd(onMarkerDragEndCallBack: (index: number, lngLat: mapboxgl.LngLat) => void) {
		this.onDragMarker = onMarkerDragEndCallBack;
	}

	@bind
	private onDragEnd(event: any) {
		if (this.onDragMarker) {
			const marker = event.target as Marker;
			const lngLat = marker.getLngLat();

			this.onDragMarker(parseInt(marker.getElement().id), lngLat);
		}
	}

	addMarker(id: number, lngLat: LngLatLike, draggable: boolean, editDiv?: (div: HTMLDivElement) => void) {
		const markerDiv = this.baseCreateNewMarkerElement();
		markerDiv.id = id + '';

		if (editDiv)
			editDiv(markerDiv);

		const marker = new mapboxgl.Marker(markerDiv, {
			draggable: draggable,
			anchor: 'bottom',
		}).setLngLat(lngLat);

		marker.on('dragend', this.onDragEnd);
		marker.addTo(this._map);
		this._markers.push(marker);
	}

	removeMarker(id: number): void {
		const markerIndex = this._markers.findIndex(x => x.getElement().id === id + '');
		if (markerIndex === -1)
			return;

		this._markers[markerIndex].off('dragend', this.onDragEnd);
		this._markers[markerIndex].remove();

		for (let i = markerIndex; i < this._markers.length; i++) {
			this._markers[i].getElement().innerText = i + '';
			this._markers[i].getElement().id = i + '';
		}

			this._markers.splice(markerIndex, 1);
	}

	clearMarkers(): void {
		for (const marker of this._markers) {
			marker.off('dragend', this.onDragEnd);
			marker.remove();
		}

		this._markers = [];
	}

	protected reinitialiseMarkers(): void {

	}
	protected onClickMarker(ev: MouseEvent): void {

	}
	protected onMouseOverMarker(ev: MouseEvent): void {

	}
	protected onMouseOutMarker(ev: MouseEvent): void {

	}
}
