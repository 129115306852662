import React from 'react';
import { match } from 'react-router-dom';
import moment from 'moment-timezone';

import { QueryReport_reportById } from 'src/graphql/__generated__/queries/queryReport';

import { baseUrl } from 'src/config';

import {
	AuthenticationService,
	Client as C,
	ReportService,
	Service,
	useInjection,
} from 'src/services';

import { FixedWidthPage, ThingLoader, ReportParametersDisplay } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';
import { getLocationParts } from 'src/util/locationFormats';

import './reportViewer.scss';

export interface Props {
	match: match<{ id: string }>;
}

interface LocationReportViewData {
	report: QueryReport_reportById;
	reportData: any;
}

export const ViewLocationReport = (props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _reportService = useInjection<ReportService>(Service.Report);

	const load = async (reportId: string): Promise<LocationReportViewData> => {
		const report = _reportService.getReportById(reportId);

		const reportDataResponse = await fetch(`${baseUrl}/api/v1/reporting/location-report/${reportId}/data`, {
			credentials: 'include',
			method: 'GET',
			headers: {
				'Accept': 'application/json',
			},
		});

		if (reportDataResponse.status !== 200)
			throw 'Failed to retrieve report data.';

		const reportDataResponseObject = JSON.parse(await reportDataResponse.text());

		return {
			report: await report,
			reportData: reportDataResponseObject,
		};
	};

	const locationRow = (asset: any) => {
		let currTimeStamp = '';
		let currLocation = 'Unknown';
		let locationDetails = '';

		if (asset.location && asset.location.assetLocation) {
			currTimeStamp = moment.tz(asset.timestamp, _authService.currentAuth.user.timeZone).format(longDateTimeFormat);
			currLocation = `${asset.location.assetLocation.location.latitude}, ${asset.location.assetLocation.location.longitude}`;

			if (asset.location.assetLocation.locationDetails) {
				const parts = getLocationParts(asset.location.assetLocation.locationDetails);

				if (parts)
					locationDetails = parts.join(', ');
			}
		}

		return <tr
			key={asset.assetId!.toString()}
			className="content-box"
		>
			<td>{asset.assetName}</td>
			<td>{currTimeStamp}</td>
			<td>{currLocation}</td>
			<td>{locationDetails}</td>
		</tr>;
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(reportViewData: LocationReportViewData) => <FixedWidthPage
			headingText="Location Report"
			subheadingText={reportViewData.report.name}
			noContentBackground
			contentClassName="report-viewer view-location-report"
		>
			{reportViewData.report.parameters && <ReportParametersDisplay
				reportParameters={reportViewData.report.parameters}
			/>}

			<div>
				<div className="report-section">
					<table className="card-table">
						<thead>
							<tr>
								<th>Asset Name</th>
								<th>Date of Location</th>
								<th>Asset Location</th>
								<th>Location Details</th>
							</tr>
						</thead>

						<tbody>
							{reportViewData.reportData.locationReportAssets.map(locationRow)}
						</tbody>
					</table>
				</div>
			</div>
		</FixedWidthPage>}
	/>;
};
