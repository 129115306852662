import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ErrorMessagePage, LoadingMessagePage } from 'src/components';
import { ManageBeaconComponent, ManageBeaconFormValues } from './manageBeaconComponent';
import { undefinedIfUnchanged } from 'src/util';

import { useQueryEditBeacon } from 'src/graphql/__generated__/queries/queryEditBeacon';
import { useMutationUpdateBeacon } from 'src/graphql/__generated__/mutations/mutationUpdateBeacon';

import {
	Client as C,
	Service,
	ToasterService,
	useIdentityType,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditBeacon = observer((props: Props) => {
	const identityType = useIdentityType();
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const query = useQueryEditBeacon({
		variables: {
			id: props.match.params.id,
			isSuperUser: identityType === C.IdentityType.SuperUser,
		},
	});

	const [updateBeacon] = useMutationUpdateBeacon();

	if (query.loading)
		return <LoadingMessagePage />;

	if (query.error || !query.data?.beacon)
		return <ErrorMessagePage />;

	const beacon = query.data.beacon;

	const submit = async (values: ManageBeaconFormValues) => {
		try {
			await updateBeacon({
				variables: {
					input: {
						bluetoothBeaconId: beacon.id,
						name: undefinedIfUnchanged(beacon.name, values.name),
						serialNumber: undefinedIfUnchanged(beacon.serialNumber, values.serialNumber),
						beaconType: undefinedIfUnchanged(beacon.beaconType, values.beaconType),
						transmitPower: undefinedIfUnchanged(beacon.transmitPower, values.transmitPower),
						dealerId: undefinedIfUnchanged(beacon.dealer?.id, values.dealerId),
						disabled: undefinedIfUnchanged(beacon.disabled, !values.enabled),
					},
				},
			});

			toasterService.showSuccess('Saved changes.');
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to save changes.');
		}
	};

	return <ManageBeaconComponent
		beacon={query.data.beacon}
		dealers={query.data.dealers || undefined}
		submit={submit}
	/>;
});
