import { MapViewMode } from './mapViewMode';
import { renderMapMarkerIcon } from 'src/app/assetTypes/assetTypesHelpers';
import { IMapMarker, MapMarkerManager } from './mapMarkerManager';
import { pixelSize } from 'src/util/latLngConverter';
import { IMapAsset } from './mapAsset';

import {
	Client as C,
} from 'src/services';

export interface IMapAssetMarker extends IMapMarker {
	markerElement: HTMLDivElement;
	mapboxMarker: mapboxgl.Marker;
	contentElement?: HTMLDivElement;
	currentHeading?: number;
	headingElement?: HTMLDivElement;
	pulseElement?: HTMLDivElement;
	hasEmergency?: boolean;
	emergencyType?: C.EmergencyType;
	labelElement?: HTMLDivElement;
	location: C.IAssetLocationDto;
	cluster?: boolean;
}

export abstract class AssetMapMarkerManager extends MapMarkerManager {
	private _assetTypes: Map<string, C.IAssetTypeDto>;

	constructor(map: mapboxgl.Map, assetTypes: C.IAssetTypeDto[]) {
		super(map);

		this._assetTypes = new Map<string, C.IAssetTypeDto>();
		for (const assetType of assetTypes)
			this._assetTypes.set(assetType.assetTypeId, assetType);
	}

	protected getAssetTypeForAsset(asset: IMapAsset) {
		if (!asset.assetType)
			return null;

		return this._assetTypes.get(asset.assetType.id);
	}

	protected createNewMarkerElement(asset: IMapAsset) : {mapMarker: HTMLDivElement, content: HTMLDivElement | undefined} {
		const markerElement = this.baseCreateNewMarkerElement();
		let content: HTMLDivElement | undefined = undefined;

		markerElement.className = 'connect-map-marker';

		if (!asset) {
			// Floorplan marker
		} else {
			content = this.getMapMarkerContent(asset);
			markerElement.appendChild(content);

			const assetType = this.getAssetTypeForAsset(asset);
			if (assetType && assetType.useCustomMapMarker) {
				markerElement.style.width = `${assetType.mapMarkerSize!}px`;
				markerElement.style.height = `${assetType.mapMarkerSize!}px`;
				markerElement.style.color = assetType.mapMarkerColor!;
			} else {
				markerElement.style.color = '#5185f3';
			}
		}

		return { mapMarker: markerElement, content: content};
	}

	protected createNewClusteredMarkerElement(assetCount: number) : { mapMarker: HTMLDivElement, content: HTMLDivElement | undefined } {
		const markerElement = this.baseCreateNewMarkerElement();
		let content: HTMLDivElement | undefined = undefined;

		markerElement.className = 'connect-map-marker';
		markerElement.classList.add('connect-map-marker__clustered-assets');
		markerElement.style.color = '#5185f3';

		content = document.createElement('div');
		content.textContent = assetCount.toString();
		content.className = 'connect-map-marker__content connect-map-marker__content-cluster';

		markerElement.appendChild(content);

		return { mapMarker: markerElement, content: content };
	}

	protected getMapMarkerContent(asset: IMapAsset) : HTMLDivElement  {
		const mapMarkerContent = document.createElement('div');
		mapMarkerContent.className = 'connect-map-marker__content';

		const assetType = this.getAssetTypeForAsset(asset);
		if (assetType && assetType.useCustomMapMarker) {
			// Create the custom icon.
			mapMarkerContent.style.backgroundColor = assetType.mapMarkerColor!;
			renderMapMarkerIcon(mapMarkerContent, assetType.icon ?? undefined, assetType.iconSize, assetType.iconColor);
		}

		return mapMarkerContent;
	}

	protected setLocation(mapMarker: IMapAssetMarker, assetLocation: C.IAssetLocationDto): boolean {
		// Don't need to move the marker if the position is the same.
		if (assetLocation.location.longitude === mapMarker.location.location.longitude &&
			assetLocation.location.latitude === mapMarker.location.location.latitude &&
			assetLocation.floorLevelIndex === mapMarker.location.floorLevelIndex) {
			return true;
		}

		const nextLocation = this.getLocation(assetLocation);
		if (!nextLocation) {
			return false;
		}

		return this.setLocationLngLat(mapMarker.mapboxMarker, nextLocation);
	}

	protected setHeading(mapMarker: IMapAssetMarker, heading: number | undefined, asset?: IMapAsset | null) {
		if (heading === mapMarker.currentHeading)
			return;

		if (heading === undefined) {
			mapMarker.markerElement.removeChild(mapMarker.headingElement!);
			mapMarker.headingElement = undefined;
			mapMarker.currentHeading = undefined;

			return;
		}

		if (mapMarker.currentHeading == undefined) {
			mapMarker.headingElement = document.createElement('div');
			mapMarker.headingElement.className = 'connect-map-marker__asset__heading';
			mapMarker.headingElement.style.transform = `rotate(${heading}deg)`;

			const headingPointer = document.createElement('div');
			headingPointer.className = 'connect-map-marker__asset__heading__arrow';
			mapMarker.headingElement.appendChild(headingPointer);

			// Handling for changing the colour and positon of the heading arrow.
			const assetType = asset && this.getAssetTypeForAsset(asset);
			if (assetType && assetType.useCustomMapMarker) {
				mapMarker.headingElement.style.height = assetType.mapMarkerSize ? `${assetType.mapMarkerSize + 19}px` : '35px';
				headingPointer.style.borderBottom = `10px solid ${assetType.mapMarkerSize}`;
			}

			mapMarker.markerElement.appendChild(mapMarker.headingElement);
		} else {
			mapMarker.headingElement!.style.transform = `rotate(${heading}deg)`;
		}

		mapMarker.currentHeading = heading;
	}

	protected setPulse(mapMarker: IMapAssetMarker, pulse: boolean, asset?: IMapAsset | null) {
		if (!pulse === !mapMarker.pulseElement)
			return;

		if (pulse) {
			mapMarker.pulseElement = document.createElement('div');
			mapMarker.pulseElement.className = 'connect-map-marker__asset__pulse';

			// Change pulse to be the colour of the custom iccon background.
			const assetType = asset && this.getAssetTypeForAsset(asset);
			if (assetType && assetType?.useCustomMapMarker) {
				mapMarker.pulseElement.style.width = `${assetType!.mapMarkerSize! + 32}px`;
				mapMarker.pulseElement.style.height = `${assetType!.mapMarkerSize! + 32}px`;
			}

			mapMarker.markerElement.appendChild(mapMarker.pulseElement);
		} else {
			mapMarker.markerElement.removeChild(mapMarker.pulseElement!);
			mapMarker.pulseElement = undefined;
		}
	}

	protected setEmergency(mapMarker: IMapAssetMarker, emergency: boolean | undefined, emergencyType?: C.EmergencyType | null, asset?: IMapAsset | null) {
		if (!mapMarker.hasEmergency === !emergency && mapMarker.emergencyType === emergencyType)
			return;

		if (emergency) {
			switch (emergencyType) {
				case C.EmergencyType.PriorityAlert:
					mapMarker.markerElement.classList.remove('emergency');
					mapMarker.markerElement.classList.add('warning');
					break;

				default:
					mapMarker.markerElement.classList.remove('warning');
					mapMarker.markerElement.classList.add('emergency');
					break;
			}

			// If the marker is for a cluster, only the colour of the marker changes (to red).
			// If the marker is for a single asset, the content also gets changed to an exclamation point.
			if (!mapMarker.cluster) {
				if (mapMarker.contentElement) {
					mapMarker.markerElement.removeChild(mapMarker.contentElement!);
					mapMarker.contentElement = undefined;
				}

				mapMarker.contentElement = document.createElement('div');
				mapMarker.contentElement.className = 'connect-map-marker__content';
				mapMarker.contentElement.appendChild(document.createTextNode('!'));
				mapMarker.markerElement.appendChild(mapMarker.contentElement);

				// Change size of the '!' in the emergency icon.
				const assetType = asset && this.getAssetTypeForAsset(asset);
				if (assetType && assetType.useCustomMapMarker) {
					mapMarker.contentElement.style.fontSize = `${assetType!.mapMarkerSize! - (assetType!.mapMarkerSize! / 10)}px`;
				}
			}
		} else {
			mapMarker.markerElement.classList.remove('emergency');
			mapMarker.markerElement.classList.remove('warning');

			if (!mapMarker.cluster) {
				mapMarker.markerElement.removeChild(mapMarker.contentElement!);
				mapMarker.contentElement = undefined;

				//Get content for map marker.
				let content = document.createElement('div');
				if (asset) {
					content = this.getMapMarkerContent(asset);
				} else {
					content.className = 'connect-map-marker__content';
				}

				mapMarker.markerElement.appendChild(content);
				mapMarker.contentElement = content;
			}
		}

		mapMarker.emergencyType = emergencyType ? emergencyType : C.EmergencyType.Emergency;
		mapMarker.hasEmergency = emergency;
	}

	getLocation(assetLocation: C.IAssetLocationDto): { lng: number; lat: number; } | undefined {
		if (this._currentMapViewMode === MapViewMode.FloorPlan && this._currentMapFloorPlan) {
			if (!assetLocation.floorPlanLocation || assetLocation.floorPlanLocation.floorPlanId !== this._currentMapFloorPlan.floorPlanId)
				return;

			return {
				lng: assetLocation.floorPlanLocation.location.x * pixelSize,
				lat: assetLocation.floorPlanLocation.location.y * -pixelSize,
			};
		}

		return {
			lng: assetLocation.location.longitude,
			lat: assetLocation.location.latitude,
		};
	}
}
