import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetServiceRemindersForLiveMap {
		assetServiceReminders  {
			id
			state
			asset {
				id
			}
		}
	}
`;
