import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import {
	Client as C,
	FloorPlansService,
	Service,
	useInjection,
} from 'src/services';

import { ManageFloorPlanComponent } from './manageFloorPlanComponent';
import { ThingLoader } from 'src/components';

export interface EditFloorPlanProps {
	match: match<{ id: string }>;
}

export const EditFloorPlan = observer((props: EditFloorPlanProps) => {
	const _floorPlansService = useInjection<FloorPlansService>(Service.FloorPlans);

	const load = async (floorPlanId: string) => {
		return await _floorPlansService.getFloorPlan(floorPlanId);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(floorPlan: C.IFloorPlanDto) => <ManageFloorPlanComponent
			floorPlan={floorPlan}
			floorPlanGroupId={floorPlan.parentGroupId}
		/>}
	/>;
});
