import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetServiceReminderTypeById($id: UUID!, $includeClients: Boolean!) {
		assetServiceReminderTypeById(id: $id) {
			id
			name
			client @include(if: $includeClients) {
				id
				name
			}
			reminders {
				id
				asset {
					id
					name
					assetTelematicsCurrentValueAndReadings {
						value
						type
					}
				}
				state
				nextDateTriggerDate
				dateDefaultFrequency
				nextOdometerTrigger
				odometerDefaultFrequency
				nextEngineTimeTrigger
				engineTimeDefaultFrequency
				extraInformation
				users {
					id
					name
					identity {
						id
						client @include(if: $includeClients) {
							id
						}
					}
				}
				userGroups {
					id
					name
				}
			}
			dateEnabled
			dateWhenToRemindInAdvance
			odometerEnabled
			odometerWhenToRemindInAdvance
			engineTimeEnabled
			engineTimeWhenToRemindInAdvance
			message
		}
	}
`;
