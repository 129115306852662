import mapboxgl, { GeoJSONSource, LngLat } from 'mapbox-gl';
import { action, makeObservable, observable } from 'mobx';
import turfKinks from '@turf/kinks';
import turfInPolygon from '@turf/boolean-point-in-polygon';
import turfCenter from '@turf/center';
import { bind } from 'bind-decorator';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { AllGeoJSON } from '@turf/helpers';

import {
	Client as C,
} from 'src/services';

import { MapViewMode } from './mapViewMode';
import { convertLatLngToXy } from 'src/util/latLngConverter';

const DEFAULT_GEOFENCE_COLOR = '#0000ff';
const DEFAULT_GEOFENCE_FILL_OPACITY = 0.1;

export class GeofenceEditorState {
	existingGeofence?: C.IGeofenceDto;
	points: LngLat[];
	allPointsConnected: boolean;
	valid: boolean;
	colour: string;
	fillOpacity: number;

	static newGeofence(): GeofenceEditorState {
		return {
			points: [],
			allPointsConnected: false,
			valid: false,
			colour: DEFAULT_GEOFENCE_COLOR,
			fillOpacity: DEFAULT_GEOFENCE_FILL_OPACITY,
		};
	}

	static existingGeofence(geofence: C.IGeofenceDto, floorPlan: C.IFloorPlanDto | undefined, currentMapViewMode: MapViewMode): GeofenceEditorState {
		let points: mapboxgl.LngLat[] = [];

		if (floorPlan && currentMapViewMode === MapViewMode.FloorPlan) {
			points = geofence.coordinates
				.map(x => convertLatLngToXy(floorPlan, x[1], x[0], true))
				.map<LngLat>(x => new LngLat(x[0], x[1]));
		} else {
			points = geofence.coordinates.map<LngLat>(x => new LngLat(x[0], x[1]));
		}
		points.pop();

		return {
			existingGeofence: geofence,
			points: points,
			allPointsConnected: true,
			valid: true,
			colour: geofence.geofenceType ? geofence.geofenceType.color : DEFAULT_GEOFENCE_COLOR,
			fillOpacity: geofence.geofenceType ? geofence.geofenceType.fillOpacity : DEFAULT_GEOFENCE_FILL_OPACITY,
		};
	}
}

export class GeofenceManager {
	private _map: mapboxgl.Map;
	private _onClickGeofence: (geofence: C.IGeofenceDto) => void;

	private _geofences = new Map<string, C.IGeofenceDto>();
	@observable geofenceTypes = new Array<C.IGeofenceTypeDto>();

	@observable editorState: GeofenceEditorState | undefined;
	private _editorMarkers = new Map<mapboxgl.Marker, number>();
	private _markerElements = new Map<HTMLDivElement, mapboxgl.Marker>();

	private selectedFloorPlan: C.IFloorPlanDto | undefined;
	private currentMapViewMode: MapViewMode;

	private _mousePosition?: LngLat;
	private _hoveringMarker? : mapboxgl.Marker;

	private _geofenceHoverLabel?: mapboxgl.Popup;
	private _geofenceLabelBehavior: C.MapLabelBehavior;

	constructor(map: mapboxgl.Map, onClickGeofence: (geofence: C.IGeofenceDto) => void, geofenceTypes: C.IGeofenceTypeDto[], geofenceLabelBehavior: C.MapLabelBehavior) {
		makeObservable(this);

		this._map = map;
		this._onClickGeofence = onClickGeofence;
		this.geofenceTypes = geofenceTypes;
		this._geofenceLabelBehavior = geofenceLabelBehavior;

		this._map.on('click', this.clicked);
		this._map.on('mousemove', this.mouseMove);
		this._map.on('mousemove', 'geofences__hover', this.mouseMoveGeofence);
		this._map.on('mouseleave', 'geofences__hover', this.mouseLeaveGeofence);
		this._map.on('click', 'geofences__hover', this.clickGeofence);
	}

	initialise(currentMapViewMode: MapViewMode, selectedFloorPlan?: C.IFloorPlanDto) {
		this.selectedFloorPlan = selectedFloorPlan;
		this.currentMapViewMode = currentMapViewMode;

		// Add geofence GeoJson data.
		const featureCollection = this.createGeofenceFeatureCollection();
		this._map.addSource('geofences', {
			type: 'geojson',
			data: featureCollection,
		});

		this._map.addLayer({
			id: 'geofences__fill',
			source: 'geofences',
			type: 'fill',
			paint: {
				'fill-color': ['get', 'colour'],
				'fill-opacity': ['get', 'fillOpacity'],
			},
		});

		this._map.addLayer({
			id: 'geofences__hover',
			source: 'geofences',
			type: 'line',
			paint: {
				'line-color': 'rgba(255, 255, 255, 0)',
				'line-width': 15,
			},
		});

		this._map.addLayer({
			id: 'geofences__outline',
			source: 'geofences',
			type: 'line',
			paint: {
				'line-color': ['get', 'colour'],
				'line-width': 2.5,
			},
		});

		// Add geofence label GeoJson data.
		const labelsFeatureCollection = this.createGeofenceLabelFeatureCollection(featureCollection);
		this._map.addSource('geofence-labels', {
			type: 'geojson',
			data: labelsFeatureCollection,
		});

		this._map.addLayer({
			id: 'geofences-labels__text',
			source: 'geofence-labels',
			type: 'symbol',
			layout: {
				'text-field': '{name}',
				'visibility': this._geofenceLabelBehavior === C.MapLabelBehavior.ShowAlways ? 'visible' : 'none',
			},
		});

		if (this.editorState) {
			this.addEditorSourcesAndLayers();
			this.updateEditorGeofence();
		}
	}

	addGeofence(geofence: C.IGeofenceDto) {
		this._geofences.set(geofence.geofenceId, geofence);
	}

	removeGeofence(geofenceId: string) {
		this._geofences.delete(geofenceId);
	}

	handleGeofenceLabelBehaviorChange(geofenceLabelBehavior: C.MapLabelBehavior) {
		this._geofenceLabelBehavior = geofenceLabelBehavior;

		this._map.setLayoutProperty('geofences-labels__text', 'visibility', this._geofenceLabelBehavior === C.MapLabelBehavior.ShowAlways ? 'visible' : 'none');
	}

	private createGeofenceFeatureCollection(): FeatureCollection {
		const geofenceFeatures = Array.from(this._geofences.values())
			.filter(x => this.selectedFloorPlan ? (x.floorPlanId === this.selectedFloorPlan!.floorPlanId) : !x.floorPlanId)
			.map<GeoJSON.Feature>(geofence => {
				let geofenceCoordinates: number[][] = geofence.coordinates;
				if (this.currentMapViewMode === MapViewMode.FloorPlan && this.selectedFloorPlan) {
					geofenceCoordinates = geofence.coordinates
						.map(coordinate => convertLatLngToXy(this.selectedFloorPlan!, coordinate[1], coordinate[0], true))
						.map(coords => [coords[0], coords[1]]);
				}

				return {
					type: 'Feature',
					properties: {
						geofenceId: geofence.geofenceId,
						colour: geofence.geofenceType ? geofence.geofenceType.color : DEFAULT_GEOFENCE_COLOR,
						fillOpacity: geofence.geofenceType ? geofence.geofenceType.fillOpacity : DEFAULT_GEOFENCE_FILL_OPACITY,
					},
					geometry: {
						type: 'Polygon',
						coordinates: [ geofenceCoordinates ],
					},
				};
			});

		return {
			type: 'FeatureCollection',
			features: geofenceFeatures,
		};
	}

	private createGeofenceLabelFeatureCollection(geofenceFeatures: FeatureCollection<Geometry, GeoJsonProperties>): FeatureCollection {
		const geofenceLabelFeatures = Array.from(this._geofences.values())
			.filter(x => this.selectedFloorPlan ? (x.floorPlanId === this.selectedFloorPlan!.floorPlanId) : !x.floorPlanId)
			.map<GeoJSON.Feature>(geofence => {
				// Get the center of the Geofence, where the label will be placed.
				const geofenceFeature = Array.from(geofenceFeatures.features).filter(x => x.properties!= null && x.properties.geofenceId == geofence.geofenceId);
				const center = turfCenter(geofenceFeature[0] as AllGeoJSON);

				return {
					type: 'Feature',
					properties: {
						name: geofence.name,
						geofenceId: geofence.geofenceId,
						colour: geofence.geofenceType ? geofence.geofenceType.color : DEFAULT_GEOFENCE_COLOR,
					},
					geometry: {
						type: 'Point',
						coordinates: center.geometry.coordinates,
					},
				};
			});

		return {
			type: 'FeatureCollection',
			features: geofenceLabelFeatures,
		};
	}

	updateGeofences() {
		const featureCollection = this.createGeofenceFeatureCollection();
		const geofenceSource = this._map.getSource('geofences') as GeoJSONSource;
		geofenceSource.setData(featureCollection);

		const geofenceLabelCollection = this.createGeofenceLabelFeatureCollection(featureCollection);
		const geofenceLabelSource = this._map.getSource('geofence-labels') as GeoJSONSource;
		geofenceLabelSource.setData(geofenceLabelCollection);
	}

	private addEditorSourcesAndLayers() {
		this._map.addSource('edit-geofence__outline', {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: [],
			},
		});

		this._map.addSource('edit-geofence__fill', {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: [],
			},
		});

		this._map.addLayer({
			id: 'edit-geofence__outline',
			source: 'edit-geofence__outline',
			type: 'line',
			paint: {
				'line-color': [
					'case',
					['any', ['has', 'overlapping'], ['has', 'outsideOfFloorPlan']],
					'#ff0000',
					['get', 'colour'],
				],
				'line-width': 2,
				'line-dasharray': [1, 1],
			},
		});

		this._map.addLayer({
			id: 'edit-geofence__fill',
			source: 'edit-geofence__fill',
			type: 'fill',
			paint: {
				'fill-color': ['get', 'colour'],
				'fill-opacity': ['get', 'fillOpacity'],
			},
		});
	}

	startDrawing(existingGeofence: C.IGeofenceDto | undefined = undefined) {
		if (existingGeofence)
			this.editorState = GeofenceEditorState.existingGeofence(existingGeofence, this.selectedFloorPlan, this.currentMapViewMode);
		else
			this.editorState = GeofenceEditorState.newGeofence();

		this._map.getCanvasContainer().classList.add('geofence-drawing');

		this.addEditorSourcesAndLayers();
		this.updateEditorGeofence();

		for (let i = 0; i < this.editorState.points.length; i++) {
			this.createEditorMarker(this.editorState.points[i], i);
		}
	}

	@bind
	tryStopEditingGeofenceIfEditing(action: string, restoreExisting: boolean): boolean {
		if (this.editorState) {
			const message = action + ' will exit the geofence editor and any changes will be lost. Continue?';
			if (!confirm(message))
				return false;

			this.stopEditingGeofence(restoreExisting);
		}

		return true;
	}

	@bind
	stopEditingGeofence(restoreExisting: boolean) {
		this._map.getCanvasContainer().classList.remove('geofence-drawing');

		if (this._map.getLayer('edit-geofence__fill'))
			this._map.removeLayer('edit-geofence__fill');

		if (this._map.getLayer('edit-geofence__outline'))
			this._map.removeLayer('edit-geofence__outline');

		if (this._map.getSource('edit-geofence__fill'))
			this._map.removeSource('edit-geofence__fill');

		if (this._map.getSource('edit-geofence__outline'))
			this._map.removeSource('edit-geofence__outline');

		for (const marker of this._editorMarkers.keys())
			marker.remove();

		this._editorMarkers.clear();
		this._markerElements.clear();
		this._hoveringMarker = undefined;

		if (this.editorState && this.editorState.existingGeofence && restoreExisting) {
			this._geofences.set(this.editorState.existingGeofence.geofenceId, this.editorState.existingGeofence);
			this.updateGeofences();
		}

		this.editorState = undefined;
	}

	@bind
	setEditingGeofenceTypeId(geofenceTypeId: string | null) {
		if (!this.editorState)
			return;

		const geofenceType = this.geofenceTypes.find(x => x.geofenceTypeId === geofenceTypeId);

		if (geofenceType) {
			this.editorState.colour = geofenceType.color;
			this.editorState.fillOpacity = geofenceType.fillOpacity;
		} else {
			this.editorState.colour = DEFAULT_GEOFENCE_COLOR;
			this.editorState.fillOpacity = DEFAULT_GEOFENCE_FILL_OPACITY;
		}

		this.updateEditorGeofence();
	}

	@action.bound
	mouseMove(e: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
		this._mousePosition = e.lngLat;

		let hoveringMarker: mapboxgl.Marker | undefined = undefined;
		if (e.originalEvent.target instanceof HTMLDivElement)
			hoveringMarker = this._markerElements.get(e.originalEvent.target);

		this._hoveringMarker = hoveringMarker;

		if (!this.editorState)
			return;

		this.updateEditorGeofence();
	}

	@bind
	mouseMoveGeofence(e: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; } & mapboxgl.EventData) {
		if (!this.editorState)
			this._map.getCanvas().style.cursor = 'pointer';

		if (this._geofenceHoverLabel)
			this._geofenceHoverLabel.remove();

		const featureProperties = e.features && e.features.length > 0 && e.features[0].properties;
		if (!featureProperties)
			return;

		const geofenceId = featureProperties['geofenceId'] as string;
		const geofence = this._geofences.get(geofenceId);
		if (!geofence)
			return;

		this._geofenceHoverLabel = new mapboxgl.Popup({
				closeButton: false,
				closeOnClick: false,
				anchor: 'left',
				className: 'geofence-hover-label'
			})
			.setLngLat(e.lngLat)
			.setHTML(geofence.name)
			.addTo(this._map);
	}

	@bind
	mouseLeaveGeofence(e: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; } & mapboxgl.EventData) {
		this._map.getCanvas().style.cursor = '';

		if (this._geofenceHoverLabel)
			this._geofenceHoverLabel.remove();
	}

	@bind
	clickGeofence(e: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; } & mapboxgl.EventData) {
		if (this.editorState)
			return;

		const featureProperties = e.features && e.features.length > 0 && e.features[0].properties;
		if (!featureProperties)
			return;

		const geofenceId = featureProperties['geofenceId'] as string;
		const geofence = this._geofences.get(geofenceId);
		if (!geofence)
			return;

		this._onClickGeofence(geofence);
	}

	@bind
	clicked(e: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
		if (!this.editorState)
			return;

		if (this.editorState.allPointsConnected)
			return;

		if (this._hoveringMarker) {
			this.finishDrawingGeofence();
			return;
		}

		const location = this._mousePosition;
		if (!location)
			return;

		const markerNumber = this.editorState!.points.length;
		this.editorState.points.push(location);

		this.createEditorMarker(location, markerNumber);

		this.updateEditorGeofence();
	}

	@bind
	private createEditorMarker(location: mapboxgl.LngLat, markerNumber: number) {
		const element = document.createElement('div');
		element.className = 'map-geofence__drawing__point';

		const marker = new mapboxgl.Marker(element, { draggable: true })
			.setLngLat(location)
			.addTo(this._map);

		marker.on('drag', this.onMarkerDrag);

		this._editorMarkers.set(marker, markerNumber);
		this._markerElements.set(element, marker);

		this._hoveringMarker = marker;
	}

	@bind
	updateEditorGeofence() {
		if (!this.editorState)
			return;

		const geofencePoints = [ ...this.editorState.points ];
		if (!this.editorState.allPointsConnected) {
			if (!this._hoveringMarker && this._mousePosition)
				geofencePoints.push(this._mousePosition);
		}

		let overlapping: boolean | undefined = undefined;
		let outsideOfFloorPlan: boolean | undefined = undefined;
		let validGeofence = true;
		if (this.editorState.points.length > 0) {
			if (this.editorState.points.length > 1)
				geofencePoints.push(this.editorState.points[0]);

			// Check if any points are outside of the floor plan.
			if (this.selectedFloorPlan) {
				const floorPlanPolygon: GeoJSON.Polygon = {
					type: 'Polygon',
						coordinates: []
				};

				if (this.currentMapViewMode === MapViewMode.World) {
					floorPlanPolygon.coordinates = [[
						[this.selectedFloorPlan.topLeft.longitude, this.selectedFloorPlan.topLeft.latitude],
						[this.selectedFloorPlan.topRight.longitude, this.selectedFloorPlan.topRight.latitude],
						[this.selectedFloorPlan.bottomRight.longitude, this.selectedFloorPlan.bottomRight.latitude],
						[this.selectedFloorPlan.bottomLeft.longitude, this.selectedFloorPlan.bottomLeft.latitude],
						[this.selectedFloorPlan.topLeft.longitude, this.selectedFloorPlan.topLeft.latitude],
					]];
				} else {
					// Adjust the polygon coordinates to be in floor plan space.
					floorPlanPolygon.coordinates = [[
						convertLatLngToXy(this.selectedFloorPlan, this.selectedFloorPlan.topLeft.latitude, this.selectedFloorPlan.topLeft.longitude, true),
						convertLatLngToXy(this.selectedFloorPlan, this.selectedFloorPlan.topRight.latitude, this.selectedFloorPlan.topRight.longitude, true),
						convertLatLngToXy(this.selectedFloorPlan, this.selectedFloorPlan.bottomRight.latitude, this.selectedFloorPlan.bottomRight.longitude, true),
						convertLatLngToXy(this.selectedFloorPlan, this.selectedFloorPlan.bottomLeft.latitude, this.selectedFloorPlan.bottomLeft.longitude, true),
						convertLatLngToXy(this.selectedFloorPlan, this.selectedFloorPlan.topLeft.latitude, this.selectedFloorPlan.topLeft.longitude, true),
					]];
				}

				if (geofencePoints.some(x => !turfInPolygon([x.lng, x.lat], floorPlanPolygon))) {
					validGeofence = false;
					outsideOfFloorPlan = true;
				}
			}
		}

		if (geofencePoints.length > 2) {
			const geofencePolygon: GeoJSON.Polygon = {
				type: 'Polygon',
				coordinates: [ geofencePoints.map(x => [x.lng, x.lat]) ],
			};

			const kinks = turfKinks(geofencePolygon);
			const source = this._map.getSource('edit-geofence__fill') as GeoJSONSource;
			if (kinks.features.length === 0 && validGeofence) {
				source.setData({
					type: 'Feature',
					properties: {
						colour: this.editorState.colour,
						fillOpacity: this.editorState.fillOpacity,
					},
					geometry: geofencePolygon,
				});
			} else {
				source.setData({
					type: 'FeatureCollection',
					features: [],
				});
			}

			overlapping = kinks.features.length !== 0 ? true : undefined;

			if (validGeofence)
				validGeofence = this.editorState.allPointsConnected && !overlapping;
		} else {
			validGeofence = false;
		}

		this.editorState.valid = validGeofence;

		const source = this._map.getSource('edit-geofence__outline') as GeoJSONSource;
		source.setData({
			type: 'Feature',
			properties: {
				colour: this.editorState.colour,
				overlapping: overlapping,
				outsideOfFloorPlan: outsideOfFloorPlan,
			},
			geometry: {
				type: 'LineString',
				coordinates: geofencePoints.map(x => [x.lng, x.lat]),
			},
		});
	}

	@bind
	onMarkerDrag(e: any) {
		const marker = e.target as mapboxgl.Marker;
		const existing = this._editorMarkers.get(marker);

		this.editorState!.points[existing!] = marker.getLngLat();

		this.updateEditorGeofence();
	}

	@bind
	finishDrawingGeofence() {
		this.editorState!.allPointsConnected = true;
		this.updateEditorGeofence();
	}
}
