import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddAssetTelematicsReading($input: AddAssetTelematicsReadingInput!) {
		addAssetTelematicsReading(input: $input) {
			assetTelematicsReading {
				id
				recordedAt
				value
				type
				asset {
					id
				}
			}
		}
	}
`;
