import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';
import { ManageBleBeaconSetComponent } from './manageBleBeaconSetComponent';

import {
	BeaconsService,
	Client as C,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditBleBeaconSet = observer((props: Props) => {
	const _bleBeaconService = useInjection<BeaconsService>(Service.Beacons);

	const load = async (beaconSetId: string): Promise<C.IBleBeaconSetDto | null> => {
		const bleBeaconSet = await _bleBeaconService.getBeaconSetById(beaconSetId);
		if (!bleBeaconSet)
			return null;

		return bleBeaconSet;
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(bleBeaconSet: C.IBleBeaconSetDto) => <ManageBleBeaconSetComponent
			beaconSet={bleBeaconSet}
		/>}
	/>;
});
