import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import {
	Client as C,
	AuthenticationService,
	BeaconsService,
	Service,
	useInjection,
} from 'src/services';

import { QueryManageBeaconSet_bluetoothBeaconSet } from 'src/graphql/__generated__/queries/queryManageBeaconSet';

import { DialogItem, SelectItemsDialog } from 'src/components/selectItemsDialog';

export interface IBeacon {
	id: string;
	name: string;
	dealer?: null | {
		id: string;
	};
}

interface Props {
	beaconSet: QueryManageBeaconSet_bluetoothBeaconSet;
	beacons: IBeacon[];
	existing: IBeacon[];
	complete: (added: string[]) => void;
	closeDialog: () => void;
}

export const AddBeaconsDialog = observer((props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _beaconsService = useInjection<BeaconsService>(Service.Beacons);

	const [saving, setSaving] = useState<boolean>(false);
	const [savingError, setSavingError] = useState<boolean>(false);

	const items = useMemo(() => {
		const exclude = new Set<string>(props.existing.map(x => x.id));

		let beacons = props.beacons.filter(x => !exclude.has(x.id));

		if (_authService.currentAuth.user.identity.type === C.IdentityType.SuperUser)
			beacons = beacons.filter(x => x.dealer?.id && x.dealer.id === props.beaconSet.dealer?.id);

		return beacons
			.map<DialogItem>(x => ({
				id: x.id,
				name: x.name,
			}))
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
	}, [props.beacons, props.existing]);

	const addBeacons = async (bleBeaconIds: string[]) => {
		setSaving(true);

		try {
			await _beaconsService.addBeaconsToASetById(props.beaconSet.id, {
				bleBeaconIds,
			});

			props.complete(bleBeaconIds);
			props.closeDialog();
		} catch (err) {
			setSavingError(true);
			setSaving(false);
		}
	};

	return <SelectItemsDialog
		title="Add Beacons to Set"
		complete={addBeacons}
		close={props.closeDialog}
		saving={saving}
		savingError={savingError}
		items={items}
	/>;
});
