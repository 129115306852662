import React from 'react';

import { ThingLoader } from 'src/components';
import { ManageAssetTypesComponent } from './manageAssetTypesComponent';

import {
	Client as C,
	ClientService,
	AuthenticationService,
	Service,
	useInjection,
} from 'src/services';

export const AddAssetType = () => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _clientService = useInjection<ClientService>(Service.Client);

	const load = async (): Promise<C.IClientDto[] | null> => {
		let clients: C.IClientDto[] | null = [];
		if (_authService.currentAuth.user.identity.type !== C.IdentityType.Client)
			clients = await _clientService.getAllClients();

		return clients;
	};

	return <ThingLoader
		load={load}
		render={(clients: C.IClientDto[]) => <ManageAssetTypesComponent
			clients={clients}
		/>}
	/>;
};
