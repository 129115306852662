import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateAssetServiceReminder($input: UpdateAssetServiceReminderInput!) {
		updateAssetServiceReminder(input: $input) {
			assetServiceReminder {
				id
			}
		}
	}
`;
