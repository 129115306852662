import React from 'react';

import { Button, FixedWidthPage } from 'src/components';

import {
	AuthenticationService,
	Service,
	useInjection,
} from 'src/services';

import './createReport.scss';

export const CreateReport = () => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);

	return <FixedWidthPage
		headingText="Create Report"
		noContentBackground
		className="create-report"
	>
		{_authService.currentAuth.permissions.general.viewAssetLocations && <>
			<h2 className="title">Assets</h2>

			<div className="content-box report-type">
				<div className="info">
					<h3 className="title">Activity</h3>

					<div className="summary">
						The activity (travelling/stopped) of one or more assets during a specified time period.
					</div>
				</div>

				<div className="actions">
					<Button text="Create" variant="contained" color="primary" href="/app/reports/create/activity" />
				</div>
			</div>

			<div className="content-box report-type">
				<div className="info">
					<h3 className="title">Asset Events Export</h3>

					<div className="summary">
						Export all asset events for one or more assets during a specified time period.
					</div>
				</div>

				<div className="actions">
					<Button text="Create" variant="contained" color="primary" href="/app/reports/create/asset-events-export" />
				</div>
			</div>

			<div className="content-box report-type">
				<div className="info">
					<h3 className="title">Daily</h3>

					<div className="summary">
						The daily summaries of activity (travelling/stopped) of one or more assets during a specified time period.
					</div>
				</div>

				<div className="actions">
					<Button text="Create" variant="contained" color="primary" href="/app/reports/create/daily" />
				</div>
			</div>

			<div className="content-box report-type">
				<div className="info">
					<h3 className="title">Geofence</h3>

					<div className="summary">
						Geofences visited by one or more assets during a specified time period.
					</div>
				</div>

				<div className="actions">
					<Button text="Create" variant="contained" color="primary" href="/app/reports/create/geofence" />
				</div>
			</div>

			<div className="content-box report-type">
				<div className="info">
					<h3 className="title">Location</h3>

					<div className="summary">
						The locations of one or more assets at a specified time (now or in the past).
					</div>
				</div>

				<div className="actions">
					<Button text="Create" variant="contained" color="primary"  href="/app/reports/create/location"/>
				</div>
			</div>

			<div className="content-box report-type">
				<div className="info">
					<h3 className="title">Safety Timer Report</h3>

					<div className="summary">
						The safety timer history for one or more assets during a specified time period.
					</div>
				</div>

				<div className="actions">
					<Button text="Create" variant="contained" color="primary"  href="/app/reports/create/safety-timer"/>
				</div>
			</div>
		</>}

		<h2 className="title">Users</h2>

		{_authService.currentAuth.permissions.general.manageAddressBooks && _authService.currentAuth.permissions.general.manageAssets && <>
			<div className="content-box report-type">
				<div className="info">
					<h3 className="title">Driver Report</h3>

					<div className="summary">
						The history for drivers, which details what assets the drivers have used during a specified time period.
					</div>
				</div>

				<div className="actions">
					<Button text="Create" variant="contained" color="primary"  href="/app/reports/create/driver" />
				</div>
			</div>
		</>}

		{_authService.currentAuth.permissions.general.manageUsers && _authService.currentAuth.permissions.general.manageUserGroups && <div className="content-box report-type">
			<div className="info">
				<h3 className="title">User Auth Audit Report</h3>

				<div className="summary">
					The authorization actions for one or more users during a specified time period (e.g. sign in/sign out/sign in as).
				</div>
			</div>

			<div className="actions">
				<Button text="Create" variant="contained" color="primary"  href="/app/reports/create/user-auth-audit" />
			</div>
		</div>}

		{_authService.currentAuth.permissions.general.accessBillingReports && <>
			<h2 className="title">Billing</h2>

			<div className="content-box report-type">
				<div className="info">
					<h3 className="title">Device Billing Report</h3>

					<div className="summary">
						The billing information for one or more devices over a specified time period.
					</div>
				</div>

				<div className="actions">
					<Button text="Create" variant="contained" color="primary"  href="/app/reports/create/billing"/>
				</div>
			</div>

			<div className="content-box report-type">
				<div className="info">
					<h3 className="title">Asset Log History Report</h3>

					<div className="summary">
						The asset log history for one or more assets over a specified time period.
					</div>
				</div>

				<div className="actions">
					<Button text="Create" variant="contained" color="primary"  href="/app/reports/create/asset-log-history"/>
				</div>
			</div>
		</>}
	</FixedWidthPage>;
};
