import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryDevicesList($includeBillingType: Boolean!, $includeDealer: Boolean!, $includeClient: Boolean!, $includeNetwork: Boolean!) {
		devices {
			id
			name
			activationState
			billingType @include(if: $includeBillingType)
			serialNumber
			deviceType
			dealer @include(if: $includeDealer) {
				id
				name
			}
			client @include(if: $includeClient) {
				name
			}
			asset {
				id
				name
			}
			... on ATrackDevice {
				imei
				atrackModelType: modelType
				phoneNumber
			}
			... on CamperVanDevice {
				imei
			}
			... on DigitalMatterDevice {
				imei
				phoneNumber
				serialNumber
			}
			... on EroadDevice {
				vehiclePlate
			}
			... on HyteraRadioDevice {
				radioId
				network @include(if: $includeNetwork) {
					id
					name
				}
			}
			... on MobilePhoneDevice {
				chatterPttUserId
				network @include(if: $includeNetwork) {
					id
					name
				}
			}
			... on TaitRadioDevice {
				radioId
				network @include(if: $includeNetwork) {
					id
					name
				}
			}
			... on TeltonikaDevice {
				imei
				teltonikaModelType: modelType
				phoneNumber
			}
		}
	}
`;
