import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';
import { GatewayOverviewComponent, GatewayOverviewProps } from './gatewayOverviewComponent';

import {
	GatewayService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const GatewayOverview = observer((props: Props) => {
	const _gatewayService = useInjection<GatewayService>(Service.Gateway);

	const load = async (gatewayId: string): Promise<GatewayOverviewProps | null> => {
		const apiKeyDto = await _gatewayService.getGatewayApiKey(gatewayId);
		const gateway = await _gatewayService.getGateway(gatewayId);

		if (!apiKeyDto || !gateway)
			return null;

		return {
			gateway: gateway,
			apiKey: apiKeyDto.key,
		};
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(props: GatewayOverviewProps) => <GatewayOverviewComponent {...props} />}
	/>;
});
