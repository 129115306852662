import { IReportFormat } from './IReportFormat';

export class GeofenceReportFormat implements IReportFormat {
	HasAssetSelection = true;
	HasGeofenceSelection = true;
	HasMinimumTravelSpeedAndStopTime = false;
	HasSpeedLimit = false;
	HasBillingCodes = false;
	HasUserSelection = false;
	HasAddressBookEntrySelection = false;
	ReportDefinitionHelperText = 'Geofences visited by one or more assets during a specified time period.';
}
