import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';

import { ManageUserGroup } from './manageUserGroup';
import { EditPermissionsComponent } from 'src/app/permissions/editPermissionsComponent';

import {
	Client as C,
	UserGroupService,
	PermissionManagementService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

interface IPageData {
	userGroup: C.IUserGroupDto;
	permissions: C.IManagePermissionsCurrent;
}

export const EditUserGroupPermissions = observer((props: Props) => {
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);
	const _permissionManagementService = useInjection<PermissionManagementService>(Service.PermissionManagement);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const load = async (userGroupId: string): Promise<IPageData | null> => {
		const userGroup = await _userGroupService.getUserGroup(userGroupId);
		if (!userGroup)
			return null;

		const permissions = await _permissionManagementService.fetchUserGroupPermissions(userGroup.userGroupId);
		if (!permissions)
			return null;

		return {
			userGroup,
			permissions,
		};
	};

	const onSubmit = async (request: C.IManagePermissionsUpdateRequest) => {
		try {
			const userGroupId = props.match.params.id;
			await _permissionManagementService.updateUserGroupPermissions(userGroupId, request);
		} catch (err) {
			_toasterService.handleWithToast(err);
		}
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(pageData: IPageData) => <ManageUserGroup
			userGroup={pageData.userGroup}
		>
			<EditPermissionsComponent
				userGroup={pageData.userGroup}
				permissions={pageData.permissions}
				onSubmit={onSubmit}
			/>
		</ManageUserGroup>}
	/>;
});
