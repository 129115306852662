import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetServiceReminderTypes($includeClients: Boolean!) {
		assetServiceReminderTypes {
			id
			name
			client @include(if: $includeClients) {
				id
				name
			}
			reminders {
				id
				asset {
					id
					name
				}
				state
				nextDateTriggerDate
				nextOdometerTrigger
				nextEngineTimeTrigger
				extraInformation
			}
			dateEnabled
			dateWhenToRemindInAdvance
			odometerEnabled
			odometerWhenToRemindInAdvance
			engineTimeEnabled
			engineTimeWhenToRemindInAdvance
			message
		}
	}
`;
