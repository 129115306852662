import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationRebootGateway($input: RebootGatewayInput!) {
		rebootGateway(input: $input) {
			gateway {
				id
			}
		}
	}
`;
