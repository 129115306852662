import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import mapboxgl from 'mapbox-gl';
import { configure } from 'mobx';

import { mapboxAccessToken } from './config';
import Router from './router';

import 'normalize.css/normalize.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.scss';

if (mapboxAccessToken)
	mapboxgl.accessToken = mapboxAccessToken;

configure({ enforceActions: 'never' });

ReactDOM.render(
	<AppContainer>
		<Router />
	</AppContainer>,
	document.getElementById('app') as HTMLElement
);

interface RequireImport {
	default: any;
}

if (module.hot) {
	module.hot.accept('./router', () => {
		const NextRouter = require<RequireImport>('./router').default;
		ReactDOM.render(
			<AppContainer>
				<NextRouter />
			</AppContainer>,
			document.getElementById('app')
		);
	});
}
