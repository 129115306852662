import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import { IOption } from 'src/util';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField, FormikSelect, LoadingMessagePage, ErrorMessagePage } from 'src/components';
import { dealerOptions, superUserOptions } from './networkTypeSettings';
import { runFormValidation } from 'src/util';

import { useQueryDealersForManageSitesPage } from 'src/graphql/__generated__/queries/queryDealersForManageSitesPage';

import {
	Client as C,
	AuthenticationService,
	HistoryService,
	SiteService,
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

interface ManageSiteFormValues {
	name: string;
	networkType: string;
	selectedDealersIds?: string[] | null;
	selectedDealerId?: string | null;
	enableFleetConfigurationId? : string | null;
}

export interface Props {
	site?: C.ISiteDto | null;
	enableFleetConfigurations?: C.IEnableFleetConfigurationDto[] | null;
}

export const ManageSiteComponent = observer((props: Props) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _siteService = useInjection<SiteService>(Service.Site);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [networkTypes, setNetworkTypes] = useState<IOption<string>[]>(() => {
		if (_authenticationService.currentAuth.user.identity.type == C.IdentityType.Dealer)
			return dealerOptions;

		if (_authenticationService.currentAuth.user.identity.type == C.IdentityType.SuperUser)
			return superUserOptions;

		return [];
	});

	const initialFormValues: ManageSiteFormValues = {
		name: props.site?.name ?? '',
		networkType: props.site?.networkType?.toString() ?? '',
		selectedDealerId: props.site?.dealerIds != null && props.site.dealerIds.length == 1 ? props.site.dealerIds[0] : '',
		selectedDealersIds: props.site?.dealerIds,
		enableFleetConfigurationId: props.site?.enableFleetConfigurationId ?? '',
	};

	const dealersListQuery = useQueryDealersForManageSitesPage({
		skip: _authenticationService.currentAuth.user.identity.type !== C.IdentityType.SuperUser,
	});

	if (dealersListQuery.loading)
		return <LoadingMessagePage />;

	if (dealersListQuery.error || (!dealersListQuery.data && _authenticationService.currentAuth.user.identity.type === C.IdentityType.SuperUser))
		return <ErrorMessagePage />;

	const getSelectedDealers = (values: ManageSiteFormValues) : string[] | null => {
		let selectedDealers: string[] | null = [];

		if (_authenticationService.currentAuth.user.identity.type === C.IdentityType.Dealer) {
			selectedDealers.push(_authenticationService.currentAuth.user.identity.dealerId!);
			return selectedDealers;
		}

		if (values.networkType === C.NetworkType.TaitTier3 || values.networkType === C.NetworkType.ChatterPtt) {
			if (values.selectedDealersIds != null)
				selectedDealers = values.selectedDealersIds;
			else
				selectedDealers = null;
		} else {
			if (values.selectedDealerId != null)
				selectedDealers.push(values.selectedDealerId);
			else
				selectedDealers = null;
		}

		return selectedDealers;
	};

	const addSite = async (values: ManageSiteFormValues): Promise<boolean> => {
		const selectedDealers = getSelectedDealers(values);
		const networkType = values.networkType as C.NetworkType;

		if (selectedDealers == null && networkType != C.NetworkType.TaitTier3)
			return false;

		const request: C.IAddSiteRequest = {
			name: values.name,
			networkType: networkType,
			dealerIds: selectedDealers == null ? [] : selectedDealers,
			enableFleetConfigurationId: networkType === C.NetworkType.TaitTier3 && values.enableFleetConfigurationId || null,
		};

		try {
			await _siteService.addSite(request);
			_toasterService.showSuccess('Site added.');
			_historyService.history.push('/app/sites/list');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to add Site.');
			return false;
		}
	};

	const updateSite = async (values: ManageSiteFormValues): Promise<boolean> => {
		const selectedDealers = getSelectedDealers(values);

		const request: C.IUpdateSiteRequest = {
			name: values.name,
			dealerIds: selectedDealers == null ? [] : selectedDealers,
			enableFleetConfigurationId: values.enableFleetConfigurationId || null,
		};

		try {
			await _siteService.updateSite(props.site!.siteId, request);
			_toasterService.showSuccess('Site updated.');
			_historyService.history.push('/app/sites/list');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update Site.');
			return false;
		}
	};

	const onSubmit = async (values: ManageSiteFormValues, { setSubmitting }: FormikHelpers<ManageSiteFormValues>) => {
		let success;
		if (props.site)
			success = await updateSite(values);
		else
			success = await addSite(values);

		if (!success)
			setSubmitting(false);
	};

	const renderDealerSelector = (formikProps: FormikProps<ManageSiteFormValues>) => {
		if (!dealersListQuery.data?.dealers || dealersListQuery.data.dealers.length == 0 ||
			_authenticationService.currentAuth.user.identity.type !== C.IdentityType.SuperUser)
			return null;

		const canHaveMultipleDealers = formikProps.values.networkType === C.NetworkType.TaitTier3 || formikProps.values.networkType === C.NetworkType.ChatterPtt;

		return <FormikSelect
			name={canHaveMultipleDealers ? 'selectedDealersIds' : 'selectedDealerId'}
			label={canHaveMultipleDealers ? 'Dealer(s)' : 'Dealer'}
			placeholder={canHaveMultipleDealers ? 'Select dealers...' : 'Select a dealer...' }
			options={dealersListQuery.data.dealers}
			clearable={false}
			multi={canHaveMultipleDealers}
			form={formikProps}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.id}
		/>;
	};

	const renderEnableFleetConfigurationSelector = (formikProps: FormikProps<ManageSiteFormValues>) => {
		if (!props.enableFleetConfigurations || formikProps.values.networkType !== C.NetworkType.TaitTier3)
			return null;

		return <FormikSelect
			name="enableFleetConfigurationId"
			label="EnableFleet Configuration"
			placeholder="Select an EnableFleet configuration..."
			options={props.enableFleetConfigurations}
			clearable={true}
			form={formikProps}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.enableFleetConfigurationId}
		/>;
	};
	
	const validateForm = (values: ManageSiteFormValues, errors: FormikErrors<ManageSiteFormValues>) => {
		if (!values.name)
			errors.name = 'Name is required.';

		if (!values.networkType)
			errors.networkType = 'Network type is required.';

		if (!values.selectedDealerId && _authenticationService.currentAuth.user.identity.type !== C.IdentityType.Dealer &&
			values.networkType !== C.NetworkType.ChatterPtt && values.networkType !== C.NetworkType.TaitTier3) {
			errors.selectedDealerId = 'You must select a dealer.';
		}
	};

	const addingNewSite = !props.site;

	return <FixedWidthPage
		className="form-page"
		headingText={addingNewSite ? 'Add Site' : 'Edit Site'}
		subheadingText={addingNewSite ? null : props.site!.name}
		pageItemId={props.site?.siteId}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageSiteFormValues>) => <Form className="formik-form">
				<Field
					name="name"
					label="Name"
					type="text"
					component={FormikTextField}
					required
				/>

				<FormikSelect
					name="networkType"
					label="Network Type"
					placeholder="Select a network type..."
					options={networkTypes}
					clearable={false}
					disabled={!!props.site}
					form={formikProps}
					getOptionLabel={option => option.label}
					getOptionValue={option => option.value}
					required
				/>

				{renderEnableFleetConfigurationSelector(formikProps)}
				{renderDealerSelector(formikProps)}

				<Button
					type="submit" variant="contained" color="primary"
					loading={formikProps.isSubmitting}
					startIcon={addingNewSite ? null : <SaveIcon />}
					text={addingNewSite ? 'Add Site' : 'Save Changes'}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
