import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ManageAddressBooks } from './manageAddressBooks';
import { ThingLoader } from 'src/components';

import {
	AddressBookService,
	Client as C,
	Service,
	ToasterService,
	HistoryService,
	useInjection,
} from 'src/services';

export interface EditAddressBookProps {
	match: match<{ id: string }>;
}

interface IPageData {
	addressBook: C.IAddressBookDto;
}

export const EditAddressBooks = observer((props: EditAddressBookProps) => {
	const addressBookService = useInjection<AddressBookService>(Service.AddressBooks);
	const toasterService = useInjection<ToasterService>(Service.Toaster);
	const historyService = useInjection<HistoryService>(Service.History);

	async function load(): Promise<IPageData | null> {
		try {
			return {
				addressBook: await addressBookService.getAddressBookById(props.match.params.id),
			};
		} catch (err) {
			toasterService.handleWithToast(err, 'Unable to load the selected address book.');
			historyService.history.push('/app/address-books/list');
		}

		return null;
	}

	return <ThingLoader
		load={load}
		render={(pageData: IPageData) =>
			<ManageAddressBooks
				addressBook={pageData.addressBook}
			/>
	}/>;
});
