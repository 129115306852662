import moment from 'moment-timezone';

export function calculateAndFormatLongDuration(startTime: moment.Moment, endTime: moment.Moment) {
	const duration = moment.duration(endTime.diff(startTime));
	return formatLongDuration(duration);
}

export function formatLongDuration(duration: moment.Duration) {
	const hours = duration.hours().toString();

	let durationString = '';

	if (duration.days() >= 1 && duration.days() < 2)
		durationString += '1 day, ';
	else if (duration.days() > 1)
		durationString += `${duration.days().toFixed(0)} days, `;

	const hoursString = hours.toString();

	durationString += hoursString.length < 2 ? `0${hoursString}` : hoursString;
	durationString += `:${formatShortDuration(duration)}`;

	return durationString;
}

export function calculateAndFormatShortDuration(startTime: moment.Moment, endTime: moment.Moment) {
	const duration = moment.duration(endTime.diff(startTime));
	return formatShortDuration(duration);
}

export function formatShortDuration(duration: moment.Duration) {
	const mins = duration.minutes().toString();
	const seconds = duration.seconds().toString();

	let durationString = '';

	durationString += mins.length < 2 ? `0${mins}` : `${mins}`;
	durationString += seconds.length < 2 ? `:0${seconds}` : `:${seconds}`;

	return durationString;
}
