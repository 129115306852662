import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { Formik, FormikProps, Form, Field, FormikHelpers, FieldArray, ArrayHelpers, FormikErrors } from 'formik';
import useAsyncEffect from 'use-async-effect';

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';

import { FixedWidthPage, Button, FormikCheckbox, FormikTextField, FormikSelect, ErrorMessagePage, LoadingMessagePage } from 'src/components';

import { useQueryCallGroupsByNetworkId } from 'src/graphql/__generated__/queries/queryCallGroupsByNetworkId';

import {
	AuthenticationService,
	CallGroupsService,
	Client as C,
	HistoryService,
	PermissionsService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import './callGroupsList.scss';

interface ICallGroup {
	name: string;
	callGroupId: string | undefined;
	networkCallGroupId: string;
	recordCalls: boolean;
	clientId: string;
}

interface ICallGroupsFormValues {
	callGroups: ICallGroup[];
}

export interface Props {
	match: match<{ id: string }>;
}

export const CallGroupsList = observer((props: Props) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _callGroupsService = useInjection<CallGroupsService>(Service.CallGroup);
	const _permissionsService = useInjection<PermissionsService>(Service.Permissions);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [clientOptions, setClientOptions] = useState<C.IClientDto[]>([]);

	const siteId = props.match.params.id;

	const query = useQueryCallGroupsByNetworkId({
		variables: {
			networkId: siteId,
		},
	});

	const site = query.data?.networkById;

	useAsyncEffect(async () => {
		if (!query.data?.callGroupsByNetworkId)
			return;

		setLoading(true);

		try {
			const addPermissions = await _permissionsService.fetchUserAddPermissions(_authenticationService.currentAuth.user.identityId);

			let clients: C.IClientDto[] = [];
			if (addPermissions.clients)
				clients = addPermissions.clients.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

			setClientOptions([
				{ clientId: 'none', name: 'None' },
				...clients,
			]);
		} catch (e) {
			_toasterService.handleWithToast(e, 'Unable to load call groups.');
			setError(true);
		}

		setLoading(false);
	}, [query.data]);

	const initialFormValues: ICallGroupsFormValues = useMemo(() => ({
		callGroups: (query.data?.callGroupsByNetworkId || [])
			.map(x => ({
				name: x.name,
				callGroupId: x.id,
				networkCallGroupId: x.networkCallGroupId || '',
				recordCalls: x.recordCalls,
				clientId: x.client.id || 'none',
			})),
	}), [query.data]);

	if (query.loading || loading)
		return <LoadingMessagePage />;

	if (query.error || !site || error)
		return <ErrorMessagePage />;

	const addNewCallGroupRow = (arrayHelpers: ArrayHelpers) => {
		const newCallGroup: ICallGroup = {
			name: '',
			callGroupId: undefined,
			networkCallGroupId: '',
			clientId: 'none',
			recordCalls: false,
		};

		arrayHelpers.push(newCallGroup);
	};

	const removeCallGroup = (arrayHelpers: ArrayHelpers, index: number) => {
		arrayHelpers.remove(index);
	};

	const handleTogglingAllCallGroups = (formikProps: FormikProps<ICallGroupsFormValues>, deselect: boolean) => {
		const callGroups = formikProps.values.callGroups;

		for (const callGroup of callGroups)
			callGroup.recordCalls = !deselect;

		const callGroup: ICallGroupsFormValues = {
			callGroups: callGroups,
		};

		formikProps.setValues(callGroup);
	};

	const onSubmit = async (values: ICallGroupsFormValues, { setSubmitting }: FormikHelpers<ICallGroupsFormValues>) => {
		const callGroups = values.callGroups;

		const requestGroups: C.IUpdateCallGroupsRequestGroup[] = callGroups.map(x => ({
			name: x.name!,
			networkCallGroupId: x.networkCallGroupId!,
			recordCalls: x.recordCalls,
			clientId: x.clientId,
		}));

		const updateRequest: C.IUpdateCallGroupsRequest = {
			siteId: siteId,
			callGroups: requestGroups,
		};

		try {
			await _callGroupsService.updateCallGroupsBySiteId(updateRequest);
			_toasterService.showSuccess('Changes saved successfully');
			_historyService.history.push(`/app/sites/list`);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to save changes.');
		}

		setSubmitting(false);
	};

	const renderCallGroupInformation = (arrayHelpers: ArrayHelpers, formikProps: FormikProps<ICallGroupsFormValues>) => {
		const canAddCallGroups = _authenticationService.currentAuth.permissions.general.manageCallGroups;

		return <table className="card-table call-groups-table">
			<thead>
				<tr>
					<th>Network ID</th>
					<th>Name</th>
					<th>Client</th>
					<th className="record-calls-checkbox-column">
						Record Calls
						<div>
							<Button
								text="All"
								variant="outlined"
								size="small"
								onClick={() => handleTogglingAllCallGroups(formikProps, false)}
							/>
							&nbsp;
							<Button
								text="None"
								variant="outlined"
								size="small"
								onClick={() => handleTogglingAllCallGroups(formikProps, true)}
							/>
						</div>
					</th>
					<th></th>
				</tr>
			</thead>

			<tbody>
				{formikProps.values.callGroups.length === 0 && <tr
					key="no-call-groups"
					className="content-box"
				>
					<td
						colSpan={5}
						style={{ textAlign: 'center' }}
					>
						None
					</td>
				</tr>}

				{formikProps.values.callGroups.map((callGroup, index) => <tr
					key={index}
					className="content-box"
				>
					<td>
						<Field
							className="input-field"
							component={FormikTextField}
							name={`callGroups[${index}].networkCallGroupId`}
							disabled={!!callGroup.callGroupId}
						/>
					</td>

					<td>
						<Field
							className="input-field"
							component={FormikTextField}
							name={`callGroups[${index}].name`}
						/>
					</td>

					<td className="client-select-column">
						<FormikSelect
							className="input-field"
							name={`callGroups[${index}].clientId`}
							options={clientOptions}
							clearable={false}
							isLoading={loading}
							form={formikProps}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.clientId}
						/>
					</td>

					<td className="record-calls-checkbox-column">
						<Field
							name={`callGroups[${index}].recordCalls`}
							type="checkbox"
							component={FormikCheckbox}
						/>
					</td>

					<td className="call-group-remove-column">
						<IconButton
							className="icon-button-small"
							title="Remove Call Group"
							onClick={() => removeCallGroup(arrayHelpers, index)}
						>
							<ClearIcon />
						</IconButton>
					</td>
				</tr>)}

				{canAddCallGroups && <tr
					key="add-call-group"
				>
					<td
						colSpan={5}
						style={{ textAlign: 'right' }}
					>
						<Button
							onClick={() => addNewCallGroupRow(arrayHelpers)}
							text="Add"
							startIcon={<AddIcon />}
							variant="outlined"
							color="primary"
						/>
					</td>
				</tr>}
			</tbody>
		</table>;
	};

	const validate = (values: ICallGroupsFormValues) => {
		const errors: FormikErrors<ICallGroup>[] = [];
		let errorAdded = false;
		for (const callGroup of values.callGroups) {
			const callGroupErrors:  FormikErrors<ICallGroup> = {};

			if (!callGroup.networkCallGroupId)
				callGroupErrors.networkCallGroupId = 'Network ID is required.';

			if (!callGroup.name)
				callGroupErrors.name = 'Name is required.';

			if (!callGroup.clientId)
				callGroupErrors.clientId = 'Client is required.';

			// Only validate new call groups.
			if (!callGroup.callGroupId) {
				// Make sure there is no other call group new or old with the same network call group ID.
				if (values.callGroups.some(x => x != callGroup && x.networkCallGroupId === callGroup.networkCallGroupId))
					callGroupErrors.networkCallGroupId = 'Network ID must be unique on the site.';
			}

			errors.push(callGroupErrors);

			errorAdded = !!callGroupErrors.networkCallGroupId || !!callGroupErrors.name || !!callGroupErrors.clientId;
		}

		return errorAdded ? { callGroups: errors } : undefined;
	};

	return <Formik
		initialValues={initialFormValues}
		validate={validate}
		validateOnChange={true}
		onSubmit={onSubmit}
		render={(formikProps: FormikProps<ICallGroupsFormValues>) => <Form className="call-groups-form">
			<FixedWidthPage
				headingText="Call Groups"
				subheadingText={site.name}
				headingActions={<Button
					className="submit-button"
					type="submit"
					color="primary"
					loading={formikProps.isSubmitting}
					startIcon={<SaveIcon />}
					text="Save all Changes"
					variant="contained"
				/>}
				noContentBackground
			>
				<FieldArray
					name="callGroups"
					render={arrayHelpers => <>
						{renderCallGroupInformation(arrayHelpers, formikProps)}
					</>}
				/>
			</FixedWidthPage>
		</Form>}
	/>;
});
