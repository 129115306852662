import { Client as C } from 'src/services';
import { DeviceBillingType } from 'src/../__generated__/globalTypes';
import { getPrettyName } from 'src/util';

const possibleBillingTypeOptions = [
	DeviceBillingType.TRIAL,
	DeviceBillingType.STANDARD,
	DeviceBillingType.CONFIGURATION_ONLY,
	DeviceBillingType.DEMO,
	DeviceBillingType.DEVELOPMENT,
];

const shouldAddBillingTypeOption = (identity: C.IIdentityDto, billingType: DeviceBillingType, deviceCurrentBillingType: DeviceBillingType | null) => {
	// Always give an option for the device's current billing type.
	if (billingType === deviceCurrentBillingType)
		return true;

	switch (billingType) {
		case DeviceBillingType.DEMO:
		case DeviceBillingType.DEVELOPMENT:
			// Only superusers can set devices to demo/development.
			return identity.type === C.IdentityType.SuperUser;

		case DeviceBillingType.TRIAL:
			if (identity.type === C.IdentityType.Dealer) {
				// Configuration only dealers can't set billing type to trial.
				if (identity.dealer!.type === C.DealerType.ConfigurationOnly)
					return false;

				if (identity.dealer!.maxTrialMonths === 0)
					return false;
			}

			// Can only set billing type to trial when creating a new device.
			return deviceCurrentBillingType === null;

		case DeviceBillingType.STANDARD:
			// Configuration only dealers can't use standard billing type.
			return identity.dealer?.type !== C.DealerType.ConfigurationOnly;

		case DeviceBillingType.CONFIGURATION_ONLY:
			return identity.type === C.IdentityType.SuperUser || identity.dealer?.type === C.DealerType.ConfigurationOnly;
	}

	return false;
};

export const getBillingTypeOptions = (identity: C.IIdentityDto, deviceCurrentBillingType: DeviceBillingType | null) => {
	return possibleBillingTypeOptions
	.filter(option => shouldAddBillingTypeOption(identity, option, deviceCurrentBillingType))
	.map(x => ({ value: x, label: getPrettyName(x) }));
};
