import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';

import { ManageAssetGroupComponent } from './manageAssetGroupComponent';

import {
	Client as C,
	AssetGroupService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditAssetGroup = observer((props: Props) => {
	const _assetGroupService = useInjection<AssetGroupService>(Service.AssetGroup);

	const load = async (assetGroupId: string) => {
		return await _assetGroupService.getAssetGroup(assetGroupId);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(assetGroup: C.IAssetGroupDto) => <ManageAssetGroupComponent
			assetGroup={assetGroup}
		/>}
	/>;
});
