import React from 'react';
import { FormikProps, getIn } from 'formik';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import './formik.scss';

export interface FormikTimePickerProps<V> {
	name: string;
	label: string;
	form: FormikProps<V>;
}

export function FormikTimePicker<V>(props: FormikTimePickerProps<V> & TextFieldProps) {
	const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		props.form.setFieldValue(props.name, event.target.value);

		if (props.onChange)
			props.onChange(event);
	};

	const {
		name,
		form: { touched, errors, values },
		label,
		...otherProps
	} = props;

	const fieldError = getIn(errors, name);
	const fieldTouched = getIn(touched, name);
	const value = getIn(values, name);

	return <div>
		<TextField
			name={name}
			id="time"
			label={props.label || 'Time'}
			type="time"
			{...otherProps}
			defaultValue={value}
			onChange={onChange}
			required
			inputProps={{
				step: 60, // 1 min
			}}
		/>

		{fieldTouched && fieldError && <div className="error">
			<div className="triangle"></div>
			<div className="contents">{fieldError}</div>
		</div>}
	</div>;
}
