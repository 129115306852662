import { injectable } from 'inversify';

import * as C from './client';

const negativeLevelRegexp = /^b(\d+)$/i;
const positiveLevelRegexp = /^\d+$/;

@injectable()
export class FloorLevelConverter {
	toIndex(level: string, numberingStyle: C.FloorNumberingStyle): number | null {
		let index: number | null = null;

		const negativeMatches = negativeLevelRegexp.exec(level);
		if (negativeMatches) {
			index = parseInt(negativeMatches[1]) * -1;
		} else if (positiveLevelRegexp.test(level)) {
			index = parseInt(level);
			if (numberingStyle == C.FloorNumberingStyle.American) {
				index--;
			}
		} else if (level.toUpperCase() == 'G') {
			index = 0;
		}

		return index;
	}

	fromIndex(index: number, numberingStyle: C.FloorNumberingStyle): string {
		let level: string;

		if (index < 0) {
			level = `B${index * -1}`;
		} else if (index == 0) {
			level = 'G';
		} else {
			if (numberingStyle == C.FloorNumberingStyle.American) {
				level = (index + 1).toString();
			} else if (numberingStyle == C.FloorNumberingStyle.British) {
				level = index.toString();
			} else throw Error(`Unsupported numbering style ${numberingStyle}`);
		}

		return level;
	}
}
