import React from 'react';
import { observer } from 'mobx-react';
import { Link, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import styled from '@emotion/styled';

import { Activate } from './activate';
import { PrivacyPolicy } from './privacyPolicy';
import { ResetPassword } from './resetPassword';
import { ResetPasswordConfirm } from './resetPasswordConfirm';
import { SignIn } from './signIn';

import {
	BrandingService,
	Service,
	useInjection
} from 'src/services';

import './index.scss';

const PrivacyPolicyContainer = styled.div`
	margin-top: 10px;
	font-size: 12px;
	text-align: center;

	a {
		text-decoration: none;
	}
`;

export const Auth = observer(() => {
	const brandingService = useInjection<BrandingService>(Service.Branding);

	return <div className="auth-wrapper flex-fill">
		<div className="auth-content">
			<div className="content-box" style={{ padding: '45px' }}>
				<div className="logo">
					<img src={brandingService.getBranding().authImage} />
				</div>

				<Switch>
					<Route exact path="/auth/activate" component={Activate} />
					<Route exact path="/auth/sign-in" component={SignIn} />
					<Route exact path="/auth/reset-password" component={ResetPassword} />
					<Route exact path="/auth/reset-password/confirm" component={ResetPasswordConfirm} />
					{brandingService.getBranding().hasPrivacyPolicy && <Route exact path="/privacy-policy" component={PrivacyPolicy} />}
					<Route component={() => <Redirect to="/auth/sign-in" />} />
				</Switch>
			</div>

			{brandingService.getBranding().hasPrivacyPolicy && <PrivacyPolicyContainer>
				<Link to={`/privacy-policy`}>Privacy Policy</Link>
			</PrivacyPolicyContainer>}
		</div>
	</div>;
});
