import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryMapAssets($includeClients: Boolean!) {
		assets {
			id
			name
			emergencyNotes
			client @include(if: $includeClients) {
				id
			}
			assetType {
				id
				name
			}
			devices {
				id
				deviceType
				activationState
				billingType
				... on HyteraRadioDevice {
					network {
						id
					}
				}
				... on TaitRadioDevice {
					manualPollingEnabled
					network {
						id
					}
				}
			}
		}
	}
`;
