import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateBeacon($input: UpdateBluetoothBeaconInput!) {
		updateBluetoothBeacon(input: $input) {
			bluetoothBeacon {
				id
				major
				minor
			}
		}
	}
`;
