import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetsForVehicleInformationList {
		assets {
			id
			name
			assetTelematicsCurrentValueAndReadings {
				type
				value
			}
			serviceReminders {
				id
				state
			}
		}
	}
`;
