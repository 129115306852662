const measurementsSystemsOptions: Array<{ value: string, label: string }> = [];

measurementsSystemsOptions.push({
	label: 'Imperial (miles)',
	value: 'imperial',
});

measurementsSystemsOptions.push({
	label: 'Metric (kilometers)',
	value: 'metric',
});

export {
	measurementsSystemsOptions,
};
