import React, { useState } from 'react';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Formik, FormikHelpers, FormikProps, Form, Field, FormikErrors } from 'formik';

import {
	HistoryService,
	Service,
	ToasterService,
	useInjection,
	UsersService,
} from 'src/services';

import { Button, FormikTextField } from 'src/components';
import { runFormValidation } from 'src/util';

interface ResetPasswordFormValues {
	emailAddress: string;
}

const validateForm = (values: ResetPasswordFormValues, errors: FormikErrors<ResetPasswordFormValues>) => {
	if (!values.emailAddress)
		errors.emailAddress = 'Email address is required.';
};

export const ResetPassword = observer(() => {
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _usersService = useInjection<UsersService>(Service.Users);

	const [sentEmail, setSentEmail] = useState<boolean>(false);

	const query = queryString.parse(_historyService.history.location.search);

	const onSubmit = async (values: ResetPasswordFormValues, { setSubmitting }: FormikHelpers<ResetPasswordFormValues>) => {
		try {
			await _usersService.resetPassword({
				emailAddress: values.emailAddress,
			});

			setSentEmail(true);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to send password reset email.');
			setSubmitting(false);
		}
	};

	return <div>
		<h1>Reset password</h1>

		{!sentEmail && <>
			<p>Enter your email address below to be sent an email with instructions on resetting your password.</p>

			<Formik
				initialValues={{
					emailAddress: query.emailAddress as string || '',
				}}
				validate={values => runFormValidation(values, validateForm)}
				validateOnChange={false}
				onSubmit={onSubmit}
				render={(formikProps: FormikProps<ResetPasswordFormValues>) => <Form className="formik-form">
					<Field
						name="emailAddress"
						label="Email Address"
						type="email"
						component={FormikTextField}
					/>

					<div className="auth-action">
						<Button
							text="Cancel"
							loading={formikProps.isSubmitting}
							href="/auth/sign-in"
						/>

						<Button
							type="submit" variant="contained" color="primary"
							text="Reset password"
							loading={formikProps.isSubmitting}
						/>
					</div>
				</Form>}
			/>
		</>}

		{sentEmail && <>
			<p>An email has been sent to your email address with instructions on resetting your password.</p>

			<div className="auth-action">
				<Button
					href="/auth/sign-in"
					text="Return to sign in"
				/>
			</div>
		</>}
	</div>;
});
