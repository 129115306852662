import React, { useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions, DialogTitle, IconButton } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckIcon from '@material-ui/icons/Check';

import {
	Client as C,
	FloorPlansService,
	Service,
	useInjection,
} from 'src/services';

import { Button, MessagePage } from 'src/components';

import './selectFloorPlanDialog.scss';

interface Props {
	setFloorPlan: (floorPlan: C.IFloorPlanDto) => void;
	selectFloorPlanGroup: (floorPlanGroup: C.IFloorPlanGroupDto) => void;
	close: () => void;
}

export const SelectFloorPlanDialog = observer((props: Props) => {
	const _floorPlanService = useInjection<FloorPlansService>(Service.FloorPlans);

	const [loading, setLoading] = useState<boolean>(false);
	const [group, setGroup] = useState<C.IFloorPlanGroupDto | null>(null);
	const [floorPlan, setFloorPlan] = useState<C.IFloorPlanDto | null>(null);

	const goUp = () => {
		if (!group)
			return;

		const parentGroupId = group.parentGroupId;
		const parentGroup = parentGroupId ? Array.from(_floorPlanService.floorPlanGroups.values()).find(x => x.floorPlanGroupId === parentGroupId) : undefined;

		setGroup(parentGroup || null);
		setFloorPlan(null);
	};

	const renderFloorPlanNavigation = (group?: C.IFloorPlanGroupDto) => {
		return <div className="select-floor-plan-dialog__item-header">
			<IconButton
				className="icon-button-small"
				onClick={goUp}
				disabled={!group}
			>
				<ArrowBackIcon />
			</IconButton>
			<div>{group && group.name || 'Collections'}</div>
		</div>;
	};

	const selectCollection = (collectionId: string) => {
		setLoading(true);

		const topLevelGroup = Array.from(_floorPlanService.collections.values())
			.find(x => x.collectionId === collectionId);

		setGroup(topLevelGroup || null);
		setFloorPlan(null);
		setLoading(false);
	};

	const confirmSelection = () => {
		if (!floorPlan)
			return;

		props.setFloorPlan(floorPlan);
	};

	const renderItem = (key: string, name: string, more: boolean, selected: boolean, action: () => void) => {
		return <div
			key={key}
			className={classNames('select-floor-plan-dialog__item', { 'selected': selected })}
			onClick={action}
		>
			{name}

			{more && <ChevronRightIcon fontSize="small" />}
			{selected && <CheckIcon fontSize="small" />}
		</div>;
	};

	const renderContent = (): { content: React.ReactElement, action?: React.ReactElement } => {
		if (loading) {
			return { content: <MessagePage loading /> };
		}

		if (!group) {
			const collections = Array.from(_floorPlanService.collections.values())
				.sort((a, b) => a.name.localeCompare(b.name));

			return {
				content: <>
					{renderFloorPlanNavigation()}
					{collections.map((x) => renderItem(x.collectionId, x.name, true, false, () => selectCollection(x.collectionId)))}
				</>,
			};
		}

		const selectedGroupId = group && group.floorPlanGroupId;
		if (!selectedGroupId) {
			return {
				content: <MessagePage
					title="There was an error loading floor plans."
				/>,
			};
		}

		const floorPlans = Array.from(_floorPlanService.floorPlans.values())
			.filter(x => x.parentGroupId === selectedGroupId)
			.sort((a, b) => a.displayOrder - b.displayOrder || a.name.localeCompare(b.name));

		const groups = Array.from(_floorPlanService.floorPlanGroups.values())
			.filter(x => x.parentGroupId === selectedGroupId)
			.sort((a, b) => a.displayOrder - b.displayOrder || a.name.localeCompare(b.name));

		let action: React.ReactElement | undefined = undefined;
		if (group.imageUrl) {
			action = <Button
				color="primary"
				onClick={() => props.selectFloorPlanGroup(group!)}
				text="View Building"
				variant="contained"
				style={{ 'marginRight': 'auto' }}
			/>;
		}

		return {
			content: <>
				{renderFloorPlanNavigation(group)}
				{groups.map((x) => renderItem(x.floorPlanGroupId, x.name, true, false, () => setGroup(x)))}
				{floorPlans.map((x) => renderItem(x.floorPlanId, x.name, false, x === floorPlan, () => setFloorPlan(x)))}
			</>,
			action,
		};
	};

	const { content, action } = renderContent();

	return <Dialog
		className="select-floor-plan-dialog"
		fullScreen={window.innerWidth < 500}
		open={true}
		onClose={props.close}
	>
		<DialogTitle>
			Select Floor Plan
		</DialogTitle>

		<DialogContent
			className="select-floor-plan-dialog__content"
		>
			{content}
		</DialogContent>

		<DialogActions>
			{action}

			<Button
				color="primary"
				onClick={props.close}
				text="Cancel"
			/>

			<Button
				color="primary"
				disabled={!floorPlan}
				onClick={confirmSelection}
				text="Select"
			/>
		</DialogActions>
	</Dialog>;
});
