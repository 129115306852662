import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryCallById($id: UUID!) {
		callById(id: $id) {
			id
			startTimestamp
			endTimestamp
			type
			audioUrl
			network {
				id
				name
			}
			segments {
				id
				networkAssetId
				asset {
					id
					name
				}
				segmentStartTimestamp
				segmentEndTimestamp
			}
		}
	}
`;
