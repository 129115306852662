import React, { useState } from 'react';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { IsolatedRefreshingDisplay } from 'src/components/isolatedRefreshingDisplay';
import { Button, DurationPicker } from 'src/components';

import './safetyTimerStartUpdateDialog.scss';

import {
	Client as C,
} from 'src/services';

export interface SafetyTimerInfo {
	endTime: moment.Moment;
	details: string;
}

export interface Props {
	safetyTimer?: C.ISafetyTimerDto;
	onStart: (safetyTimerInfo: SafetyTimerInfo) => void;
	onExtend: (safetyTimerId: string, safetyTimerInfo: SafetyTimerInfo) => void;
	onClose?: () => void;
}

export const SafetyTimerStartUpdateDialog = observer((props: Props) => {
	const [periodHours, setPeriodHours] = useState<number>(0);
	const [periodMinutes, setPeriodMinutes] = useState<number>(0);
	const [details, setDetails] = useState<string>(props.safetyTimer?.details ?? '');

	const onTimeChange = (hours: number, minutes: number) => {
		setPeriodHours(hours);
		setPeriodMinutes(minutes);
	};

	const onSubmit = () => {
		const endTime = getEndDateTime();

		if (props.safetyTimer?.safetyTimerId) {
			props.onExtend(props.safetyTimer.safetyTimerId, {
				endTime: endTime,
				details: details,
			});
		} else {
			props.onStart({
				endTime: endTime,
				details: details,
			});
		}
	};

	const getEndDateTime = (): moment.Moment => {
		const end = props.safetyTimer?.endTimestamp.clone() || moment();

		return end
			.add(periodHours, 'hours')
			.add(periodMinutes, 'minutes');
	};

	const getSafetyTimerEndTime = (): JSX.Element => {
		return <div>{moment(getEndDateTime()).format('DD/MM/YYYY h:mm:ss A')}</div>;
	};

	const periodMoment = moment(new Date(2020, 8, 1, periodHours, periodMinutes));

	return <Dialog
		open
		onClose={props.onClose}
		className="map-safety-timer-dialog"
	>
		<DialogTitle>{props.safetyTimer ? 'Extend' : 'Start'} Safety Timer</DialogTitle>

		<DialogContent>
			<div>
				Enter {props.safetyTimer ? 'how long this safety timer should be extended by' : 'the expected duration of this safety timer'} in <strong>hours</strong> and <strong>minutes</strong>.
				An emergency will be generated if the safety timer reaches its end time without being extended/ended.
			</div>

			<DurationPicker
				hours={periodHours}
				minutes={periodMinutes}
				onChange={onTimeChange}
			/>

			<div className="lw-minor-heading">Ending:</div>
			<IsolatedRefreshingDisplay
				time={periodMoment}
				render={getSafetyTimerEndTime}
			/>

			<TextField
				className="lw-details"
				label="Details"
				multiline
				rows={4}
				variant="filled"
				value={details}
				onChange={e => setDetails(e.target.value)}
				fullWidth
			/>
		</DialogContent>

		<DialogActions>
			<Button
				text="Cancel"
				onClick={props.onClose}
				variant="text"
			/>

			<Button
				autoFocus
				text={props.safetyTimer ? 'Extend' : 'Start'}
				onClick={onSubmit}
				variant="text"
				color="primary"
				disabled={periodHours === 0 && periodMinutes === 0}
			/>
		</DialogActions>
	</Dialog>;
});
