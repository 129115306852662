import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAddAsset($includeDealers: Boolean!, $includeClients: Boolean!) {
		assetTypes {
			id
			name
			client @include(if: $includeClients) {
				id
			}
		}

		dealers @include(if: $includeDealers) {
			id
			name
		}

		clients @include(if: $includeClients) {
			id
			name
			dealer @include(if: $includeDealers) {
				id
			}
		}
	}
`;
