import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryEmergenciesForLiveMap($emergencyStatus: EmergencyStatus!) {
		emergencies(emergencyStatus: $emergencyStatus) {
			id
			generatedAt
			asset {
				id
			}
			deviceIoConfiguration {
				name
			}
			type
		}
	}
`;
