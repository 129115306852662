import { inject, injectable } from 'inversify';

import { defaultBranding } from 'src/config';

import {
	AuthenticationService,
	Client as C,
	Service,
} from 'src/services';

const brandlessAuthImage = require('./branding/brandless/auth.png');
const brandlessNavbarImage = require('./branding/brandless/navbar.png');
const logicWirelessAuthImage = require('./branding/logic/auth.png');
const logicWirelessNavbarImage = require('./branding/logic/navbar.png');
const torutekAuthImage = require('./branding/torutek/auth.png');
const torutekNavbarImage = require('./branding/torutek/navbar.png');
const vitalAuthImage = require('./branding/vital/auth.png');
const vitalNavbarImage = require('./branding/vital/navbar.png');

interface Branding {
	company: string;
	authImage: any;
	navbarImage: any;
	hasPrivacyPolicy?: boolean;
}

// Branding for companies.

const Brandless: Branding = {
	company: 'Brandless',
	authImage: brandlessAuthImage,
	navbarImage: brandlessNavbarImage,
};

export const LogicWirelessBranding: Branding = {
	company: 'Logic Wireless',
	authImage: logicWirelessAuthImage,
	navbarImage: logicWirelessNavbarImage,
	hasPrivacyPolicy: true,
};

export const TorutekBranding: Branding = {
	company: 'Torutek',
	authImage: torutekAuthImage,
	navbarImage: torutekNavbarImage,
	hasPrivacyPolicy: true,
};

export const VitalBranding: Branding = {
	company: 'Vital',
	authImage: vitalAuthImage,
	navbarImage: vitalNavbarImage,
	hasPrivacyPolicy: true,
};

function getDefaultBranding(): Branding {
	if (defaultBranding === LogicWirelessBranding.company)
		return LogicWirelessBranding;

	if (defaultBranding === VitalBranding.company)
		return VitalBranding;

	if (defaultBranding === TorutekBranding.company)
		return TorutekBranding;

	return Brandless;
}

@injectable()
export class BrandingService {
	constructor(@inject(Service.Authentication) private auth: AuthenticationService) {

	}

	private _defaultBranding: Branding = getDefaultBranding();

	getBranding() {
		const url = window.location.href;

		let branding = { ...this._defaultBranding };
		if (url.includes('logicwireless') || url.includes('logiclocate') || url.includes('logic-connect'))
			branding = { ...LogicWirelessBranding };

		if (url.includes('vital'))
			branding = { ...VitalBranding };

		if (this.auth?.currentAuth?.user?.identity?.dealer?.navbarBrandingImageUrl && this.auth.currentAuth.user.identity.type == C.IdentityType.Dealer)
			branding.navbarImage = this.auth.currentAuth.user.identity.dealer?.navbarBrandingImageUrl;
		else if (this.auth?.currentAuth?.user?.identity?.client?.navbarBrandingImageUrl && this.auth.currentAuth.user.identity.type == C.IdentityType.Client)
			branding.navbarImage = this.auth.currentAuth.user.identity.client?.navbarBrandingImageUrl;

		return branding;
	}
}
