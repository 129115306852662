import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';

import { AddressBookEntrySelector, Button, FixedWidthPage, FormikDateTimeRangePicker, DateTimeRange, FormikTextField, MessagePage } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	Client as C,
	AuthenticationService,
	HistoryService,
	ReportService,
	Service,
	ToasterService,
	useInjection,
	useAddressBookEntrySelectorService,
} from 'src/services';

interface CreateDriverReportFormValues {
	name: string;
	addressBookEntries: string[];
	dateTimeRange: DateTimeRange;
}

const validateForm = (values: CreateDriverReportFormValues, errors: FormikErrors<CreateDriverReportFormValues>) => {
	if (!values.name)
		errors.name = 'Report name is required.';

	if (values.addressBookEntries.length === 0)
		errors.addressBookEntries = 'At least one address book entry is required.';
}

export const CreateDriverReport = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _reportService = useInjection<ReportService>(Service.Report);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const _addressBookEntrySelectorService = useAddressBookEntrySelectorService();

	const onSubmit = async (values: CreateDriverReportFormValues, { setSubmitting }: FormikHelpers<CreateDriverReportFormValues>) => {
		try {
			const request: C.INewDriverReportRequest = {
				name: values.name,
				addressBookEntryIds: values.addressBookEntries,
				startTimestamp: values.dateTimeRange.start,
				endTimestamp: values.dateTimeRange.end,
			};

			await _reportService.createDriverReport(request);
			_historyService.history.push('/app/reports');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to create report.');
			setSubmitting(false);
		}
	};

	const now = moment.tz(_authService.currentAuth.user.timeZone);

	return <FixedWidthPage
		className="form-page"
		headingText="Create Driver Report"
		contentMessageReplace={_addressBookEntrySelectorService.loading && <MessagePage loading />}
	>
		<Formik
			initialValues={{
				name: '',
				addressBookEntries: [],
				dateTimeRange: { start: now.clone().subtract(1, 'day'), end: now },
			}}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<CreateDriverReportFormValues>) => <Form className="formik-form material">
				<Field
					name="name"
					label="Report Name"
					component={FormikTextField}
					required
				/>

				<AddressBookEntrySelector
					options={_addressBookEntrySelectorService.addressBookEntryOptions}
					formikProps={formikProps}
				/>

				<FormikDateTimeRangePicker
					name="dateTimeRange"
					label="Report Period"
					form={formikProps}
					fullWidth
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					text="Create"
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
