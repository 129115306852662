import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';
import { ToasterService } from './toasterService';

@injectable()
export class SiteService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.Toaster) private _toasterService: ToasterService,
	) {

	}

	async addSite(site: C.IAddSiteRequest): Promise<C.ISiteDto> {
		return await this.client.addSite(new C.AddSiteRequest(site));
	}

	async updateSite(siteId: string, site: C.IUpdateSiteRequest): Promise<C.ISiteDto> {
		return await this.client.updateSiteById(siteId, (new C.UpdateSiteRequest(site)));
	}

	async getSite(siteId: string): Promise<C.ISiteDto | null> {
		try {
			return await this.client.getSiteById(siteId);
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load site.');
		}

		return null;
	}

	async getAllSites(): Promise<C.ISiteDto[] | null> {
		try {
			return await this.client.listSites();
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load all sites.');
		}

		return null;
	}
}
