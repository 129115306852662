import { inject, injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import moment from 'moment-timezone';

import * as A from './authenticationService';
import * as C from './client';
import * as ES from './emergencyService';
import { Service } from './service';

import { baseUrl } from 'src/config';

export enum ConnectionStatus {
	Disconnected,
	Connecting,
	Connected,
	DifficultyConnecting,
}

export interface AssetEventsSubscription {
	floorplanId?: string | null;
}

export interface GatewayObserverSubscription {
	gatewayId: string;
	sendLogs: boolean;
}

export class GatewayLog {
	gatewayId: string;
	timestamp: moment.Moment;
	message: string;

	constructor(logMessageData: any) {
		if (logMessageData) {
			this.gatewayId = logMessageData['gatewayId'] !== undefined ? logMessageData['gatewayId'] : <any> null;
			this.timestamp = logMessageData['timestamp'] !== undefined ? moment(logMessageData['timestamp'].toString()): <any> null;
			this.message = logMessageData['message'] !== undefined ? logMessageData['message'] : <any> null;
		}
	}
}

@injectable()
export class WebSocketService {
	private _shouldBeConnected: boolean = false;
	private _hub: HubConnection | null = null;

	@observable status: ConnectionStatus = ConnectionStatus.Disconnected;
	private _lastConnectAttempt: moment.Moment = moment().subtract(1, 'day');
	private _connectAttemptCount: number = 0;

	private _lastPing: moment.Moment = moment();

	private _connectionUpdateInterval: number;

	private _assetEventsSubscription: AssetEventsSubscription | null = null;
	private _assetEventsSubscriptionCallback: ((events: C.IAssetEventDto[]) => void) | null = null;
	private _gatewayObserverSubscriptionCallback: ((log: GatewayLog) => void) | null = null;
	private _reportUpdateCallback: ((report: C.IReportDto) => void) | null = null;
	private _gatewayUpdateCallback: ((gateway: C.IGatewayDto) => void) | null = null;

	private _safetyTimerUpdatesSubscriptionCallback: ((safetyTimer: C.ISafetyTimerDto) => void) | null = null;

	constructor(
		@inject(Service.Authentication) private _authService: A.AuthenticationService,
		@inject(Service.Emergency) private _emergencyService: ES.EmergencyService,
	) {
		makeObservable(this);
		this._connectionUpdateInterval = window.setInterval(() => this.updateConnection(), 1000);
	}

	private async updateConnection() {
		// Shouldn't be connected, make sure we aren't
		if (!this._shouldBeConnected) {
			if (this._hub)
				await this.resetState();

			return;
		}

		// Should be connected, maybe connect or ping
		if (this._hub)
			await this.maybeSendPing();
		else
			await this.maybeConnect();
	}

	private hasTimeElapsedSeconds(previousTime: moment.Moment, seconds: number) {
		const now = moment();
		const diff = now.diff(previousTime, 'milliseconds');
		return diff > seconds * 1000;
	}

	private async maybeSendPing() {
		if (!this.hasTimeElapsedSeconds(this._lastPing, 10))
			return;

		this._lastPing = moment();

		try {
			await this._hub!.invoke('Ping');
		} catch (err) {
			// Ignore
		}
	}

	private async maybeConnect() {
		if (!this.hasTimeElapsedSeconds(this._lastConnectAttempt, 10))
			return;

		this._lastConnectAttempt = moment();
		await this.connect();
	}

	start() {
		this._shouldBeConnected = true;
	}

	async stop(): Promise<void> {
		this._shouldBeConnected = false;
		await this.disconnect();
	}

	private async connect() {
		try {
			this._hub = new HubConnectionBuilder()
				.withUrl(`${baseUrl}/api/v1/websocket`)
				.build();

			this._hub.on('AssetEvents', data => this.processAssetEvents(data));
			this._hub.on('SafetyTimerUpdate', data => this.processSafetyTimerUpdates(C.SafetyTimerDto.fromJS(data)));
			this._hub.on('Emergency', data => this._emergencyService.handleEmergency(C.EmergencyDto.fromJS(data)));
			this._hub.on('ReportUpdate', data => this._reportUpdateCallback && this._reportUpdateCallback(C.ReportDto.fromJS(data)));
			this._hub.on('GatewayUpdate', data => this._gatewayUpdateCallback && this._gatewayUpdateCallback(C.GatewayDto.fromJS(data)));
			this._hub.on('PermissionsUpdate', data => this._authService.updateGeneralPermissions(C.GeneralIdentityPermissions.fromJS(data)));
			this._hub.on('GatewayLog', data => this.processGatewayLog(data));
			this._hub.on('Pong', () => {});
			this._hub.onclose(() => this.disconnected());

			await this._hub.start();
			this._connectAttemptCount = 0;

			await this.updateAssetEventsSubscription(this._assetEventsSubscription);
		} catch (err) {
			console.error('Failed to connect to websocket.', err);

			this._hub = null;
			this._connectAttemptCount++;
		}

		this.updateConnectionStatus();
	}

	private async disconnect() {
		if (!this._hub)
			return;

		try {
			await this._hub.stop();
		} catch (err) {
			// Ignore
		}

		this._hub = null;
		this.updateConnectionStatus();
	}

	private async disconnected(): Promise<void> {
		this._hub = null;
		this.updateConnectionStatus();
	}

	@action
	private updateConnectionStatus() {
		if (!this._shouldBeConnected) {
			this.status = ConnectionStatus.Disconnected;
			return;
		}

		if (this._hub) {
			this.status = ConnectionStatus.Connected;
			return;
		}

		this.status = this._connectAttemptCount <= 2 ? ConnectionStatus.Connecting : ConnectionStatus.DifficultyConnecting;
	}

	async updateAssetEventsSubscription(subscription: AssetEventsSubscription | null): Promise<void> {
		this._assetEventsSubscription = subscription;

		try {
			this._hub && await this._hub.invoke('UpdateAssetEventsSubscription', subscription);
		} catch (err) {
			// Couldn't update subscription, websocket is probably not connected
		}
	}

	async subscribeToGatewayObserver(gatewayId: string, callback: (log: GatewayLog) => void) {
		this._gatewayObserverSubscriptionCallback = callback;

		const subscription: GatewayObserverSubscription = {
			gatewayId: gatewayId,
			sendLogs: true,
		};

		try {
			this._hub && await this._hub.invoke('UpdateGatewayObserverSubscription', subscription);
		} catch (err) {
			// Couldn't add a gateway observer subscription, websocket is probably not connected.
		}
	}

	async unsubscribeFromGatewayObserver(gatewayId: string) {
		this._gatewayObserverSubscriptionCallback = null;

		const subscription: GatewayObserverSubscription = {
			gatewayId: gatewayId,
			sendLogs: false,
		};

		try {
			this._hub && await this._hub.invoke('UpdateGatewayObserverSubscription', subscription);
		} catch (err) {
			// Couldn't remove a gateway observer subscription, websocket is probably not connected.
		}
	}

	subscribeToReportUpdates(callback: (report: C.IReportDto) => void) {
		this._reportUpdateCallback = callback;
	}

	unsubscribeFromReportUpdates() {
		this._reportUpdateCallback = null;
	}

	subscribeToGatewayUpdates(callback: (gateway: C.IGatewayDto) => void) {
		this._gatewayUpdateCallback = callback;
	}

	unsubscribeFromGatewayUpdates() {
		this._gatewayUpdateCallback = null;
	}

	async subscribeToAssetEvents(callback: (events: C.IAssetEventDto[]) => void) {
		this._assetEventsSubscriptionCallback = callback;
		await this.updateAssetEventsSubscription({ floorplanId: null });
	}

	async unsubscribeFromAssetEvents() {
		this._assetEventsSubscriptionCallback = null;
		await this.updateAssetEventsSubscription(null);
	}

	private processAssetEvents(data: any[]): void {
		const assetEvents = data.map(x => C.AssetEventDto.fromJS(x));
		if (this._assetEventsSubscriptionCallback)
			this._assetEventsSubscriptionCallback(assetEvents);
	}

	private processGatewayLog(data: any): void {
		const gatewayLog = new GatewayLog(data);

		if (this._gatewayObserverSubscriptionCallback)
			this._gatewayObserverSubscriptionCallback(gatewayLog);
	}

	subscribeToSafetyTimerUpdates(callback: (safetyTimer: C.ISafetyTimerDto) => void) {
		this._safetyTimerUpdatesSubscriptionCallback = callback;
	}

	unsubscribeFromSafetyTimerUpdates() {
		this._assetEventsSubscriptionCallback = null;
	}

	private processSafetyTimerUpdates(safetyTimer: C.ISafetyTimerDto): void {
		if (this._safetyTimerUpdatesSubscriptionCallback)
			this._safetyTimerUpdatesSubscriptionCallback(safetyTimer);
	}

	async resetState(): Promise<void> {
		await this.stop();

		this._assetEventsSubscription = null;
		this._assetEventsSubscriptionCallback = null;
		this._gatewayUpdateCallback = null;
		this._reportUpdateCallback = null;

		this._lastConnectAttempt = moment().subtract(1, 'day');
		this._lastPing = moment();
	}
}
