import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryDealerList {
		dealers {
			id
			name
			type
			maxTrialMonths
			billingCode
			deviceTrialEndNotificationEnabled
			deviceTrialEndNotificationPeriodDays
			navbarBrandingImageUrl
		}
	}
`;
