import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';

interface Props {
	time?: moment.Moment;
	render: () => JSX.Element;
}

// Use this component to isolate a state change occuring every second. (Doesn't cause a re-render of parent)
export const IsolatedRefreshingDisplay = observer((props: Props) => {
	const [content, setContent] = useState<JSX.Element>(props.render());

	useEffect(() => {
		setContent(props.render());

		const interval = window.setInterval(() => setContent(props.render()), 1000);

		return () => {
			clearInterval(interval);
		};
	}, [props.time, props.render]);

	return content;
});
