import mapboxgl from 'mapbox-gl';
import { bind } from 'bind-decorator';

import { IEventsBeforeTime } from './historyMap';
import { AssetMapMarkerManager, IMapAssetMarker } from './assetMapMarkerManager';

import {
	Client as C,
} from 'src/services';

import { IMapAsset } from './mapAsset';

export class HistoryMapMarkerManager extends AssetMapMarkerManager {
	private _onClickAssetEvent: (assetEventId: string) => void;

	private _events?: C.IAssetEventDto[] = undefined;
	private _eventMarkers: IMapAssetMarker[] = [];

	private _selectedEvents?: IEventsBeforeTime = undefined;
	private _displayMarker?: IMapAssetMarker;

	private _asset?: IMapAsset | null;

	constructor(map: mapboxgl.Map, assetTypes: C.IAssetTypeDto[], onClickAssetEvent: (assetEventId: string) => void) {
		super(map, assetTypes);
		this._onClickAssetEvent = onClickAssetEvent;
	}

	protected reinitialiseMarkers() {
		this.setEventMarkers(this._events);
		this.setSelectedEvents(this._selectedEvents);
	}

	protected clearMarkers() {
		if (this._displayMarker)
			this._displayMarker.markerElement.remove();

		for (const marker of this._eventMarkers)
			marker.mapboxMarker.remove();
	}

	setSelectedAsset(asset?: IMapAsset | null) {
		this._asset = asset;
		this.setSelectedEvents(this._selectedEvents);
	}

	setSelectedEvents(eventsBeforeSelectedTime?: IEventsBeforeTime) {
		if (this._displayMarker)
			this._displayMarker.markerElement.remove();

		this._selectedEvents = eventsBeforeSelectedTime;
		if (!eventsBeforeSelectedTime || !eventsBeforeSelectedTime.eventWithLocation || !eventsBeforeSelectedTime.event)
			return;

		const locationEvent = eventsBeforeSelectedTime.eventWithLocation;
		const location = this.getLocation(locationEvent.assetLocation!);
		if (!location)
			return;

		const markerElement = this.createNewMarkerElement(this._asset!).mapMarker;
		markerElement.dataset['assetEventId'] = eventsBeforeSelectedTime.event.assetEventId;

		const mapMarker = new mapboxgl.Marker(markerElement)
			.setLngLat(location);

		const assetMarker: IMapAssetMarker = {
			markerElement: markerElement,
			mapboxMarker: mapMarker,
			location: locationEvent.assetLocation!,
		};

		if (locationEvent.assetLocation!.heading)
			this.setHeading(assetMarker, locationEvent.assetLocation!.heading, this._asset);

		this.setEmergency(assetMarker, eventsBeforeSelectedTime.event.type === C.AssetEventType.Emergency, eventsBeforeSelectedTime.event.properties?.emergency?.emergencyType, this._asset);
		this.setPulse(assetMarker, true, this._asset);

		assetMarker.mapboxMarker.addTo(this._map);
		this._displayMarker = assetMarker;
	}

	public setEventMarkers(events?: C.IAssetEventDto[]) {
		for (const marker of this._eventMarkers)
			marker.mapboxMarker.remove();

		this._eventMarkers = [];
		this._events = events;
		if (!events)
			return;

		let lastLocationEvent: C.IAssetEventDto | undefined = undefined;
		for (const event of events) {
			if (event.assetLocation)
				lastLocationEvent = event;

			if (event.type == null || (event.type !== C.AssetEventType.Emergency && event.type !== C.AssetEventType.KeyOn &&
				event.type !== C.AssetEventType.KeyOff && event.type !== C.AssetEventType.StartMoving && event.type !== C.AssetEventType.StopMoving))
				continue;

			if (!lastLocationEvent)
				continue;

			const location = this.getLocation(lastLocationEvent.assetLocation!);
			if (!location)
				continue;

			const newMarker = this.createNewMarkerElement(this._asset!);
			const markerElement = newMarker.mapMarker;
			markerElement.dataset['assetEventId'] = event.assetEventId;

			const mapMarker = new mapboxgl.Marker(markerElement)
				.setLngLat(location);

			const assetMarker: IMapAssetMarker = {
				markerElement: markerElement,
				mapboxMarker: mapMarker,
				location: lastLocationEvent.assetLocation!,
			};

			switch (event.type) {
				case C.AssetEventType.Emergency:
					this.setEmergency(assetMarker, true, event.properties?.emergency?.emergencyType, this._asset);
					break;
				case C.AssetEventType.KeyOn:
					this.setAssetEventType(assetMarker, 'key-on');
					break;
				case C.AssetEventType.KeyOff:
					this.setAssetEventType(assetMarker, 'key-off');
					break;
				case C.AssetEventType.StartMoving:
					this.setAssetEventType(assetMarker, 'start-moving');
					break;
				case C.AssetEventType.StopMoving:
					this.setAssetEventType(assetMarker, 'stop-moving');
					break;
				default:
					continue;
			}

			assetMarker.mapboxMarker.addTo(this._map);
			this._eventMarkers.push(assetMarker);
		}
	}

	setAssetEventType(mapMarker: IMapAssetMarker, cssName: string) {
		if (mapMarker.contentElement) {
			mapMarker.markerElement.removeChild(mapMarker.contentElement!);
			mapMarker.contentElement = undefined;
		}

		mapMarker.markerElement.classList.add(cssName);

		mapMarker.contentElement = document.createElement('div');
		mapMarker.contentElement.className = 'connect-map-marker__content';
		const htmlDivSvgIcon = document.createElement('div');
		htmlDivSvgIcon.classList.add('icon');

		mapMarker.contentElement.appendChild(htmlDivSvgIcon);
		mapMarker.markerElement.appendChild(mapMarker.contentElement);
	}

	@bind
	protected onClickMarker(ev: MouseEvent) {
		if (!ev.currentTarget)
			return;

		const element = ev.currentTarget as HTMLDivElement;
		if (!element.dataset)
			return;

		const assetEventId = element.dataset['assetEventId'];
		if (!assetEventId)
			return;

		this._onClickAssetEvent(assetEventId);
	}

	@bind
	protected onMouseOverMarker(ev: MouseEvent): void {

	}

	@bind
	protected onMouseOutMarker(ev: MouseEvent): void {

	}
}
