import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';
import { ToasterService } from './toasterService';

@injectable()
export class BeaconsService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.Toaster) private _toasterService: ToasterService,
	) {

	}

	async addBleBeaconSet(request: C.IAddBleBeaconSetRequest): Promise<C.IBleBeaconSetDto> {
		return await this.client.addBleBeaconSet(new C.AddBleBeaconSetRequest(request));
	}

	async addBeaconsToASetById(beaconSetId: string, request: C.IBleSetAddRemoveBeaconsRequest): Promise<void> {
		return await this.client.bleBeaconSetAddBeacons(beaconSetId, new C.BleSetAddRemoveBeaconsRequest(request));
	}

	async removeBeaconsFromSetById(beaconSetId: string, request: C.IBleSetAddRemoveBeaconsRequest) {
		return await this.client.bleBeaconSetRemoveBeacons(beaconSetId, new C.BleSetAddRemoveBeaconsRequest(request));
	}

	async updateBleBeaconSet(beaconSetId: string, request: C.IUpdateBleBeaconSetRequest): Promise<C.IBleBeaconSetDto> {
		return await this.client.updateBleBeaconSetById(beaconSetId, new C.UpdateBleBeaconSetRequest(request));
	}

	async getAllBleBeaconSets(): Promise<C.IBleBeaconSetDto[]> {
		return await this.client.listBleBeaconSets();
	}

	async getBeaconSetById(beaconSetId: string): Promise<C.IBleBeaconSetDto> {
		return await this.client.getBleBeaconSetById(beaconSetId);
	}

	async deleteBeaconSetById(beaconSetId: string): Promise<void> {
		return await this.client.deleteBleBeaconSetById(beaconSetId);
	}
}
