import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetServiceReminderById($id: UUID!, $includeClients: Boolean!) {
		assetServiceReminderById (id: $id) {
			id
			state
			asset {
				id
				name
			}
			assetServiceReminderType {
				id
				name
			}
			nextDateTriggerDate
			dateDefaultFrequency
			nextOdometerTrigger
			odometerDefaultFrequency
			nextEngineTimeTrigger
			engineTimeDefaultFrequency
			extraInformation
			users {
				id
				name
				identity {
					id
					client @include(if: $includeClients) {
						id
					}
				}
			}
			userGroups {
				id
				name
				client @include(if: $includeClients) {
					id
				}
			}
		}
	}
`;
