import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { red } from '@material-ui/core/colors';
import classNames from 'classnames';
import { beaconUuid } from 'src/config';
import DialogContentText from '@material-ui/core/DialogContentText';

import AddIcon from '@material-ui/icons/Add';

import { FixedWidthPage, MessagePage, Button, CustomActionDialogBox, PopoverMenu, PopoverMenuItem, LoadingMessagePage, ErrorMessagePage } from 'src/components';
import { getPrettyName } from 'src/util';

import {
	AuthenticationService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import { useQueryBeaconsList, QueryBeaconsList_bluetoothBeacons } from 'src/graphql/__generated__/queries/queryBeaconsList';
import { useMutationDeleteBluetoothBeacons } from 'src/graphql/__generated__/mutations/mutationDeleteBluetoothBeacons';

import './beaconsList.scss';

export const BeaconsList = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [deleteBeaconId, setDeleteBeaconId] = useState<string | null>(null);

	const canDeleteAssets = _authService.currentAuth.permissions.general.deleteAssets || false;
	const canAddBeacons = _authService.currentAuth.permissions.general.addBeacons;
	const canManageBeaconSets = _authService.currentAuth.permissions.general.manageBeacons;

	const query = useQueryBeaconsList({});
	const [deleteBeacon] = useMutationDeleteBluetoothBeacons();

	const beacons = useMemo(() =>
		(query.data?.bluetoothBeacons || [])
			.sort((a, b) => {
				const compare = a.name.localeCompare(b.name, undefined, { numeric: true });
				if (compare !== 0)
					return compare;

				return (a.serialNumber || '').localeCompare((b.serialNumber || ''), undefined, { numeric: true });
			})
		, [query.data?.bluetoothBeacons]);

	if (query.loading)
		return <LoadingMessagePage />;

	if (query.error || !query.data?.bluetoothBeacons)
		return <ErrorMessagePage />;

	const renderBeaconInformation = (beacon: QueryBeaconsList_bluetoothBeacons): JSX.Element => {
		const statusText = beacon.disabled ? 'Disabled' : 'Enabled';

		return <tr key={beacon.id} className="content-box">
			<td>{beacon.name}</td>
			<td>{beacon.floorPlan?.name}</td>
			<td className="monospace">{beacon.serialNumber}</td>
			<td className="monospace">{beacon.major}</td>
			<td className="monospace">{beacon.minor}</td>
			<td>{beacon.transmitPower}</td>
			<td>{beacon.beaconType && getPrettyName(beacon.beaconType)}</td>
			<td>
				<div className="status">
					<div
						className={classNames('status-indicator', { 'online': !beacon.disabled })}
						title={statusText}
					/>
				</div>
			</td>

			<td className="actions">
				<PopoverMenu
					renderOptions={() => renderActionMenu(beacon)}
				/>
			</td>
		</tr>;
	};

	const renderActionMenu = (beacon: QueryBeaconsList_bluetoothBeacons) => {
		const options = [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				title="Edit beacon"
				href={`/app/bluetooth-beacons/${beacon.id}/edit`}
			/>,
		];

		if (canDeleteAssets) {
			options.push(<PopoverMenuItem
				key="delete"
				text="Delete"
				title="Delete beacon"
				onClick={() => setDeleteBeaconId(beacon.id)}
			/>);
		}

		return options;
	};

	const confirmDelete = async (beacon: QueryBeaconsList_bluetoothBeacons) => {
		setDeleteBeaconId(null);

		try {
			await deleteBeacon({
				variables: {
					input: { ids: [ beacon.id ] }
				},
			});

			_toasterService.showSuccess(`${beacon.name} has been deleted.`);
			query.refetch();
		} catch (e) {
			_toasterService.handleWithToast(e, 'Failed to delete beacon.');
		}
	};

	const beaconToDelete = deleteBeaconId ? beacons.find(x => x.id === deleteBeaconId) : null;

	const headingActions = <>
		{canManageBeaconSets  && <Button className="beacon-sets-button" href="/app/ble-beacon-sets/list" text="Beacon Sets" variant="outlined" color="primary"/>}
		{canAddBeacons && <Button href="/app/bluetooth-beacons/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
	</>;

	return <FixedWidthPage
		headingText="Beacons"
		headingActions={headingActions}
		noContentBackground
		contentClassName={classNames({ 'flex-fill-no-overflow': beacons.length === 0 })}
	>
		<p className="beacon-uuid">
			All beacons must be programmed with the following UUID: <span className="monospace">{beaconUuid}</span>
		</p>

		{beacons.length > 0 && <table className="card-table">
			<thead>
				<tr>
					<th>Name</th>
					<th>Floor Plan</th>
					<th>Serial Number</th>
					<th>Major</th>
					<th>Minor</th>
					<th>TX Power</th>
					<th>Type</th>
					<th className="align-center">Enabled</th>
					<th></th>
				</tr>
			</thead>

			<tbody>
				{beacons.map(renderBeaconInformation)}
			</tbody>
		</table>}

		{beacons.length === 0 && <MessagePage
			title="No beacons."
			action={canAddBeacons ? <Button href="/app/bluetooth-beacons/add" text="Add a beacon?" variant="outlined" /> : null}
		/>}

		{beaconToDelete && <CustomActionDialogBox
			title={`Delete ${beaconToDelete.name}?`}
			actionButton={<Button
				variant="contained"
				color={red}
				text="Delete Beacon"
				onClick={() => confirmDelete(beaconToDelete)}
			/>}
			dialogCloseCallback={() => setDeleteBeaconId(null)}
		>
			<DialogContentText>
				If deleted, assets will no longer be able to use this beacon for indoor positioning.<br />
				<strong>This cannot be undone.</strong>
			</DialogContentText>
		</CustomActionDialogBox>}
	</FixedWidthPage>;
});
