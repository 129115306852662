import { inject, injectable } from 'inversify';
import { makeAutoObservable, observable } from 'mobx';
import moment from 'moment-timezone';

import { Service } from './service';
import { GatewayLog, WebSocketService } from './webSocketService';

const logsTimeoutMilliseconds = 5 * 60 * 1000; // 5 minutes.
export const connectingMessage = 'Connecting to the gateway...';
export const disconnectedMessage = 'Disconnected from the gateway.';

@injectable()
export class GatewayLogsService {
	private _logsTimeout: number | null = null;

	@observable logs: GatewayLog[] = [];
	@observable monitoringGatewayId: string | null = null;

	constructor(
		@inject(Service.WebSocket) private _webSocketService: WebSocketService,
	) {
		makeAutoObservable(this, {}, { autoBind: true });
	}

	monitorGateway(gatewayId: string) {
		this.logs = [
			{
				gatewayId: gatewayId,
				message: connectingMessage,
				timestamp: moment.tz(),
			},
		];

		this.monitoringGatewayId = gatewayId;
		this._webSocketService.subscribeToGatewayObserver(gatewayId, this.addLog);

		if (this._logsTimeout)
			clearTimeout(this._logsTimeout);

		this._logsTimeout = window.setTimeout(() => {
			this.stopMonitoringGateway();
		}, logsTimeoutMilliseconds);
	}

	stopMonitoringGateway() {
		if (!this.monitoringGatewayId)
			return;

		if (this._logsTimeout) {
			clearTimeout(this._logsTimeout);
			this._logsTimeout = null;
		}

		this._webSocketService.unsubscribeFromGatewayObserver(this.monitoringGatewayId);
		this.monitoringGatewayId = null;
	}

	userActive() {
		if (!this._logsTimeout)
			return;

		clearTimeout(this._logsTimeout);

		this._logsTimeout = window.setTimeout(() => {
			this.stopMonitoringGateway();
		}, logsTimeoutMilliseconds);
	}

	private addLog(log: GatewayLog) {
		this.logs.push(log);
	}
}
