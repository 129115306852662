import { FormikErrors } from 'formik';

import { DeviceIoAction, DeviceIoName, TeltonikaDeviceModelType } from 'src/../__generated__/globalTypes';

import { getPrettyName } from '../../util/prettyNames';

export interface IDeviceWithIo {
	id: string;
	name: string;
	modelType: TeltonikaDeviceModelType | null;
	ioConfigurations: IDeviceIoConfiguration[];
}

export interface IDeviceIoConfiguration {
	name: string;
	id: string | null;
	isEnabled: boolean;
	ioName: DeviceIoName;
	onAction: DeviceIoAction | null;
	offAction: DeviceIoAction | null;
}

export interface DeviceIoNameOption {
	ioName: DeviceIoName;
	name: string;
}

export interface DeviceIoActionOption {
	action: DeviceIoAction | null;
	name: string;
}

export const validateDevicesWithIoConfigurations = (values: IDeviceWithIo[]) => {
	const errors: FormikErrors<IDeviceWithIo[]> = [];
	let errorAdded = false;

	for (const device of values) {
		const deviceErrors: FormikErrors<IDeviceWithIo> = {};

		const validatedIoConfigurations = validateIoConfigurations(device.ioConfigurations);
		deviceErrors.ioConfigurations = validatedIoConfigurations.ioErrors;

		errors.push(deviceErrors);

		errorAdded = errorAdded || validatedIoConfigurations.errorsAdded;
	}

	return errorAdded ? { devices: errors } : undefined;
};

export const validateIoConfigurations = (ioConfigurations: IDeviceIoConfiguration[]) => {
	const actionMustBeSpecifiedError = 'An action must be specified';
	const uniqueIoNameAndActionError = 'One of each action is allowed per IO type';

	const ioErrors: FormikErrors<IDeviceIoConfiguration>[] = [];
	let errorsAdded = false;

	for (const ioConfiguration of ioConfigurations) {
		const ioConfigurationErrors:  FormikErrors<IDeviceIoConfiguration> = {};

		if (!ioConfiguration.name) {
			ioConfigurationErrors.name = 'Name is required';
			errorsAdded = true;
		}

		if (ioConfiguration.name.length > 25) {
			ioConfigurationErrors.name = 'Name must be 25 characters or less.';
			errorsAdded = true;
		}

		if (!ioConfiguration.onAction && !ioConfiguration.offAction) {
			ioConfigurationErrors.onAction = actionMustBeSpecifiedError;
			ioConfigurationErrors.offAction = actionMustBeSpecifiedError;
			errorsAdded = true;
		} else if (ioConfiguration.onAction) {
			// Make sure there is no other configuration with the same IO and action on.
			const index = ioConfigurations.findIndex(x => x != ioConfiguration && x.ioName === ioConfiguration.ioName && x.onAction && ioConfiguration.onAction && x.onAction === ioConfiguration.onAction);
			if (index >= 0) {
				ioConfigurationErrors.onAction = uniqueIoNameAndActionError;
				errorsAdded = true;
			}
		} else if (ioConfiguration.offAction) {
			// Make sure there is no other configuration with the same IO and action off.
			const index = ioConfigurations.findIndex(x => x != ioConfiguration && x.ioName === ioConfiguration.ioName && x.offAction && ioConfiguration.offAction && x.offAction === ioConfiguration.offAction);
			if (index >= 0) {
				ioConfigurationErrors.offAction = uniqueIoNameAndActionError;
				errorsAdded = true;
			}
		}

		ioErrors.push(ioConfigurationErrors);
	}

	return {ioErrors, errorsAdded};
};

export const getDeviceIoNameOptions = (modelType: TeltonikaDeviceModelType): DeviceIoNameOption[] => {
	const IoLines: DeviceIoNameOption[] = [];

	// Currently both Teltonika FMC130 and FMC640 devices use din1 for ignition, so hide din1.
	// IoLines.push({
	// 	ioName: DeviceIoName.DIN1,
	// 	name: getPrettyName(DeviceIoName.DIN1),
	// });

	let din2PrettyName = getPrettyName(DeviceIoName.DIN2);
	if (modelType === TeltonikaDeviceModelType.FMC130 || modelType === TeltonikaDeviceModelType.FMC230)
		din2PrettyName += '/Ground Sense';

	IoLines.push({
		ioName: DeviceIoName.DIN2,
		name: din2PrettyName,
	});

	IoLines.push({
		ioName: DeviceIoName.DIN3,
		name: getPrettyName(DeviceIoName.DIN3),
	});

	if (modelType === TeltonikaDeviceModelType.FMC640) {
		IoLines.push({
			ioName: DeviceIoName.DIN4,
			name: getPrettyName(DeviceIoName.DIN4),
		});
	}

	return IoLines;
};

export const getDeviceIoActionOptions = () => {
	return [{
		action: null,
		name: 'N/A',
	},
	{
		action: DeviceIoAction.TRIGGER_EMERGENCY,
		name: getPrettyName(DeviceIoAction.TRIGGER_EMERGENCY),
	},
	{
		action: DeviceIoAction.TRIGGER_COUNTER,
		name: getPrettyName(DeviceIoAction.TRIGGER_COUNTER),
	},
	{
		action: DeviceIoAction.TRIGGER_TIMER,
		name: getPrettyName(DeviceIoAction.TRIGGER_TIMER),
	},
	{
		action: DeviceIoAction.TRIGGER_PRIORITY_ALERT,
		name: getPrettyName(DeviceIoAction.TRIGGER_PRIORITY_ALERT),
	}];
};
