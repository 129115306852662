import React from 'react';

import { AssetServiceRemindersStateBubblesDisplay } from './assetServiceRemindersStateBubblesDisplay';

import { QueryAssetServiceReminderTypes_assetServiceReminderTypes  } from 'src/graphql/__generated__/queries/queryAssetServiceReminderTypes';
import { AssetServiceReminderState } from 'src/../__generated__/globalTypes';

interface Props {
	serviceReminderType: QueryAssetServiceReminderTypes_assetServiceReminderTypes;
	href: string;
}

export const AssetServiceReminderStateDisplay = ((props: Props) => {
	let totalNotExpiredReminders = 0;
	let totalRemindersAboutToExpire = 0;
	let totalExpiredReminders = 0;

	if (props.serviceReminderType.reminders) {
		totalNotExpiredReminders = props.serviceReminderType.reminders.filter(x => x?.state === AssetServiceReminderState.NOT_EXPIRED).length;
		totalRemindersAboutToExpire = props.serviceReminderType.reminders.filter(x => x?.state === AssetServiceReminderState.WITHIN_REMINDER_PERIOD).length;
		totalExpiredReminders = props.serviceReminderType.reminders.filter(x => x?.state === AssetServiceReminderState.EXPIRED).length;
	}

	return <AssetServiceRemindersStateBubblesDisplay
		totalNotExpiredReminders={totalNotExpiredReminders}
		totalRemindersAboutToExpire={totalRemindersAboutToExpire}
		totalExpiredReminders={totalExpiredReminders}
		href={props.href}
	/>;
});
