import { MapViewMode } from './mapViewMode';
import { LngLatLike, LngLat } from 'mapbox-gl';

import {
	Client as C,
	Container,
	FloorPlansService,
	Service,
} from 'src/services';
import { convertLatLngToXy } from 'src/util/latLngConverter';

export interface IMapMarkerLocation {
	location: C.ILatLngDto;
	floorLevelIndex?: number | null;
}

export interface IMapMarker {
	markerElement: HTMLDivElement;
	mapboxMarker: mapboxgl.Marker;
	contentElement?: HTMLDivElement;
	labelElement?: HTMLDivElement;
	location: IMapMarkerLocation;
}

export abstract class MapMarkerManager {
	private _floorPlanService = Container.get<FloorPlansService>(Service.FloorPlans);

	protected _map: mapboxgl.Map;

	protected _currentMapViewMode: MapViewMode = MapViewMode.World;
	protected _currentMapFloorPlan: C.IFloorPlanDto | undefined;

	constructor(map: mapboxgl.Map) {
		this._map = map;
	}

	public setMapView(mapViewMode: MapViewMode, floorPlan?: C.IFloorPlanDto) {
		this.clearMarkers();

		this._currentMapViewMode = mapViewMode;
		this._currentMapFloorPlan = floorPlan;

		this.reinitialiseMarkers();
	}

	protected abstract clearMarkers(): void;
	protected abstract reinitialiseMarkers(): void;
	protected abstract onClickMarker(ev: MouseEvent): void;
	protected abstract onMouseOverMarker(ev: MouseEvent): void;
	protected abstract onMouseOutMarker(ev: MouseEvent): void;

	protected baseCreateNewMarkerElement() : HTMLDivElement {
		const markerElement = document.createElement('div');

		markerElement.onclick = this.onClickMarker;
		markerElement.onmouseover = this.onMouseOverMarker;
		markerElement.onmouseout = this.onMouseOutMarker;

		return markerElement;
	}

	protected setLocationLngLat(marker: mapboxgl.Marker, lngLat: LngLatLike): boolean {
		const lngLatConverted = LngLat.convert(lngLat);
		marker.setLngLat(lngLatConverted);

		return true;
	}

	protected getMarkerLocation(lng: number, lat: number, floorPlanId?: string): { lng: number; lat: number; } | null {
		if (this._currentMapViewMode === MapViewMode.FloorPlan && this._currentMapFloorPlan) {
			if (!floorPlanId || floorPlanId !== this._currentMapFloorPlan.floorPlanId)
				return null;

			const floorPlan = this._floorPlanService.floorPlans.get(floorPlanId);
			if (!floorPlan)
				return null;

			const floorPlanLocation = convertLatLngToXy(floorPlan, lat, lng, true);

			return {
				lng: floorPlanLocation[0],
				lat: floorPlanLocation[1],
			};
		}

		return {
			lng,
			lat,
		};
	}
}
