import { IReportFormat } from './IReportFormat';

export class UserAuthAuditReportFormat implements IReportFormat {
	HasAssetSelection = false;
	HasGeofenceSelection = false;
	HasMinimumTravelSpeedAndStopTime = false;
	HasSpeedLimit = false;
	HasBillingCodes = false;
	HasUserSelection = true;
	HasAddressBookEntrySelection = false;
	ReportDefinitionHelperText = 'The authorization actions for one or more users during a specified time period (e.g. sign in/sign out/sign in as).';
}
