import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class EnableFleetConfigurationService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
	) {

	}

	async addEnableFleetConfiguration(request: C.IAddEnableFleetConfigurationRequest): Promise<C.IEnableFleetConfigurationDto> {
		return await this.client.addEnableFleetConfiguration(new C.AddEnableFleetConfigurationRequest(request));
	}

	async updateEnableFleetConfiguration(configurationId: string, request: C.IUpdateEnableFleetConfigurationRequest): Promise<C.IEnableFleetConfigurationDto> {
		return await this.client.updateEnableFleetConfigurationById(configurationId, new C.UpdateEnableFleetConfigurationRequest(request));
	}

	async getEnableFleetConfiguration(configurationId: string): Promise<C.IEnableFleetConfigurationDto> {
		return await this.client.getEnableFleetConfigurationById(configurationId);
	}

	async listEnableFleetConfigurations(): Promise<C.IEnableFleetConfigurationDto[]> {
		return await this.client.listEnableFleetConfigurations();
	}

	async getEnableFleetCustomizationProfileFields(configurationId: string): Promise<C.IEnableFleetCustomizationProfileFieldDto[]> {
		return await this.client.getEnableFleetConfigurationCustomizationProfileFieldsById(configurationId);
	}

	async getEnableFleetConfigurationFields(configurationId: string): Promise<C.IEnableFleetConfigurationFieldDto[]> {
		return await this.client.getEnableFleetConfigurationFieldsByConfigurationId(configurationId);
	}

	async updateEnableFleetConfigurationFields(configurationId: string, request: C.IUpdateEnableFleetConfigurationFieldsRequest): Promise<void> {
		return await this.client.updateEnableFleetConfigurationFieldsByConfigurationId(configurationId, new C.UpdateEnableFleetConfigurationFieldsRequest(request));
	}

	async getEnableFleetPermissionBySiteId(siteId: string): Promise<C.IEnableFleetFieldPermissionDto[]> {
		return await this.client.listEnableFleetPermissionsBySiteId(siteId);
	}

	async updateEnableFleetPermissions(siteId: string, request: C.IUpdateEnableFleetPermissionsRequest): Promise<void> {
		return await this.client.updateEnableFleetPermissionsBySiteId(siteId, new C.UpdateEnableFleetPermissionsRequest(request));
	}

	async getEnableFleetSitesWithPermissions(): Promise<string[]> {
		return await this.client.getEnableFleetSitesWithPermissions();
	}

	async updateEnableFleetDeviceConfigurationByAssetId(assetId: string, request: C.IUpdateEnableFleetDeviceConfigurationRequest): Promise<void> {
		return await this.client.updateEnableFleetDeviceConfiguration(assetId, new C.UpdateEnableFleetDeviceConfigurationRequest(request));
	}

	async getEnableFleetDeviceConfigurationByAssetId(assetId: string): Promise<C.IEnableFleetDeviceConfigurationFieldDto[]> {
		return await this.client.getEnableFleetDeviceConfiguration(assetId);
	}
}
