import React from 'react';
import { Link } from 'react-router-dom';
import MaterialButton, { ButtonProps as MaterialButtonProps } from '@material-ui/core/Button';
import { Color } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

import './button.scss';

export type ButtonProps = {
	text?: React.ReactNode;
	loading?: boolean;
	href?: string | null;
	color?: Color | 'primary' | 'secondary';
} & Omit<MaterialButtonProps, 'color'>;

const useStyles = makeStyles<Theme, { color?: Color }>(theme => ({
	root: {
		color: props => props.color != null ? theme.palette.getContrastText(props.color[500]) : undefined,
		backgroundColor: props => props.color != null ? props.color[500] : undefined,
		'&:hover': {
			backgroundColor: props => props.color != null ? props.color[700] : undefined,
		},
	},
	startIcon: {
		marginLeft: 'initial',
	},
}));

export const Button: React.FunctionComponent<ButtonProps> = React.forwardRef((buttonProps, ref) => {
	const href = React.forwardRef<Link, never>((linkProps, _) => (
		<Link to={buttonProps.href} {...linkProps} />
	));

	const {
		text,
		color,
		loading,
		...props
	} = buttonProps;

	let classes: Record<string, string> | undefined;
	let materialButtonColor: 'primary' | 'secondary' | undefined;
	if (typeof color === 'string') {
		materialButtonColor = color;
		classes = useStyles({ color: undefined });
	} else {
		classes = useStyles({ color });
	}

	return <MaterialButton
		{...props}
		ref={ref}
		color={materialButtonColor}
		disabled={props.disabled || loading}
		component={props.href ? href : 'button'}
		className={classNames(props.className, classes.root)}
		classes={{ startIcon: classes.startIcon }}
		startIcon={props.startIcon && !loading ? props.startIcon : undefined}
		endIcon={props.endIcon && !loading ? props.endIcon : undefined}
	>
		{loading
			? <CircularProgress size={15} />
			: text}
	</MaterialButton>;
});
