import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class AddressBookService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
	) {	}

	async getAllAddressBooks(): Promise<C.IAddressBookDto[]> {
		return this.client.listAddressBooks();
	}

	async getAddressBookById(addressBookId: string): Promise<C.IAddressBookDto> {
		return this.client.getAddressBookById(addressBookId);
	}

	async updateAddressBookById(addressBookId: string, request: C.IUpdateAddressBookRequest) {
		return this.client.updateAddressBookById(addressBookId, new C.UpdateAddressBookRequest(request));
	}

	async addAddressBook(request: C.IAddAddressBookRequest) {
		return this.client.addAddressBook(new C.AddAddressBookRequest(request));
	}

	async deleteAddressBook(addressBookId: string) {
		return this.client.deleteAddressBookById(addressBookId);
	}
}
