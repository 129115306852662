import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { red } from '@material-ui/core/colors';

import { DeviceType } from 'src/../__generated__/globalTypes';
import { Button, IsolatedRefreshingDisplay } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';

import {
	Client as C,
	Service,
	AuthenticationService,
	SafetyTimerService,
	ToasterService,
	useInjection,
} from 'src/services';

import { SafetyTimerStartUpdateDialog, SafetyTimerInfo } from './safetyTimerStartUpdateDialog';
import { IMapAsset } from './mapAsset';

export interface Props {
	asset: IMapAsset;
	safetyTimer?: C.ISafetyTimerDto;
	safetyTimerUpdate?: (safetyTimer: C.ISafetyTimerDto) => void;
}

export const InfoBoxSafetyTimer = observer((props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _safetyTimerService = useInjection<SafetyTimerService>(Service.SafetyTimer);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [safetyTimerStartUpdateDialogOpen, setSafetyTimerStartUpdateDialogOpen] = useState<boolean>(false);
	const [safetyTimerEndDialogOpen, setSafetyTimerEndDialogOpen] = useState<boolean>(false);

	useEffect(() => {
		if (!props.safetyTimer && safetyTimerEndDialogOpen)
			setSafetyTimerEndDialogOpen(false);
	}, [props.safetyTimer, safetyTimerEndDialogOpen]);

	const onStartSafetyTimer = async (safetyTimerInfo: SafetyTimerInfo) => {
		const request: C.IStartSafetyTimerRequest = {
			assetId: props.asset.assetId,
			endTimestamp: safetyTimerInfo.endTime,
			details: safetyTimerInfo.details,
		};

		try {
			const result = await _safetyTimerService.startSafetyTimer(request);
			setSafetyTimerStartUpdateDialogOpen(false);

			if (props.safetyTimerUpdate) {
				props.safetyTimerUpdate(result);
			}
		} catch (e) {
			_toasterService.handleWithToast(e, 'Failed to start safety timer.');
		}
	};

	const onExtendSafetyTimer = async (safetyTimerId: string, safetyTimerInfo: SafetyTimerInfo) => {
		const request: C.ISafetyTimerCheckInRequest = {
			endTimestamp: safetyTimerInfo.endTime,
			details: safetyTimerInfo.details,
		};

		try {
			const result = await _safetyTimerService.safetyTimerCheckIn(safetyTimerId, request);
			setSafetyTimerStartUpdateDialogOpen(false);

			if (props.safetyTimerUpdate)
				props.safetyTimerUpdate(result);
		} catch (e) {
			_toasterService.handleWithToast(e, 'Failed to extend safety timer.');
		}
	};

	const onEndSafetyTimer = async (safetyTimerId: string | null) => {
		if (!safetyTimerId)
			return;

		const request: C.IEndSafetyTimerRequest = {
			details: null,
		};

		try {
			const result = await _safetyTimerService.endSafetyTimer(safetyTimerId, request);
			if (props.safetyTimerUpdate)
				props.safetyTimerUpdate(result);
		} catch (e) {
			_toasterService.handleWithToast(e, 'Failed to end safety timer.');
		}
	};

	const renderSafetyTimerEndDialog = () => {
		if (!props.safetyTimer)
			return;

		return <Dialog
			open
			onClose={() => setSafetyTimerEndDialogOpen(false)}
		>
			<DialogTitle>End Safety TImer</DialogTitle>

			<DialogContent>
				<p><strong>Is the user safe?</strong></p>

				<p>
					Only end the safety timer if the user is out of the dangerous situation and has confirmed their safety.
					If the user needs more time, cancel this and extend the safety timer instead.
				</p>
			</DialogContent>

			<DialogActions>
				<Button
					text="Cancel"
					onClick={() => setSafetyTimerEndDialogOpen(false)}
					variant="text"
				/>

				<Button
					autoFocus
					text="End Safety Timer"
					onClick={() => onEndSafetyTimer(props.safetyTimer!!.safetyTimerId)}
					variant="text"
					color={red}
				/>
			</DialogActions>
		</Dialog>;
	};

	const renderNewSafetyTimerControls = (): JSX.Element => {
		return <div className="safety-timer">
			<Button
				text="Safety Timer"
				variant="outlined"
				onClick={() => setSafetyTimerStartUpdateDialogOpen(true)}
			/>
		</div>;
	};

	// Is re-rendered every second.
	const renderExistingSafetyTimerControls = (safetyTimer: C.ISafetyTimerDto): JSX.Element => {
		const secondsRemaining = safetyTimer.endTimestamp.diff(moment(), 'seconds');

		return <div className="safety-timer active">
			<strong>Safety Timer</strong>

			<p>Started: {safetyTimer.startTimestamp.format(longDateTimeFormat)}</p>

			{secondsRemaining > 0
				? <p>
					Ending: {safetyTimer.endTimestamp.format(longDateTimeFormat)}<br />
					({props.safetyTimer?.endTimestamp.fromNow()})
				</p>
				: <p>Ended: {safetyTimer.endTimestamp.format(longDateTimeFormat)}</p>}

			{safetyTimer.details && <p>Details: {safetyTimer.details}</p>}

			{_authService.currentAuth.permissions.general.canUseSafetyTimer &&
				!props.asset.devices?.some(x => x.deviceType === DeviceType.MOBILE_PHONE) &&
				_authService.currentAuth.user.identity.type === C.IdentityType.Client && <span className="actions">
				<Button
					text="Extend"
					variant="outlined"
					onClick={() => setSafetyTimerStartUpdateDialogOpen(true)}
					disabled={secondsRemaining < 1}
				/>

				<Button
					text="End"
					variant="outlined"
					onClick={() => setSafetyTimerEndDialogOpen(true)}
					disabled={secondsRemaining < 1}
				/>
			</span>}
		</div>;
	};

	return <>
		{!props.safetyTimer &&
			_authService.currentAuth.permissions.general.canUseSafetyTimer &&
			!props.asset.devices?.some(x => x.deviceType === DeviceType.MOBILE_PHONE) &&
			_authService.currentAuth.user.identity.type === C.IdentityType.Client &&
			renderNewSafetyTimerControls()}

		{props.safetyTimer && <IsolatedRefreshingDisplay
			time={props.safetyTimer.endTimestamp}
			render={() => renderExistingSafetyTimerControls(props.safetyTimer!!)}
		/>}

		{safetyTimerStartUpdateDialogOpen && <SafetyTimerStartUpdateDialog
			safetyTimer={props.safetyTimer}
			onStart={onStartSafetyTimer}
			onExtend={onExtendSafetyTimer}
			onClose={() => setSafetyTimerStartUpdateDialogOpen(false)}
		/>}

		{safetyTimerEndDialogOpen && renderSafetyTimerEndDialog()}
	</>;
});
