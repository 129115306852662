import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import AddIcon from '@material-ui/icons/Add';

import { MessagePage, FixedWidthPage, ThingLoader, Button, PopoverMenu, PopoverMenuItem } from 'src/components';

import {
	Client as C,
	AssetGroupService,
	AuthenticationService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

export const AssetGroupsList = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _assetGroupService = useInjection<AssetGroupService>(Service.AssetGroup);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const load = async () => {
		const assetGroups = await _assetGroupService.getAssetGroups(C.ListAssetGroupsType.Manage);
		if (!assetGroups)
			return null;

		return assetGroups
			.sort((a, b) =>
				a.name.localeCompare(b.name, undefined, { numeric: true }) ||
				a.client.name.localeCompare(b.client.name, undefined, { numeric: true }));
	};

	const renderActionMenu = (group: C.IAssetGroupDto, reload: Function) => {
		const options: JSX.Element[] = [];

		if (group.type === C.AssetGroupType.UserCreated) {
			options.push(<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/asset-groups/${group.assetGroupId}/edit`}
			/>);
		}

		options.push(<PopoverMenuItem
			key="manage"
			text="Manage Group Items"
			href={`/app/asset-groups/${group.assetGroupId}/manage`}
		/>);

		if (group.type === C.AssetGroupType.UserCreated) {
			options.push(<PopoverMenuItem
				key="delete"
				text="Delete"
				onClick={() => onClickDelete(group, reload)}
			/>);
		}

		return options;
	};

	const onClickDelete = async (assetGroup: C.IAssetGroupDto, reload: Function) => {
		const input = prompt('Are you sure you want to delete this asset group? All items assigned to this group will be un-assigned. This cannot be undone. Enter the asset group name to confirm.');
		if (!input)
			return;

		if (input !== assetGroup.name) {
			_toasterService.showWarning('The asset group name you entered did not match the asset group you attempted to delete. No changes have been made.');
			return;
		}

		try {
			await _assetGroupService.deleteAssetGroup(assetGroup.assetGroupId);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to delete asset group.');
		}

		reload();
		_toasterService.showSuccess(`${assetGroup.name} has been deleted successfully.`);
	};

	return <ThingLoader
		load={load}
		render={(groups: C.IAssetGroupDto[], reload: Function) => <FixedWidthPage
			headingText="Asset Groups"
			headingActions={<Button href="/app/asset-groups/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': groups.length === 0 })}
		>
			{groups.length > 0 && <table className="card-table">
				<thead>
					<tr>
						<th>Group Name</th>
						{_authService.currentAuth.user.identity.type !== C.IdentityType.Client && <th>Client</th>}
						<th></th>
					</tr>
				</thead>

				<tbody>
					{groups.map(x => <tr className="content-box"
						key={x.assetGroupId}
					>
						<td>{x.name}</td>
						{_authService.currentAuth.user.identity.type !== C.IdentityType.Client && <td>{x.client.name}</td>}
						<td className="actions">
							<PopoverMenu
								renderOptions={() => renderActionMenu(x, reload)}
							/>
						</td>
					</tr>)}
				</tbody>
			</table>}

			{groups.length === 0 && <MessagePage
				title="No asset groups."
				action={<Button href="/app/asset-groups/add" text="Add a group?" variant="outlined" />}
			/>}
		</FixedWidthPage>}
	/>;
});
