import { useEffect, useMemo } from 'react';

import { Service } from 'src/services/service';
import { ToasterService } from 'src/services/toasterService';

import { useQueryAssetSelector } from 'src/graphql/__generated__/queries/queryAssetSelector';
import { useInjection } from 'src/services';

export interface IAssetSelectorFormValues {
	assets: string[];
}

export const useAssetSelectorService = () => {
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const query = useQueryAssetSelector({});
	const error = !query.loading && (query.error || !query.data?.assets);

	useEffect(() => {
		if (error)
			_toasterService.handleWithToast(query.error, 'Failed to load assets.');
	}, [error, query.error]);

	const assetOptions = useMemo(() => {
		if (!query.data?.assets)
			return null;

		return query.data.assets
			.map(x => ({ label: x.name, value: x.id }))
			.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
	}, [query.data]);

	return {
		loading: query.loading,
		assetOptions,
	};
};
