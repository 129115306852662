import React from 'react';

export interface Item {
	text: string;
	textDesktop?: string;
	startIcon: React.ReactNode;
	endIcon?: React.ReactNode;
}

export interface UrlItem extends Item {
	url: string;
}

export interface SubItem extends Item {
	children: AnyItem[];
	mobileRenderAsSubMenu?: boolean;
}

export interface ActionItem extends Item {
	action: Function;
}

export type AnyItem = UrlItem | SubItem | ActionItem;

export function isUrlItem(item: AnyItem): item is UrlItem {
	return (item as UrlItem).url != null;
}

export function isSubItem(item: AnyItem): item is SubItem {
	return (item as SubItem).children != null;
}

export function isActionItem(item: AnyItem): item is ActionItem {
	return (item as ActionItem).action != null;
}
