import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogTitle } from '@material-ui/core';
import { css } from '@emotion/css';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import classNames from 'classnames';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FormikCheckbox, FormikSelect, FormikTextField } from 'src/components';
import { runFormValidation } from 'src/util';
import { IMapCenterAndZoom } from './mapboxManager';

import {
	Client as C,
	AuthenticationService,
	Service,
	ToasterService,
	UsersService,
	useInjection,
} from 'src/services';

const homeLocationInputsStyle = css`
	display: flex;
	flex-direction: row;

	*:not(:last-child) {
		margin-right: 5px;
	};
`;

const homeLocationButtonStyle = css`
	margin-top: 5px !important;
	margin-bottom: 10px !important;
`;

const mapSettingsSelectBoxesStyle = css`
	>:not(:last-child) {
		margin-bottom: 10px;
	};
`;

const formHeightStyle = css`
	height: 0px;
`;

export interface MapSettingsDialogProps {
	mapSettings: C.IUserMapSettingsDto;
	dialogCloseCallback: (mapSettings?: C.IUserMapSettingsDto) => void;
	currentMapCenterAndZoom: IMapCenterAndZoom;
}

const validateForm = (values: C.IUserMapSettingsDto, errors: FormikErrors<C.IUserMapSettingsDto>) => {
	if (!values.customHomeLocationEnabled) {
		if (!values.customHomeLocationLatitude)
			errors.customHomeLocationLatitude = 'Latitude is required if custom home location is enabled.';
		else if (values.customHomeLocationLatitude < -90 || values.customHomeLocationLatitude > 90)
			errors.customHomeLocationLatitude = 'Latitude must be between -90 and 90.';

		if (!values.customHomeLocationLongitude)
			errors.customHomeLocationLongitude = 'Longitude is required if custom home location is enabled.';
		else if (values.customHomeLocationLongitude < -180 || values.customHomeLocationLongitude > 180)
			errors.customHomeLocationLongitude = 'Longitude must be between -180 and 180.';
		
		if (!values.customHomeLocationZoom)
			errors.customHomeLocationZoom = 'Zoom is required if custom home location is enabled.';
		else if (values.customHomeLocationZoom < 1 || values.customHomeLocationZoom > 22)
			errors.customHomeLocationZoom = 'Zoom must be between 1 and 22.';
	}

	if (!userHasGrantedNotificationPermission(values.assetActiveBehavior))
		errors.assetActiveBehavior = 'Browser notifications must be allowed to show notifications.';

	if (!userHasGrantedNotificationPermission(values.assetInactiveBehavior))
		errors.assetInactiveBehavior = 'Browser notifications must be allowed to show notifications.';
};

const userHasGrantedNotificationPermission = (value: C.AssetActiveInactiveBehavior) =>  {
	if ((value === C.AssetActiveInactiveBehavior.PlaySoundAndShowNotification || value === C.AssetActiveInactiveBehavior.ShowNotification) && Notification.permission !== 'granted')
		return false;

	return true;
};

const checkLatOrLongValue = (value: any, min: number, max: number) => {
	if (value === null || value === undefined)
		return true;

	if (value < min || value > max)
		return false;

	return true;
};

export const mapStyles = [
	{
		value: C.MapStyle.Map,
		label: 'Map',
	},
	{
		value: C.MapStyle.Satellite,
		label: 'Satellite',
	},
];

export const assetLabelBehavior = [
	{
		value: C.MapLabelBehavior.ShowAlways,
		label: 'Show always',
	},
	{
		value: C.MapLabelBehavior.ShowOnHover,
		label: 'Show on hover',
	},
];

export const assetActiveInactiveBehavior = [
	{
		value: C.AssetActiveInactiveBehavior.DoNothing,
		label: 'Do nothing',
	},
	{
		value: C.AssetActiveInactiveBehavior.PlaySound,
		label: 'Play sound',
	},
	{
		value: C.AssetActiveInactiveBehavior.ShowNotification,
		label: 'Show notification',
	},
	{
		value: C.AssetActiveInactiveBehavior.PlaySoundAndShowNotification,
		label: 'Play sound & show notification',
	},
];

export const assetThresholdOptions = [
	{
		value: 0,
		label: 'No limit',
	},
	{
		value: 30,
		label: '30 minutes',
	},
	{
		value: 60,
		label: '1 hour',
	},
	{
		value: 120,
		label: '2 hours',
	},
	{
		value: 240,
		label: '4 hours',
	},
	{
		value: 1440,
		label: '1 day',
	},
	{
		value: 2880,
		label: '2 days',
	},
	{
		value: 10080,
		label: '7 days',
	},
	{
		value: 43200,
		label: '1 month',
	},
];

export const MapSettingsDialog = (props: MapSettingsDialogProps) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _usersService = useInjection<UsersService>(Service.Users);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const getRoundedMapCenterAndZoom = () => {
		return {
			center: {
				lat: Math.round((props.currentMapCenterAndZoom.center.lat + Number.EPSILON) * 1000000) / 1000000,
				lng: Math.round((props.currentMapCenterAndZoom.center.lng + Number.EPSILON) * 1000000) / 1000000,
			},
			zoom: Math.round((props.currentMapCenterAndZoom.zoom + Number.EPSILON) * 100) / 100,
		};
	};

	const onChangeCustomHomeZoomAndCentre = (formikProps: FormikProps<C.IUserMapSettingsDto>) => {
		const roundedMapCenterAndZoom = getRoundedMapCenterAndZoom();

		formikProps.setFieldValue('customHomeLocationLatitude', roundedMapCenterAndZoom.center.lat);
		formikProps.setFieldValue('customHomeLocationLongitude', roundedMapCenterAndZoom.center.lng);
		formikProps.setFieldValue('customHomeLocationZoom', roundedMapCenterAndZoom.zoom);

		formikProps.setFieldTouched('customHomeLocationLatitude');
		formikProps.setFieldTouched('customHomeLocationLongitude');
		formikProps.setFieldTouched('customHomeLocationZoom');
	};

	const onSubmit = async (values: C.IUserMapSettingsDto, { setSubmitting }: FormikHelpers<C.IUserMapSettingsDto>) => {
		try {
			const updateRequest: C.IUpdateUserMapSettingsRequest = {
				defaultMapStyle: values.defaultMapStyle,
				assetLabelBehavior: values.assetLabelBehavior,
				assetActiveBehavior: values.assetActiveBehavior,
				assetInactiveBehavior: values.assetInactiveBehavior,
				customHomeLocationEnabled: values.customHomeLocationEnabled,
				customHomeLocationLatitude: values.customHomeLocationLatitude,
				customHomeLocationLongitude: values.customHomeLocationLongitude,
				customHomeLocationZoom: values.customHomeLocationZoom,
				hideAssetLatestEventThreshold: values.hideAssetLatestEventThreshold ?? 0,
				geofenceLabelBehaviour: values.geofenceLabelBehaviour,
				assetClusteringEnabled: values.assetClusteringEnabled,
			};

			const newSettings = await _usersService.updateUserMapSettings(_authenticationService.currentAuth.user.userId, updateRequest);

			props.dialogCloseCallback(newSettings);
			_toasterService.showSuccess('Map settings updated.');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update map settings.');
			setSubmitting(false);
		}
	};

	const assetActiveAndInactiveValueChange = async (option: {value: C.AssetActiveInactiveBehavior, label: string} | {value: C.AssetActiveInactiveBehavior, label: string}[] | null, formikProps: FormikProps<C.IUserMapSettingsDto>) => {
		if (option === null || Array.isArray(option))
			return;

		if (option.value == C.AssetActiveInactiveBehavior.PlaySoundAndShowNotification || option.value == C.AssetActiveInactiveBehavior.ShowNotification) {
			await Notification.requestPermission();

			// Touch the values to show an error if the user does not allow notifications.
			formikProps.setFieldTouched('assetActiveBehavior');
			formikProps.setFieldTouched('assetInactiveBehavior');
		}
	};

	const roundedMapCenterAndZoom = getRoundedMapCenterAndZoom();

	const initialValues: C.IUserMapSettingsDto = {
		defaultMapStyle: props.mapSettings.defaultMapStyle,
		assetLabelBehavior: props.mapSettings.assetLabelBehavior,
		assetActiveBehavior: props.mapSettings.assetActiveBehavior,
		assetInactiveBehavior: props.mapSettings.assetInactiveBehavior,
		customHomeLocationEnabled: props.mapSettings.customHomeLocationEnabled,
		customHomeLocationLatitude: props.mapSettings.customHomeLocationLatitude != null
			? props.mapSettings.customHomeLocationLatitude
			: roundedMapCenterAndZoom.center.lat,
		customHomeLocationLongitude: props.mapSettings.customHomeLocationLongitude != null
			? props.mapSettings.customHomeLocationLongitude
			: roundedMapCenterAndZoom.center.lng,
		customHomeLocationZoom: props.mapSettings.customHomeLocationZoom != null
			? props.mapSettings.customHomeLocationZoom
			: roundedMapCenterAndZoom.zoom,
			hideAssetLatestEventThreshold: props.mapSettings.hideAssetLatestEventThreshold ?? 0,
		assetClusteringEnabled: props.mapSettings.assetClusteringEnabled,
		geofenceLabelBehaviour: props.mapSettings.geofenceLabelBehaviour,
	};

	return <Formik
		initialValues={initialValues}
		validate={values => runFormValidation(values, validateForm)}
		validateOnChange={true}
		onSubmit={onSubmit}
		render={(formikProps: FormikProps<C.IUserMapSettingsDto>) => <Form className={classNames('formik-form material', formHeightStyle)}>
			<Dialog fullScreen={window.innerWidth < 500} open onClose={() => props.dialogCloseCallback()} className="current-map__settings-dialog">
				<DialogTitle>
					Map Settings
				</DialogTitle>

				<DialogContent>
					<Field
						name="customHomeLocationEnabled"
						label="Use Custom Home Location"
						component={FormikCheckbox}
					/>

					<div className={homeLocationInputsStyle}>
						<Field
							name="customHomeLocationLatitude"
							label="Latitude"
							type="number"
							component={FormikTextField}
							disabled={!formikProps.values.customHomeLocationEnabled}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<Field
							name="customHomeLocationLongitude"
							label="Longitude"
							type="number"
							component={FormikTextField}
							disabled={!formikProps.values.customHomeLocationEnabled}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<Field
							name="customHomeLocationZoom"
							label="Zoom"
							type="number"
							component={FormikTextField}
							disabled={!formikProps.values.customHomeLocationEnabled}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>

					<Button
						className={homeLocationButtonStyle}
						onClick={() => onChangeCustomHomeZoomAndCentre(formikProps)}
						variant="contained"
						text="Set Home To Current Map View"
						disabled={!formikProps.values.customHomeLocationEnabled}
						fullWidth
						type="button"
					/>

					<div className={mapSettingsSelectBoxesStyle}>
						<FormikSelect
							name="defaultMapStyle"
							label="Default map style"
							form={formikProps}
							options={mapStyles}
							helperText="The visual style of the map."
							getOptionLabel={option => option.label}
							getOptionValue={option => option.value}
						/>

						<FormikSelect
							name="assetLabelBehavior"
							label="Asset label behaviour"
							form={formikProps}
							options={assetLabelBehavior}
							helperText="When the labels of assets should be shown."
							getOptionLabel={option => option.label}
							getOptionValue={option => option.value}
						/>

						<FormikSelect
							name="geofenceLabelBehaviour"
							label="Geofence label behaviour"
							form={formikProps}
							options={assetLabelBehavior}
							helperText="When the labels of geofences should be shown."
							getOptionLabel={option => option.label}
							getOptionValue={option => option.value}
						/>

						<FormikSelect
							name="assetActiveBehavior"
							label="Asset active behaviour"
							form={formikProps}
							options={assetActiveInactiveBehavior}
							helperText="What should happen when an asset becomes active."
							getOptionLabel={option => option.label}
							getOptionValue={option => option.value}
							onChange={(value) => assetActiveAndInactiveValueChange(value, formikProps)}
						/>

						<FormikSelect
							name="assetInactiveBehavior"
							label="Asset inactive behaviour"
							form={formikProps}
							options={assetActiveInactiveBehavior}
							helperText="What should happen when an asset becomes inactive."
							getOptionLabel={option => option.label}
							getOptionValue={option => option.value}
							onChange={(value) => assetActiveAndInactiveValueChange(value, formikProps)}
						/>

						<FormikSelect
							name="hideAssetLatestEventThreshold"
							label="Asset last update threshold"
							form={formikProps}
							options={assetThresholdOptions}
							helperText="Hide markers for assets that haven't sent any updates recently."
							getOptionLabel={option => option.label}
							getOptionValue={option => option.value}
						/>

						<Field
							name="assetClusteringEnabled"
							label="Enable asset clustering"
							component={FormikCheckbox}
						/>
					</div>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={() => props.dialogCloseCallback()}
						variant="outlined"
						color="primary"
						disabled={formikProps.isSubmitting}
						text="Cancel"
					/>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						startIcon={<SaveIcon />}
						loading={formikProps.isSubmitting}
						disabled={formikProps.isSubmitting}
						onClick={() => formikProps.submitForm()}
						text="Save Settings"
					/>
				</DialogActions>
			</Dialog>
		</Form>}
	/>;
};
