import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddDigitalMatterDevice($input: AddDigitalMatterDeviceInput!) {
		addDigitalMatterDevice(input: $input) {
			digitalMatterDevice {
				id
				asset {
					id
				}
			}
		}
	}
`;
