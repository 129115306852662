import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikErrors } from 'formik';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import {
	Client as C,
} from 'src/services';

import { Button, FormikTextField, FormikCheckbox } from 'src/components';
import { IEnableFleetConfigurationField } from './editEnableFleetConfigurationFieldsComponent';
import { IOption, runFormValidation } from 'src/util';

const fieldTypes: IOption<string>[] = [
	{
		label: 'Text',
		value: C.EnableFleetFieldType.Text.toString(),
	}, {
		label: 'Number (Integer)',
		value: C.EnableFleetFieldType.NumberInteger.toString(),
	}, {
		label: 'Number (Decimal)',
		value: C.EnableFleetFieldType.NumberDecimal.toString(),
	}, {
		label: 'Checkbox',
		value: C.EnableFleetFieldType.Checkbox.toString(),
	}, {
		label: 'Dropdown',
		value: C.EnableFleetFieldType.Dropdown.toString(),
	}, {
		label: 'IP Address',
		value: C.EnableFleetFieldType.IpAddress.toString(),
	},
];

export interface AddEditFieldDialogBoxProps {
	title: string;
	field?: IEnableFleetConfigurationField;
	onClose?: () => void;
	onSubmit: (field: IEnableFleetConfigurationField) => void;
	configurationFields: IEnableFleetConfigurationField[];
	customizationProfileFields: C.IEnableFleetCustomizationProfileFieldDto[];
	buttonType: string;
	editFieldIndex?: number;
}

export interface IFormValues {
	enableFleetConfigurationFieldId?: string;
	reference?: string;
	label?: string;
	fieldType?: string;
	readOnly?: boolean;
	canBeBlank: boolean;
	stringMinChars?: number;
	stringMaxChars?: number;
	numberMinimum?: number;
	numberMaximum?: number;
	numberStep?: number;
	dropdownOptions?: string;
}

export const AddEditFieldDialogBox = observer((props: AddEditFieldDialogBoxProps) => {
	const initialFormValues: IFormValues = {
		enableFleetConfigurationFieldId: props.field && props.field.enableFleetConfigurationFieldId || undefined,
		reference: props.field && props.field.reference || undefined,
		label: props.field?.label || '',
		fieldType: props.field?.fieldType || '',
		readOnly: props.field?.readOnly || false,
		canBeBlank: props.field?.canBeBlank || false,
		stringMinChars: props.field?.stringMinChars || undefined,
		stringMaxChars: props.field?.stringMaxChars || undefined,
		numberMinimum: props.field?.numberMinimum || undefined,
		numberMaximum: props.field?.numberMaximum || undefined,
		numberStep: props.field && props.field.numberStep || undefined,
		dropdownOptions: props.field && props.field.dropdownOptions && props.field.dropdownOptions.join('\n') || '',
	};

	const closeDialog = () => {
		if (props.onClose)
			props.onClose();
	};

	const onSubmit = (values: IFormValues) => {
		if (values.fieldType === C.EnableFleetFieldType.Checkbox)
			values.canBeBlank = false;

		if (values.fieldType !== C.EnableFleetFieldType.Dropdown)
			values.dropdownOptions = undefined;

		if (values.fieldType !== C.EnableFleetFieldType.NumberInteger && values.fieldType !== C.EnableFleetFieldType.NumberDecimal) {
			values.numberMinimum = undefined;
			values.numberMaximum = undefined;
			values.numberStep = undefined;
		}

		if (values.fieldType !== C.EnableFleetFieldType.Text) {
			values.stringMinChars = undefined;
			values.stringMaxChars = undefined;
		}

		const field: IEnableFleetConfigurationField = {
			enableFleetConfigurationFieldId: props.field && props.field.enableFleetConfigurationFieldId,
			reference: values.reference!,
			label: values.label!,
			fieldType: values.fieldType!,
			readOnly: values.readOnly!,
			canBeBlank: values.canBeBlank,
			stringMinChars: values.stringMinChars,
			stringMaxChars: values.stringMaxChars,
			numberMinimum: values.numberMinimum,
			numberMaximum: values.numberMaximum,
			numberStep: values.numberStep === 0 ? undefined : values.numberStep,
			dropdownOptions: values.dropdownOptions && values.dropdownOptions.split(/\r?\n/) || undefined,
		};

		props.onSubmit(field);
	};
	
	const validateForm = (values: IFormValues, errors: FormikErrors<IFormValues>) => {
		if (!values.reference)
			errors.reference = 'You must select a field reference.';

		if (!values.label)
			errors.label = 'Label is required.';

		if (!values.fieldType)
			errors.fieldType = 'Field type is required.';
			
		// Clearing a number input leaves an empty string as the value...
		if (values.stringMinChars as any === '') values.stringMinChars = undefined;
		if (values.stringMaxChars as any === '') values.stringMaxChars = undefined;
		if (values.numberMinimum as any === '') values.numberMinimum = undefined;
		if (values.numberMaximum as any === '') values.numberMaximum = undefined;
		if (values.numberStep as any === '') values.numberStep = undefined;

		if (values.fieldType === C.EnableFleetFieldType.Text) {
			if (values.stringMinChars != null && values.stringMinChars < 0)
				errors.stringMinChars = 'Value must be greater than or equal to 0.';

			if (values.stringMaxChars != null) {
				if (values.stringMaxChars < 0)
					errors.stringMinChars = 'Value must be greater than or equal to 0.';
				else if (values.stringMinChars != null)
					errors.stringMaxChars = 'Value must be greater than or equal to minimum characters.';
			}
		}

		const integerField = values.fieldType === C.EnableFleetFieldType.NumberInteger;
		if (values.fieldType === C.EnableFleetFieldType.NumberInteger || values.fieldType === C.EnableFleetFieldType.NumberDecimal) {
			if (values.numberStep != null) {
				if (values.numberStep <= 0)
					errors.numberStep = 'Value must be greater than 0.';
				else if (integerField && !Number.isInteger(values.numberStep))
					errors.numberStep = 'Value must be an integer.';
			}

			if (values.numberMinimum == null && values.numberStep != null)
				errors.numberMinimum = 'Minimum must be specified if step is specified.';
			else if (values.numberMinimum != null && integerField && !Number.isInteger(values.numberMinimum))
				errors.numberMinimum = 'Value must be an integer.';

			if (values.numberMaximum != null) {
				if (values.numberMinimum != null && values.numberMaximum < values.numberMinimum)
					errors.numberMaximum = 'Value must be greater than or equal to minimum.';
				else if (integerField && !Number.isInteger(values.numberMaximum))
					errors.numberMaximum = 'Value must be an integer.';
			}
		}

		if (values.fieldType === C.EnableFleetFieldType.Dropdown) {
			if (!values.dropdownOptions)
				errors.dropdownOptions = 'You must enter at least one dropdown option.';
		}
	}

	const handleFieldTypeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, formikProps:  FormikProps<IFormValues>) => {
		formikProps.setFieldValue('fieldType', event.target.value);
		formikProps.setFieldTouched('fieldType');
	};

	const handleFieldReferenceChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, formikProps:  FormikProps<IFormValues>) => {
		const reference = props.customizationProfileFields.find(x => x.reference === event.target.value);

		formikProps.setFieldValue('reference', reference ? reference.reference : undefined);
		formikProps.setFieldTouched('reference');

		formikProps.setFieldValue('label', reference ? reference.enableFleetLabel : '');
		formikProps.setFieldTouched('label');

		switch (reference?.type) {
			case 'List':
				formikProps.setFieldValue('fieldType', C.EnableFleetFieldType.Dropdown.toString());
				formikProps.setFieldTouched('fieldType');
				importCustomizationProfileDropdownOptions(formikProps, reference.reference, true);
				break;

			case 'Single-Line Text':
			case 'Multi-Line Text':
				formikProps.setFieldValue('fieldType', C.EnableFleetFieldType.Text.toString());
				formikProps.setFieldTouched('fieldType');
				break;

			default:
				formikProps.setFieldValue('fieldType', '');
				formikProps.setFieldTouched('fieldType', false);
		}
	};

	const importCustomizationProfileDropdownOptions = (formikProps: FormikProps<IFormValues>, reference: string | undefined, overrideReplaceCheck: boolean = false) => {
		if (!reference)
			return;

		const customizationProfileField = props.customizationProfileFields.find(x => x.reference === reference);
		if (!customizationProfileField || !customizationProfileField.listOptions)
			return;

		if (!overrideReplaceCheck && !!formikProps.values.dropdownOptions) {
			if (!confirm('Are you sure you want to replace the current dropdown options with the dropdown options from the EnableFleet customization profile?'))
				return;
		}

		formikProps.setFieldValue('dropdownOptions', customizationProfileField.listOptions.join('\n'));
		formikProps.setFieldTouched('dropdownOptions');
	};

	const referenceOptions = props.customizationProfileFields
		.filter(fieldReference => {
			if (props.field && props.field.reference === fieldReference.reference)
				return true;

			return !props.configurationFields.find(field => field.reference === fieldReference.reference);
		})
		.sort((a, b) => a.reference.localeCompare(b.reference, undefined, { numeric: true }))
		.map(x => <option
			key={x.reference}
			value={x.reference}
		>
			{x.reference}
		</option>);

	return <Dialog
		open={true}
		onClose={closeDialog}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<IFormValues>) => <Form>
				<DialogTitle>{props.title}</DialogTitle>

				<DialogContent>
					<TextField
						select
						autoFocus={false}
						label="Field Reference"
						value={formikProps.values.reference}
						onChange={e => handleFieldReferenceChange(e, formikProps)}
						error={formikProps.touched.reference && !!formikProps.errors.reference}
						helperText={formikProps.touched.reference && formikProps.errors.reference}
						fullWidth
						placeholder="Select a reference..."
						SelectProps={{
							native: true,
						}}
						margin="normal"
						variant="filled"
					>
						<option key="" value=""></option>
						{referenceOptions}
					</TextField>

					<TextField
						select
						label="Field Type"
						value={formikProps.values.fieldType}
						onChange={e => handleFieldTypeChange(e, formikProps)}
						error={formikProps.touched.fieldType && !!formikProps.errors.fieldType}
						helperText={formikProps.touched.fieldType && formikProps.errors.fieldType}
						fullWidth
						SelectProps={{
							native: true,
						}}
						margin="normal"
						variant="filled"
					>
						<option key="" value=""></option>
						{fieldTypes.map(option => <option
							key={option.value}
							value={option.value}
						>
							{option.label}
						</option>)};
					</TextField>

					<Field
						margin="normal"
						name="label"
						label="Label"
						type="text"
						component={FormikTextField}
					/>

					<Field
						name="readOnly"
						label="Read-only"
						type="checkbox"
						component={FormikCheckbox}
					/>

					{formikProps.values.fieldType !== C.EnableFleetFieldType.Checkbox && <Field
						name="canBeBlank"
						label="Allow field to be left empty"
						type="checkbox"
						component={FormikCheckbox}
					/>}

					{formikProps.values.fieldType === C.EnableFleetFieldType.Text && <Field
						margin="dense"
						name="stringMinChars"
						label="Minimum characters"
						type="number"
						component={FormikTextField}
						inputProps={{
							min: 0,
						}}
						helperText="The minimum number of allowed characters."
					/>}

					{formikProps.values.fieldType === C.EnableFleetFieldType.Text && <Field
						margin="dense"
						name="stringMaxChars"
						label="Maximum characters"
						type="number"
						component={FormikTextField}
						inputProps={{
							min: 0,
						}}
						helperText="The maximum number of allowed characters."
					/>}

					{(formikProps.values.fieldType === C.EnableFleetFieldType.NumberInteger ||
						formikProps.values.fieldType === C.EnableFleetFieldType.NumberDecimal) && <Field
						margin="dense"
						name="numberMinimum"
						label="Minimum number"
						type="number"
						component={FormikTextField}
					/>}

					{(formikProps.values.fieldType === C.EnableFleetFieldType.NumberInteger ||
						formikProps.values.fieldType === C.EnableFleetFieldType.NumberDecimal) && <Field
						margin="dense"
						name="numberMaximum"
						label="Maximum number"
						type="number"
						component={FormikTextField}
					/>}

					{(formikProps.values.fieldType === C.EnableFleetFieldType.NumberInteger ||
						formikProps.values.fieldType === C.EnableFleetFieldType.NumberDecimal) && <Field
						margin="dense"
						name="numberStep"
						label="Step"
						type="number"
						component={FormikTextField}
						helperText="The interval between allowed numbers."
					/>}

					{formikProps.values.fieldType === C.EnableFleetFieldType.Dropdown && <>
						{props.customizationProfileFields.some(x => x.reference === formikProps.values.reference && x.listOptions) && <Button
							style={{ marginTop: '10px' }}
							variant="outlined"
							size="small"
							color="primary"
							text="Import/refresh EnableFleet dropdown options"
							onClick={() => importCustomizationProfileDropdownOptions(formikProps, formikProps.values.reference)}
						/>}

						<Field
							margin="dense"
							name="dropdownOptions"
							label="Dropdown Options"
							component={FormikTextField}
							multiline
							helperText="Specify each option on a new line."
							rowsMax={4}
							inputProps={{
								rows: 3,
							}}
						/>
					</>}
				</DialogContent>

				<DialogActions>
					<Button
						className="cancel-button"
						variant="outlined"
						color="primary"
						text="Cancel"
						onClick={closeDialog}
					/>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						text={props.buttonType}
					/>
				</DialogActions>
			</Form>}
		/>
	</Dialog>;
});
