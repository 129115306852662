import React from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { observer } from 'mobx-react';
import useAsyncEffect from 'use-async-effect';

import { useQueryAddDevice } from 'src/graphql/__generated__/queries/queryAddDevice';
import { apiNetworksToDeviceNetworks, IDeviceNetwork, ManageDeviceComponent, ManageDeviceFormValues } from './manageDeviceComponent';

import {
	Client as C,
	HistoryService,
	Service,
	SiteService,
	ToasterService,
	useCurrentUser,
	useIdentityType,
	useInjection,
} from 'src/services';

import { ErrorMessagePage, LoadingMessagePage } from 'src/components';

import { submitAddDevice } from './submitAddDevice';

export interface Props {
	addAssetView?: boolean;
}

export const AddDevice = observer((props: Props) => {
	const currentUser = useCurrentUser()!;
	const identityType = useIdentityType();
	const apolloClient = useInjection<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	const historyService = useInjection<HistoryService>(Service.History);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const siteService = useInjection<SiteService>(Service.Site);
	const [networks, setNetworks] = React.useState<IDeviceNetwork[] | null>(null);
	const [loading, setLoading] = React.useState(true);

	useAsyncEffect(async () => {
		if (networks === null) {
			const apiNetworks = await siteService.getAllSites();
			setNetworks(apiNetworksToDeviceNetworks(apiNetworks));
			setLoading(false);
		}
	}, []);

	const query = useQueryAddDevice({
		variables: {
			includeAssetTypes: !!props.addAssetView,
			includeDealers: identityType === C.IdentityType.SuperUser,
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		},
		skip: identityType !== C.IdentityType.SuperUser && identityType !== C.IdentityType.Dealer,
	});

	if (loading || query.loading)
		return <LoadingMessagePage />;

	if (query.error)
		return <ErrorMessagePage />;

	const submit = async (values: ManageDeviceFormValues) => {
		try {
			const device = await submitAddDevice(apolloClient, values, !!props.addAssetView, currentUser);
			toasterService.showSuccess(`Added ${props.addAssetView ? 'asset' : 'device'}.`);

			if (props.addAssetView)
				historyService.history.push(`/app/assets/${device!.asset!.id}/edit`);
			else
				historyService.history.push(`/app/devices/${device!.id}/edit`);
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to add asset.');
		}
	};

	return <ManageDeviceComponent
		addAssetView={props.addAssetView}
		assetTypes={query.data?.assetTypes || undefined}
		bluetoothBeaconSets={query.data?.bluetoothBeaconSets || undefined}
		dealers={query.data?.dealers || undefined}
		clients={query.data?.clients || undefined}
		networks={networks || undefined}
		submit={submit}
	/>;
});
