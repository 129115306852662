import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';

@injectable()
export class PermissionsService {
	constructor(
		@inject(Service.ApiClient) private _client: C.Client,
	) {
	}

	async fetchUserAddPermissions(identityId: string): Promise<C.IUserAddPermissionsResponse> {
		return await this._client.getIdentityAddUserPermissionsById(identityId);
	}
}
