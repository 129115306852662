import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Button } from 'src/components';

export interface CustomActionDialogBoxProps {
	className?: string;
	title: string;
	actionButton?: JSX.Element;
	cancelText?: string;
	dialogCloseCallback?: () => void;
	secondaryButton?: { text: string, action?: () => void };
	loading?: boolean;
	fullWidth?: boolean;
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	children?: React.ReactNode;
}

export const CustomActionDialogBox = (props: CustomActionDialogBoxProps) => {
	const closeDialog = () => {
		if (props.dialogCloseCallback)
			props.dialogCloseCallback();
	};

	return <Dialog
		open={true}
		onClose={closeDialog}
		className={props.className}
		fullScreen={window.innerWidth < 500}
		fullWidth={props.fullWidth}
		maxWidth={props.maxWidth}
	>
		<DialogTitle>{props.title}</DialogTitle>

		<DialogContent>
			{props.children}
		</DialogContent>

		<DialogActions>
			<Button
				type="button"
				variant="outlined"
				color="primary"
				text={props.secondaryButton?.text ?? (props.cancelText || 'Cancel')}
				onClick={props.secondaryButton?.action ?? closeDialog}
				disabled={props.loading}
			/>

			{props.actionButton}
		</DialogActions>
	</Dialog>;
};
