import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddEroadDevice($input: AddEroadDeviceInput!) {
		addEroadDevice(input: $input) {
			eroadDevice {
				id
				asset {
					id
				}
			}
		}
	}
`;
