import React from 'react';
import { css, cx } from '@emotion/css';
import classNames from 'classnames';
import HomeIcon from '@material-ui/icons/Home';

import { MapViewMode } from './mapViewMode';
import { MapboxMapStyle } from './mapboxManager';

interface IMapActionButtonsProps {
	mapViewMode: MapViewMode;
	mapStyle: MapboxMapStyle;
	toggleMapStyle: (selectedStyle?: MapboxMapStyle) => void;
	hasSidebar?: boolean;
	moveMapToHome?: () => void;
}

const mapActionsStyle = css`
	display: flex;
	flex-direction: row;
	position: absolute;
	bottom: 15px;
	align-items: flex-end;
	margin-left: 15px;
	z-index: -1;
`;

const hasSidebarStyle = css`
	margin-left: -20px;
`;

const homeButtonStyle = css`
	height: 50px;
	width: 50px;
	border: 0;
	margin-left: 10px;
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2) !important;
	background-color: white;
	font-size: 12px;
	pointer-events: initial;
	cursor: pointer;
	padding: 5px;
`;

export const MapActionButtons = (props: IMapActionButtonsProps) => {
	if (props.mapViewMode !== MapViewMode.World)
		return null;

	return <div className={cx(mapActionsStyle, { [hasSidebarStyle]: props.hasSidebar })}>
		<button
			className={classNames('current-map__map-style-toggle', { 'current-map__map-style-toggle--map': props.mapStyle === MapboxMapStyle.Satellite })}
			type="button"
			title="Change map style"
			onClick={() => props.toggleMapStyle()}
		>
			<span className="current-map__map-style-toggle__label">{props.mapStyle === MapboxMapStyle.Streets ? 'Satellite' : 'Map'}</span>
		</button>

		{props.moveMapToHome && <button
			className={homeButtonStyle}
			type="button"
			onClick={props.moveMapToHome}
		>
			<HomeIcon />
		</button>}
	</div>;
};
