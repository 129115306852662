import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { red } from '@material-ui/core/colors';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Button } from './button';
import { CustomActionDialogBox } from './customActionDialogBox';
import { Service, ToasterService, useInjection } from 'src/services';

import { useMutationDeactivateDevices } from 'src/graphql/__generated__/mutations/mutationDeactivateDevices';

interface Props {
	deviceIds: string[];
	close: () => void;
	onSuccess?: () => Promise<any>;
}

export const DeactivateDevicesDialog = observer((props: Props) => {
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [acknowledgedWarning, setAcknowledgedWarning] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [deactivateDevices] = useMutationDeactivateDevices();

	const submit = async () => {
		setLoading(true);

		try {
			await deactivateDevices({
				variables: {
					input: {
						ids: props.deviceIds,
					},
				},
			});

			props.onSuccess && await props.onSuccess();
			props.close();
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to deactivate device(s).');
			setLoading(false);
		}
	};

	return <CustomActionDialogBox
		title={props.deviceIds.length === 1 ? `Deactivate Device?` : `Deactivate ${props.deviceIds.length} Devices?`}
		actionButton={<Button
			variant="contained"
			color={red}
			text="Deactivate"
			onClick={submit}
			disabled={!acknowledgedWarning}
			loading={loading}
		/>}
		dialogCloseCallback={props.close}
	>
		<DialogContentText>
			If deactivated:
		</DialogContentText>

		<DialogContentText component="ul">
			<li>Any physical device(s) (e.g. mobile phones) linked to the selected device(s) will be unlinked.</li>
			<li>The selected device(s) will not be able to record any new information <strong>(including locations and emergencies)</strong>.</li>
		</DialogContentText>

		<FormControlLabel
			control={<Checkbox
				onChange={(_, checked) => setAcknowledgedWarning(checked)}
				checked={acknowledgedWarning}
				color="primary"
				disabled={loading}
			/>}
			label="I acknowledge that I have read and understand the above information."
		/>
	</CustomActionDialogBox>;
});
