import { css } from '@emotion/css';

export const mapContainerStyle = css`
	flex: 1 1 100%;
	position: relative;
`;

export const mapStyle = css`
	position: absolute !important;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
`;

export const mapStyleWindowed = css`
	width: 100%;
	height: 800px;
`;

export const mapDraggableMarker = css`
	width: 32px;
	height: 32px;
	background-size: contain;
	text-align: center;
	color: white;
`;

export const fuelChartContainer = css`
	height: min(40%, 400px);
	overflow: hidden;
`;
