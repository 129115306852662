import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddTaitRadioDevice($input: AddTaitRadioDeviceInput!) {
		addTaitRadioDevice(input: $input) {
			taitRadioDevice {
				id
				asset {
					id
				}
			}
		}
	}
`;
