import { first, find, some } from 'iter-tools';
import mapboxgl from 'mapbox-gl';

import {
	Client as C,
} from 'src/services';

import { LiveMapAssetState } from './liveMapAssetState';

interface IGridLocation {
	row: number;
	column: number;
	key: string;
}

const gridKey = (column: number, row: number) => `${column},${row}`;

export interface IGridCell {
	/** The grid location of this cell. */
	gridLocation: IGridLocation;

	/** Assets that are actually in this cell. */
	assets: Set<ILiveMapGridAsset>;

	/** If the assets from this grid cell have been clustered into another cell,
	 * the grid location of that cell. */
	clusterGridLocation?: IGridLocation;

	/** Assets that have a marker in this cell.
	 * The assets may either be actually in this cell, or have been clustered into this cell.
	 *
	 * Important: An asset can remain in this set after a cluster is dissolved, even if the
	 * asset wouldn't normally sit in this cell. */
	markerAssets: Set<ILiveMapGridAsset>;

	/* If this cell is currently represented by a cluster marker. */
	isCluster: boolean;
}

interface ILiveMapGridAsset {
	/* The relevant asset state. */
	assetState: LiveMapAssetState;

	/* The grid cell that contains this asset. */
	gridCell?: IGridCell;

	/** The grid cell that contains the marker for this asset.
	 *
	 * May or may not be a single asset marker.
	 * May be the same as the gridCell property. */
	markerGridCell?: IGridCell;
}

export enum MarkerChangeType {
	AddAssetMarker,
	UpdateAssetMarker,
	RemoveAssetMarker,
	AddClusterMarker,
	UpdateClusterMarker,
	RemoveClusterMarker,
}

export class AssetMarkerChange {
	type: MarkerChangeType.AddAssetMarker | MarkerChangeType.UpdateAssetMarker | MarkerChangeType.RemoveAssetMarker;
	assetId: string;
}

export class ClusterMarkerChange {
	type: MarkerChangeType.AddClusterMarker | MarkerChangeType.RemoveClusterMarker;
	gridKey: string;
}

export class UpdateClusterMarkerChange {
	type: MarkerChangeType.UpdateClusterMarker;
	gridKey: string;
	assetsChanged: boolean;
}

export type MarkerChange = AssetMarkerChange | ClusterMarkerChange | UpdateClusterMarkerChange;

export class MarkerChanges {
	changes: MarkerChange[] = [];

	addAssetMarker = (assetId: string) => this.changes.push({ type: MarkerChangeType.AddAssetMarker, assetId });
	updateAssetMarker = (assetId: string) => this.changes.push({ type: MarkerChangeType.UpdateAssetMarker, assetId });
	removeAssetMarker = (assetId: string) => this.changes.push({ type: MarkerChangeType.RemoveAssetMarker, assetId });

	addClusterMarker = (gridKey: string) => this.changes.push({ type: MarkerChangeType.AddClusterMarker, gridKey });
	updateClusterMarker = (gridKey: string, assetsChanged: boolean) => this.changes.push({ type: MarkerChangeType.UpdateClusterMarker, gridKey, assetsChanged });
	removeClusterMarker = (gridKey: string) => this.changes.push({ type: MarkerChangeType.RemoveClusterMarker, gridKey });
}

export class LiveMapGrid {
	private _clusteringEnabled: boolean;
	private _getLocation: (assetLocation: C.IAssetLocationDto) => { lng: number; lat: number; } | undefined;
	private _project: (lnglat: mapboxgl.LngLatLike) => mapboxgl.Point;

	private _mapGrid = new Map<string, IGridCell>();

	/** All assets (asset ID -> live map grid asset). */
	private _allAssets = new Map<string, ILiveMapGridAsset>();

	private _zeroZeroGridDetails: mapboxgl.Point | undefined = undefined;

	private _gridSize = 31;

	constructor(
		clusteringEnabled: boolean,
		getLocation: (assetLocation: C.IAssetLocationDto) => { lng: number; lat: number; } | undefined,
		project: (lnglat: mapboxgl.LngLatLike) => mapboxgl.Point,
		gridSize: number = 31
	) {
		this._clusteringEnabled = clusteringEnabled;
		this._getLocation = getLocation;
		this._project = project;
		this._gridSize = gridSize;
	}

	initialize(assetStates: LiveMapAssetState[]): MarkerChanges {
		this.mapMoved();

		for (const assetState of assetStates)
			this.handleAssetEventUpdate(assetState);

		const markerChanges = new MarkerChanges();

		for (const gridCell of this._mapGrid.values()) {
			if (gridCell.markerAssets.size > 0) {
				// If clustering is enabled and there are multiple assets in this cell,
				// add the cell as a cluster. Otherwise, add each asset individually.
				const cluster = this._clusteringEnabled && gridCell.markerAssets.size > 1;
				if (cluster) {
					markerChanges.addClusterMarker(gridCell.gridLocation.key);
				} else {
					for (const asset of gridCell.markerAssets.values())
						markerChanges.addAssetMarker(asset.assetState.asset.assetId);
				}
			}
		}

		return markerChanges;
	}

	mapMoved() {
		this._zeroZeroGridDetails = this._project([ 0, 0 ]);
	}

	public getGridCell(gridLocation: string) {
		return this._mapGrid.get(gridLocation);
	}

	handleAssetEventUpdate(assetState: LiveMapAssetState) {
		let asset = this._allAssets.get(assetState.asset.assetId);
		if (!asset) {
			asset = {
				assetState: assetState,
			};

			this._allAssets.set(asset.assetState.asset.assetId, asset);
		}

		const markerChanges = new MarkerChanges();

		if (assetState.isAgedOff || assetState.hiddenBySearchOrFilter) {

			const shouldHaveMarker = assetState.isSelected || (assetState.hasEmergency && !assetState.hiddenBySearchOrFilter);
			if (!shouldHaveMarker) {
				if (asset.gridCell) {
					// There is a marker for this aged off asset, but there
					// shouldn't be, so we need to remove the marker, or remove the
					// asset from the cluster that it is currently in.
					this.removeFromGrid(asset, markerChanges);
				} else {
					// There is no marker for this aged off asset, and there shouldn't
					// be, so we don't need to do anything.
				}

				return markerChanges;
			}

			// If the aged off asset should have a marker, we can treat it as a normal asset.
		}

		const assetLocation = assetState.assetLocation;
		const newAssetGridLocation = assetLocation && this.getGridLocationFromAssetLocation(assetLocation);
		if (!assetLocation || !newAssetGridLocation)
			return markerChanges;

		if (asset.gridCell) {
			const sameGridCell = asset.gridCell.gridLocation.key === newAssetGridLocation.key;

			if (sameGridCell) {
				if (asset.markerGridCell!.markerAssets.size > 1) {
					markerChanges.updateClusterMarker(asset.markerGridCell!.gridLocation.key, false);
				} else {
					markerChanges.updateAssetMarker(asset.assetState.asset.assetId);
				}
			} else {
				this.moveGridCell(asset, newAssetGridLocation, markerChanges);
			}
		} else {
			this.addToGrid(asset, newAssetGridLocation, markerChanges);
		}

		return markerChanges;
	}

	/**
	 * Add a new asset to the grid.
	 * Only be used for assets which are not currently on the grid.
	 */
	private addToGrid(asset: ILiveMapGridAsset, assetGridLocation: IGridLocation, markerChanges: MarkerChanges) {
		if (asset.gridCell || asset.markerGridCell)
			console.error(`Grid info already exists for asset: ${asset.assetState.asset.assetId}`);

		const gridCell = this.addToGridCell(asset, assetGridLocation);

		let markerGridCell = gridCell;

		if (this._clusteringEnabled) {
			// If there aren't already assets clustered into the current grid cell,
			// try to look for a neighbour cell that already has an asset/cluster.
			// If there is one, then this asset will be added to the cluster assets for that cell instead.
			if (gridCell.markerAssets.size === 0) {
				const neighbourGridCellToUse = this.findClusterCell(gridCell, false);
				if (neighbourGridCellToUse)
					markerGridCell = neighbourGridCellToUse;
			}

			if (gridCell !== markerGridCell && gridCell.clusterGridLocation?.key !== markerGridCell.gridLocation.key)
				gridCell.clusterGridLocation = markerGridCell.gridLocation;
		}

		asset.markerGridCell = markerGridCell;
		markerGridCell.markerAssets.add(asset);

		this.markerGridCellAssetAdded(markerGridCell, asset, markerChanges);
	}

	/**
	 * Add an asset to the grid cell at the specified location.
	 * If a grid cell does not currently exist at that location, one will be created.
	 */
	private addToGridCell(asset: ILiveMapGridAsset, assetGridLocation: IGridLocation): IGridCell {
		let gridCell = this._mapGrid.get(assetGridLocation.key);

		if (!gridCell) {
			gridCell = {
				gridLocation: assetGridLocation,
				assets: new Set<ILiveMapGridAsset>(),
				markerAssets: new Set<ILiveMapGridAsset>(),
				isCluster: false,
			};

			this._mapGrid.set(gridCell.gridLocation.key, gridCell);
		}

		gridCell.assets.add(asset);
		asset.gridCell = gridCell;

		return gridCell;
	}

	/**
	 * For the specified cell, find a nearby cell that assets can be clustered into.
	 */
	private findClusterCell(gridCell: IGridCell, includeOriginCell: boolean, ignoreAssetId?: string): IGridCell | undefined {
		for (let row = gridCell.gridLocation.row - 1; row <= gridCell.gridLocation.row + 1; row++) {
			for (let column = gridCell.gridLocation.column - 1; column <= gridCell.gridLocation.column + 1; column++) {
				// Only include the origin cell if specified.
				if (row === gridCell.gridLocation.row && column === gridCell.gridLocation.column && !includeOriginCell)
					continue;

				const neighbourCell = this._mapGrid.get(gridKey(column, row));
				if (!neighbourCell)
					continue;

				if (neighbourCell.markerAssets.size > 0) {
					if (!ignoreAssetId)
						return neighbourCell;

					if (some(x => x.assetState.asset.assetId !== ignoreAssetId, neighbourCell.markerAssets.values()))
						return neighbourCell;
				}
			}
		}

		return undefined;
	}

	/**
	 * Calculate the grid location for an asset location.
	 */
	private getGridLocationFromAssetLocation(assetLocation: C.IAssetLocationDto): IGridLocation | undefined {
		const location = this._getLocation(assetLocation!);
		if (!location)
			return undefined;

		const projectedMapMarker = this._project(location);

		const row = Math.floor((projectedMapMarker.y - this._zeroZeroGridDetails!.y) / this._gridSize);
		const column = Math.floor((projectedMapMarker.x - this._zeroZeroGridDetails!.x) / this._gridSize);

		return {
			key: gridKey(column, row),
			row: row,
			column: column,
		};
	}

	/**
	 * Move an asset into the grid cell at the specified grid location.
	 */
	private moveGridCell(asset: ILiveMapGridAsset, newAssetGridLocation: IGridLocation, markerChanges: MarkerChanges) {
		if (!asset.gridCell) {
			console.error(`Attempting to move asset, but no grid cell is currently set for asset: ${asset.assetState.asset.assetId}`);
			return;
		}

		if (!asset.markerGridCell) {
			console.error(`Attempting to move asset, but no cluster grid cell is currently set for asset: ${asset.assetState.asset.assetId}`);
			return;
		}

		const oldGridCell = asset.gridCell;
		const oldMarkerGridCell = asset.markerGridCell;

		oldGridCell.assets.delete(asset);
		oldMarkerGridCell.markerAssets.delete(asset);

		const newGridCell = this.addToGridCell(asset, newAssetGridLocation);

		if (oldGridCell.assets.size === 0 && oldGridCell.markerAssets.size === 0)
			this._mapGrid.delete(oldGridCell.gridLocation.key);

		if (this._clusteringEnabled) {
			const newClusterCell = this.findClusterCell(newGridCell, true, asset.assetState.asset.assetId);

			if (newClusterCell && newClusterCell !== newGridCell && newGridCell.clusterGridLocation?.key !== newClusterCell.gridLocation.key)
				newGridCell.clusterGridLocation = newClusterCell.gridLocation;

			if (oldMarkerGridCell && oldMarkerGridCell.isCluster) {
				// Asset is changing grid cell, and is currently clustered.

				if (newClusterCell) {
					// The asset's new grid location has a nearby cell with an asset in it, so we need to create or join a cluster.

					if (newClusterCell === asset.markerGridCell) {
						// The new cluster cell is the same as the current one.
						// Move the asset's grid location but keep its clustered location.

						asset.markerGridCell = newClusterCell;
						newClusterCell.markerAssets.add(asset);
						markerChanges.updateClusterMarker(newClusterCell.gridLocation.key, false);
					} else {
						// The new cluster cell is different to the current one.
						// Remove the asset from its current cluster and move it to the new grid location.

						asset.markerGridCell = newClusterCell;
						newClusterCell.markerAssets.add(asset);
						this.markerGridCellAssetRemoved(oldMarkerGridCell, markerChanges);
						this.markerGridCellAssetAdded(newClusterCell, asset, markerChanges);
					}
				} else {
					// The asset's new grid location doesn't have any nearby assets, we need to make a single asset marker.

					asset.markerGridCell = newGridCell;
					newGridCell.markerAssets.add(asset);
					markerChanges.addAssetMarker(asset.assetState.asset.assetId);
					this.markerGridCellAssetRemoved(oldMarkerGridCell, markerChanges);
				}
			} else {
				// Asset is changing grid cell, and is not currently clustered.

				if (oldMarkerGridCell && newGridCell !== oldMarkerGridCell)
					this.markerGridCellAssetRemoved(oldMarkerGridCell, markerChanges);

				if (newClusterCell) {
					// The asset's new grid location has a nearby cell with an asset in it, so we need to create or join a cluster.

					asset.markerGridCell = newClusterCell;
					newClusterCell.markerAssets.add(asset);
					markerChanges.removeAssetMarker(asset.assetState.asset.assetId);
					this.markerGridCellAssetAdded(newClusterCell, asset, markerChanges);
				} else {
					// Still not clustered.
					// Move the single asset marker to the new cell.

					asset.markerGridCell = newGridCell;
					newGridCell.markerAssets.add(asset);
					markerChanges.updateAssetMarker(asset.assetState.asset.assetId);
				}
			}
		} else {
			// Asset is changing grid cell, and clustering is disabled.
			// Move the marker to the new cell.

			asset.markerGridCell = newGridCell;
			newGridCell.markerAssets.add(asset);
			markerChanges.updateAssetMarker(asset.assetState.asset.assetId);
		}
	}

	/**
	 * Remove an asset from the grid.
	 */
	private removeFromGrid(asset: ILiveMapGridAsset, markerChanges: MarkerChanges) {
		if (!asset.gridCell || !asset.markerGridCell) {
			console.error(`Attempted to remove asset from grid with missing grid cell information: ${asset.assetState.asset.assetId}`);
			return;
		}

		asset.gridCell.assets.delete(asset);
		asset.markerGridCell.markerAssets.delete(asset);

		if (!this._clusteringEnabled || asset.markerGridCell.markerAssets.size === 0)
			markerChanges.removeAssetMarker(asset.assetState.asset.assetId);

		this.markerGridCellAssetRemoved(asset.markerGridCell!, markerChanges);

		if (asset.gridCell.assets.size === 0 && asset.gridCell.markerAssets.size === 0)
			this._mapGrid.delete(asset.gridCell.gridLocation.key);

		asset.gridCell = undefined;
		asset.markerGridCell = undefined;
	}

	/**
	 * Make the requires changes to a grid cell after an asset has been added.
	 */
	private markerGridCellAssetAdded(gridCell: IGridCell, asset: ILiveMapGridAsset, markerChanges: MarkerChanges) {
		if (!this._clusteringEnabled || gridCell.markerAssets.size === 1) {
			markerChanges.addAssetMarker(asset.assetState.asset.assetId);
		} else if (gridCell.markerAssets.size === 2) {
			const otherAsset = find(x => x !== asset, gridCell.markerAssets.values());
			if (!otherAsset) {
				console.error(`Other asset is missing for grid cell: ${gridCell.gridLocation.key}`);
				return;
			}

			gridCell.isCluster = true;

			markerChanges.removeAssetMarker(otherAsset.assetState.asset.assetId);
			markerChanges.addClusterMarker(gridCell.gridLocation.key);
		} else {
			markerChanges.updateClusterMarker(gridCell.gridLocation.key, true);
		}
	}

	/**
	 * Make the required changes to a grid cell after an asset has been removed.
	 *
	 * Note: This method does not remove any single asset marker.
	 * If required, that should be done before this method is called.
	 */
	private markerGridCellAssetRemoved(gridCell: IGridCell, markerChanges: MarkerChanges) {
		if (!this._clusteringEnabled) {
			if (gridCell.assets.size === 0 && gridCell.markerAssets.size === 0)
				this._mapGrid.delete(gridCell.gridLocation.key);

			return;
		}

		if (gridCell.markerAssets.size === 0) {
			// Only delete the grid cell if there are no other assets using it.
			if (gridCell.assets.size === 0) {
				this._mapGrid.delete(gridCell.gridLocation.key);
			}
		} else if (gridCell.markerAssets.size === 1) {
			markerChanges.removeClusterMarker(gridCell.gridLocation.key);

			gridCell.isCluster = false;

			const remainingAsset = first(gridCell.markerAssets.values());
			if (!remainingAsset) {
				console.error(`Remaining asset is missing for grid cell: ${gridCell.gridLocation.key}`);
				return;
			}

			markerChanges.addAssetMarker(remainingAsset.assetState.asset.assetId);
		} else {
			markerChanges.updateClusterMarker(gridCell.gridLocation.key, true);
		}
	}
}
