import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryReport($reportId: UUID!) {
		reportById(reportId: $reportId) {
			id
			name
			type
			requestedAt
			processingStatus
			parameters {
				id
				startTimestamp
				endTimestamp
				minimumTravelSpeed
				minimumStopTime
				assets {
					id
					name
				}
				assetGroups {
					id
					name
				}
				startDate
				endDate
				startTime
				timestamp
				timeZone
				billingCodes
				addressBookEntries {
					id
					userName
					userIdentifier
				}
				users {
					id
					name
				}
				userGroups {
					id
					name
				}
			}
		}
	}
`;
