import React from 'react';
import { observer } from 'mobx-react';
import { bind } from 'bind-decorator';
import { QueryResult, Query, MaterialTableProps } from '@material-table/core';
import { flatMap, omitBy } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';
import { css } from '@emotion/css';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';

import { DeviceType } from 'src/../__generated__/globalTypes';
import {
	executeQueryCallRecordingsOptions,
	QueryCallRecordingsOptions_assets,
	QueryCallRecordingsOptions_assets_devices,
	QueryCallRecordingsOptions_assets_devices_MobilePhoneDevice,
	QueryCallRecordingsOptions_assets_devices_TaitRadioDevice,
	QueryCallRecordingsOptions_callGroups,
	QueryCallRecordingsOptions_networks
} from 'src/graphql/__generated__/queries/queryCallRecordingsOptions';

import { FixedWidthPage, MaterialTable, MessagePage, Button, DateTimeRangePicker, DateTimeRange, CallPlayingDialog, CustomAutocomplete } from 'src/components';
import { IOption } from 'src/util';
import { shortDateShortTimeFormat } from 'src/util/dateTimeFormats';
import { calculateAndFormatLongDuration } from 'src/util/durationFormats';

import {
	Container,
	AuthenticationService,
	Client as C,
	CallService,
	Service,
} from 'src/services';

import './index.scss';

const actionButtonCss = css`
	display: flex;
	justify-content: center;

	> :not(:last-child) {
		margin-right: 5px;
	}
`;

const downloadCallsButtonStyle = css`
	display: flex;
	flex-direction: column;
	padding-top: 5px;

	button {
		align-self: flex-end;
		width: fit-content;
	}

	.max-calls-message {
		color: red;
	}
`;

const callPartyIconStyle = css`
	color: gray;
	margin-right: 5px;
	
	svg {
		font-size: 1.2rem;
		margin-bottom: -4px;
	}
`;

enum NetworkDeviceIdType {
	ChatterPttUserId = 'Chatter PTT User ID',
	RadioId = 'Radio ID',
	SelcallId = 'Selcall ID',
}

interface INetworkDeviceIdAndType {
	identifier: string;
	type: NetworkDeviceIdType;
}

type DeviceWithNetwork = QueryCallRecordingsOptions_assets_devices_MobilePhoneDevice | QueryCallRecordingsOptions_assets_devices_TaitRadioDevice;

export function isDeviceWithNetwork(device: QueryCallRecordingsOptions_assets_devices): device is DeviceWithNetwork {
	return device.deviceType === DeviceType.MOBILE_PHONE || device.deviceType === DeviceType.TAIT_RADIO;
}

interface State {
	callGroups: Map<string, QueryCallRecordingsOptions_callGroups>;
	assets: Map<string, QueryCallRecordingsOptions_assets>;
	siteOptions: IOption<string>[];
	callGroupOptions: IOption<string>[];
	assetOptions: IOption<string>[];
	networkDeviceIdOptions: INetworkDeviceIdAndType[];
	loading: boolean;
	playingCall: C.ICallDto | null;
	siteId?: string;
	callGroupId?: string;
	assetId?: string;
	networkDeviceId?: string;
	dateTimeRange: DateTimeRange;
	pendingFiltersSiteId?: string;
	pendingFiltersCallGroupId?: string;
	pendingFiltersAssetId?: string;
	pendingFiltersDateTimeRange: DateTimeRange;
	pendingFiltersNetworkDeviceId?: string;
}

@observer
export class CallsIndex extends React.Component<never, State> {
	private _authenticationService = Container.get<AuthenticationService>(Service.Authentication);
	private _apolloClient = Container.get<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	private _callService = Container.get<CallService>(Service.Call);

	private _tableRef: React.RefObject<unknown>;

	private allAssets: QueryCallRecordingsOptions_assets[];
	private allDevices: QueryCallRecordingsOptions_assets_devices[];
	private allNetworks: QueryCallRecordingsOptions_networks[] | null;
	private allCallGroups: QueryCallRecordingsOptions_callGroups[];
	private allCalls: C.ICallDto[];

	constructor(props: never) {
		super(props);

		this._tableRef = React.createRef<unknown>();

		const now = moment.tz(this._authenticationService.currentAuth.user.timeZone).startOf('minute');
		const dateTimeRange = {
			start: now.clone().subtract(7, 'days'),
			end: now.clone().endOf('minute'),
		};

		this.state = {
			callGroups: new Map<string, QueryCallRecordingsOptions_callGroups>(),
			assets: new Map<string, QueryCallRecordingsOptions_assets>(),
			siteOptions: [],
			callGroupOptions: [],
			assetOptions: [],
			networkDeviceIdOptions: [],
			playingCall: null,
			loading: true,
			dateTimeRange,
			pendingFiltersDateTimeRange: dateTimeRange,
		};
	}

	async componentDidMount() {
		const callRecordingOptionsQuery = await executeQueryCallRecordingsOptions(this._apolloClient, { includeNetwork: this._authenticationService.currentAuth.user.identity.type !== C.IdentityType.Client });
		if (callRecordingOptionsQuery.error || !callRecordingOptionsQuery.data || !callRecordingOptionsQuery.data.assets || !callRecordingOptionsQuery.data.callGroups)
			throw 'Failed to load.';

		this.allAssets = callRecordingOptionsQuery.data.assets;
		this.allDevices = flatMap(callRecordingOptionsQuery.data.assets, x => x.devices == null ? [] : x.devices);

		const assetMap = new Map<string, QueryCallRecordingsOptions_assets>();
		for (const asset of this.allAssets)
			assetMap.set(asset.id, asset);

		const assetOptions = this.allAssets
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
			.map(asset => ({ label: asset.name, value: asset.id }));

		let networkOptions: IOption<string>[] = [];
		if (this._authenticationService.currentAuth.user.identity.type !== C.IdentityType.Client) {
			this.allNetworks = callRecordingOptionsQuery.data.networks;

			if (this.allNetworks)
				networkOptions = this.allNetworks.map(site => ({ label: site.name, value: site.id }));
		}

		this.allCallGroups = callRecordingOptionsQuery.data.callGroups;

		const callGroupsMap = new Map<string, QueryCallRecordingsOptions_callGroups>();
		for (const callGroup of this.allCallGroups)
			callGroupsMap.set(callGroup.id, callGroup);

		const callGroupsOptions = this.allCallGroups
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
			.map(callGroup => ({ label: callGroup.name, value: callGroup.id }));

		this.setState({
			assets: assetMap,
			callGroups: callGroupsMap,
			siteOptions: networkOptions,
			callGroupOptions: callGroupsOptions,
			networkDeviceIdOptions: this.getDeviceIdentifierOptions(),
			assetOptions,
			loading: false,
		});
	}

	@bind
	private async queryData(query: Query<object>): Promise<QueryResult<object>> {
		const response = await this._callService.listCalls(query.page * query.pageSize, query.pageSize, this.state.dateTimeRange.start, this.state.dateTimeRange.end, this.state.assetId, this.state.callGroupId, this.state.siteId, this.state.networkDeviceId);

		this.allCalls = response.calls;
		return {
			data: response.calls,
			page: query.page,
			totalCount: response.pagination.totalCount,
		};
	}

	@bind
	private AddDeviceIdentifierIfUnique(deviceIdentifierOptions: INetworkDeviceIdAndType[], identifier: string | null, type: NetworkDeviceIdType) {
		// This option already exists, don't add it.
		if (identifier == null || deviceIdentifierOptions.some(x => x.identifier == identifier && x.type == type))
			return;

		deviceIdentifierOptions.push({identifier: identifier, type: type});
	}

	@bind
	private getDeviceIdentifierOptions(siteId?: string) {
		let deviceIdentifierOptions: INetworkDeviceIdAndType[] = [];

		for (const device of this.allDevices) {
			if (!isDeviceWithNetwork(device))
				continue;

			// If site is provided, only return devices on that specific site.
			if (siteId && device.network?.id !== siteId)
					continue;

			switch (device.deviceType) {
				case DeviceType.TAIT_RADIO:
					const taitDevice = device as QueryCallRecordingsOptions_assets_devices_TaitRadioDevice;
					this.AddDeviceIdentifierIfUnique(deviceIdentifierOptions, taitDevice.selcallId, NetworkDeviceIdType.SelcallId);
					this.AddDeviceIdentifierIfUnique(deviceIdentifierOptions, taitDevice.radioId, NetworkDeviceIdType.RadioId);
					break;
				case DeviceType.MOBILE_PHONE:
					const mobileDevice = device as QueryCallRecordingsOptions_assets_devices_MobilePhoneDevice;
					this.AddDeviceIdentifierIfUnique(deviceIdentifierOptions, mobileDevice.chatterPttUserId, NetworkDeviceIdType.ChatterPttUserId);
					break;
			}
		}
		// Sort by type of identifier and then by the value for that identifier.
		deviceIdentifierOptions = deviceIdentifierOptions.sort((a, b) => (a.type + a.identifier).localeCompare(b.type + b.identifier));

		return deviceIdentifierOptions;
	}

	@bind
	private async openCallPlayingDialog(call: C.ICallDto) {
		// Can't start playback if the audio URL is missing.
		if (!call.audioUrl)
			return;

		this.setState({ playingCall: call });
	}

	@bind
	private onCloseCallPlayingDialog(): void {
		if (this.state.playingCall)
			this.setState({ playingCall: null });
	}

	@bind
	private onCallPlayingDialogPreviousOrNextCall(nextCall: boolean): void {
		if (!this.state.playingCall)
			return;

		const indexOfCurrentCall = this.allCalls.findIndex(x => x.callId === this.state.playingCall!.callId);

		if (nextCall && indexOfCurrentCall !== (this.allCalls.length - 1)) {
			this.setState({ playingCall: this.allCalls[indexOfCurrentCall + 1] });
		} else if (!nextCall && indexOfCurrentCall !== 0) {
			this.setState({ playingCall: this.allCalls[indexOfCurrentCall - 1] });
		} else {
			this.onCloseCallPlayingDialog();
		}
	}

	@bind
	private applyFilters() {
		this.setState({
			siteId: this.state.pendingFiltersSiteId,
			callGroupId: this.state.pendingFiltersCallGroupId,
			assetId: this.state.pendingFiltersAssetId,
			dateTimeRange: this.state.pendingFiltersDateTimeRange,
			networkDeviceId: this.state.pendingFiltersNetworkDeviceId,
			playingCall: null,
		}, () => (this._tableRef.current as any)?.onQueryChange());
	}

	@bind
	private onChangeDateTimeRange(dateTimeRange: DateTimeRange) {
		this.setState({
			pendingFiltersDateTimeRange: dateTimeRange,
		});
	}

	@bind
	private onChangeSelectedAsset(selected: IOption<string> | null) {
		this.setState({
			pendingFiltersCallGroupId: undefined,
			pendingFiltersNetworkDeviceId: undefined,
			pendingFiltersAssetId: selected?.value,
		});
	}

	@bind
	private onChangeSelectedCallGroup(selected: IOption<string> | null) {
		this.setState({
			pendingFiltersCallGroupId: selected?.value,
			pendingFiltersAssetId: undefined,
			pendingFiltersNetworkDeviceId: undefined,
		});
	}

	@bind
	private onChangeSelectedDeviceIdentifier(_: React.ChangeEvent, value: INetworkDeviceIdAndType | string | null) {
		let newValue: string | undefined = undefined;
		if (value) {
			if (typeof value === 'string')
				newValue = value;
			else
				newValue = value.identifier;
		}

		this.setState({
			pendingFiltersAssetId: undefined,
			pendingFiltersNetworkDeviceId: newValue,
		});
	}

	@bind
	private onChangeSelectedSite(selected: IOption<string> | null) {
		// Client should never be able to call this method.
		if (this._authenticationService.currentAuth.user.identity.type === C.IdentityType.Client)
			return;

		const pendingFiltersSiteId = selected?.value;
		let { pendingFiltersAssetId, pendingFiltersCallGroupId } = this.state;

		// If the site is set, filter both assets and call groups by the site.
		let callGroupOptions: IOption<string>[] = [];
		let assetOptions: IOption<string>[] = [];
		if (pendingFiltersSiteId) {
			callGroupOptions = this.allCallGroups
				.filter(x => x.network.id == pendingFiltersSiteId)
				.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
				.map(callGroup => ({ label: callGroup.name, value: callGroup.id }));

			assetOptions = this.allAssets
				.filter(x => x.devices?.some(device => isDeviceWithNetwork(device) && device.network && device.network.id === pendingFiltersSiteId))
				.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
				.map(asset => ({ label: asset.name, value: asset.id }));
		} else {
			callGroupOptions = this.allCallGroups
				.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
				.map(callGroup => ({ label: callGroup.name, value: callGroup.id }));

			assetOptions = this.allAssets
				.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
				.map(asset => ({ label: asset.name, value: asset.id }));
		}

		// Make sure the current pending call group and asset filter items exist on the selected site.
		if (pendingFiltersSiteId) {
			const selectedAsset = pendingFiltersAssetId && this.state.assets.get(pendingFiltersAssetId);
			if (!selectedAsset || !selectedAsset.devices || !selectedAsset.devices.some(x => isDeviceWithNetwork(x) && x.network && x.network.id === pendingFiltersSiteId))
				pendingFiltersAssetId = undefined;

			const selectedCallGroup = pendingFiltersCallGroupId && this.state.callGroups.get(pendingFiltersCallGroupId);
			if (!selectedCallGroup || selectedCallGroup.network.id !== pendingFiltersSiteId)
				pendingFiltersCallGroupId = undefined;
		}

		this.setState({
			pendingFiltersSiteId,
			pendingFiltersCallGroupId,
			pendingFiltersAssetId,
			callGroupOptions,
			networkDeviceIdOptions: this.getDeviceIdentifierOptions(pendingFiltersSiteId),
			assetOptions,
		});
	}

	@bind
	private renderFilters() {
		const isClientUser = this._authenticationService.currentAuth.user.identity.type === C.IdentityType.Client;

		return <div>
			<DateTimeRangePicker
				label="Time Range"
				value={this.state.pendingFiltersDateTimeRange}
				onChange={this.onChangeDateTimeRange}
				fullWidth
			/>

			{!isClientUser && <>
				<CustomAutocomplete
					label="Site"
					value={this.state.pendingFiltersSiteId || undefined}
					onChange={this.onChangeSelectedSite}
					options={this.state.siteOptions}
					getOptionLabel={x => x.label}
					getOptionValue={x => x.value}
				/>
			</>}

			<CustomAutocomplete
				label="Call Group"
				value={this.state.pendingFiltersCallGroupId || undefined}
				onChange={this.onChangeSelectedCallGroup}
				options={this.state.callGroupOptions}
				getOptionLabel={x => x.label}
				getOptionValue={x => x.value}
			/>

			<CustomAutocomplete
				label="Asset"
				value={this.state.pendingFiltersAssetId || undefined}
				onChange={this.onChangeSelectedAsset}
				options={this.state.assetOptions}
				getOptionLabel={x => x.label}
				getOptionValue={x => x.value}
			/>

			<Autocomplete
				freeSolo
				autoSelect
				value={this.state.pendingFiltersNetworkDeviceId || null}
				onChange={this.onChangeSelectedDeviceIdentifier}
				options={this.state.networkDeviceIdOptions}
				getOptionLabel={(x: INetworkDeviceIdAndType | string) => (typeof x === 'string') ? x : x.identifier}
				groupBy={(x: INetworkDeviceIdAndType) => x.type}
				renderInput={(params) => <TextField
					{...params}
					variant="filled"
					label="Network Device Identifier"
					helperText="Select an option or enter a network identifier (e.g. SelCall ID, Radio ID, ChatterPTT user ID)."
				/>}
			/>

			<div className="form-actions">
				<Button
					variant="contained"
					color="primary"
					text="Apply Filters"
					onClick={this.applyFilters}
				/>
			</div>
		</div>;
	}

	@bind
	private renderParty(party?: C.ICallPartyDto) {
		if (!party)
			return 'Unknown';

		if (party.networkAssetId) {
			const asset = party.assetId && this.state.assets.get(party.assetId);
			return asset ? asset.name : party.networkAssetId;
		} else if (party.networkCallGroupId) {
			const callGroup = party.callGroupId && this.state.callGroups.get(party.callGroupId);
			return callGroup ? callGroup.name : party.networkCallGroupId;
		}

		return 'Unknown';
	}

	@bind
	private renderParties(call: C.ICallDto) {
		if (!call.parties)
			return 'Unknown';

		const callParties = omitBy(call.parties, x => x.networkAssetId == null || x.didSpeak === false);
		const callPartiesFlatMap = flatMap(callParties, x => x.networkAssetId as string);

		let callPartiesString = '';
		let charCount = 0;
		for (let i = 0; i < callPartiesFlatMap.length; i++) {
			callPartiesString += callPartiesFlatMap[i];

			charCount += callPartiesFlatMap[i].length;

			if (charCount + 2 >= 40) {
				const remainingParties = callPartiesFlatMap.length - i;

				if (remainingParties == 0)
					break;

				callPartiesString += `... ${remainingParties} more`;

				break;
			}

			if (i != callPartiesFlatMap.length - 1) {
				callPartiesString += ', ';
				charCount += 2;
			}
		}

		return <Tooltip title="To see more information click 'play'">
			<span>
				{callPartiesString}
			</span>
		</Tooltip>;
	}

	@bind
	private async downloadCalls(callIds: string[] | undefined) {
		if (!callIds)
			return;

		const fileResponse = await this._callService.downloadCalls({
			callIds,
		});

		const a = document.createElement('a');
		a.href = window.URL.createObjectURL(fileResponse.data);
		if (callIds.length === 1)
			a.download = fileResponse.fileName || `call-${callIds[0]}.mp3`;
		else
			a.download = fileResponse.fileName || `calls.zip`;

		a.click();
	}

	renderTableActions(props: MaterialTableProps<C.ICallDto>): React.ReactElement<any> {
		const callIds = !props.data ? undefined : (props.data as C.ICallDto[]).map(x => x.callId);

		return <div className={downloadCallsButtonStyle}>
			<Button
				variant="contained"
				color="primary"
				text={(props.data == null || props.data.length === 0) ? 'Download Calls' : `Download ${props.data.length} Call(s)`}
				startIcon={<CloudDownloadIcon />}
				title="Download Calls"
				disabled={!props.data || props.data.length === 0 || props.data.length > 50}
				onClick={() => this.downloadCalls(callIds)}
			/>

			{props.data && props.data.length > 50 && <span className="max-calls-message">A maximum of 50 calls may be selected.</span>}
		</div>;
	}

	render() {
		if (this.state.loading) {
			return <MessagePage loading />;
		}

		return <FixedWidthPage
			headingText="Call Recordings"
			contentClassName="calls-list"
			noContentBackground
		>
			<div className="content-box filters">
				{this.renderFilters()}
			</div>

			<MaterialTable
				tableName="calls-list"
				style={{ zIndex: 0 }}
				tableRef={this._tableRef}
				options={{
					filtering: false,
					sorting: false,
					search: false,
					grouping: false,
					showTitle: false,
					pageSize: 25,
					selection: true,
					showTextRowsSelected: false,
				}}
				columns={[
					{
						title: 'Time',
						field: 'callStartTimestamp',
						render: (call: C.ICallDto) => call.startTimestamp.format(shortDateShortTimeFormat),
					},
					{
						title: 'Caller',
						render: (call: C.ICallDto) => this.renderParty(call.parties?.find(x => !!x.isSource)),
					},
					{
						title: 'Called',
						render: (call: C.ICallDto) => <span>
							<span
								className={callPartyIconStyle}
								title={call.type === C.CallType.IndividualVoice
									? 'Individual'
									: (call.type === C.CallType.GroupVoice ? 'Group' : 'Ad hoc group')}
							>
								{call.type === C.CallType.IndividualVoice
									? <PersonIcon />
									: <PeopleIcon />}
							</span>

							{call.type === C.CallType.AdHocGroupVoice
								? <span title={call.parties ? `${call.parties.length} other(s)` : undefined}>Ad hoc group</span>
								: this.renderParty(call.parties?.find(x => !!x.isDestination))}
						</span>,
					},
					{
						title: 'Call duration',
						render: (call: C.ICallDto) => calculateAndFormatLongDuration(call.startTimestamp, call.endTimestamp),
					},
					{
						title: 'Network Device IDs',
						render: this.renderParties,
					},
					{
						render: (call: C.ICallDto) => call.audioUrl && <div className={actionButtonCss}>
							<Button
								className="play-button"
								text={<PlayArrowIcon />}
								variant="outlined"
								onClick={() => this.openCallPlayingDialog(call)}
							/>

							<Button
								text={<CloudDownloadIcon />}
								variant="outlined"
								onClick={() => this.downloadCalls([call.callId])}
							/>
						</div>
					}
				]}
				components={{
					Actions: props => this.renderTableActions(props),
				}}
				data={this.queryData}
			/>

			{this.state.playingCall && <CallPlayingDialog
				callId={this.state.playingCall.callId}
				canGoToNextCall={this.allCalls.findIndex(x => x.callId === this.state.playingCall!.callId) !== this.allCalls.length - 1}
				canGoToPreviousCall={this.allCalls.findIndex(x => x.callId === this.state.playingCall!.callId) > 0}
				onClose={this.onCloseCallPlayingDialog}
				onDownload={(callId: string) => this.downloadCalls([callId])}
				onClickPreviousOrNext={this.onCallPlayingDialogPreviousOrNextCall}
			/>}
		</FixedWidthPage>;
	}
}
