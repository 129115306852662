import React from 'react';
import { observer } from 'mobx-react';
import bind from 'bind-decorator';
import { LngLatBounds } from 'mapbox-gl';
import Slider, { Mark } from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

import {
	Container,
	AuthenticationService,
	Client as C,
	FloorPlansService,
	Service,
} from 'src/services';

import { Button } from 'src/components';
import { MapboxManager, MapboxMapStyle } from 'src/app/map/mapboxManager';
import { mapStyleWindowed } from 'src/app/map/mapStyles';

import './georectifier.scss';
import 'src/app/map/mapPage.scss';

export interface ImageCoordinates {
	topLeft: C.ILatLngDto;
	topRight: C.ILatLngDto;
	bottomLeft: C.ILatLngDto;
	bottomRight: C.ILatLngDto;
}

interface GeorectifierProps {
	image: {
		url: string;
		width: number;
		height: number;
	};
	disabled: boolean;
	floorPlanLocationChanged: (coordinates: ImageCoordinates) => void;
	floorPlan?: C.IFloorPlanDto | null;
	uploadingImage: boolean;
}

interface State {
	mapStyle: MapboxMapStyle;
	image?: {
		height: number;
		width: number;
	};
	imageWidthPercent: number;
	imageHeightPercent: number;
	imageOpacity: number;
}

const imagePercentSliderMarks: Mark[] = [
	{
		value: 50,
		label: '50%',
	}, {
		value: 100,
		label: '100%',
	}, {
		value: 150,
		label: '150%',
	}
];

const imageOpacitySliderMarks: Mark[] = [
	{
		value: 0,
		label: '0%',
	}, {
		value: 100,
		label: '100%',
	}
];

@observer
export class Georectifier extends React.Component<GeorectifierProps, State> {
	private _authenticationService = Container.get<AuthenticationService>(Service.Authentication);
	private _floorPlansService = Container.get<FloorPlansService>(Service.FloorPlans);

	private _mapboxElement?: HTMLDivElement;
	private _mapboxManager?: MapboxManager;

	constructor(props: GeorectifierProps) {
		super(props);

		this.state = {
			mapStyle: MapboxMapStyle.Streets,
			imageWidthPercent: 100,
			imageHeightPercent: 100,
			imageOpacity: 20,
		};
	}

	componentDidUpdate(prevProps: GeorectifierProps) {
		if (prevProps.image !== this.props.image) {
			// If there is a floor plan showing on the map, remove it.
			this._mapboxManager?.removeFloorPlan();

			this.onImageChange(true);
		}
	}

	private async initializeWorldMap(mapboxManager: MapboxManager) {
		this._mapboxManager = mapboxManager;
		await this._mapboxManager.world(MapboxMapStyle.Streets);

		const floorPlan = this.props.floorPlan;
		if (!this.props.uploadingImage && floorPlan) {
			this._mapboxManager.addFloorPlanToWorld(floorPlan);

			const floorPlanBounds = new LngLatBounds();
			floorPlanBounds.extend([ floorPlan.topLeft.longitude, floorPlan.topLeft.latitude ]);
			floorPlanBounds.extend([ floorPlan.topRight.longitude, floorPlan.topRight.latitude ]);
			floorPlanBounds.extend([ floorPlan.bottomLeft.longitude, floorPlan.bottomLeft.latitude ]);
			floorPlanBounds.extend([ floorPlan.bottomRight.longitude, floorPlan.bottomRight.latitude ]);

			this._mapboxManager.fitBounds(floorPlanBounds, { padding: 50 });
		}
	}

	@bind
	private async worldMapRefChanged(element: HTMLDivElement | null) {
		if (!element)
			return;

		if (this._mapboxManager)
			return;

		this._mapboxElement = element;
		const mapboxManager = new MapboxManager(element, { enableRotation: true, onViewChange: this.notifyFloorPlanPositionChanged, metric: this._authenticationService.currentAuth.user.usesMetric });
		await this.initializeWorldMap(mapboxManager);

		this.onImageChange(true);
	}

	@bind
	onImageChange(newImage: boolean = false) {
		if (!this._mapboxElement || !this.props.uploadingImage)
			return;

		const imageMaxHeight = this._mapboxElement.clientHeight * 0.6;
		const imageMaxWidth = this._mapboxElement.clientWidth * 0.6;

		const heightRatio = imageMaxHeight / this.props.image.height;
		const widthRatio = imageMaxWidth / this.props.image.width;

		const ratio = Math.min(heightRatio, widthRatio);

		let { imageHeightPercent, imageWidthPercent, imageOpacity } = this.state;

		if (newImage) {
			const previousGeorectifierParams = this._floorPlansService.previousGeorectifierParams;
			if (previousGeorectifierParams) {
				this._mapboxManager!.map.jumpTo({
					center: previousGeorectifierParams.mapCentre,
					zoom: previousGeorectifierParams.mapZoom,
					bearing: previousGeorectifierParams.mapBearing,
				});

				if (previousGeorectifierParams.imageHeight === this.props.image.height &&
					previousGeorectifierParams.imageWidth === this.props.image.width) {
					imageHeightPercent = previousGeorectifierParams.imageHeightPercent;
					imageWidthPercent = previousGeorectifierParams.imageWidthPercent;
				}

				imageOpacity = previousGeorectifierParams.imageOpacity;
			}
		}

		this.setState({
			imageHeightPercent,
			imageWidthPercent,
			imageOpacity,
			image: {
				height: this.props.image.height * ratio * imageHeightPercent / 100,
				width: this.props.image.width * ratio * imageWidthPercent / 100,
			},
		}, () => {
			this.notifyFloorPlanPositionChanged();
		});
	}

	@bind
	async toggleMapStyle() {
		const newMapStyle = this.state.mapStyle === MapboxMapStyle.Streets ? MapboxMapStyle.Satellite : MapboxMapStyle.Streets;
		this.setState({ mapStyle: newMapStyle });

		await this._mapboxManager!.world(newMapStyle);

		if (this.props.floorPlan && !this.props.uploadingImage)
			this._mapboxManager!.addFloorPlanToWorld(this.props.floorPlan);
	}

	@bind
	private notifyFloorPlanPositionChanged() {
		if (!this._mapboxManager || !this._mapboxElement || !this.state.image || !this.props.uploadingImage)
			return;

		this._floorPlansService.previousGeorectifierParams = {
			mapCentre: this._mapboxManager.map.getCenter(),
			mapZoom: this._mapboxManager.map.getZoom(),
			mapBearing: this._mapboxManager.map.getBearing(),
			imageHeight: this.props.image.height,
			imageWidth: this.props.image.width,
			imageHeightPercent: this.state.imageHeightPercent,
			imageWidthPercent: this.state.imageWidthPercent,
			imageOpacity: this.state.imageOpacity,
		};

		const topLeft_x = (this._mapboxElement.clientWidth / 2) - (this.state.image.width / 2);
		const topLeft_y = (this._mapboxElement.clientHeight / 2) - (this.state.image.height / 2);

		const topRight_x = (this._mapboxElement.clientWidth / 2) + (this.state.image.width / 2);
		const topRight_y = (this._mapboxElement.clientHeight / 2) - (this.state.image.height / 2);

		const bottomLeft_x = (this._mapboxElement.clientWidth / 2) - (this.state.image.width / 2);
		const bottomLeft_y = (this._mapboxElement.clientHeight / 2) + (this.state.image.height / 2);

		const bottomRight_x = (this._mapboxElement.clientWidth / 2) + (this.state.image.width / 2);
		const bottomRight_y = (this._mapboxElement.clientHeight / 2) + (this.state.image.height / 2);

		// Convert the Mapbox X,Y coordinates of corners of the floor plan image to real world coordinates.
		const topLeft = this._mapboxManager.map.unproject([topLeft_x, topLeft_y]);
		const topRight = this._mapboxManager.map.unproject([topRight_x, topRight_y]);
		const bottomLeft = this._mapboxManager.map.unproject([bottomLeft_x, bottomLeft_y]);
		const bottomRight = this._mapboxManager.map.unproject([bottomRight_x, bottomRight_y]);

		this.props.floorPlanLocationChanged({
			topLeft: { latitude: topLeft.lat, longitude: topLeft.lng },
			topRight: { latitude: topRight.lat, longitude: topRight.lng },
			bottomLeft: { latitude: bottomLeft.lat, longitude: bottomLeft.lng },
			bottomRight: { latitude: bottomRight.lat, longitude: bottomRight.lng },
		});
	}

	@bind
	private onImageWidthPercentChange(event: React.ChangeEvent, value: number) {
		this.setState({ imageWidthPercent: value }, () => this.onImageChange());
	}

	@bind
	private onImageHeightPercentChange(event: React.ChangeEvent, value: number) {
		this.setState({ imageHeightPercent: value }, () => this.onImageChange());
	}

	@bind
	private onImageOpacityPercentChange(event: React.ChangeEvent, value: number) {
		this.setState({ imageOpacity: value });
	}

	@bind
	private resetAdjustments() {
		this.setState({
			imageWidthPercent: 100,
			imageHeightPercent: 100,
			imageOpacity: 20,
		}, () => this.onImageChange());
	}

	render() {
		const { image: imageInfo } = this.state;

		return <div className="georectifier">
			{(!this.props.floorPlan || this.props.uploadingImage) && <div className="content-box">
				<ul>
					<li>Position the floor plan by moving the map.</li>
					<li>Control the size of the floor plan by zooming in/out.</li>
					<li>Rotate the map by holding right click and dragging <em>left and right</em>.</li>
					<li>Make finer adjustments using the sliders below the map.</li>
				</ul>
			</div>}

			<div className="content-box">
				<div className="map-container">
					<div className={mapStyleWindowed} ref={this.worldMapRefChanged} />

					{(!this.props.floorPlan || this.props.uploadingImage) && imageInfo && <div
						className="floor-plan"
						style={{
							height: imageInfo.height,
							width: imageInfo.width,
							backgroundImage: `url("${this.props.image.url}")`,
							opacity: this.state.imageOpacity / 100
						}}
					/>}

					<div className="map-style-toggle">
						<button
							className={'current-map__map-style-toggle ' + (this.state.mapStyle === MapboxMapStyle.Satellite ? 'current-map__map-style-toggle--map' : '')}
							type="button"
							title="Change map style"
							onClick={this.toggleMapStyle}
						>
							<span className="current-map__map-style-toggle__label">{this.state.mapStyle === MapboxMapStyle.Streets ? 'Satellite' : 'Map'}</span>
						</button>
					</div>
				</div>
			</div>

			{(!this.props.floorPlan || this.props.uploadingImage) && <div className="content-box adjustments">
				<Button
					className="reset"
					text="Reset"
					variant="outlined"
					color="primary"
					onClick={this.resetAdjustments}
				/>

				<Typography gutterBottom>
					Floor Plan Width
				</Typography>

				<div className="padded-slider">
					<Slider
						value={this.state.imageWidthPercent}
						min={50}
						max={150}
						onChange={this.onImageWidthPercentChange}
						marks={imagePercentSliderMarks}
						step={1}
					/>
				</div>

				<Typography gutterBottom style={{ paddingTop: '10px' }}>
					Floor Plan Height
				</Typography>

				<div className="padded-slider">
					<Slider
						value={this.state.imageHeightPercent}
						min={50}
						max={150}
						onChange={this.onImageHeightPercentChange}
						marks={imagePercentSliderMarks}
						step={1}
					/>
				</div>

				<Typography gutterBottom style={{ paddingTop: '10px' }}>
					Image Opacity
				</Typography>

				<div className="padded-slider">
					<Slider
						value={this.state.imageOpacity}
						min={0}
						max={100}
						onChange={this.onImageOpacityPercentChange}
						marks={imageOpacitySliderMarks}
						step={1}
					/>
				</div>
			</div>}
		</div>;
	}
}
