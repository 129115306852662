import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';

import { FixedWidthPage, ThingLoader, Button } from 'src/components';

import {
	Client as C,
	ClientService,
	Service,
	useInjection,
} from 'src/services';

import './clientOverview.scss';

export interface Props {
	match: match<{ id: string }>;
}

export const ClientOverview = observer((props: Props) => {
	const _clientService = useInjection<ClientService>(Service.Client);

	const load = async (clientId: string) => {
		return await _clientService.getClientOverview(clientId);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(overview: C.IClientOverview) => <FixedWidthPage
			className="client-overview"
			headingText={overview.name}
			headingActions={<Button href={`/app/clients/${overview.clientId}/edit`} startIcon={<EditIcon />} text="Edit" variant="outlined" color="primary" />}
			pageItemId={overview.clientId}
			noContentBackground
		>
			<div className="icon-card content-box">
				<PersonIcon />

				<div>
					There are currently <strong>{overview.numberOfUsers}</strong> users registered under this client.
				</div>
			</div>

			<div className="icon-card content-box">
				<PeopleIcon />

				<div>
					There are currently <strong>{overview.numberOfUserGroups}</strong> groups managed by this client.
				</div>
			</div>
		</FixedWidthPage>}
	/>;
});
