import bind from 'bind-decorator';
import mapboxgl from 'mapbox-gl';
import { arrayFrom, first, map, take } from 'iter-tools';

import {
	Client as C,
} from 'src/services';

import { LiveMapAssetState } from './liveMapAssetState';
import { AssetMapMarkerManager, IMapAssetMarker } from './assetMapMarkerManager';
import { LiveMapGrid, MarkerChanges, IGridCell, MarkerChangeType } from './liveMapGrid';
import { IMapAsset } from './mapAsset';

interface IMarkerManagerAsset {
	assetState: LiveMapAssetState;
}

interface ILiveMapMarker extends IMapAssetMarker {}

interface ISingleAssetLiveMapMarker extends ILiveMapMarker {
	asset: IMapAsset;
}

interface IClusterLiveMapMarker extends ILiveMapMarker {
	// gridCell: IGridCell;
	gridLocation: string;
}

export class LiveMapMarkerManager extends AssetMapMarkerManager {
	private _assetLabelBehavior: C.MapLabelBehavior;
	private _assetClusteringEnabled: boolean;

	/** All assets (asset ID -> marker manager asset). */
	private _allAssets = new Map<string, IMarkerManagerAsset>();

	private _selectedClusterGridKey: string | undefined = undefined;
	private _setSelectedAssetId: (assetId: string | undefined, goToAsset: boolean) => void;
	private _setSelectedAssetsInCluster: (assets: IMapAsset[] | undefined) => void;

	private _mapGrid: LiveMapGrid;

	private _singleAssetMarkers = new Map<string, ISingleAssetLiveMapMarker>();
	private _clusterAssetMarkers = new Map<string, IClusterLiveMapMarker>();

	constructor(map: mapboxgl.Map, assetTypes: C.IAssetTypeDto[], setSelectedAssetId: (assetId: string | undefined) => void, assetLabelBehavior: C.MapLabelBehavior,
		assetClusteringEnabled: boolean, setSelectedAssetsInCluster: (assets: IMapAsset[]) => void) {
		super(map, assetTypes);

		this._setSelectedAssetId = setSelectedAssetId;
		this._setSelectedAssetsInCluster = setSelectedAssetsInCluster;
		this._assetLabelBehavior = assetLabelBehavior;
		this._assetClusteringEnabled = assetClusteringEnabled;

		this._map.on('zoomstart', this.onMapZoomStart);
		this._map.on('zoomend', this.onMapZoomEnd);
		this._map.on('moveend', this.onMapMoveEnd);
	}

	@bind
	private onMapZoomStart() {
		this._selectedClusterGridKey = undefined;
		this._setSelectedAssetsInCluster(undefined);
	}

	@bind
	private onMapZoomEnd() {
		this.clearMarkers();
		this.reinitialiseMarkers();
	}

	@bind
	private onMapMoveEnd() {
		this._mapGrid.mapMoved();
	}

	@bind
	protected onClickMarker(ev: MouseEvent) {
		if (!ev.currentTarget)
			return;

		const element = ev.currentTarget as HTMLDivElement;
		if (!element.dataset)
			return;

		const clusteredGridLocation = element.dataset['clusteredGridLocation'];
		if (clusteredGridLocation) {
			if (this._selectedClusterGridKey === clusteredGridLocation)
				return;

			const gridCell = this._mapGrid.getGridCell(clusteredGridLocation);
			if (!gridCell) {
				console.error(`Grid cell is missing: ${clusteredGridLocation}`);
				return;
			}

			// If there was already a selected cluster, stop it from pulsing.
			const markerChanges = new MarkerChanges();
			if (this._selectedClusterGridKey)
				markerChanges.updateClusterMarker(this._selectedClusterGridKey, false);

			this._selectedClusterGridKey = gridCell.gridLocation.key;

			// Clear the selected state for a single asset (in case there is one).
			this._setSelectedAssetId(undefined, false);

			// Set selected for the cluster's assets.
			const assets = arrayFrom(map(x => x.assetState.asset, gridCell.markerAssets.values()));
			this._setSelectedAssetsInCluster(assets);

			markerChanges.updateClusterMarker(this._selectedClusterGridKey, false);
			this.handleMarkerChanges(markerChanges);

			ev.stopPropagation();
			return;
		}

		const newSelectedAssetId = element.dataset['assetId'];
		if (newSelectedAssetId) {
			this._setSelectedAssetId(newSelectedAssetId, false);

			ev.stopPropagation();
			return;
		}
	}

	@bind
	private addLabelToMarker(marker: ILiveMapMarker) {
		const label = document.createElement('div');
		label.className = 'connect-map-marker__label';

		const arrow = document.createElement('div');
		arrow.className = 'connect-map-marker__label__arrow';
		label.appendChild(arrow);

		const textDiv = document.createElement('div');
		textDiv.className = 'connect-map-marker__label__text';

		if (!marker.cluster) {
			const singleMarker = marker as ISingleAssetLiveMapMarker;

			if (singleMarker.labelElement || !singleMarker.asset.name)
				return;

			// Make sure the label is far enough away from the icon if its a custom icon.
			const assetType = this.getAssetTypeForAsset(singleMarker.asset);
			if (assetType && assetType.useCustomMapMarker) {
				label.style.marginLeft = assetType.mapMarkerSize ? `${assetType.mapMarkerSize - 3}px` : '14px';
			}

			textDiv.appendChild(document.createTextNode(singleMarker.asset.name));
			label.appendChild(textDiv);

			singleMarker.labelElement = label;
			singleMarker.markerElement.appendChild(label);
		} else {
			const clusteredMarker = marker as IClusterLiveMapMarker;
			const gridCell = this._mapGrid.getGridCell(clusteredMarker.gridLocation);
			if (!gridCell) {
				console.error(`Grid cell is missing: ${clusteredMarker.gridLocation}`);
				return;
			}

			// 3 less then the width of cluster markers.
			label.style.marginLeft = '28px';

			const labelAssets = arrayFrom(take(4, gridCell.markerAssets.values()));
			for (let i = 0; i < Math.min(3, labelAssets.length); i++) {
				const name = document.createElement('span');
				name.innerText = labelAssets[i].assetState.asset.name;
				textDiv.appendChild(name);

				textDiv.appendChild(document.createElement('br'));
			}

			if (gridCell.markerAssets.size === 4) {
				const name = document.createElement('span');
				name.innerText = labelAssets[3].assetState.asset.name;
				textDiv.appendChild(name);
			} else if (gridCell.markerAssets.size > 4) {
				const otherAssetsMessage = document.createElement('em');
				otherAssetsMessage.innerText = `${gridCell.markerAssets.size - 3} other assets`;
				textDiv.appendChild(otherAssetsMessage);
			}

			label.appendChild(textDiv);

			clusteredMarker.labelElement = label;
			clusteredMarker.markerElement.appendChild(label);
		}
	}

	@bind
	private removeLabelFromMarker(marker: ILiveMapMarker) {
		if (!marker.labelElement)
			return;

		marker.markerElement.removeChild(marker.labelElement);

		marker.labelElement = undefined;
	}

	@bind
	private addLabelsToAllMarkers() {
		for (const marker of this._singleAssetMarkers.values())
			this.addLabelToMarker(marker);

		for (const marker of this._clusterAssetMarkers.values())
			this.addLabelToMarker(marker);
	}

	@bind
	private removeLabelsFromAllMarkers() {
		for (const marker of this._singleAssetMarkers.values())
			this.removeLabelFromMarker(marker);

		for (const marker of this._clusterAssetMarkers.values())
			this.removeLabelFromMarker(marker);
	}

	@bind
	protected onMouseOverMarker(ev: MouseEvent) {
		if (!ev.currentTarget)
			return;

		const element = ev.currentTarget as HTMLDivElement;
		if (!element.dataset)
			return;

		const clusteredGridLocation = element.dataset['clusteredGridLocation'];
		const hoveringAssetId = element.dataset['assetId'];
		if (!hoveringAssetId && !clusteredGridLocation)
			return;

		let mapMarker: ILiveMapMarker | undefined = undefined;
		if (hoveringAssetId) {
			mapMarker = this._singleAssetMarkers.get(hoveringAssetId);
		} else {
			mapMarker = this._clusterAssetMarkers.get(clusteredGridLocation!);
		}

		if (!mapMarker)
			return;

		this.addLabelToMarker(mapMarker);

		mapMarker.markerElement.classList.add('hovering');
	}

	@bind
	protected onMouseOutMarker(ev: MouseEvent) {
		if (!ev.currentTarget)
			return;

		const element = ev.currentTarget as HTMLDivElement;
		if (!element.dataset)
			return;

		const hoveringAssetId = element.dataset['assetId'];
		const clusteredGridLocation = element.dataset['clusteredGridLocation'];

		if (!hoveringAssetId && !clusteredGridLocation)
			return;

		let mapMarker: ILiveMapMarker | undefined = undefined;
		if (hoveringAssetId) {
			mapMarker = this._singleAssetMarkers.get(hoveringAssetId);
		} else {
			mapMarker = this._clusterAssetMarkers.get(clusteredGridLocation!);
		}

		if (!mapMarker)
			return;

		if (this._assetLabelBehavior === C.MapLabelBehavior.ShowOnHover)
			this.removeLabelFromMarker(mapMarker);

		mapMarker.markerElement.classList.remove('hovering');
	}

	public handleAssetLabelBehaviorChange(assetLabelBehavior: C.MapLabelBehavior) {
		this._assetLabelBehavior = assetLabelBehavior;

		if (this._assetLabelBehavior === C.MapLabelBehavior.ShowAlways)
			this.addLabelsToAllMarkers();
		else
			this.removeLabelsFromAllMarkers();
	}

	public clearSelectedClusteredMarker() {
		if (this._selectedClusterGridKey) {
			const markerChanges = new MarkerChanges();
			markerChanges.updateClusterMarker(this._selectedClusterGridKey, false);

			this._selectedClusterGridKey = undefined;
			this._setSelectedAssetsInCluster(undefined);

			this.handleMarkerChanges(markerChanges);
		}
	}

	/**
	 * Create a marker for a cluster of assets.
	 */
	private createNewClusteredAssetsMarker(gridCell: IGridCell) {
		const clusteredMarkerElement = this.createNewClusteredMarkerElement(gridCell.markerAssets.size);
		const mapMarkerElement = clusteredMarkerElement.mapMarker;

		mapMarkerElement.dataset['clusteredGridLocation'] = gridCell.gridLocation.key;

		const centerAssetLocation = first(gridCell.markerAssets.values())!.assetState.assetLocation!;
		const location = this.getLocation(centerAssetLocation);
		if (!location) {
			console.error(`Get location failed for marker at grid cell: ${gridCell.gridLocation.key}`);
			return;
		}

		const mapMarker = new mapboxgl.Marker(mapMarkerElement)
			.setLngLat(location);

		const clusterMarker: IClusterLiveMapMarker = {
			gridLocation: gridCell.gridLocation.key,
			cluster: true,
			markerElement: mapMarkerElement,
			mapboxMarker: mapMarker,
			location: centerAssetLocation,
			contentElement: clusteredMarkerElement.content,
		};

		this.setOthersForCluster(clusterMarker, gridCell);

		if (this._assetLabelBehavior === C.MapLabelBehavior.ShowAlways)
			this.addLabelToMarker(clusterMarker);

		return clusterMarker;
	}

	/**
	 * Create a marker for a single asset.
	 */
	private createNewAssetMarker(asset: IMarkerManagerAsset) {
		const location = this.getLocation(asset.assetState.assetLocation!);
		if (!location)
			return;

		const markerElementAndContent = this.createNewMarkerElement(asset.assetState.asset);
		const mapMarkerElement = markerElementAndContent.mapMarker;
		mapMarkerElement.dataset['assetId'] = asset.assetState.asset.assetId;

		const mapMarker = new mapboxgl.Marker(mapMarkerElement)
			.setLngLat(location);

		const assetMarker: ISingleAssetLiveMapMarker = {
			asset: asset.assetState.asset,
			markerElement: mapMarkerElement,
			mapboxMarker: mapMarker,
			location: asset.assetState.assetLocation!,
			contentElement: markerElementAndContent.content,
		};

		if (asset.assetState.assetLocation!.heading)
			this.setHeading(assetMarker, asset.assetState.assetLocation!.heading, asset.assetState.asset);

		this.setOthers(assetMarker, asset.assetState);

		if (this._assetLabelBehavior === C.MapLabelBehavior.ShowAlways)
			this.addLabelToMarker(assetMarker);

		return assetMarker;
	}

	private setOthers(mapMarker: IMapAssetMarker, assetState: LiveMapAssetState) {
		this.setEmergency(mapMarker, assetState.hasEmergency, assetState.emergencyType, assetState.asset);

		const shouldPulse = assetState.hasEmergency || assetState.isSelected;
		this.setPulse(mapMarker, !!shouldPulse, assetState.asset);
	}

	private setOthersForCluster(mapMarker: IClusterLiveMapMarker, gridCell: IGridCell) {
		let assetEmergency = false;
		let assetSelected = false;
		let emergencyType = undefined;

		for (const asset of gridCell.markerAssets) {
			if (asset.assetState.hasEmergency)
				assetEmergency = true;

			if (asset.assetState.isSelected)
				assetSelected = true;

			if (asset.assetState.emergencyType) {
				// When new emergency types are introduced there will need to be an order of prioritization.
				// For now Emergency takes precedence over all.
				const isEmergency = asset.assetState.emergencyType === C.EmergencyType.Emergency;
				const isPriorityAlert = asset.assetState.emergencyType === C.EmergencyType.PriorityAlert;

				if (isEmergency || isPriorityAlert && emergencyType !== C.EmergencyType.Emergency)
					emergencyType = asset.assetState.emergencyType;
			}
		}

		// Default to C.EmergencyType.Emergency if the type was not set
		if (!emergencyType)
			emergencyType = C.EmergencyType.Emergency;

		this.setEmergency(mapMarker, assetEmergency, emergencyType);

		const shouldPulse = mapMarker.hasEmergency || assetSelected || this._selectedClusterGridKey === gridCell.gridLocation.key;
		this.setPulse(mapMarker, !!shouldPulse, null);
	}

	private project = (lnglat: mapboxgl.LngLatLike): mapboxgl.Point => this._map.project(lnglat);

	initialize(liveAssetStates: LiveMapAssetState[]) {
		this._mapGrid = new LiveMapGrid(this._assetClusteringEnabled, this.getLocation.bind(this), this.project);
		const markerChanges = this._mapGrid.initialize(liveAssetStates);

		this._allAssets.clear();
		for (const assetState of liveAssetStates) {
			this._allAssets.set(assetState.asset.assetId, {
				assetState: assetState,
			});
		}

		this.handleMarkerChanges(markerChanges);
	}

	protected clearMarkers() {
		for (const marker of this._singleAssetMarkers.values())
			marker.mapboxMarker.remove();

		for (const marker of this._clusterAssetMarkers.values())
			marker.mapboxMarker.remove();

		this._singleAssetMarkers.clear();
		this._clusterAssetMarkers.clear();

		this._mapGrid = new LiveMapGrid(this._assetClusteringEnabled, this.getLocation.bind(this), this.project);
	}

	protected reinitialiseMarkers() {
		const assetStates = arrayFrom(map(x => x.assetState, this._allAssets.values()));
		this.initialize(assetStates);
	}

	clearAndReinitialiseMarkers(assetClusteringEnabled: boolean) {
		this._assetClusteringEnabled = assetClusteringEnabled;

		this._selectedClusterGridKey = undefined;
		this._setSelectedAssetsInCluster(undefined);

		this._setSelectedAssetId(undefined, false);

		this.clearMarkers();
		this.reinitialiseMarkers();
	}

	handleAssetEventUpdate(assetState: LiveMapAssetState) {
		const markerChanges = this._mapGrid.handleAssetEventUpdate(assetState);
		this.handleMarkerChanges(markerChanges);
	}

	handleMarkerChanges(markerChanges: MarkerChanges) {
		for (const change of markerChanges.changes) {
			switch (change.type) {
				case MarkerChangeType.AddAssetMarker: {
					const asset = this._allAssets.get(change.assetId);
					if (!asset) {
						console.error(`Asset is missing: ${change.assetId}`);
						continue;
					}

					const marker = this.createNewAssetMarker(asset);
					if (!marker) {
						console.error(`Create marker failed for asset: ${change.assetId}`);
						continue;
					}

					this._singleAssetMarkers.set(change.assetId, marker);
					marker.mapboxMarker.addTo(this._map);
					break;
				}

				case MarkerChangeType.UpdateAssetMarker: {
					const asset = this._allAssets.get(change.assetId);
					if (!asset) {
						console.error(`Asset is missing: ${change.assetId}`);
						continue;
					}

					const marker = this._singleAssetMarkers.get(change.assetId);
					if (!marker) {
						console.error(`Marker is missing for asset: ${change.assetId}`);
						continue;
					}

					const assetLocation = asset.assetState.assetLocation;
					if (!assetLocation) {
						console.error(`Asset location is missing for asset: ${change.assetId}`);
						continue;
					}

					if (assetLocation !== marker.location) {
						if (!this.setLocation(marker, assetLocation)) {
							console.error(`Set location failed for asset: ${change.assetId}`);
							continue;
						}

						marker.location = assetLocation;
					}

					this.setHeading(marker, assetLocation.heading || undefined, asset.assetState.asset);
					this.setOthers(marker, asset.assetState);

					break;
				}

				case MarkerChangeType.RemoveAssetMarker: {
					const marker = this._singleAssetMarkers.get(change.assetId);
					if (!marker) {
						console.error(`Marker is missing for asset: ${change.assetId}`);
						continue;
					}

					this._singleAssetMarkers.delete(change.assetId);
					marker.mapboxMarker.remove();
					break;
				}

				case MarkerChangeType.AddClusterMarker: {
					const gridCell = this._mapGrid.getGridCell(change.gridKey);
					if (!gridCell) {
						console.error(`Grid cell is missing missing: ${change.gridKey}`);
						continue;
					}

					const marker = this.createNewClusteredAssetsMarker(gridCell);
					if (!marker) {
						console.error(`Create marker failed for grid cell: ${change.gridKey}`);
						continue;
					}

					this._clusterAssetMarkers.set(change.gridKey, marker);
					marker.mapboxMarker.addTo(this._map);
					break;
				}

				case MarkerChangeType.UpdateClusterMarker: {
					const gridCell = this._mapGrid.getGridCell(change.gridKey);
					if (!gridCell) {
						console.error(`Grid cell is missing: ${change.gridKey}`);
						continue;
					}

					const marker = this._clusterAssetMarkers.get(change.gridKey);
					if (!marker) {
						console.error(`Marker is missing for grid cell: ${change.gridKey}`);
						continue;
					}

					if (change.assetsChanged) {
						if (marker.labelElement) {
							this.removeLabelFromMarker(marker);
							this.addLabelToMarker(marker);
						}

						if (marker.contentElement && !marker.hasEmergency) {
							marker.contentElement.textContent = gridCell.markerAssets.size.toString();
						}
					}

					this.setOthersForCluster(marker, gridCell);

					break;
				}

				case MarkerChangeType.RemoveClusterMarker: {
					const marker = this._clusterAssetMarkers.get(change.gridKey);
					if (!marker) {
						console.error(`Marker is missing for grid cell: ${change.gridKey}`);
						continue;
					}

					this._clusterAssetMarkers.delete(change.gridKey);
					marker.mapboxMarker.remove();
					break;
				}
			}
		}
	}
}
