import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DialogContentText } from '@material-ui/core';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import * as Colors from '@material-ui/core/colors';

import AddIcon from '@material-ui/icons/Add';

import { FixedWidthPage, MessagePage, ThingLoader, Button, CustomActionDialogBox, PopoverMenu, PopoverMenuItem } from 'src/components';

import {
	Client as C,
	AuthenticationService,
	BeaconsService,
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

export const BleBeaconSetsList = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _beaconSetsService = useInjection<BeaconsService>(Service.Beacons);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [beaconSetToDelete, setBeaconSetToDelete] = useState<C.IBleBeaconSetDto | null>(null);
	const [beaconSetActionNameConfirm, setBeaconSetActionNameConfirm] = useState<string>();

	const loadBeaconSets = async () => {
		const beaconSets = await _beaconSetsService.getAllBleBeaconSets();
		if (!beaconSets)
			return null;

		return beaconSets;
	};

	const closeDeleteDialog = () => {
		setBeaconSetToDelete(null);
		setBeaconSetActionNameConfirm('');
	};

	const confirmDelete = async (bleBeaconSet: C.IBleBeaconSetDto, reload: Function) => {
		await _beaconSetsService.deleteBeaconSetById(bleBeaconSet.bleBeaconSetId);
		_toasterService.showSuccess(`${bleBeaconSet.name} has been deleted.`);

		closeDeleteDialog();
		reload();
	};

	const handleDeleteAssetTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		setBeaconSetActionNameConfirm(event.target.value);
	};

	const renderActionMenu = (bleBeaconSet: C.IBleBeaconSetDto, reload: Function) => {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/ble-beacon-set/${bleBeaconSet.bleBeaconSetId}/edit`}
			/>,
			<PopoverMenuItem
				key="manage"
				text="Manage Beacon Set Items"
				href={`/app/ble-beacon-set/${bleBeaconSet.bleBeaconSetId}/manage`}
			/>,
			<PopoverMenuItem
				key="delete"
				text="Delete"
				onClick={() => setBeaconSetToDelete(bleBeaconSet)}
			/>
		];
	};

	return <ThingLoader
		load={loadBeaconSets}
		render={(beaconSets: C.IBleBeaconSetDto[], reload: Function) => <FixedWidthPage
			headingText="Beacon Sets"
			headingActions={<Button href="/app/ble-beacon-sets/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': beaconSets.length === 0 })}
		>
			{beaconSets.length > 0 && <table className="card-table">
				<thead>
					<tr>
						<th>Name</th>
						{_authService.currentAuth.user.identity.type === C.IdentityType.SuperUser && <th>Dealer</th>}
						<th></th>
					</tr>
				</thead>

				<tbody>
					{beaconSets.map(x => <tr
						key={x.bleBeaconSetId}
						className="content-box"
					>
						<td>{x.name}</td>
						{_authService.currentAuth.user.identity.type === C.IdentityType.SuperUser && <td>{x.dealer.name}</td>}
						<td className="actions">
							<PopoverMenu
								renderOptions={() => renderActionMenu(x, reload)}
							/>
						</td>
					</tr>)}
				</tbody>
			</table>}

			{beaconSetToDelete && <CustomActionDialogBox
				title={`Delete ${beaconSetToDelete.name}?`}
				actionButton={<Button
					color={Colors.red}
					text="Delete Beacon Set"
					disabled={beaconSetToDelete.name !== beaconSetActionNameConfirm}
					variant="contained"
					onClick={() => confirmDelete(beaconSetToDelete, reload)}
				/>}
				dialogCloseCallback={closeDeleteDialog}
			>
				<DialogContentText>
					Are you sure you want to delete this beacon set? All beacons assigned to
					this set will be un-assigned and the beacon set will be removed from all
					assets it is associated with.<br/>
					<strong>This cannot be undone.</strong><br/><br/>

					Please enter the name of the beacon set below to confirm deletion:
				</DialogContentText>

				<TextField
					id="name"
					label="Beacon Set Name"
					value={beaconSetActionNameConfirm}
					onChange={handleDeleteAssetTextChange}
					fullWidth
					margin="normal"
					variant="filled"
				/>
			</CustomActionDialogBox>}

			{beaconSets.length === 0 && <MessagePage
				title="No beacon sets"
				action={<Button href="/app/ble-beacon-sets/add" text="Add a beacon set?" variant="outlined" />}
			/>}
		</FixedWidthPage>}
	/>;
});
