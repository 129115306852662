import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryUsersAndUserGroupsForAssetServiceRemindersSelector($includeUsers: Boolean!, $includeUserGroups: Boolean!, $includeClients: Boolean!) {
		userGroups @include(if: $includeUserGroups){
			id
			name
			client @include(if: $includeClients) {
				id
			}
		}
		users @include(if: $includeUsers) {
			id
			name
			identity {
				id
				client @include(if: $includeClients) {
					id
				}
			}
		}
	}
`;
