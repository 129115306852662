import React from 'react';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
	IAddressBookEntrySelectorFormValues,
} from 'src/services';

import { FormikSelect } from 'src/components';
import { IOption } from 'src/util';

interface Props {
	options: IOption<string>[] | null;
	formikProps: FormikProps<IAddressBookEntrySelectorFormValues>;
	isLoading?: boolean;
	className?: string;
}

export const AddressBookEntrySelector = observer((props: Props) => {
	if (!props.options)
		return null;

	return <FormikSelect
		name="addressBookEntries"
		placeholder="Select Address Book Entries"
		label="Address Book Entries"
		form={props.formikProps}
		isLoading={props.isLoading}
		multi
		className={props.className}
		options={props.options.slice()}
		getOptionLabel={option => option.label}
		getOptionValue={option => option.value}
		disableCloseOnSelect
	/>;
});
