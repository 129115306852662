import React, { useState } from 'react';
import { DialogContentText, FormControlLabel, Checkbox } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

import { Button } from './button';
import { CustomActionDialogBox, CustomActionDialogBoxProps } from './customActionDialogBox';

import {
	Service,
	LinkedDeviceService,
	ToasterService,
	useInjection,
} from 'src/services';

export interface DeviceUnlinkDialogBoxProps extends Omit<CustomActionDialogBoxProps, 'title'> {
	className?: string;
	devices: { deviceId: string; name: string; }[];
	reload: Function;
	showCantBeUndoneMessage?: boolean;
}

export const DeviceUnlinkDialogBox = (props: DeviceUnlinkDialogBoxProps) => {
	const _linkedDeviceService = useInjection<LinkedDeviceService>(Service.LinkedDevice);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [loading, setLoading] = useState<boolean>(false);
	const [readAndAcknowledgedInformation, setReadAndAcknowledgedInformation] = useState<boolean>(false);

	const actionButtonClicked = async () => {
		setLoading(true);

		try {
			const deviceIds = props.devices.map(x => x.deviceId);
			await _linkedDeviceService.unlinkDevice(deviceIds[0]);

			_toasterService.showSuccess(`The selected device(s) have been unlinked.`);

			props.reload();
		} catch (e) {
			_toasterService.handleWithToast(e, `Failed to unlink device(s).`);
			setLoading(false);
		}

		if (props.dialogCloseCallback)
			props.dialogCloseCallback();
	};

	return <CustomActionDialogBox
		title={`Unlink ${props.devices.length} device(s)?`}
		loading={loading}
		actionButton={<Button
			variant="contained"
			color={red}
			text={`Unlink ${props.devices.length} Device(s)`}
			onClick={actionButtonClicked}
			disabled={!readAndAcknowledgedInformation}
			loading={loading}
		/>}
		dialogCloseCallback={props.dialogCloseCallback}
		fullWidth={true}
		maxWidth="sm"
	>
		<DialogContentText>
			If unlinked:
		</DialogContentText>

		<DialogContentText component="ul">
			<li>All active safety timer(s) for the selected device(s) will be stopped.</li>
			<li>The currently linked device(s) will not be able to record any new information <strong>(including locations and emergencies).</strong></li>
		</DialogContentText>

		{props.showCantBeUndoneMessage && <DialogContentText><strong>This cannot be undone.</strong></DialogContentText>}

		<FormControlLabel
			control={<Checkbox
				onChange={() => setReadAndAcknowledgedInformation(!readAndAcknowledgedInformation)}
				checked={readAndAcknowledgedInformation}
				color="primary"
				disabled={loading}
			/>}
			label="I acknowledge that I have read and understand the above information."
		/>
	</CustomActionDialogBox>;
};
