import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { ErrorMessagePage, FixedWidthPage, LoadingMessagePage, Button } from 'src/components';
import { DeviceIoConfigurationsTable } from './deviceIoCofigurationsTable';
import { validateDevicesWithIoConfigurations, IDeviceWithIo } from './deviceIoConfigurationsModelsAndHelperMethods';
import { undefinedIfUnchanged } from 'src/util';

import SaveIcon from '@material-ui/icons/Save';

import { useQueryAssetWithIoConfigurations, QueryAssetWithIoConfigurations_asset_devices_TeltonikaDevice } from 'src/graphql/__generated__/queries/queryAssetWithIoConfigurations';
import { usemutationUpdateDevicesIoConfigurations } from 'src/graphql/__generated__/mutations/mutationUpdateDevicesIoConfigurations';
import { UpdateDevicesIoConfigurationsInput, UpdateDeviceIoConfigurationsInput, DeviceType } from 'src/../__generated__/globalTypes';

import {
	AuthenticationService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

export interface IDevicesIoFormValues {
	devices: IDeviceWithIo[];
}

export interface Props {
	match: match<{
		id: string
	}>;
}

export const IoConfigurationsList = observer((props: Props) => {
	const authService = useInjection<AuthenticationService>(Service.Authentication);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [ updateDevicesIoConfigurationsMutation ] = usemutationUpdateDevicesIoConfigurations();
	const initlialiseTableValues = useRef<Boolean> (false);

	const assetQuery = useQueryAssetWithIoConfigurations({
		variables: {
			id: props.match.params.id,
		}
	});

	const asset = assetQuery.data?.asset;

	if (assetQuery.loading)
		return <LoadingMessagePage />;

	if (assetQuery.error || !asset?.devices || !authService.currentAuth.permissions.general.manageDevices)
		return <ErrorMessagePage />;

	const submit = async (values: IDevicesIoFormValues) => {
		try {
			const deviceIoConfigurationsUpdateInput: UpdateDevicesIoConfigurationsInput = {
				devices: [],
			};

			for (const device of values.devices) {
				// Get the original device values.
				const originalDevice = asset.devices!.find(x => x.id === device.id && x.deviceType === DeviceType.TELTONIKA);

				if (originalDevice) {
					const updatedDevice: UpdateDeviceIoConfigurationsInput = {
						deviceId: originalDevice.id,
						ioConfigurations: [],
					};

					for (const ioConfiguration of device.ioConfigurations) {
						// Get the original ioConfiguration if it exists
						const orignalIoConfiguration = (ioConfiguration?.id && originalDevice.ioConfigurations) ? originalDevice.ioConfigurations.find(x => x.id === ioConfiguration.id) : null;

						updatedDevice.ioConfigurations.push({
							name: ioConfiguration.name,
							id: !orignalIoConfiguration ? undefined : orignalIoConfiguration.id,
							isEnabled: ioConfiguration.isEnabled,
							ioName: ioConfiguration.ioName,
							onAction: ioConfiguration.onAction,
							offAction: ioConfiguration.offAction,
						});
					}

					deviceIoConfigurationsUpdateInput.devices.push(updatedDevice);
				}
			}

			await updateDevicesIoConfigurationsMutation({
				variables: {
					input: deviceIoConfigurationsUpdateInput,
				}
			});

			toasterService.showSuccess('Device IO configurations updated.');

			// Make sure the new values returned are set.
			initlialiseTableValues.current = false;
			await assetQuery.refetch();

			return true;
		} catch (err) {
			toasterService.handleWithToast(err, `Failed to update device IO configurations.`);
			return false;
		}
	};

	let initialValues: IDevicesIoFormValues = {
		devices: [],
	};

	if (!initlialiseTableValues.current) {
		initialValues = {
			devices: asset.devices?.filter(x => x.deviceType === DeviceType.TELTONIKA).map(x => ({
				id: x.id,
				name: x.name,
				modelType: (x  as QueryAssetWithIoConfigurations_asset_devices_TeltonikaDevice)?.teltonikaModelType,
				ioConfigurations: x.ioConfigurations?.map(y => ({
					name: y.name,
					id: y.id,
					isEnabled: y.isEnabled,
					ioName: y.ioName,
					onAction: y.onAction || null,
					offAction: y.offAction || null,
				})) ?? []
			})) ?? []
		};

		initlialiseTableValues.current = true;
	}

	return <Formik
		initialValues={initialValues}
		validate={(values: IDevicesIoFormValues) => validateDevicesWithIoConfigurations(values.devices)}
		validateOnChange={true}
		onSubmit={submit}
	>
		{formikProps => <Form className="formik-form">
			<FixedWidthPage
				headingText="Asset IO Configurations"
				subheadingText={asset!.name}
				headingActions={<Button startIcon={<SaveIcon/>} type="submit" text="Save All" variant="contained" color="primary"/>}
				noContentBackground
			>
				{formikProps.values.devices.map((_, index) => {
					return <DeviceIoConfigurationsTable
						key={index}
						formikProps={formikProps}
						deviceIndex={index}
					/>;
				})}
			</FixedWidthPage>
		</Form>}
	</Formik>;
});
