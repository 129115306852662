import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddCamperVanDevice($input: AddCamperVanDeviceInput!) {
		addCamperVanDevice(input: $input) {
			camperVanDevice {
				id
				asset {
					id
				}
			}
		}
	}
`;
