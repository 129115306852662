import { inject, injectable } from 'inversify';

import * as C from './client';
import { Service } from './service';
import { ToasterService } from './toasterService';

@injectable()
export class UsersService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.Toaster) private _toasterService: ToasterService,
	) {

	}

	async addUser(request: C.IAddUserRequest): Promise<C.IUserDto> {
		return await this.client.addUser(new C.AddUserRequest(request));
	}

	async updateUser(userId: string, request: C.IUpdateUserRequest): Promise<C.IUserDto> {
		return await this.client.updateUserById(userId, new C.UpdateUserRequest(request));
	}

	async sendActivationEmail(userId: string): Promise<void> {
		return await this.client.sendActivationEmail(userId);
	}

	async deleteUser(userId: string): Promise<void> {
		return await this.client.deleteUser(userId);
	}

	async getUser(userId: string): Promise<C.IUserDto | null> {
		try {
			return await this.client.getUserById(userId);
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load user.');
		}

		return null;
	}

	async getAllUsers(): Promise<C.IUserDto[] | null> {
		try {
			return await this.client.listUsers();
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to load users.');
		}

		return null;
	}

	async userActivateValidateToken(request: C.IActivateAccountValidateTokenRequest): Promise<C.IActivateAccountValidateTokenResponse | null> {
		try {
			return await this.client.validateUserActivationToken(new C.ActivateAccountValidateTokenRequest(request));
		} catch (err) {
			// :(
		}

		return null;
	}

	async activateAccount(request: C.IActivateAccountRequest): Promise<boolean> {
		try {
			await this.client.activateUserAccount(new C.ActivateAccountRequest(request));
			return true;
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to activate account.');
		}

		return false;
	}

	async getUserGroups(userId: string): Promise<C.ICurrentUserGroupsResponse> {
		return await this.client.getUserUserGroupsByUserId(userId);
	}

	async modifyUserGroups(userId: string, request: C.IUpdateUserUserGroupsByUserIdRequest): Promise<void> {
		await this.client.updateUserUserGroupsByUserId(userId, new C.UpdateUserUserGroupsByUserIdRequest(request));
	}

	async changePassword(userId: string, request: C.IChangePasswordRequest): Promise<void> {
		await this.client.changeUserPasswordById(userId, new C.ChangePasswordRequest(request));
	}

	async resetPassword(request: C.IResetPasswordRequest) {
		await this.client.resetUserPassword(new C.ResetPasswordRequest(request));
	}

	async confirmResetPassword(request: C.IConfirmResetPasswordRequest) {
		await this.client.confirmUserResetPassword(new C.ConfirmResetPasswordRequest(request));
	}

	async getUserGatewayAlertSettings(userId: string): Promise<C.IUserGatewayAlertSettingsDto> {
		return await this.client.getUserGatewayAlertSettingsById(userId);
	}

	async saveUserGatewayAlertSettings(userId: string, userGatewayAlertSettings: C.IUserGatewayAlertSettingsDto): Promise<void> {
		return await this.client.updateUserGatewayAlertSettingsById(userId, new C.UserGatewayAlertSettingsDto(userGatewayAlertSettings));
	}

	async getUserMapSettings(userId: string): Promise<C.IUserMapSettingsDto> {
		return this.client.getUserMapSettingsByUserId(userId);
	}

	async updateUserMapSettings(userId: string, request: C.IUpdateUserMapSettingsRequest): Promise<C.IUserMapSettingsDto> {
		return await this.client.updateUserMapSettingsByUserId(userId, new C.UpdateUserMapSettingsRequest(request));
	}

	async updateUserMapLayerSettings(userId: string, request: C.IUpdateUserMapLayerSettingsRequest): Promise<C.IUserMapSettingsDto> {
		return await this.client.updateLayerSetting(userId, new C.UpdateUserMapLayerSettingsRequest(request));
	}
}
