import React, { useState } from 'react';
import classNames from 'classnames';
import { red } from '@material-ui/core/colors';
import { withStyles, Theme, } from '@material-ui/core/styles';
import { DialogContentText } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';

import { Button, FixedWidthPage, ThingLoader, MessagePage, CustomActionDialogBox, PopoverMenu, PopoverMenuItem } from 'src/components';
import { longDateShortTimeFormat } from 'src/util/dateTimeFormats';

import {
	AuthenticationService,
	Client as C,
	ReportService,
	ToasterService,
	Service,
	useInjection,
} from 'src/services';

const RedButton = withStyles((theme: Theme) => ({
	root: {
		color: theme.palette.getContrastText(red[500]),
		backgroundColor: red[500],
		'&:hover': {
			backgroundColor: red[700],
		},
	},
}))(Button);

export const ScheduledReportsList = () => {
	const _reportService = useInjection<ReportService>(Service.Report);
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [reportToDelete, setReportToDelete] = useState<C.IScheduledReportDto | null>(null);
	const [deleteNameConfirm, setDeleteNameConfirm] = useState<string>('');

	const loadReportList = async () => {
		return await _reportService.listScheduledReports();
	};

	const renderReportRow = (report: C.IScheduledReportDto, reload: Function) => {
		const reportType = _reportService.getReportTypeFormatted(report.reportType);
		if (!reportType)
			return null;

		let nextReportTimestamp: string;
		if (report.enabled)
			nextReportTimestamp = report.nextReportTimestamp.tz(_authService.currentAuth.user.timeZone).format(longDateShortTimeFormat);
		else
			nextReportTimestamp = 'Report is disabled.';

		return <tr key={report.scheduledReportId} className="content-box">
			<td>{report.name && report.name.length > 0 ? report.name : <span className="unnamed">Unnamed</span>}</td>
			<td>{reportType}</td>
			<td>{report.frequency}</td>
			<td>
				<div className="status">
					<div
						className={classNames('status-indicator', report.enabled ? 'enabled' : 'disabled')}
						title={report.enabled ? 'Enabled' : 'Disabled'}
					/>
				</div>
			</td>
			<td>{nextReportTimestamp}</td>

			<td className="actions">
				<PopoverMenu
					renderOptions={() => renderActionMenu(report, reload)}
				/>
			</td>
		</tr>;
	};

	const renderActionMenu = (scheduledReport: C.IScheduledReportDto, reload: Function) => {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/reports/scheduled/${scheduledReport.scheduledReportId}/edit`}
			/>,
			<PopoverMenuItem
				key="delete"
				text="Delete"
				onClick={() => setReportToDelete(scheduledReport)}
			/>,
		];
	};

	const confirmDelete = async (report: C.IScheduledReportDto, reload: Function) => {
		try {
			await _reportService.deleteScheduledReport(report.scheduledReportId);
			_toasterService.showSuccess(`${report.name} has been deleted.`);
			clearDialogState();
			reload();
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to delete scheduled report.');
		}
	};

	const clearDialogState = () => {
		setReportToDelete(null);
		setDeleteNameConfirm('');
	};

	return <ThingLoader
		load={loadReportList}
		render={(scheduledReports: C.IScheduledReportDto[], reload: Function) => <FixedWidthPage
			headingText="Scheduled Reports"
			noContentBackground
			headingActions={<Button text="Create new scheduled report" startIcon={<AddIcon />} variant="outlined" color="primary" href="/app/reports/scheduled/create" />}
			className="scheduled-reports-list"
			contentMessageReplace={scheduledReports.length === 0 && <MessagePage
				title="No scheduled reports."
				action={<Button href="/app/reports/scheduled/create" text="Create a scheduled new report?" variant="outlined" />}
			/>}
		>
			<table className="card-table">
				<thead>
					<tr>
						<th>Name</th>
						<th>Type</th>
						<th>Frequency</th>
						<th className="align-center">Enabled</th>
						<th>Next Report</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{scheduledReports.map(x => renderReportRow(x, reload))}
				</tbody>
			</table>

			{reportToDelete && <CustomActionDialogBox
				title={`Delete ${reportToDelete.name}?`}
				actionButton={<RedButton
					disabled={reportToDelete.name !== deleteNameConfirm}
					variant="contained"
					color="primary"
					text="Delete Scheduled Report"
					onClick={() => confirmDelete(reportToDelete, reload)}
				/>}
				dialogCloseCallback={clearDialogState}
			>
				<DialogContentText>
					<strong>This cannot be undone.</strong><br/><br/>

					Please enter the name of the scheduled report below to confirm deletion:
				</DialogContentText>

				<TextField
					id="name"
					label="Scheduled Report Name"
					value={deleteNameConfirm}
					onChange={e => setDeleteNameConfirm(e.target.value)}
					fullWidth
					margin="normal"
					variant="filled"
				/>
			</CustomActionDialogBox>}
		</FixedWidthPage>}
	/>;
};
