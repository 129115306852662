import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateEroadDevice($input: UpdateEroadDeviceInput!) {
		updateEroadDevice(input: $input) {
			eroadDevice {
				id
			}
		}
	}
`;
