import React from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import { FormikTextField } from 'src/components';

interface IEroadDeviceFormValues {
	vehiclePlate: string;
}

interface IProps<T> {
	form: FormikProps<T>;
}

export const ManageEroadDeviceComponent = observer(<T extends IEroadDeviceFormValues>(props: IProps<T>) => {
	return <>
		<Field
			name="vehiclePlate"
			label="Vehicle Plate"
			type="text"
			component={FormikTextField}
			required
		/>
	</>;
});
