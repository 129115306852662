import React from 'react';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
	Client as C,
	FloorLevelConverter,
	Service,
	useInjection,
} from 'src/services';

import { Button } from 'src/components';

const useStyles = makeStyles({
	root: {
		display: 'grid',
		gridTemplateColumns: '30% 20% 50px 50px',
		columnGap: '10px',
	},
});

export interface LevelSelectorProps {
	levelIndex: number;
	numberingStyle: C.FloorNumberingStyle;
	onLevelChange: (levelIndex: number) => void;
	onNumberingStyleChange: (style: C.FloorNumberingStyle) => void;
}

export const LevelSelector = (props: LevelSelectorProps) => {
	const levelConverter = useInjection<FloorLevelConverter>(Service.FloorLevelConverter);
	const classes = useStyles();

	const incrementFloor = () => {
		if (props.levelIndex !== null)
			props.onLevelChange(props.levelIndex + 1);
	};

	const decrementFloor = () => {
		if (props.levelIndex !== null)
			props.onLevelChange(props.levelIndex - 1);
	};

	const onNumberingStyleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onNumberingStyleChange(event.target.value as C.FloorNumberingStyle);
	};

	const levelString = levelConverter.fromIndex(props.levelIndex, props.numberingStyle);

	return <div className={classes.root}>
		<TextField
			select
			label="Numbering Style"
			value={props.numberingStyle}
			onChange={onNumberingStyleChanged}
			variant="filled"
		>
			<MenuItem key={C.FloorNumberingStyle.American} value={C.FloorNumberingStyle.American}>
				American (B2, B1, G, 2, 3)
			</MenuItem>

			<MenuItem key={C.FloorNumberingStyle.British} value={C.FloorNumberingStyle.British}>
				British (B2, B1, G, 1, 2)
			</MenuItem>
		</TextField>

		<TextField
			label="Level"
			value={levelString}
			spellCheck={false}
			variant="filled"
		/>

		<Button
			text={<KeyboardArrowUpIcon />}
			disabled={props.levelIndex === null}
			onClick={incrementFloor}
		/>

		<Button
			text={<KeyboardArrowDownIcon />}
			disabled={props.levelIndex === null}
			onClick={decrementFloor}
		/>
	</div>;
};
