import { IEmergency } from 'src/components/emergency/emergencyViewer';

import { QueryEmergenciesForEmergencyList_emergencies } from 'src/graphql/__generated__/queries/queryEmergenciesForEmergencyList';
import { QueryEmergenciesForEmergencyHistoryList_emergencies } from 'src/graphql/__generated__/queries/queryEmergenciesForEmergencyHistoryList';

import {
	Client as C,
} from 'src/services';

export function getEventTime(event: C.IAssetEventDto | C.IEmergencyDto | QueryEmergenciesForEmergencyList_emergencies | QueryEmergenciesForEmergencyHistoryList_emergencies | IEmergency) {
	return event.generatedAt || event.recordedAt;
}
