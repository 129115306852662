import { gql } from '@apollo/client';

import { CUSTOM_MAP_MARKER_FIELDS } from 'src/graphql/fragments/customMapMarkerFields';

export const MUTATION = gql`
	mutation MutationUpdateCustomMapMarker($input: UpdateCustomMapMarkerInput!) {
		updateCustomMapMarker(input: $input) {
			customMapMarker {
				...CustomMapMarkerFields
			}
		}
	}

	${CUSTOM_MAP_MARKER_FIELDS}
`;
