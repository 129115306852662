import React from 'react';

import { ErrorMessagePage, LoadingMessagePage } from 'src/components';
import { useQueryDealerOptions } from 'src/graphql/__generated__/queries/queryDealerOptions';
import { useMutationAddBeacon } from 'src/graphql/__generated__/mutations/mutationAddBeacon';
import { useIdentityType, Client as C, useInjection, Service, ToasterService, HistoryService } from 'src/services';

import { ManageBeaconComponent, ManageBeaconFormValues } from './manageBeaconComponent';

export const AddBeacon = () => {
	const identityType = useIdentityType();
	const historyService = useInjection<HistoryService>(Service.History);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const query = useQueryDealerOptions({
		skip: identityType !== C.IdentityType.SuperUser,
	});

	const [addBeacon] = useMutationAddBeacon();

	if (query.loading)
		return <LoadingMessagePage />;

	if (query.error)
		return <ErrorMessagePage />;

	const submit = async (values: ManageBeaconFormValues) => {
		try {
			const result = await addBeacon({
				variables: {
					input: {
						name: values.name,
						serialNumber: values.serialNumber,
						beaconType: values.beaconType,
						transmitPower: values.transmitPower,
						dealerId: values.dealerId,
					},
				},
			});

			const beaconId = result.data!.addBluetoothBeacon!.bluetoothBeacon!.id;

			toasterService.showSuccess('Bluetooth beacon added.');
			historyService.history.push(`/app/bluetooth-beacons/${beaconId}/edit`);
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to add Bluetooth beacon.');
		}
	};

	return <ManageBeaconComponent
		dealers={query.data?.dealers || undefined}
		submit={submit}
	/>;
};
