import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateTaitRadioDevice($input: UpdateTaitRadioDeviceInput!) {
		updateTaitRadioDevice(input: $input) {
			taitRadioDevice {
				id
			}
		}
	}
`;
