import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import * as C from 'src/services/client';

import { Service } from 'src/services/service';
import { AssetGroupService } from 'src/services/assetGroupService';
import { ToasterService } from 'src/services/toasterService';
import { useInjection } from 'src/services';
import { IOption } from 'src/util';

export interface IAssetGroupSelectorFormValues {
	assets: string[];
}

export const useAssetGroupSelectorService = (listType: C.ListAssetGroupsType) => {
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _assetGroupService = useInjection<AssetGroupService>(Service.AssetGroup);

	const [assetGroupOptions, setAssetGroupOptions] = useState<IOption<string>[] | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useAsyncEffect(async () => {
		try {
			const assetGroups = await _assetGroupService.getAssetGroups(listType);

			const options = assetGroups
				.map(x => ({
					label: x.type === C.AssetGroupType.AllAssetsForClient ? `${x.name} (${x.client.name})` : x.name,
					value: x.assetGroupId
				}))
				.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));

			setAssetGroupOptions(options);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to load asset groups.');
		}

		setLoading(false);
	}, [listType]);

	return { assetGroupOptions, loading };
};
