import React from 'react';
import moment from 'moment-timezone';

import { IMapAsset } from './mapAsset';
import { DateTimeRangePicker, DateTimeRange, CustomAutocomplete } from 'src/components';

import './historyFilter.scss';

export interface HistoryFilterProps {
	assetId?: string | null;
	dateTimeRange: DateTimeRange;
	assets: IMapAsset[];
	disabled: boolean;
	onChange: (assetId: string | null, dateTimeRange: DateTimeRange) => void;
}

const fuelReportingAssets = [
	'a0034921-fcb8-44a9-3093-08da6e2015c8',
	'74de625f-02a0-4d4b-1d4a-08daa4ea8085',
	'adb4b9c8-4541-40c5-1d4c-08daa4ea8085',
];

export const HistoryFilter = (props: HistoryFilterProps) => {

	const handleAssetChange = (selected: IMapAsset | null) => {
		const newAssetId = selected ? selected.assetId! : null;
		props.onChange(newAssetId, props.dateTimeRange);
	};

	const maxDuration = props.assetId && fuelReportingAssets.includes(props.assetId)
		? moment.duration(31, 'days')
		: moment.duration(7, 'days');

	return <>
		<div className="filter">
			<label>Asset:</label>
			<CustomAutocomplete
				className="asset-select"
				placeholder="Select an asset..."
				value={props.assetId || undefined}
				disabled={props.disabled}
				onChange={handleAssetChange}
				getOptionLabel={x => x.name}
				getOptionValue={x => x.assetId}
				options={props.assets}
				outlined
			/>
		</div>

		<div className="filter">
			<DateTimeRangePicker
				value={props.dateTimeRange}
				onChange={value => props.onChange(props.assetId || null, value)}
				variant="text"
				disabled={props.disabled}
				maxDuration={maxDuration}
			/>
		</div>
	</>;
};
