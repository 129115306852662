import classNames from 'classnames';
import { Observer, observer } from 'mobx-react';
import React, { useState } from 'react';
import { AutoSizer, List, ListRowProps, ScrollParams } from 'react-virtualized';

import { connectingMessage, disconnectedMessage, GatewayLog } from 'src/services';
import { shortDateFormat, longTimeFormat } from 'src/util/dateTimeFormats';

const _logRowHeight = 18;

interface Props {
	logs: GatewayLog[];
	timezone: string;
	connected: boolean;
}

export const GatewayLogViewer = observer((props: Props) => {
	const [keepScrollBottomLocked, setKeepScrollBottomLocked] = useState<boolean>(true);

	const handleScroll = (scrollParams: ScrollParams) => {
		const heightFromBottomOfScroll = (scrollParams.scrollHeight - (scrollParams.clientHeight + scrollParams.scrollTop));
		setKeepScrollBottomLocked(heightFromBottomOfScroll <= _logRowHeight);
	};

	const rowRenderer = (rowProps: ListRowProps): JSX.Element => {
		const gatewayLog = props.logs[rowProps.index];

		const messageClassNames = classNames('log-message', {
			'connecting-message': gatewayLog.message === connectingMessage,
			'disconnected-message': gatewayLog.message === disconnectedMessage,
		});

		return <div key={rowProps.key} className="formatted-log-message" style={rowProps.style}>
			<span className="timestamp">{gatewayLog.timestamp.tz(props.timezone).format(`${shortDateFormat} ${longTimeFormat}`)}:</span>
			<span className={messageClassNames} title={gatewayLog.message}>{gatewayLog.message}</span>
		</div>;
	};

	return <AutoSizer disableHeight>
		{({width}) => <Observer>
			{() => <List
				className={classNames('logs-list', 'monospace', {'disconnected': !props.connected})}
				height={720}
				width={width}
				rowRenderer={rowRenderer}
				rowCount={props.logs.length}
				rowHeight={_logRowHeight}
				overscanRowCount={20}
				onScroll={handleScroll}
				scrollToIndex={keepScrollBottomLocked ? props.logs.length - 1 : undefined }
			/>}
		</Observer>}
	</AutoSizer>;
});
