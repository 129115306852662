import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryEmergenciesForEmergencyService($emergencyStatus: EmergencyStatus!) {
		emergencies(emergencyStatus: $emergencyStatus) {
			id
			status
			type
			asset {
				id
				name
			}
			deviceIoConfiguration {
				name
				device {
					name
				}
			}
		}
	}
`;
