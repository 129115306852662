import React from 'react';
import moment from 'moment-timezone';

import { calculateAndFormatShortDuration } from 'src/util/durationFormats';
import { MaterialTable } from 'src/components';

import { QueryCallById_callById_segments } from 'src/graphql/__generated__/queries/queryCallById';

interface Props {
	callSegments: QueryCallById_callById_segments[];
	callStartTimestamp: moment.Moment;
}

export const CallSegments = (props: Props) => {
	return <MaterialTable
		tableName="calls-segments"
		options={{
			filtering: false,
			grouping: false,
			showTitle: false,
			pageSize: 25,
			selection: false,
			toolbar: false,
			draggable: false,
		}}
		columns={[
			{
				title: 'Time',
				field: 'time',
				render: (callSegment: QueryCallById_callById_segments) => {
					const startTime = calculateAndFormatShortDuration(props.callStartTimestamp, moment(callSegment.segmentStartTimestamp));
					const endTime = calculateAndFormatShortDuration(props.callStartTimestamp, moment(callSegment.segmentEndTimestamp));

					return `${startTime} - ${endTime}`;
				},
				grouping: false,
				filtering: false,
				sorting: false,
			},
			{
				title: 'Network Device ID',
				field: 'networkAssetId',
				render: (callSegment: QueryCallById_callById_segments) => callSegment.networkAssetId,
				grouping: false,
				filtering: false,
				sorting: false,
			},
			{
				title: 'Asset',
				field: 'assetName',
				render: (callSegment: QueryCallById_callById_segments) => {
					return callSegment?.asset?.name ? callSegment.asset.name : 'Unknown';
				},
				grouping: false,
				filtering: false,
				sorting: false,
			},
		]}
		data={props.callSegments.sort((a, b) => moment(a.segmentStartTimestamp).diff(moment(b.segmentEndTimestamp)))}
	/>;
};
