import React, { useState } from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import LayersIcon from '@material-ui/icons/Layers';
import MapIcon from '@material-ui/icons/Map';

import SortableTree, {
	ExtendedNodeData,
	NodeData,
	TreeItem,
	OnDragPreviousAndNextLocation,
	changeNodeAtPath,
	TreeIndex,
	TreeNode as TreeNodeData,
	FullTree,
} from 'react-sortable-tree';

import {
	Client as C,
	TreeNode,
} from 'src/services';

export interface InvalidNode {
	id: string;
	reason: string;
}

export interface FloorPlanTreeProps {
	floorPlanTree: TreeItem[];
	invalidNodes: InvalidNode[];
	loading: boolean;
	getNodeKey: (data: TreeIndex & TreeNodeData) => string;
	onMove: (data: NodeData & FullTree) => void;
	nodeDeleted: (path: string[]) => boolean;
	updateFloorPlanTree: (floorPlanTree: TreeItem[]) => void;
	renderGroupActions: (floorPlanGroupId: string, path: string[], deleted: boolean) => JSX.Element | null;
	renderFloorPlanActions: (floorPlanId: string, path: string[], deleted: boolean) => JSX.Element | null;
}

export const FloorPlanTree = (props: FloorPlanTreeProps) => {
	const [editingNodeTitle, setEditingNodeTitle] = useState<{ id: string, value: string } | null>(null);

	const onNodeNameEditing = (value: string, groupId: string) => {
		setEditingNodeTitle({ id: groupId, value: value });
	};

	const onNodeRenamed = (value: string, node: TreeItem, path: string[]) => {
		const trimmedValue = value.trim();
		if (trimmedValue.length > 0 && trimmedValue !== node.title!) {
			props.updateFloorPlanTree(changeNodeAtPath({
				treeData: props.floorPlanTree!,
				path: path,
				getNodeKey: props.getNodeKey,
				newNode: { ...node, title: trimmedValue, nameChanged: true },
			}));
		}

		setEditingNodeTitle(null);
	};

	const canDrag = (data: ExtendedNodeData) => {
		// Can't drag the root node
		return (data.node as TreeNode).parentId != '-1';
	};

	const canDrop = (data: OnDragPreviousAndNextLocation & NodeData) => {
		if (props.loading)
			return false;

		// Outside collection
		if (data.nextParent === null)
			return false;

		const nextParent = data.nextParent as TreeNode;
		// Same parent
		if (nextParent.id === (data.prevParent as TreeNode).id)
			return true;

		// Parent is not group
		if (nextParent.type !== C.FloorPlanCollectionItemType.Group)
			return false;

		return true;
	};

	const generateNodeProps = (data: { node: TreeItem, path: any, treeIndex: number }): { title: JSX.Element, buttons?: JSX.Element[], className?: string } => {
		const treeNode = data.node as TreeNode;
		let buttons = [];

		const invalidNode = props.invalidNodes.find(x => x.id === treeNode.id);
		if (invalidNode) {
			buttons.push(<Tooltip title={invalidNode.reason} placement="left">
				<ErrorIcon fontSize="small" className="error" />
			</Tooltip>);
		}

		const thisNodeDeleted = props.nodeDeleted(data.path);
		if (treeNode.type === C.FloorPlanCollectionItemType.Plan) {
			const planActions = props.renderFloorPlanActions(treeNode.id!, data.path, thisNodeDeleted);
			planActions && buttons.push(planActions);
		} else if (treeNode.type === C.FloorPlanCollectionItemType.Group) {
			const groupActions = props.renderGroupActions(treeNode.id!, data.path, thisNodeDeleted);
			groupActions && buttons.push(groupActions);
		}

		const editingThisTreeNode = !!editingNodeTitle && editingNodeTitle.id === treeNode.id;

		if (thisNodeDeleted) {
			buttons = [<DeleteIcon fontSize="small" className="pad-right" />].concat(buttons);
		}

		return {
			title: <div style={{ display: 'flex', alignItems: 'center' }}>
				{treeNode.type == C.FloorPlanCollectionItemType.Group && <LayersIcon className="pad-right" fontSize="small" />}
				{treeNode.type == C.FloorPlanCollectionItemType.Plan && <MapIcon className="pad-right" fontSize="small" />}

				<div style={{ display: 'inline-block' }}>
					<TextField
						disabled={props.loading}
						onChange={(event) => onNodeNameEditing(event.target.value, treeNode.id!)}
						value={editingThisTreeNode ? editingNodeTitle!.value : treeNode.title}
						onBlur={(event) => onNodeRenamed(event.target.value, data.node, data.path)}
					/>
				</div>
			</div>,
			className: classNames({
				'group': treeNode.type === C.FloorPlanCollectionItemType.Group,
				'invalid': !thisNodeDeleted && props.invalidNodes.findIndex(x => x.id === treeNode.id) > -1,
				'deleted': thisNodeDeleted,
			}),
			buttons: buttons,
		};
	};

	return <SortableTree
		treeData={props.floorPlanTree!}
		onChange={props.updateFloorPlanTree}
		canDrag={canDrag}
		canDrop={canDrop}
		onMoveNode={props.onMove}
		maxDepth={5}
		generateNodeProps={generateNodeProps}
		getNodeKey={props.getNodeKey}
		isVirtualized={false}
	/>;
};
