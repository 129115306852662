import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { useQueryAssetServiceReminderById } from 'src/graphql/__generated__/queries/queryAssetServiceReminderById';

import { ManageAssetServiceReminder }  from './manageAssetServiceReminder';
import { ErrorMessagePage, LoadingMessagePage } from 'src/components';

import {
	AuthenticationService,
	Client as C,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditAssetServiceReminder = observer((props: Props) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const identity = authenticationService.currentAuth.user.identity;

	const assetServiceReminderQuery = useQueryAssetServiceReminderById({
		variables: {
			id: props.match.params.id,
			includeClients: identity.type === C.IdentityType.SuperUser || identity.type === C.IdentityType.Dealer,
		}
	});

	if (assetServiceReminderQuery.loading)
		return <LoadingMessagePage />;

	if (assetServiceReminderQuery.error || !assetServiceReminderQuery.data?.assetServiceReminderById)
		return <ErrorMessagePage />;

	return <ManageAssetServiceReminder
		assetServiceReminder={assetServiceReminderQuery.data!.assetServiceReminderById}
	/>;
});
