import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';

import { Button } from './button';
import { CustomActionDialogBox } from './customActionDialogBox';
import { FormikSelect } from './formikSelect';
import { Service, ToasterService, useInjection } from 'src/services';

import { useQueryAssignDeviceToAsset } from 'src/graphql/__generated__/queries/queryAssignDeviceToAsset';
import { useMutationAssignDeviceToAsset } from 'src/graphql/__generated__/mutations/mutationAssignDeviceToAsset';

interface Props {
	asset?: {
		id: string;
		name: string;
	};
	device?: {
		id: string;
		name: string;
	};
	close: () => void;
	onSuccess?: () => Promise<any>;
}

interface AssignDeviceToAssetDialogFormValues {
	assetId?: string;
	deviceId?: string;
}

export const AssignDeviceToAssetDialog = observer((props: Props) => {
	const toasterService = useInjection<ToasterService>(Service.Toaster);
	const [loading, setLoading] = useState<boolean>(false);

	const [assignDeviceToAsset] = useMutationAssignDeviceToAsset();

	const query = useQueryAssignDeviceToAsset({
		variables: {
			includeAssets: !props.asset,
			includeDevices: !props.device,
		},
	});

	const assetOptions = useMemo(
		() => query.data?.assets?.slice().sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
		[query.data?.assets]);

	const deviceOptions = useMemo(
		() => query.data?.devices?.slice().filter(x => !x.asset).sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
		[query.data?.devices]);

	const submit = async (values: AssignDeviceToAssetDialogFormValues) => {
		setLoading(true);

		try {
			await assignDeviceToAsset({
				variables: {
					input: {
						assetId: props.asset?.id ?? values.assetId,
						deviceId: props.device?.id ?? values.deviceId,
					},
				},
			});

			props.onSuccess && await props.onSuccess();
			props.close();
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to assign device.');
			setLoading(false);
		}
	};

	const formikBag = useFormik<AssignDeviceToAssetDialogFormValues>({
		initialValues: {},
		onSubmit: submit,
	});

	return <CustomActionDialogBox
		title="Assign Device To Asset"
		actionButton={<Button
			variant="contained"
			text="Assign"
			onClick={formikBag.submitForm}
			loading={loading}
			color="primary"
		/>}
		dialogCloseCallback={props.close}
	>
		<DialogContentText>
			Assigning a device to an asset will mean any information generated by that device (e.g. locations and emergencies) will be associated with the asset.
		</DialogContentText>

		<form onSubmit={formikBag.handleSubmit} className="formik-form">
			{props.asset && <TextField
				id="asset-name"
				label="Asset"
				value={props.asset.name}
				disabled
				fullWidth
				variant="filled"
			/>}

			{props.device && <TextField
				id="device-name"
				label="Device"
				value={props.device.name}
				disabled
				fullWidth
				variant="filled"
			/>}

			{assetOptions && <FormikSelect
				name="assetId"
				label="Asset"
				required
				form={formikBag}
				options={assetOptions}
				getOptionValue={x => x.id}
				getOptionLabel={x => x.name}
			/>}

			{deviceOptions && <FormikSelect
				name="deviceId"
				label="Device"
				required
				form={formikBag}
				options={deviceOptions}
				getOptionValue={x => x.id}
				getOptionLabel={x => x.name}
			/>}
		</form>
	</CustomActionDialogBox>;
});
