import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useAsyncEffect from 'use-async-effect';

import {
	Client as C,
	AssetGroupService,
	GeofenceService,
	Service,
	useInjection,
} from 'src/services';

import { DialogItem, SelectItemsDialog } from 'src/components/selectItemsDialog';

interface Props {
	assetGroup: C.IAssetGroupDto;
	existing: C.IGeofenceDto[];
	complete: (added: C.IGeofenceDto[]) => void;
	closeDialog: () => void;
}

export const AddGeofencesDialog = observer((props: Props) => {
	const _geofenceService = useInjection<GeofenceService>(Service.Geofence);
	const _assetGroupService = useInjection<AssetGroupService>(Service.AssetGroup);

	const [loading, setLoading] = useState<boolean>(true);
	const [items, setItems] = useState<DialogItem[] | undefined>(undefined);
	const [saving, setSaving] = useState<boolean>(false);
	const [savingError, setSavingError] = useState<boolean>(false);

	useAsyncEffect(async () => {
		const geofences = await _geofenceService.getGeofences();
		const exclude = new Set<string>(props.existing.map(x => x.geofenceId));

		const items = geofences
			.filter(x =>
				x.name.trim().length !== 0 &&
					!exclude.has(x.geofenceId) &&
					x.client.clientId === props.assetGroup.client.clientId)
			.map(x => ({
				id: x.geofenceId,
				name: x.name,
			}))
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

		setItems(items);
		setLoading(false);
	}, []);

	const addAssets = async (geofenceIds: string[]) => {
		setSaving(true);

		try {
			const addedGeofences = await _assetGroupService.addAssetGroupGeofences(props.assetGroup.assetGroupId, {
				geofenceIds: geofenceIds,
			});

			props.complete(addedGeofences);
			props.closeDialog();
		} catch (err) {
			setSavingError(true);
			setSaving(false);
		}
	};

	return <SelectItemsDialog
		title="Add Geofences to Group"
		complete={addAssets}
		close={props.closeDialog}
		loading={loading}
		saving={saving}
		savingError={savingError}
		items={items}
	/>;
});
