import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { ThingLoader } from 'src/components';

import { ManageClientComponent } from './manageClientComponent';

import {
	Client as C,
	ClientService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const EditClient = observer((props: Props) => {
	const _clientService = useInjection<ClientService>(Service.Client);

	const loadClient = async (clientId: string) => {
		return await _clientService.getClient(clientId);
	};

	return <ThingLoader
		id={props.match.params.id}
		load={loadClient}
		render={(client: C.IClientDto) => <ManageClientComponent
			client={client}
		/>}
	/>;
});
