import { inject, injectable } from 'inversify';
import { useSnackbar, WithSnackbarProps, OptionsObject } from 'notistack';

import { ErrorResponse } from './client';
import { AuthenticationService } from './authenticationService';
import { Service } from './service';
import { ApolloError } from '@apollo/client';

let snackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator = () => {
	snackbarRef = useSnackbar();
	return null;
};

@injectable()
export class ToasterService {
	constructor(@inject(Service.Authentication) private auth: AuthenticationService) {

	}

	public showSuccess(message: string) {
		snackbarRef.enqueueSnackbar(message, { variant: 'success' });
	}

	public showWarning(message: string) {
		snackbarRef.enqueueSnackbar(message, { variant: 'warning' });
	}

	public showDanger(message: string) {
		snackbarRef.enqueueSnackbar(message, { variant: 'error' });
	}

	public showCustom(message: React.ReactNode, options: OptionsObject) {
		snackbarRef.enqueueSnackbar(message, options);
	}

	public closeToast(key: string) {
		snackbarRef.closeSnackbar(key);
	}

	handleWithToast(err: any, messageStart?: string, messageEnd?: string) {
		const errorMessage = this.handleError(err, messageStart, messageEnd);

		snackbarRef.enqueueSnackbar(errorMessage, {
			variant: 'error',
			autoHideDuration: 10000,
		});
	}

	private handleError(err: any, messageStart?: string, messageEnd?: string): string {
		const errorParts = [];

		if (messageStart)
			errorParts.push(messageStart);

		if (err instanceof ErrorResponse) {
			errorParts.push(err.errors);
		} else if (err instanceof ApolloError) {
			errorParts.push(err.message);
		} else if (typeof(err) === 'string') {
			errorParts.push(err);
		} else if (err.status == 401) {
			// Their token has probably expired, redirect them to sign in
			this.auth.clearUserState();
			return 'You have been signed out.';
		} else {
			errorParts.push('An unexpected error occurred. Please check your internet connection, refresh the page and try again.');
		}

		if (messageEnd)
			errorParts.push(messageEnd);

		return errorParts.join(' ');
	}
}
