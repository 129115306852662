import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Checkbox from '@material-ui/core/Checkbox';
import { match } from 'react-router-dom';

import { FixedWidthPage, Button, LoadingMessagePage, ErrorMessagePage } from 'src/components';
import { AddBeaconsDialog } from './addBeaconsDialog';

import { useQueryManageBeaconSet, QueryManageBeaconSet_bluetoothBeaconSet } from 'src/graphql/__generated__/queries/queryManageBeaconSet';

import {
	Client as C,
	Service,
	AuthenticationService,
	BeaconsService,
	ToasterService,
	useInjection,
} from 'src/services';

import 'src/app/itemGroupsTables.scss';

interface Props {
	match: match<{ id: string }>;
}

export const ManageBleBeaconSet = observer((props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _beaconService = useInjection<BeaconsService>(Service.Beacons);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [showAddBeaconDialog, setShowAddBeaconDialog] = useState<boolean>(false);
	const [selectedBeacons, setSelectedBeacons] = useState<Set<string>>(new Set());
	const [removeBeaconsLoading, setRemoveBeaconsLoading] = useState<boolean>(false);

	const query = useQueryManageBeaconSet({
		variables: {
			bluetoothBeaconSetId: props.match.params.id,
			includeDealer: _authService.currentAuth.user.identity.type === C.IdentityType.SuperUser,
		}
	});

	if (query.loading)
		return <LoadingMessagePage />;

	if (query.error || !query.data || !query.data.bluetoothBeaconSet || !query.data.bluetoothBeaconSet.bluetoothBeacons || !query.data.bluetoothBeacons)
		return <ErrorMessagePage />;

	const bleBeaconSet = query.data.bluetoothBeaconSet;
	const setBeacons = query.data.bluetoothBeaconSet.bluetoothBeacons;
	const beacons = query.data.bluetoothBeacons;

	const removeBeacons = async () => {
		if (!confirm(`Are you sure you want to remove the selected beacon(s) from this group?`))
			return;

		setRemoveBeaconsLoading(true);

		try {
			await _beaconService.removeBeaconsFromSetById(props.match.params.id, {
				bleBeaconIds: Array.from(selectedBeacons),
			});

			setSelectedBeacons(new Set());
			query.refetch();
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to remove beacons from group.');
		}

		setRemoveBeaconsLoading(false);
	};

	const addBeaconsComplete = () => {
		setShowAddBeaconDialog(false);
		query.refetch();
	};

	const toggleBeacon = (beaconId: string) => {
		const updatedSelectedBeacons = new Set(selectedBeacons);
		if (updatedSelectedBeacons.has(beaconId))
			updatedSelectedBeacons.delete(beaconId);
		else
			updatedSelectedBeacons.add(beaconId);

		setSelectedBeacons(updatedSelectedBeacons);
	};

	return <FixedWidthPage
		headingText={bleBeaconSet!.name}
		noContentBackground
		contentClassName="manage-beacon-set"
	>
		{showAddBeaconDialog && <AddBeaconsDialog
			beaconSet={bleBeaconSet!}
			beacons={beacons!}
			existing={setBeacons!}
			complete={addBeaconsComplete}
			closeDialog={() => setShowAddBeaconDialog(false)}
		/>}

		<div className="manage-beacon-set-beacons">
			<div className="item-group-heading">
				<h2 className="item-group-title">Beacons</h2>
				<div className="actions">
					<Button
						text="Remove"
						variant="outlined"
						disabled={selectedBeacons.size === 0 || removeBeaconsLoading}
						loading={removeBeaconsLoading}
						onClick={removeBeacons}
					/>

					<Button
						text="Add"
						variant="outlined"
						disabled={removeBeaconsLoading}
						onClick={() => setShowAddBeaconDialog(true)}
					/>
				</div>
			</div>

			<table className="card-table">
				<tbody>
					{setBeacons!.map(x => <tr key={x.id} className="content-box">
						<td>
							<Checkbox
								checked={selectedBeacons.has(x.id)}
								onClick={y => toggleBeacon(x.id)}
								color="primary"
							/>
						</td>
						<td>
							{x.name}
						</td>
					</tr>)}
				</tbody>
			</table>

			{setBeacons!.length === 0 && <div
				className="manage-beacon-set__item-block__no-items content-box"
			>
				No beacons in this set.
			</div>}
		</div>
	</FixedWidthPage>;
});
