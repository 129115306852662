import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { RGBColor } from 'react-color';

import AddIcon from '@material-ui/icons/Add';

import { Button, FixedWidthPage, MessagePage, PopoverMenu, PopoverMenuItem, ThingLoader } from 'src/components';
import { hexToRgb } from 'src/util/colorHelpers';

import {
	Client as C,
	GeofenceService,
	Service,
	useInjection,
} from 'src/services';

const colorBlockStyle = (color: RGBColor) => ({
	display: 'inline-block',
	marginTop: '-30px',
	marginBottom: '-10px',
	height: '30px',
	width: '45px',
	backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
	border: `3px solid rgb(${color.r}, ${color.g}, ${color.b})`,
});

export const GeofenceTypeList = observer(() => {
	const _geofenceService = useInjection<GeofenceService>(Service.Geofence);

	const load = async () => {
		const geofenceTypes = await _geofenceService.getGeofenceTypes();
		return geofenceTypes.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
	};

	const renderGeofenceType = (geofenceType: C.IGeofenceTypeDto) => {
		const color = hexToRgb(geofenceType.color, geofenceType.fillOpacity);

		return <tr key={geofenceType.geofenceTypeId} className="content-box">
			<td>{geofenceType.name}</td>
			<td><div style={colorBlockStyle(color)} /></td>

			<td className="actions">
				<PopoverMenu
					renderOptions={() => renderActionMenu(geofenceType)}
				/>
			</td>
		</tr>;
	};

	const renderActionMenu = (geofenceType: C.IGeofenceTypeDto) => {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/geofences/types/${geofenceType.geofenceTypeId}/edit`}
			/>,
		];
	};

	return <ThingLoader
		load={load}
		render={geofenceTypes => <FixedWidthPage
			headingText="Geofence Types"
			noContentBackground
			headingActions={<Button href="/app/geofences/types/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
			contentClassName={classNames({ 'flex-fill-no-overflow': geofenceTypes.length === 0 })}
		>
			{geofenceTypes.length > 0 && <table className="card-table">
				<thead>
					<tr>
						<th>Name</th>
						<th>Appearance</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{geofenceTypes.map(renderGeofenceType)}
				</tbody>
			</table>}

			{geofenceTypes.length === 0 && <MessagePage
				title="No geofence types."
				action={<Button href="/app/geofences/types/add" text="Add a geofence type?" variant="outlined" />}
			/>}
		</FixedWidthPage>}
	/>;
});
