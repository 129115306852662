export enum AssetServiceReminderUserOrUserGroupAlertState {
	AlertForSelectedReminders,
	DoNotAlertForSelectedReminders,
	MixedAlertingForSelectedReminders, // This means at least one selected reminder has this user set to alert, and another has this user set to not alert.
}

export interface IAssetServiceReminderUserOrUserGroup {
	id: string;
	name: string;
	state: AssetServiceReminderUserOrUserGroupAlertState;
}

export interface IAssetServiceReminderUser extends IAssetServiceReminderUserOrUserGroup {
}

export interface IAssetServiceReminderUserGroup extends IAssetServiceReminderUserOrUserGroup {
}

// This method will take initial users or groups and the currently selected user groups and return which ones to add or delete.
export const getUsersOrUserGroupIdsToAddAndDelete = (initialUsersOrUserGroups: IAssetServiceReminderUserOrUserGroup[], currentUsersOrUserGroups: IAssetServiceReminderUserOrUserGroup[]) => {
	const usersOrUserGroupsToAdd: string[] = [];
	const usersOrUserGroupsToDelete: string[] = [];

	for (const currentUserGroupToAlert of currentUsersOrUserGroups) {
		const initialValue = initialUsersOrUserGroups.find(x => x.id === currentUserGroupToAlert.id);
		// If the user or user group initial alert state has changed it needs to be deleted or added.
		if (!initialValue || initialValue.state != currentUserGroupToAlert.state) {
			if (currentUserGroupToAlert.state === AssetServiceReminderUserOrUserGroupAlertState.AlertForSelectedReminders)
				usersOrUserGroupsToAdd.push(currentUserGroupToAlert.id);
			else
				usersOrUserGroupsToDelete.push(currentUserGroupToAlert.id);
		}
	}

	return {
		usersOrUserGroupsToAdd,
		usersOrUserGroupsToDelete,
	};
};
