import { inject, injectable } from 'inversify';
import moment from 'moment-timezone';

import * as C from './client';
import { Service } from './service';

@injectable()
export class CallService {
	constructor(
		@inject(Service.ApiClient) private client: C.Client,
	) {

	}

	async listCalls(offset: number, count: number, startTimestamp: moment.Moment, endTimestamp: moment.Moment, assetId?: string, callGroupId?: string, siteId?: string, networkDeviceId?:string): Promise<C.IListCallsResponse> {
		return await this.client.listCalls(startTimestamp, endTimestamp, assetId, callGroupId, siteId, networkDeviceId, offset, count);
	}

	async downloadCalls(callIds: C.IDownloadCallsRequest) {
		return await this.client.downloadCalls(new C.DownloadCallsRequest(callIds));
	}
}
