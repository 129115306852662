import React, { useState, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { Formik, Form, Field, FormikErrors, FormikProps } from 'formik';
import { InputAdornment } from '@material-ui/core';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField, FormikSelect, ErrorMessagePage, LoadingMessagePage, FormikAssetServiceReminderUserOrUserGroupsCheckboxSelector } from 'src/components';
import { undefinedIfUnchanged } from 'src/util';
import { getUsersOrUserGroupIdsToAddAndDelete,  AssetServiceReminderUserOrUserGroupAlertState, IAssetServiceReminderUser, IAssetServiceReminderUserGroup } from './assetServiceRemindersModelsAndHelperMethods';

import { useQueryAssetServiceReminderTypesForAssetServiceReminders, QueryAssetServiceReminderTypesForAssetServiceReminders_assetServiceReminderTypes } from 'src/graphql/__generated__/queries/queryAssetServiceReminderTypesForAssetServiceReminders';
import { QueryAssetServiceReminderById_assetServiceReminderById, QueryAssetServiceReminderById_assetServiceReminderById_users, QueryAssetServiceReminderById_assetServiceReminderById_userGroups } from 'src/graphql/__generated__/queries/queryAssetServiceReminderById';
import { useMutationAddAssetServiceReminder } from 'src/graphql/__generated__/mutations/mutationAddAssetServiceReminder';
import { useMutationUpdateAssetServiceReminder } from 'src/graphql/__generated__/mutations/mutationUpdateAssetServiceReminder';
import { useQueryAssetsForRemindersManage, QueryAssetsForRemindersManage_assets } from 'src/graphql/__generated__/queries/queryAssetsForRemindersManage';
import { useQueryUsersAndUserGroupsForAssetServiceRemindersSelector, QueryUsersAndUserGroupsForAssetServiceRemindersSelector_users, QueryUsersAndUserGroupsForAssetServiceRemindersSelector_userGroups } from 'src/graphql/__generated__/queries/queryUsersAndUserGroupsForAssetServiceRemindersSelector';
import { AssetTelematicsType } from 'src/../__generated__/globalTypes';

import {
	AuthenticationService,
	Client as C,
	DistanceService,
	DistanceUnit,
	HistoryService,
	useIdentityType,
	Service,
	TelematicsService,
	ToasterService,
	useInjection,
} from 'src/services';

export interface Props {
	assetServiceReminderTypeId?: string;
	assetServiceReminder?: QueryAssetServiceReminderById_assetServiceReminderById;
	assetId?: string;
}

export interface AssetServiceReminderFormValues {
	assetId: string | null;
	assetServiceReminderTypeId: string | null;
	nextDateTrigger: string;
	dateDefaultFrequency: number;
	nextOdometerTrigger: number;
	odometerDefaultFrequency: number;
	nextEngineTimeTrigger: number;
	engineTimeDefaultFrequency: number;
	extraInformation: string;
	usersToAlert: IAssetServiceReminderUser[];
	userGroupsToAlert: IAssetServiceReminderUserGroup[];
}

const useStyles = makeStyles({
	buttonContainer: {
		display: 'flex',
		columnGap: 'normal'
	},
	saveButton: {
		marginLeft: 'auto',
	},
	addMoreButton: {
		alignSelf: 'flex-start',
		maxWidth: '250px',
		width: '100%',
		marginTop: '15px',
		padding: '6px 16px',
		fontSize: '0.875rem',
		marginRight: 'auto',
	},
	currentTelematicsValueFormatted: {
		marginBottom: '10px',
		display: 'inline-block',
	}
});

export const ManageAssetServiceReminder = observer((props: Props) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const distanceService = useInjection<DistanceService>(Service.DistanceService);
	const historyService = useInjection<HistoryService>(Service.History);
	const telematicsService = useInjection<TelematicsService>(Service.Telematics);
	const toasterService = useInjection<ToasterService>(Service.Toaster);
	const identityType = useIdentityType();
	const includeUsers = identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer || (identityType === C.IdentityType.Client && !!authenticationService.currentAuth.user.identity.isAdmin);

	const [ addAssetServiceReminderMutation ] = useMutationAddAssetServiceReminder();
	const [ updateAssetServiceReminderMutation ] = useMutationUpdateAssetServiceReminder();

	const [selectedAsset, setSelectedAsset] = useState<QueryAssetsForRemindersManage_assets | null>(null);
	const [selectedAssetServiceReminderType, setSelectedAssetServiceReminderType] = useState<QueryAssetServiceReminderTypesForAssetServiceReminders_assetServiceReminderTypes | null>(null);
	const [initialValues, setInitialValues] = useState<AssetServiceReminderFormValues | undefined>(undefined);
	const [currentAssetOptions, setCurrentAssetOptions] = useState<QueryAssetsForRemindersManage_assets[]>([]);
	const [currentAssetServiceReminderTypesOptions, setCurrentAssetServiceReminderTypesOptions] = useState<QueryAssetServiceReminderTypesForAssetServiceReminders_assetServiceReminderTypes[]>([]);

	const allUsers = useRef<QueryUsersAndUserGroupsForAssetServiceRemindersSelector_users[]>([]);
	const allUserGroups = useRef<QueryUsersAndUserGroupsForAssetServiceRemindersSelector_userGroups[]>([]);

	const clientId = identityType === C.IdentityType.Client && !!authenticationService.currentAuth.user.identity.isAdmin ? authenticationService.currentAuth.user.identity.clientId : selectedAssetServiceReminderType?.client?.id;

	const currentAssetServiceReminderUsersToAlertOptions: IAssetServiceReminderUser[] = useMemo(() => {
		// Filter by client for dealers and super users, client should only be returned users and groups for their client.
		if (clientId && (identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer || (identityType === C.IdentityType.Client && !!authenticationService.currentAuth.user.identity.isAdmin))) {
			// Client admins will only get returned users/user groups they can manage.
			let currentUsersForThisClient = allUsers.current;
			if (identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer)
				currentUsersForThisClient = allUsers.current
					.filter(x => x.identity?.client?.id && x.identity.client.id === clientId);

			return currentUsersForThisClient.map(x => ({
				id: x.id,
				name: x.name,
				state: AssetServiceReminderUserOrUserGroupAlertState.DoNotAlertForSelectedReminders,
			}));
		}

		return [];
	}, [selectedAssetServiceReminderType, allUsers]);

	const currentAssetServiceReminderUserGroupsToAlertOptions: IAssetServiceReminderUser[] = useMemo(() => {
		// Filter by client for dealers and super users, client should only be returned users and groups for their client.
		if (clientId && (identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer || (identityType === C.IdentityType.Client && !!authenticationService.currentAuth.user.identity.isAdmin))) {
			// Client admins will only get returned users/user groups they can manage.
			let currentUserGroupsForThisClient = allUserGroups.current;
			if (identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer)
				currentUserGroupsForThisClient = allUserGroups.current
					.filter(x => x.client?.id === clientId);

			return currentUserGroupsForThisClient.map(x => ({
				id: x.id,
				name: x.name,
				state: AssetServiceReminderUserOrUserGroupAlertState.DoNotAlertForSelectedReminders,
			}));
		}

		return [];
	}, [selectedAssetServiceReminderType, allUserGroups]);

	const identityDistanceUnit = authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles;

	const assetServiceReminder = props.assetServiceReminder;

	const assetServiceReminderUsers = assetServiceReminder?.users?.filter(x => x !== null) as QueryAssetServiceReminderById_assetServiceReminderById_users[] ?? [];
	const assetServiceReminderUserGroups = assetServiceReminder?.userGroups?.filter(x => x !== null) as QueryAssetServiceReminderById_assetServiceReminderById_userGroups[] ?? [];

	const classNames = useStyles();

	const assetServiceReminderTypeQuery = useQueryAssetServiceReminderTypesForAssetServiceReminders({
		variables: {
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		},
	});

	const assetsQuery = useQueryAssetsForRemindersManage({
		variables: {
			includeClient: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		},
	});

	const usersAndUserGroupsQuery = useQueryUsersAndUserGroupsForAssetServiceRemindersSelector({
		variables: {
			includeUsers: includeUsers,
			includeUserGroups: includeUsers,
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		}
	});

	let initialAssetId: string | null = assetServiceReminder?.asset.id ?? props.assetId;
	let initialAssetServiceReminderTypeId: string | null = assetServiceReminder?.assetServiceReminderType.id ?? props.assetServiceReminderTypeId;

	if (assetsQuery.loading || assetServiceReminderTypeQuery.loading || usersAndUserGroupsQuery.loading)
		return <LoadingMessagePage />;

	if (assetsQuery.error || assetServiceReminderTypeQuery.error || !assetsQuery.data?.assets || !assetServiceReminderTypeQuery.data?.assetServiceReminderTypes || !usersAndUserGroupsQuery.data?.users || !usersAndUserGroupsQuery.data?.userGroups)
		return <ErrorMessagePage />;

	const allAssets = assetsQuery.data.assets;
	const allAssetServiceReminderTypes = assetServiceReminderTypeQuery.data.assetServiceReminderTypes;

	if (!initialValues) {

		allUsers.current = usersAndUserGroupsQuery.data.users;
		allUserGroups.current = usersAndUserGroupsQuery.data.userGroups;

		let initialAsset: QueryAssetsForRemindersManage_assets | null = null;
		if (initialAssetServiceReminderTypeId && !selectedAssetServiceReminderType) {
			const reminderType = allAssetServiceReminderTypes.find(x => x.id == initialAssetServiceReminderTypeId);

			if (reminderType) {
				setCurrentAssetServiceReminderTypesOptions([reminderType]);
				setSelectedAssetServiceReminderType(reminderType);

				// Don't filter if the user is a client, they will only be sent the assets they can access.
				const assetOptions = authenticationService.currentAuth.user.identity.type === C.IdentityType.Client ? allAssets : allAssets.filter(x => x.client != null && x.client.id === reminderType.client?.id);
				setCurrentAssetOptions(assetOptions);

				// Pick the first asset as the initial value if there is a asset.
				if (initialAssetId) {
					initialAsset = allAssets.find(x => x.id == initialAssetId) ??  null;
				} else if (assetOptions.length > 0) {
					initialAssetId = assetOptions[0].id;
					initialAsset = assetOptions[0];
				}
				setSelectedAsset(initialAsset);
			}
		}

		if (initialAssetId && !selectedAsset && !props.assetServiceReminderTypeId) {
			initialAsset = allAssets.find(x => x.id == initialAssetId) ?? null;
			if (initialAsset) {
				const clientId = initialAsset.client?.id;
				setCurrentAssetOptions([initialAsset]);
				setSelectedAsset(initialAsset);

				// Don't filter if the user is a client, they will only be sent the reminder types they can access.
				const assetServiceReminderTypeOptions = authenticationService.currentAuth.user.identity.type === C.IdentityType.Client ? allAssetServiceReminderTypes : allAssetServiceReminderTypes.filter(x => x.client != null && x.client.id === clientId);
				setCurrentAssetServiceReminderTypesOptions(assetServiceReminderTypeOptions);

				// Pick the first asset service reminder type as the initial value if there is a type.
				if (assetServiceReminderTypeOptions.length > 0) {
					setSelectedAssetServiceReminderType(assetServiceReminderTypeOptions[0]);
					initialAssetServiceReminderTypeId = assetServiceReminderTypeOptions[0].id;
				}
			}
		}

		const initalOdoValue = assetServiceReminder?.nextOdometerTrigger ? assetServiceReminder.nextOdometerTrigger : getTelematicsValueForAnAsset(initialAsset, AssetTelematicsType.ODOMETER);
		const initalEngineTimeValue = assetServiceReminder?.nextEngineTimeTrigger ? assetServiceReminder.nextEngineTimeTrigger : getTelematicsValueForAnAsset(initialAsset, AssetTelematicsType.ENGINE_TIME);
		const initialValues: AssetServiceReminderFormValues = {
			assetId: initialAssetId,
			assetServiceReminderTypeId: initialAssetServiceReminderTypeId,
			nextDateTrigger: assetServiceReminder?.nextDateTriggerDate ? moment(assetServiceReminder.nextDateTriggerDate).format('YYYY-MM-DD') :  moment.tz(authenticationService.currentAuth.user.timeZone).add(1, 'day').format('YYYY-MM-DD'),
			dateDefaultFrequency: assetServiceReminder?.dateDefaultFrequency ? assetServiceReminder.dateDefaultFrequency : 7,
			nextOdometerTrigger: distanceService.getDistance(initalOdoValue, identityDistanceUnit),
			odometerDefaultFrequency: assetServiceReminder?.odometerDefaultFrequency ? distanceService.getDistance(assetServiceReminder.odometerDefaultFrequency, identityDistanceUnit) : 5000,
			nextEngineTimeTrigger: Math.round(initalEngineTimeValue / 60),
			engineTimeDefaultFrequency: assetServiceReminder?.engineTimeDefaultFrequency ?  Math.round(assetServiceReminder.engineTimeDefaultFrequency / 60) : 100,
			extraInformation: assetServiceReminder?.extraInformation || '',
			usersToAlert: assetServiceReminderUsers.map(x => ({
				id: x.id,
				name: x.name,
				state: AssetServiceReminderUserOrUserGroupAlertState.AlertForSelectedReminders,
			})),
			userGroupsToAlert: assetServiceReminderUserGroups.map(x => ({
				id: x.id,
				name: x.name,
				state: AssetServiceReminderUserOrUserGroupAlertState.AlertForSelectedReminders,
			})),
		};

		setInitialValues(initialValues);
	}

	if (!initialValues)
		return <ErrorMessagePage />;

	const validateForm = (values: AssetServiceReminderFormValues) => {
		const errors: FormikErrors<AssetServiceReminderFormValues> = {};

		if (!values.assetId || !selectedAsset)
			errors.assetId = 'Selecting an asset is required.';

		if (!values.assetServiceReminderTypeId || !selectedAssetServiceReminderType)
			errors.assetServiceReminderTypeId = 'Selecting an asset service reminder type is required.';

		if (selectedAssetServiceReminderType?.dateEnabled) {
			const nextDateTriggerAsDate = moment(values.nextDateTrigger);
			if (!nextDateTriggerAsDate.isValid())
				errors.nextDateTrigger = 'Next date trigger is not valid.';

			if (values.dateDefaultFrequency <= 0 || values.dateDefaultFrequency > 365)
				errors.dateDefaultFrequency = 'Default frequency is required to be greater then 1 but less then or equal to 365.';
		}

		if (selectedAssetServiceReminderType?.odometerEnabled) {
			if (!values.nextOdometerTrigger || values.nextOdometerTrigger <= 0)
				errors.nextOdometerTrigger = 'Next odometer trigger must be greater than 0.';

			if (values.assetId && selectedAsset) {
				const currentOdometerReading = getTelematicsValueForAnAsset(selectedAsset, AssetTelematicsType.ODOMETER);
				const currentOdoInMetres = currentOdometerReading ? distanceService.getDistance(currentOdometerReading, identityDistanceUnit) : undefined;

				if (!currentOdoInMetres)
					errors.nextOdometerTrigger = 'No odometer reading available for the selected asset.';
			}

			if (values.odometerDefaultFrequency <= 0)
				errors.odometerDefaultFrequency = 'Default frequency is required to be greater then 1.';
		}

		if (selectedAssetServiceReminderType?.engineTimeEnabled) {
			if (!values.nextEngineTimeTrigger || values.nextEngineTimeTrigger <= 0)
				errors.nextEngineTimeTrigger = 'Next engine time trigger must be greater than 0.';

			if (values.assetId) {
				const currentEngineTimeReading = getTelematicsValueForAnAsset(selectedAsset, AssetTelematicsType.ENGINE_TIME);
				const currentEngineTimeInHours = currentEngineTimeReading ? Math.round(currentEngineTimeReading / 60) : undefined;

				if (!currentEngineTimeInHours)
					errors.nextEngineTimeTrigger = 'No engine time reading available for the selected asset.';
			}

			if (values.engineTimeDefaultFrequency <= 0)
				errors.engineTimeDefaultFrequency = 'Default frequency is required to be greater then 1.';
		}

		return errors;
	};

	const submit = async (values: AssetServiceReminderFormValues) => {
		const success = await addOrEditAssetServiceReminder(values);

		if (success) {
			if (props.assetServiceReminderTypeId)
				historyService.history.push(`/app/asset-service-reminders/${values.assetServiceReminderTypeId}/list`);
			else
				historyService.history.push(`/app/vehicle-information/${values.assetId}`);

			toasterService.showSuccess('Asset service reminder saved.');
		}
	};

	const addOrEditAssetServiceReminder = async (values: AssetServiceReminderFormValues) => {
		try {
			const nextDateTrigger = selectedAssetServiceReminderType!.dateEnabled ? values.nextDateTrigger : undefined;
			const nextOdometerTrigger = selectedAssetServiceReminderType!.odometerEnabled ? distanceService.getMetresFromDistance(values.nextOdometerTrigger, identityDistanceUnit) : undefined;
			const defaultOdoFrequency = selectedAssetServiceReminderType!.odometerEnabled ? distanceService.getMetresFromDistance(values.odometerDefaultFrequency, identityDistanceUnit) : undefined;
			const nextEngineTimeTrigger = selectedAssetServiceReminderType!.engineTimeEnabled ? Math.round(values.nextEngineTimeTrigger * 60) : undefined;
			const defaultEngineTimeFrequency = selectedAssetServiceReminderType!.engineTimeEnabled ? Math.round(values.engineTimeDefaultFrequency * 60) : undefined;

			if (!assetServiceReminder) {
				await addAssetServiceReminderMutation({
					variables: {
						input: {
							assetId: values.assetId,
							assetServiceReminderTypeId: values.assetServiceReminderTypeId,
							nextDateTrigger: nextDateTrigger,
							dateDefaultFrequency: values.dateDefaultFrequency,
							nextOdometerTrigger: nextOdometerTrigger,
							odometerDefaultFrequency: defaultOdoFrequency,
							nextEngineTimeTrigger: nextEngineTimeTrigger,
							engineTimeDefaultFrequency: defaultEngineTimeFrequency,
							extraInformation: values.extraInformation ?? undefined,
							usersIds: values.usersToAlert.map(x => x.id),
							usersGroupIds: values.userGroupsToAlert.map( x => x.id),
						}
					}
				});
			} else {
				// Figure out which user groups have changed, to decide what users to add or delete.
				const usersToAddAndDelete = getUsersOrUserGroupIdsToAddAndDelete(initialValues.usersToAlert, values.usersToAlert);
				const userGroupsToAddAndDelete = getUsersOrUserGroupIdsToAddAndDelete(initialValues.userGroupsToAlert, values.userGroupsToAlert);

				await updateAssetServiceReminderMutation({
					variables: {
						input: {
							assetReminderId: assetServiceReminder.id,
							nextDateTrigger: undefinedIfUnchanged(assetServiceReminder.nextDateTriggerDate, nextDateTrigger),
							dateDefaultFrequency: undefinedIfUnchanged(assetServiceReminder.dateDefaultFrequency, values.dateDefaultFrequency),
							nextOdometerTrigger: undefinedIfUnchanged(assetServiceReminder.nextOdometerTrigger, nextOdometerTrigger),
							odometerDefaultFrequency: undefinedIfUnchanged(assetServiceReminder.odometerDefaultFrequency, defaultOdoFrequency),
							nextEngineTimeTrigger: undefinedIfUnchanged(assetServiceReminder.nextEngineTimeTrigger, nextEngineTimeTrigger),
							engineTimeDefaultFrequency: undefinedIfUnchanged(assetServiceReminder.engineTimeDefaultFrequency, defaultEngineTimeFrequency),
							extraInformation: undefinedIfUnchanged(assetServiceReminder.extraInformation, values.extraInformation),
							usersToAdd: usersToAddAndDelete.usersOrUserGroupsToAdd,
							usersToDelete: usersToAddAndDelete.usersOrUserGroupsToDelete,
							userGroupsToAdd: userGroupsToAddAndDelete.usersOrUserGroupsToAdd,
							userGroupsToDelete: userGroupsToAddAndDelete.usersOrUserGroupsToDelete,
						}
					}
				});
			}

			return true;
		} catch (err) {
			toasterService.handleWithToast(err, `Failed to ${assetServiceReminder ? 'edit' : 'add'} asset service reminder.`);
			return false;
		}
	};

	function getFormattedTelematicsValue(telematicsType: AssetTelematicsType) {
		if (telematicsType === AssetTelematicsType.ENGINE_TIME) {
			const currentEngineTime = getTelematicsValueForAnAsset(selectedAsset, AssetTelematicsType.ENGINE_TIME);
			return telematicsService.getFormattedEngineTimeTelematicsReadingValue(currentEngineTime);
		}

		const currentOdometerReading = getTelematicsValueForAnAsset(selectedAsset, AssetTelematicsType.ODOMETER);
		return telematicsService.getFormattedOdometerTelematicsReadingValue(currentOdometerReading, identityDistanceUnit);
	}

	function getAndSetTelematicsValuesBasedOnCurrent(asset: QueryAssetsForRemindersManage_assets | null, values: AssetServiceReminderFormValues) {
		if (!assetServiceReminder && asset) {
			const currentOdometerReading = getTelematicsValueForAnAsset(asset, AssetTelematicsType.ODOMETER);
			const currentEngineTime = getTelematicsValueForAnAsset(asset, AssetTelematicsType.ENGINE_TIME);

			let odometerValue = 0;
			if (currentOdometerReading)
				odometerValue = distanceService.getDistance(currentOdometerReading, identityDistanceUnit);

			let engineTimeValue = 0;
			if (currentEngineTime)
				engineTimeValue = Math.round((currentEngineTime) / 60);

			values.nextOdometerTrigger = odometerValue;
			values.nextEngineTimeTrigger = engineTimeValue;
		}

		return values;
	}

	function onChangeAsset(assetId: string, formikProps: FormikProps<AssetServiceReminderFormValues>) {
		const asset = allAssets.find(x => x.id == assetId) ?? null;
		setSelectedAsset(asset);

		const newValues = getAndSetTelematicsValuesBasedOnCurrent(asset, formikProps.values);
		newValues.assetId = assetId;

		formikProps.setValues(newValues);
	}

	function onChangeAssetServiceReminderType(assetServiceReminderId: string, formikProps: FormikProps<AssetServiceReminderFormValues>) {
		const index = allAssetServiceReminderTypes.findIndex(x => x.id == assetServiceReminderId);
		if (index >= 0)
			setSelectedAssetServiceReminderType(allAssetServiceReminderTypes[index]);

		const newValues = getAndSetTelematicsValuesBasedOnCurrent(selectedAsset, formikProps.values);
		newValues.assetServiceReminderTypeId = assetServiceReminderId;

		formikProps.setValues(newValues);
	}

	function getTelematicsValueForAnAsset(asset: QueryAssetsForRemindersManage_assets | null, type: AssetTelematicsType) {
		return asset?.assetTelematicsCurrentValueAndReadings?.find(x => x.type === type)?.value ?? 0;
	}

	return <FixedWidthPage
		headingText={`${assetServiceReminder ? 'Edit' : 'Add'} Asset Service Reminder`}
		className="form-page"
		pageItemId={assetServiceReminder?.id}
		noContentBackground
	>
		<div className="content-box">
			<Formik
				initialValues={initialValues}
				validate={validateForm}
				validateOnChange={false}
				onSubmit={submit}
			>
				{formikProps => <Form className="formik-form">
					<FormikSelect
						name="assetId"
						label="Asset"
						placeholder="Select a asset..."
						onChange={(e) => !Array.isArray(e) && e ? onChangeAsset(e.id, formikProps) : {}}
						options={currentAssetOptions}
						form={formikProps}
						getOptionLabel={option => option.name}
						getOptionValue={option => option.id}
						required
						disabled={!!assetServiceReminder || !!props.assetId}
					/>

					<FormikSelect
						name="assetServiceReminderTypeId"
						label="Asset Service Reminder Type"
						placeholder="Select a service reminder type..."
						onChange={(e) => !Array.isArray(e) && e ? onChangeAssetServiceReminderType(e.id, formikProps) : {}}
						options={currentAssetServiceReminderTypesOptions}
						form={formikProps}
						getOptionLabel={option => option.name}
						getOptionValue={option => option.id}
						required
						disabled={!!assetServiceReminder || !!props.assetServiceReminderTypeId}
					/>

					{allAssetServiceReminderTypes.length <= 0 && <Alert severity="warning">
						No asset service reminder type available, please create a asset service reminder before adding a asset service reminder.
					</Alert>}

					{selectedAssetServiceReminderType?.dateEnabled && <Field
						name="nextDateTrigger"
						label="Next date trigger"
						type="date"
						component={FormikTextField}
						required
						disabled={!selectedAssetServiceReminderType.dateEnabled}
					/>}

					{selectedAssetServiceReminderType?.dateEnabled && <Field
						name="dateDefaultFrequency"
						label="Default frequency"
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="end">days</InputAdornment>,
						}}
						component={FormikTextField}
					/>}

					{selectedAssetServiceReminderType?.odometerEnabled && <div>
						<span className={classNames.currentTelematicsValueFormatted}>{getFormattedTelematicsValue(AssetTelematicsType.ODOMETER)}</span>

						<Field
							name="nextOdometerTrigger"
							label="Next odometer trigger"
							type="number"
							InputProps={{
								endAdornment: <InputAdornment position="end">{authenticationService.currentAuth.user.usesMetric ? 'km' : 'mi' }</InputAdornment>,
							}}
							component={FormikTextField}
							required
						/>
					</div>}

					{selectedAssetServiceReminderType?.odometerEnabled && <Field
						name="odometerDefaultFrequency"
						label="Default frequency"
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="end">{authenticationService.currentAuth.user.usesMetric ? 'km' : 'mi' }</InputAdornment>,
						}}
						component={FormikTextField}
					/>}

					{selectedAssetServiceReminderType?.engineTimeEnabled && <div>
						<span className={classNames.currentTelematicsValueFormatted}>{getFormattedTelematicsValue(AssetTelematicsType.ENGINE_TIME)}</span>

						<Field
							name="nextEngineTimeTrigger"
							label="Next engine time trigger"
							type="number"
							InputProps={{
								endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
							}}
							component={FormikTextField}
							required
						/>
					</div>}

					{selectedAssetServiceReminderType?.engineTimeEnabled && <Field
						name="engineTimeDefaultFrequency"
						label="Default frequency"
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
						}}
						component={FormikTextField}
					/>}

					{formikProps.errors.nextEngineTimeTrigger?.includes('No engine time reading') || formikProps.errors.nextOdometerTrigger?.includes('No odometer reading') && <Alert severity="warning">
						Please enter the missing reading value on the 'Vehicle Information' page.
					</Alert>}

					<FormikAssetServiceReminderUserOrUserGroupsCheckboxSelector
						name="usersToAlert"
						options={currentAssetServiceReminderUsersToAlertOptions}
						form={formikProps}
					/>

					<FormikAssetServiceReminderUserOrUserGroupsCheckboxSelector
						name="userGroupsToAlert"
						label="User Groups To Alert"
						options={currentAssetServiceReminderUserGroupsToAlertOptions}
						form={formikProps}
					/>

					<Field
						name="extraInformation"
						label="Extra Information"
						type="text"
						component={FormikTextField}
						multiline
						minRows={3}
					/>

					<div className={classNames.buttonContainer}>
						<Button
							className={classNames.saveButton}
							type="submit"
							variant="contained"
							color="primary"
							loading={formikProps.isSubmitting}
							startIcon={assetServiceReminder ? <SaveIcon /> : null }
							text={assetServiceReminder ? 'Save Changes' : 'Add'}
						/>
					</div>
				</Form>}
			</Formik>
		</div>
	</FixedWidthPage>;
});
