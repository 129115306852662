import React from 'react';
import { makeStyles } from '@material-ui/core';
import { red, orange, green  } from '@material-ui/core/colors';
import classNames from 'classnames';

import { AssetServiceReminderState } from 'src/../__generated__/globalTypes';

export interface AssetServiceReminderProps {
	state: AssetServiceReminderState;
	whiteBackgroundColour?: boolean;
	count?: number;
	size?: 'small' | 'medium' | 'large';
}

export const AssetServiceReminderStateBubble = ((props: AssetServiceReminderProps) => {
	let fontSize = 120;
	let bubbleSize = '32px';
	if (props.size === 'small') {
		bubbleSize = '26px';
		fontSize = 110;
	} else if (props.size === 'large') {
		bubbleSize = '38px';
		fontSize = 130;
	}

	if (props.count && props.count > 100)
		fontSize = fontSize * 0.80;

	const useStyles = makeStyles({
		circle: {
			borderRadius: '50%',
			width: bubbleSize,
			height: bubbleSize,
			justifySelf: 'center',
			fontSize: `${fontSize}%`,
			fontWeight: 600,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			border: `2px solid`,
		},
		notExpiredCircle: {
			color: green[800],
			backgroundColor: getBackgroundColour(AssetServiceReminderState.NOT_EXPIRED),
		},
		aboutToExpireCircle: {
			color: orange[500],
			backgroundColor: getBackgroundColour(AssetServiceReminderState.WITHIN_REMINDER_PERIOD),
		},
		expiredCircle: {
			color: red[500],
			backgroundColor: getBackgroundColour(AssetServiceReminderState.EXPIRED),
		},
	});

	function getBackgroundColour(state: AssetServiceReminderState) {
		if (props.whiteBackgroundColour)
			return 'white';

		switch (state) {
			case AssetServiceReminderState.NOT_EXPIRED:
				return props.count == undefined ? green[500] : undefined;

			case AssetServiceReminderState.WITHIN_REMINDER_PERIOD:
				return props.count == undefined ? orange[300] : undefined;

			case AssetServiceReminderState.EXPIRED:
				return props.count == undefined ? red[200] : undefined;
		}
	}

	const classes = useStyles();

	let className = '';
	let message = '';
	switch (props.state) {
		case AssetServiceReminderState.NOT_EXPIRED:
			className = classes.notExpiredCircle;
			message = props.count != undefined ? 'Number of assets which expired service reminders which are not expired.' : 'Not Expired';
			break;

		case AssetServiceReminderState.WITHIN_REMINDER_PERIOD:
			className = classes.aboutToExpireCircle;
			message = props.count != undefined ? 'Number of assets which have a service reminder that is about to expire.' : 'Within Reminder Period';
			break;

		case AssetServiceReminderState.EXPIRED:
			className = classes.expiredCircle;
			message = props.count != undefined ? 'Number of assets which have expired service reminders.' : 'Expired';
			break;
	}

	return <span
		className={classNames(classes.circle, className)}
		title={message}
	>
		{props.count}
	</span>;
});
