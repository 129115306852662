import React from 'react';

import { MapMarkerIcon } from 'src/../__generated__/globalTypes';

import * as C from 'src/services/client';

const airplaneIcon = require('!svg-inline-loader?classPrefix!../resources/icons/airplanemode_active-24px.svg');
const boatIcon = require('!svg-inline-loader?classPrefix!../resources/icons/directions_boat-24px.svg');
const busIcon = require('!svg-inline-loader?classPrefix!../resources/icons/directions_bus-24px.svg');
const bikeIcon = require('!svg-inline-loader?classPrefix!../resources/icons/pedal_bike-24px.svg');
const carIcon = require('!svg-inline-loader?classPrefix!../resources/icons/directions_car-24px.svg');
const cameraIcon = require('!svg-inline-loader?classPrefix!../resources/icons/camera.svg');
const electricCarIcon = require('!svg-inline-loader?classPrefix!../resources/icons/electric_car-24px.svg');
const errorIcon = require('!svg-inline-loader?classPrefix!../resources/icons/error.svg');
const enforementIcon = require('!svg-inline-loader?classPrefix!../resources/icons/local_police-24px.svg');
const gpsTrackerIcon = require('!svg-inline-loader?classPrefix!../resources/icons/my_location-24px.svg');
const houseIcon = require('!svg-inline-loader?classPrefix!../resources/icons/house.svg');
const mobileRadioIcon = require('!svg-inline-loader?classPrefix!../resources/icons/mobile-radio-24px.svg');
const mopedIcon = require('!svg-inline-loader?classPrefix!../resources/icons/moped-24px.svg');
const motorcycleIcon = require('!svg-inline-loader?classPrefix!../resources/icons/two_wheeler-24px.svg');
const truckIcon = require('!svg-inline-loader?classPrefix!../resources/icons/local_shipping-24px.svg');
const personIcon = require('!svg-inline-loader?classPrefix!../resources/icons/person-24px.svg');
const personWalkingIcon = require('!svg-inline-loader?classPrefix!../resources/icons/directions_walk-24px.svg');
const phoneIcon = require('!svg-inline-loader?classPrefix!../resources/icons/phone_android-24px.svg');
const portableRadioIcon = require('!svg-inline-loader?classPrefix!../resources/icons/portable-radio-24px.svg');
const questionMarkIcon = require('!svg-inline-loader?classPrefix!../resources/icons/question_mark.svg');
const shuttleIcon = require('!svg-inline-loader?classPrefix!../resources/icons/airport_shuttle-24px.svg');
const taxiIcon = require('!svg-inline-loader?classPrefix!../resources/icons/local_taxi-24px.svg');
const trainIcon = require('!svg-inline-loader?classPrefix!../resources/icons/train-24px.svg');
const tractorIcon = require('!svg-inline-loader?classPrefix!../resources/icons/agriculture-24px.svg');
const trailerIcon = require('!svg-inline-loader?classPrefix!../resources/icons/trailer.svg');
const warningIcon = require('!svg-inline-loader?classPrefix!../resources/icons/warning.svg');

export interface IMapMarkerIconOption {
	label: string;
	MapMarkerIcon: C.MapMarkerIcon;
	mapMarkerIcon: MapMarkerIcon | null;
	svg: any | null;
	asset?: boolean;
	customMarker?: boolean;
}

export const mapMarkerIconOptions: IMapMarkerIconOption[] = [
	{
		MapMarkerIcon: C.MapMarkerIcon.None,
		mapMarkerIcon: null,
		label: 'None',
		svg: null,
		asset: true,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Airplane,
		mapMarkerIcon: MapMarkerIcon.AIRPLANE,
		label: 'Airplane',
		svg: airplaneIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Boat,
		mapMarkerIcon: MapMarkerIcon.BOAT,
		label: 'Boat',
		svg: boatIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Bus,
		mapMarkerIcon: MapMarkerIcon.BUS,
		label: 'Bus',
		svg: busIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Bike,
		mapMarkerIcon: MapMarkerIcon.BIKE,
		label: 'Push Bike',
		svg: bikeIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Camera,
		mapMarkerIcon: MapMarkerIcon.CAMERA,
		label: 'Camera',
		svg: cameraIcon,
		asset: false,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Car,
		mapMarkerIcon: MapMarkerIcon.CAR,
		label: 'Car',
		svg: carIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.ElectricCar,
		mapMarkerIcon: MapMarkerIcon.ELECTRIC_CAR,
		label: 'Electric Car',
		svg: electricCarIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Enforcement,
		mapMarkerIcon: MapMarkerIcon.ENFORCEMENT,
		label: 'Enforcement',
		svg: enforementIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Error,
		mapMarkerIcon: MapMarkerIcon.ERROR,
		label: 'Error',
		svg: errorIcon,
		asset: false,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.GpsTracker,
		mapMarkerIcon: MapMarkerIcon.GPS_TRACKER,
		label: 'GPS Tracker',
		svg: gpsTrackerIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.House,
		mapMarkerIcon: MapMarkerIcon.HOUSE,
		label: 'House',
		svg: houseIcon,
		asset: false,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.MobileRadio,
		mapMarkerIcon: MapMarkerIcon.MOBILE_RADIO,
		label: 'Mobile Radio',
		svg: mobileRadioIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Moped,
		mapMarkerIcon: MapMarkerIcon.MOPED,
		label: 'Moped',
		svg: mopedIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Motorcycle,
		mapMarkerIcon: MapMarkerIcon.MOTORCYCLE,
		label: 'Motorcycle',
		svg: motorcycleIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Truck,
		mapMarkerIcon: MapMarkerIcon.TRUCK,
		label: 'Truck',
		svg: truckIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Person,
		mapMarkerIcon: MapMarkerIcon.PERSON,
		label: 'Person',
		svg: personIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.PersonWalking,
		mapMarkerIcon: MapMarkerIcon.PERSON_WALKING,
		label: 'Person Walking',
		svg: personWalkingIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Phone,
		mapMarkerIcon: MapMarkerIcon.PHONE,
		label: 'Phone',
		svg: phoneIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.PortableRadio,
		mapMarkerIcon: MapMarkerIcon.PORTABLE_RADIO,
		label: 'Portable Radio',
		svg: portableRadioIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.QuestionMark,
		mapMarkerIcon: MapMarkerIcon.QUESTION_MARK,
		label: 'Question Mark',
		svg: questionMarkIcon,
		asset: false,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Shuttle,
		mapMarkerIcon: MapMarkerIcon.SHUTTLE,
		label: 'Shuttle',
		svg: shuttleIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Taxi,
		mapMarkerIcon: MapMarkerIcon.TAXI,
		label: 'Taxi',
		svg: taxiIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Train,
		mapMarkerIcon: MapMarkerIcon.TRAIN,
		label: 'Train',
		svg: trainIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Tractor,
		mapMarkerIcon: MapMarkerIcon.TRACTOR,
		label: 'Tractor',
		svg: tractorIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Trailer,
		mapMarkerIcon: MapMarkerIcon.TRAILER,
		label: 'Trailer',
		svg: trailerIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Warning,
		mapMarkerIcon: MapMarkerIcon.WARNING,
		label: 'Warning',
		svg: warningIcon,
		asset: false,
		customMarker: true,
	},
];

export function renderMapMarkerIcon(parentElement: HTMLDivElement, iconShape: C.MapMarkerIcon | undefined, iconSize?: number | null, iconColor?: string | null) {
	const svg = mapMarkerIconOptions.find(x => x.MapMarkerIcon === iconShape)?.svg;
	if (!svg)
		return;

	parentElement.innerHTML = svg;

	const svgIcon = parentElement.getElementsByTagName('svg');
	if (svgIcon.length > 0 && iconSize && iconColor) {
		svgIcon[0].style.width = `${iconSize}px`;
		svgIcon[0].style.height = `${iconSize}px`;
		svgIcon[0].style.fill = iconColor;
		svgIcon[0].style.zIndex = '5';
		svgIcon[0].style.position = 'absolute';
	}
}

function renderMapMarkerIconOptionSvg(parentElement: HTMLDivElement | null, svg: any) {
	if (!parentElement)
		return;

	const oldIcons = parentElement.getElementsByClassName('asset-type-selector-icon');
	if (oldIcons.length > 0) {
		for (let i = 0; i < oldIcons.length; i++) {
			parentElement.removeChild(oldIcons[i]);
		}
	}

	const iconHtmlDiv = document.createElement('div');
	iconHtmlDiv.innerHTML = svg;

	iconHtmlDiv.className = 'asset-type-selector-icon';
	iconHtmlDiv.style.width = '20px';
	iconHtmlDiv.style.height = '20px';
	iconHtmlDiv.style.marginRight = '10px';

	parentElement.appendChild(iconHtmlDiv);
}

export function renderMapMarkerIconOption(option: IMapMarkerIconOption) : React.ReactNode {
	return <>
		<div ref={(divElement: HTMLDivElement) => renderMapMarkerIconOptionSvg(divElement, option.svg)} />
		<span>{option.label}</span>
	</>;
}
