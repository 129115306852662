import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { Button } from './button';
import { Typography } from '@material-ui/core';

interface Props {
	className?: string;
	hours: number;
	minutes: number;
	onChange: (hours: number, minutes: number) => void;
}

const useStyles = makeStyles({
	root: {
		display: 'grid',
		gridTemplateColumns: '1fr 25px 1fr',
		maxWidth: '200px',
		alignSelf: 'center',
	},
	entryField: {
		'& *': {
			textAlign: 'center',
		}
	},
	hour: {
		gridColumnStart: 1,
		gridColumnEnd: 2,
	},
	separator: {
		gridColumnStart: 2,
		gridColumnEnd: 3,
		textAlign: 'center',
	},
	minute: {
		gridColumnStart: 3,
		gridColumnEnd: 4,
	}
});

interface FieldThing {
	value: string;
	valid: boolean;
	hasFocus: boolean;
}

const getValueIfValid = (input: string, min: number, max: number) => {
	if (input === '')
		return null;

	const inputNumber = +input;
	if (inputNumber < min || inputNumber > max)
		return null;

	return inputNumber;
};

export const DurationPicker = (props: Props) => {
	const classes = useStyles();

	const [hours, setHours] = React.useState<FieldThing>({ value: props.hours.toString(), valid: true, hasFocus: false });
	const [minutes, setMinutes] = React.useState<FieldThing>({ value: props.minutes.toString(), valid: true, hasFocus: false });

	const onFocusHours = () => {
		setHours({ value: props.hours.toString(), valid: true, hasFocus: true });
	};

	const onFocusMinutes = () => {
		setMinutes({ value: props.minutes.toString(), valid: true, hasFocus: true });
	};

	const onBlurHours = () => {
		setHours({
			value: (hours.valid ? +hours.value : props.hours).toString(),
			valid: true,
			hasFocus: false,
		});
	};

	const onBlurMinutes = () => {
		setMinutes({
			value: (minutes.valid ? +minutes.value : props.minutes).toString(),
			valid: true,
			hasFocus: false,
		});
	};

	const onChangeHours = (event: React.ChangeEvent<HTMLInputElement>) => {
		const stringHours = event.target.value.replace(/\D/g, '');
		const newHours = { ...hours, value: stringHours, valid: false };

		const newValue = getValueIfValid(stringHours, 0, 23);
		if (newValue != null) {
			props.onChange(newValue, props.minutes);
			newHours.valid = true;
		}

		setHours(newHours);
	};

	const onChangeMinutes = (event: React.ChangeEvent<HTMLInputElement>) => {
		const stringMinutes = event.target.value.replace(/\D/g, '');
		const newMinutes = { ...minutes, value: stringMinutes, valid: false };

		const newValue = getValueIfValid(stringMinutes, 0, 59);
		if (newValue != null) {
			props.onChange(props.hours, newValue);
			newMinutes.valid = true;
		}

		setMinutes(newMinutes);
	};

	return <div className={classNames(props.className, classes.root)}>
		<Button
			className={classes.hour}
			text={<KeyboardArrowUpIcon />}
			onClick={() => props.onChange(Math.min(23, props.hours + 1), props.minutes)}
		/>

		<Button
			className={classes.minute}
			text={<KeyboardArrowUpIcon />}
			onClick={() => props.onChange(props.hours, Math.min(59, props.minutes + 1))}
		/>

		<TextField
			className={classNames(classes.entryField, classes.hour)}
			value={hours.hasFocus ? hours.value : props.hours}
			fullWidth
			onChange={onChangeHours}
			onFocus={onFocusHours}
			onBlur={onBlurHours}
			error={!hours.valid}
		/>

		<Typography className={classes.separator}>:</Typography>

		<TextField
			className={classNames(classes.entryField, classes.minute)}
			value={minutes.hasFocus ? minutes.value : props.minutes}
			fullWidth
			onChange={onChangeMinutes}
			onFocus={onFocusMinutes}
			onBlur={onBlurMinutes}
			error={!minutes.valid}
		/>

		<Button
			className={classes.hour}
			text={<KeyboardArrowDownIcon />}
			onClick={() => props.onChange(Math.max(0, props.hours - 1), props.minutes)}
		/>

		<Button
			className={classes.minute}
			text={<KeyboardArrowDownIcon />}
			onClick={() => props.onChange(props.hours, Math.max(0, props.minutes - 1))}
		/>
	</div>;
};
