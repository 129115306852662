import { injectable } from 'inversify';

export enum DistanceUnit {
	Metres,
	Kilometres,
	Yards,
	Miles,
}

export enum SpeedUnit {
	KilometresPerHour,
	MilesPerHour,
}

@injectable()
export class DistanceService {

	getDistance(metres: number, unit: DistanceUnit) {
		switch (unit) {
			case DistanceUnit.Metres:
				return metres;

			case DistanceUnit.Kilometres:
				return metres * 0.001;

			case DistanceUnit.Miles:
				return metres * 0.00062137119223733;

			case DistanceUnit.Yards:
				return metres * 1.09361;
		}
	}

	getMetresFromDistance(value: number, valueDistanceUnitType: DistanceUnit) {
		switch (valueDistanceUnitType) {
			case DistanceUnit.Metres:
				return value;

			case DistanceUnit.Kilometres:
				return value * 1000;

			case DistanceUnit.Miles:
				return value * 1609.344;

			case DistanceUnit.Yards:
				return value * 0.9144;
		}
	}

	formatDistance(metres: number, unit: DistanceUnit, round: boolean = true) {
		const distance = this.getDistance(metres, unit);
		const distanceRounded = round ? distance.toFixed(2) : distance;

		switch (unit) {
			case DistanceUnit.Metres:
				return `${distanceRounded} m`;

			case DistanceUnit.Kilometres:
				return `${distanceRounded} km`;

			case DistanceUnit.Miles:
				return `${distanceRounded} mi`;

			case DistanceUnit.Yards:
				return `${distanceRounded} yd`;
		}
	}

	getSpeed(kilometresPerHour: number, unit: SpeedUnit) {
		switch (unit) {
			case SpeedUnit.KilometresPerHour:
				return kilometresPerHour;

			case SpeedUnit.MilesPerHour:
				return this.getDistance(kilometresPerHour * 1000, DistanceUnit.Miles);
		}
	}

	formatSpeed(kilometresPerHour: number, unit: SpeedUnit, round: boolean = true) {
		const speed = this.getSpeed(kilometresPerHour, unit);
		const speedRounded = round ? Math.round(speed) : speed;

		switch (unit) {
			case SpeedUnit.KilometresPerHour:
			return `${speedRounded} km/h`;

			case SpeedUnit.MilesPerHour:
			return `${speedRounded} mph`;
		}
	}

	convertMilesToKilometres(miles: number) {
		return miles * 1.609344;
	}

	convertKilometresToMiles(kilometres: number) {
		return kilometres / 1.609344;
	}
}
