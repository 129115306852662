import React from 'react';
import { FieldProps, getIn } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const FormikFormControl = withStyles({
	root: {
		display: 'flex',
	},
})(FormControl);

interface FormikCheckboxProps {
	label: string;
	helperText?: string;
}

const FormikCheckbox = ({
	label,
	helperText,
	field,
	form: { touched, errors, values },
	...props
}: FieldProps & FormikCheckboxProps) => {
	const checked = getIn(values, field.name);
	const fieldError = getIn(errors, field.name);
	const fieldTouched = getIn(touched, field.name);

	return <FormikFormControl
		{...props}
	>
		<FormControlLabel
			control={<Checkbox
				color="primary"
				{...field}
				checked={checked}
			/>}
			label={label}
		/>

		{fieldTouched && fieldError && <div className="error">
			<div className="triangle"></div>
			<div className="contents">{fieldError}</div>
		</div>}

		{helperText && <FormHelperText>{helperText}</FormHelperText>}
	</FormikFormControl>;
};

export {
	FormikCheckboxProps,
	FormikCheckbox,
};
